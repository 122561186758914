import { useEffect, useState } from "react";
import { IFootballMatchDetail } from "../../../../../commons/models/football/interface/i-football-match";
import { AppAvatar, AppBox, AppGridBox, AppPaper, AppText, AppTitle } from "../../../../../commons/components";
import { toTitleCase } from "../../../../../commons/utilities/string-utils";
import { IFootballMatchPenalty } from "../../../../../commons/models/football/interface/i-football-match-penalty";
import AppBorderBox from "../../../../../commons/components/BorderBox";
import IconFootball from "../../../../../commons/components/icons/football";
import { Tooltip } from "react-tooltip";
import { appSession, appStorage } from "../../../../../commons";
import { useAppSelector } from "../../../../../hooks/app";

interface MatchPenaltyListProps {
  match: IFootballMatchDetail;
}

const MatchPenaltyList = ({ match }: MatchPenaltyListProps) => {
  const [penaltyNumberArray, setPenaltyNumberArray] = useState<number[]>([]);
  const [homePenaltyList, setHomePenaltyList] = useState<IFootballMatchPenalty[]>([]);
  const [awayPenaltyList, setAwayPenaltyList] = useState<IFootballMatchPenalty[]>([]);
  const [homePenaltyGoalList, setHomePenaltyGoalList] = useState<IFootballMatchPenalty[]>([]);
  const [awayPenaltyGoalList, setAwayPenaltyGoalList] = useState<IFootballMatchPenalty[]>([]);
  const { theme } = useAppSelector((state) => state.session);

  useEffect(() => {
    const penaltyNumbers = new Set<number>();
    let homePenalties = [];
    let awayPenalties = [];
    let homeGoaledPenalties = [];
    let awayGoaledPenalties = [];
    for (const penalty of match.penalties) {
      penaltyNumbers.add(penalty.penaltyNumber);
      if (penalty.teamId === match.homeTeam?.id) {
        homePenalties.push(penalty);
        if (penalty.goalScored) {
          homeGoaledPenalties.push(penalty);
        }
      } else {
        awayPenalties.push(penalty);
        if (penalty.goalScored) {
          awayGoaledPenalties.push(penalty);
        }
      }
    }
    setHomePenaltyList(homePenalties);
    setAwayPenaltyList(awayPenalties);
    setHomePenaltyGoalList(homeGoaledPenalties);
    setAwayPenaltyGoalList(awayGoaledPenalties);
    setPenaltyNumberArray([...penaltyNumbers]);
  }, [match]);

  return (
    <>
      <AppBox flexDirection="column" gap="xs">
        <AppTitle as="h5">Penalty Shootout</AppTitle>
        <AppPaper shadow="xs" padding="xs">
          <AppBox flexDirection="column" gap="xs">
            <AppBorderBox border={["Bottom"]} pb="xs">
              <AppBox gap="sm" justifyContent="space-between" alignItems="center">
                <AppBox gap="2xs" alignItems="center" className="w-100 flex-1" justifyContent="center">
                  <AppAvatar size="sm" username={match.homeTeam?.shortName || ""} src={match.homeTeam?.logo || ""} />
                  <AppText size="lg" fontWeight="bold">
                    {toTitleCase(match.homeTeam?.shortName || "")} ({homePenaltyGoalList.length})
                  </AppText>
                </AppBox>
                <AppBox gap="2xs" alignItems="center" className="w-100 flex-1" justifyContent="center">
                  <AppAvatar size="sm" username={match.awayTeam?.shortName || ""} src={match.awayTeam?.logo || ""} />
                  <AppText size="lg" fontWeight="bold">
                    {toTitleCase(match.awayTeam?.shortName || "")} ({awayPenaltyGoalList.length})
                  </AppText>
                </AppBox>
              </AppBox>
            </AppBorderBox>
            <AppGridBox style={{ gridTemplateColumns: "2fr 0.25fr 2fr" }}>
              <AppBox flexDirection="column" gap="2xs" px="xs">
                {homePenaltyList.map((penalty) => (
                  <AppBox
                    justifyContent="space-between"
                    alignItems="center"
                    key={penalty.id}
                    className="penalty-height"
                  >
                    <AppBox gap="2xs" alignItems="center" justifyContent="center">
                      <AppAvatar size="xs" username={penalty.player.fullName || ""} src={penalty.player.avatar || ""} />
                      <AppText>{toTitleCase(penalty.player.displayName || "")}</AppText>
                    </AppBox>
                    <AppBox className="penalty-icon">
                      {penalty.goalScored ? (
                        <span data-tooltip-id="goal">
                          <IconFootball color="rgba(var(--success-600))" />
                        </span>
                      ) : (
                        <span data-tooltip-id="miss">
                          <IconFootball color="rgba(var(--danger-600))" />
                        </span>
                      )}
                    </AppBox>
                  </AppBox>
                ))}
              </AppBox>
              <AppBox flexDirection="column" alignItems="center">
                {penaltyNumberArray.map((penaltyNumber) => (
                  <AppBox alignItems="center" key={penaltyNumber} className="flex-1" style={{ height: "32px" }}>
                    <AppText>{penaltyNumber}</AppText>
                  </AppBox>
                ))}
              </AppBox>
              <AppBox flexDirection="column" gap="2xs" px="xs">
                {awayPenaltyList.map((penalty) => (
                  <AppBox
                    justifyContent="space-between"
                    alignItems="center"
                    key={penalty.id}
                    className="penalty-height"
                  >
                    <AppBox gap="2xs" alignItems="center" justifyContent="center">
                      <AppAvatar size="xs" username={penalty.player.fullName || ""} src={penalty.player.avatar || ""} />
                      <AppText>{toTitleCase(penalty.player.displayName || "")}</AppText>
                    </AppBox>
                    <AppBox className="penalty-icon">
                      {penalty.goalScored ? (
                        <span data-tooltip-id="goal">
                          <IconFootball color="rgba(var(--success-600))" />
                        </span>
                      ) : (
                        <span data-tooltip-id="miss">
                          <IconFootball color="rgba(var(--danger-600))" />
                        </span>
                      )}
                    </AppBox>
                  </AppBox>
                ))}
              </AppBox>
            </AppGridBox>
          </AppBox>
        </AppPaper>
      </AppBox>
      <Tooltip id="goal" content={"Goal"} place="bottom" variant={theme === "dark" ? "light" : "dark"} />
      <Tooltip id="miss" content={"Miss"} place="bottom" variant={theme === "dark" ? "light" : "dark"} />
    </>
  );
};

export default MatchPenaltyList;
