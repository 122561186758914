import { useEffect, useState } from "react";
import { AppBox, AppButton, AppGridBox, AppInput, AppSelect, AppText } from "../../../../../commons/components";
import { Controller, useForm } from "react-hook-form";
import { findInputError, isFormInvalid } from "../../../../../commons/utilities/form-utils";
import { ISelectOptions } from "../../../../../commons/components/ui-components/Select";
import { findIndexByCondition } from "../../../../../commons/utilities/array-utils";
import { FieldErrors, FieldValues } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import { setStepperFormData } from "../../../../../redux/slices/stepper-form-slice";
import { IStadium } from "../../../../../commons/models/i-stadium";
import { PlayingSurfaceTypeEnum } from "../../../../../commons/enums/playing-surface-type-enum";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { IStadiumDTO } from "../../../../../api-services/football/admin/manage-stadium-service";
import { toSentenceCase } from "../../../../../commons/utilities/string-utils";
interface TeamSpecificsAddEditProps {
  stadiumData?: IStadium | null;
  formStep: number;
  formErrors: FieldErrors<FieldValues>;
  submitStatus: StatusEnum;
  onSubmit: (currentStep: number) => void;
  onBack: (currentStep: number) => void;
  onClearError: (errorKey: string) => void;
}

const TeamSpecificsAddEdit = ({
  stadiumData,
  formStep,
  formErrors,
  submitStatus,
  onBack,
  onSubmit,
  onClearError,
}: TeamSpecificsAddEditProps) => {
  const methods = useForm();
  const dispatch = useAppDispatch();
  const [playingSurfaceIndex, setPlayingSurfaceIndex] = useState(0);
  const formData = useAppSelector<IStadiumDTO>((state) => state.stepperForm.stepperFormData);

  const playingSurfaceOptions: ISelectOptions[] = [
    {
      title: "Natural Grass",
      value: PlayingSurfaceTypeEnum.NaturalGrass,
    },
    {
      title: "Synthetic Grass",
      value: PlayingSurfaceTypeEnum.SyntheticGrass,
    },
    {
      title: "Hybrid Grass",
      value: PlayingSurfaceTypeEnum.HybridGrass,
    },
    {
      title: "Sand",
      value: PlayingSurfaceTypeEnum.Sand,
    },
    {
      title: "Gravel",
      value: PlayingSurfaceTypeEnum.Gravel,
    },
  ];

  useEffect(() => {
    if (!stadiumData && playingSurfaceOptions) {
      const stadiumData: IStadiumDTO = {
        ...formData,
        playingSurfaceType: playingSurfaceOptions[0].value.toString(),
      };
      dispatch(setStepperFormData<IStadiumDTO>(stadiumData));
    }
  }, []);

  useEffect(() => {
    if (stadiumData) {
      let playingSurfaceIndex = findIndexByCondition(
        playingSurfaceOptions,
        (option) => option.value === stadiumData.playingSurfaceType
      );
      if (playingSurfaceIndex >= 0) {
        setPlayingSurfaceIndex(playingSurfaceIndex);
      } else {
        setPlayingSurfaceIndex(0);
      }
      methods.setValue("playingSurfaceType", `${stadiumData.playingSurfaceType}`);
    }
  }, [stadiumData]);

  const convertValue = (key: string, value: string): any => {
    const numericKeys = ["capacity", "pitchLength", "pitchWidth"];
    return numericKeys.includes(key) ? Number(value) : value;
  };

  const handleInputChange = (value: string, key: string) => {
    const stadiumData: IStadiumDTO = {
      ...formData,
      [key]: convertValue(key, value),
    };
    dispatch(setStepperFormData<IStadiumDTO>(stadiumData));
    onClearError(key);
  };
  return (
    <AppBox flexDirection="column" justifyContent="space-between" gap="sm" className="flex-1">
      <AppBox flexDirection="column" gap="sm" className="w-100">
        <Controller
          name="capacity"
          defaultValue={stadiumData ? stadiumData.capacity : ""}
          control={methods.control}
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                id="capacity"
                label="Capacity"
                placeholder="Enter venue's capacity, e.g. 60,000"
                type="number"
                defaultValue={value}
                flex="row"
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "capacity");
                }}
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "capacity")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.capacity?.message}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="pitchLength"
          defaultValue={stadiumData ? stadiumData.pitchLength : ""}
          control={methods.control}
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                id="pitchLength"
                label="Pitch Length (meter)"
                placeholder="Enter pitch's length in meter"
                type="number"
                defaultValue={value}
                flex="row"
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "pitchLength");
                }}
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "pitchLength")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.pitchLength?.message}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="pitchWidth"
          defaultValue={stadiumData ? stadiumData.pitchWidth : ""}
          control={methods.control}
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                id="pitchWidth"
                label="Pitch Width (meter)"
                placeholder="Enter pitch's width in meter"
                type="number"
                defaultValue={value}
                flex="row"
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "pitchWidth");
                }}
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "pitchWidth")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.pitchWidth?.message}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="playingSurfaceType"
          control={methods.control}
          rules={{ required: false }}
          defaultValue={stadiumData ? playingSurfaceOptions[playingSurfaceIndex].value : playingSurfaceOptions[0].value}
          render={({ field: { onChange } }) => (
            <AppBox flexDirection="column">
              <AppGridBox className="w-100 flex-1" style={{ gridTemplateColumns: "1fr 2fr" }} gap="sm">
                <AppBox alignItems="center">
                  <AppText as="label" size="lg">
                    Playing Surface Type
                  </AppText>
                </AppBox>
                <AppSelect
                  options={playingSurfaceOptions}
                  onChange={(option) => {
                    onChange(option.value);
                    setPlayingSurfaceIndex(
                      findIndexByCondition(
                        playingSurfaceOptions,
                        (surfaceOption) => surfaceOption.value === option.value
                      )
                    );
                    handleInputChange(option.value.toString(), "playingSurfaceType");
                  }}
                  currentOption={playingSurfaceOptions[playingSurfaceIndex]}
                />
              </AppGridBox>
            </AppBox>
          )}
        />
      </AppBox>
      <AppBox gap="xs" justifyContent="end">
        <AppButton
          label="Back"
          variant="outline"
          color="gray"
          borderLight
          onClick={() => {
            onBack(formStep);
          }}
          disabled={submitStatus === StatusEnum.Loading}
        />
        <AppButton
          type="submit"
          label={stadiumData ? "Save Changes" : "Create"}
          onClick={() => {
            onSubmit(formStep);
          }}
          loading={submitStatus === StatusEnum.Loading}
        />
      </AppBox>
    </AppBox>
  );
};

export default TeamSpecificsAddEdit;
