import {
  AppBox,
  AppButton,
  AppGridBox,
  AppIconButton,
  AppModal,
  AppPaper,
  AppText,
} from "../../../../../commons/components";
import TeamFormBackGround from "../../../../../assets/images/backgrounds/Ground-Large.svg";
import { IFootballPlayerCommentaries } from "../../../../../commons/models/football/interface/i-football-player-lineup";
import { IFootballMatchDetail } from "../../../../../commons/models/football/interface/i-football-match";
import { useEffect, useLayoutEffect, useState } from "react";
import IconPersonAvailable from "../../../../../commons/components/icons/person-available";
import PlayerSearch from "../../../teams/components/PlayerSearch";
import { PlayerPositionEnum } from "../../../../../commons/enums/match-player-position-enum";
import { FootballTeamPlayer } from "../../../../../commons/models/football/football-team-player";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import IconDismiss from "../../../../../commons/components/icons/dismiss";
import { groupArrayByProperty } from "../../../../../commons/utilities/array-utils";
import { MatchTeamFormPositionGroup } from "./MatchTeamFormPositionGroup";
import { TournamentFormEnum } from "../../../../../commons/enums/tournament-form-enum";
import { addEditSeasonMatchTeamLineup } from "../../../../../redux/slices/football/admin/season/match/manage-season-match-team-lineup-slice";
import { PlayerRoleType } from "../../../../../commons/enums/match-player-role-type";
import { useSnackbar } from "../../../../../redux/snackbarProvider";
import { IServerErrorResponse } from "../../../../../commons/components/interface";
import { AxiosError } from "axios";
import { fetchSeasonMatchDetail } from "../../../../../redux/slices/football/admin/season/match/manage-season-match-detail-slice";
import { DndContext } from "@dnd-kit/core";
import { DraggableComponent } from "./DraggableComponent";
import { DroppableComponent } from "./DroppableComponent";
import { FormationBoxComponent } from "./FormationBoxComponent";
import FormationPlayerComponent from "./FormationPlayerComponent";
import isNullOrUndefined from "../../../../../commons/utilities/null-or-undefined";
import IconProhibited from "../../../../../commons/components/icons/prohibited";
import { MatchStatusEnum } from "../../../../../commons/enums/match-status-enum";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { IMatchTeamLineupInfo } from "../../../../../api-services/football/admin/manage-football-season-service";

interface TeamFormProps {
  isHomeTeam: boolean;
  match: IFootballMatchDetail;
  players: IFootballPlayerCommentaries[];
}

export default function MatchTeamFormAddEdit({ isHomeTeam, match, players }: TeamFormProps) {
  let lineupCells = [
    [6, 1],
    [2, 4],
    [4, 4],
    [6, 4],
    [8, 4],
    [10, 4],
    [2, 6],
    [4, 6],
    [6, 6],
    [8, 6],
    [10, 6],
    [2, 8],
    [4, 8],
    [6, 8],
    [8, 8],
    [10, 8],
    [2, 10],
    [4, 10],
    [6, 10],
    [8, 10],
    [10, 10],
    [4, 12],
    [6, 12],
    [8, 12],
  ];
  const substituteCells = [
    [1, 1],
    [1, 2],
    [1, 3],
    [1, 4],
    [1, 5],
    [1, 6],
    [1, 7],
    [1, 8],
    [1, 9],
  ];
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const [isEditFormation, setIsEditFormation] = useState(false);
  const [isAddLineupPlayer, setIsAddLineupPlayer] = useState(false);
  const [isAddBenchPlayer, setIsAddBenchPlayer] = useState(false);
  const [isMaxPlayerLimit, setIsMaxPlayerLimit] = useState(false);
  const [startingLineupPlayers, setStartingLineupPlayers] = useState<IFootballPlayerCommentaries[]>();
  const [benchPlayers, setBenchPlayers] = useState<IFootballPlayerCommentaries[]>();
  const [selectedLineupPlayer, setSelectedLineUpPlayer] = useState<FootballTeamPlayer | null>(null);
  const [selectedBenchPlayer, setSelectedBenchPlayer] = useState<FootballTeamPlayer | null>(null);
  const [selectedPlayers, setSelectedPlayers] = useState<FootballTeamPlayer[]>([]);
  const [playerGridPosition, setPlayerGridPosition] = useState<string>();
  const [playerPlayingRole, setPlayerPlayingRole] = useState<PlayerRoleType>();
  const [playingLineup, setPlayingLineup] = useState<string>();
  const [groupedLineupPlayers, setGroupedLineupPlayers] = useState<
    {
      [x: string]: string | IFootballPlayerCommentaries[];
      items: IFootballPlayerCommentaries[];
    }[]
  >([]);
  const [selectedGoalkeeper, setSelectedGoalkeeper] = useState<IFootballPlayerCommentaries[]>();
  const [selectedDefenders, setSelectedDefenders] = useState<IFootballPlayerCommentaries[]>();
  const [selectedMidfielders, setSelectedMidfielders] = useState<IFootballPlayerCommentaries[]>();
  const [selectedForwards, setSelectedForwards] = useState<IFootballPlayerCommentaries[]>();
  const [playerLineUpLimit, setPlayerLineupLimit] = useState<number>();
  const [isMaxLineupPlayers, setIsMaxLineupPlayers] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const { seasonMatchDetailStatus } = useAppSelector((state) => state.footballManageSeasonMatchDetail);
  const { seasonMatchTeamLineUpStatus } = useAppSelector((state) => state.footballManageSeasonMatchTeamLineup);

  useEffect(() => {
    if (match && match.competition) {
      const playerLimit = getPlayerLineupLimit(match.competition?.teamForm || "");
      setPlayerLineupLimit(playerLimit);
    }
  }, [match]);

  useLayoutEffect(() => {
    if (playerLineUpLimit) {
      handleSetToDefault();
    }
  }, [isHomeTeam, players, playerLineUpLimit]);

  useEffect(() => {
    if (selectedLineupPlayer) {
      if (playerGridPosition && playerPlayingRole) {
        const playerLineup: IFootballPlayerCommentaries = {
          id: selectedLineupPlayer.player?.id || selectedLineupPlayer.id,
          position: selectedLineupPlayer.position as PlayerPositionEnum,
          playingRole: playerPlayingRole,
          isCaptain: selectedLineupPlayer.isCaptain,
          onBench: false,
          jerseyNumber: selectedLineupPlayer.jerseyNumber,
          gridPosition: playerGridPosition,
          displayName: selectedLineupPlayer.player?.displayName || selectedLineupPlayer.displayName,
          avatar: selectedLineupPlayer.player?.avatar || selectedLineupPlayer.avatar,
        };
        var lineupPlayers: IFootballPlayerCommentaries[] = startingLineupPlayers ?? [];
        lineupPlayers.push(playerLineup);
        setStartingLineupPlayers([...lineupPlayers]);
      }
      setSelectedLineUpPlayer(null);
    }
  }, [selectedLineupPlayer]);

  useEffect(() => {
    if (selectedBenchPlayer) {
      const playerLineup: IFootballPlayerCommentaries = {
        id: selectedBenchPlayer.player?.id || selectedBenchPlayer.id,
        position: selectedBenchPlayer.position as PlayerPositionEnum,
        playingRole: null,
        isCaptain: selectedBenchPlayer.isCaptain,
        onBench: true,
        jerseyNumber: selectedBenchPlayer.jerseyNumber,
        gridPosition: null,
        displayName: selectedBenchPlayer.player?.displayName || selectedBenchPlayer.displayName,
        avatar: selectedBenchPlayer.player?.avatar || selectedBenchPlayer.avatar,
      };
      var lineupPlayers: IFootballPlayerCommentaries[] = benchPlayers ?? [];
      lineupPlayers.push(playerLineup);
      setBenchPlayers([...lineupPlayers]);
    }
    setSelectedBenchPlayer(null);
  }, [selectedBenchPlayer]);

  useEffect(() => {
    let defenders = 0;
    let midfielders = 0;
    let attackingMidfielders = 0;
    let forwards = 0;
    if (startingLineupPlayers) {
      startingLineupPlayers.forEach((lineupPlayer) => {
        if (lineupPlayer.gridPosition) {
          const [x, y] = lineupPlayer.gridPosition.split(",").map(Number);
          if (x === 3 || x === 4 || x === 6) {
            defenders++;
          } else if (x === 8) {
            midfielders++;
          } else if (x === 10) {
            attackingMidfielders++;
          } else if (x === 12) {
            forwards++;
          }
        }
      });
    }
    const lineup = handleLineup(defenders, midfielders, attackingMidfielders, forwards);
    setPlayingLineup(lineup);
  }, [startingLineupPlayers]);

  useEffect(() => {
    if (isNullOrUndefined(startingLineupPlayers)) {
      setGroupedLineupPlayers([]);
      return;
    }
    if (startingLineupPlayers && playerLineUpLimit) {
      if (startingLineupPlayers.length > 0) {
        const groupedPlayer = groupArrayByProperty(startingLineupPlayers, "position");
        setGroupedLineupPlayers([...groupedPlayer]);
      } else {
        setGroupedLineupPlayers([]);
      }
      if (startingLineupPlayers.length >= playerLineUpLimit) {
        setIsMaxLineupPlayers(true);
      } else {
        setIsMaxLineupPlayers(false);
      }
    }
  }, [startingLineupPlayers]);

  useEffect(() => {
    if (startingLineupPlayers || benchPlayers) {
      const formattedLineupPlayers: FootballTeamPlayer[] =
        startingLineupPlayers?.map(
          (startingPlayer) =>
            ({
              id: startingPlayer.id,
              jerseyNumber: startingPlayer.jerseyNumber,
              position: startingPlayer.position,
              isCaptain: startingPlayer.isCaptain,
              playingRoles: startingPlayer.playingRole,
              displayName: startingPlayer.displayName,
              avatar: startingPlayer.avatar,
              player: {
                id: startingPlayer.id,
                displayName: startingPlayer.displayName,
                avatar: startingPlayer.avatar,
              },
            } as FootballTeamPlayer)
        ) || [];
      const formattedBenchPlayers: FootballTeamPlayer[] =
        benchPlayers?.map(
          (benchPlayer) =>
            ({
              id: benchPlayer.id,
              jerseyNumber: benchPlayer.jerseyNumber,
              position: benchPlayer.position,
              isCaptain: benchPlayer.isCaptain,
              playingRoles: benchPlayer.playingRole,
              displayName: benchPlayer.displayName,
              avatar: benchPlayer.avatar,
              player: {
                id: benchPlayer.id,
                displayName: benchPlayer.displayName,
                avatar: benchPlayer.avatar,
              },
            } as FootballTeamPlayer)
        ) || [];
      const selectedAllPlayers = formattedLineupPlayers.concat(formattedBenchPlayers);
      setSelectedPlayers(selectedAllPlayers);
    } else {
      setSelectedPlayers([]);
    }
  }, [startingLineupPlayers, benchPlayers]);

  useEffect(() => {
    if (groupedLineupPlayers.length > 0) {
      const positions = new Set<string>();
      groupedLineupPlayers.forEach((group) => {
        positions.add(group.position as string);
      });
      const allPositions = Array.from(positions);
      if (!allPositions.includes(PlayerPositionEnum.Goalkeeper)) {
        setSelectedGoalkeeper([]);
      }
      if (!allPositions.includes(PlayerPositionEnum.Defender)) {
        setSelectedDefenders([]);
      }
      if (!allPositions.includes(PlayerPositionEnum.Midfielder)) {
        setSelectedMidfielders([]);
      }
      if (!allPositions.includes(PlayerPositionEnum.Forward)) {
        setSelectedForwards([]);
      }
      groupedLineupPlayers.map((group) => {
        switch (group.position as string) {
          case PlayerPositionEnum.Goalkeeper:
            setSelectedGoalkeeper([...(group.items as IFootballPlayerCommentaries[])]);
            break;
          case PlayerPositionEnum.Defender:
            setSelectedDefenders([...(group.items as IFootballPlayerCommentaries[])]);
            break;
          case PlayerPositionEnum.Midfielder:
            setSelectedMidfielders([...(group.items as IFootballPlayerCommentaries[])]);
            break;
          case PlayerPositionEnum.Forward:
            setSelectedForwards([...(group.items as IFootballPlayerCommentaries[])]);
            break;
        }
      });
    } else {
      setAllPositionNull();
    }
  }, [groupedLineupPlayers]);

  useEffect(() => {
    if (playerGridPosition) {
      const [col, row] = playerGridPosition.split(",");
      setPlayerPlayingRole(getPlayerRole(Number(col), Number(row)));
    }
  }, [playerGridPosition]);

  const setAllPositionNull = () => {
    setSelectedGoalkeeper([]);
    setSelectedDefenders([]);
    setSelectedMidfielders([]);
    setSelectedForwards([]);
  };

  const handleLineup = (def: number, mid: number, atkMid: number, fwd: number) => {
    const lineupArr: string[] = [];
    if (def > 0) lineupArr.push(`${def}`);
    if (mid > 0) lineupArr.push(`${mid}`);
    if (atkMid > 0) lineupArr.push(`${atkMid}`);
    if (fwd > 0) lineupArr.push(`${fwd}`);
    return lineupArr.join("-");
  };

  const removeLineupPlayer = (playerToRemove: IFootballPlayerCommentaries) => {
    setStartingLineupPlayers((prevLineup) => prevLineup?.filter((player) => player.id !== playerToRemove.id));
    setIsMaxLineupPlayers(false);
  };

  const removeBenchPlayer = (playerToRemove: IFootballPlayerCommentaries) => {
    setBenchPlayers((prevLineup) => prevLineup?.filter((player) => player.id !== playerToRemove.id));
  };

  const filterPlayersWithGridPosition = (players: IFootballPlayerCommentaries[]): IFootballPlayerCommentaries[] => {
    if (!players) return [];
    return players.filter((player) => player.gridPosition !== null);
  };

  const filterPlayersOnBench = (players: IFootballPlayerCommentaries[]): IFootballPlayerCommentaries[] => {
    if (!players) return [];
    return players.filter((player) => player.gridPosition === null);
  };

  const getPlayerLineupLimit = (teamForm: string): number => {
    switch (teamForm) {
      case TournamentFormEnum.Form11:
        return 11;
      case TournamentFormEnum.Form7:
        return 7;
      default:
        return 0;
    }
  };

  const handleSaveFormation = () => {
    if (startingLineupPlayers && playingLineup && match.homeTeam && match.awayTeam) {
      const matchPlayers = startingLineupPlayers.concat(benchPlayers as IFootballPlayerCommentaries[]);
      const teamLineupInfo: IMatchTeamLineupInfo = {
        lineUps: playingLineup,
        players: matchPlayers,
      };
      dispatch(
        addEditSeasonMatchTeamLineup({
          seasonId: match.season.id,
          matchId: match.id,
          teamId: isHomeTeam ? match.homeTeam.id : match.awayTeam.id,
          teamLineupInfo,
        })
      )
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "formationAdd",
            text: "Team Formation Added Successfully",
            variant: "success",
          });
          dispatch(
            fetchSeasonMatchDetail({
              seasonId: match.season.id,
              matchId: match.id,
            })
          );
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          addSnackbar({
            key: "error",
            text: error.response?.data.message,
            variant: "danger",
          });
        });
    }
  };

  const getPlayerRole = (col: number, row: number): PlayerRoleType => {
    const playingRoles = [
      ["", "", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "LB", "", "LWB", "", "LM", "", "LW", ""],
      ["", "", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "LCB", "", "LDM", "", "LCM", "", "LAM", "", "LS"],
      ["", "", "", "", "", "", "", "", "", "", "", ""],
      ["GK", "", "SW", "CB", "", "CDM", "", "CM", "", "CAM", "", "CS"],
      ["", "", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "RCB", "", "RDM", "", "RCM", "", "RAM", "", "RS"],
      ["", "", "", "", "", "", "", "", "", "", "", ""],
      ["", "", "", "RB", "", "RWB", "", "RM", "", "RW", ""],
    ];
    return playingRoles[row - 1][col - 1] as PlayerRoleType;
  };

  const handleFiledPlayerAdd = () => {
    if (isMaxLineupPlayers) {
      setIsMaxPlayerLimit(true);
    } else {
      setIsAddLineupPlayer(true);
    }
  };

  function handleDragStart(event: any) {
    setIsDragging(true);
  }

  function handleDragEnd({ over, active }: any) {
    let players = startingLineupPlayers || [];
    let benchPlayersArray = benchPlayers || [];
    if (over && active.id !== over.id) {
      let draggedData = JSON.parse(JSON.stringify(active.data.current));
      let overData: any;
      if (over?.data.current) {
        overData = JSON.parse(JSON.stringify(over.data.current));
      }

      if (!isNullOrUndefined(over.data?.current)) {
        switch (true) {
          case active.id.includes("bench") && over.id.includes("bench"):
            if (benchPlayersArray) {
              const dragDataIndex = benchPlayersArray.findIndex((item) => item.id === draggedData.id);
              const dropDataIndex = benchPlayersArray.findIndex((item) => item.id === overData.id);
              [benchPlayersArray[dragDataIndex], benchPlayersArray[dropDataIndex]] = [overData, draggedData];
            }
            break;
          case over.id.includes("bench") && !active.id.includes("bench"):
            const dropDataIndex = benchPlayersArray?.findIndex((item) => item.id === overData.id);
            let [activeCol, activeRow] = active.id.split(",");
            draggedData.gridPosition = null;
            draggedData.playingRole = null;
            draggedData.onBench = true;
            overData.gridPosition = active.id;
            overData.playingRole = getPlayerRole(activeCol, activeRow);
            overData.onBench = false;
            players = players?.filter((item) => item.id !== draggedData.id);
            players?.push(overData);
            benchPlayersArray.splice(dropDataIndex, 1, draggedData);
            break;
          case active.id.includes("bench") && !over.id.includes("bench"):
            const dragDataIndex = benchPlayersArray.findIndex((item) => item.id === draggedData.id);
            let [overCol, overRow] = over.id.split(",");
            draggedData.gridPosition = over.id;
            draggedData.playingRole = getPlayerRole(overCol, overRow);
            draggedData.onBench = false;
            overData.gridPosition = null;
            overData.playingRole = null;
            overData.onBench = true;
            players = players?.filter((item) => item.id !== overData.id);
            players?.push(draggedData);
            benchPlayersArray.splice(dragDataIndex, 1, overData);
            break;
          default:
            let [Col1, Row1] = over.id.split(",");
            let [Col2, Row2] = active.id.split(",");
            draggedData.gridPosition = over.id;
            draggedData.playingRole = getPlayerRole(Col1, Row1);
            overData.gridPosition = active.id;
            overData.playingRole = getPlayerRole(Col2, Row2);
            players = players.filter((player) => player.id !== draggedData.id && player.id !== overData.id);
            players = [...players, { ...draggedData }, { ...overData }];
            break;
        }
        setStartingLineupPlayers([...players]);
        setBenchPlayers([...benchPlayersArray]);
      } else {
        const [overCol, overRow] = over.id.split(",");
        const sourceArray = active.id.includes("bench") ? benchPlayersArray : players;
        const targetArray = over.id.includes("bench") ? benchPlayersArray : players;
        if (active.id.includes("bench") && !over.id.includes("bench") && targetArray.length === playerLineUpLimit) {
          setIsMaxPlayerLimit(true);
        } else {
          const dataIndex = sourceArray.findIndex((item) => item.id === draggedData.id);
          sourceArray.splice(dataIndex, 1);
          active.id.includes("bench") ? setBenchPlayers([...sourceArray]) : setStartingLineupPlayers([...sourceArray]);
          draggedData.gridPosition = over.id.includes("bench") ? null : over.id;
          draggedData.playingRole = over.id.includes("bench") ? null : getPlayerRole(overCol, overRow);
          draggedData.onBench = over.id.includes("bench") ? true : false;
          targetArray.push(draggedData);
          over.id.includes("bench") ? setBenchPlayers([...targetArray]) : setStartingLineupPlayers([...targetArray]);
        }
      }
    }
    setIsDragging(false);
  }

  const handleClearAll = () => {
    setStartingLineupPlayers([]);
    setBenchPlayers([]);
  };

  const handleSetToDefault = () => {
    setStartingLineupPlayers([]);
    setBenchPlayers([]);
    if (players) {
      setStartingLineupPlayers([...filterPlayersWithGridPosition(players)]);
      setBenchPlayers([...filterPlayersOnBench(players)]);
      if (players.length === 0) {
        setIsEditFormation(true);
        setIsMaxLineupPlayers(false);
      } else {
        setIsEditFormation(false);
        setIsMaxLineupPlayers(true);
      }
    } else {
      setStartingLineupPlayers([]);
      setBenchPlayers([]);
      setIsEditFormation(true);
    }
  };

  return (
    <>
      <AppBox flexDirection="column" gap="md">
        <AppBox flexDirection="column" gap="xs">
          <AppBox alignItems="center" justifyContent="space-between">
            <AppText as="label" fontWeight="bold" size="lg">
              {`${isEditFormation ? "Create Formation: " : "Formation: "} ${playingLineup}`}
            </AppText>
            {match.status === MatchStatusEnum.Upcoming && (
              <>
                {isEditFormation ? (
                  <AppBox gap="xs">
                    {(isHomeTeam && players && players.length > 0) || (!isHomeTeam && players && players.length > 0) ? (
                      <AppButton
                        label="Set As Default"
                        variant="outline"
                        color="gray"
                        borderLight
                        onClick={handleSetToDefault}
                        disabled={seasonMatchTeamLineUpStatus === StatusEnum.Loading}
                      />
                    ) : (
                      <>
                        {((startingLineupPlayers && startingLineupPlayers?.length > 0) ||
                          (benchPlayers && benchPlayers?.length > 0)) && (
                          <AppButton
                            label="Clear All"
                            variant="outline"
                            color="gray"
                            borderLight
                            disabled={seasonMatchTeamLineUpStatus === StatusEnum.Loading}
                            onClick={handleClearAll}
                          />
                        )}
                      </>
                    )}
                    <AppButton
                      label="Save Formation"
                      variant="light"
                      disabled={!isMaxLineupPlayers}
                      onClick={handleSaveFormation}
                      loading={seasonMatchTeamLineUpStatus === StatusEnum.Loading}
                    />
                  </AppBox>
                ) : (
                  <AppButton label="Edit Formation" variant="light" onClick={() => setIsEditFormation(true)} />
                )}
              </>
            )}
          </AppBox>
          <AppPaper padding="none" shadow="xs" className="formation-container">
            <img alt="ground img" src={TeamFormBackGround} className="w-100" />
            {seasonMatchDetailStatus === StatusEnum.Succeeded && (
              <AppBox flexDirection="column">
                <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
                  <AppGridBox columns={15} rows={11} p="3xl" className="lineup-grid">
                    <>
                      {lineupCells.map(([row, col]) => {
                        const leftPosition = col === 3 ? "20%" : col === 1 ? "60%" : "50%";
                        return (
                          <AppBox
                            key={`${row}-${col}`}
                            style={{
                              gridColumn: col,
                              gridRow: row,
                            }}
                            className="w-100 lineup-grid-cell"
                          >
                            <AppBox
                              className="lineup-grid-cell-content"
                              style={{
                                left: leftPosition,
                              }}
                            >
                              {startingLineupPlayers &&
                              startingLineupPlayers.some((player) => player.gridPosition === `${col},${row}`) ? (
                                <>
                                  {startingLineupPlayers.map((player, index) => {
                                    if (player.gridPosition === `${col},${row}`) {
                                      return (
                                        <DroppableComponent id={`${col},${row}`} data={player} key={index}>
                                          <AppBox className="player-cell-container">
                                            {isEditFormation && !isDragging && (
                                              <AppBox className="remove-button" mb="xs">
                                                <AppIconButton
                                                  icon={<IconDismiss />}
                                                  color="gray"
                                                  variant="outline"
                                                  borderLight
                                                  size="xs"
                                                  radius="rounded"
                                                  onClick={() => removeLineupPlayer(player)}
                                                />
                                              </AppBox>
                                            )}
                                            <DraggableComponent
                                              id={`${col},${row}`}
                                              data={player}
                                              disabled={!isEditFormation}
                                            >
                                              <FormationPlayerComponent
                                                player={player}
                                                isHomeTeam={isHomeTeam}
                                                match={match}
                                                isDraggable={isEditFormation}
                                              />
                                            </DraggableComponent>
                                          </AppBox>
                                        </DroppableComponent>
                                      );
                                    }
                                    return null;
                                  })}
                                </>
                              ) : (
                                <>
                                  {isEditFormation && (
                                    <DroppableComponent id={`${col},${row}`}>
                                      <FormationBoxComponent
                                        isDropZone={isDragging}
                                        onClick={() => {
                                          setPlayerGridPosition(`${col},${row}`);
                                          handleFiledPlayerAdd();
                                        }}
                                      />
                                    </DroppableComponent>
                                  )}
                                </>
                              )}
                            </AppBox>
                          </AppBox>
                        );
                      })}
                    </>
                  </AppGridBox>
                  <AppGridBox columns={9} rows={1} px="3xl" className="bench-grid">
                    {substituteCells.map(([row, col], index) => {
                      const player = benchPlayers?.[index];
                      return (
                        <AppBox
                          key={`${row}-${col}`}
                          style={{
                            gridColumn: col,
                            gridRow: row,
                          }}
                          className="w-100 bench-grid-cell"
                        >
                          <AppBox className="bench-grid-cell-content">
                            {player ? (
                              <DroppableComponent id={`bench-${col}`} data={player} key={index}>
                                <AppBox className="player-cell-container">
                                  {isEditFormation && !isDragging && (
                                    <AppBox className="remove-button " mb="xs">
                                      <AppIconButton
                                        icon={<IconDismiss />}
                                        color="gray"
                                        variant="outline"
                                        borderLight
                                        size="xs"
                                        radius="rounded"
                                        onClick={() => removeBenchPlayer(player)}
                                      />
                                    </AppBox>
                                  )}
                                  <DraggableComponent id={`bench-${col}`} data={player} disabled={!isEditFormation}>
                                    <FormationPlayerComponent player={player} isHomeTeam={isHomeTeam} match={match} />
                                  </DraggableComponent>
                                </AppBox>
                              </DroppableComponent>
                            ) : (
                              <>
                                {isEditFormation && (
                                  <DroppableComponent id={`bench-${col}`}>
                                    <FormationBoxComponent
                                      isDropZone={isDragging}
                                      onClick={() => {
                                        setIsAddBenchPlayer(true);
                                      }}
                                    />
                                  </DroppableComponent>
                                )}
                              </>
                            )}
                          </AppBox>
                        </AppBox>
                      );
                    })}
                  </AppGridBox>
                </DndContext>
              </AppBox>
            )}
          </AppPaper>
        </AppBox>
        <AppBox flexDirection="column" gap="md">
          <MatchTeamFormPositionGroup
            position="Goalkeeper"
            teamPlayers={selectedGoalkeeper}
            isFormationEdit={isEditFormation}
          />
          <MatchTeamFormPositionGroup
            position="Defenders"
            teamPlayers={selectedDefenders}
            isFormationEdit={isEditFormation}
          />
          <MatchTeamFormPositionGroup
            position="Midfielders"
            teamPlayers={selectedMidfielders}
            isFormationEdit={isEditFormation}
          />
          <MatchTeamFormPositionGroup
            position="Forwards"
            teamPlayers={selectedForwards}
            isFormationEdit={isEditFormation}
          />
          <MatchTeamFormPositionGroup
            position="Substitutes"
            teamPlayers={benchPlayers}
            isFormationEdit={isEditFormation}
          />
        </AppBox>
      </AppBox>
      {match && match.homeTeam && match.awayTeam && (
        <>
          <AppModal
            titleIcon={<IconPersonAvailable />}
            title="Choose A Player"
            opened={isAddLineupPlayer}
            onClose={(e) => {
              setIsAddLineupPlayer(false);
            }}
            withoutButtons
          >
            <PlayerSearch
              onSelectedTeamPlayer={(teamPlayers) => {
                setSelectedLineUpPlayer(teamPlayers[0]);
                setIsAddLineupPlayer(false);
              }}
              team={isHomeTeam ? match.homeTeam : match.awayTeam}
              userSelectedTeamPlayers={selectedPlayers}
            />
          </AppModal>
          <AppModal
            titleIcon={<IconPersonAvailable />}
            title="Choose A Player"
            opened={isAddBenchPlayer}
            onClose={(e) => {
              setIsAddBenchPlayer(false);
            }}
            withoutButtons
          >
            <PlayerSearch
              onSelectedTeamPlayer={(teamPlayers) => {
                setSelectedBenchPlayer(teamPlayers[0]);
                setIsAddBenchPlayer(false);
              }}
              team={isHomeTeam ? match.homeTeam : match.awayTeam}
              userSelectedTeamPlayers={selectedPlayers}
            />
          </AppModal>
          <AppModal
            titleIcon={<IconProhibited />}
            title="Unable To Add More Players"
            iconColor="danger"
            opened={isMaxPlayerLimit}
            onClose={(e) => {
              setIsMaxPlayerLimit(false);
            }}
            withoutConfirmButton
          >
            <AppText>
              Sorry, but your team formation is already complete with {playerLineUpLimit} players, which is the maximum
              allowed.
            </AppText>
          </AppModal>
        </>
      )}
    </>
  );
}
