import { AbstractBaseService } from "../ensue-react-system/utilities/base-service";
import { EnsueHttpClient } from "../ensue-react-system/http/ensue-http-client";
import { ApiEndPointUriProvider } from "../ensue-react-system/http/ensue-http-types";
import { FileUploadResponse } from "../commons/models/file-upload-response";

export class UploadFileService extends AbstractBaseService<FileUploadResponse> {
  protected __model: FileUploadResponse = new FileUploadResponse();

  public constructor(__client: EnsueHttpClient, __endpointProvider: ApiEndPointUriProvider) {
    super(__client, __endpointProvider);
  }

  public getResourceName(): string {
    return "upload-files";
  }

  public uploadFile(formData: FormData): Promise<FileUploadResponse> {
    return this.request<FormData, FileUploadResponse>(this.__url, "post", formData);
  }
}
