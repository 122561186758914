import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { AppBox } from "../../../commons/components";
import { StandingBracket } from "../../../commons/components/ui-components/StandingBracket";
import { fetchManageSeasonKnockoutList } from "../../../redux/slices/football/admin/season/knockout/manage-knockout-list-slice";

export const MatchDetailBrackets = () => {
  const dispatch = useAppDispatch();
  const { seasonMatchDetail } = useAppSelector((state) => state.footballManageSeasonMatchDetail);
  const { seasonKnockoutList } = useAppSelector((state) => state.footballSeasonKnockoutList);

  useEffect(() => {
    if (seasonMatchDetail.season) {
      dispatch(fetchManageSeasonKnockoutList({ seasonId: seasonMatchDetail.season.id }));
    }
  }, [seasonMatchDetail]);

  return (
    <AppBox gap="sm" flexDirection="column">
      <StandingBracket knockoutTree={seasonKnockoutList} isManage competition={seasonMatchDetail.competition} />
    </AppBox>
  );
};
