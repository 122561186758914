import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../../commons";
import { IMatchPlayersStatisticDTO } from "../../../../../../../api-services/football/admin/manage-football-season-service";

interface ISeasonMatchPlayersStatisticAdd {
  seasonMatchPlayersStatisticAddResponseStatus: StatusEnum;
}

const initialState: ISeasonMatchPlayersStatisticAdd = {
  seasonMatchPlayersStatisticAddResponseStatus: StatusEnum.Idle,
};

export interface IFootballSeasonMatchPlayersStatisticAddOptions {
  seasonId: number;
  matchId: number;
  playerId: number;
  matchPlayersStatisticData: IMatchPlayersStatisticDTO;
}

export const seasonMatchPlayersStatisticAdd = createAsyncThunk(
  "manage/seasonMatchPlayersStatisticAdd",
  async (options: IFootballSeasonMatchPlayersStatisticAddOptions, thunkAPI) => {
    try {
      const seasonMatchPlayersStatisticAddResponse = await manageFootballSeasonService.addSeasonMatchPlayerStatistic(
        options.seasonId,
        options.matchId,
        options.playerId,
        options.matchPlayersStatisticData
      );
      return seasonMatchPlayersStatisticAddResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonMatchPlayersStatisticAddSlice = createSlice({
  name: "seasonMatchPlayersStatisticAdd",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonMatchPlayersStatisticAdd.pending, (state) => {
        state.seasonMatchPlayersStatisticAddResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonMatchPlayersStatisticAdd.fulfilled, (state, action) => {
        state.seasonMatchPlayersStatisticAddResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonMatchPlayersStatisticAdd.rejected, (state) => {
        state.seasonMatchPlayersStatisticAddResponseStatus = StatusEnum.Failed;
      });
  },
});

export default seasonMatchPlayersStatisticAddSlice.reducer;
