import {
  AppBox,
  AppGridBox,
  AppSkeletonGlobalCardList,
  AppSkeletonNewsList,
  AppText,
  AppTitle,
} from "../../../commons/components";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import TournamentTeamCard from "./components/TournamentTeamCard";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { useEffect } from "react";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import {
  IFootballCompetitionTeamsOption,
  fetchCompetitionTeamList,
} from "../../../redux/slices/football/public/competition/football-competition-teams-slice";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";

export default function TournamentTeams() {
  const dispatch = useAppDispatch();
  const { newsList } = useAppSelector((state) => state.news);
  const { competition } = useAppSelector((state) => state.footballCompetition);
  const { seasonList } = useAppSelector((state) => state.footballSeason);
  const { competitionTeamList, competitionTeamListStatus } = useAppSelector((state) => state.footballCompetitionTeams);
  const { selectedGlobalSeasonOption } = useAppSelector((state) => state.globalSeasonSelect);

  useEffect(() => {
    if (competition.data.id && selectedGlobalSeasonOption.value) {
      const competitionTeamOption: IFootballCompetitionTeamsOption = {
        competitionId: competition.data.id,
        seasonId: Number(selectedGlobalSeasonOption.value),
      };
      dispatch(fetchCompetitionTeamList(competitionTeamOption));
    }
  }, [selectedGlobalSeasonOption]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  return (
    <AppBox className="page-content" gap="md">
      <AppBox displayBlock={true}>
        {seasonList.length > 0 ? (
          <>
            {competitionTeamListStatus === StatusEnum.Loading ? (
              <>
                <AppSkeletonGlobalCardList numberOfItems={20} withAvatar withGrid />
              </>
            ) : (
              <>
                {competitionTeamListStatus === StatusEnum.Succeeded && (
                  <AppBox flexDirection="column" gap="xs">
                    <AppTitle as="h5">Associated Teams</AppTitle>
                    {competitionTeamList.length > 0 ? (
                      <AppGridBox className="associated-team" columns={2} gap="sm">
                        {competitionTeamList.mapArray((item) => (
                          <TournamentTeamCard key={item.id} team={item.team} />
                        ))}
                      </AppGridBox>
                    ) : (
                      <AppText>The is no teams in current season</AppText>
                    )}
                  </AppBox>
                )}
              </>
            )}
          </>
        ) : (
          <AppText>The season has either ended or not yet started.</AppText>
        )}
      </AppBox>
      {newsList.status === (StatusEnum.Loading || StatusEnum.Idle) ? (
        <AppSkeletonNewsList />
      ) : (
        <NewsTimelineCard newsList={newsList.data} timelineType="small" />
      )}
    </AppBox>
  );
}
