import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../../commons";
import { IMatchCommentaryData } from "../../../../../../../api-services/football/admin/manage-football-season-service";

interface ISeasonMatchCommentaryEdit {
  seasonMatchCommentaryEditResponseStatus: StatusEnum;
}

const initialState: ISeasonMatchCommentaryEdit = {
  seasonMatchCommentaryEditResponseStatus: StatusEnum.Idle,
};

export interface IFootballSeasonMatchCommentaryEditOptions {
  seasonId: number;
  matchId: number;
  commentaryId: number;
  matchCommentaryData: IMatchCommentaryData;
}

export const seasonMatchCommentaryEdit = createAsyncThunk(
  "manage/seasonMatchCommentaryEdit",
  async (options: IFootballSeasonMatchCommentaryEditOptions, thunkAPI) => {
    try {
      const seasonMatchCommentaryEditResponse = await manageFootballSeasonService.editSeasonMatchCommentary(
        options.seasonId,
        options.matchId,
        options.commentaryId,
        options.matchCommentaryData
      );
      return seasonMatchCommentaryEditResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonMatchCommentaryEditSlice = createSlice({
  name: "seasonMatchCommentaryEdit",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonMatchCommentaryEdit.pending, (state) => {
        state.seasonMatchCommentaryEditResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonMatchCommentaryEdit.fulfilled, (state, action) => {
        state.seasonMatchCommentaryEditResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonMatchCommentaryEdit.rejected, (state) => {
        state.seasonMatchCommentaryEditResponseStatus = StatusEnum.Failed;
      });
  },
});

export default seasonMatchCommentaryEditSlice.reducer;
