import {
  AppAvatar,
  AppBox,
  AppDateAndTime,
  AppIconButton,
  AppLink,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import IconDismiss from "../../../../commons/components/icons/dismiss";
import IconProhibited from "../../../../commons/components/icons/prohibited";
import { TeamStatusEnum } from "../../../../commons/enums/team-status-enum";
import { IFootballTeam } from "../../../../commons/models/football/interface/i-football-team";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface TournamentTeamCardProps {
  team: IFootballTeam;
  onRemoveTeam: (team: IFootballTeam) => void;
  withOutRemove?: boolean;
}

const TournamentTeamCard = ({ team, withOutRemove, onRemoveTeam }: TournamentTeamCardProps) => {
  const handleTeamRemoveClick = () => {
    onRemoveTeam(team);
  };
  const teamPath = `/manage/teams/${team.id}`;
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <AppBox alignItems="center" gap="sm">
      <AppPaper padding="xs" withBorder className="flex-1">
        <AppBox justifyContent="space-between" alignItems="center">
          <AppLink to={teamPath} onClick={handleClick}>
            <AppBox gap="xs" alignItems="center">
              <AppAvatar username={team.name} src={team.logo || ""} size="md" />
              <AppBox flexDirection="column">
                <AppTitle as="h6">{toTitleCase(team.shortName)}</AppTitle>
                {team.founded && (
                  <AppBox gap="3xs" alignItems="center">
                    <AppText as="span" fontWeight="semibold" size="sm" color="muted">
                      Foundation Date:
                    </AppText>
                    <AppDateAndTime date={team.founded} format="DD MMM, YYYY" size="sm" color="muted" />
                  </AppBox>
                )}
              </AppBox>
            </AppBox>
          </AppLink>
          {team.status === Number(TeamStatusEnum.Terminated) && (
            <AppBox alignItems="center" gap="2xs">
              <IconProhibited color="var(--fg-outline-danger)" />
              <AppText>Terminated</AppText>
            </AppBox>
          )}
          {withOutRemove || (
            <AppIconButton icon={<IconDismiss />} variant="light" color="danger" onClick={handleTeamRemoveClick} />
          )}
        </AppBox>
      </AppPaper>
    </AppBox>
  );
};

export default TournamentTeamCard;
