import { useEffect, useRef, useState } from "react";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppDateAndTime,
  AppGeneralSearch,
  AppIconButton,
  AppLink,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import {
  cleanUpTeamList,
  fetchManageTeamList,
} from "../../../../redux/slices/football/admin/team/manage-team-list-slice";
import { ManageFootballTeam } from "../../../../commons/models/football/admin/manage-football-team";
import IconMultiselect from "../../../../commons/components/icons/multiselect";
import { fetchCompetitionSeasonTeamList } from "../../../../redux/slices/football/admin/competition/manage-competition-season-teams-slice";
import { cleanUpCompetitionSeasonTeams } from "../../../../redux/slices/football/admin/competition/manage-competition-season-teams-slice";
import isNullOrUndefined from "../../../../commons/utilities/null-or-undefined";
import { IFootballSeasonGroup } from "../../../../commons/models/football/admin/interface/i-manage-football-season-group";
import { TeamStatusEnum } from "../../../../commons/enums/team-status-enum";
import { FootballCompetitionTeam } from "../../../../commons/models/football/football-competition-team";
import { isLastIndex } from "../../../../commons/utilities/array-utils";
import { cleanUpGeneralSearchTitle, setGeneralCleanSearchField } from "../../../../redux/slices/general-search-slice";
import { toTitleCase } from "../../../../commons/utilities/string-utils";
import { StatusEnum } from "../../../../commons/enums/status-enum";

/**
 * Provide competition and season to search the team of that competition only
 */

interface TeamSearchProps {
  onSelectedTeam?: (teams: ManageFootballTeam[]) => void;
  withoutMultiselect?: boolean;
  competitionId?: number;
  seasonId?: number;
  fixtureGroup?: IFootballSeasonGroup;
  userSelectedTeams?: ManageFootballTeam[];
  userSelectedCompetitionTeams?: FootballCompetitionTeam[];
}
export default function TeamSearch({
  onSelectedTeam,
  withoutMultiselect,
  competitionId,
  seasonId,
  userSelectedTeams,
  userSelectedCompetitionTeams,
}: TeamSearchProps) {
  const dispatch = useAppDispatch();
  const multiSelectRef = useRef<HTMLInputElement>(null);
  const [isMultiSelect, setMultiSelect] = useState(false);
  const [isUncheckAll, setUncheckAll] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState<ManageFootballTeam[]>([]);
  const [filteredTeams, setFilteredTeams] = useState<ManageFootballTeam[]>([]);
  const [filteredCompetitionTeams, setFilteredCompetitionTeams] = useState<FootballCompetitionTeam[]>([]);
  const { competitionSeasonTeamList } = useAppSelector((state) => state.footballManageCompetitionSeasonTeamList);
  const { competitionSeasonTeamAddResponseStatus } = useAppSelector(
    (state) => state.footballManageCompetitionSeasonTeamAdd
  );
  const { teamList } = useAppSelector((state) => state.footballManageTeamList);
  const { generalSearchTitle } = useAppSelector((state) => state.generalSearch);

  useEffect(() => {
    dispatch(setGeneralCleanSearchField(true));
    dispatch(cleanUpGeneralSearchTitle());
  }, []);

  useEffect(() => {
    handleDefaultTeamFilter();
  }, [teamList, competitionSeasonTeamList, userSelectedTeams, userSelectedCompetitionTeams]);

  useEffect(() => {
    if (isUncheckAll) {
      setSelectedTeams([]);
      setUncheckAll(false);
    }
  }, [isUncheckAll]);

  const handleDefaultTeamFilter = () => {
    const selectedIds = new Set();
    if (userSelectedTeams && userSelectedTeams.length > 0) {
      userSelectedTeams.forEach((team) => selectedIds.add(team.id));
    }
    if (userSelectedCompetitionTeams && userSelectedCompetitionTeams.length > 0) {
      userSelectedCompetitionTeams.forEach((competitionTeam) => selectedIds.add(competitionTeam.team.id));
    }
    if (teamList.length > 0) {
      let filteredTeams = [];
      if (selectedIds) {
        filteredTeams = teamList.all().filter((team) => !selectedIds.has(team.id));
      } else {
        filteredTeams = teamList.all();
      }
      setFilteredTeams(filteredTeams);
    } else {
      setFilteredTeams([]);
    }
    if (competitionSeasonTeamList.length > 0) {
      let filteredTeams = [];
      if (selectedIds) {
        filteredTeams = competitionSeasonTeamList
          .all()
          .filter((competitionTeam) => !selectedIds.has(competitionTeam.team.id));
      } else {
        filteredTeams = competitionSeasonTeamList.all();
      }
      setFilteredCompetitionTeams(filteredTeams);
    } else {
      setFilteredCompetitionTeams([]);
    }
  };

  const handleTeamClick = (team: ManageFootballTeam) => {
    if (onSelectedTeam) {
      onSelectedTeam([team]);
    }
  };

  const handleMultiSelectClick = (e: any) => {
    e.preventDefault();
    multiSelectRef.current?.click();
  };

  const handleTeamSelection = (e: any, team: ManageFootballTeam) => {
    if (e.target.checked) {
      setSelectedTeams([...selectedTeams, team]);
    } else {
      setSelectedTeams(selectedTeams.filter((t) => t.id !== team.id));
    }
  };

  const handleSaveTeams = () => {
    if (onSelectedTeam) {
      onSelectedTeam(selectedTeams);
    }
  };

  useEffect(() => {
    const filterParams = {
      sort: "firstName",
      name: generalSearchTitle,
      status: TeamStatusEnum.Active,
      page: 0,
    };
    if (competitionId && seasonId) {
      dispatch(cleanUpCompetitionSeasonTeams());
      dispatch(
        fetchCompetitionSeasonTeamList({
          competitionId: competitionId,
          seasonId: seasonId,
          urlParams: filterParams,
        })
      );
    } else {
      dispatch(cleanUpTeamList());
      dispatch(fetchManageTeamList(filterParams));
    }
  }, [generalSearchTitle]);

  return (
    <AppBox flexDirection="column" gap="md">
      <AppBox flexDirection="column" gap="sm">
        <AppBox gap="xs">
          <AppBox className="w-100 flex-1">
            <AppGeneralSearch />
          </AppBox>
          {withoutMultiselect ?? (
            <>
              <AppIconButton
                icon={<IconMultiselect />}
                variant="light"
                onClick={(e) => handleMultiSelectClick(e)}
                color={isMultiSelect ? "primary" : "gray"}
                borderLight
              />
              <input
                type="checkbox"
                hidden
                ref={multiSelectRef}
                checked={isMultiSelect}
                onChange={(e) => {
                  setMultiSelect(e.target.checked);
                  setSelectedTeams([]);
                }}
              />
            </>
          )}
        </AppBox>
        <AppBox flexDirection="column" style={{ height: "25rem", overflowY: "scroll" }}>
          {isNullOrUndefined(competitionId) ? (
            <>
              {filteredTeams &&
                filteredTeams.map((team, index) => (
                  <AppBorderBox border={isLastIndex(index, filteredTeams) ? [] : ["Bottom"]} py="xs" key={index}>
                    {isMultiSelect ? (
                      <AppBox justifyContent="space-between" alignItems="center" pr="xs" className="flex-1">
                        <AppBox gap="xs" alignItems="center">
                          <AppAvatar username={team.name} src={team.logo} size="md" />
                          <AppBox flexDirection="column">
                            <AppTitle as="h6">{toTitleCase(team.shortName)}</AppTitle>
                            {team.founded && (
                              <AppBox gap="3xs" alignItems="center">
                                <AppText as="span" fontWeight="semibold" size="sm" color="muted">
                                  Foundation Date:
                                </AppText>
                                <AppDateAndTime date={team.founded} format="DD MMM, YYYY" size="sm" color="muted" />
                              </AppBox>
                            )}
                          </AppBox>
                        </AppBox>
                        <label className="ee-checkbox">
                          <input
                            type="checkbox"
                            checked={selectedTeams.includes(team)}
                            onChange={(e) => {
                              handleTeamSelection(e, team);
                            }}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </AppBox>
                    ) : (
                      <AppLink onClick={() => handleTeamClick(team)}>
                        <AppBox justifyContent="space-between" alignItems="center" className="flex-1">
                          <AppBox gap="xs" alignItems="center">
                            <AppAvatar username={team.name} src={team.logo} size="sm" />
                            <AppBox flexDirection="column">
                              <AppTitle as="h6">{toTitleCase(team.shortName)}</AppTitle>
                              {team.founded && (
                                <AppBox gap="3xs" alignItems="center">
                                  <AppText as="span" fontWeight="semibold" size="sm" color="muted">
                                    Foundation Date:
                                  </AppText>
                                  <AppDateAndTime date={team.founded} format="DD MMM, YYYY" size="sm" color="muted" />
                                </AppBox>
                              )}
                            </AppBox>
                          </AppBox>
                        </AppBox>
                      </AppLink>
                    )}
                  </AppBorderBox>
                ))}
              {filteredTeams.length === 0 && <AppText>There are not any team</AppText>}
            </>
          ) : (
            <>
              {
                <>
                  {filteredCompetitionTeams &&
                    filteredCompetitionTeams.map((seasonTeam, index) => (
                      <AppBorderBox
                        border={isLastIndex(index, filteredCompetitionTeams) ? [] : ["Bottom"]}
                        py="xs"
                        key={index}
                      >
                        <AppLink onClick={() => handleTeamClick(seasonTeam.team as ManageFootballTeam)}>
                          <AppBox justifyContent="space-between" alignItems="center" className="flex-1">
                            <AppBox gap="xs" alignItems="center">
                              <AppAvatar username={seasonTeam.team.name} src={seasonTeam.team.logo || ""} size="sm" />
                              <AppBox flexDirection="column">
                                <AppTitle as="h6">{toTitleCase(seasonTeam.team.shortName)}</AppTitle>
                                {seasonTeam.team.founded && (
                                  <AppBox gap="3xs" alignItems="center">
                                    <AppText as="span" fontWeight="semibold" size="sm" color="muted">
                                      Foundation Date:
                                    </AppText>
                                    <AppDateAndTime
                                      date={seasonTeam.team.founded}
                                      format="DD MMM, YYYY"
                                      size="sm"
                                      color="muted"
                                    />
                                  </AppBox>
                                )}
                              </AppBox>
                            </AppBox>
                          </AppBox>
                        </AppLink>
                      </AppBorderBox>
                    ))}
                  {filteredCompetitionTeams.length === 0 && <AppText>There are not any team</AppText>}
                </>
              }
            </>
          )}
        </AppBox>
      </AppBox>
      {isMultiSelect && (
        <AppBox justifyContent="end" gap="xs">
          <AppButton
            variant="outline"
            borderLight
            label="Clear"
            color="gray"
            onClick={() => setUncheckAll(true)}
            disabled={competitionSeasonTeamAddResponseStatus === StatusEnum.Loading}
          />
          <AppButton
            label={"Add Teams"}
            onClick={handleSaveTeams}
            disabled={filteredTeams.length === 0}
            loading={competitionSeasonTeamAddResponseStatus === StatusEnum.Loading}
          />
        </AppBox>
      )}
    </AppBox>
  );
}
