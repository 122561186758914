import React, { useEffect, useState } from "react";
import { AppBox, AppButton, AppGridBox, AppSelect, AppText } from "../../../../../commons/components";
import { Controller, useForm } from "react-hook-form";
import { findInputError, isFormInvalid } from "../../../../../commons/utilities/form-utils";
import { ISelectOptions } from "../../../../../commons/components/ui-components/Select";
import { findIndexByCondition } from "../../../../../commons/utilities/array-utils";
import { FieldErrors, FieldValues } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import { setStepperFormData } from "../../../../../redux/slices/stepper-form-slice";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { IFootballCompetition } from "../../../../../commons/models/football/interface/i-football-competition";
import { TournamentFormEnum } from "../../../../../commons/enums/tournament-form-enum";
import { TournamentSkillEnum } from "../../../../../commons/enums/tournament-skill-enum";
import { TournamentTypeEnum } from "../../../../../commons/enums/tournament-type-enum";
import { GenderEnum } from "../../../../../commons/enums/gender-enum";
import { TournamentAgeGroupEnum } from "../../../../../commons/enums/tournament-age-group-enum";
import { ICompetitionDTO } from "../../../../../api-services/football/admin/manage-football-competition-service";
import { toSentenceCase } from "../../../../../commons/utilities/string-utils";
interface TournamentSpecificsAddEditProps {
  competitionData?: IFootballCompetition | null;
  formStep: number;
  formErrors: FieldErrors<FieldValues>;
  submitStatus: StatusEnum;
  onBack: (currentStep: number) => void;
  onSubmit: (currentStep: number) => void;
  onClearError: (errorKey: string) => void;
}

const TournamentSpecificsAddEdit = ({
  competitionData,
  formStep,
  formErrors,
  submitStatus,
  onBack,
  onSubmit,
  onClearError,
}: TournamentSpecificsAddEditProps) => {
  const methods = useForm();
  const dispatch = useAppDispatch();
  const formData = useAppSelector<ICompetitionDTO>((state) => state.stepperForm.stepperFormData);
  const [tournamentSkillIndex, setTournamentSkillIndex] = useState<number>(0);
  const [tournamentAgeGroupIndex, setTournamentAgeGroupIndex] = useState<number>(0);
  const [tournamentGenderIndex, setTournamentGenderIndex] = useState<number>(0);
  const [tournamentTypeIndex, setTournamentTypeIndex] = useState<number>(0);
  const [tournamentFormIndex, setTournamentFormIndex] = useState<number>(0);

  const tournamentFormOptions: ISelectOptions[] = [
    {
      title: "11 vs 11",
      value: TournamentFormEnum.Form11,
    },
    {
      title: "7 vs 7",
      value: TournamentFormEnum.Form7,
    },
  ];
  const tournamentSkillOptions: ISelectOptions[] = [
    {
      title: "Competitive",
      value: TournamentSkillEnum.Competitive,
    },
    {
      title: "Casual",
      value: TournamentSkillEnum.Casual,
    },
  ];
  const tournamentTypeOptions: ISelectOptions[] = [
    {
      title: "League",
      value: TournamentTypeEnum.League,
    },
    {
      title: "Knockout",
      value: TournamentTypeEnum.Knockout,
    },
    {
      title: "Hybrid",
      value: TournamentTypeEnum.Hybrid,
    },
  ];
  const tournamentGenderOptions: ISelectOptions[] = [
    {
      title: "Men's",
      value: GenderEnum.Male,
    },
    {
      title: "Women's",
      value: GenderEnum.Female,
    },
  ];
  const tournamentAgeGroupOptions: ISelectOptions[] = [
    {
      title: "U18",
      value: TournamentAgeGroupEnum.UnderEighteen,
    },
    {
      title: "U20",
      value: TournamentAgeGroupEnum.UnderTwenty,
    },
    {
      title: "Senior",
      value: TournamentAgeGroupEnum.Senior,
    },
  ];

  const convertValue = (key: string, value: string): any => {
    const numericKeys = ["weight", "height", "status"];
    return numericKeys.includes(key) ? Number(value) : value;
  };

  const handleInputChange = (value: string, key: string) => {
    const competitionData: ICompetitionDTO = {
      ...formData,
      [key]: convertValue(key, value),
    };
    dispatch(setStepperFormData<ICompetitionDTO>(competitionData));
    onClearError(key);
  };

  useEffect(() => {
    if (!competitionData) {
      const stadiumData: ICompetitionDTO = {
        ...formData,
        type: tournamentTypeOptions[0].value.toString(),
        skillLevel: tournamentSkillOptions[0].value.toString(),
        teamForm: tournamentFormOptions[0].value.toString(),
        teamGender: Number(tournamentGenderOptions[0].value),
        ageGroup: tournamentAgeGroupOptions[0].value.toString(),
      };
      dispatch(setStepperFormData<ICompetitionDTO>(stadiumData));
    }
  }, []);

  useEffect(() => {
    if (competitionData) {
      let tournamentFormIndex = findIndexByCondition(
        tournamentFormOptions,
        (option) => option.value === competitionData.teamForm
      );
      if (tournamentFormIndex >= 0) {
        setTournamentFormIndex(tournamentFormIndex);
      }
      methods.setValue("teamForm", `${competitionData.teamForm}`);

      let tournamentSkillIndex = findIndexByCondition(
        tournamentSkillOptions,
        (option) => option.value === competitionData.skillLevel
      );
      if (tournamentSkillIndex >= 0) {
        setTournamentSkillIndex(tournamentSkillIndex);
      }
      methods.setValue("skillLevel", `${competitionData.skillLevel}`);

      let tournamentTypeIndex = findIndexByCondition(
        tournamentTypeOptions,
        (option) => option.value === competitionData.type
      );
      if (tournamentTypeIndex >= 0) {
        setTournamentTypeIndex(tournamentTypeIndex);
      }
      methods.setValue("type", `${competitionData.type}`);

      let tournamentGenderIndex = findIndexByCondition(
        tournamentGenderOptions,
        (option) => option.value === competitionData.teamGender?.toString()
      );
      if (tournamentGenderIndex >= 0) {
        setTournamentGenderIndex(tournamentGenderIndex);
      }
      methods.setValue("teamGender", competitionData.teamGender?.toString());

      let tournamentAgeGroupIndex = findIndexByCondition(
        tournamentAgeGroupOptions,
        (option) => option.value === competitionData.ageGroup
      );
      if (tournamentAgeGroupIndex >= 0) {
        setTournamentAgeGroupIndex(tournamentAgeGroupIndex);
      }
      methods.setValue("ageGroup", `${competitionData.ageGroup}`);
    }
  }, [competitionData]);

  return (
    <AppBox flexDirection="column" justifyContent="space-between" gap="sm" className="flex-1">
      <AppBox flexDirection="column" gap="sm" className="w-100">
        <Controller
          name="type"
          defaultValue={
            competitionData ? tournamentTypeOptions[tournamentTypeIndex].value : tournamentTypeOptions[0].value
          }
          control={methods.control}
          render={({ field: { onChange } }) => (
            <AppBox flexDirection="column">
              <AppGridBox className="w-100 flex-1" style={{ gridTemplateColumns: "1fr 2fr" }} gap="sm">
                <AppBox alignItems="center">
                  <AppText as="label" size="lg">
                    Tournament Type *
                  </AppText>
                </AppBox>
                <AppSelect
                  options={tournamentTypeOptions}
                  onChange={(option) => {
                    onChange(option.value);
                    setTournamentTypeIndex(
                      findIndexByCondition(tournamentTypeOptions, (footOption) => footOption.value === option.value)
                    );
                    handleInputChange(option.value.toString(), "type");
                  }}
                  currentOption={tournamentTypeOptions[tournamentTypeIndex]}
                />
              </AppGridBox>
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "type")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.type?.message}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="skillLevel"
          defaultValue={
            competitionData ? tournamentSkillOptions[tournamentSkillIndex].value : tournamentSkillOptions[0].value
          }
          control={methods.control}
          render={({ field: { onChange } }) => (
            <AppBox flexDirection="column">
              <AppGridBox className="w-100 flex-1" style={{ gridTemplateColumns: "1fr 2fr" }} gap="sm">
                <AppBox alignItems="center">
                  <AppText as="label" size="lg">
                    Tournament Nature
                  </AppText>
                </AppBox>
                <AppSelect
                  options={tournamentSkillOptions}
                  onChange={(option) => {
                    onChange(option.value);
                    setTournamentSkillIndex(
                      findIndexByCondition(tournamentSkillOptions, (footOption) => footOption.value === option.value)
                    );
                    handleInputChange(option.value.toString(), "skillLevel");
                  }}
                  currentOption={tournamentSkillOptions[tournamentSkillIndex]}
                />
              </AppGridBox>
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "skillLevel")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.skillLevel?.message}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="teamForm"
          defaultValue={
            competitionData ? tournamentFormOptions[tournamentFormIndex].value : tournamentFormOptions[0].value
          }
          control={methods.control}
          render={({ field: { onChange } }) => (
            <AppBox flexDirection="column">
              <AppGridBox className="w-100 flex-1" style={{ gridTemplateColumns: "1fr 2fr" }} gap="sm">
                <AppBox alignItems="center">
                  <AppText as="label" size="lg">
                    Team Formation
                  </AppText>
                </AppBox>
                <AppSelect
                  options={tournamentFormOptions}
                  onChange={(option) => {
                    onChange(option.value);
                    setTournamentFormIndex(
                      findIndexByCondition(tournamentFormOptions, (footOption) => footOption.value === option.value)
                    );
                    handleInputChange(option.value.toString(), "teamForm");
                  }}
                  currentOption={tournamentFormOptions[tournamentFormIndex]}
                />
              </AppGridBox>
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "teamForm")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.teamForm?.message}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="teamGender"
          defaultValue={
            competitionData ? tournamentGenderOptions[tournamentGenderIndex].value : tournamentGenderOptions[0].value
          }
          control={methods.control}
          render={({ field: { onChange } }) => (
            <AppBox flexDirection="column">
              <AppGridBox className="w-100 flex-1" style={{ gridTemplateColumns: "1fr 2fr" }} gap="sm">
                <AppBox alignItems="center">
                  <AppText as="label" size="lg">
                    Gender
                  </AppText>
                </AppBox>
                <AppSelect
                  options={tournamentGenderOptions}
                  onChange={(option) => {
                    onChange(option.value);
                    setTournamentGenderIndex(
                      findIndexByCondition(tournamentGenderOptions, (footOption) => footOption.value === option.value)
                    );
                    handleInputChange(option.value.toString(), "teamGender");
                  }}
                  currentOption={tournamentGenderOptions[tournamentGenderIndex]}
                />
              </AppGridBox>
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "teamGender")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.teamGender?.message}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="ageGroup"
          defaultValue={
            competitionData
              ? tournamentAgeGroupOptions[tournamentAgeGroupIndex].value
              : tournamentAgeGroupOptions[0].value
          }
          control={methods.control}
          render={({ field: { onChange } }) => (
            <AppBox flexDirection="column">
              <AppGridBox className="w-100 flex-1" style={{ gridTemplateColumns: "1fr 2fr" }} gap="sm">
                <AppBox alignItems="center">
                  <AppText as="label" size="lg">
                    Age Group
                  </AppText>
                </AppBox>
                <AppSelect
                  options={tournamentAgeGroupOptions}
                  onChange={(option) => {
                    onChange(option.value);
                    setTournamentAgeGroupIndex(
                      findIndexByCondition(tournamentAgeGroupOptions, (footOption) => footOption.value === option.value)
                    );
                    handleInputChange(option.value.toString(), "ageGroup");
                  }}
                  currentOption={tournamentAgeGroupOptions[tournamentAgeGroupIndex]}
                />
              </AppGridBox>
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "ageGroup")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.ageGroup?.message}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
      </AppBox>
      <AppBox gap="xs" justifyContent="end">
        <AppButton
          label="Back"
          variant="outline"
          color="gray"
          borderLight
          onClick={() => {
            onBack(formStep);
          }}
          disabled={submitStatus === StatusEnum.Loading}
        />
        <AppButton
          type="submit"
          label={competitionData ? "Save Changes" : "Submit"}
          onClick={() => {
            onSubmit(formStep);
          }}
          loading={submitStatus === StatusEnum.Loading}
        />
      </AppBox>
    </AppBox>
  );
};

export default TournamentSpecificsAddEdit;
