import { AppAvatar, AppBox, AppLink, AppPaper, AppText, AppTitle } from "../../../../commons/components";
import { IFootballTeam } from "../../../../commons/models/football/interface/i-football-team";

interface TeamTopStatsProps {
  team: IFootballTeam;
  rank: number;
  statValue: string;
}

export default function TeamTopStatsCard({ team, rank, statValue }: TeamTopStatsProps) {
  const formattedRank = rank.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });
  const handlePlayerClick = () => {
    window.scrollTo(0, 0);
  };
  const teamPath = `/teams/${team.id}`;
  return (
    <AppLink to={teamPath} onClick={handlePlayerClick}>
      <AppPaper radius="md" padding="xs" shadow="xs">
        <AppBox alignItems="center" gap="xs">
          <AppText as="label" size="sm" color="muted" className="min-w-2">
            {formattedRank}
          </AppText>
          <AppAvatar username={team.name} src={team.logo || ""} />
          <AppBox alignItems="center" className="flex-1">
            <AppTitle as={"h5"}>{team.name}</AppTitle>
          </AppBox>
          <AppTitle as={"h5"} color="primary">
            {statValue}
          </AppTitle>
        </AppBox>
      </AppPaper>
    </AppLink>
  );
}
