import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballPlayerService } from "../../../../../commons";
import { FootballAttribute } from "../../../../../commons/models/football/football-attribute";
import { EnsueCollection } from "../../../../../ensue-react-system/utilities/ensue-collection";

export interface IManagePlayerListState {
  playerAttributeList: EnsueCollection<FootballAttribute>;
  playerAttributeListStatus: StatusEnum;
}

const initialState: IManagePlayerListState = {
  playerAttributeList: new EnsueCollection<FootballAttribute>(),
  playerAttributeListStatus: StatusEnum.Idle,
};

export const fetchManagePlayerAttributeList = createAsyncThunk("manage/playerAttributeList", async (_, thunkAPI) => {
  try {
    const playerAttributeList = await manageFootballPlayerService.getPlayerAttributeList();
    return playerAttributeList as EnsueCollection<FootballAttribute>;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const footballManagePlayerAttributeListSlice = createSlice({
  name: "footballManagePlayerAttributeList",
  initialState: initialState,
  reducers: {
    cleanUpPlayerAttributeList(state) {
      state.playerAttributeList = new EnsueCollection<FootballAttribute>();
      state.playerAttributeListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManagePlayerAttributeList.pending, (state) => {
        state.playerAttributeListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchManagePlayerAttributeList.fulfilled,
        (state, action: PayloadAction<EnsueCollection<FootballAttribute>>) => {
          state.playerAttributeListStatus = StatusEnum.Succeeded;
          state.playerAttributeList = action.payload;
        }
      )
      .addCase(fetchManagePlayerAttributeList.rejected, (state) => {
        state.playerAttributeListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpPlayerAttributeList } = footballManagePlayerAttributeListSlice.actions;
export default footballManagePlayerAttributeListSlice.reducer;
