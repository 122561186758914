import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FootballMatchStat } from "../../../../../../../commons/models/football/football-match-stats";
import { StatusEnum } from "../../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../../commons";

export interface IFootballStatisticsState {
  seasonMatchStatistics: FootballMatchStat;
  seasonMatchStatisticsStatus: StatusEnum;
}

const initialState: IFootballStatisticsState = {
  seasonMatchStatistics: new FootballMatchStat(),
  seasonMatchStatisticsStatus: StatusEnum.Idle,
};
export interface IManageSeasonMatchStatisticOption {
  seasonId: number;
  matchId: number;
}

export const fetchSeasonMatchStatistic = createAsyncThunk(
  "matches/fetchSeasonMatchStatistic",
  async (option: IManageSeasonMatchStatisticOption, thunkAPI) => {
    try {
      const matchStats = await manageFootballSeasonService.getMatchStatistics(option.seasonId, option.matchId);
      return matchStats as FootballMatchStat;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageSeasonMatchStatisticSlice = createSlice({
  name: "footballSeasonMatchStatistics",
  initialState: initialState,
  reducers: {
    cleanUpSeasonMatchStats(state) {
      state.seasonMatchStatistics = new FootballMatchStat();
      state.seasonMatchStatisticsStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSeasonMatchStatistic.pending, (state) => {
        state.seasonMatchStatisticsStatus = StatusEnum.Loading;
      })
      .addCase(fetchSeasonMatchStatistic.fulfilled, (state, action: PayloadAction<FootballMatchStat>) => {
        state.seasonMatchStatisticsStatus = StatusEnum.Succeeded;
        state.seasonMatchStatistics = action.payload;
      })
      .addCase(fetchSeasonMatchStatistic.rejected, (state) => {
        state.seasonMatchStatisticsStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpSeasonMatchStats } = manageSeasonMatchStatisticSlice.actions;
export default manageSeasonMatchStatisticSlice.reducer;
