import { AppBox, AppDateAndTime, AppDivider, AppPaper, AppText } from "../../../../commons/components";
import IconCalendarClock from "../../../../commons/components/icons/calendar-clock";
import IconCLock from "../../../../commons/components/icons/clock";
import IconLocation from "../../../../commons/components/icons/location";
import IconPeopleTeam from "../../../../commons/components/icons/people-team";
import IconWhistle from "../../../../commons/components/icons/whistle";
import { IFootballMatchDetail } from "../../../../commons/models/football/interface/i-football-match";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface ManageMatchInfoProps {
  match: IFootballMatchDetail;
}

export default function MatchManageInfoCard({ match }: ManageMatchInfoProps) {
  const headReferee = match.matchReferees
    .filter((referee) => referee.role === "HEAD")
    .map((headReferee) => headReferee.referee?.fullName)[0];
  return (
    <AppPaper shadow="xs" padding="xs">
      <AppBox flexDirection="column">
        {/* location */}
        <AppBox gap="2xs" alignItems="center">
          <AppBox gap="3xs" alignItems="center">
            <AppText as="span" color="accent">
              <IconLocation />
            </AppText>
            <AppText fontWeight="bold">Location:</AppText>
          </AppBox>
          <AppText>
            {match.stadium
              ? `${toTitleCase(match.stadium?.name)}, ${toTitleCase(match.stadium?.city || "")}`
              : "No stadium details."}
          </AppText>
        </AppBox>
        <AppDivider my="2xs" />
        {/* date */}
        <AppBox gap="2xs" alignItems="center">
          <AppBox gap="3xs" alignItems="center">
            <AppText as="span" color="accent">
              <IconCalendarClock />
            </AppText>
            <AppText fontWeight="bold">Date & Time:</AppText>
          </AppBox>
          {match.scheduledAt?.isValid() ? (
            <AppBox>
              <AppDateAndTime date={match.scheduledAt} format="D MMM, YYYY" />
              <AppDivider orientation="vertical" mx="3xs" />
              <AppDateAndTime date={match.scheduledAt} format="h:mm a" />
            </AppBox>
          ) : (
            <AppText>No schedule details</AppText>
          )}
        </AppBox>

        {/* referee */}
        <AppDivider my="2xs" />
        <AppBox gap="2xs" alignItems="center">
          <AppBox gap="3xs" alignItems="center">
            <AppText as="span" color="accent">
              <IconWhistle />
            </AppText>
            <AppText fontWeight="bold">Referee:</AppText>
          </AppBox>
          {match.matchReferees.length > 0 && headReferee ? (
            <AppText>{toTitleCase(headReferee)}</AppText>
          ) : (
            <AppText>No Referee Details</AppText>
          )}
        </AppBox>
        <AppDivider my="2xs" />
        <AppBox gap="2xs" alignItems="center">
          <AppBox gap="3xs" alignItems="center">
            <AppText as="span" color="accent">
              <IconCLock />
            </AppText>
            <AppText fontWeight="bold">Match Duration:</AppText>
          </AppBox>
          {match.gameplayDuration ? (
            <AppText>{match.gameplayDuration / 60} min</AppText>
          ) : (
            <AppText>No Match Duration Details</AppText>
          )}
        </AppBox>
      </AppBox>
    </AppPaper>
  );
}
