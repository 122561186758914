import {
  AppBox,
  AppButton,
  AppGridBox,
  AppSkeletonGlobalCardList,
  AppSkeletonListComponent,
  AppSkeletonStatistics,
  AppTitle,
} from "../../../commons/components";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import { MatchStatusEnum } from "../../../commons/enums/match-status-enum";
import { MatchTeamTypeEnum } from "../../../commons/enums/match-team-type-enum";
import MatchPenaltyList from "./components/penalty/MatchPenaltyLIst";
import {
  fetchSeasonMatchCommentaryList,
  IManageSeasonMatchCommentaryListOption,
} from "../../../redux/slices/football/admin/season/match/commentary/manage-season-match-commentary-list-slice";
import MatchUpcomingSummary from "../../public/match-detail/components/MatchUpcomingSummary";
import MatchCommentaryCard from "../../public/match-detail/components/MatchCommentaryCard";
import MatchStatisticsGroup from "../../public/match-detail/components/MatchStatisticsGroup";
import MatchManageInfoCard from "./components/MatchManageInfoCard";
import { fetchSeasonMatchStatistic } from "../../../redux/slices/football/admin/season/match/statistics/manage-season-match-statistics-slice";

export default function MatchDetailSummary() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { seasonMatchDetail, seasonMatchDetailStatus } = useAppSelector(
    (state) => state.footballManageSeasonMatchDetail
  );
  const { seasonMatchCommentaryList, seasonMatchCommentaryListStatus } = useAppSelector(
    (state) => state.footballManageSeasonMatchCommentaryList
  );
  const { seasonMatchStatistics, seasonMatchStatisticsStatus } = useAppSelector(
    (state) => state.footballManageSeasonMatchStatistic
  );

  const handleCommentaryClick = () => {
    navigate(
      `/manage/tournaments/${seasonMatchDetail.competition.id}/seasons/${seasonMatchDetail.season.id}/matches/${seasonMatchDetail.id}/commentary`
    );
    window.scrollTo(0, 0);
  };
  const handleStatisticClick = () => {
    navigate(
      `/manage/tournaments/${seasonMatchDetail.competition.id}/seasons/${seasonMatchDetail.season.id}/matches/${seasonMatchDetail.id}/statistics`
    );
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (seasonMatchDetail.status !== MatchStatusEnum.Upcoming && seasonMatchDetail.id) {
      let options: IManageSeasonMatchCommentaryListOption = {
        matchId: Number(seasonMatchDetail.id),
        seasonId: seasonMatchDetail.season.id,
        urlParams: {
          perPage: 10,
          sort: "gameTime",
          sortOrder: SortOrderEnum.Descending,
        },
      };
      dispatch(fetchSeasonMatchCommentaryList(options));
      dispatch(fetchSeasonMatchStatistic({ seasonId: seasonMatchDetail.season.id, matchId: seasonMatchDetail.id }));
    }
  }, [seasonMatchDetail]);

  return (
    <AppGridBox style={{ gridTemplateColumns: "2fr 1fr" }} gap="md" mb="sm">
      <AppBox flexDirection="column" gap="md">
        {seasonMatchDetail.status === MatchStatusEnum.Upcoming ? (
          <>
            <MatchUpcomingSummary isAdmin />
          </>
        ) : (
          <>
            {seasonMatchDetail.penalties.length > 0 && <MatchPenaltyList match={seasonMatchDetail} />}
            {seasonMatchCommentaryListStatus === StatusEnum.Loading ? (
              <AppSkeletonGlobalCardList numberOfItems={10} withText />
            ) : (
              <>
                {seasonMatchCommentaryList.length > 0 && (
                  <AppBox flexDirection="column" gap="xs">
                    <AppTitle as="h5">Match Commentary</AppTitle>
                    <AppBox flexDirection="column" gap="sm" pl="xs">
                      {seasonMatchCommentaryList.mapArray((commentaryItem, index) => (
                        <MatchCommentaryCard key={index} commentary={commentaryItem} match={seasonMatchDetail} />
                      ))}
                    </AppBox>
                  </AppBox>
                )}
              </>
            )}
            <AppBox flexDirection="column" gap="sm">
              {seasonMatchStatisticsStatus === StatusEnum.Loading ? (
                <AppSkeletonStatistics isMatch />
              ) : (
                <MatchStatisticsGroup match={seasonMatchDetail} matchStats={seasonMatchStatistics} />
              )}
            </AppBox>
          </>
        )}
      </AppBox>
      <AppBox flexDirection="column" gap="sm">
        {seasonMatchDetailStatus === StatusEnum.Loading ? (
          <AppSkeletonListComponent numberOfItems={4} />
        ) : (
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">Match Information</AppTitle>
            <MatchManageInfoCard match={seasonMatchDetail} />
          </AppBox>
        )}
      </AppBox>
    </AppGridBox>
  );
}
