import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISelectOptions } from "../../commons/components/ui-components/Select";
interface GlobalSeasonSelectState {
  selectedGlobalSeasonOption: ISelectOptions;
  globalSeasonOptions: ISelectOptions[];
}

const initialState: GlobalSeasonSelectState = {
  globalSeasonOptions: [],
  selectedGlobalSeasonOption: {
    title: "",
    value: 0,
    secondaryValue: undefined,
  },
};

export const GlobalSeasonSelectSlice = createSlice({
  name: "GlobalSeasonSelectSlice",
  initialState: initialState,
  reducers: {
    setGlobalSeasonSelectOptions: (state, action: PayloadAction<ISelectOptions[]>) => {
      state.globalSeasonOptions = action.payload;
    },
    setGlobalSeasonSelectedOption: (state, action: PayloadAction<ISelectOptions>) => {
      state.selectedGlobalSeasonOption = action.payload;
    },

    cleanUpGlobalSeasonSelectOptions(state) {
      state.globalSeasonOptions = [];
    },

    cleanUpGlobalSeasonSelectedOption(state) {
      state.selectedGlobalSeasonOption = {
        title: "",
        value: 0,
        secondaryValue: undefined,
      };
    },
  },
});

export const {
  setGlobalSeasonSelectOptions,
  setGlobalSeasonSelectedOption,
  cleanUpGlobalSeasonSelectOptions,
  cleanUpGlobalSeasonSelectedOption,
} = GlobalSeasonSelectSlice.actions;

export default GlobalSeasonSelectSlice.reducer;
