import {
  AppBox,
  AppButton,
  AppDatePicker,
  AppGridBox,
  AppInput,
  AppSelect,
  AppText,
} from "../../../../../commons/components";
import { Controller, useForm } from "react-hook-form";
import { findInputError, isFormInvalid } from "../../../../../commons/utilities/form-utils";
import moment from "moment";
import { FieldErrors, FieldValues } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import { setStepperFormData } from "../../../../../redux/slices/stepper-form-slice";
import { formatDate } from "../../../../../commons/utilities/date-utils";
import { IStadium } from "../../../../../commons/models/i-stadium";
import { IStadiumDTO } from "../../../../../api-services/football/admin/manage-stadium-service";
import { ISelectOptions } from "../../../../../commons/components/ui-components/Select";
import { CountryCodeEnum } from "../../../../../commons/enums/country-code-enum";
import { useEffect, useLayoutEffect, useState } from "react";
import { findIndexByCondition } from "../../../../../commons/utilities/array-utils";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { toSentenceCase } from "../../../../../commons/utilities/string-utils";

interface StadiumBasicInfoAddEditProps {
  stadiumData?: IStadium | null;
  formStep: number;
  formErrors: FieldErrors<FieldValues>;
  submitStatus: StatusEnum;
  onBack: (currentStep: number) => void;
  onNext: (currentStep: number) => void;
  onClearError: (errorKey: string) => void;
  onSaveAndExit: () => void;
}

const StadiumBasicInfoAddEdit = ({
  stadiumData,
  formStep,
  formErrors,
  submitStatus,
  onSaveAndExit,
  onBack,
  onNext,
  onClearError,
}: StadiumBasicInfoAddEditProps) => {
  const methods = useForm();
  const dispatch = useAppDispatch();
  const formData = useAppSelector<IStadiumDTO>((state) => state.stepperForm.stepperFormData);
  const [countryIndex, setCountryIndex] = useState<number>(0);
  const countryOptions: ISelectOptions[] = [
    {
      title: "United States",
      value: CountryCodeEnum.UnitedStates,
    },
    {
      title: "Great Briton",
      value: CountryCodeEnum.GreatBriton,
    },
    {
      title: "Canada",
      value: CountryCodeEnum.Canada,
    },
    {
      title: "Denmark",
      value: CountryCodeEnum.Denmark,
    },
    {
      title: "France",
      value: CountryCodeEnum.France,
    },
    {
      title: "Spain",
      value: CountryCodeEnum.Spain,
    },
    {
      title: "Nepal",
      value: CountryCodeEnum.Nepal,
    },
  ];

  useEffect(() => {
    if (!stadiumData && countryOptions) {
      const stadiumData: IStadiumDTO = {
        ...formData,
        country: countryOptions[0].value.toString(),
      };
      dispatch(setStepperFormData<IStadiumDTO>(stadiumData));
    }
  }, []);

  useLayoutEffect(() => {
    if (stadiumData) {
      let countryIndex = findIndexByCondition(countryOptions, (option) => option.value === stadiumData.country);
      if (countryIndex >= 0) {
        setCountryIndex(countryIndex);
      } else {
        setCountryIndex(0);
      }
      methods.setValue("country", `${stadiumData.country}`);
    }
  }, [stadiumData]);

  const convertValue = (key: string, value: string): any => {
    const numericKeys = ["status"];
    return numericKeys.includes(key) ? Number(value) : value;
  };

  const handleInputChange = (value: string, key: string) => {
    const stadiumData: IStadiumDTO = {
      ...formData,
      [key]: convertValue(key, value),
    };
    dispatch(setStepperFormData<IStadiumDTO>(stadiumData));
    onClearError(key);
  };

  const handleDateChange = (value?: string) => {
    if (value) {
      const stadiumData: IStadiumDTO = {
        ...formData,
        inaugurationAt: formatDate(moment(value)),
      };
      dispatch(setStepperFormData<IStadiumDTO>(stadiumData));
      onClearError("inaugurationAt");
    }
  };
  return (
    <AppBox flexDirection="column" justifyContent="space-between" gap="sm" className="flex-1">
      <AppBox flexDirection="column" gap="sm" className="w-100">
        <Controller
          name="name"
          defaultValue={stadiumData ? stadiumData.name : ""}
          control={methods.control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                id="name"
                label="Name *"
                placeholder="Enter venue's name"
                type="text"
                defaultValue={value}
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "name");
                }}
                flex="row"
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "name")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.name?.message ?? "Venue name is required."}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="inaugurationAt"
          control={methods.control}
          defaultValue={stadiumData?.inaugurationAt ? moment(stadiumData.inaugurationAt).toDate() : null}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column" gap="2xs">
              <AppGridBox className="w-100 flex-1" style={{ gridTemplateColumns: "1fr 2fr" }} gap="sm">
                <AppBox alignItems="center">
                  <AppText as="label" size="lg">
                    Inauguration Date
                  </AppText>
                </AppBox>
                <AppDatePicker
                  onChange={(event) => {
                    onChange(event);
                    handleDateChange(event?.toString());
                  }}
                  selected={value}
                  calenderPlacement="bottom-end"
                  maxDate={new Date()}
                />
              </AppGridBox>
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "inaugurationAt")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.inaugurationAt?.message}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="address"
          defaultValue={stadiumData ? stadiumData.address : ""}
          control={methods.control}
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                id="address"
                label="Address"
                placeholder="Enter venue's address e.g. 100 street"
                type="text"
                defaultValue={value}
                flex="row"
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "address");
                }}
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "address")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.address?.message}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="city"
          defaultValue={stadiumData ? stadiumData.city : ""}
          control={methods.control}
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                id="city"
                label="City"
                placeholder="Enter the city venue is located in"
                type="text"
                defaultValue={value}
                flex="row"
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "city");
                }}
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "city")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.city?.message}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="country"
          defaultValue={stadiumData ? stadiumData.country : ""}
          control={methods.control}
          rules={{ required: false }}
          render={({ field: { onChange } }) => (
            <AppBox flexDirection="column" gap="2xs">
              <AppGridBox className="w-100 flex-1" style={{ gridTemplateColumns: "1fr 2fr" }} gap="sm">
                <AppBox alignItems="center">
                  <AppText as="label" size="lg">
                    Country
                  </AppText>
                </AppBox>
                <AppSelect
                  options={countryOptions}
                  onChange={(option) => {
                    onChange(option.value);
                    setCountryIndex(
                      findIndexByCondition(countryOptions, (countryOption) => countryOption.value === option.value)
                    );
                    handleInputChange(option.value.toString(), "status");
                  }}
                  currentOption={countryOptions[countryIndex ?? 0]}
                />
              </AppGridBox>
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "country")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.country?.message}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
      </AppBox>
      <AppBox gap="xs" justifyContent="end">
        <AppButton
          label="Back"
          variant="outline"
          color="gray"
          borderLight
          onClick={() => {
            onBack(formStep);
          }}
          disabled={submitStatus === StatusEnum.Loading}
        />
        {stadiumData && (
          <AppButton
            label="Save & Exit"
            variant="light"
            onClick={() => {
              onSaveAndExit();
            }}
            loading={submitStatus === StatusEnum.Loading}
          />
        )}
        <AppButton
          label="Next"
          onClick={() => {
            onNext(formStep);
          }}
          disabled={submitStatus === StatusEnum.Loading}
        />
      </AppBox>
    </AppBox>
  );
};

export default StadiumBasicInfoAddEdit;
