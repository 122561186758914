import {
  AppAvatar,
  AppBox,
  AppDivider,
  AppDoughnutChart,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppProgressChart from "../../../../commons/components/ui-components/ProgressChart";
import { IFootballMatch } from "../../../../commons/models/football/interface/i-football-match";
import { IFootballMatchStat } from "../../../../commons/models/football/interface/i-football-match-stats";
import { MatchTeamTypeEnum } from "../../../../commons/enums/match-team-type-enum";

interface MatchInfoProps {
  match: IFootballMatch;
  matchStats: IFootballMatchStat;
}

export default function MatchStatisticsGroup({ match, matchStats }: MatchInfoProps) {
  const statValue = (statPosition: MatchTeamTypeEnum, valueA: number, valueB: number): number => {
    if (valueA === 0 && valueB === 0) {
      return 0;
    } else {
      let result: number;
      if (statPosition === MatchTeamTypeEnum.Home) {
        result = Math.floor((valueA / (valueA + valueB)) * 100);
      } else {
        result = Math.floor((valueB / (valueA + valueB)) * 100);
      }
      if (isNaN(result)) {
        return 0;
      }
      return result;
    }
  };

  const matchStatsItems = [
    {
      title: "Shots",
      homeValue: Number(matchStats.home?.shots) ?? 0,
      awayValue: Number(matchStats.away?.shots) ?? 0,
      chartValueLeft: statValue(
        MatchTeamTypeEnum.Home,
        Number(matchStats.home?.shots) ?? 0,
        Number(matchStats.away?.shots) ?? 0
      ),
      chartValueRight: statValue(
        MatchTeamTypeEnum.Away,
        Number(matchStats.home?.shots) ?? 0,
        Number(matchStats.away?.shots) ?? 0
      ),
    },
    {
      title: "Shots on Target",
      homeValue: Number(matchStats.home?.shotsOnTarget) ?? 0,
      awayValue: Number(matchStats.away?.shotsOnTarget) ?? 0,
      chartValueLeft: statValue(
        MatchTeamTypeEnum.Home,
        Number(matchStats.home?.shotsOnTarget) ?? 0,
        Number(matchStats.away?.shotsOnTarget) ?? 0
      ),
      chartValueRight: statValue(
        MatchTeamTypeEnum.Away,
        Number(matchStats.home?.shotsOnTarget) ?? 0,
        Number(matchStats.away?.shotsOnTarget) ?? 0
      ),
    },
    {
      title: "Chances Created",
      homeValue: Number(matchStats.home?.chancesCreated) ?? 0,
      awayValue: Number(matchStats.away?.chancesCreated) ?? 0,
      chartValueLeft: statValue(
        MatchTeamTypeEnum.Home,
        Number(matchStats.home?.chancesCreated) ?? 0,
        Number(matchStats.away?.chancesCreated) ?? 0
      ),
      chartValueRight: statValue(
        MatchTeamTypeEnum.Away,
        Number(matchStats.home?.chancesCreated) ?? 0,
        Number(matchStats.away?.chancesCreated) ?? 0
      ),
    },
    {
      title: "Passes",
      homeValue: Number(matchStats.home?.passesAttempted) ?? 0,
      awayValue: Number(matchStats.away?.passesAttempted) ?? 0,
      chartValueLeft: statValue(
        MatchTeamTypeEnum.Home,
        Number(matchStats.home?.passesAttempted) ?? 0,
        Number(matchStats.away?.passesAttempted) ?? 0
      ),
      chartValueRight: statValue(
        MatchTeamTypeEnum.Away,
        Number(matchStats.home?.passesAttempted) ?? 0,
        Number(matchStats.away?.passesAttempted) ?? 0
      ),
    },
    {
      title: "Crosses",
      homeValue: Number(matchStats.home?.crosses) ?? 0,
      awayValue: Number(matchStats.away?.crosses) ?? 0,
      chartValueLeft: statValue(
        MatchTeamTypeEnum.Home,
        Number(matchStats.home?.crosses) ?? 0,
        Number(matchStats.away?.crosses) ?? 0
      ),
      chartValueRight: statValue(
        MatchTeamTypeEnum.Away,
        Number(matchStats.home?.crosses) ?? 0,
        Number(matchStats.away?.crosses) ?? 0
      ),
    },
    {
      title: "Saves",
      homeValue: Number(matchStats.home?.saves) ?? 0,
      awayValue: Number(matchStats.away?.saves) ?? 0,
      chartValueLeft: statValue(
        MatchTeamTypeEnum.Home,
        Number(matchStats.home?.saves) ?? 0,
        Number(matchStats.away?.saves) ?? 0
      ),
      chartValueRight: statValue(
        MatchTeamTypeEnum.Away,
        Number(matchStats.home?.saves) ?? 0,
        Number(matchStats.away?.saves) ?? 0
      ),
    },
    {
      title: "Clearances",
      homeValue: Number(matchStats.home?.clearances) ?? 0,
      awayValue: Number(matchStats.away?.clearances) ?? 0,
      chartValueLeft: statValue(
        MatchTeamTypeEnum.Home,
        Number(matchStats.home?.clearances) ?? 0,
        Number(matchStats.away?.clearances) ?? 0
      ),
      chartValueRight: statValue(
        MatchTeamTypeEnum.Away,
        Number(matchStats.home?.clearances) ?? 0,
        Number(matchStats.away?.clearances) ?? 0
      ),
    },
    {
      title: "Yellow Cards",
      homeValue: Number(matchStats.home?.yellowCards) ?? 0,
      awayValue: Number(matchStats.away?.yellowCards) ?? 0,
      chartValueLeft: statValue(
        MatchTeamTypeEnum.Home,
        Number(matchStats.home?.yellowCards) ?? 0,
        Number(matchStats.away?.yellowCards) ?? 0
      ),
      chartValueRight: statValue(
        MatchTeamTypeEnum.Away,
        Number(matchStats.home?.yellowCards) ?? 0,
        Number(matchStats.away?.yellowCards) ?? 0
      ),
    },
    {
      title: "Red Cards",
      homeValue: Number(matchStats.home?.redCards) ?? 0,
      awayValue: Number(matchStats.away?.redCards) ?? 0,
      chartValueLeft: statValue(
        MatchTeamTypeEnum.Home,
        Number(matchStats.home?.redCards) ?? 0,
        Number(matchStats.away?.redCards) ?? 0
      ),
      chartValueRight: statValue(
        MatchTeamTypeEnum.Away,
        Number(matchStats.home?.redCards) ?? 0,
        Number(matchStats.away?.redCards) ?? 0
      ),
    },
    {
      title: "Offsides",
      homeValue: Number(matchStats.home?.offsides) ?? 0,
      awayValue: Number(matchStats.away?.offsides) ?? 0,
      chartValueLeft: statValue(
        MatchTeamTypeEnum.Home,
        Number(matchStats.home?.offsides) ?? 0,
        Number(matchStats.away?.offsides) ?? 0
      ),
      chartValueRight: statValue(
        MatchTeamTypeEnum.Away,
        Number(matchStats.home?.offsides) ?? 0,
        Number(matchStats.away?.offsides) ?? 0
      ),
    },
    {
      title: "Fouls",
      homeValue: Number(matchStats.home?.foulsCommitted) ?? 0,
      awayValue: Number(matchStats.away?.foulsCommitted) ?? 0,
      chartValueLeft: statValue(
        MatchTeamTypeEnum.Home,
        Number(matchStats.home?.foulsCommitted) ?? 0,
        Number(matchStats.away?.foulsCommitted) ?? 0
      ),
      chartValueRight: statValue(
        MatchTeamTypeEnum.Away,
        Number(matchStats.home?.foulsCommitted) ?? 0,
        Number(matchStats.away?.foulsCommitted) ?? 0
      ),
    },
  ];

  return (
    <AppBox flexDirection="column" gap="xs">
      <AppTitle as="h5">Match Statistics</AppTitle>
      <AppPaper radius="md" shadow="xs" padding="sm">
        <AppBox flexDirection="column" gap="sm">
          <AppBox>
            <AppBox className="flex-1" alignItems="center" gap="2xs" justifyContent="end">
              <AppText size="lg" textAlign="right" fontWeight="extrabold" className="stat-title-width">
                {match.homeTeam?.shortName}
              </AppText>
              <AppAvatar username={match.homeTeam?.name || ""} src={match.homeTeam?.logo || ""} />
            </AppBox>
            <AppDivider orientation="vertical" color="dark" mx="3xl" />
            <AppBox className="flex-1" alignItems="center" gap="2xs">
              <AppAvatar username={match.awayTeam?.name || ""} src={match.awayTeam?.logo || ""} />
              <AppText size="lg" fontWeight="extrabold" className="stat-title-width">
                {match.awayTeam?.shortName}
              </AppText>
            </AppBox>
          </AppBox>
          <AppBox alignItems="center" gap="sm">
            <AppBox className="flex-1" justifyContent="end">
              <AppDoughnutChart
                height="160px"
                width="160px"
                color="primary"
                value={Number(matchStats.home?.possessionPercentage) ?? 0}
                info={{
                  value: `${Number(matchStats.home?.possessionPercentage) ?? 0}%`,
                }}
              />
            </AppBox>
            <AppText textAlign="center" color="muted">
              {"Possession"}
            </AppText>
            <AppBox className="flex-1">
              <AppDoughnutChart
                height="160px"
                width="160px"
                color="accent"
                value={Number(matchStats.away?.possessionPercentage) ?? 0}
                info={{
                  value: `${Number(matchStats.away?.possessionPercentage) ?? 0}%`,
                }}
              />
            </AppBox>
          </AppBox>
          <AppBox flexDirection="column" py="2xs">
            {matchStatsItems.map((item, index) => (
              <AppBox key={index} flexDirection="column" gap="xs" py="xs">
                <AppBox justifyContent="center" gap="xs" alignItems="center">
                  <AppText fontWeight="bold" className="stat-width">
                    {item.homeValue}
                  </AppText>
                  <AppText color="muted" className="flex-1" textAlign="center">
                    {item.title}
                  </AppText>
                  <AppText fontWeight="bold" className="stat-width" textAlign="right">
                    {item.awayValue}
                  </AppText>
                </AppBox>
                <AppBox gap="xs">
                  <AppBox className="flex-1" justifyContent="end">
                    <AppProgressChart value={item.chartValueLeft} orientation="right-to-left" />
                  </AppBox>
                  <AppBox className="flex-1">
                    <AppProgressChart value={item.chartValueRight} color="accent" />
                  </AppBox>
                </AppBox>
              </AppBox>
            ))}
          </AppBox>
        </AppBox>
      </AppPaper>
    </AppBox>
  );
}
