import { AppBox, AppPaper, AppText, AppTitle, InfoCard } from "../../../../commons/components";
import AppGridBox from "../../../../commons/components/GridBox";
import { TournamentTypeEnum } from "../../../../commons/enums/tournament-type-enum";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";
import { IFootballSeason } from "../../../../commons/models/football/interface/i-football-season";
import { formatDate } from "../../../../commons/utilities/date-utils";
import { getGender } from "../../../../commons/utilities/gender-utils";
import { toSentenceCase } from "../../../../commons/utilities/string-utils";

interface TournamentBasicInfoCardProps {
  tournament: IFootballCompetition;
  season?: IFootballSeason;
}

export default function TournamentBasicInfoCard({ tournament, season }: TournamentBasicInfoCardProps) {
  const getTournamentType = (type: string) => {
    switch (type) {
      case TournamentTypeEnum.League:
        return "League";
      case TournamentTypeEnum.Knockout:
        return "Knockout";
      case TournamentTypeEnum.Hybrid:
        return "Hybrid";
      default:
        return "Unknown";
    }
  };
  const basicInfoList = [
    {
      title: "Tournament Type",
      label: getTournamentType(tournament.type),
    },
    {
      title: "Season Duration",
      label: `${
        season
          ? `${formatDate(season.startAt, "DD MMM, YYYY")} - ${formatDate(season.endAt, "DD MMM, YYYY")}`
          : "No season"
      }`,
    },
    {
      title: "Team's Gender",
      label: `${getGender(tournament.teamGender?.toString() || "-")}`,
    },
    {
      title: "Team's Age",
      label: toSentenceCase(tournament.ageGroup || "-"),
    },
    {
      title: "Team Formation",
      label: tournament.teamForm || "-",
    },
    {
      title: "Tournament Nature",
      label: toSentenceCase(tournament.skillLevel || "-"),
    },
  ];
  return (
    <AppBox flexDirection="column" gap="sm">
      <AppGridBox className="tournament-info" columns={3} gap="sm">
        {basicInfoList.map((item, index) => (
          <AppPaper shadow="xs" padding="none" key={index}>
            <InfoCard label={item.title} title={item.label} alignItems="start"></InfoCard>
          </AppPaper>
        ))}
      </AppGridBox>
      <AppBox flexDirection="column" gap="2xs">
        <AppTitle as="h5">Description</AppTitle>
        <AppText as="p" fontWeight="medium" size="lg">
          <span
            dangerouslySetInnerHTML={{
              __html: tournament.description || "There is not any description.",
            }}
          ></span>
        </AppText>
      </AppBox>
    </AppBox>
  );
}
