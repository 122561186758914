import { AppAvatar, AppBox, AppContainer, AppDatePicker, AppLink, AppSearch, AppSelect, AppTitle } from "..";
import IconPerson from "../icons/person";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { setToken, setUser } from "../../../redux/slices/session-slice";
import { User } from "../../models/user";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import IconLogout from "../icons/logout";
import { appStorage } from "../..";
import {
  cleanUpToolbarCleanSearchField,
  cleanUpToolbarSearchClean,
  cleanUpToolbarSearchTitle,
  setToolbarCleanSearchField,
  setToolbarSearchTitle,
} from "../../../redux/slices/toolbar-search-slice";
import { ISelectOptions } from "./Select";
import {
  cleanUpToolbarMatchSelectionDate,
  setToolbarCompetitionSelectedOption,
  setToolbarMatchSelectionDateAfter,
  setToolbarMatchSelectionDateBefore,
} from "../../../redux/slices/toolbar-competition-select-slice";
import moment from "moment";

export default function Toolbar() {
  const dispatch = useAppDispatch();
  const { pageTitle } = useAppSelector((state) => state.pageTitle);
  const { enableToolbarCompetitionSelect: enableCompetitionSelect, toolbarCompetitionOptions: competitionOptions } =
    useAppSelector((state) => state.toolbarCompetitionSelect);
  const [selectedTournamentOption, setSelectedTournamentOption] = useState<ISelectOptions>(competitionOptions[0]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isClearable, setIsClearable] = useState(false);
  const userType = appStorage.getItem("userType");
  const defaultStartDate = moment().subtract(3, "days");
  const defaultEndDate = moment().add(1, "months");

  useLayoutEffect(() => {
    if (enableCompetitionSelect) {
      setStartDate(moment(defaultStartDate).toDate());
      setEndDate(moment(defaultEndDate).toDate());
      dispatch(cleanUpToolbarMatchSelectionDate());
    }
  }, [enableCompetitionSelect]);

  useEffect(() => {
    setIsClearable(
      moment(startDate).toString() !== moment(defaultStartDate).toString() &&
        moment(endDate).toString() !== moment(defaultEndDate).toString()
    );
  }, [startDate, endDate]);

  useLayoutEffect(() => {
    dispatch(cleanUpToolbarSearchTitle());
    dispatch(setToolbarCleanSearchField(true));
    dispatch(setToolbarCompetitionSelectedOption(selectedTournamentOption));
  }, [selectedTournamentOption]);

  const handleAuthClick = () => {
    if (userType === "admin") {
      dispatch(setUser(new User()));
      dispatch(setToken(""));
    }
  };

  const onChange = (dates: Date | [Date | null, Date | null] | null, event: React.SyntheticEvent<any, Event>) => {
    event.preventDefault();
    if (dates instanceof Array) {
      const [start, end] = dates;
      setStartDate(start || null);
      setEndDate(end || null);
      if (start && end) {
        dispatch(setToolbarMatchSelectionDateBefore(moment(start)));
        dispatch(setToolbarMatchSelectionDateAfter(moment(end)));
      }
      if (!start && !end) {
        setStartDate(moment().subtract(3, "days").toDate());
        setEndDate(moment().add(1, "months").toDate());
        dispatch(cleanUpToolbarMatchSelectionDate());
      }
    }
  };

  return (
    <header className={`ee-toolbar ${enableCompetitionSelect && "ee-toolbar-with-filter"}`}>
      <AppContainer>
        <AppBox className="w-100" flexDirection="column">
          <AppBox className="mobile-nav-header"></AppBox>
          <AppBox justifyContent={pageTitle ? "space-between" : "end"}>
            {pageTitle && (
              <AppBox className="left-section" gap="sm" alignItems="center" pr="xs">
                <AppTitle as="h2">{pageTitle}</AppTitle>
              </AppBox>
            )}
            <AppBox className="right-section" gap="sm" alignItems="center">
              {enableCompetitionSelect && (
                <AppBox className="competition-filter" gap="sm" alignItems="center">
                  <AppBox className="toolbar-select">
                    <AppSelect
                      className="w-100"
                      options={competitionOptions}
                      withSearch
                      onChange={setSelectedTournamentOption}
                      dropDownHeight="lg"
                    />
                  </AppBox>
                  <AppBox className="toolbar-date-picker">
                    <AppDatePicker
                      className="w-100"
                      onChange={onChange}
                      isMultiSelector
                      dateFormat="d MMM, yyyy"
                      selectedStart={startDate}
                      selectedEnd={endDate}
                      isClearable={isClearable}
                    />
                  </AppBox>
                </AppBox>
              )}
              <AppBox className="search-container">
                <ToolbarSearch />
              </AppBox>
              <AppLink className="user-login" to={userType === "public" ? "/manage" : "/"} onClick={handleAuthClick}>
                <AppAvatar
                  size="md"
                  username={""}
                  icon={userType === "admin" ? <IconLogout /> : <IconPerson />}
                  color="dark"
                />
              </AppLink>
            </AppBox>
          </AppBox>
        </AppBox>
      </AppContainer>
    </header>
  );
}

const ToolbarSearch = () => {
  const dispatch = useAppDispatch();
  const { toolbarSearchEnable, toolbarCleanSearchField } = useAppSelector((state) => state.toolbarSearch);
  const [searchValueReset, setSearchValueReset] = useState<boolean>(false);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (toolbarCleanSearchField) {
      setSearchValueReset(!searchValueReset);
      dispatch(cleanUpToolbarCleanSearchField());
    }
  }, [toolbarCleanSearchField]);

  useEffect(() => {
    if (!toolbarSearchEnable) {
      dispatch(cleanUpToolbarSearchTitle());
      dispatch(cleanUpToolbarSearchClean());
    }
  }, [toolbarSearchEnable]);

  const handleSearch = (value: string) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      dispatch(setToolbarSearchTitle(value));
    }, 750);
  };

  const handleSearchClear = (e: Event) => {
    e.preventDefault();
    dispatch(cleanUpToolbarSearchTitle());
  };
  return (
    <AppSearch
      onChange={(value) => {
        handleSearch(value);
      }}
      onClear={handleSearchClear}
      disabled={!toolbarSearchEnable}
      resetValue={searchValueReset}
    />
  );
};
