import { Controller, useForm } from "react-hook-form";
import { AppBox, AppButton, AppDatePicker, AppSelect, AppText, AppTimePicker } from "../../../../commons/components";
import { formatDate, formatDateTime } from "../../../../commons/utilities/date-utils";
import { IFootballMatchDetail } from "../../../../commons/models/football/interface/i-football-match";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import moment from "moment";
import { useEffect, useState } from "react";
import { ISelectOptions } from "../../../../commons/components/ui-components/Select";
import { fetchManageRefereeList } from "../../../../redux/slices/football/admin/referee/manage-referee-list-slice";
import { fetchManageStadiumList } from "../../../../redux/slices/stadium/manage-stadium-list-slice";
import { findIndexByCondition } from "../../../../commons/utilities/array-utils";
import { findInputError, isFormInvalid } from "../../../../commons/utilities/form-utils";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import isNullOrUndefined from "../../../../commons/utilities/null-or-undefined";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { RefereeTypeEnum } from "../../../../commons/enums/referee-type-enum";
import { seasonMatchEdit } from "../../../../redux/slices/football/admin/season/match/manage-season-match-edit-slice";
import { fetchSeasonMatchDetail } from "../../../../redux/slices/football/admin/season/match/manage-season-match-detail-slice";
import { toSentenceCase, toTitleCase } from "../../../../commons/utilities/string-utils";
import { GameDurationEnum } from "../../../../commons/enums/game-duration-enum";
import { IFootballMatchEditDTO } from "../../../../api-services/football/admin/manage-football-season-service";

interface MatchEditProps {
  matchData: IFootballMatchDetail;
  onEdit: () => void;
  onCancel: () => void;
}

const MatchEdit = ({ matchData, onCancel, onEdit }: MatchEditProps) => {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const minDate = moment().toDate();
  const { stadiumList, stadiumListStatus } = useAppSelector((state) => state.manageStadiumList);
  const { refereeList, refereeListStatus } = useAppSelector((state) => state.footballManageRefereeList);
  const { seasonMatchEditStatus } = useAppSelector((state) => state.footballManageSeasonMatchEdit);
  const methods = useForm();
  const formErrors = methods.formState.errors;
  const [matchDate, setMatchDate] = useState<Date>(moment().toDate());
  const [matchTime, setMatchTime] = useState(moment().format("HH:mm:ss"));
  const [matchTimezone, setMatchTimezone] = useState(moment.tz.guess());
  const [venueSelectOptions, setVenueSelectOptions] = useState<ISelectOptions[]>([]);
  const [refereeSelectOptions, setRefereeSelectOptions] = useState<ISelectOptions[]>([]);
  const [durationIndex, setDurationIndex] = useState(0);
  const [venueIndex, setVenueIndex] = useState(0);
  const [refereeIndex, setRefereeIndex] = useState(0);

  const durationSelectOptions: ISelectOptions[] = [
    { title: "90 Minutes", value: GameDurationEnum.Minutes90 },
    { title: "70 Minutes", value: GameDurationEnum.Minutes70 },
    { title: "40 Minutes", value: GameDurationEnum.Minutes40 },
    { title: "20 Minutes", value: GameDurationEnum.Minutes20 },
  ];

  useEffect(() => {
    dispatch(fetchManageRefereeList({ sort: "createdAt", sortOrder: "DESC" }));
    dispatch(fetchManageStadiumList({ sort: "createdAt", sortOrder: "DESC" }));
  }, []);

  useEffect(() => {
    const refereeOptions: ISelectOptions[] = [{ title: "Select main referee", value: 0 }];
    if (refereeList.length > 0) {
      refereeList.forEach((referee) => {
        refereeOptions.push({
          title: toTitleCase(referee.fullName),
          value: referee.id,
        });
      });
      setRefereeSelectOptions(refereeOptions);
    }
  }, [refereeList]);

  useEffect(() => {
    const venueOptions: ISelectOptions[] = [{ title: "Select a venue", value: 0 }];
    if (stadiumList.length > 0) {
      stadiumList.forEach((venue) => {
        venueOptions.push({
          title: venue.name,
          value: venue.id,
        });
      });
      setVenueSelectOptions(venueOptions);
    }
  }, [stadiumList]);

  useEffect(() => {
    if (matchData) {
      if (matchData.scheduledAt) {
        setMatchDate(matchData?.scheduledAt.toDate());
      }
      let durationIndex = findIndexByCondition(
        durationSelectOptions,
        (option) => option.value === Math.floor(matchData.gameplayDuration / 60)
      );
      if (durationIndex >= 0) {
        setDurationIndex(durationIndex);
        methods.setValue("gameplayDuration", durationSelectOptions[durationIndex].value);
      }
      if (venueSelectOptions.length > 0 && refereeSelectOptions.length > 0) {
        let venueIndex = findIndexByCondition(venueSelectOptions, (option) => option.value === matchData.stadium?.id);

        if (venueIndex >= 0) {
          setVenueIndex(venueIndex);
          methods.setValue("stadiumId", venueSelectOptions[venueIndex].value);
        }

        let refereeIndex = findIndexByCondition(
          refereeSelectOptions,
          (option) => option.value === matchData.matchReferees[0]?.referee.id
        );
        if (refereeIndex >= 0) {
          setRefereeIndex(refereeIndex);
          methods.setValue("referees", refereeSelectOptions[refereeIndex].value);
        }
      }
    }
  }, [refereeSelectOptions, venueSelectOptions, matchData]);

  const handleDateTime = (date: Date, time: string, timezone: string): Promise<string> => {
    return new Promise((resolve) => {
      const utcDateTime = formatDateTime(moment.tz(formatDate(date) + " " + time, timezone).utc());
      resolve(utcDateTime);
    });
  };

  const handleFormSubmit = (values: IFootballMatchEditDTO) => {
    const formattedValues = {
      stadiumId: values.stadiumId ? Number(values.stadiumId) : null,
      scheduledAt: values ? values.scheduledAt : formatDateTime(moment()),
      referees: values.referees ? [{ id: Number(values.referees), role: RefereeTypeEnum.Head }] : [],
      gameplayDuration: Number(values.gameplayDuration) * 60,
    };
    if (matchData) {
      dispatch(
        seasonMatchEdit({
          seasonId: matchData.season.id,
          matchId: matchData.id,
          seasonMatchEditData: formattedValues,
        })
      )
        .unwrap()
        .then(() => {
          handleSuccess();
        })
        .catch(handleError);
    }
  };

  const handleSuccess = () => {
    addSnackbar({
      key: "match-edit-success",
      text: "Match Edited Successfully",
      variant: "success",
    });
    dispatch(
      fetchSeasonMatchDetail({
        seasonId: matchData.season.id,
        matchId: matchData.id,
      })
    );
    if (onEdit) {
      onEdit();
    }
  };

  const handleError = (error: AxiosError<IServerErrorResponse>) => {
    const responseData = error.response?.data;
    if (error.response?.status === 417) {
      if (responseData) {
        addSnackbar({
          key: "error",
          text: "Form not valid",
          variant: "danger",
        });
        Object.entries(responseData).forEach(([field, messages]) => {
          messages.forEach((message: string) => {
            methods.setError(field, { message });
          });
        });
      }
    } else {
      addSnackbar({
        key: "error",
        text: responseData?.message,
        variant: "danger",
      });
    }
  };

  return (
    <form
      noValidate
      onSubmit={methods.handleSubmit((e) => {
        methods.formState.isValid && handleFormSubmit(e as IFootballMatchEditDTO);
      })}
    >
      <AppBox flexDirection="column" gap="md">
        <AppBox flexDirection="column" gap="sm" pr="xs" style={{ overflowY: "scroll", maxHeight: "30rem" }}>
          <Controller
            name="gameplayDuration"
            control={methods.control}
            defaultValue={durationSelectOptions[durationIndex].value}
            render={({ field: { onChange } }) => (
              <AppBox flexDirection="column" gap="2xs">
                <AppText as="label" size="lg">
                  Match Duration
                </AppText>
                <AppSelect
                  options={durationSelectOptions}
                  onChange={(option) => onChange(option.value)}
                  currentOption={durationSelectOptions[durationIndex]}
                />
                {isFormInvalid(findInputError(formErrors, "gameplayDuration")) && (
                  <AppText as="span" color="danger">
                    <>
                      {toSentenceCase(
                        `${formErrors.gameplayDuration?.message && formErrors.gameplayDuration?.message}`
                      )}
                    </>
                  </AppText>
                )}
              </AppBox>
            )}
          />
          <Controller
            name="scheduledAt"
            defaultValue={
              matchData ? formatDateTime(matchData.scheduledAt) : handleDateTime(matchDate, matchTime, matchTimezone)
            }
            control={methods.control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <AppBox flexDirection="column" gap="sm">
                <AppBox flexDirection="column" gap="2xs">
                  <AppText as="label" size="lg">
                    Match Date
                  </AppText>
                  <AppDatePicker
                    onChange={(date) => {
                      setMatchDate(date as Date);
                      handleDateTime(date as Date, matchTime, matchTimezone).then((utcDateTime) => {
                        onChange(utcDateTime);
                      });
                    }}
                    selected={matchDate}
                    calenderPlacement="bottom-end"
                    minDate={minDate}
                  />
                </AppBox>
                <AppBox flexDirection="column" gap="2xs">
                  <AppText as="label" size="lg">
                    Match Time (ground time)
                  </AppText>
                  <AppTimePicker
                    givenTime={matchData.scheduledAt}
                    onChange={(time) => {
                      setMatchTime(time);
                      handleDateTime(matchDate, time, matchTimezone).then((utcDateTime) => {
                        onChange(utcDateTime);
                      });
                    }}
                  />
                </AppBox>
              </AppBox>
            )}
          />
          {stadiumListStatus === StatusEnum.Succeeded && venueSelectOptions.length > 0 && (
            <Controller
              name="stadiumId"
              control={methods.control}
              defaultValue={venueSelectOptions[venueIndex].value}
              render={({ field: { onChange } }) => (
                <AppBox flexDirection="column" gap="2xs">
                  <AppText as="label" size="lg">
                    Venue
                  </AppText>
                  {venueSelectOptions.length > 0 && (
                    <AppSelect
                      options={venueSelectOptions}
                      onChange={(option) => onChange(option.value)}
                      currentOption={venueSelectOptions[venueIndex]}
                    />
                  )}
                  {isFormInvalid(findInputError(formErrors, "stadiumId")) && (
                    <AppText as="span" color="danger">
                      <>{toSentenceCase(`${formErrors.stadiumId?.message && formErrors.stadiumId?.message}`)}</>
                    </AppText>
                  )}
                </AppBox>
              )}
            />
          )}
          {refereeListStatus === StatusEnum.Succeeded && refereeSelectOptions.length > 0 && (
            <Controller
              name="referees"
              control={methods.control}
              defaultValue={
                isNullOrUndefined(matchData) ? refereeSelectOptions[0].value : refereeSelectOptions[refereeIndex].value
              }
              render={({ field: { onChange } }) => (
                <AppBox flexDirection="column" gap="2xs">
                  <AppText as="label" size="lg">
                    Main Referee
                  </AppText>
                  {refereeSelectOptions.length > 0 && (
                    <AppSelect
                      options={refereeSelectOptions}
                      onChange={(option) => onChange(option.value)}
                      currentOption={refereeSelectOptions[refereeIndex]}
                    />
                  )}
                  {isFormInvalid(findInputError(formErrors, "referees")) && (
                    <AppText as="span" color="danger">
                      <>{toSentenceCase(`${formErrors.referees?.message && formErrors.referees?.message}`)}</>
                    </AppText>
                  )}
                </AppBox>
              )}
            />
          )}
        </AppBox>
        <AppBox justifyContent="end" gap="xs">
          <AppButton
            variant="outline"
            borderLight
            label="Cancel"
            color="gray"
            onClick={(e) => {
              e.preventDefault();
              if (onCancel) {
                onCancel();
              }
            }}
            disabled={seasonMatchEditStatus === StatusEnum.Loading}
          />
          <AppButton
            type="submit"
            disabled={!methods.formState.isValid}
            label={"Save Changes"}
            loading={seasonMatchEditStatus === StatusEnum.Loading}
          />
        </AppBox>
      </AppBox>
    </form>
  );
};

export default MatchEdit;
