import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../../commons";

interface ISeasonMatchPlayerPassStatisticDelete {
  seasonMatchPlayerPassStatisticDeleteStatus: StatusEnum;
}

const initialState: ISeasonMatchPlayerPassStatisticDelete = {
  seasonMatchPlayerPassStatisticDeleteStatus: StatusEnum.Idle,
};

export interface IFootballSeasonMatchPlayerPassStatisticDeleteOptions {
  seasonId: number;
  matchId: number;
  playerId: number;
}

export const seasonMatchPlayerPassStatisticDelete = createAsyncThunk(
  "manage/seasonMatchPlayerPassStatisticDelete",
  async (options: IFootballSeasonMatchPlayerPassStatisticDeleteOptions, thunkAPI) => {
    try {
      const seasonMatchPlayerPassStatisticDelete =
        await manageFootballSeasonService.deleteSeasonMatchPlayerPassStatistic(
          options.seasonId,
          options.matchId,
          options.playerId
        );
      return seasonMatchPlayerPassStatisticDelete;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonMatchPlayerPassStatisticDeleteSlice = createSlice({
  name: "seasonMatchPlayerPassStatisticDelete",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonMatchPlayerPassStatisticDelete.pending, (state) => {
        state.seasonMatchPlayerPassStatisticDeleteStatus = StatusEnum.Loading;
      })
      .addCase(seasonMatchPlayerPassStatisticDelete.fulfilled, (state, action) => {
        state.seasonMatchPlayerPassStatisticDeleteStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonMatchPlayerPassStatisticDelete.rejected, (state) => {
        state.seasonMatchPlayerPassStatisticDeleteStatus = StatusEnum.Failed;
      });
  },
});

export default seasonMatchPlayerPassStatisticDeleteSlice.reducer;
