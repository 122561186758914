import { useEffect, useState } from "react";
import { AppBox, AppButton, AppGridBox, AppInput, AppSelect, AppText } from "../../../../../commons/components";
import { Controller, useForm } from "react-hook-form";
import { IFootballPlayerDetail } from "../../../../../commons/models/football/interface/i-football-player";
import { findInputError, isFormInvalid } from "../../../../../commons/utilities/form-utils";
import { ISelectOptions } from "../../../../../commons/components/ui-components/Select";
import { PlayerFootEnum } from "../../../../../commons/enums/player-foot-enum";
import IconApproval from "../../../../../commons/components/icons/approval";
import IconPersonProhibited from "../../../../../commons/components/icons/person-prohibited";
import { PlayerStatusEnum } from "../../../../../commons/enums/player-status-enum";
import { PlayerPositionEnum } from "../../../../../commons/enums/match-player-position-enum";
import { findIndexByCondition } from "../../../../../commons/utilities/array-utils";
import { FieldErrors, FieldValues } from "react-hook-form";
import { IPlayerDTO } from "../../../../../api-services/football/admin/manage-football-player-service";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import { setStepperFormData } from "../../../../../redux/slices/stepper-form-slice";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { toSentenceCase } from "../../../../../commons/utilities/string-utils";
interface PlayerSpecificsAddEditProps {
  playerData?: IFootballPlayerDetail | null;
  formStep: number;
  formErrors: FieldErrors<FieldValues>;
  submitStatus: StatusEnum;
  onBack: (currentStep: number) => void;
  onNext: (currentStep: number) => void;
  onClearError: (errorKey: string) => void;
  onSaveAndExit: () => void;
}

export const playerFootOptions: ISelectOptions[] = [
  {
    title: "Right",
    value: PlayerFootEnum.Right,
  },
  {
    title: "Left",
    value: PlayerFootEnum.Left,
  },
  {
    title: "Both",
    value: PlayerFootEnum.Both,
  },
];
const playerStatusOptions: ISelectOptions[] = [
  {
    title: "Active",
    icon: <IconApproval color="var(--fg-outline-success)" />,
    value: PlayerStatusEnum.Active,
  },
  {
    title: "Retired",
    icon: <IconPersonProhibited color="var(--fg-outline-danger)" />,
    value: PlayerStatusEnum.Retired,
  },
];
const playerPositionOptions: ISelectOptions[] = [
  {
    title: "Goalkeeper",
    value: PlayerPositionEnum.Goalkeeper,
  },
  {
    title: "Defender",
    value: PlayerPositionEnum.Defender,
  },
  {
    title: "Midfielder",
    value: PlayerPositionEnum.Midfielder,
  },
  {
    title: "Forward",
    value: PlayerPositionEnum.Forward,
  },
];

const PlayerSpecificsAddEdit = ({
  playerData,
  formStep,
  formErrors,
  submitStatus,
  onBack,
  onNext,
  onClearError,
  onSaveAndExit,
}: PlayerSpecificsAddEditProps) => {
  const methods = useForm();
  const dispatch = useAppDispatch();
  const [playerFootIndex, setPlayerFootIndex] = useState(0);
  const [playerPositionIndex, setPlayerPositionIndex] = useState(0);
  const [playerStatusIndex, setPlayerStatusIndex] = useState(0);
  const formData = useAppSelector<IPlayerDTO>((state) => state.stepperForm.stepperFormData);

  const convertValue = (key: string, value: string): any => {
    const numericKeys = ["weight", "height", "status"];
    return numericKeys.includes(key) ? Number(value) : value;
  };

  const handleInputChange = (value: string, key: string) => {
    const playerData: IPlayerDTO = {
      ...formData,
      [key]: convertValue(key, value),
    };
    dispatch(setStepperFormData<IPlayerDTO>(playerData));
    onClearError(key);
  };

  useEffect(() => {
    if (!playerData) {
      const stadiumData: IPlayerDTO = {
        ...formData,
        status: Number(playerStatusOptions[0].value),
        preferredFoot: playerFootOptions[0].value.toString(),
        position: playerPositionOptions[0].value.toString(),
      };
      dispatch(setStepperFormData<IPlayerDTO>(stadiumData));
    }
  }, []);

  useEffect(() => {
    if (playerData) {
      let playerFootIndex = findIndexByCondition(
        playerFootOptions,
        (option) => option.value === playerData.preferredFoot?.toLowerCase()
      );
      if (playerFootIndex >= 0) {
        setPlayerFootIndex(playerFootIndex);
      }
      methods.setValue("preferredFoot", playerData.preferredFoot);
      let playerPositionIndex = findIndexByCondition(
        playerPositionOptions,
        (option) => option.value === playerData.position
      );
      if (playerPositionIndex >= 0) {
        setPlayerPositionIndex(playerPositionIndex);
      }
      methods.setValue("position", `${playerData.position}`);
      let playerStatusIndex = findIndexByCondition(
        playerStatusOptions,
        (option) => option.value === playerData.status.toString()
      );
      if (playerStatusIndex >= 0) {
        setPlayerStatusIndex(playerStatusIndex);
      }
      methods.setValue("status", `${playerData.status}`);
    }
  }, [playerData]);

  return (
    <AppBox flexDirection="column" justifyContent="space-between" gap="sm" className="flex-1">
      <AppBox flexDirection="column" gap="sm" className="w-100">
        <Controller
          name="height"
          defaultValue={playerData ? playerData.height : ""}
          control={methods.control}
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                id="height"
                label="Height (cm)"
                placeholder="Enter player's height in cm"
                type="number"
                defaultValue={value}
                flex="row"
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "height");
                }}
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "height")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.height?.message && formErrors.height.message}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="weight"
          defaultValue={playerData ? playerData.weight : ""}
          control={methods.control}
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                id="weight"
                label="Weight (lb)"
                placeholder="Enter player's weight in lb"
                type="number"
                defaultValue={value}
                flex="row"
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "weight");
                }}
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "weight")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.weight?.message && formErrors.weight.message}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="preferredFoot"
          defaultValue={playerData ? playerFootOptions[playerFootIndex].value : playerFootOptions[0].value}
          control={methods.control}
          render={({ field: { onChange } }) => (
            <AppBox flexDirection="column">
              <AppGridBox className="w-100 flex-1" style={{ gridTemplateColumns: "1fr 2fr" }} gap="sm">
                <AppBox alignItems="center">
                  <AppText as="label" size="lg">
                    <label>Preferred Foot</label>
                  </AppText>
                </AppBox>
                <AppSelect
                  options={playerFootOptions}
                  onChange={(option) => {
                    onChange(option.value);
                    setPlayerFootIndex(
                      findIndexByCondition(playerFootOptions, (footOption) => footOption.value === option.value)
                    );
                    handleInputChange(option.value.toString(), "preferredFoot");
                  }}
                  currentOption={playerFootOptions[playerFootIndex]}
                />
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="position"
          defaultValue={playerData ? playerPositionOptions[playerPositionIndex].value : playerPositionOptions[0].value}
          control={methods.control}
          render={({ field: { onChange } }) => (
            <AppBox flexDirection="column">
              <AppGridBox className="w-100 flex-1" style={{ gridTemplateColumns: "1fr 2fr" }} gap="sm">
                <AppBox alignItems="center">
                  <AppText as="label" size="lg">
                    <label>Position *</label>
                  </AppText>
                </AppBox>
                <AppSelect
                  options={playerPositionOptions}
                  onChange={(option) => {
                    onChange(option.value);
                    setPlayerPositionIndex(
                      findIndexByCondition(
                        playerPositionOptions,
                        (positionOption) => positionOption.value === option.value
                      )
                    );
                    handleInputChange(option.value.toString(), "position");
                  }}
                  currentOption={playerPositionOptions[playerPositionIndex]}
                />
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="status"
          control={methods.control}
          rules={{ required: false }}
          defaultValue={playerData ? playerStatusOptions[playerStatusIndex].value : playerStatusOptions[0].value}
          render={({ field: { onChange } }) => (
            <AppBox flexDirection="column">
              <AppGridBox className="w-100 flex-1" style={{ gridTemplateColumns: "1fr 2fr" }} gap="sm">
                <AppBox alignItems="center">
                  <AppText as="label" size="lg">
                    <label>Status</label>
                  </AppText>
                </AppBox>
                <AppSelect
                  options={playerStatusOptions}
                  onChange={(option) => {
                    onChange(option.value);
                    setPlayerStatusIndex(
                      findIndexByCondition(playerStatusOptions, (statusOption) => statusOption.value === option.value)
                    );
                    handleInputChange(option.value.toString(), "status");
                  }}
                  currentOption={playerStatusOptions[playerStatusIndex]}
                />
              </AppGridBox>
            </AppBox>
          )}
        />
      </AppBox>
      <AppBox gap="xs" justifyContent="end">
        <AppButton
          label="Back"
          variant="outline"
          color="gray"
          borderLight
          onClick={() => {
            onBack(formStep);
          }}
          disabled={submitStatus === StatusEnum.Loading}
        />
        {playerData && (
          <AppButton
            label="Save & Exit"
            variant="light"
            onClick={() => {
              onSaveAndExit();
            }}
            loading={submitStatus === StatusEnum.Loading}
          />
        )}
        <AppButton
          label="Next"
          onClick={() => {
            onNext(formStep);
          }}
          disabled={submitStatus === StatusEnum.Loading}
        />
      </AppBox>
    </AppBox>
  );
};

export default PlayerSpecificsAddEdit;
