import { useEffect, useLayoutEffect, useState } from "react";
import {
  AppBox,
  AppGridBox,
  AppSelect,
  AppSkeletonListComponent,
  AppSkeletonStatistics,
  AppSkeletonTabs,
  AppTitle,
} from "../../../commons/components";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { ISelectOptions } from "../../../commons/components/ui-components/Select";
import { IFootballPlayerStatisticsOption } from "../../../redux/slices/football/public/player/football-player-basic-statistics-slice";
import PlayerStatisticsChartCard from "../../public/player-detail/components/PlayerStatisticsChartCard";
import PlayerStatisticsCard from "../../public/player-detail/components/PlayerStatisticsCard";
import {
  cleanUpManagePlayerStats,
  fetchManagePlayerBasicStatistics,
} from "../../../redux/slices/football/admin/player/manage-player-basic-statistics-slice";
import { fetchManagePlayerCompetitionList } from "../../../redux/slices/football/admin/player/manage-player-competitions-slice";
import { IComparativeStatistics } from "../../../commons/models/football/interface/i-football-statistics";
import { FootballBasicStatistics } from "../../../commons/models/football/football-basic-statistics";
import { IFootballTeamStatisticsOption } from "../../../redux/slices/football/public/team/football-team-basic-statistics-slice";
import {
  cleanUpManageTeamStats,
  fetchManageTeamBasicStatistics,
} from "../../../redux/slices/football/admin/team/manage-team-basic-statistics-slice";
import { findIndexByCondition } from "../../../commons/utilities/array-utils";

export default function PlayerManageStatistics() {
  const [competitionSelectOptions, setCompetitionSelectOptions] = useState<ISelectOptions[]>([]);
  const [seasonSelectOptions, setSeasonSelectOptions] = useState<ISelectOptions[]>([]);
  const { playerInfo, playerInfoStatus } = useAppSelector((state) => state.footballManagePlayer);
  const { playerStatistics, playerStatisticsStatus } = useAppSelector((state) => state.footballManagePlayerStatistics);
  const { teamStatistics, teamStatisticsStatus } = useAppSelector((state) => state.footballManageTeamStatistics);
  const { playerCompetitionList } = useAppSelector((state) => state.footballManagePlayerCompetitions);
  const [currentCompetitionId, setCurrentCompetitionId] = useState<number>();
  const [currentSeasonId, setCurrentSeasonId] = useState<number>();
  const [currentCompetitionOption, setCurrentCompetitionOption] = useState<ISelectOptions>();
  const [currentSeasonOption, setCurrentSeasonOption] = useState<ISelectOptions>();
  const [comparativeStatistics, setComparativeStatistics] = useState<IComparativeStatistics>({
    statistics: new FootballBasicStatistics(),
    baseStatistics: new FootballBasicStatistics(),
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (playerInfo.id) {
      dispatch(fetchManagePlayerCompetitionList(playerInfo.id));
    }
  }, [playerInfo]);

  useEffect(() => {
    const competitionOptions: ISelectOptions[] = [];
    if (playerCompetitionList.length > 0) {
      playerCompetitionList.forEach((competition) => {
        competitionOptions.push({
          title: competition.title,
          value: competition.id,
        });
      });
      const currentPlayerCompetitionId = playerInfo.latestSeason?.competition?.id;
      let currentPlayerCompetitionIndex = findIndexByCondition(
        competitionOptions,
        (option) => option.value === currentPlayerCompetitionId
      );
      if (currentPlayerCompetitionIndex >= 0) {
        setCurrentCompetitionOption(competitionOptions[currentPlayerCompetitionIndex]);
        setCurrentCompetitionId(Number(competitionOptions[currentPlayerCompetitionIndex].value));
      } else {
        setCurrentCompetitionId(Number(competitionOptions[0].value));
      }
      setCompetitionSelectOptions(competitionOptions);
    }
  }, [playerCompetitionList]);

  useEffect(() => {
    if (currentCompetitionId) {
      const currentCompetition = playerCompetitionList.first((competition) => competition.id === currentCompetitionId);
      const seasonOptions: ISelectOptions[] = [];
      currentCompetition?.seasons.forEach((season) => {
        seasonOptions.push({ title: season.title, value: season.id });
      });
      const currentPlayerSeasonId = playerInfo.latestSeason?.season?.id;
      let currentPlayerSeasonIndex = findIndexByCondition(
        seasonOptions,
        (option) => option.value === currentPlayerSeasonId
      );
      if (currentPlayerSeasonIndex >= 0) {
        setCurrentSeasonOption(seasonOptions[currentPlayerSeasonIndex]);
        setCurrentSeasonId(Number(seasonOptions[currentPlayerSeasonIndex].value));
      } else {
        setCurrentSeasonOption(seasonOptions[0]);
        setCurrentSeasonId(Number(seasonOptions[0].value));
      }
      setSeasonSelectOptions(seasonOptions);
    }
  }, [currentCompetitionId]);

  useEffect(() => {
    if (!currentSeasonId) {
      dispatch(cleanUpManagePlayerStats());
      dispatch(cleanUpManageTeamStats());
    }

    if (currentCompetitionId && !currentSeasonId) {
      return;
    }

    if (currentCompetitionId && currentSeasonId) {
      const playerBasicStatOption: IFootballPlayerStatisticsOption = {
        playerId: playerInfo.id,
        urlParams: {
          seasonId: currentSeasonId,
        },
      };
      dispatch(fetchManagePlayerBasicStatistics(playerBasicStatOption));
      if (playerInfo.additionalInfo && playerInfo.additionalInfo.team) {
        const teamStatOption: IFootballTeamStatisticsOption = {
          teamId: playerInfo.additionalInfo.team.id,
          urlParams: {
            seasonId: currentSeasonId,
          },
        };
        dispatch(fetchManageTeamBasicStatistics(teamStatOption));
      }
    }
  }, [currentSeasonId]);

  useLayoutEffect(() => {
    setComparativeStatistics({ statistics: playerStatistics, baseStatistics: teamStatistics });
  }, [playerStatistics, teamStatistics]);

  return (
    <AppBox mb="sm" displayBlock>
      <AppBox flexDirection="column" gap="sm">
        {playerInfoStatus === StatusEnum.Succeeded && (
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">Player Statistics</AppTitle>
            <AppGridBox columns={2} gap="sm">
              {competitionSelectOptions.length > 0 && (
                <AppSelect
                  currentOption={currentCompetitionOption}
                  options={competitionSelectOptions}
                  onChange={(option) => {
                    setCurrentCompetitionId(Number(option.value));
                  }}
                />
              )}
              {seasonSelectOptions.length > 0 && (
                <AppSelect
                  currentOption={currentSeasonOption}
                  options={seasonSelectOptions}
                  onChange={(option) => {
                    setCurrentSeasonId(Number(option.value));
                  }}
                />
              )}
            </AppGridBox>
          </AppBox>
        )}
        {playerStatisticsStatus === StatusEnum.Loading && teamStatisticsStatus === StatusEnum.Loading ? (
          <AppBox flexDirection="column" gap="sm">
            <AppSkeletonStatistics noTitle />
            <AppSkeletonTabs numberOfItems={4} />
            <AppSkeletonListComponent noTitle noAvatar />
          </AppBox>
        ) : (
          <AppBox flexDirection="column" gap="md">
            <PlayerStatisticsCard playerStats={playerStatistics} player={playerInfo} />
            <PlayerStatisticsChartCard player={playerInfo} statistics={comparativeStatistics} />
          </AppBox>
        )}
      </AppBox>
    </AppBox>
  );
}
