import { PlayerPositionEnum } from "../../../../../commons/enums/match-player-position-enum";
import { AppBox, Jersey } from "../../../../../commons/components";
import { IFootballMatchDetail } from "../../../../../commons/models/football/interface/i-football-match";
import { darkenColor, getTextColorBasedOnBackground } from "../../../../../commons/utilities/color-utils";
import { IFootballPlayerCommentaries } from "../../../../../commons/models/football/interface/i-football-player-lineup";
import { MatchEventTypeEnum } from "../../../../../commons/enums/match-event-type-enum";
import { toTitleCase } from "../../../../../commons/utilities/string-utils";

interface FormationPlayerComponentProps {
  match: IFootballMatchDetail;
  player: IFootballPlayerCommentaries;
  isHomeTeam: boolean;
  isDraggable?: boolean;
}
const DEFAULT_HOME_COLOR = "#E4450A";
const DEFAULT_AWAY_COLOR = "#2F2388";
const DEFAULT_HOME_GOALKEEPER_COLOR = "#222222";
const DEFAULT_AWAY_GOALKEEPER_COLOR = "#ffffff";

const FormationPlayerComponent = ({ match, player, isHomeTeam, isDraggable }: FormationPlayerComponentProps) => {
  const checkIsSubstitution = (player: IFootballPlayerCommentaries): boolean => {
    const commentaries = player.commentaries;
    if (!commentaries) return false;
    return commentaries.some((commentary) => commentary.eventType === MatchEventTypeEnum.SubstituteOut);
  };
  return (
    <AppBox
      className="player-cell-container"
      style={{ cursor: isDraggable ? "grab" : "default" }}
      alignItems="center"
      flexDirection="column"
      justifyContent="end"
      pb="xs"
    >
      {player.position === PlayerPositionEnum.Goalkeeper ? (
        <Jersey
          colorBack={
            isHomeTeam
              ? match.homeTeam?.goalkeeperHomeColor || DEFAULT_HOME_GOALKEEPER_COLOR
              : match.awayTeam?.goalkeeperAwayColor || DEFAULT_AWAY_GOALKEEPER_COLOR
          }
          colorText={
            isHomeTeam
              ? getTextColorBasedOnBackground(match.homeTeam?.goalkeeperHomeColor || DEFAULT_HOME_GOALKEEPER_COLOR)
              : getTextColorBasedOnBackground(match.awayTeam?.goalkeeperAwayColor || DEFAULT_AWAY_GOALKEEPER_COLOR)
          }
          colorSleeve={
            isHomeTeam
              ? darkenColor(match.homeTeam?.goalkeeperHomeColor || DEFAULT_HOME_GOALKEEPER_COLOR, 0.1)
              : darkenColor(match.awayTeam?.goalkeeperAwayColor || DEFAULT_AWAY_GOALKEEPER_COLOR, 0.1)
          }
          name={toTitleCase(player.displayName)}
          number={player.jerseyNumber}
          isCaptain={player.isCaptain}
          isSubstitution={checkIsSubstitution(player)}
          jerseySize={60}
        />
      ) : (
        <Jersey
          colorBack={
            isHomeTeam
              ? match.homeTeam?.homeColor || DEFAULT_HOME_COLOR
              : match.awayTeam?.awayColor || DEFAULT_AWAY_COLOR
          }
          colorText={
            isHomeTeam
              ? getTextColorBasedOnBackground(match.homeTeam?.homeColor || DEFAULT_HOME_COLOR)
              : getTextColorBasedOnBackground(match.awayTeam?.awayColor || DEFAULT_AWAY_COLOR)
          }
          colorSleeve={
            isHomeTeam
              ? darkenColor(match.homeTeam?.homeColor || DEFAULT_HOME_COLOR, 0.1)
              : darkenColor(match.awayTeam?.awayColor || DEFAULT_AWAY_COLOR, 0.1)
          }
          name={toTitleCase(player.displayName)}
          number={player.jerseyNumber}
          isCaptain={player.isCaptain}
          isSubstitution={checkIsSubstitution(player)}
          jerseySize={60}
        />
      )}
    </AppBox>
  );
};

export default FormationPlayerComponent;
