import {
  AppAvatar,
  AppBox,
  AppGridBox,
  AppIconButton,
  AppLink,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../../../commons/components";
import IconBin from "../../../../../commons/components/icons/bin";
import IconEdit from "../../../../../commons/components/icons/edit";
import { IManageFootballMatchPlayersStatistics } from "../../../../../commons/models/football/admin/interface/i-manage-football-match-players-statistics";
import { formatMatchTime } from "../../../../../commons/utilities/game-time-utils";
import { getStatisticsKeyString } from "../../../../../commons/utilities/match-or-matchCard-utils";
import { toSentenceCase, toTitleCase } from "../../../../../commons/utilities/string-utils";

interface PlayerStatisticListCardProps {
  playerStatsData: IManageFootballMatchPlayersStatistics;
  onEdit: (value: IManageFootballMatchPlayersStatistics) => void;
  onDelete: (value: IManageFootballMatchPlayersStatistics) => void;
}

export const MatchPlayersStatisticListCard = ({ playerStatsData, onEdit, onDelete }: PlayerStatisticListCardProps) => {
  const handlePlayerClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <AppPaper shadow="xs" padding="none">
      <AppBox px="sm" py="xs">
        <AppGridBox columns={3} gap="xs" className="w-100 flex-1">
          <AppBox alignItems="center" gap="xs">
            <AppBox className="match-time-width" alignItems="center">
              <AppText as="p" fontWeight="bold">
                {formatMatchTime(playerStatsData.gameTime, playerStatsData.gameExtraTime)}
              </AppText>
            </AppBox>
            <AppLink to={`/manage/players/${playerStatsData.player.id}`} onClick={handlePlayerClick}>
              <AppBox alignItems="center" gap="xs">
                <AppAvatar username={playerStatsData.player.displayName} src={playerStatsData.player.avatar || ""} />
                <AppBox flexDirection="column">
                  <AppTitle as="h6">{toTitleCase(playerStatsData.player.displayName)}</AppTitle>
                  <AppBox alignItems="center">
                    <AppText size="sm">{toSentenceCase(playerStatsData.player.position)}</AppText>
                  </AppBox>
                </AppBox>
              </AppBox>
            </AppLink>
          </AppBox>
          <AppBox flexDirection="column">
            <AppTitle as="h5">{playerStatsData.value}</AppTitle>
            <AppText color="info">{getStatisticsKeyString(playerStatsData.key)}</AppText>
          </AppBox>
          <AppBox gap="xs" justifyContent="end" alignItems="center">
            <AppIconButton icon={<IconEdit />} variant="light" onClick={() => onEdit(playerStatsData)} />
            <AppIconButton
              icon={<IconBin />}
              variant="light"
              color="danger"
              onClick={() => onDelete(playerStatsData)}
            />
          </AppBox>
        </AppGridBox>
      </AppBox>
    </AppPaper>
  );
};
