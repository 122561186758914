import {
  AppAvatar,
  AppBox,
  AppDateAndTime,
  AppIconButton,
  AppLink,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import IconDismiss from "../../../../commons/components/icons/dismiss";
import IconProhibited from "../../../../commons/components/icons/prohibited";
import { TeamStatusEnum } from "../../../../commons/enums/team-status-enum";
import { IFootballTeam } from "../../../../commons/models/football/interface/i-football-team";

interface TournamentTeamCardProps {
  teamInfo: IFootballTeam;
  withOutRemove?: boolean;
  onTeamRemove?: (team: IFootballTeam) => void;
}

export default function TournamentSeasonTeamCard({ teamInfo, withOutRemove, onTeamRemove }: TournamentTeamCardProps) {
  const teamPath = `/manage/teams/${teamInfo.id}`;
  return (
    <AppPaper padding="xs" shadow="xs">
      <AppBox justifyContent="space-between" alignItems="center" gap="sm">
        <AppLink to={teamPath} className="flex-1">
          <AppBox gap="xs" alignItems="center">
            <AppAvatar username={teamInfo.name} src={teamInfo.logo || ""} size="lg" />
            <AppBox flexDirection="column">
              <AppTitle as="h6">{teamInfo.shortName}</AppTitle>
              <AppBox gap="3xs" alignItems="center">
                <AppText as="span" fontWeight="semibold" size="sm" color="muted">
                  Foundation Date:
                </AppText>
                <AppDateAndTime date={teamInfo.founded || ""} format="DD MMM, YYYY" size="sm" color="muted" />
              </AppBox>
            </AppBox>
          </AppBox>
        </AppLink>
        {teamInfo.status === Number(TeamStatusEnum.Terminated) && (
          <AppBox alignItems="center" gap="2xs">
            <IconProhibited color="var(--fg-outline-danger)" />
            <AppText>Terminated</AppText>
          </AppBox>
        )}
        {withOutRemove || (
          <AppIconButton
            variant="light"
            color="danger"
            onClick={() => {
              if (onTeamRemove) {
                onTeamRemove(teamInfo);
              }
            }}
            icon={<IconDismiss />}
          />
        )}
      </AppBox>
    </AppPaper>
  );
}
