import { useEffect, useState } from "react";
import { IFootballMatchDetail, IMatchState } from "../../../../commons/models/football/interface/i-football-match";
import AppTabs, { TabItem } from "../../../../commons/components/Tabs";
import { useLocation } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppLink,
  AppModal,
  AppPaper,
  AppScorePill,
  AppSkeletonHeader,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import AppBorderBox from "../../../../commons/components/BorderBox";
import { MatchStatusEnum } from "../../../../commons/enums/match-status-enum";
import { MatchStatus } from "../../../../commons/components/ScorePill";
import IconRibbonStar from "../../../../commons/components/icons/ribbon-star";
import MatchStartDetail from "./MatchStartDetail";
import MatchAddInjuryTime from "./MatchAddInjuryTime";
import Pill from "../../../../commons/components/Pill";
import IconLive from "../../../../commons/components/icons/live";
import { MatchStateEnum } from "../../../../commons/enums/match-state-enum";
import { MatchStateTypeEnum } from "../../../../commons/enums/match-state-type-enum";
import moment from "moment";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { seasonMatchStateChange } from "../../../../redux/slices/football/admin/season/match/manage-season-match-state-change-slice";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import IconQuestionCircle from "../../../../commons/components/icons/question-circle";
import { fetchSeasonMatchDetail } from "../../../../redux/slices/football/admin/season/match/manage-season-match-detail-slice";
import { formatDateTime } from "../../../../commons/utilities/date-utils";
import IconEdit from "../../../../commons/components/icons/edit";
import { seasonMatchEnd } from "../../../../redux/slices/football/admin/season/match/manage-season-match-end-slice";
import { formatLiveMatchTIme, getGameTime, getMatchPauseString } from "../../../../commons/utilities/game-time-utils";
import MatchEdit from "./MatchEdit";
import { setMatchTime } from "../../../../redux/slices/football/admin/season/match/manage-season-live-match-time-slice";
import { TournamentFormEnum } from "../../../../commons/enums/tournament-form-enum";
import { TournamentTypeEnum } from "../../../../commons/enums/tournament-type-enum";
import { toTitleCase } from "../../../../commons/utilities/string-utils";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";
import { MatchFixtureGroupType } from "../../../../commons/enums/match-fixture-group-type-enum";
import IconFootball from "../../../../commons/components/icons/football";
import MatchPenaltiesEntry from "./penalty/MatchPenaltiesEntry";

interface MatchDetailHeaderProps {
  match: IFootballMatchDetail;
}

export default function MatchDetailManageHeader({ match }: MatchDetailHeaderProps) {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const [penaltyGoals, setPenaltyGoals] = useState<{ home?: number; away?: number }>();
  const [currentTab, setCurrentTab] = useState("summary");
  const [activeItem, setActiveItem] = useState<TabItem>();
  const [matchStatus, setMatchStatus] = useState<MatchStatus>();
  const [isMatchStart, setIsMatchStart] = useState(false);
  const [isMatchHalfEnd, setIsMatchHalfEnd] = useState(false);
  const [isMatchHalfStart, setIsMatchHalfStart] = useState(false);
  const [isMatchPenalty, setIsMatchPenalty] = useState(false);
  const [isMatchEnd, setIsMatchEnd] = useState(false);
  const [isAddTime, setIsAddTime] = useState(false);
  const [isMatchEdit, setMatchEdit] = useState(false);
  const [selectedMatchState, setSelectedMatchState] = useState<IMatchState>();
  const [gameTime, setGameTime] = useState("");
  const location = useLocation();
  const { seasonMatchDetailStatus } = useAppSelector((state) => state.footballManageSeasonMatchDetail);
  const { seasonMatchStateChangeResponseStatus } = useAppSelector(
    (state) => state.footballManageSeasonMatchStateChange
  );
  const { seasonMatchEndResponseStatus } = useAppSelector((state) => state.footballManageSeasonMatchEnd);
  const tabItems = [
    {
      label: "Summary",
      value: "summary",
      href: "summary",
    },
    {
      label: "Commentary",
      value: "commentary",
      href: "commentary",
    },
    {
      label: "Lineups",
      value: "lineups",
      href: "lineups",
    },
    {
      label: "Statistics",
      value: "statistics",
      href: "statistics",
    },
    {
      label: "Standings",
      value: "standings",
      href: "standings",
      hidden: match.competition.type === TournamentTypeEnum.Knockout,
    },
    {
      label: "Brackets",
      value: "brackets",
      href: "brackets",
      hidden: match.competition.type === TournamentTypeEnum.League,
    },
  ];
  useEffect(() => {
    const url = location.pathname.split("/");
    setCurrentTab(url[url.length - 1]);
  }, [activeItem, location.pathname]);

  useEffect(() => {
    const lastPathSegment = location.pathname.split("/").pop();
    if (!isNaN(Number(lastPathSegment))) {
      setCurrentTab("summary");
    }
  }, [location.pathname]);

  useEffect(() => {
    switch (match.status) {
      case MatchStatusEnum.Upcoming:
        setMatchStatus("upcoming");
        break;
      case MatchStatusEnum.Live:
        setMatchStatus("live");
        break;
      case MatchStatusEnum.Finished:
        setMatchStatus("previous");
        break;
    }

    const matchState = match.states.find((matchState) => matchState.state === MatchStateEnum.InProgress);
    if (matchState) {
      setSelectedMatchState(matchState);
    } else {
      const finishedMatchStates = match.states.filter((matchState) => matchState.state === MatchStateEnum.Completed);
      finishedMatchStates.sort((a, b) => moment(b.startAt).valueOf() - moment(a.startAt).valueOf());
      setSelectedMatchState(finishedMatchStates[0]);
    }
    if (match.penalties.length > 0) {
      const homePenaltyGoal = match.penalties.filter(
        (penalty) => penalty.teamId === match.homeTeam?.id && penalty.goalScored
      ).length;
      const awayPenaltyGoal = match.penalties.filter(
        (penalty) => penalty.teamId === match.awayTeam?.id && penalty.goalScored
      ).length;
      setPenaltyGoals({ home: homePenaltyGoal, away: awayPenaltyGoal });
    }
  }, [match]);

  useEffect(() => {
    if (match.status === MatchStatusEnum.Live && selectedMatchState) {
      const intervalId = setInterval(() => {
        setGameTime(getGameTime(selectedMatchState, match.gameplayDuration));
        if (selectedMatchState.state === MatchStateEnum.Completed) {
          dispatch(setMatchTime(""));
        } else {
          dispatch(setMatchTime(getGameTime(selectedMatchState, match.gameplayDuration)));
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [match.status, selectedMatchState]);

  const handleMatchEnd = () => {
    dispatch(seasonMatchEnd({ seasonId: match.season.id, matchId: match.id }))
      .unwrap()
      .then(() => {
        setIsMatchEnd(false);
        dispatch(
          fetchSeasonMatchDetail({
            seasonId: match.season.id,
            matchId: match.id,
          })
        );
        addSnackbar({
          key: "match-end-success",
          text: "Match Ended Successfully",
          variant: "success",
        });
      })
      .catch((error: AxiosError<IServerErrorResponse>) => {
        const responseData = error.response?.data;
        addSnackbar({
          key: "error",
          text: responseData?.message,
          variant: "danger",
        });
      });
  };

  const handleHalfEnd = () => {
    if (match && selectedMatchState) {
      const stateInfo = {
        state: MatchStateEnum.Completed,
      };
      dispatch(
        seasonMatchStateChange({
          seasonId: match.season.id,
          matchId: match.id,
          stateId: selectedMatchState.id,
          matchStartInfo: stateInfo,
        })
      )
        .unwrap()
        .then(() => {
          setIsMatchHalfEnd(false);
          dispatch(
            fetchSeasonMatchDetail({
              seasonId: match.season.id,
              matchId: match.id,
            })
          );
          addSnackbar({
            key: "match-pause-success",
            variant: "success",
            text: "Match Paused",
          });
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          const responseData = error.response?.data;
          addSnackbar({
            key: "error",
            text: responseData?.message,
            variant: "danger",
          });
        });
    }
  };
  const handleHalfStart = () => {
    if (match && selectedMatchState) {
      const stateInfo = {
        startAt: formatDateTime(moment().utc()),
        state: MatchStateEnum.InProgress,
      };
      let matchState: IMatchState = {} as IMatchState;
      switch (selectedMatchState.type) {
        case MatchStateTypeEnum.FirstHalf:
          matchState = match.states.find(
            (matchState) => matchState.type === MatchStateTypeEnum.SecondHalf
          ) as IMatchState;
          break;
        case MatchStateTypeEnum.SecondHalf:
          matchState = match.states.find(
            (matchState) => matchState.type === MatchStateTypeEnum.ExtraTimeFirstHalf
          ) as IMatchState;
          break;
        case MatchStateTypeEnum.ExtraTimeFirstHalf:
          matchState = match.states.find(
            (matchState) => matchState.type === MatchStateTypeEnum.ExtraTimeSecondHalf
          ) as IMatchState;
          break;
      }
      dispatch(
        seasonMatchStateChange({
          seasonId: match.season.id,
          matchId: match.id,
          stateId: matchState.id,
          matchStartInfo: stateInfo,
        })
      )
        .unwrap()
        .then(() => {
          setIsMatchHalfStart(false);
          dispatch(
            fetchSeasonMatchDetail({
              seasonId: match.season.id,
              matchId: match.id,
            })
          );
          addSnackbar({
            key: "match-resume-success",
            variant: "success",
            text: "Match Resumed",
          });
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          const responseData = error.response?.data;
          addSnackbar({
            key: "error",
            text: responseData?.message,
            variant: "danger",
          });
        });
    }
  };

  const handlePenaltyClose = () => {
    dispatch(
      fetchSeasonMatchDetail({
        seasonId: match.season.id,
        matchId: match.id,
      })
    );
  };

  return (
    <>
      <AppPaper shadow="xs" padding="none">
        {seasonMatchDetailStatus === StatusEnum.Loading ? (
          <AppSkeletonHeader isMatch withoutBackground />
        ) : (
          <AppBorderBox border={["Bottom"]}>
            <AppBox alignItems="center" gap="xs" justifyContent="center" p="sm">
              <AppBox flexDirection="column" justifyContent="center" alignItems="center" className="flex-1" gap="xs">
                <AppLink to={`/manage/tournaments/${match.competition.id}`} className="link">
                  <AppText color="muted" textAlign="center">
                    {match.competition.title}
                  </AppText>
                </AppLink>
                <AppBox gap="xl" alignItems="center" className="w-100">
                  <AppBox className="match-header" justifyContent="end">
                    <AppLink to={`/manage/teams/${match.homeTeam?.id}`}>
                      <AppBox alignItems="center" gap="xs">
                        <AppTitle as="h4" textAlign="right" lineClamp={2}>
                          {toTitleCase(match.homeTeam?.shortName || "")}
                        </AppTitle>
                        <AppAvatar username={match.homeTeam?.name || ""} size="2xl" src={match.homeTeam?.logo || ""} />
                      </AppBox>
                    </AppLink>
                  </AppBox>
                  <AppBox flexDirection="column" gap="2xs">
                    {matchStatus && (
                      <AppScorePill
                        size="lg"
                        matchStatus={matchStatus}
                        valueFirst={match.homeGoals || 0}
                        valueSecond={match.awayGoals || 0}
                        penaltyValueFirst={penaltyGoals?.home}
                        penaltyValueSecond={penaltyGoals?.away}
                      />
                    )}
                    {match.status === MatchStatusEnum.Live && selectedMatchState && (
                      <Pill
                        icon={<IconLive />}
                        label={`live | ${
                          selectedMatchState.state === MatchStateEnum.Completed
                            ? getMatchPauseString(selectedMatchState, match.competition)
                            : formatLiveMatchTIme(getGameTime(selectedMatchState, match.gameplayDuration))
                        }`}
                      />
                    )}
                  </AppBox>
                  <AppBox className="match-header">
                    <AppLink to={`/manage/teams/${match.awayTeam?.id}`}>
                      <AppBox alignItems="center" gap="xs">
                        <AppAvatar username={match.awayTeam?.name || ""} size="2xl" src={match.awayTeam?.logo || ""} />
                        <AppTitle as="h4" lineClamp={2}>
                          {toTitleCase(match.awayTeam?.shortName || "")}
                        </AppTitle>
                      </AppBox>
                    </AppLink>
                  </AppBox>
                </AppBox>
              </AppBox>
              {match.status === MatchStatusEnum.Upcoming && (
                <AppBox gap="xs">
                  <AppButton
                    label="Edit Match"
                    variant="light"
                    fullWidth
                    onClick={() => {
                      setMatchEdit(true);
                    }}
                  />
                  <AppButton
                    label="Start Match"
                    onClick={() => {
                      setIsMatchStart(true);
                    }}
                  />
                </AppBox>
              )}
              {match.status === MatchStatusEnum.Live && (
                <AppBox gap="xs">
                  {!selectedMatchState?.addedTime && selectedMatchState?.state === MatchStateEnum.InProgress && (
                    <AppButton
                      label="Add Injury Time"
                      variant="light"
                      onClick={() => {
                        setIsAddTime(true);
                      }}
                    />
                  )}
                  {selectedMatchState?.type === MatchStateTypeEnum.FirstHalf &&
                    selectedMatchState.state === MatchStateEnum.InProgress && (
                      <AppButton
                        label="End First Half"
                        onClick={() => {
                          setIsMatchHalfEnd(true);
                        }}
                      />
                    )}
                  {selectedMatchState?.type === MatchStateTypeEnum.FirstHalf &&
                    selectedMatchState.state === MatchStateEnum.Completed && (
                      <AppButton
                        label="Start Second Half"
                        onClick={() => {
                          setIsMatchHalfStart(true);
                        }}
                      />
                    )}
                  {selectedMatchState?.type === MatchStateTypeEnum.SecondHalf &&
                    selectedMatchState?.addedTime &&
                    selectedMatchState.state === MatchStateEnum.InProgress &&
                    match.competition.teamForm === TournamentFormEnum.Form11 && (
                      <AppButton
                        label="End Second Half"
                        onClick={() => {
                          setIsMatchHalfEnd(true);
                        }}
                      />
                    )}
                  {match.competition.teamForm === TournamentFormEnum.Form11 &&
                    selectedMatchState?.type === MatchStateTypeEnum.SecondHalf &&
                    selectedMatchState.state === MatchStateEnum.Completed && (
                      <AppButton
                        label="Start First Half (ET)"
                        onClick={() => {
                          setIsMatchHalfStart(true);
                        }}
                      />
                    )}
                  {match.competition.teamForm === TournamentFormEnum.Form11 &&
                    selectedMatchState?.type === MatchStateTypeEnum.ExtraTimeFirstHalf &&
                    selectedMatchState.state === MatchStateEnum.InProgress && (
                      <AppButton
                        label="End First Half (ET)"
                        onClick={() => {
                          setIsMatchHalfEnd(true);
                        }}
                      />
                    )}
                  {match.competition.teamForm === TournamentFormEnum.Form11 &&
                    selectedMatchState?.type === MatchStateTypeEnum.ExtraTimeFirstHalf &&
                    selectedMatchState.state === MatchStateEnum.Completed && (
                      <AppButton
                        label="Start Second Half (ET)"
                        onClick={() => {
                          setIsMatchHalfStart(true);
                        }}
                      />
                    )}
                  {match.fixtureGroup.type === MatchFixtureGroupType.KnockOut && (
                    <>
                      {selectedMatchState?.type === MatchStateTypeEnum.ExtraTimeSecondHalf &&
                        selectedMatchState.state === MatchStateEnum.InProgress &&
                        match.competition.teamForm === TournamentFormEnum.Form11 && (
                          <AppButton
                            label="End Second Half (ET)"
                            variant="light"
                            onClick={() => {
                              setIsMatchHalfEnd(true);
                            }}
                          />
                        )}
                      {selectedMatchState?.type === MatchStateTypeEnum.SecondHalf &&
                        selectedMatchState.state === MatchStateEnum.InProgress &&
                        match.competition.teamForm === TournamentFormEnum.Form7 && (
                          <AppButton
                            label="End Second Half"
                            variant="light"
                            onClick={() => {
                              setIsMatchHalfEnd(true);
                            }}
                          />
                        )}
                      {((match.competition.teamForm === TournamentFormEnum.Form11 &&
                        selectedMatchState?.type === MatchStateTypeEnum.ExtraTimeSecondHalf) ||
                        (selectedMatchState?.type === MatchStateTypeEnum.SecondHalf &&
                          match.competition.teamForm === TournamentFormEnum.Form7)) &&
                        selectedMatchState.state === MatchStateEnum.Completed && (
                          <AppButton
                            label="To Penalties"
                            onClick={() => {
                              setIsMatchPenalty(true);
                            }}
                          />
                        )}
                    </>
                  )}
                  {(selectedMatchState?.type === MatchStateTypeEnum.ExtraTimeSecondHalf ||
                    selectedMatchState?.type === MatchStateTypeEnum.SecondHalf) &&
                    selectedMatchState.state === MatchStateEnum.InProgress && (
                      <AppButton
                        label="End Game"
                        onClick={() => {
                          setIsMatchEnd(true);
                        }}
                      />
                    )}
                </AppBox>
              )}
            </AppBox>
          </AppBorderBox>
        )}
        <AppBox p="sm">
          <AppTabs
            as="a"
            onClick={(e) => {
              setActiveItem(e);
              window.scrollTo(0, 0);
            }}
            activeValue={currentTab}
            tabItems={tabItems}
          />
        </AppBox>
      </AppPaper>
      <AppModal
        opened={isMatchStart}
        onClose={(e) => {
          setIsMatchStart(false);
        }}
        title="Add Details To Start The Match"
        titleIcon={<IconRibbonStar />}
        withoutButtons
      >
        <MatchStartDetail
          match={match}
          onMatchStart={() => setIsMatchStart(false)}
          onCancel={() => setIsMatchStart(false)}
        />
      </AppModal>
      {selectedMatchState && (
        <AppModal
          opened={isAddTime}
          onClose={(e) => {
            setIsAddTime(false);
          }}
          title="Add Injury Time"
          titleIcon={<IconRibbonStar />}
          withoutButtons
        >
          <MatchAddInjuryTime
            match={match}
            state={selectedMatchState}
            onAddTime={() => {
              setIsAddTime(false);
            }}
            onCancel={() => setIsAddTime(false)}
          />
        </AppModal>
      )}
      {selectedMatchState && (
        <AppModal
          opened={isMatchHalfEnd}
          onClose={(e) => {
            setIsMatchHalfEnd(false);
          }}
          title="Do You Want To End The Half?"
          titleIcon={<IconQuestionCircle />}
          confirmButtonLabel="Yes"
          withoutCancelButton
          onSave={handleHalfEnd}
          withLoadingButton={seasonMatchStateChangeResponseStatus === StatusEnum.Loading}
        >
          <AppText>
            Do you want to end{" "}
            {(selectedMatchState.type === MatchStateTypeEnum.FirstHalf && "first half") ||
              (selectedMatchState.type === MatchStateTypeEnum.SecondHalf && "second half") ||
              (selectedMatchState.type === MatchStateTypeEnum.ExtraTimeFirstHalf && "extra time first half") ||
              (selectedMatchState.type === MatchStateTypeEnum.ExtraTimeSecondHalf && "extra time second half")}
            ?
          </AppText>
        </AppModal>
      )}
      {selectedMatchState && (
        <AppModal
          opened={isMatchHalfStart}
          onClose={(e) => {
            setIsMatchHalfStart(false);
          }}
          title="Do You Want To Start The Half?"
          titleIcon={<IconQuestionCircle />}
          confirmButtonLabel="Yes"
          withoutCancelButton
          onSave={handleHalfStart}
          withLoadingButton={seasonMatchStateChangeResponseStatus === StatusEnum.Loading}
        >
          <AppText>
            Do you want to start{" "}
            {(selectedMatchState.type === MatchStateTypeEnum.FirstHalf && "second half") ||
              (selectedMatchState.type === MatchStateTypeEnum.SecondHalf && "extra time first half") ||
              (selectedMatchState.type === MatchStateTypeEnum.ExtraTimeFirstHalf && "extra time second half")}
            ?
          </AppText>
        </AppModal>
      )}
      <AppModal
        opened={isMatchEnd}
        onClose={(e) => {
          setIsMatchEnd(false);
        }}
        title="Do You Want To End The Match?"
        titleIcon={<IconQuestionCircle />}
        confirmButtonLabel="Yes"
        withoutCancelButton
        onSave={handleMatchEnd}
        withLoadingButton={seasonMatchEndResponseStatus === StatusEnum.Loading}
      >
        <AppText>
          Do you want to end the match between {match.homeTeam?.shortName} and {match.awayTeam?.shortName}?
        </AppText>
      </AppModal>
      {match && (
        <>
          <AppModal
            opened={isMatchEdit}
            onClose={() => setMatchEdit(false)}
            titleIcon={<IconEdit />}
            title="Edit Match Details"
            withoutButtons
          >
            <MatchEdit
              matchData={match}
              onCancel={() => {
                setMatchEdit(false);
              }}
              onEdit={() => {
                setMatchEdit(false);
              }}
            />
          </AppModal>
          <AppModal
            modalSize="xl"
            opened={isMatchPenalty}
            onClose={() => {
              handlePenaltyClose();
              setIsMatchPenalty(false);
            }}
            titleIcon={<IconFootball />}
            title="Match Penalties"
            withoutButtons
          >
            <MatchPenaltiesEntry
              match={match}
              onEndGame={() => {
                handlePenaltyClose();
                setIsMatchPenalty(false);
                setIsMatchEnd(true);
              }}
            />
          </AppModal>
        </>
      )}
    </>
  );
}
