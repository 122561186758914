import { Outlet, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { useEffect } from "react";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { AppBox, AppContainer, NotFound } from "../../../commons/components";
import { setPageTitle } from "../../../redux/slices/page-title-slice";
import {
  cleanUpSeasonMatch,
  fetchSeasonMatchDetail,
} from "../../../redux/slices/football/admin/season/match/manage-season-match-detail-slice";
import MatchDetailManageHeader from "./components/MatchDetailManageHeader";
import { cleanUpManageTeamPlayers } from "../../../redux/slices/football/admin/team/manage-team-players-slice";
import setDocumentTitle from "../../../commons/utilities/document-title";
import { MatchStatusEnum } from "../../../commons/enums/match-status-enum";
import { toTitleCase } from "../../../commons/utilities/string-utils";
import { cleanUpSeasonMatchPlayersStatisticList } from "../../../redux/slices/football/admin/season/match/statistics/manage-season-match-players-statistic-list-slice";
import { cleanUpSeasonMatchPlayerPassStatisticList } from "../../../redux/slices/football/admin/season/match/statistics/manage-season-match-players-pass-statistic-list-slice";
import { cleanUpSeasonMatchPossessionList } from "../../../redux/slices/football/admin/season/match/statistics/manage-season-match-possession-list-slice";

export default function MatchDetailManage() {
  const { matchId, seasonId } = useParams();
  const dispatch = useAppDispatch();
  const { seasonMatchDetail, seasonMatchDetailStatus } = useAppSelector(
    (state) => state.footballManageSeasonMatchDetail
  );
  useEffect(() => {
    if (seasonId && matchId) {
      dispatch(
        fetchSeasonMatchDetail({
          seasonId: Number(seasonId),
          matchId: Number(matchId),
        })
      );
    }
  }, [matchId]);

  useEffect(() => {
    if (seasonMatchDetail.status === MatchStatusEnum.Upcoming) {
      setDocumentTitle([
        `${toTitleCase(seasonMatchDetail.homeTeam?.shortName || "")} vs ${toTitleCase(
          seasonMatchDetail.awayTeam?.shortName || ""
        )}`,
      ]);
    } else {
      setDocumentTitle([
        `${toTitleCase(seasonMatchDetail.homeTeam?.shortName || "")} ${seasonMatchDetail.homeGoals}`,
        `${seasonMatchDetail?.awayGoals} ${toTitleCase(seasonMatchDetail.awayTeam?.shortName || "")}`,
      ]);
    }
  }, [seasonMatchDetail]);

  useEffect(() => {
    if (seasonMatchDetailStatus === StatusEnum.Failed) {
      return () => {
        dispatch(cleanUpSeasonMatch());
      };
    }
  }, [seasonMatchDetailStatus]);

  useEffect(() => {
    dispatch(setPageTitle(""));
    return () => {
      dispatch(cleanUpManageTeamPlayers());
      dispatch(cleanUpSeasonMatchPlayersStatisticList());
      dispatch(cleanUpSeasonMatchPlayerPassStatisticList());
      dispatch(cleanUpSeasonMatchPossessionList());
    };
  }, []);

  return (
    <AppContainer>
      {seasonMatchDetailStatus === StatusEnum.Failed ? (
        <NotFound />
      ) : (
        <AppBox mt="md" flexDirection="column" gap="md">
          <MatchDetailManageHeader match={seasonMatchDetail} />
          <Outlet />
        </AppBox>
      )}
    </AppContainer>
  );
}
