import { AppBox, AppButton, AppGridBox, AppInput, AppText } from "../../../../../commons/components";
import { Controller, useForm } from "react-hook-form";
import { findInputError, isFormInvalid } from "../../../../../commons/utilities/form-utils";
import { FieldErrors, FieldValues } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import { setStepperFormData } from "../../../../../redux/slices/stepper-form-slice";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { IManageFootballReferee } from "../../../../../commons/models/football/admin/interface/i-manage-football-referee";
import { IRefereeDTO } from "../../../../../api-services/football/admin/manage-football-referee-service";
import { toSentenceCase } from "../../../../../commons/utilities/string-utils";

interface StadiumBasicInfoAddEditProps {
  refereeData?: IManageFootballReferee | null;
  formStep: number;
  formErrors: FieldErrors<FieldValues>;
  submitStatus: StatusEnum;
  onSubmit: (currentStep: number) => void;
  onBack: (currentStep: number) => void;
  onClearError: (errorKey: string) => void;
}

const StadiumBasicInfoAddEdit = ({
  refereeData,
  formStep,
  formErrors,
  submitStatus,
  onSubmit,
  onBack,
  onClearError,
}: StadiumBasicInfoAddEditProps) => {
  const methods = useForm();
  const dispatch = useAppDispatch();
  const formData = useAppSelector<IRefereeDTO>((state) => state.stepperForm.stepperFormData);

  const convertValue = (key: string, value: string): any => {
    const numericKeys = ["status"];
    return numericKeys.includes(key) ? Number(value) : value;
  };

  const handleInputChange = (value: string, key: string) => {
    const refereeData: IRefereeDTO = {
      ...formData,
      [key]: convertValue(key, value),
    };
    dispatch(setStepperFormData<IRefereeDTO>(refereeData));
    onClearError(key);
  };

  return (
    <AppBox flexDirection="column" justifyContent="space-between" gap="sm" className="flex-1">
      <AppBox flexDirection="column" gap="sm" className="w-100">
        <Controller
          name="firstName"
          defaultValue={refereeData ? refereeData.firstName : ""}
          control={methods.control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                id="firstName"
                label="First Name *"
                placeholder="Enter referee's first name"
                type="text"
                defaultValue={value}
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "firstName");
                }}
                flex="row"
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "firstName")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.firstName?.message ?? "First name is required."}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="middleName"
          defaultValue={refereeData ? refereeData.middleName : ""}
          control={methods.control}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                id="middleName"
                label="Middle Name"
                placeholder="Enter referee's middle name"
                type="text"
                defaultValue={value}
                flex="row"
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "middleName");
                }}
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "middleName")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.middleName?.message && formErrors.middleName.message}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="lastName"
          defaultValue={refereeData ? refereeData.lastName : ""}
          control={methods.control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                id="lastName"
                label="Last Name *"
                placeholder="Enter referee's last name"
                type="text"
                defaultValue={value}
                flex="row"
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "lastName");
                }}
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "lastName")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.lastName?.message ?? "Last name is required."}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
      </AppBox>
      <AppBox gap="xs" justifyContent="end">
        <AppButton
          label="Back"
          variant="outline"
          color="gray"
          borderLight
          onClick={() => {
            onBack(formStep);
          }}
          disabled={submitStatus === StatusEnum.Loading}
        />
        <AppButton
          type="submit"
          label={refereeData ? "Save Changes" : "Create"}
          onClick={() => {
            onSubmit(formStep);
          }}
          loading={submitStatus === StatusEnum.Loading}
        />
      </AppBox>
    </AppBox>
  );
};

export default StadiumBasicInfoAddEdit;
