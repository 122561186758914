import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { IMatchPenaltyDTO } from "../../../../../../api-services/football/admin/manage-football-season-service";
import { manageFootballSeasonService } from "../../../../../../commons";

export interface IManageMatchPenaltyAddEditDeleteState {
  manageMatchPenaltyAddStatus: StatusEnum;
  manageMatchPenaltyEditStatus: StatusEnum;
  manageMatchPenaltyDeleteStatus: StatusEnum;
}

export interface IManageMatchPenaltyAddOption {
  seasonId: number;
  matchId: number;
  penaltyInfo: IMatchPenaltyDTO;
}
export interface IManageMatchPenaltyEditOption {
  seasonId: number;
  matchId: number;
  penaltyId: number;
  penaltyInfo: IMatchPenaltyDTO;
}
export interface IManageMatchPenaltyDeleteOption {
  seasonId: number;
  matchId: number;
  penaltyId: number;
}

const initialState: IManageMatchPenaltyAddEditDeleteState = {
  manageMatchPenaltyAddStatus: StatusEnum.Idle,
  manageMatchPenaltyEditStatus: StatusEnum.Idle,
  manageMatchPenaltyDeleteStatus: StatusEnum.Idle,
};

export const AddMatchPenalty = createAsyncThunk(
  "manage/MatchPenaltyAdd",
  async (options: IManageMatchPenaltyAddOption, thunkAPI) => {
    try {
      const managePenaltyData = await manageFootballSeasonService.addSeasonMatchPenalty(
        options.seasonId,
        options.matchId,
        options.penaltyInfo
      );
      return managePenaltyData;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const EditMatchPenalty = createAsyncThunk(
  "manage/MatchPenaltyEdit",
  async (options: IManageMatchPenaltyEditOption, thunkAPI) => {
    try {
      const managePenaltyData = await manageFootballSeasonService.editSeasonMatchPenalty(
        options.seasonId,
        options.matchId,
        options.penaltyId,
        options.penaltyInfo
      );
      return managePenaltyData;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const DeleteMatchPenalty = createAsyncThunk(
  "manage/MatchPenaltyDelete",
  async (options: IManageMatchPenaltyDeleteOption, thunkAPI) => {
    try {
      const managePenaltyData = await manageFootballSeasonService.deleteSeasonMatchPenalty(
        options.seasonId,
        options.matchId,
        options.penaltyId
      );
      return managePenaltyData;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageMatchPenaltyAddEditSlice = createSlice({
  name: "manageMatchPenaltyAddEditSlice",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(AddMatchPenalty.pending, (state) => {
        state.manageMatchPenaltyAddStatus = StatusEnum.Loading;
      })
      .addCase(AddMatchPenalty.fulfilled, (state) => {
        state.manageMatchPenaltyAddStatus = StatusEnum.Succeeded;
      })
      .addCase(AddMatchPenalty.rejected, (state) => {
        state.manageMatchPenaltyAddStatus = StatusEnum.Failed;
      })
      .addCase(EditMatchPenalty.pending, (state) => {
        state.manageMatchPenaltyEditStatus = StatusEnum.Loading;
      })
      .addCase(EditMatchPenalty.fulfilled, (state) => {
        state.manageMatchPenaltyEditStatus = StatusEnum.Succeeded;
      })
      .addCase(EditMatchPenalty.rejected, (state) => {
        state.manageMatchPenaltyEditStatus = StatusEnum.Failed;
      })
      .addCase(DeleteMatchPenalty.pending, (state) => {
        state.manageMatchPenaltyDeleteStatus = StatusEnum.Loading;
      })
      .addCase(DeleteMatchPenalty.fulfilled, (state) => {
        state.manageMatchPenaltyDeleteStatus = StatusEnum.Succeeded;
      })
      .addCase(DeleteMatchPenalty.rejected, (state) => {
        state.manageMatchPenaltyDeleteStatus = StatusEnum.Failed;
      });
  },
});

export default manageMatchPenaltyAddEditSlice.reducer;
