import { AppBox, AppLink, AppPaper, AppTitle } from "../../../../commons/components";
import { generateInitials } from "../../../../commons/utilities/string-utils";

interface AssociatedTeamOrLeagueProps {
  logo: string;
  name: string;
  isLeague?: boolean;
  teamOrLeagueId: number;
}

export default function AssociatedTeamOrLeague({ logo, name, isLeague, teamOrLeagueId }: AssociatedTeamOrLeagueProps) {
  const teamPath = `/teams/${teamOrLeagueId}`;
  const competitionPath = `/tournaments/${teamOrLeagueId}`;
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <AppPaper className="associated-league-card" padding="none" withBorder>
      <AppLink to={isLeague ? competitionPath : teamPath} onClick={handleClick}>
        <AppBox p="2xs" alignItems="center" justifyContent="center">
          {logo ? (
            <img className="associated-image" crossOrigin="anonymous" alt={name} src={logo} />
          ) : (
            <AppBox className="associated-image" alignItems="center" justifyContent="center">
              <AppTitle as="h4" textAlign="center" color="currentColor">
                {generateInitials(name)}
              </AppTitle>
            </AppBox>
          )}
        </AppBox>
      </AppLink>
    </AppPaper>
  );
}
