import { AppBox, AppGridBox, AppLink, AppText } from "../../../../../commons/components";
import { FormProvider, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import { useSnackbar } from "../../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { IServerErrorResponse } from "../../../../../commons/components/interface";
import AppBorderBox from "../../../../../commons/components/BorderBox";
import RefereeImageAddEdit from "./RefereeImageAddEdit";
import StadiumBasicInfoAddEdit from "./RefereeBasicInfoAddEdit";
import { cleanUpStepperFormData, setStepperFormData } from "../../../../../redux/slices/stepper-form-slice";
import { getErrorKeyPosition } from "../../../../../commons/utilities/form-utils";
import { IManageFootballReferee } from "../../../../../commons/models/football/admin/interface/i-manage-football-referee";
import { IRefereeDTO } from "../../../../../api-services/football/admin/manage-football-referee-service";
import { refereeEdit } from "../../../../../redux/slices/football/admin/referee/manage-referee-edit-slice";
import { refereeAdd } from "../../../../../redux/slices/football/admin/referee/manage-referee-add-slice";
import { cleanUpToolbarSearchTitle } from "../../../../../redux/slices/toolbar-search-slice";
import { fetchManageRefereeList } from "../../../../../redux/slices/football/admin/referee/manage-referee-list-slice";
import { StatusEnum } from "../../../../../commons/enums/status-enum";

interface RefereeAddEditProps {
  refereeData?: IManageFootballReferee | null;
  onCancel?: () => void;
  onSave: () => void;
}

const stepperFormOptions = [
  {
    step: 1,
    title: "Referee's Image",
  },
  {
    step: 2,
    title: "Basic Information",
  },
];

const stepperFromKeyMap = [["avatar"], ["firstName", "middleName", "lastName"]];

export default function RefereeAddEdit({ refereeData, onCancel, onSave }: RefereeAddEditProps) {
  const methods = useForm();
  const formErrors = methods.formState.errors;
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const [selectedStepperFormOption, setSelectedStepperFormOption] = useState(stepperFormOptions[0]);
  const formData = useAppSelector<IRefereeDTO>((state) => state.stepperForm.stepperFormData);
  const { refereeAddResponseStatus } = useAppSelector((state) => state.footballManageRefereeAdd);
  const { refereeEditResponseStatus } = useAppSelector((state) => state.footballManageRefereeEdit);
  const { uploadedImageStatus } = useAppSelector((state) => state.imageUpload);

  //initiate the stepperData
  useLayoutEffect(() => {
    if (!refereeData) {
      const refereeData: IRefereeDTO = {
        firstName: "",
        lastName: "",
        middleName: "",
        avatar: null,
      };
      dispatch(setStepperFormData<IRefereeDTO>(refereeData));
    }
  }, []);

  useLayoutEffect(() => {
    if (refereeData) {
      const refereeDTO: IRefereeDTO = {
        firstName: refereeData.firstName,
        lastName: refereeData.lastName,
        middleName: refereeData.middleName,
        avatar: refereeData.avatar,
      };
      dispatch(setStepperFormData<IRefereeDTO>(refereeDTO));
    }
  }, [refereeData]);

  const handleNext = (currentStep: number) => {
    if (
      refereeAddResponseStatus !== StatusEnum.Loading &&
      refereeEditResponseStatus !== StatusEnum.Loading &&
      uploadedImageStatus !== StatusEnum.Loading
    ) {
      setSelectedStepperFormOption(stepperFormOptions[currentStep]);
    }
  };
  const handleBack = (currentStep: number) => {
    if (
      refereeAddResponseStatus !== StatusEnum.Loading &&
      refereeEditResponseStatus !== StatusEnum.Loading &&
      uploadedImageStatus !== StatusEnum.Loading
    ) {
      setSelectedStepperFormOption(stepperFormOptions[currentStep - 2]);
    }
  };

  const handleStepperOptionClick = (option: any) => {
    if (
      refereeAddResponseStatus !== StatusEnum.Loading &&
      refereeEditResponseStatus !== StatusEnum.Loading &&
      uploadedImageStatus !== StatusEnum.Loading
    ) {
      setSelectedStepperFormOption(option);
    }
  };

  const handleFormSubmit = () => {
    const formattedValues: IRefereeDTO = {
      ...formData,
      avatar: formData.avatar ? formData.avatar : null,
    };
    dispatch(
      refereeData
        ? refereeEdit({ refereeId: refereeData.id, refereeData: formattedValues })
        : refereeAdd({ refereeData: formattedValues })
    )
      .unwrap()
      .then(() => {
        if (refereeData) {
          dispatch(cleanUpToolbarSearchTitle());
          addSnackbar({
            key: "referee-edit-success",
            text: "Referee Edited Successfully",
            variant: "success",
          });
        } else {
          addSnackbar({
            key: "referee-add-success",
            text: "Referee Added Successfully",
            variant: "success",
          });
        }
        dispatch(cleanUpStepperFormData());
        dispatch(fetchManageRefereeList({ sort: "createdAt", sortOrder: "DESC" }));
        onSave();
      })
      .catch((error: AxiosError<IServerErrorResponse>) => {
        const responseData = error.response?.data;
        if (error.response?.status === 417) {
          addSnackbar({
            key: "error",
            text: "Form not valid",
            variant: "danger",
          });
          if (responseData) {
            const stepperPositionSet = new Set<number>();
            Object.entries(responseData).forEach(([field, messages]) => {
              messages.forEach((message: string) => {
                methods.setError(field, { message });
                const stepperPosition = getErrorKeyPosition(field, stepperFromKeyMap);
                if (stepperPosition) {
                  stepperPositionSet.add(stepperPosition.step);
                }
                const lowestStep = Math.min(...Array.from(stepperPositionSet));
                setSelectedStepperFormOption(stepperFormOptions[lowestStep]);
              });
            });
          }
        } else {
          addSnackbar({
            key: "error",
            text: responseData?.message,
            variant: "danger",
          });
        }
      });
  };

  const clearErrorField = (key: string) => {
    methods.clearErrors(key);
  };

  return (
    <FormProvider {...methods}>
      <AppBox flexDirection="column" gap="sm" style={{ height: "var(--modal-md)" }}>
        <AppGridBox className=" flex-1" style={{ gridTemplateColumns: "1fr 3fr" }} gap="sm">
          <AppBorderBox border={["Right"]}>
            <AppBox flexDirection="column" gap="2xs" className="w-100">
              {stepperFormOptions.map((option, index) => (
                <AppLink
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStepperOptionClick(option);
                  }}
                >
                  <AppBorderBox
                    border={option.step === selectedStepperFormOption.step ? ["Right"] : []}
                    borderColor="primary"
                  >
                    <AppBox pl="sm" py="2xs">
                      <AppText
                        fontWeight="semibold"
                        size="lg"
                        color={option.step === selectedStepperFormOption.step ? "primary" : "currentColor"}
                      >
                        {option.title}
                      </AppText>
                    </AppBox>
                  </AppBorderBox>
                </AppLink>
              ))}
            </AppBox>
          </AppBorderBox>
          <AppBox flexDirection="column" className="w-100 flex-1">
            <AppBox
              className="flex-1"
              style={selectedStepperFormOption.step === 1 ? { display: "flex" } : { display: "none" }}
            >
              <RefereeImageAddEdit
                refereeData={refereeData}
                formStep={selectedStepperFormOption.step}
                formErrors={formErrors}
                onSkipOrNext={handleNext}
                onClearError={clearErrorField}
                submitStatus={refereeEditResponseStatus}
                onSaveAndExit={handleFormSubmit}
              />
            </AppBox>
            <AppBox
              className="flex-1"
              style={selectedStepperFormOption.step === 2 ? { display: "flex" } : { display: "none" }}
            >
              <StadiumBasicInfoAddEdit
                refereeData={refereeData}
                formStep={selectedStepperFormOption.step}
                submitStatus={refereeAddResponseStatus || refereeEditResponseStatus}
                formErrors={formErrors}
                onBack={handleBack}
                onSubmit={handleFormSubmit}
                onClearError={clearErrorField}
              />
            </AppBox>
          </AppBox>
        </AppGridBox>
      </AppBox>
    </FormProvider>
  );
}
