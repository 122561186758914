import { useEffect, useState } from "react";
import {
  AppAvatar,
  AppBox,
  AppDivider,
  AppGeneralSearch,
  AppLink,
  AppSkeletonGlobalCardList,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import { formatDigits, toSentenceCase, toTitleCase } from "../../../../commons/utilities/string-utils";
import { IFootballMatchDetail } from "../../../../commons/models/football/interface/i-football-match";
import { IFootballPlayerLineup } from "../../../../commons/models/football/interface/i-football-player-lineup";
import { IFootballTeam } from "../../../../commons/models/football/interface/i-football-team";
import { MatchEventTypeEnum } from "../../../../commons/enums/match-event-type-enum";
import AppLineUpBadge from "../../../../commons/components/ui-components/LineupBadge";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { fetchManageMatchTeams } from "../../../../redux/slices/football/admin/season/match/manage-season-match-teams-slice";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import { cleanUpGeneralSearchTitle, setGeneralCleanSearchField } from "../../../../redux/slices/general-search-slice";

interface MatchPlayerSearchProps {
  onSelectedPlayer?: (players: IMatchPlayer) => void;
  match: IFootballMatchDetail;
  selectedTeam?: IFootballTeam | null;
  selectedPlayer?: IMatchPlayer | null;
  eventType?: MatchEventTypeEnum;
  isSupportingPlayer?: boolean;
}

export interface IMatchPlayer extends IFootballPlayerLineup {
  team: IFootballTeam | null;
}

const MatchPlayerSearch = ({
  onSelectedPlayer,
  match,
  eventType,
  isSupportingPlayer,
  selectedTeam,
  selectedPlayer,
}: MatchPlayerSearchProps) => {
  const dispatch = useAppDispatch();
  const [allPlayers, setAllPlayers] = useState<IMatchPlayer[]>([]);
  const [filteredPlayers, setFilteredPlayers] = useState<IMatchPlayer[]>([]);
  const [sortedFilteredPlayers, setSortedFilteredPlayers] = useState<IMatchPlayer[]>([]);
  const { manageMatchTeams, manageMatchTeamsStatus } = useAppSelector((state) => state.footballManageSeasonMatchTeams);
  const { generalSearchTitle } = useAppSelector((state) => state.generalSearch);

  useEffect(() => {
    dispatch(setGeneralCleanSearchField(true));
    dispatch(cleanUpGeneralSearchTitle());
  }, []);

  useEffect(() => {
    if (match.id) {
      dispatch(fetchManageMatchTeams({ seasonId: match.season.id, matchId: match.id }));
    }
  }, [match]);

  useEffect(() => {
    if (manageMatchTeams) {
      let allPlayers: IMatchPlayer[] = [];
      manageMatchTeams.home.forEach((player) => {
        allPlayers.push({
          ...player,
          team: match.homeTeam,
        });
      });
      manageMatchTeams.away.forEach((player) => {
        allPlayers.push({
          ...player,
          team: match.awayTeam,
        });
      });
      if (selectedTeam) {
        switch (eventType) {
          case MatchEventTypeEnum.Substitution:
          case MatchEventTypeEnum.Goal:
            const filteredTeamPlayers = allPlayers.filter((player) => player.team?.id === selectedTeam.id);
            setAllPlayers(filteredTeamPlayers);
            break;
          default:
            setAllPlayers(allPlayers);
            break;
        }
      } else {
        setAllPlayers(allPlayers);
      }
    }
  }, [manageMatchTeams]);

  useEffect(() => {
    setFilteredPlayers(handlePlayerFiltration());
  }, [allPlayers]);

  useEffect(() => {
    if (filteredPlayers.length > 0) {
      const customOrder = ["GOALKEEPER", "DEFENDER", "MIDFIELDER", "FORWARD"];
      let sortedData = filteredPlayers.sort((a, b) => {
        return customOrder.indexOf(a.position as string) - customOrder.indexOf(b.position as string);
      });
      if (eventType === MatchEventTypeEnum.Goal) {
        sortedData.reverse();
      }
      setSortedFilteredPlayers(sortedData);
    } else {
      setSortedFilteredPlayers([]);
    }
  }, [filteredPlayers]);

  const handlePlayerClick = (player: IMatchPlayer) => {
    if (onSelectedPlayer) {
      onSelectedPlayer(player);
    }
  };

  useEffect(() => {
    let players = handlePlayerFiltration();
    if (generalSearchTitle && players.length > 0) {
      const filteredArray = players.filter((player) => {
        return (
          player.fullName?.toLowerCase().includes(generalSearchTitle.toLowerCase()) ||
          player.displayName?.toLowerCase().includes(generalSearchTitle.toLowerCase())
        );
      });
      setFilteredPlayers(filteredArray);
    } else {
      setFilteredPlayers(players);
    }
  }, [generalSearchTitle]);

  const handlePlayerFiltration = (): IMatchPlayer[] => {
    if (eventType) {
      let filterPlayerArray: IMatchPlayer[] = [];
      switch (eventType) {
        case MatchEventTypeEnum.Substitution:
          if (isSupportingPlayer) {
            filterPlayerArray = allPlayers.filter((player) => player.onBench);
          } else {
            filterPlayerArray = allPlayers.filter((player) => !player.onBench);
          }
          break;
        case MatchEventTypeEnum.Goal:
          filterPlayerArray = allPlayers.filter((player) => !player.onBench);
          if (selectedPlayer) {
            filterPlayerArray = allPlayers.filter((player) => player.id !== selectedPlayer.id);
          }
          break;
        case MatchEventTypeEnum.Foul:
          filterPlayerArray = allPlayers;
          if (selectedPlayer) {
            filterPlayerArray = allPlayers.filter((player) => player.id !== selectedPlayer.id);
          }
          break;
        default:
          filterPlayerArray = allPlayers;
          break;
      }
      return filterPlayerArray;
    } else {
      return allPlayers;
    }
  };

  return (
    <AppBox flexDirection="column" gap="sm">
      <AppBox className="w-100 flex-1">
        <AppGeneralSearch />
      </AppBox>
      <AppBox flexDirection="column" style={{ height: "30rem", overflowY: "scroll" }}>
        {manageMatchTeamsStatus === StatusEnum.Loading ? (
          <AppSkeletonGlobalCardList noTitle numberOfItems={5} withAvatar />
        ) : (
          <>
            {sortedFilteredPlayers.length > 0 ? (
              <>
                {sortedFilteredPlayers.map((player, index) => (
                  <AppBorderBox border={["Bottom"]} py="xs" key={index}>
                    <AppLink onClick={() => handlePlayerClick(player)}>
                      <AppBox justifyContent="space-between" alignItems="center" className="flex-1" pr="xs">
                        <AppBox gap="xs" alignItems="center">
                          <AppAvatar username={player.fullName || ""} src={player.avatar} size="md" />
                          <AppBox flexDirection="column">
                            <AppBox alignItems="center" gap="2xs">
                              <AppTitle as="h6">{toTitleCase(player?.displayName)}</AppTitle>
                              {player.isCaptain && <AppLineUpBadge status="CAPTAIN" size={20} />}
                            </AppBox>
                            <AppBox>
                              <AppText as="span" fontWeight="semibold" size="sm">
                                {formatDigits(player.jerseyNumber)}
                              </AppText>
                              <AppDivider orientation="vertical" mx="3xs" />
                              <AppText as="span" fontWeight="semibold" size="sm">
                                {toSentenceCase(player.position)}
                              </AppText>
                            </AppBox>
                          </AppBox>
                        </AppBox>
                        <AppAvatar username={player.team?.shortName || ""} src={player.team?.logo || ""} size="xs" />
                      </AppBox>
                    </AppLink>
                  </AppBorderBox>
                ))}
              </>
            ) : (
              <AppText>There are no players you searched for</AppText>
            )}
          </>
        )}
      </AppBox>
    </AppBox>
  );
};

export default MatchPlayerSearch;
