import { useEffect } from "react";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { AppBox } from "../../../../commons/components";
import { fetchSeasonKnockoutList } from "../../../../redux/slices/football/public/season/football-season-knockout-list-slice";
import { StandingBracket } from "../../../../commons/components/ui-components/StandingBracket";

interface TournamentStandingBracketProps {
  currentSeasonId: number;
}

export const TournamentStandingBracket = ({ currentSeasonId }: TournamentStandingBracketProps) => {
  const dispatch = useAppDispatch();
  const { seasonKnockoutList } = useAppSelector((state) => state.footballSeasonKnockoutList);
  const { competition } = useAppSelector((state) => state.footballCompetition);

  useEffect(() => {
    if (currentSeasonId) {
      dispatch(fetchSeasonKnockoutList({ seasonId: currentSeasonId }));
    }
  }, [currentSeasonId]);

  return (
    <AppBox gap="sm" flexDirection="column">
      <StandingBracket knockoutTree={seasonKnockoutList} competition={competition.data} />
    </AppBox>
  );
};
