import { useLayoutEffect, useState } from "react";
import { AppBox } from "../../../commons/components";
import { useAppSelector } from "../../../hooks/app";
import { TournamentTypeEnum } from "../../../commons/enums/tournament-type-enum";
import AppTabs, { TabItem } from "../../../commons/components/Tabs";
import EventsFixtureComponent from "./components/EventsFixtureComponent";
import EventsBracketComponent from "./components/EventsBracketComponent";

export default function TournamentManageEvents() {
  const { competitionInfo } = useAppSelector((state) => state.footballManageCompetition);
  const [eventsType, setEventsType] = useState<string>();
  const GROUPS = "groups";
  const KNOCKOUTS = "knockouts";

  const tabItems = [
    {
      label: "Groups",
      value: GROUPS,
    },
    {
      label: "Knockouts",
      value: KNOCKOUTS,
    },
  ];

  useLayoutEffect(() => {
    if (competitionInfo.type === TournamentTypeEnum.Hybrid) {
      setEventsType(GROUPS);
    }
  }, [competitionInfo]);

  const handleTabChange = (args: TabItem) => {
    setEventsType(args.value);
  };

  return (
    <AppBox mb="sm" gap="sm" flexDirection="column">
      {competitionInfo.type === TournamentTypeEnum.Hybrid && (
        <AppBox>
          <AppTabs
            as="button"
            onClick={handleTabChange}
            tabItems={tabItems}
            variant="filled"
            shadow="xs"
            activeValue={eventsType}
            isFilterTabs
          />
        </AppBox>
      )}
      {(eventsType === GROUPS || competitionInfo.type === TournamentTypeEnum.League) && (
        <EventsFixtureComponent competition={competitionInfo} />
      )}
      {(eventsType === KNOCKOUTS || competitionInfo.type === TournamentTypeEnum.Knockout) && (
        <EventsBracketComponent competition={competitionInfo} />
      )}
    </AppBox>
  );
}
