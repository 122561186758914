import { useState } from "react";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppContainer,
  AppDatePicker,
  AppDivider,
  AppDoughnutChart,
  AppPaper,
  AppSelect,
  AppSkeleton,
  AppTab,
  AppText,
  AppTimePicker,
  AppTitle,
} from "../commons/components";
import AppGridBox from "../commons/components/GridBox";
import IconAlert from "../commons/components/icons/alert";
import IconCalendar from "../commons/components/icons/calendar";
import IconPeopleTeam from "../commons/components/icons/people-team";
import TripleToggleSwitch from "../commons/components/TripleToggleSwitch";

export default function Components() {
  const tabItems = [
    { label: "Summary", value: "summary" },
    { label: "Profile", value: "profile" },
    { label: "Teams", value: "teams" },
    { label: "Events", value: "events" },
    { label: "Statistics", value: "statistics" },
  ];
  const [currentTime, setCurrentTime] = useState("");
  return (
    <AppContainer
      style={{
        paddingTop: "var(--space-lg)",
        paddingBottom: "var(--space-lg)",
      }}
    >
      <AppBox flexDirection="column" gap="lg">
        <AppPaper withBorder={true}>
          <AppTitle>Triple Toggle Switch</AppTitle>
          <AppDivider my="md" />
          <AppBox justifyContent="center" alignItems="center" gap="sm">
            <AppBox>
              <TripleToggleSwitch switchId={"0"} onChange={() => {}} withoutCenter />
            </AppBox>
          </AppBox>
        </AppPaper>
        <AppPaper withBorder={true}>
          <AppTitle>Time Picker</AppTitle>
          <AppDivider my="md" />
          <AppBox justifyContent="center" flexDirection="column" alignItems="center" gap="sm">
            <AppTimePicker
              onChange={(time) => {
                setCurrentTime(time);
              }}
            />
            <AppText size="lg" fontWeight="bold">
              Selected time = {currentTime}
            </AppText>
          </AppBox>
        </AppPaper>
        <AppPaper withBorder={true}>
          <AppTitle>Chart</AppTitle>
          <AppDivider my="md" />
          <AppBox gap="md" alignItems="end">
            <AppDoughnutChart color="success" info={{ value: 26, label: "Win" }} value={60} />
            <AppDoughnutChart color="info" info={{ value: 6, label: "Draw" }} value={16} />
            <AppDoughnutChart color="danger" info={{ value: 8, label: "Loss" }} value={24} />
          </AppBox>
        </AppPaper>
        <AppPaper withBorder={true}>
          <AppTitle>Avatar</AppTitle>
          <AppDivider my="md" />
          <AppBox gap="md" alignItems="end">
            <AppAvatar username="John Smith" variant="filled" />
            <AppAvatar username="John Smith" variant="outline" />
            <AppAvatar username="John Smith" variant="light" />
            <AppAvatar username="John Smith" variant="filled" color="warning" icon={<IconPeopleTeam />} />
            <AppAvatar username="John Smith" variant="outline" color="warning" icon={<IconPeopleTeam />} />
            <AppAvatar username="John Smith" variant="light" color="warning" icon={<IconPeopleTeam />} />
            <AppAvatar
              username="John Smith"
              src="https://userstock.io/data/wp-content/uploads/2020/06/women-s-white-and-black-button-up-collared-shirt-774909-2-300x300.jpg"
            />
          </AppBox>
        </AppPaper>

        <AppPaper withBorder={true}>
          <AppTitle>Button</AppTitle>
          <AppDivider my="md" />
          <AppBox flexDirection="column" gap="md">
            <AppGridBox columns={4} gap="md">
              <AppButton label="Button" variant="filled" />
              <AppButton label="Button" variant="outline" />
              <AppButton label="Button" variant="light" />
              <AppButton label="Button" variant="subtle" />
            </AppGridBox>

            <AppGridBox columns={4} gap="md">
              <AppButton label="Button" variant="filled" leftSection={<IconAlert />} />
              <AppButton label="Button" variant="outline" leftSection={<IconAlert />} />
              <AppButton label="Button" variant="light" leftSection={<IconAlert />} />
              <AppButton label="Button" variant="subtle" leftSection={<IconAlert />} />
            </AppGridBox>
          </AppBox>
        </AppPaper>

        <AppPaper withBorder={true}>
          <AppTitle>Dropdown</AppTitle>
          <AppDivider my="md" />
          <AppBox>
            <AppSelect
              currentOption={{ title: "hello", icon: <IconCalendar />, value: 45 }}
              withMultiSelect
              options={[
                { title: "hello", icon: <IconCalendar />, value: 45 },
                { title: "hifdasf", icon: <IconCalendar />, value: 54 },
                { title: "hifsadfdsa", icon: <IconCalendar />, value: 53 },
                { title: "hifasdfsfa", icon: <IconCalendar />, value: 55 },
                { title: "hi", icon: <IconCalendar />, value: 56 },
              ]}
            />
          </AppBox>
        </AppPaper>

        <AppPaper withBorder={true}>
          <AppTitle>Date Picker</AppTitle>
          <AppDivider my="md" />
          <AppBox flexDirection="column" gap="xs">
            <AppText>Single date selector :</AppText>
            <AppBox className="w-1/2">
              <AppDatePicker onChange={() => {}} selected={new Date()} />
            </AppBox>
          </AppBox>
        </AppPaper>

        <AppPaper withBorder={true}>
          <AppTitle>Title</AppTitle>
          <AppDivider my="md" />
          <AppBox flexDirection="column">
            <AppBox flexDirection="column" gap="sm">
              <AppTitle as="h1">h1.Title </AppTitle>
              <AppTitle as="h2">h2.Title </AppTitle>
              <AppTitle as="h3">h3.Title </AppTitle>
              <AppTitle as="h4">h4.Title </AppTitle>
              <AppTitle as="h5">h5.Title </AppTitle>
              <AppTitle as="h6">h6.Title </AppTitle>
            </AppBox>
            <AppDivider my="md" />
            <AppBox flexDirection="column" gap="sm">
              <AppTitle as="h6" size="lg">
                Title LG
              </AppTitle>
              <AppTitle as="h6" size="md">
                Title MD
              </AppTitle>
              <AppTitle as="h6" size="sm">
                Title SM
              </AppTitle>
            </AppBox>
          </AppBox>
        </AppPaper>

        <AppPaper withBorder={true}>
          <AppTitle>Text</AppTitle>
          <AppDivider my="md" />
          <AppBox flexDirection="column" style={{ width: "50%" }}>
            <AppBox flexDirection="column" gap="sm">
              <AppText textAlign="left">Aligned left</AppText>
              <AppText textAlign="center">Aligned center</AppText>
              <AppText textAlign="right">Aligned right</AppText>
              <AppText textAlign="start">Aligned start</AppText>
              <AppText textAlign="end">Aligned end</AppText>
              <AppText textAlign="justify">
                Aligned Justify, sit amet consectetur adipisicing elit. Laborum nam inventore illum distinctio
                consequatur sapiente. Sint suscipit delectus quisquam quidem incidunt laudantium odio reiciendis unde
                nihil arei facere.
              </AppText>
            </AppBox>
            <AppDivider my="md" />
            <AppBox flexDirection="column" gap="sm">
              <AppText textDecoration="underline">Text underline</AppText>
              <AppText textDecoration="line-through">Text Line through</AppText>
              <AppText textDecoration="overline">Text overline</AppText>
            </AppBox>
            <AppDivider my="md" />
            <AppBox flexDirection="column" gap="sm">
              <AppText textTransform="capitalize">Text capitalize</AppText>
              <AppText textTransform="uppercase">Text uppercase</AppText>
              <AppText textTransform="lowercase">Text lowercase</AppText>
            </AppBox>
            <AppDivider my="md" />
            <AppBox flexDirection="column" gap="sm">
              <AppText fontStyle="italic">Text italic</AppText>
              <AppText fontStyle="oblique">Text oblique</AppText>
            </AppBox>
            <AppDivider my="md" />
            <AppBox flexDirection="column" gap="sm">
              <AppText fontWeight="thin">thin</AppText>
              <AppText fontWeight="light">light</AppText>
              <AppText fontWeight="normal">normal</AppText>
              <AppText fontWeight="medium">medium</AppText>
              <AppText fontWeight="semibold">semibold</AppText>
              <AppText fontWeight="bold">bold</AppText>
              <AppText fontWeight="extrabold">extrabold</AppText>
            </AppBox>
          </AppBox>
        </AppPaper>

        <AppPaper withBorder={true}>
          <AppTitle>Tabs</AppTitle>
          <AppDivider my="md" />
          <AppTab as="button" tabItems={tabItems} activeValue="summary" variant="filled" shadow="sm" />
        </AppPaper>

        <AppPaper withBorder={true}>
          <AppTitle>Skeleton</AppTitle>
          <AppDivider my="md" />
          <AppSkeleton type="pill" />
        </AppPaper>
      </AppBox>
    </AppContainer>
  );
}
