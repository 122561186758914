import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  getStatisticsEventObject,
  IStatisticsEventObject,
} from "../../../../../commons/utilities/match-or-matchCard-utils";
import { MatchStatisticsEventEnum } from "../../../../../commons/enums/match-statistics-event-enum";
import MatchPlayerSearch, { IMatchPlayer } from "../MatchPlayerSearch";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppGridBox,
  AppIconButton,
  AppInput,
  AppModal,
  AppPaper,
  AppSelect,
  AppText,
  AppTitle,
} from "../../../../../commons/components";
import { statisticsEventsOption } from "../../MatchDetailManageStatistics";
import AppBorderBox from "../../../../../commons/components/BorderBox";
import MatchTimeFormComponent, { matchTimePattern } from "../MatchTimeFormComponent";
import { findInputError, isFormInvalid } from "../../../../../commons/utilities/form-utils";
import { toSentenceCase, toTitleCase } from "../../../../../commons/utilities/string-utils";
import IconDismiss from "../../../../../commons/components/icons/dismiss";
import IconPersonAdd from "../../../../../commons/components/icons/person-add";
import { MatchStatusEnum } from "../../../../../commons/enums/match-status-enum";
import IconPersonAvailable from "../../../../../commons/components/icons/person-available";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import {
  IMatchPlayerPassStatisticDTO,
  IMatchPlayersStatisticDTO,
  IMatchPossessionDTO,
} from "../../../../../api-services/football/admin/manage-football-season-service";
import { seasonMatchPossessionAdd } from "../../../../../redux/slices/football/admin/season/match/statistics/manage-season-match-possession-add-slice";
import { IFootballMatchDetail } from "../../../../../commons/models/football/interface/i-football-match";
import { fetchSeasonMatchPossessionList } from "../../../../../redux/slices/football/admin/season/match/statistics/manage-season-match-possession-list-slice";
import { useSnackbar } from "../../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../../commons/components/interface";
import { seasonMatchPlayersStatisticAdd } from "../../../../../redux/slices/football/admin/season/match/statistics/manage-season-match-players-statistic-add-slice";
import { fetchSeasonMatchPlayersStatisticList } from "../../../../../redux/slices/football/admin/season/match/statistics/manage-season-match-players-statistic-list-slice";
import { ISelectOptions } from "../../../../../commons/components/ui-components/Select";
import { seasonMatchPlayerPassStatisticAdd } from "../../../../../redux/slices/football/admin/season/match/statistics/manage-season-match-players-pass-statistic-add-slice";
import { fetchSeasonMatchPlayerPassStatisticList } from "../../../../../redux/slices/football/admin/season/match/statistics/manage-season-match-players-pass-statistic-list-slice";
import { StatusEnum } from "../../../../../commons/enums/status-enum";

interface MatchStatisticsAddProps {
  match: IFootballMatchDetail;
  statisticMatchEvent: string;
  onSelectedEvent: (event: string) => void;
}

export const MatchStatisticsAdd = ({ match, statisticMatchEvent, onSelectedEvent }: MatchStatisticsAddProps) => {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const [selectedEvent, setSelectedEvent] = useState<string>(MatchStatisticsEventEnum.Possession);
  const [currentStatisticOption, setCurrentStatisticOption] = useState<ISelectOptions>(statisticsEventsOption[0]);
  const [selectedEventObject, setSelectedEventObject] = useState<IStatisticsEventObject>();
  const [formResetToggle, setFormResetToggle] = useState<boolean>(false);
  const [timeResetToggle, setTimeResetToggle] = useState<boolean>(false);
  const [homePossession, setHomePossession] = useState<number>();
  const [awayPossession, setAwayPossession] = useState<number>();
  const [isPlayerSearch, setIsPlayerSearch] = useState(false);
  const [player, setPlayer] = useState<IMatchPlayer | null>();
  const methods = useForm();
  const formErrors = methods.formState.errors;
  const { seasonMatchPossessionAddResponseStatus } = useAppSelector(
    (state) => state.footballManageSeasonMatchPossessionAdd
  );
  const { seasonMatchPlayersStatisticAddResponseStatus } = useAppSelector(
    (state) => state.footballManageSeasonMatchPlayersStatisticAdd
  );
  const { seasonMatchPlayerPassStatisticAddResponseStatus } = useAppSelector(
    (state) => state.footballManageSeasonMatchPlayerPassStatisticAdd
  );

  useEffect(() => {
    handleSelectChange(statisticMatchEvent);
    const matchEvent = statisticsEventsOption.find((eventObject) => eventObject.value === statisticMatchEvent);
    if (matchEvent) {
      setCurrentStatisticOption(matchEvent);
    }
  }, [statisticMatchEvent]);

  useEffect(() => {
    if (selectedEvent) {
      setSelectedEventObject(getStatisticsEventObject(selectedEvent as MatchStatisticsEventEnum));
      onSelectedEvent(selectedEvent);
    }
  }, [selectedEvent]);

  const handleSelectChange = (value: string) => {
    const gameTime = methods.getValues("gameTime");
    methods.reset(); // reset in order to clear other input fields
    methods.setValue("gameTime", gameTime);
    setFormResetToggle(!formResetToggle);
    setPlayer(null);
    setAwayPossession(undefined);
    setHomePossession(undefined);
    setSelectedEvent(value);
  };
  const handleFormSubmit = (formValues: any) => {
    if (match.id && match.season.id) {
      let gameExtraTime = 0;
      let injuryTimeMin = 0;
      let injuryTimeSec = 0;
      const [normalTime, injuryTime] = formValues.gameTime.split("+");
      const [normalTimeMin, normalTimeSec] = normalTime.split(":");
      if (injuryTime) {
        [injuryTimeMin, injuryTimeSec] = injuryTime.split(":");
      }
      const gameTime = Number(normalTimeMin) * 60 + Number(normalTimeSec);
      if (injuryTimeMin && injuryTimeSec) {
        gameExtraTime = Number(injuryTimeMin) * 60 + Number(injuryTimeSec);
      }
      if (selectedEvent === MatchStatisticsEventEnum.Interception) {
        const formattedPlayersStatInput: IMatchPlayersStatisticDTO = {
          key: selectedEvent,
          value: Number(formValues.value),
          gameTime: gameTime,
          gameExtraTime: gameExtraTime || null,
        };
        dispatch(
          seasonMatchPlayersStatisticAdd({
            matchId: match.id,
            seasonId: match.season.id,
            playerId: formValues.playerId,
            matchPlayersStatisticData: formattedPlayersStatInput,
          })
        )
          .unwrap()
          .then(() => {
            resetForm();
            addSnackbar({
              key: "stats-add-success",
              text: "Statistics Added Successfully",
              variant: "success",
            });
            dispatch(
              fetchSeasonMatchPlayersStatisticList({
                matchId: match.id,
                seasonId: match.season.id,
                urlParams: { key: selectedEvent },
              })
            );
          })
          .catch(handleError);
      }
      if (selectedEvent === MatchStatisticsEventEnum.Possession) {
        const formattedPossessionInput: IMatchPossessionDTO = {
          homePossession: Number(formValues.homePossession),
          gameTime: gameTime,
          gameExtraTime: gameExtraTime || null,
        };
        dispatch(
          seasonMatchPossessionAdd({
            matchId: match.id,
            seasonId: match.season.id,
            matchPossessionData: formattedPossessionInput,
          })
        )
          .unwrap()
          .then(() => {
            resetForm();
            addSnackbar({
              key: "possession-stats-add-success",
              text: "Possession Added Successfully",
              variant: "success",
            });
            dispatch(fetchSeasonMatchPossessionList({ matchId: match.id, seasonId: match.season.id }));
          })
          .catch(handleError);
      }
      if (selectedEvent === MatchStatisticsEventEnum.Passes) {
        const formattedPossessionInput: IMatchPlayerPassStatisticDTO = {
          passes: Number(formValues.passes),
          passSucceeded: Number(formValues.passSucceeded),
          gameTime: gameTime,
          gameExtraTime: gameExtraTime || null,
        };
        dispatch(
          seasonMatchPlayerPassStatisticAdd({
            matchId: match.id,
            seasonId: match.season.id,
            playerId: formValues.playerId,
            matchPlayerPassStatisticData: formattedPossessionInput,
          })
        )
          .unwrap()
          .then(() => {
            resetForm();
            addSnackbar({
              key: "pass-stats-add-success",
              text: "Statistics Added Successfully",
              variant: "success",
            });
            dispatch(fetchSeasonMatchPlayerPassStatisticList({ matchId: match.id, seasonId: match.season.id }));
          })
          .catch(handleError);
      }
    }
  };
  const handleError = (error: AxiosError<IServerErrorResponse>) => {
    const responseData = error.response?.data;
    if (error.response?.status === 417) {
      addSnackbar({
        key: "error",
        text: "Form not valid",
        variant: "danger",
      });
      if (responseData) {
        Object.entries(responseData).forEach(([field, messages]) => {
          messages.forEach((message: string) => {
            methods.setError(field, { message });
          });
        });
      }
    } else {
      addSnackbar({
        key: "error",
        text: responseData?.message,
        variant: "danger",
      });
    }
  };
  const handlePlayerChoose = (e: Event) => {
    e.preventDefault();
    setIsPlayerSearch(true);
  };
  const handleMatchPlayerSelection = (player: IMatchPlayer) => {
    setPlayer(player);
    setIsPlayerSearch(false);
  };
  const resetForm = () => {
    methods.reset();
    setFormResetToggle(!formResetToggle);
    setTimeResetToggle(!timeResetToggle);
    setPlayer(null);
    setAwayPossession(undefined);
    setHomePossession(undefined);
  };

  const handleHomePossessionChange = (value: number) => {
    const awayValue = 100 - value;
    setHomePossession(value);
    setAwayPossession(awayValue);
    methods.setValue("homePossession", value);
    methods.setValue("awayPossession", awayValue);
  };

  const handleAwayPossessionChange = (value: number) => {
    const homeValue = 100 - value;
    setAwayPossession(value);
    setHomePossession(homeValue);
    methods.setValue("awayPossession", value);
    methods.setValue("homePossession", homeValue);
  };

  const handleMatchTimeChange = (value: string) => {
    const [gameTime, gameExtraTime] = value.split(" + ");
    methods.setValue("gameTime", gameTime);
    methods.setValue("gameExtraTime", gameExtraTime || "");
    methods.clearErrors(["gameTime", "gameExtraTime"]);
  };

  return (
    <>
      <AppPaper padding="sm" radius="xs" shadow="xs">
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit((e) => {
              methods.formState.isValid && handleFormSubmit(e as any);
            })}
          >
            <AppBox flexDirection="column" gap="sm">
              <AppBorderBox border={["Bottom"]} pb="sm">
                <AppGridBox columns={2} gap="sm">
                  <>
                    <AppBox flexDirection="column" gap="2xs">
                      <AppText size="lg" fontWeight="semibold">
                        Match Event
                      </AppText>
                      <AppSelect
                        disabled={match.status === MatchStatusEnum.Upcoming}
                        options={statisticsEventsOption}
                        onChange={(option) => {
                          handleSelectChange(option.value.toString());
                        }}
                        currentOption={currentStatisticOption}
                      />
                    </AppBox>
                    {isFormInvalid(findInputError(formErrors, "key")) && (
                      <AppText as="span" color="danger">
                        <>{toSentenceCase(`${formErrors.key?.message ?? "key is required"}`)}</>
                      </AppText>
                    )}
                  </>
                  <Controller
                    name="gameTime"
                    defaultValue={""}
                    rules={{
                      required: true,
                      pattern: matchTimePattern,
                    }}
                    render={({ field: { onChange } }) => (
                      <>
                        <MatchTimeFormComponent
                          match={match}
                          onChange={(value) => {
                            handleMatchTimeChange(value);
                            onChange(value);
                          }}
                          formReset={timeResetToggle}
                        />
                        {isFormInvalid(findInputError(formErrors, "gameTime")) && (
                          <AppText as="span" color="danger">
                            <>
                              {toSentenceCase(
                                `${
                                  formErrors.gameTime?.message ??
                                  "Game time is required and should be in format mm:ss or mm:ss + mm:ss"
                                }`
                              )}
                            </>
                          </AppText>
                        )}
                        {isFormInvalid(findInputError(formErrors, "gameExtraTime")) && (
                          <AppText as="span" color="danger">
                            <>
                              {toSentenceCase(
                                `${
                                  formErrors.gameExtraTime?.message ??
                                  "Game time is required and should be in format mm:ss or mm:ss + mm:ss"
                                }`
                              )}
                            </>
                          </AppText>
                        )}
                      </>
                    )}
                  />
                </AppGridBox>
              </AppBorderBox>
              {selectedEvent === MatchStatisticsEventEnum.Possession && (
                <AppBox flexDirection="column" gap="sm">
                  <AppGridBox columns={2} gap="sm" className="w-100">
                    <Controller
                      name="homePossession"
                      defaultValue={""}
                      control={methods.control}
                      rules={{ required: true, pattern: /^\d{1,2}$/ }}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <AppBox gap="2xs" flexDirection="column" alignItems="center">
                            <AppBox gap="2xs" flexDirection="column" alignItems="center">
                              <AppAvatar username={match.homeTeam?.shortName || ""} src={match.homeTeam?.logo || ""} />
                              <AppText size="lg" fontWeight="bold">
                                {toTitleCase(match.homeTeam?.shortName || "")}
                              </AppText>
                            </AppBox>
                            <AppInput
                              id="homePossession"
                              placeholder="Enter team possession in %"
                              type="number"
                              label={`Possession (${match.homeTeam?.shortName})`}
                              minValue={0}
                              maxValue={100}
                              onChange={(event) => {
                                const value = Number(event.target.value);
                                handleHomePossessionChange(value);
                                onChange(value);
                              }}
                              value={awayPossession ? 100 - awayPossession : value}
                              disabled={match.status === MatchStatusEnum.Upcoming}
                            />
                            {isFormInvalid(findInputError(formErrors, "homePossession")) && (
                              <AppText as="span" color="danger">
                                <>
                                  {toSentenceCase(
                                    `${formErrors.homePossession?.message ?? `Home possession is required.`}`
                                  )}
                                </>
                              </AppText>
                            )}
                          </AppBox>
                        </>
                      )}
                    />
                    <Controller
                      name="awayPossession"
                      defaultValue={""}
                      control={methods.control}
                      rules={{ required: true, pattern: /^\d{1,2}$/ }}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <AppBox gap="2xs" flexDirection="column" alignItems="center">
                            <AppBox gap="2xs" flexDirection="column" alignItems="center">
                              <AppAvatar username={match.awayTeam?.shortName || ""} src={match.awayTeam?.logo || ""} />
                              <AppText size="lg" fontWeight="bold">
                                {toTitleCase(match.awayTeam?.shortName || "")}
                              </AppText>
                            </AppBox>
                            <AppInput
                              id="awayPossession"
                              placeholder="Enter team possession in %"
                              type="number"
                              label={`Possession (${match.awayTeam?.shortName})`}
                              minValue={0}
                              maxValue={100}
                              onChange={(event) => {
                                const value = Number(event.target.value);
                                handleAwayPossessionChange(value);
                                onChange(value);
                              }}
                              value={homePossession ? 100 - homePossession : value}
                              disabled={match.status === MatchStatusEnum.Upcoming}
                            />
                            {isFormInvalid(findInputError(formErrors, "awayPossession")) && (
                              <AppText as="span" color="danger">
                                <>
                                  {toSentenceCase(
                                    `${formErrors.awayPossession?.message ?? `Away possession is required.`}`
                                  )}
                                </>
                              </AppText>
                            )}
                          </AppBox>
                        </>
                      )}
                    />
                  </AppGridBox>
                </AppBox>
              )}
              {selectedEvent !== MatchStatisticsEventEnum.Possession && selectedEventObject && (
                <AppBox flexDirection="column" gap="sm">
                  {selectedEvent === MatchStatisticsEventEnum.Interception && (
                    <Controller
                      name="value"
                      defaultValue={""}
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <AppBox flexDirection="column">
                          <AppInput
                            id="number"
                            label={selectedEventObject.title}
                            placeholder={selectedEventObject.placeHolderStringPrimary}
                            type="number"
                            onChange={onChange}
                            value={value}
                          />
                          {isFormInvalid(findInputError(formErrors, "value")) && (
                            <AppText as="span" color="danger">
                              <>{toSentenceCase(`${formErrors.value?.message ?? "Statistic value is required."}`)}</>
                            </AppText>
                          )}
                        </AppBox>
                      )}
                    />
                  )}
                  {selectedEvent === MatchStatisticsEventEnum.Passes && (
                    <>
                      <Controller
                        name="passes"
                        defaultValue={""}
                        control={methods.control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <AppBox flexDirection="column">
                            <AppInput
                              id="number"
                              label={selectedEventObject.title}
                              placeholder={selectedEventObject.placeHolderStringPrimary}
                              type="number"
                              onChange={onChange}
                              value={value}
                            />
                            {isFormInvalid(findInputError(formErrors, "passes")) && (
                              <AppText as="span" color="danger">
                                <>{toSentenceCase(`${formErrors.passes?.message ?? "Statistic value is required."}`)}</>
                              </AppText>
                            )}
                          </AppBox>
                        )}
                      />
                      <Controller
                        name="passSucceeded"
                        defaultValue={""}
                        control={methods.control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <AppBox flexDirection="column">
                            <AppInput
                              id="number"
                              label={selectedEventObject.titleSecondary}
                              placeholder={selectedEventObject.placeHolderStringSecondary}
                              type="number"
                              onChange={onChange}
                              value={value}
                            />
                            {isFormInvalid(findInputError(formErrors, "passSucceeded")) && (
                              <AppText as="span" color="danger">
                                <>
                                  {toSentenceCase(
                                    `${formErrors.passSucceeded?.message ?? "Statistic value is required."}`
                                  )}
                                </>
                              </AppText>
                            )}
                          </AppBox>
                        )}
                      />
                    </>
                  )}
                  <Controller
                    name="playerId"
                    defaultValue={""}
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                      <>
                        {player ? (
                          <AppBox alignItems="center" gap="sm">
                            <AppPaper padding="xs" withBorder className="flex-1">
                              <AppBox justifyContent="space-between" alignItems="center">
                                <AppBox gap="xs" alignItems="center">
                                  <AppAvatar username={player.fullName as string} src={player.avatar} size="sm" />
                                  <AppBox flexDirection="column">
                                    <AppTitle as="h6">{toTitleCase(player.displayName)}</AppTitle>
                                    <AppText as="span" fontWeight="semibold" size="sm">
                                      {toSentenceCase(player.position)}
                                    </AppText>
                                  </AppBox>
                                </AppBox>
                                <AppAvatar
                                  username={player.team?.shortName || ""}
                                  src={player.team?.logo || ""}
                                  size="xs"
                                />
                              </AppBox>
                            </AppPaper>
                            <AppIconButton
                              icon={<IconDismiss />}
                              variant="light"
                              color="danger"
                              onClick={(e) => {
                                e.preventDefault();
                                setPlayer(null);
                              }}
                            />
                          </AppBox>
                        ) : (
                          <AppBorderBox borderVariant="dashed" className="w-100" p="sm" radius="sm" borderColor="dark">
                            <AppBox className="flex-1 w-100" gap="sm" flexDirection="column" alignItems="center">
                              <IconPersonAdd height={40} width={40} color="rgba(var(--border-200))" />
                              <AppText>{selectedEventObject.playerSelectString}</AppText>
                              <AppButton
                                label="Choose"
                                variant="light"
                                onClick={(e) => {
                                  handlePlayerChoose(e);
                                }}
                                disabled={match.status === MatchStatusEnum.Upcoming}
                              />
                            </AppBox>
                          </AppBorderBox>
                        )}
                        <AppModal
                          opened={isPlayerSearch}
                          titleIcon={<IconPersonAvailable />}
                          title="Choose A Player"
                          onClose={() => setIsPlayerSearch(false)}
                          withoutButtons
                        >
                          <MatchPlayerSearch
                            match={match}
                            onSelectedPlayer={(player) => {
                              handleMatchPlayerSelection(player);
                              onChange(player.id);
                            }}
                          />
                        </AppModal>

                        {isFormInvalid(findInputError(formErrors, "playerId")) && (
                          <AppText as="span" color="danger">
                            <>{toSentenceCase(`${formErrors.playerId?.message ?? `Player is required.`}`)}</>
                          </AppText>
                        )}
                      </>
                    )}
                  />
                </AppBox>
              )}
              <AppBox gap="xs" justifyContent="end">
                <AppButton
                  type="submit"
                  disabled={!methods.formState.isValid}
                  label="Add Statistic"
                  loading={
                    seasonMatchPossessionAddResponseStatus === StatusEnum.Loading ||
                    seasonMatchPlayersStatisticAddResponseStatus === StatusEnum.Loading ||
                    seasonMatchPlayerPassStatisticAddResponseStatus === StatusEnum.Loading
                  }
                />
              </AppBox>
            </AppBox>
          </form>
        </FormProvider>
      </AppPaper>
    </>
  );
};
