import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../../commons";
import { IMatchPlayersStatisticDTO } from "../../../../../../../api-services/football/admin/manage-football-season-service";

interface ISeasonMatchPlayersStatisticEdit {
  seasonMatchPlayersStatisticEditResponseStatus: StatusEnum;
}

const initialState: ISeasonMatchPlayersStatisticEdit = {
  seasonMatchPlayersStatisticEditResponseStatus: StatusEnum.Idle,
};

export interface IFootballSeasonMatchPlayersStatisticEditOptions {
  seasonId: number;
  matchId: number;
  playerId: number;
  statisticId: number;
  matchPlayersStatisticData: IMatchPlayersStatisticDTO;
}

export const seasonMatchPlayersStatisticEdit = createAsyncThunk(
  "manage/seasonMatchPlayersStatisticEdit",
  async (options: IFootballSeasonMatchPlayersStatisticEditOptions, thunkAPI) => {
    try {
      const seasonMatchPlayersStatisticEditResponse = await manageFootballSeasonService.editSeasonMatchPlayerStatistic(
        options.seasonId,
        options.matchId,
        options.playerId,
        options.statisticId,
        options.matchPlayersStatisticData
      );
      return seasonMatchPlayersStatisticEditResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonMatchPlayersStatisticEditSlice = createSlice({
  name: "seasonMatchPlayersStatisticEdit",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonMatchPlayersStatisticEdit.pending, (state) => {
        state.seasonMatchPlayersStatisticEditResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonMatchPlayersStatisticEdit.fulfilled, (state, action) => {
        state.seasonMatchPlayersStatisticEditResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonMatchPlayersStatisticEdit.rejected, (state) => {
        state.seasonMatchPlayersStatisticEditResponseStatus = StatusEnum.Failed;
      });
  },
});

export default seasonMatchPlayersStatisticEditSlice.reducer;
