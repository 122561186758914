import { Fragment, useEffect, useState } from "react";
import {
  AppBox,
  AppGridBox,
  AppPaper,
  AppSkeletonGlobalCardList,
  AppSkeletonNewsList,
  AppText,
  AppTitle,
} from "../../../commons/components";
import AppTabs, { TabItem } from "../../../commons/components/Tabs";
import { useSearchParams } from "react-router-dom";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import MatchLineupCard from "./components/MatchLineupCard";
import MatchTeamForm from "./components/MatchTeamForm";
import { MatchTeamTypeEnum } from "../../../commons/enums/match-team-type-enum";
import { getQueryParams } from "../../../commons/utilities/filterParams";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { PlayerPositionEnum } from "../../../commons/enums/match-player-position-enum";
import {
  IFootballPlayerLineup,
  IFootballPlayerEvent,
  IFootballPlayerCommentaries,
} from "../../../commons/models/football/interface/i-football-player-lineup";
import { MatchEventTypeEnum } from "../../../commons/enums/match-event-type-enum";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import { fetchMatchTeams } from "../../../redux/slices/football/public/match/football-match-teams-slice";
import { FootballTeamPlayer } from "../../../commons/models/football/football-team-player";
import {
  cleanUpTeamPlayers,
  fetchTeamPlayerList,
  IFootballTeamPlayersOption,
} from "../../../redux/slices/football/public/team/football-team-players-slice";
import { MatchStatusEnum } from "../../../commons/enums/match-status-enum";
import { groupArrayByProperty } from "../../../commons/utilities/array-utils";
import { pluralizeString, toSentenceCase } from "../../../commons/utilities/string-utils";
import MatchLineupSquadCard from "./components/MatchLineupSquadCard";
import IconEmpty from "../../../commons/components/icons/empty";
import { MatchCommentaryMetaDataTypeEnum } from "../../../commons/enums/match-commentary-metadata-type-enum";

type GroupedPlayers = {
  [key in PlayerPositionEnum]: IFootballPlayerLineup[];
};

export default function MatchLineup() {
  const dispatch = useAppDispatch();
  const queryParams = getQueryParams();
  const [tabItems, setTabItems] = useState<TabItem[]>([]);
  const [, setSearchParams] = useSearchParams();
  const [teamType, setTeamType] = useState(MatchTeamTypeEnum.Home);
  const [formattedTeamPlayers, setFormattedTeamPlayers] = useState<IFootballPlayerCommentaries[]>([]);
  const [groupedLineupPlayers, setGroupedLineupPlayers] = useState<GroupedPlayers>({
    GOALKEEPER: [],
    DEFENDER: [],
    MIDFIELDER: [],
    FORWARD: [],
    SUBSTITUTE: [],
  });
  const [groupedPlayers, setGroupedPlayers] = useState<
    {
      [x: string]: string | FootballTeamPlayer[];
      items: FootballTeamPlayer[];
    }[]
  >([]);

  const { match } = useAppSelector((state) => state.footballMatchDetail);
  const { matchTeams, matchTeamsStatus } = useAppSelector((state) => state.footballMatchTeams);
  const { teamPlayersList } = useAppSelector((state) => state.footballTeamPlayers);
  const { newsList } = useAppSelector((state) => state.news);

  useEffect(() => {
    if (match.id && match.season && match.season.id) {
      dispatch(fetchMatchTeams({ seasonId: match.season.id, matchId: match.id }));
    }
    setTeamType(queryParams.team === MatchTeamTypeEnum.Away ? MatchTeamTypeEnum.Away : MatchTeamTypeEnum.Home);
  }, [match]);

  const groupPlayersByPosition = (playersWithEvents: IFootballPlayerEvent[]) => {
    return playersWithEvents.reduce((groupedLineupPlayers, player) => {
      const position = player.gridPosition ? player.position : PlayerPositionEnum.Substitute;
      groupedLineupPlayers[position] = groupedLineupPlayers[position] || [];
      groupedLineupPlayers[position].push(player);
      return groupedLineupPlayers;
    }, {} as GroupedPlayers);
  };

  useEffect(() => {
    const urlParams = {
      team: teamType === MatchTeamTypeEnum.Home ? MatchTeamTypeEnum.Home : MatchTeamTypeEnum.Away,
    };
    setSearchParams(urlParams, {
      replace: true,
    });
  }, [teamType]);

  useEffect(() => {
    if (matchTeams.home.length > 0 || matchTeams.away.length > 0) {
      let formattedPlayersWithCommentaries = teamType === MatchTeamTypeEnum.Home ? matchTeams.home : matchTeams.away;
      formattedPlayersWithCommentaries = formattedPlayersWithCommentaries.map((player) => {
        const updatedCommentaries = player.commentaries?.map((commentary) => {
          if (commentary.eventType === MatchEventTypeEnum.Substitution) {
            if (commentary.supportingPlayerId === player.id) {
              return {
                ...commentary,
                eventType: MatchEventTypeEnum.SubstituteIn,
              };
            } else {
              return {
                ...commentary,
                eventType: MatchEventTypeEnum.SubstituteOut,
              };
            }
          }
          if (commentary.eventType === MatchEventTypeEnum.Penalty) {
            if (commentary.metadata?.["type"] === MatchCommentaryMetaDataTypeEnum.Scored) {
              return {
                ...commentary,
                eventType: MatchEventTypeEnum.PenaltyScored,
              };
            } else {
              return {
                ...commentary,
                eventType: MatchEventTypeEnum.Penalty,
              };
            }
          }
          return commentary;
        });
        return {
          ...player,
          commentaries: updatedCommentaries,
        };
      });
      setFormattedTeamPlayers(formattedPlayersWithCommentaries);
      const playersGroupedByPosition = groupPlayersByPosition(formattedPlayersWithCommentaries);
      setGroupedLineupPlayers(playersGroupedByPosition);
    }
  }, [teamType, matchTeams]);

  useEffect(() => {
    dispatch(cleanUpTeamPlayers());
    if (match.status === MatchStatusEnum.Upcoming) {
      let playerListOption = {};
      if (matchTeams.home.length === 0 && teamType === MatchTeamTypeEnum.Home) {
        playerListOption = {
          teamId: match.homeTeam?.id,
          urlParams: { seasonId: match.season.id },
        };
      }
      if (matchTeams.away.length === 0 && teamType === MatchTeamTypeEnum.Away) {
        playerListOption = {
          teamId: match.awayTeam?.id,
          urlParams: { seasonId: match.season.id },
        };
      }
      if (Object.keys(playerListOption).length > 0) {
        dispatch(fetchTeamPlayerList(playerListOption as IFootballTeamPlayersOption));
      }
    }
  }, [teamType, matchTeams]);

  useEffect(() => {
    if (match) {
      setTabItems([
        {
          label: match.homeTeam?.shortName || "",
          value: MatchTeamTypeEnum.Home,
          icon: match.homeTeam?.logo,
        },
        {
          label: match.awayTeam?.shortName || "",
          value: MatchTeamTypeEnum.Away,
          icon: match.awayTeam?.logo,
        },
      ]);
    }
  }, [match, teamType]);

  useEffect(() => {
    if (teamPlayersList.length > 0) {
      const formattedPlayers: FootballTeamPlayer[] = teamPlayersList.mapArray((player) => ({
        ...player,
      }));
      const playersGroup = groupArrayByProperty(formattedPlayers, "position");
      const customOrder = ["GOALKEEPER", "DEFENDER", "MIDFIELDER", "FORWARD"];
      const sortedData = playersGroup.sort((a, b) => {
        return customOrder.indexOf(a.position as string) - customOrder.indexOf(b.position as string);
      });
      setGroupedPlayers(sortedData);
    }
  }, [teamPlayersList]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  const handleTabChange = (args: TabItem) => {
    setTeamType(args.value as MatchTeamTypeEnum);
    if (args.value !== teamType) {
      setGroupedLineupPlayers({
        GOALKEEPER: [],
        DEFENDER: [],
        MIDFIELDER: [],
        FORWARD: [],
        SUBSTITUTE: [],
      });
    }
  };

  return (
    <AppBox className="page-content" gap="md">
      <AppBox displayBlock={true}>
        {matchTeamsStatus === StatusEnum.Loading ? (
          <AppBox flexDirection="column" gap="xs">
            <AppSkeletonGlobalCardList numberOfItems={3} withAvatar withBadge />
            <AppSkeletonGlobalCardList numberOfItems={3} withAvatar withBadge />
            <AppSkeletonGlobalCardList numberOfItems={3} withAvatar withBadge />
            <AppSkeletonGlobalCardList numberOfItems={3} withAvatar withBadge />
          </AppBox>
        ) : (
          <AppBox flexDirection="column" gap="sm">
            <AppTitle as="h5">Match Lineups</AppTitle>
            <AppBox>
              <AppTabs
                as="button"
                tabItemClassName="tab-big"
                onClick={handleTabChange}
                activeValue={teamType}
                tabItems={tabItems}
                variant="filled"
                shadow="xs"
                isFilterTabs
              />
            </AppBox>
            {formattedTeamPlayers.length > 0 ? (
              <AppBox flexDirection="column" gap="xs">
                <MatchTeamForm
                  isHomeTeam={teamType === MatchTeamTypeEnum.Home}
                  players={formattedTeamPlayers}
                  lineUp={teamType === MatchTeamTypeEnum.Home ? match.homeLineUps : match.awayLineUps}
                  match={match}
                />
                <AppBox flexDirection="column" gap="sm">
                  {groupedLineupPlayers.GOALKEEPER?.length > 0 && (
                    <AppBox flexDirection="column" gap="xs">
                      <AppText fontWeight="semibold" color="dark" size="lg">
                        Goalkeeper
                      </AppText>
                      <AppBox flexDirection="column" gap="sm">
                        {groupedLineupPlayers.GOALKEEPER?.map((goalkeeper, index) => (
                          <MatchLineupCard player={goalkeeper} key={index} />
                        ))}
                      </AppBox>
                    </AppBox>
                  )}
                  {groupedLineupPlayers.DEFENDER?.length > 0 && (
                    <AppBox flexDirection="column" gap="xs">
                      <AppText fontWeight="semibold" color="dark" size="lg">
                        Defenders
                      </AppText>
                      <AppBox flexDirection="column" gap="sm">
                        {groupedLineupPlayers.DEFENDER?.map((defender, index) => (
                          <MatchLineupCard player={defender} key={index} />
                        ))}
                      </AppBox>
                    </AppBox>
                  )}
                  {groupedLineupPlayers.MIDFIELDER?.length > 0 && (
                    <AppBox flexDirection="column" gap="xs">
                      <AppText fontWeight="semibold" color="dark" size="lg">
                        Midfielders
                      </AppText>
                      <AppBox flexDirection="column" gap="sm">
                        {groupedLineupPlayers.MIDFIELDER?.map((midfielder, index) => (
                          <MatchLineupCard player={midfielder} key={index} />
                        ))}
                      </AppBox>
                    </AppBox>
                  )}
                  {groupedLineupPlayers.FORWARD?.length > 0 && (
                    <AppBox flexDirection="column" gap="xs">
                      <AppText fontWeight="semibold" color="dark" size="lg">
                        Forwards
                      </AppText>
                      <AppBox flexDirection="column" gap="sm">
                        {groupedLineupPlayers.FORWARD?.map((forward, index) => (
                          <MatchLineupCard player={forward} key={index} />
                        ))}
                      </AppBox>
                    </AppBox>
                  )}
                  {groupedLineupPlayers.SUBSTITUTE?.length > 0 && (
                    <AppBox flexDirection="column" gap="xs">
                      <AppText fontWeight="semibold" color="dark" size="lg">
                        Substitutes
                      </AppText>
                      <AppBox flexDirection="column" gap="sm">
                        {groupedLineupPlayers.SUBSTITUTE?.map((substitute, index) => (
                          <MatchLineupCard player={substitute} isSubstitute key={index} />
                        ))}
                      </AppBox>
                    </AppBox>
                  )}
                </AppBox>
              </AppBox>
            ) : (
              <AppBox flexDirection="column" gap="sm">
                <AppPaper shadow="xs">
                  <AppBox flexDirection="column" alignItems="center" gap="sm" justifyContent="center" p="sm">
                    <AppBox alignItems="center" flexDirection="column" justifyContent="center" gap="2xs">
                      <IconEmpty width={56} height={56} color="rgba(var(--border-200))" />
                      <AppTitle as="h6">No Updates On Lineups</AppTitle>
                      <AppText textAlign="center">
                        The lineups for this match have not been updated yet. Please check back closer to kickoff for
                        the latest team formations and player selections.
                      </AppText>
                    </AppBox>
                  </AppBox>
                </AppPaper>
                <AppTitle as="h5">Squad</AppTitle>
                {groupedPlayers.length > 0 ? (
                  <>
                    {groupedPlayers.map((groupedPlayer, index) => (
                      <AppBox key={index} flexDirection="column" gap="xs">
                        <AppText fontWeight="semibold" color="dark" size="lg">
                          {toSentenceCase(pluralizeString(groupedPlayer.position as string))}
                        </AppText>
                        <AppBox flexDirection="column" gap="sm">
                          {groupedPlayer.items.map((player, index) => (
                            <MatchLineupSquadCard key={index} teamPlayer={player} />
                          ))}
                        </AppBox>
                      </AppBox>
                    ))}
                  </>
                ) : (
                  <AppText>There are not any players in this team.</AppText>
                )}
              </AppBox>
            )}
          </AppBox>
        )}
      </AppBox>
      {newsList.status === (StatusEnum.Loading || StatusEnum.Idle) ? (
        <AppSkeletonNewsList />
      ) : (
        <NewsTimelineCard newsList={newsList.data} timelineType="small" />
      )}
    </AppBox>
  );
}
