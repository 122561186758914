import {
  AppAvatar,
  AppBox,
  AppLink,
  AppPaper,
  AppScorePill,
  AppSkeletonHeader,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppTabs, { TabItem } from "../../../../commons/components/Tabs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IFootballMatchDetail, IMatchState } from "../../../../commons/models/football/interface/i-football-match";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { cleanUpNews } from "../../../../redux/slices/news-slice";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import setDocumentTitle from "../../../../commons/utilities/document-title";
import { MatchStatusEnum } from "../../../../commons/enums/match-status-enum";
import { MatchStateEnum } from "../../../../commons/enums/match-state-enum";
import moment from "moment";
import { formatLiveMatchTIme, getGameTime, getMatchPauseString } from "../../../../commons/utilities/game-time-utils";
import Pill from "../../../../commons/components/Pill";
import IconLive from "../../../../commons/components/icons/live";
import { TournamentTypeEnum } from "../../../../commons/enums/tournament-type-enum";
import { MatchStatus } from "../../../../commons/components/ScorePill";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface MatchDetailHeaderProps {
  match: IFootballMatchDetail;
}

export default function MatchDetailHeader({ match }: MatchDetailHeaderProps) {
  const [penaltyGoals, setPenaltyGoals] = useState<{ home?: number; away?: number }>();
  const [currentTab, setCurrentTab] = useState("summary");
  const [activeItem, setActiveItem] = useState<TabItem>();
  const [selectedMatchState, setSelectedMatchState] = useState<IMatchState>();
  const [gameTime, setGameTime] = useState("");
  const [matchStatusType, setMatchStatusType] = useState<MatchStatus>();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { matchStatus } = useAppSelector((state) => state.footballMatchDetail);

  useEffect(() => {
    const url = location.pathname.split("/");
    setCurrentTab(url[url.length - 1]);
  }, [activeItem, location.pathname]);

  useEffect(() => {
    const lastPathSegment = location.pathname.split("/").pop();
    if (!isNaN(Number(lastPathSegment))) {
      setCurrentTab("summary");
    }
  }, [location.pathname]);

  const handleTournamentClick = () => {
    dispatch(cleanUpNews());
    window.scrollTo(0, 0);
  };

  const tabItems = [
    {
      label: "Summary",
      value: "summary",
      href: "summary",
    },
    {
      label: "Commentary",
      value: "commentary",
      href: "commentary",
      hidden: match.status === MatchStatusEnum.Upcoming,
    },
    {
      label: "Lineups",
      value: "lineups",
      href: "lineups",
    },
    {
      label: "Statistics",
      value: "statistics",
      href: "statistics",
      hidden: match.status === MatchStatusEnum.Upcoming,
    },
    {
      label: "Standings",
      value: "standings",
      href: "standings",
      hidden: match.competition.type === TournamentTypeEnum.Knockout,
    },
    {
      label: "Brackets",
      value: "brackets",
      href: "brackets",
      hidden: match.competition.type === TournamentTypeEnum.League,
    },
  ];
  useEffect(() => {
    switch (match.status) {
      case MatchStatusEnum.Upcoming:
        setMatchStatusType("upcoming");
        break;
      case MatchStatusEnum.Live:
        setMatchStatusType("live");
        break;
      case MatchStatusEnum.Finished:
        setMatchStatusType("previous");
        break;
    }
    if (match.status === MatchStatusEnum.Upcoming) {
      setDocumentTitle([
        `${toTitleCase(match.homeTeam?.shortName || "")} vs ${toTitleCase(match.awayTeam?.shortName || "")}`,
      ]);
    } else {
      setDocumentTitle([
        `${toTitleCase(match.homeTeam?.shortName || "")} ${match.homeGoals}`,
        `${match.awayGoals} ${toTitleCase(match.awayTeam?.shortName || "")}`,
      ]);
    }
    if (match.penalties.length > 0) {
      const homePenaltyGoal = match.penalties.filter(
        (penalty) => penalty.teamId === match.homeTeam?.id && penalty.goalScored
      ).length;
      const awayPenaltyGoal = match.penalties.filter(
        (penalty) => penalty.teamId === match.awayTeam?.id && penalty.goalScored
      ).length;
      setPenaltyGoals({ home: homePenaltyGoal, away: awayPenaltyGoal });
    }
  }, [match]);

  useEffect(() => {
    if (match.status === MatchStatusEnum.Live) {
      const matchState = match.states.find((matchState) => matchState.state === MatchStateEnum.InProgress);
      if (matchState) {
        setSelectedMatchState(matchState);
      } else {
        const finishedMatchStates = match.states.filter((matchState) => matchState.state === MatchStateEnum.Completed);
        finishedMatchStates.sort((a, b) => moment(b.startAt).valueOf() - moment(a.startAt).valueOf());
        setSelectedMatchState(finishedMatchStates[0]);
      }
    }
  }, [match]);

  useEffect(() => {
    if (match.status === MatchStatusEnum.Live && selectedMatchState) {
      const intervalId = setInterval(() => {
        setGameTime(getGameTime(selectedMatchState, match.gameplayDuration));
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [match.status, selectedMatchState]);

  return (
    <AppPaper shadow="xs" padding="none">
      {matchStatus === StatusEnum.Loading ? (
        <AppSkeletonHeader isMatch />
      ) : (
        <AppBox
          className="match-header"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="xs"
          p="sm"
          style={{
            borderBottom: "1px solid rgb(var(--border-default))",
          }}
        >
          <AppLink to={`/tournaments/${match.competition.id}`} className="link" onClick={handleTournamentClick}>
            <AppText color="muted" textAlign="center">
              {match.competition?.title}
            </AppText>
          </AppLink>
          <AppBox gap="xl" alignItems="center" className="match-team-container w-100">
            <AppBox className="match-header" justifyContent="end">
              <AppLink to={`/teams/${match.homeTeam?.id}`}>
                <AppBox className="match-home-team" alignItems="center" gap="xs">
                  <AppTitle as="h4" textAlign="right" className="match-team-title short-name">
                    {toTitleCase(match.homeTeam?.shortName || "")}
                  </AppTitle>
                  <AppTitle as="h4" textAlign="right" className="match-team-title full-name">
                    {toTitleCase(match.homeTeam?.name || "")}
                  </AppTitle>
                  <AppAvatar
                    className="match-team-avatar"
                    username={match.homeTeam?.shortName || ""}
                    size="2xl"
                    src={match.homeTeam?.logo || ""}
                  />
                </AppBox>
              </AppLink>
            </AppBox>
            <AppBox flexDirection="column" gap="2xs">
              {matchStatusType && (
                <AppScorePill
                  size="lg"
                  matchStatus={matchStatusType}
                  valueFirst={match.homeGoals || 0}
                  valueSecond={match.awayGoals || 0}
                  penaltyValueFirst={penaltyGoals?.home}
                  penaltyValueSecond={penaltyGoals?.away}
                />
              )}
              {match.status === MatchStatusEnum.Live && selectedMatchState && (
                <Pill
                  icon={<IconLive />}
                  label={`live | ${
                    selectedMatchState.state === MatchStateEnum.Completed
                      ? getMatchPauseString(selectedMatchState, match.competition)
                      : formatLiveMatchTIme(getGameTime(selectedMatchState, match.gameplayDuration))
                  }`}
                />
              )}
            </AppBox>
            <AppBox className="match-header">
              <AppLink to={`/teams/${match.awayTeam?.id}`}>
                <AppBox className="match-away-team" alignItems="center" gap="xs">
                  <AppAvatar
                    className="match-team-avatar"
                    username={match.awayTeam?.shortName || ""}
                    size="2xl"
                    src={match.awayTeam?.logo || ""}
                  />
                  <AppTitle as="h4" className="match-team-title short-name">
                    {toTitleCase(match.awayTeam?.shortName || "")}
                  </AppTitle>
                  <AppTitle as="h4" className="match-team-title full-name">
                    {toTitleCase(match.awayTeam?.name || "")}
                  </AppTitle>
                </AppBox>
              </AppLink>
            </AppBox>
          </AppBox>
        </AppBox>
      )}
      <AppBox p="sm">
        <AppTabs
          as="a"
          onClick={(e) => {
            setActiveItem(e);
            window.scrollTo(0, 0);
          }}
          activeValue={currentTab}
          tabItems={tabItems}
        />
      </AppBox>
    </AppPaper>
  );
}
