import {
  AppAvatar,
  AppBox,
  AppIconButton,
  AppLink,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../../../commons/components";
import IconBin from "../../../../../commons/components/icons/bin";
import IconEdit from "../../../../../commons/components/icons/edit";
import { IManageFootballMatchPlayerPassStatistics } from "../../../../../commons/models/football/admin/interface/i-manage-football-match-players-statistics";
import { formatMatchTime } from "../../../../../commons/utilities/game-time-utils";
import { toSentenceCase, toTitleCase } from "../../../../../commons/utilities/string-utils";

interface PlayerStatisticListCardProps {
  playerStatsData: IManageFootballMatchPlayerPassStatistics;
  onEdit: (value: IManageFootballMatchPlayerPassStatistics) => void;
  onDelete: (value: IManageFootballMatchPlayerPassStatistics) => void;
}

export const MatchPlayerPassStatisticListCard = ({
  playerStatsData,
  onEdit,
  onDelete,
}: PlayerStatisticListCardProps) => {
  const handlePlayerClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <AppPaper shadow="xs" padding="none">
      <AppBox px="sm" py="xs" gap="xs" justifyContent="space-between">
        <AppBox alignItems="center" gap="xs" style={{ width: "25rem", maxWidth: "25rem" }}>
          <AppBox className="match-time-width" alignItems="center">
            <AppText as="p" fontWeight="bold">
              {formatMatchTime(playerStatsData.gameTime, playerStatsData.gameExtraTime)}
            </AppText>
          </AppBox>
          <AppLink to={`/manage/players/${playerStatsData.player.id}`} onClick={handlePlayerClick}>
            <AppBox alignItems="center" gap="xs">
              <AppAvatar username={playerStatsData.player.displayName} src={playerStatsData.player.avatar || ""} />
              <AppBox flexDirection="column">
                <AppTitle as="h6">{toTitleCase(playerStatsData.player.displayName)}</AppTitle>
                <AppBox alignItems="center">
                  <AppText size="sm">{toSentenceCase(playerStatsData.player.position)}</AppText>
                </AppBox>
              </AppBox>
            </AppBox>
          </AppLink>
        </AppBox>
        <AppBox flexDirection="column">
          <AppTitle as="h5">{playerStatsData.passes}</AppTitle>
          <AppText color="info">Passes</AppText>
        </AppBox>
        <AppBox flexDirection="column">
          <AppTitle as="h5">{playerStatsData.passSucceeded}</AppTitle>
          <AppText color="info">Passes Successful</AppText>
        </AppBox>
        <AppBox gap="xs" justifyContent="end" alignItems="center">
          <AppIconButton icon={<IconEdit />} variant="light" onClick={() => onEdit(playerStatsData)} />
          <AppIconButton icon={<IconBin />} variant="light" color="danger" onClick={() => onDelete(playerStatsData)} />
        </AppBox>
      </AppBox>
    </AppPaper>
  );
};
