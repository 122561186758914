import { ReactElement, useEffect, useRef, useState } from "react";
import AppBox from "../Box";
import IconChevronBottom from "../icons/chevron-bottom";
import IconChevronUp from "../icons/chevron-up";
import AppText from "../Text";
import AppIconButton from "../IconButton";
import IconDismiss from "../icons/dismiss";

export interface ISelectOptions {
  title: string;
  value: number | string;
  secondaryValue?: any;
  icon?: ReactElement;
}

interface ISelectProps {
  options: ISelectOptions[];
  className?: string;
  optionsClassName?: string;
  currentOption?: ISelectOptions | ISelectOptions[];
  disabled?: boolean;
  withSearch?: boolean;
  withMultiSelect?: boolean;
  smallSize?: boolean;
  dropDownHeight?: "md" | "lg";
  onChange?: (option: ISelectOptions) => void;
  onMultiSelect?: (option: ISelectOptions[]) => void;
}

export default function Select({
  options,
  className,
  onChange,
  currentOption,
  optionsClassName,
  disabled,
  withSearch,
  smallSize,
  dropDownHeight = "md",
  onMultiSelect,
  withMultiSelect,
}: ISelectProps) {
  const [isActive, setIsActive] = useState(false);
  const [isDropUp, setIsDropUp] = useState(false);
  const [selected, setIsSelected] = useState<ISelectOptions | null>({
    title: options[0].title,
    icon: options[0]?.icon,
    value: options[0]?.value,
    secondaryValue: options[0]?.secondaryValue,
  });
  const [selectedOptions, setSelectedOptions] = useState<ISelectOptions[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const classNames = [className].filter(Boolean).join(" ");
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const dropdownContentRef = useRef<HTMLUListElement | null>(null);

  const handleSelectChange = (option: ISelectOptions, index: number) => {
    if (withMultiSelect) {
      setSearchQuery("");
      const isSelected = selectedOptions.find((selected) => selected.value === option.value);

      let updatedSelectedOptions;
      if (isSelected) {
        updatedSelectedOptions = selectedOptions.filter((selected) => selected.value !== option.value);
      } else {
        updatedSelectedOptions = [...selectedOptions, option];
      }

      setSelectedOptions(updatedSelectedOptions);

      if (onMultiSelect) {
        onMultiSelect(updatedSelectedOptions);
      }
    } else {
      setIsActive(false);
      setSearchQuery("");
      setIsSelected({
        title: option.title,
        icon: option.icon,
        value: option.value,
      });

      const selectedValue = option.value || index;
      if (selectedValue !== selected?.value && onChange) {
        onChange(option);
      }
    }
  };

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsActive(false);
      setSearchQuery("");
    }
  };

  const handleToggleDropdown = (e: any) => {
    e.stopPropagation();
    if (!disabled) {
      setIsActive(!isActive);
      if (!isActive) {
        let parent = dropdownRef.current?.parentElement;
        while (parent) {
          const overflowY = window.getComputedStyle(parent).overflowY;
          if (overflowY === "scroll") {
            break;
          }
          parent = parent.parentElement;
        }
        const dropdownRect = dropdownRef.current?.getBoundingClientRect();
        const dropdownContentHeight = dropdownRect?.height || 0;
        let spaceBelow, spaceAbove;
        if (parent) {
          const parentRect = parent.getBoundingClientRect();
          spaceBelow = parentRect.bottom - dropdownRect!.bottom;
          spaceAbove = dropdownRect!.top - parentRect.top;
        } else {
          spaceBelow = window.innerHeight - (dropdownRect?.bottom || 0);
          spaceAbove = dropdownRect?.top || 0;
        }
        setIsDropUp(spaceBelow < dropdownContentHeight && spaceAbove > dropdownContentHeight);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (currentOption) {
      if (withMultiSelect) {
        const initialSelectedOptions = Array.isArray(currentOption) ? currentOption : [currentOption];
        setSelectedOptions(initialSelectedOptions);
        setIsSelected(null);
      } else {
        setIsSelected(currentOption as ISelectOptions);
      }
    }
  }, [currentOption, withMultiSelect]);

  const filteredOptions = options.filter((option) => option.title.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <div
      ref={dropdownRef}
      className={`ee-input  ${smallSize && "drop-down-small"} ee-dropdown ${classNames}`}
      style={disabled ? { cursor: "not-allowed" } : { cursor: "pointer" }}
    >
      <AppBox
        className={`${smallSize && "drop-down-small"} ee-dropdown-btn`}
        alignItems="center"
        gap={smallSize ? "2xs" : "xs"}
        py={smallSize ? "4xs" : "2xs"}
        style={smallSize ? { paddingTop: "var(--space-4xs)" } : {}}
        onClick={handleToggleDropdown}
      >
        <AppBox gap="3xs" alignItems="center" className="flex-1">
          {withMultiSelect ? (
            <AppText lineClamp={1} size={smallSize ? "sm" : "md"}>
              {(selectedOptions.length > 1 ? `(${selectedOptions.length}) ` : "") +
                selectedOptions.map((option) => option.title).join(", ") || "Select..."}
            </AppText>
          ) : (
            <>
              {selected?.icon && selected.icon}
              <AppText lineClamp={1} size={smallSize ? "sm" : "md"}>
                {selected?.title}
              </AppText>
            </>
          )}
        </AppBox>
        <AppBox gap="3xs">
          {withMultiSelect && selectedOptions.length > 0 && (
            <AppIconButton
              icon={<IconDismiss />}
              radius="rounded"
              size="xs"
              color="gray"
              variant="outline"
              borderLight
              onClick={() => {
                setSelectedOptions([]);
                if (onMultiSelect) {
                  onMultiSelect([]);
                }
              }}
            />
          )}
          <span onClick={handleToggleDropdown}>
            {isActive ? (
              <IconChevronUp size={smallSize ? 18 : undefined} />
            ) : (
              <IconChevronBottom size={smallSize ? 18 : undefined} />
            )}
          </span>
        </AppBox>
      </AppBox>
      {isActive && (
        <div
          className={`ee-dropdown-content ${optionsClassName} ${dropDownHeight && `height-${dropDownHeight}`}  ${
            isDropUp ? "drop-up" : "drop-down"
          }`}
        >
          {withSearch && (
            <div className="ee-dropdown-search-container">
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                className={`ee-dropdown-search ee-input ${smallSize && "drop-down-small"}`}
              />
            </div>
          )}
          <ul ref={dropdownContentRef}>
            {filteredOptions.map((option, index) => (
              <li
                className={`item ${smallSize && "drop-down-small"} ${
                  selectedOptions.find((selected) => selected.value === option.value) ||
                  selected?.value === option.value
                    ? "selected"
                    : ""
                }`}
                key={index}
                value={option.value || index}
                onClick={() => handleSelectChange(option, index)}
              >
                <AppBox gap="3xs" alignItems="center">
                  {option.icon && option.icon}
                  <AppText size={smallSize ? "sm" : "md"}>{option.title}</AppText>
                </AppBox>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
