interface ButtonProps {
  size?: "xs" | "sm" | "md" | "lg";
  variant?: "dots" | "spinner";
  withinContent?: boolean;
}

export default function AppLoader({ size = "sm", variant = "spinner", withinContent }: ButtonProps) {
  const classNames = [
    `loader`,
    variant && `loader-${variant}`,
    size && `size-${size}`,
    withinContent && "within-content",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div className={`loader-overlay ${withinContent && "within-content"}`}>
      <span className={classNames}></span>
    </div>
  );
}
