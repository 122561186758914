import { Controller, useForm } from "react-hook-form";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppDatePicker,
  AppIconButton,
  AppInput,
  AppModal,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import IconPersonAvailable from "../../../../commons/components/icons/person-available";
import { findInputError, isFormInvalid } from "../../../../commons/utilities/form-utils";
import { PlayerRoleSelector } from "./PlayerRoleSelector";
import { useEffect, useState } from "react";
import moment from "moment";
import { ManageFootballPlayer } from "../../../../commons/models/football/admin/manage-football-player";
import { toSentenceCase, toTitleCase } from "../../../../commons/utilities/string-utils";
import IconBin from "../../../../commons/components/icons/bin";
import PlayerSearch from "./PlayerSearch";
import { formatDateTime } from "../../../../commons/utilities/date-utils";
import { ITeamPlayerDTO } from "../../../../api-services/football/admin/manage-football-team-service";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import {
  teamPlayerAdd,
  teamPlayerEdit,
} from "../../../../redux/slices/football/admin/team/manage-team-player-add-edit-slice";
import { IFootballTeamDetail } from "../../../../commons/models/football/interface/i-football-team";
import {
  IManageTeamPlayersOption,
  fetchManageTeamPlayerList,
} from "../../../../redux/slices/football/admin/team/manage-team-players-slice";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { FootballTeamPlayer } from "../../../../commons/models/football/football-team-player";
import setDocumentTitle from "../../../../commons/utilities/document-title";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import IconPersonAdd from "../../../../commons/components/icons/person-add";
import PlayerAddFromSquad from "./PlayerAddFromSquad";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import { IFootballTeamPlayer } from "../../../../commons/models/football/interface/i-football-team-player";

interface TeamAddEditProps {
  team: IFootballTeamDetail;
  existingTeamPlayers?: FootballTeamPlayer[];
  selectedTeamPlayer?: IFootballTeamPlayer | null;
  onPlayerAddEdit?: (player: ManageFootballPlayer) => void;
}

export default function TeamPlayerAdd({
  team,
  existingTeamPlayers,
  selectedTeamPlayer,
  onPlayerAddEdit,
}: TeamAddEditProps) {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const methods = useForm();
  const formErrors = methods.formState.errors;
  const [selectedPlayer, setSelectedPlayer] = useState<ManageFootballPlayer | null>(null);
  const [isPlayerSearch, setPlayerSearch] = useState(false);
  const [isPlayerCreate, setPlayerCreate] = useState(false);
  const [minDate, setMinDate] = useState<Date>();
  const { manageTeamPlayerAddStatus, manageTeamPlayerEditStatus } = useAppSelector(
    (state) => state.footballManageTeamPlayerAddEdit
  );

  useEffect(() => {
    setMinDate(moment(team.founded).toDate());
    selectedTeamPlayer
      ? setDocumentTitle([`${toTitleCase(team.shortName)}`, "Edit Players", "Manage"])
      : setDocumentTitle([`${toTitleCase(team.shortName)}`, "Add Players", "Manage"]);
  }, [team]);

  const handleFormSubmit = (values: ITeamPlayerDTO) => {
    const formattedValues = {
      playingRoles: values.playingRoles,
      isCaptain: values.isCaptain,
      jerseyNumber: Number(values.jerseyNumber),
      startAt: formatDateTime(values.startAt, "YYYY-MM-DD HH:mm:00"),
    };
    if (team && selectedPlayer) {
      dispatch(
        selectedTeamPlayer
          ? teamPlayerEdit({
              teamId: team.id,
              playerId: selectedPlayer.id,
              playerInfo: formattedValues,
            })
          : teamPlayerAdd({
              teamId: team.id,
              playerId: selectedPlayer.id,
              playerInfo: formattedValues,
            })
      )
        .unwrap()
        .then(() => {
          selectedTeamPlayer
            ? addSnackbar({
                key: "team-player-edit-success",
                text: "Team Player Edited Successfully",
                variant: "success",
              })
            : addSnackbar({
                key: "team-player-add-success",
                text: "Team Player Added Successfully",
                variant: "success",
              });
          const playerListOption: IManageTeamPlayersOption = {
            teamId: team.id,
          };
          dispatch(fetchManageTeamPlayerList(playerListOption));
          if (onPlayerAddEdit && selectedPlayer) {
            onPlayerAddEdit(selectedPlayer);
          }
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          const responseData = error.response?.data;
          if (error.response?.status === 417) {
            addSnackbar({
              key: "error",
              text: "Form not valid",
              variant: "danger",
            });
            if (responseData) {
              Object.entries(responseData).forEach(([field, messages]) => {
                messages.forEach((message: string) => {
                  methods.setError(field, { message });
                });
              });
            }
          } else {
            addSnackbar({
              key: "error",
              text: responseData?.message,
              variant: "danger",
            });
          }
        });
    }
  };

  useEffect(() => {
    if (selectedTeamPlayer) {
      const formattedSelectedTeamPlayer: IFootballTeamPlayer = {
        ...selectedTeamPlayer,
        player: {
          ...selectedTeamPlayer.player,
          position: selectedTeamPlayer.position,
        },
      };
      setSelectedPlayer(new ManageFootballPlayer(formattedSelectedTeamPlayer.player));
      methods.setValue("playerId", formattedSelectedTeamPlayer.player.id);
    }
  }, [selectedTeamPlayer]);

  useEffect(() => {
    if (selectedPlayer) {
      const playerDateOfBirth = moment(selectedPlayer.dateOfBirth).toDate();
      const teamFounded = moment(team.founded).toDate();
      const latestDate = playerDateOfBirth > teamFounded ? playerDateOfBirth : teamFounded;
      setMinDate(latestDate);
    }
  }, [selectedPlayer]);

  return (
    <>
      <form
        noValidate
        onSubmit={methods.handleSubmit((e) => {
          methods.formState.isValid && handleFormSubmit(e as ITeamPlayerDTO);
        })}
      >
        <AppBox flexDirection="column" gap="md">
          <AppBox flexDirection="column" gap="sm" pr="2xs" pb="2xs" style={{ overflowY: "scroll", maxHeight: "30rem" }}>
            <Controller
              control={methods.control}
              name="playerId"
              defaultValue={""}
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <>
                  {selectedPlayer ? (
                    <AppBox alignItems="center" gap="sm">
                      <AppPaper padding="xs" withBorder className="flex-1">
                        <AppBox justifyContent="space-between" alignItems="center">
                          <AppBox gap="xs" alignItems="center">
                            <AppAvatar
                              username={selectedPlayer.fullName as string}
                              src={selectedPlayer.avatar}
                              size="sm"
                            />
                            <AppBox flexDirection="column">
                              <AppTitle as="h6">{selectedPlayer.fullName}</AppTitle>
                              <AppText as="span" fontWeight="semibold" size="sm">
                                {toSentenceCase(selectedPlayer.position)}
                              </AppText>
                            </AppBox>
                          </AppBox>
                        </AppBox>
                      </AppPaper>
                      {!selectedTeamPlayer && (
                        <AppIconButton
                          icon={<IconBin />}
                          variant="light"
                          color="danger"
                          onClick={() => {
                            setSelectedPlayer(null);
                            onChange("");
                          }}
                        />
                      )}
                    </AppBox>
                  ) : (
                    <AppBox flexDirection="column" alignItems="center" gap="sm">
                      <AppBox flexDirection="column" alignItems="center" gap="xs">
                        <IconPersonAvailable height={40} width={40} color="rgba(var(--border-200))" />
                        <AppText>Choose or create a player you wish to add to your team.</AppText>
                      </AppBox>
                      <AppBox gap="sm">
                        <AppButton
                          label="Choose"
                          variant="outline"
                          color="gray"
                          onClick={(e) => {
                            e.preventDefault();
                            setPlayerSearch(true);
                          }}
                        />
                        <AppButton
                          label="Create Player Profile"
                          variant="light"
                          onClick={(e) => {
                            e.preventDefault();
                            setPlayerCreate(true);
                          }}
                        />
                      </AppBox>
                    </AppBox>
                  )}
                  <AppModal
                    titleIcon={<IconPersonAvailable />}
                    title="Choose A Player"
                    opened={isPlayerSearch}
                    onClose={(e) => {
                      setPlayerSearch(false);
                    }}
                    withoutButtons
                  >
                    <PlayerSearch
                      onSelectedPlayer={(players) => {
                        onChange(players[0].id);
                        setSelectedPlayer(players[0]);
                        setPlayerSearch(false);
                      }}
                      userSelectedTeamPlayers={existingTeamPlayers}
                    />
                  </AppModal>
                </>
              )}
            />

            <Controller
              control={methods.control}
              name="isCaptain"
              defaultValue={selectedTeamPlayer ? selectedTeamPlayer.isCaptain : false}
              render={({ field: { onChange, value } }) => (
                <AppBox flexDirection="column">
                  <AppBox justifyContent="space-between">
                    <AppText as="label" size="lg">
                      Assign player as captain
                    </AppText>
                    <AppBox className="checkbox-switch">
                      <input type="checkbox" id="switch" checked={value} onChange={onChange} />
                      <label htmlFor="switch"></label>
                    </AppBox>
                  </AppBox>
                  {isFormInvalid(findInputError(formErrors, "isCaptain")) && (
                    <AppText as="span" color="danger">
                      <>{toSentenceCase(`${formErrors.isCaptain?.message && formErrors.isCaptain.message}`)}</>
                    </AppText>
                  )}
                </AppBox>
              )}
            />

            <Controller
              control={methods.control}
              name="startAt"
              rules={{ required: true }}
              defaultValue={selectedTeamPlayer ? moment(selectedTeamPlayer.startAt).toDate() : moment().toDate()}
              render={({ field: { onChange, value } }) => (
                <AppBox flexDirection="column" gap="2xs">
                  <AppText as="label" size="lg">
                    Start Date
                  </AppText>
                  <AppDatePicker
                    onChange={onChange}
                    selected={value}
                    calenderPlacement="bottom-end"
                    maxDate={new Date()}
                    minDate={minDate}
                  />
                  {isFormInvalid(findInputError(formErrors, "startAt")) && (
                    <AppText as="span" color="danger">
                      <>{toSentenceCase(`${formErrors.startAt?.message ?? "Start date is required."}`)}</>
                    </AppText>
                  )}
                </AppBox>
              )}
            />

            <Controller
              name="jerseyNumber"
              defaultValue={selectedTeamPlayer ? selectedTeamPlayer.jerseyNumber : ""}
              control={methods.control}
              rules={{ required: true, pattern: /^\d{1,2}$/ }}
              render={({ field: { onChange, value } }) => (
                <AppBox flexDirection="column">
                  <AppInput
                    defaultValue={value}
                    id="number"
                    label="Squad Number"
                    placeholder="Enter player's squad number"
                    type="number"
                    minValue={0}
                    maxValue={99}
                    onChange={onChange}
                  />
                  {isFormInvalid(findInputError(formErrors, "jerseyNumber")) && (
                    <AppText as="span" color="danger">
                      <>{toSentenceCase(`${formErrors.jerseyNumber?.message ?? "Player number is required."}`)}</>
                    </AppText>
                  )}
                </AppBox>
              )}
            />
            {selectedPlayer && (
              <Controller
                name="playingRoles"
                defaultValue={selectedTeamPlayer ? selectedTeamPlayer.playingRoles : ""}
                control={methods.control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <AppBox flexDirection="column">
                    <AppBox flexDirection="column" gap="xs">
                      <AppText as="label" size="lg">
                        Choose player's proficient playing roles
                      </AppText>
                      <PlayerRoleSelector
                        currentRoles={value}
                        playerPosition={selectedPlayer.position}
                        onSelectedRoles={(roles) => onChange(roles)}
                      />
                    </AppBox>
                    {isFormInvalid(findInputError(formErrors, "playingRoles")) && (
                      <AppText as="span" color="danger">
                        Player's role is required.
                      </AppText>
                    )}
                  </AppBox>
                )}
              />
            )}
          </AppBox>
          <AppBox justifyContent="end" gap="xs">
            <AppButton
              type="submit"
              disabled={!methods.formState.isValid}
              label={selectedTeamPlayer ? "Save Changes" : "Add Player"}
              loading={
                manageTeamPlayerAddStatus === StatusEnum.Loading || manageTeamPlayerEditStatus === StatusEnum.Loading
              }
            />
          </AppBox>
        </AppBox>
      </form>
      <AppModal
        titleIcon={<IconPersonAdd />}
        title="Create A Player"
        opened={isPlayerCreate}
        onClose={(e) => {
          setPlayerCreate(false);
        }}
        withoutButtons
      >
        <PlayerAddFromSquad
          onCancel={() => {
            setPlayerCreate(false);
          }}
          onSave={(player) => {
            setSelectedPlayer(player);
            methods.setValue("playerId", player.id);
            setPlayerCreate(false);
          }}
        />
      </AppModal>
    </>
  );
}
