import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { AppAvatar, AppBox, AppButton, AppLink, AppText } from "../../../../../commons/components";
import AppBorderBox from "../../../../../commons/components/BorderBox";
import IconImageAdd from "../../../../../commons/components/icons/image-add";
import IconImage from "../../../../../commons/components/icons/image";
import AvatarEditor from "react-avatar-editor";
import { dataURItoBlob } from "../../../../../commons/utilities/blob-utils";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import { uploadImage } from "../../../../../redux/slices/image-upload-slice";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../../commons/components/interface";
import { useSnackbar } from "../../../../../redux/snackbarProvider";
import { findInputError, isFormInvalid } from "../../../../../commons/utilities/form-utils";
import { FieldErrors, FieldValues } from "react-hook-form";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { setStepperFormData } from "../../../../../redux/slices/stepper-form-slice";
import { IManageFootballReferee } from "../../../../../commons/models/football/admin/interface/i-manage-football-referee";
import { IRefereeDTO } from "../../../../../api-services/football/admin/manage-football-referee-service";
import { toSentenceCase } from "../../../../../commons/utilities/string-utils";

interface StadiumImageAddEditProps {
  refereeData?: IManageFootballReferee | null;
  formStep: number;
  formErrors: FieldErrors<FieldValues>;
  submitStatus: StatusEnum;
  onSkipOrNext: (currentStep: number) => void;
  onClearError: (errorKey: string) => void;
  onSaveAndExit: () => void;
}

const StadiumImageAddEdit = ({
  refereeData,
  formStep,
  formErrors,
  submitStatus,
  onSaveAndExit,
  onSkipOrNext,
  onClearError,
}: StadiumImageAddEditProps) => {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const inputRef = useRef<HTMLInputElement>(null);
  const [rawImage, setRawImage] = useState<string | File>("");
  const [avatarAddInProgress, setAvatarAddInProgress] = useState(false);
  const [avatarAdded, setAvatarAdded] = useState(false);
  const [isInvalidImageSize, setInvalidImageSize] = useState(false);
  const [isInvalidImageType, setInvalidImageType] = useState(false);
  const [slideValue, setSlideValue] = useState(10);
  const { uploadedImageStatus } = useAppSelector((state) => state.imageUpload);
  const formData = useAppSelector<IRefereeDTO>((state) => state.stepperForm.stepperFormData);
  const cropRef = useRef<AvatarEditor>(null);

  useLayoutEffect(() => {
    if (refereeData?.avatar) {
      setAvatarAdded(true);
    }
  }, [refereeData]);

  const handleImageUpload = (image: string | null) => {
    const refereeData: IRefereeDTO = {
      ...formData,
      avatar: image,
    };
    dispatch(setStepperFormData<IRefereeDTO>(refereeData));
    onClearError("avatar");
  };

  const handleCrop = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (cropRef.current) {
      const dataURL = cropRef.current.getImage().toDataURL();
      const fileBlob = dataURItoBlob(dataURL);
      const formData = new FormData();
      formData.append("file", fileBlob, (rawImage as File).name);
      dispatch(uploadImage(formData))
        .unwrap()
        .then((response) => {
          setAvatarAdded(true);
          setSlideValue(10);
          handleImageUpload(response.url);
          setAvatarAddInProgress(false);
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          const responseData = error.response?.data;
          addSnackbar({
            key: "error",
            text: responseData?.message,
            variant: "danger",
          });
        });
    }
  };

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    setSlideValue(value);
  };

  const handleUploadClick = (e: Event) => {
    e.preventDefault();
    inputRef.current?.click();
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }
    const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
    const isValidFileType = validFileTypes.includes(file.type);
    const isValidFileSize = file.size <= 5000000; // 5MB
    if (isValidFileType && isValidFileSize) {
      setRawImage(file);
      setInvalidImageSize(false);
      setInvalidImageType(false);
      setAvatarAddInProgress(true);
    } else {
      if (!isValidFileSize) {
        setInvalidImageSize(true);
      } else {
        setInvalidImageType(true);
      }
    }
    if (inputRef.current) {
      (inputRef.current as HTMLInputElement).value = "";
    }
  };
  return (
    <>
      {avatarAddInProgress ? (
        <AppBox flexDirection="column" gap="sm" justifyContent="space-between" className="w-100 flex-1">
          <AppBox alignItems="center" flexDirection="column" gap="xs">
            <AppText>Drag image to reposition</AppText>
            <AvatarEditor
              backgroundColor="white"
              ref={cropRef}
              image={rawImage}
              borderRadius={300}
              style={{ borderRadius: "10px" }}
              scale={slideValue / 15}
              width={350}
              height={350}
              disableHiDPIScaling
            />
            <AppBox gap="xs" alignItems="center">
              <IconImage height={15} width={15} />
              <AppBox className="range-slider">
                <AppBox className="range-slider_thumb" style={{ left: `${slideValue - 10}%` }}></AppBox>
                <AppBox className="range-slider_line">
                  <AppBox className="range-slider_line-fill" style={{ width: `${slideValue}%` }}></AppBox>
                </AppBox>
                <input
                  type="range"
                  min={10}
                  max={100}
                  value={slideValue}
                  onChange={handleSliderChange}
                  className="range-slider_input"
                />
              </AppBox>
              <IconImage />
            </AppBox>
          </AppBox>
          <AppBox gap="xs" justifyContent="end">
            <AppButton
              label="Cancel"
              variant="outline"
              color="gray"
              borderLight
              disabled={uploadedImageStatus === StatusEnum.Loading}
              onClick={() => {
                setAvatarAddInProgress(false);
                setSlideValue(10);
              }}
            />
            <AppButton
              label="Crop"
              variant="light"
              onClick={handleCrop}
              loading={uploadedImageStatus === StatusEnum.Loading}
            />
          </AppBox>
        </AppBox>
      ) : (
        <AppBox flexDirection="column" justifyContent="space-between" gap="sm" className="flex-1">
          <AppBox flexDirection="column" alignItems="center" gap="sm" className="w-100" pt="sm">
            <AppLink
              onClick={(e) => {
                e.preventDefault();
                handleUploadClick(e);
              }}
            >
              <AppBorderBox radius="rounded">
                <AppBox alignItems="center" justifyContent="center" style={{ width: "9rem", height: "9rem" }}>
                  {avatarAdded ? (
                    <AppAvatar username={"Avatar"} size="6xl" src={formData.avatar || ""} />
                  ) : (
                    <IconImageAdd height={40} width={40} color="rgba(var(--border-200))" />
                  )}
                </AppBox>
              </AppBorderBox>
            </AppLink>
            <AppBox flexDirection="column" gap="xs">
              {avatarAdded ? (
                <AppBox flexDirection="column" gap="2xs">
                  <AppText as="label" size="lg" textAlign="center">
                    Referee's Image
                  </AppText>
                  <AppText textAlign="center">
                    A clear photo establishes your credibility and makes it easy for teams and players to identify you.
                  </AppText>
                </AppBox>
              ) : (
                <AppBox flexDirection="column" gap="2xs">
                  <AppText as="label" size="lg" textAlign="center">
                    Add Referee's Image
                  </AppText>
                  <AppText textAlign="center">
                    We support PNGs and JPEGs. Supported file size must be under 5 MB
                  </AppText>
                </AppBox>
              )}
              {isInvalidImageSize && (
                <AppText as="span" color="danger">
                  The Image size is more than 5 MB
                </AppText>
              )}
              {isInvalidImageType && (
                <AppText as="span" color="danger">
                  The Image type is not supported.
                </AppText>
              )}
              {isFormInvalid(findInputError(formErrors, "avatar")) && (
                <AppText as="span" color="danger">
                  <>{toSentenceCase(`${formErrors.avatar?.message && formErrors.avatar.message}`)}</>
                </AppText>
              )}
              <AppBox alignItems="center" justifyContent="center" gap="sm">
                <input
                  type="file"
                  ref={inputRef}
                  onChange={(e) => {
                    handleImageChange(e);
                  }}
                  accept=".jpeg,.jpg,.png"
                  hidden
                />
                <AppButton
                  size="sm"
                  label={avatarAdded ? "Change Image" : "Upload Image"}
                  color={avatarAdded ? "gray" : "primary"}
                  variant={avatarAdded ? "outline" : "filled"}
                  borderLight={avatarAdded}
                  onClick={(e) => {
                    handleUploadClick(e);
                  }}
                />
                {avatarAdded && (
                  <AppButton
                    size="sm"
                    label={"Remove Image"}
                    color={"danger"}
                    variant={"light"}
                    onClick={() => {
                      setAvatarAdded(false);
                      handleImageUpload(null);
                    }}
                  />
                )}
              </AppBox>
            </AppBox>
          </AppBox>
          <AppBox gap="xs" justifyContent="end">
            {avatarAdded || (
              <AppButton
                label="Skip"
                variant="outline"
                color="gray"
                borderLight
                onClick={() => {
                  onSkipOrNext(formStep);
                }}
              />
            )}
            {refereeData && (
              <AppButton
                label="Save & Exit"
                variant="light"
                onClick={() => {
                  onSaveAndExit();
                }}
                loading={submitStatus === StatusEnum.Loading}
                disabled={uploadedImageStatus === StatusEnum.Loading}
              />
            )}
            {avatarAdded && (
              <AppButton
                label="Next"
                onClick={() => {
                  onSkipOrNext(formStep);
                }}
                disabled={submitStatus === StatusEnum.Loading || uploadedImageStatus === StatusEnum.Loading}
              />
            )}
          </AppBox>
        </AppBox>
      )}
    </>
  );
};

export default StadiumImageAddEdit;
