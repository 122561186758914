import { useEffect } from "react";
import { AppBox, AppGridBox, AppSkeletonNewsList } from "../../../commons/components";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { RootState } from "../../../redux/store";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchLatestNews, fetchNews } from "../../../redux/slices/news-slice";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import setDocumentTitle from "../../../commons/utilities/document-title";

export default function TournamentNews() {
  const dispatch = useAppDispatch();
  const { newsList, latestNewsList } = useAppSelector((state: RootState) => state.news);
  const { player } = useAppSelector((state) => state.footballPlayerDetail);
  useEffect(() => {
    if (latestNewsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Ascending,
      };
      dispatch(fetchLatestNews(newsOptions));
    }
  }, [latestNewsList.status]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  return (
    <AppBox className="page-content" gap="md">
      {newsList.status === StatusEnum.Loading || StatusEnum.Idle ? (
        <AppSkeletonNewsList isBig />
      ) : (
        <NewsTimelineCard newsList={newsList.data} timelineType="big" />
      )}
      <AppBox displayBlock>
        {latestNewsList.status === StatusEnum.Loading || StatusEnum.Idle ? (
          <AppSkeletonNewsList />
        ) : (
          <NewsTimelineCard newsList={latestNewsList.data} title="Latest News" timelineType="small" />
        )}
      </AppBox>
    </AppBox>
  );
}
