import { configureStore } from "@reduxjs/toolkit";
import {
  authReducer,
  footballMatchCommentaryReducer,
  footballCompetitionAwardsReducer,
  footballCompetitionReducer,
  footballCompetitionStatsReducer,
  footballCompetitionTeamsReducer,
  footballManageCompetitionDeleteReducer,
  footballManageCompetitionListReducer,
  footballManageCompetitionReducer,
  footballManageCompetitionSeasonTeamAddReducer,
  footballManageCompetitionSeasonTeamListReducer,
  footballManagePlayerDeleteReducer,
  footballManagePlayerListReducer,
  footballManagePlayerReducer,
  footballManagePlayerRetireReducer,
  footballManageRefereeDeleteReducer,
  footballManageRefereeListReducer,
  footballManageSeasonAddReducer,
  footballManageSeasonEditReducer,
  footballManageSeasonEndReducer,
  footballManageTeamDeleteReducer,
  footballManageTeamListReducer,
  footballManageTeamPlayerAddEditReducer,
  footballManageTeamPlayersReducer,
  footballManageTeamReducer,
  footballManageTeamTerminateReducer,
  footballMatchDetailReducer,
  footballMatchListReducer,
  footballMatchStatsReducer,
  footballPlayerAttributeReducer,
  footballPlayerAwardReducer,
  footballPlayerPositionsReducer,
  footballPlayerReducer,
  footballPlayerStatisticsReducer,
  footballPlayerTeamsReducer,
  footballPlayerTrainingsReducer,
  footballSeasonReducer,
  footballSeasonStandingReducer,
  footballComparativeStatisticsReducer,
  footballTeamAwardReducer,
  footballTeamCompetitionsReducer,
  footballTeamDetailReducer,
  footballTeamPlayersReducer,
  footballTeamPlayersStatisticsReducer,
  footballTeamStatisticsReducer,
  footballTeamTrainingReducer,
  imageUploadReducer,
  manageStadiumDeleteReducer,
  manageStadiumListReducer,
  manageStadiumReducer,
  newsReducer,
  sessionReducer,
  pageTitleReducer,
  footballManageSeasonFixtureGroupAddReducer,
  footballManageSeasonFixtureGroupsReducer,
  footballManageSeasonFixtureGroupEditReducer,
  footballManageSeasonFixtureGroupDeleteReducer,
  footballManageSeasonFixtureGroupFixturesReducer,
  footballManageSeasonFixtureGroupFixtureAddReducer,
  footballManageSeasonFixtureGroupFixtureEditReducer,
  footballManageSeasonFixtureGroupFixtureDeleteReducer,
  footballManageSeasonMatchDetailReducer,
  footballManageSeasonMatchTeamLineupReducer,
  footballManageCompetitionSeasonTeamRemoveReducer,
  footballManageTeamPlayerRemoveReducer,
  footballManagePlayerActiveReducer,
  footballManageTeamActivateReducer,
  footballManageSeasonMatchCommentaryListReducer,
  footballManageSeasonMatchCommentaryAddReducer,
  footballManageSeasonMatchCommentaryEditReducer,
  footballManageSeasonMatchCommentaryDeleteReducer,
  footballManageSeasonMatchPossessionListReducer,
  footballManageSeasonMatchPossessionAddReducer,
  footballManageSeasonMatchPossessionEditReducer,
  footballManageSeasonMatchPossessionDeleteReducer,
  footballManageSeasonMatchPlayersStatisticListReducer,
  footballManageSeasonMatchPlayersStatisticAddReducer,
  footballManageSeasonMatchPlayersStatisticEditReducer,
  footballManageSeasonMatchPlayersStatisticDeleteReducer,
  footballManageSeasonMatchEditReducer,
  footballSeasonMatchTimeReducer,
  footballManagePlayersNotInTeamReducer,
  footballManageCompetitionSeasonReducer,
  footballPlayerCompetitionsReducer,
  footballManageTeamCompetitionsReducer,
  footballManageCompetitionSeasonStandingReducer,
  footballManagePlayerCompetitionsReducer,
  footballManagePlayerStatisticsReducer,
  footballManageTeamStatisticsReducer,
  footballManageCompetitionSeasonStatisticsReducer,
  footballManageCompetitionSeasonMatchListReducer,
  footballPlayerMatchListReducer,
  footballManagePlayerMatchListReducer,
  footballMatchTeamsReducer,
  footballManageSeasonMatchTeamsReducer,
  manageStadiumEditReducer,
  manageStadiumAddReducer,
  footballManageRefereeEditReducer,
  footballManageRefereeAddReducer,
  footballManageCompetitionEditReducer,
  footballManageCompetitionAddReducer,
  footballManagePlayerAddReducer,
  footballManagePlayerEditReducer,
  footballManageTeamEditReducer,
  footballManageTeamAddReducer,
  toolbarSearchReducer,
  footballManageSeasonTeamGroupAddReducer,
  footballManageSeasonTeamGroupEditReducer,
  footballManageSeasonTeamGroupDeleteReducer,
  footballManageSeasonTeamGroupListReducer,
  footballManageSeasonTeamGroupTeamAddReducer,
  footballManageSeasonTeamGroupTeamRemoveReducer,
  footballSeasonStatisticsReducer,
  footballManageCompetitionSeasonComparativeStatisticsReducer,
  footballManageSeasonKnockoutListReducer,
  footballManageSeasonKnockoutCreateReducer,
  footballManageSeasonKnockoutEditReducer,
  generalSearchReducer,
  footballSeasonKnockoutListReducer,
  toolbarCompetitionSelectReducer,
  globalSeasonSelectReducer,
  footballManageSeasonMatchPlayerPassStatisticListReducer,
  footballManageSeasonMatchPlayerPassStatisticAddReducer,
  footballManageSeasonMatchPlayerPassStatisticEditReducer,
  footballManageSeasonMatchPlayerPassStatisticDeleteReducer,
  stepperFormReducer,
  footballManageSeasonKnockoutMatchEditReducer,
  fileUploadReducer,
  footballSeasonMatchAddTimeReducer,
  footballSeasonMatchEndReducer,
  footballSeasonMatchStartReducer,
  footballSeasonMatchStateChangeReducer,
  footballManageSeasonMatchPenaltyAddEditDeleteReducer,
  footballManageSeasonMatchPenaltyListReducer,
  footballManageSeasonMatchStatisticReducer,
  footballManagePlayerAttributeListReducer,
  footballManagePlayerAttributeReducer,
  footballManagePlayerAttributeAddEditReducer,
} from "./slices";
const store = configureStore({
  reducer: {
    auth: authReducer,
    fileUpload: fileUploadReducer,
    generalSearch: generalSearchReducer,
    globalSeasonSelect: globalSeasonSelectReducer,
    imageUpload: imageUploadReducer,
    news: newsReducer,
    pageTitle: pageTitleReducer,
    session: sessionReducer,
    stepperForm: stepperFormReducer,
    toolbarCompetitionSelect: toolbarCompetitionSelectReducer,
    toolbarSearch: toolbarSearchReducer,
    //public
    //competition
    footballComparativeStatistics: footballComparativeStatisticsReducer,
    footballCompetition: footballCompetitionReducer,
    footballCompetitionTeams: footballCompetitionTeamsReducer,
    footballCompetitionAwards: footballCompetitionAwardsReducer,
    footballCompetitionStats: footballCompetitionStatsReducer,
    //season
    footballSeason: footballSeasonReducer,
    footballSeasonStanding: footballSeasonStandingReducer,
    footballSeasonStatistics: footballSeasonStatisticsReducer,
    footballSeasonKnockoutList: footballSeasonKnockoutListReducer,
    //match
    footballMatchList: footballMatchListReducer,
    footballMatchDetail: footballMatchDetailReducer,
    footballMatchTeams: footballMatchTeamsReducer,
    footballMatchStatistics: footballMatchStatsReducer,
    footballMatchCommentary: footballMatchCommentaryReducer,
    //player
    footballPlayerDetail: footballPlayerReducer,
    footballPlayerAward: footballPlayerAwardReducer,
    footballPlayerTeams: footballPlayerTeamsReducer,
    footballPlayerTraining: footballPlayerTrainingsReducer,
    footballPlayerPositions: footballPlayerPositionsReducer,
    footballPlayerStatistics: footballPlayerStatisticsReducer,
    footballPlayerAttributes: footballPlayerAttributeReducer,
    footballPlayerCompetitions: footballPlayerCompetitionsReducer,
    footballPlayerMatchList: footballPlayerMatchListReducer,
    //team
    footballTeamDetail: footballTeamDetailReducer,
    footballTeamStatistics: footballTeamStatisticsReducer,
    footballTeamPlayers: footballTeamPlayersReducer,
    footballTeamAwards: footballTeamAwardReducer,
    footballTeamCompetitions: footballTeamCompetitionsReducer,
    footballTeamTraining: footballTeamTrainingReducer,
    footballTeamPlayersStatistics: footballTeamPlayersStatisticsReducer,
    //Admin
    //player
    footballManagePlayerList: footballManagePlayerListReducer,
    footballManagePlayer: footballManagePlayerReducer,
    footballManagePlayerAdd: footballManagePlayerAddReducer,
    footballManagePlayerEdit: footballManagePlayerEditReducer,
    footballManagePlayerDelete: footballManagePlayerDeleteReducer,
    footballManagePlayerRetire: footballManagePlayerRetireReducer,
    footballManagePlayerActive: footballManagePlayerActiveReducer,
    footballManagePlayersNotInTeam: footballManagePlayersNotInTeamReducer,
    footballManagePlayerCompetitions: footballManagePlayerCompetitionsReducer,
    footballManagePlayerStatistics: footballManagePlayerStatisticsReducer,
    footballManagePlayerMatchList: footballManagePlayerMatchListReducer,
    footballManagePlayerAttributeList: footballManagePlayerAttributeListReducer,
    footballManagePlayerAttribute: footballManagePlayerAttributeReducer,
    footballManagePlayerAttributeAddEdit: footballManagePlayerAttributeAddEditReducer,
    //team
    footballManageTeamList: footballManageTeamListReducer,
    footballManageTeam: footballManageTeamReducer,
    footballManageTeamEdit: footballManageTeamEditReducer,
    footballManageTeamAdd: footballManageTeamAddReducer,
    footballManageTeamDelete: footballManageTeamDeleteReducer,
    footballManageTeamTerminate: footballManageTeamTerminateReducer,
    footballManageTeamActivate: footballManageTeamActivateReducer,
    footballManageTeamPlayers: footballManageTeamPlayersReducer,
    footballManageTeamPlayerAddEdit: footballManageTeamPlayerAddEditReducer,
    footballManageTeamPlayerRemove: footballManageTeamPlayerRemoveReducer,
    footballManageTeamCompetitions: footballManageTeamCompetitionsReducer,
    footballManageTeamStatistics: footballManageTeamStatisticsReducer,
    //competition
    footballManageCompetition: footballManageCompetitionReducer,
    footballManageCompetitionList: footballManageCompetitionListReducer,
    footballManageCompetitionEdit: footballManageCompetitionEditReducer,
    footballManageCompetitionAdd: footballManageCompetitionAddReducer,
    footballManageCompetitionDelete: footballManageCompetitionDeleteReducer,
    footballManageCompetitionSeasonTeamList: footballManageCompetitionSeasonTeamListReducer,
    footballManageCompetitionSeasonTeamAdd: footballManageCompetitionSeasonTeamAddReducer,
    footballManageCompetitionSeasonTeamRemove: footballManageCompetitionSeasonTeamRemoveReducer,
    footballManageCompetitionSeason: footballManageCompetitionSeasonReducer,
    footballManageCompetitionSeasonStanding: footballManageCompetitionSeasonStandingReducer,
    footballManageCompetitionSeasonComparativeStatistics: footballManageCompetitionSeasonComparativeStatisticsReducer,
    footballManageCompetitionSeasonStatistics: footballManageCompetitionSeasonStatisticsReducer,
    footballManageCompetitionSeasonMatchList: footballManageCompetitionSeasonMatchListReducer,
    //season
    footballManageSeasonAdd: footballManageSeasonAddReducer,
    footballManageSeasonEdit: footballManageSeasonEditReducer,
    footballManageSeasonEnd: footballManageSeasonEndReducer,
    //season-teamGroup
    footballManageSeasonTeamGroupAdd: footballManageSeasonTeamGroupAddReducer,
    footballManageSeasonTeamGroupEdit: footballManageSeasonTeamGroupEditReducer,
    footballManageSeasonTeamGroupDelete: footballManageSeasonTeamGroupDeleteReducer,
    footballManageSeasonTeamGroupList: footballManageSeasonTeamGroupListReducer,
    footballManageSeasonTeamGroupTeamAdd: footballManageSeasonTeamGroupTeamAddReducer,
    footballManageSeasonTeamGroupTeamRemove: footballManageSeasonTeamGroupTeamRemoveReducer,
    //season-fixtureGroup
    footballManageSeasonFixtureGroupAdd: footballManageSeasonFixtureGroupAddReducer,
    footballManageSeasonFixtureGroupEdit: footballManageSeasonFixtureGroupEditReducer,
    footballManageSeasonFixtureGroupDelete: footballManageSeasonFixtureGroupDeleteReducer,
    footballManageSeasonFixtureGroups: footballManageSeasonFixtureGroupsReducer,
    footballManageSeasonFixtureGroupFixtures: footballManageSeasonFixtureGroupFixturesReducer,
    footballManageSeasonFixtureGroupFixtureAdd: footballManageSeasonFixtureGroupFixtureAddReducer,
    footballManageSeasonFixtureGroupFixtureEdit: footballManageSeasonFixtureGroupFixtureEditReducer,
    footballManageSeasonFixtureGroupFixtureDelete: footballManageSeasonFixtureGroupFixtureDeleteReducer,
    //season-match
    footballSeasonMatchTime: footballSeasonMatchTimeReducer,
    footballManageSeasonMatchAddTime: footballSeasonMatchAddTimeReducer,
    footballManageSeasonMatchDetail: footballManageSeasonMatchDetailReducer,
    footballManageSeasonMatchEdit: footballManageSeasonMatchEditReducer,
    footballManageSeasonMatchEnd: footballSeasonMatchEndReducer,
    footballManageSeasonMatchStart: footballSeasonMatchStartReducer,
    footballManageSeasonMatchStateChange: footballSeasonMatchStateChangeReducer,
    footballManageSeasonMatchTeamLineup: footballManageSeasonMatchTeamLineupReducer,
    footballManageSeasonMatchTeams: footballManageSeasonMatchTeamsReducer,
    footballManageSeasonMatchPenaltyAddEditDelete: footballManageSeasonMatchPenaltyAddEditDeleteReducer,
    footballManageSeasonMatchPenaltyList: footballManageSeasonMatchPenaltyListReducer,
    //season-match-commentary
    footballManageSeasonMatchCommentaryList: footballManageSeasonMatchCommentaryListReducer,
    footballManageSeasonMatchCommentaryAdd: footballManageSeasonMatchCommentaryAddReducer,
    footballManageSeasonMatchCommentaryEdit: footballManageSeasonMatchCommentaryEditReducer,
    footballManageSeasonMatchCommentaryDelete: footballManageSeasonMatchCommentaryDeleteReducer,
    //season-match-statistics
    footballManageSeasonMatchPossessionList: footballManageSeasonMatchPossessionListReducer,
    footballManageSeasonMatchPossessionAdd: footballManageSeasonMatchPossessionAddReducer,
    footballManageSeasonMatchPossessionEdit: footballManageSeasonMatchPossessionEditReducer,
    footballManageSeasonMatchPossessionDelete: footballManageSeasonMatchPossessionDeleteReducer,
    footballManageSeasonMatchStatistic: footballManageSeasonMatchStatisticReducer,
    footballManageSeasonMatchPlayersStatisticList: footballManageSeasonMatchPlayersStatisticListReducer,
    footballManageSeasonMatchPlayersStatisticAdd: footballManageSeasonMatchPlayersStatisticAddReducer,
    footballManageSeasonMatchPlayersStatisticEdit: footballManageSeasonMatchPlayersStatisticEditReducer,
    footballManageSeasonMatchPlayersStatisticDelete: footballManageSeasonMatchPlayersStatisticDeleteReducer,
    footballManageSeasonMatchPlayerPassStatisticList: footballManageSeasonMatchPlayerPassStatisticListReducer,
    footballManageSeasonMatchPlayerPassStatisticAdd: footballManageSeasonMatchPlayerPassStatisticAddReducer,
    footballManageSeasonMatchPlayerPassStatisticEdit: footballManageSeasonMatchPlayerPassStatisticEditReducer,
    footballManageSeasonMatchPlayerPassStatisticDelete: footballManageSeasonMatchPlayerPassStatisticDeleteReducer,
    //season-knockout
    footballManageSeasonKnockoutList: footballManageSeasonKnockoutListReducer,
    footballManageSeasonKnockoutCreate: footballManageSeasonKnockoutCreateReducer,
    footballManageSeasonKnockoutEdit: footballManageSeasonKnockoutEditReducer,
    footballManageSeasonKnockoutMatchEdit: footballManageSeasonKnockoutMatchEditReducer,
    //referee
    footballManageRefereeList: footballManageRefereeListReducer,
    footballManageRefereeEdit: footballManageRefereeEditReducer,
    footballManageRefereeAdd: footballManageRefereeAddReducer,
    footballManageRefereeDelete: footballManageRefereeDeleteReducer,
    //stadium
    manageStadium: manageStadiumReducer,
    manageStadiumList: manageStadiumListReducer,
    manageStadiumEdit: manageStadiumEditReducer,
    manageStadiumAdd: manageStadiumAddReducer,
    manageStadiumDelete: manageStadiumDeleteReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false });
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
