import {
  AppAvatar,
  AppBox,
  AppPaper,
  AppSelect,
  AppSkeletonHeader,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppTabs, { TabItem } from "../../../../commons/components/Tabs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";
import { IFootballSeason } from "../../../../commons/models/football/interface/i-football-season";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import { TournamentTypeEnum } from "../../../../commons/enums/tournament-type-enum";
import { ISelectOptions } from "../../../../commons/components/ui-components/Select";
import { findIndexByCondition } from "../../../../commons/utilities/array-utils";
import { setGlobalSeasonSelectedOption } from "../../../../redux/slices/global-season-select-slice";

interface ITournamentDetailHeaderProps {
  tournament: IFootballCompetition;
}

export default function TournamentDetailHeader({ tournament }: ITournamentDetailHeaderProps) {
  const [currentTab, setCurrentTab] = useState("summary");
  const [activeItem, setActiveItem] = useState<TabItem>();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [seasonOptions, setSeasonOptions] = useState<ISelectOptions[]>([]);
  const [currentSeasonOption, setCurrentSeasonOption] = useState<ISelectOptions>();
  const { competition } = useAppSelector((state) => state.footballCompetition);
  const { seasonList } = useAppSelector((state) => state.footballSeason);

  useEffect(() => {
    const url = location.pathname.split("/");
    setCurrentTab(url[url.length - 1]);
  }, [activeItem, location.pathname]);

  useEffect(() => {
    const lastPathSegment = location.pathname.split("/").pop();
    if (!isNaN(Number(lastPathSegment))) {
      setCurrentTab("summary");
    }
  }, [location.pathname]);

  const tabItems = [
    {
      label: "Summary",
      value: "summary",
      href: "summary",
    },
    {
      label: "Profile",
      value: "profile",
      href: "profile",
    },
    {
      label: "Teams",
      value: "teams",
      href: "teams",
    },
    {
      label: "Events",
      value: "events",
      href: "events",
    },
    {
      label: "Statistics",
      value: "statistics",
      href: "statistics",
    },
    {
      label: "Standings",
      value: "standings",
      href: "standings",
      hidden: competition.data.type === TournamentTypeEnum.Knockout,
    },
    {
      label: "Brackets",
      value: "brackets",
      href: "brackets",
      hidden: competition.data.type === TournamentTypeEnum.League,
    },
    {
      label: "News",
      value: "news",
      href: "news",
      hidden: true, // TODO: To be implemented later
    },
  ];

  useEffect(() => {
    if (seasonList.length > 0) {
      const seasonYearArray: ISelectOptions[] = [];
      seasonList.forEach((season) => {
        seasonYearArray.push({ title: season.title, value: season.id, secondaryValue: season });
      });
      const currentTeamSeasonId = competition.data.latestSeason?.id;
      let currentTeamSeasonIndex = findIndexByCondition(
        seasonYearArray,
        (option) => option.value === currentTeamSeasonId
      );
      if (currentTeamSeasonIndex >= 0) {
        setCurrentSeasonOption(seasonYearArray[currentTeamSeasonIndex]);
        dispatch(setGlobalSeasonSelectedOption(seasonYearArray[currentTeamSeasonIndex]));
      } else {
        setCurrentSeasonOption(seasonYearArray[0]);
        dispatch(setGlobalSeasonSelectedOption(seasonYearArray[0]));
      }
      setSeasonOptions(seasonYearArray);
    }
  }, [seasonList]);

  const handleSeasonChange = (option: ISelectOptions) => {
    dispatch(setGlobalSeasonSelectedOption(option));
  };

  return (
    <AppPaper shadow="xs" padding="none">
      {competition.status === StatusEnum.Loading ? (
        <AppSkeletonHeader />
      ) : (
        <AppBox
          alignItems="center"
          gap="xs"
          p="sm"
          style={{
            borderBottom: "1px solid rgb(var(--border-default))",
          }}
        >
          <AppAvatar username={tournament.title} size="2xl" src={tournament?.logo || ""} />
          <AppBox flexDirection="column" gap="3xs">
            <AppTitle as="h4">{tournament.title}</AppTitle>
            {seasonOptions.length > 0 && (
              <AppBox gap="3xs" alignItems="center">
                <AppText>Season:</AppText>
                <AppBox>
                  <AppSelect
                    options={seasonOptions}
                    currentOption={currentSeasonOption}
                    smallSize
                    onChange={handleSeasonChange}
                  />
                </AppBox>
              </AppBox>
            )}
          </AppBox>
        </AppBox>
      )}
      <AppBox p="sm">
        <AppTabs
          as="a"
          onClick={(e) => {
            setActiveItem(e);
            window.scrollTo(0, 0);
          }}
          activeValue={currentTab}
          tabItems={tabItems}
        />
      </AppBox>
    </AppPaper>
  );
}
