import {
  AppAvatar,
  AppBanner,
  AppBox,
  AppDateAndTime,
  AppDivider,
  AppLink,
  AppPaper,
  AppSkeletonHeader,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppTabs, { TabItem } from "../../../../commons/components/Tabs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IFootballPlayerDetail } from "../../../../commons/models/football/interface/i-football-player";
import AppBorderBox from "../../../../commons/components/BorderBox";
import { useAppSelector } from "../../../../hooks/app";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import PlayerInfoHeader from "./PlayerInfoHeader";
import { toTitleCase } from "../../../../commons/utilities/string-utils";
import IconPhotoFilm from "../../../../commons/components/icons/photo-film";

interface PlayerDetailHeaderProps {
  playerInfo: IFootballPlayerDetail;
}

export default function PlayerDetailHeader({ playerInfo }: PlayerDetailHeaderProps) {
  const [currentTab, setCurrentTab] = useState("summary");
  const [activeItem, setActiveItem] = useState<TabItem>();
  const location = useLocation();
  const { playerStatus } = useAppSelector((state) => state.footballPlayerDetail);

  useEffect(() => {
    const url = location.pathname.split("/");
    setCurrentTab(url[url.length - 1]);
  }, [activeItem, location.pathname]);

  useEffect(() => {
    const lastPathSegment = location.pathname.split("/").pop();
    if (!isNaN(Number(lastPathSegment))) {
      setCurrentTab("summary");
    }
  }, [location.pathname]);

  const tabItems = [
    {
      label: "Summary",
      value: "summary",
      href: "summary",
    },
    {
      label: "Profile",
      value: "profile",
      href: "profile",
    },

    {
      label: "Events",
      value: "events",
      href: "events",
    },
    {
      label: "Statistics",
      value: "statistics",
      href: "statistics",
    },
    {
      label: "News",
      value: "news",
      href: "news",
      hidden: true,
      // TODO: To be implemented later
    },
  ];

  return (
    <AppPaper shadow="xs" padding="none">
      {playerStatus === StatusEnum.Loading ? (
        <AppSkeletonHeader />
      ) : (
        <AppBox
          className="player-header"
          alignItems="center"
          gap="xs"
          p="sm"
          style={{
            borderBottom: "1px solid rgb(var(--border-default))",
          }}
        >
          {currentTab === "summary" ? (
            <>
              {playerInfo.banner ? (
                <AppBanner username={playerInfo.fullName} src={playerInfo.banner || ""} />
              ) : (
                <AppBorderBox>
                  <AppBox alignItems="center" justifyContent="center" style={{ width: "16.5rem", height: "22rem" }}>
                    <IconPhotoFilm height={40} width={40} color="rgba(var(--border-200))" />
                  </AppBox>
                </AppBorderBox>
              )}
            </>
          ) : (
            <AppAvatar username={playerInfo.fullName} size="2xl" src={playerInfo.avatar || ""} />
          )}
          <AppBox flexDirection="column" className="flex-1">
            <AppBox flexDirection="column" gap="3xs">
              <AppTitle as="h4">{toTitleCase(playerInfo.fullName)}</AppTitle>
              <AppBox className="player-sub-title" alignItems="center" gap="3xs">
                {playerInfo.additionalInfo && playerInfo.additionalInfo.team && (
                  <>
                    <AppBox gap="3xs" alignItems="center">
                      <AppLink to={`/teams/${playerInfo.additionalInfo.team.id}`}>
                        <AppAvatar
                          username={playerInfo.additionalInfo.team.name}
                          src={playerInfo.additionalInfo.team.logo || ""}
                          size="xs"
                        />
                      </AppLink>
                      <AppBox flexDirection="column">
                        <AppLink to={`/teams/${playerInfo.additionalInfo.team.id}`}>
                          <AppText fontWeight="semibold" size="md">
                            {toTitleCase(playerInfo.additionalInfo.team.name)}
                          </AppText>
                        </AppLink>
                        {playerInfo.debutAt?.isValid() && (
                          <AppBox alignItems="center" gap="3xs">
                            <AppBox alignItems="center" justifyContent="center" gap="3xs">
                              <AppText as="label" size="md">
                                Debut:
                              </AppText>
                              <AppDateAndTime size="md" date={playerInfo.debutAt || ""} format="DD MMM, YYYY" />
                            </AppBox>
                            <AppBorderBox border={["Left"]} pl="3xs">
                              <AppBox alignItems="center">
                                <AppText as="label" size="md" lineClamp={1}>
                                  {playerInfo.latestSeason.competition?.title}
                                </AppText>
                              </AppBox>
                            </AppBorderBox>
                          </AppBox>
                        )}
                      </AppBox>
                    </AppBox>
                  </>
                )}
              </AppBox>
            </AppBox>
            {currentTab === "summary" && <PlayerInfoHeader playerInfo={playerInfo} />}
          </AppBox>
        </AppBox>
      )}
      <AppBox p="sm">
        <AppTabs
          as="a"
          onClick={(e) => {
            setActiveItem(e);
            window.scrollTo(0, 0);
          }}
          activeValue={currentTab}
          tabItems={tabItems}
        />
      </AppBox>
    </AppPaper>
  );
}
