import React, { useEffect, useState } from "react";
import { AppBox, AppButton, AppGridBox, AppInput, AppSelect, AppText } from "../../../../../commons/components";
import { Controller, useForm } from "react-hook-form";
import { findInputError, isFormInvalid } from "../../../../../commons/utilities/form-utils";
import { ISelectOptions } from "../../../../../commons/components/ui-components/Select";
import IconApproval from "../../../../../commons/components/icons/approval";
import IconPersonProhibited from "../../../../../commons/components/icons/person-prohibited";
import { findIndexByCondition } from "../../../../../commons/utilities/array-utils";
import { FieldErrors, FieldValues } from "react-hook-form";
import { ITeamDTO } from "../../../../../api-services/football/admin/manage-football-team-service";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import { setStepperFormData } from "../../../../../redux/slices/stepper-form-slice";
import { TeamStatusEnum } from "../../../../../commons/enums/team-status-enum";
import { IFootballTeam } from "../../../../../commons/models/football/interface/i-football-team";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import AppColorPicker from "../ColorPicker";
import { toSentenceCase } from "../../../../../commons/utilities/string-utils";
interface TeamSpecificsAddEditProps {
  teamData?: IFootballTeam | null;
  formStep: number;
  formErrors: FieldErrors<FieldValues>;
  submitStatus: StatusEnum;
  onSubmit: (currentStep: number) => void;
  onBack: (currentStep: number) => void;
  onClearError: (errorKey: string) => void;
}

const TeamSpecificsAddEdit = ({
  teamData,
  formStep,
  formErrors,
  submitStatus,
  onBack,
  onSubmit,
  onClearError,
}: TeamSpecificsAddEditProps) => {
  const methods = useForm();
  const dispatch = useAppDispatch();
  const [teamStatusIndex, setTeamStatusIndex] = useState(0);
  const formData = useAppSelector<ITeamDTO>((state) => state.stepperForm.stepperFormData);

  const teamStatusOptions: ISelectOptions[] = [
    {
      title: "Active",
      icon: <IconApproval color="var(--fg-outline-success)" />,
      value: TeamStatusEnum.Active,
    },
    {
      title: "Terminated",
      icon: <IconPersonProhibited color="var(--fg-outline-danger)" />,
      value: TeamStatusEnum.Terminated,
    },
  ];

  const convertValue = (key: string, value: string): any => {
    const numericKeys = ["status"];
    return numericKeys.includes(key) ? Number(value) : value;
  };

  const handleInputChange = (value: string, key: string) => {
    const teamData: ITeamDTO = {
      ...formData,
      [key]: convertValue(key, value),
    };
    dispatch(setStepperFormData<ITeamDTO>(teamData));
    onClearError(key);
  };

  useEffect(() => {
    if (teamData) {
      let teamStatusIndex = findIndexByCondition(
        teamStatusOptions,
        (option) => option.value === teamData.status.toString()
      );
      if (teamStatusIndex >= 0) {
        setTeamStatusIndex(teamStatusIndex);
      }
      methods.setValue("status", `${teamData.status}`);
    }
  }, [teamData]);

  useEffect(() => {
    if (!teamData) {
      const stadiumData: ITeamDTO = {
        ...formData,
        status: Number(teamStatusOptions[0].value),
      };
      dispatch(setStepperFormData<ITeamDTO>(stadiumData));
    }
  }, []);

  return (
    <AppBox flexDirection="column" justifyContent="space-between" gap="sm" className="flex-1">
      <AppBox flexDirection="column" gap="sm" className="w-100">
        <AppGridBox className="w-100 flex-1" style={{ gridTemplateColumns: "1fr 2fr" }} gap="sm">
          <AppBox alignItems="center">
            <AppText as="label" size="lg">
              Team Colors
            </AppText>
          </AppBox>
          <AppBox flexDirection="column" gap="2xs">
            <AppBox alignItems="center" gap="sm" justifyContent="end">
              <Controller
                control={methods.control}
                name="homeColor"
                rules={{ required: false }}
                defaultValue={teamData ? teamData.homeColor : null}
                render={({ field: { onChange, value } }) => (
                  <AppBox flexDirection="column" alignItems="center" gap="3xs" mx="2xs">
                    <AppColorPicker
                      color={value}
                      setColor={(color: string) => {
                        onChange(color);
                        handleInputChange(color, "homeColor");
                      }}
                    />
                    <AppText as="label" size="sm">
                      Home
                    </AppText>
                  </AppBox>
                )}
              />
              <Controller
                control={methods.control}
                name="awayColor"
                rules={{ required: false }}
                defaultValue={teamData ? teamData.awayColor : null}
                render={({ field: { onChange, value } }) => (
                  <AppBox flexDirection="column" alignItems="center" gap="3xs" mx="2xs">
                    <AppColorPicker
                      color={value}
                      setColor={(color: string) => {
                        onChange(color);
                        handleInputChange(color, "awayColor");
                      }}
                    />
                    <AppText as="label" size="sm">
                      Away
                    </AppText>
                  </AppBox>
                )}
              />
              <Controller
                control={methods.control}
                name="thirdColor"
                rules={{ required: false }}
                defaultValue={teamData ? teamData.thirdColor : null}
                render={({ field: { onChange, value } }) => (
                  <AppBox flexDirection="column" alignItems="center" gap="3xs" mx="3xs">
                    <AppColorPicker
                      color={value}
                      setColor={(color: string) => {
                        onChange(color);
                        handleInputChange(color, "thirdColor");
                      }}
                    />
                    <AppText as="label" size="sm">
                      Optional
                    </AppText>
                  </AppBox>
                )}
              />
            </AppBox>
            <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
              {isFormInvalid(findInputError(formErrors, "homeColor")) && (
                <AppText as="span" color="danger" textAlign="end">
                  <>{toSentenceCase(`${formErrors.homeColor?.message}`)}</>
                </AppText>
              )}
            </AppGridBox>
            <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
              {isFormInvalid(findInputError(formErrors, "awayColor")) && (
                <AppText as="span" color="danger" textAlign="end">
                  <>{toSentenceCase(`${formErrors.awayColor?.message}`)}</>
                </AppText>
              )}
            </AppGridBox>
            <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
              {isFormInvalid(findInputError(formErrors, "thirdColor")) && (
                <AppText as="span" color="danger" textAlign="end">
                  <>{toSentenceCase(`${formErrors.thirdColor?.message}`)}</>
                </AppText>
              )}
            </AppGridBox>
          </AppBox>
        </AppGridBox>
        <AppGridBox className="w-100 flex-1" style={{ gridTemplateColumns: "1fr 2fr" }} gap="sm">
          <AppBox alignItems="center">
            <AppText as="label" size="lg">
              Goalkeeper Colors
            </AppText>
          </AppBox>
          <AppBox flexDirection="column" gap="2xs">
            <AppBox alignItems="center" gap="sm" justifyContent="end">
              <Controller
                control={methods.control}
                name="goalkeeperHomeColor"
                rules={{ required: false }}
                defaultValue={teamData ? teamData.goalkeeperHomeColor : null}
                render={({ field: { onChange, value } }) => (
                  <AppBox flexDirection="column" alignItems="center" gap="3xs" mx="2xs">
                    <AppColorPicker
                      color={value}
                      setColor={(color: string) => {
                        onChange(color);
                        handleInputChange(color, "goalkeeperHomeColor");
                      }}
                    />
                    <AppText as="label" size="sm">
                      Home
                    </AppText>
                  </AppBox>
                )}
              />
              <Controller
                control={methods.control}
                name="goalkeeperAwayColor"
                rules={{ required: false }}
                defaultValue={teamData ? teamData.goalkeeperAwayColor : null}
                render={({ field: { onChange, value } }) => (
                  <AppBox flexDirection="column" alignItems="center" gap="3xs" mx="2xs">
                    <AppColorPicker
                      color={value}
                      setColor={(color: string) => {
                        onChange(color);
                        handleInputChange(color, "goalkeeperAwayColor");
                      }}
                    />
                    <AppText as="label" size="sm">
                      Away
                    </AppText>
                  </AppBox>
                )}
              />
            </AppBox>
            <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
              {isFormInvalid(findInputError(formErrors, "goalkeeperHomeColor")) && (
                <AppText as="span" color="danger" textAlign="end">
                  <>{toSentenceCase(`${formErrors.goalkeeperHomeColor?.message}`)}</>
                </AppText>
              )}
            </AppGridBox>
            <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
              {isFormInvalid(findInputError(formErrors, "goalkeeperAwayColor")) && (
                <AppText as="span" color="danger" textAlign="end">
                  <>{toSentenceCase(`${formErrors.goalkeeperAwayColor?.message}`)}</>
                </AppText>
              )}
            </AppGridBox>
          </AppBox>
        </AppGridBox>
        <Controller
          name="status"
          control={methods.control}
          rules={{ required: false }}
          defaultValue={teamData ? teamStatusOptions[teamStatusIndex].value : teamStatusOptions[0].value}
          render={({ field: { onChange } }) => (
            <AppBox flexDirection="column">
              <AppGridBox className="w-100 flex-1" style={{ gridTemplateColumns: "1fr 2fr" }} gap="sm">
                <AppBox alignItems="center">
                  <AppText as="label" size="lg">
                    <label>Status</label>
                  </AppText>
                </AppBox>
                <AppSelect
                  options={teamStatusOptions}
                  onChange={(option) => {
                    onChange(option.value);
                    setTeamStatusIndex(
                      findIndexByCondition(teamStatusOptions, (statusOption) => statusOption.value === option.value)
                    );
                    handleInputChange(option.value.toString(), "status");
                  }}
                  currentOption={teamStatusOptions[teamStatusIndex]}
                />
              </AppGridBox>
            </AppBox>
          )}
        />
      </AppBox>
      <AppBox gap="xs" justifyContent="end">
        <AppButton
          label="Back"
          variant="outline"
          color="gray"
          borderLight
          onClick={() => {
            onBack(formStep);
          }}
          disabled={submitStatus === StatusEnum.Loading}
        />
        <AppButton
          type="submit"
          label={teamData ? "Save Changes" : "Create"}
          onClick={() => {
            onSubmit(formStep);
          }}
          loading={submitStatus === StatusEnum.Loading}
        />
      </AppBox>
    </AppBox>
  );
};

export default TeamSpecificsAddEdit;
