import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../../commons";
import { PaginatedEnsueCollection } from "../../../../../../../ensue-react-system/utilities/paginated-ensue-collection";
import { ManageFootballMatchPlayersStatistics } from "../../../../../../../commons/models/football/admin/manage-football-match-players-statistics";

export interface IManageSeasonMatchPlayersStatListState {
  seasonMatchPlayersStatisticList: PaginatedEnsueCollection<ManageFootballMatchPlayersStatistics>;
  seasonMatchPlayersStatisticListStatus: StatusEnum;
}

export interface IManageSeasonMatchPlayersStatisticListOption {
  seasonId: number;
  matchId: number;
  urlParams: {
    key: string;
    page?: number;
    perPage?: number;
    sort?: string;
    sortOrder?: string;
  };
}

const initialState: IManageSeasonMatchPlayersStatListState = {
  seasonMatchPlayersStatisticList: new PaginatedEnsueCollection<ManageFootballMatchPlayersStatistics>(),
  seasonMatchPlayersStatisticListStatus: StatusEnum.Idle,
};

export const fetchSeasonMatchPlayersStatisticList = createAsyncThunk(
  "manage/seasonMatchPlayersStatisticList",
  async (options: IManageSeasonMatchPlayersStatisticListOption, thunkAPI) => {
    try {
      const seasonMatchPlayersStatisticList = await manageFootballSeasonService.getSeasonMatchPlayerStatistics(
        options.seasonId,
        options.matchId,
        options.urlParams
      );
      return seasonMatchPlayersStatisticList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageSeasonMatchPlayersStatisticListSlice = createSlice({
  name: "manageSeasonMatchPlayersStatisticList",
  initialState: initialState,
  reducers: {
    cleanUpSeasonMatchPlayersStatisticList(state) {
      state.seasonMatchPlayersStatisticList = new PaginatedEnsueCollection<ManageFootballMatchPlayersStatistics>();
      state.seasonMatchPlayersStatisticListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSeasonMatchPlayersStatisticList.pending, (state) => {
        state.seasonMatchPlayersStatisticListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchSeasonMatchPlayersStatisticList.fulfilled,
        (state, action: PayloadAction<PaginatedEnsueCollection<ManageFootballMatchPlayersStatistics>>) => {
          state.seasonMatchPlayersStatisticListStatus = StatusEnum.Succeeded;
          state.seasonMatchPlayersStatisticList = action.payload;
        }
      )
      .addCase(fetchSeasonMatchPlayersStatisticList.rejected, (state) => {
        state.seasonMatchPlayersStatisticListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpSeasonMatchPlayersStatisticList } = manageSeasonMatchPlayersStatisticListSlice.actions;
export default manageSeasonMatchPlayersStatisticListSlice.reducer;
