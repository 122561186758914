import { useState } from "react";
import { AppBox, AppModal, AppSkeletonGlobalCardList, AppTitle } from "../../../../../commons/components";
import IconBin from "../../../../../commons/components/icons/bin";
import IconEdit from "../../../../../commons/components/icons/edit";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import { useSnackbar } from "../../../../../redux/snackbarProvider";
import { seasonMatchCommentaryDelete } from "../../../../../redux/slices/football/admin/season/match/commentary/manage-season-match-commentary-delete-slice";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../../commons/components/interface";
import MatchCommentaryDelete from "./MatchCommentaryDelete";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import MatchCommentaryDeleteInprogress from "./MatchCommentaryDeleteInprogress";
import { fetchSeasonMatchCommentaryList } from "../../../../../redux/slices/football/admin/season/match/commentary/manage-season-match-commentary-list-slice";
import MatchCommentaryEdit from "./MatchCommentaryEdit";
import { MatchEventTypeEnum } from "../../../../../commons/enums/match-event-type-enum";
import { fetchSeasonMatchDetail } from "../../../../../redux/slices/football/admin/season/match/manage-season-match-detail-slice";
import { MatchCommentaryMetaDataTypeEnum } from "../../../../../commons/enums/match-commentary-metadata-type-enum";
import {
  IFootballCommentary,
  IMatchCommentaryMetadata,
} from "../../../../../commons/models/football/interface/i-football-commentary";
import MatchManageCommentaryCard from "./MatchManageCommentaryCard";

const MatchCommentaryList = () => {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const { seasonMatchDetail } = useAppSelector((state) => state.footballManageSeasonMatchDetail);
  const { seasonMatchCommentaryList, seasonMatchCommentaryListStatus } = useAppSelector(
    (state) => state.footballManageSeasonMatchCommentaryList
  );
  const { seasonMatchCommentaryDeleteStatus } = useAppSelector(
    (state) => state.footballManageSeasonMatchCommentaryDelete
  );
  const [isEditMatchCommentary, setEditMatchCommentary] = useState(false);
  const [isDeleteMatchCommentary, setDeleteMatchCommentary] = useState(false);
  const [selectedCommentary, setSelectedCommentary] = useState<IFootballCommentary | null>(null);

  const handleDeleteConfirm = (e: Event) => {
    e.preventDefault();
    if (seasonMatchDetail && selectedCommentary) {
      setDeleteMatchCommentary(false);
      dispatch(
        seasonMatchCommentaryDelete({
          seasonId: seasonMatchDetail.season.id,
          matchId: seasonMatchDetail.id,
          commentaryId: selectedCommentary.id,
        })
      )
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "commentary-delete",
            text: "Commentary Deleted Successfully",
            variant: "success",
          });
          if (
            selectedCommentary.eventType === MatchEventTypeEnum.Goal ||
            selectedCommentary.eventType === MatchEventTypeEnum.OwnGoal ||
            (selectedCommentary.eventType === MatchEventTypeEnum.Penalty &&
              (selectedCommentary.metadata as IMatchCommentaryMetadata)["type"] ===
                MatchCommentaryMetaDataTypeEnum.Scored)
          ) {
            dispatch(fetchSeasonMatchDetail({ matchId: seasonMatchDetail.id, seasonId: seasonMatchDetail.season.id }));
          } else {
            dispatch(
              fetchSeasonMatchCommentaryList({
                matchId: seasonMatchDetail.id,
                seasonId: seasonMatchDetail.season.id,
              })
            );
          }
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          addSnackbar({
            key: "error",
            text: error?.response?.data.message,
            variant: "danger",
          });
        });
    }
  };

  const handleDeleteClick = (commentary: IFootballCommentary) => {
    setSelectedCommentary(commentary);
    setDeleteMatchCommentary(true);
  };

  const handleEditClick = (commentary: IFootballCommentary) => {
    setSelectedCommentary(commentary);
    setEditMatchCommentary(true);
  };

  return (
    <>
      <AppBox flexDirection="column" gap="xs" pl="xs">
        {seasonMatchCommentaryListStatus === StatusEnum.Loading ? (
          <AppSkeletonGlobalCardList numberOfItems={5} withText />
        ) : (
          <>
            {seasonMatchCommentaryList.length > 0 && (
              <AppBox flexDirection="column" gap="xs">
                <AppTitle as="h5">Match Commentary</AppTitle>
                {seasonMatchCommentaryList.mapArray((commentary) => (
                  <MatchManageCommentaryCard
                    match={seasonMatchDetail}
                    commentary={commentary}
                    onEdit={(commentary) => {
                      handleEditClick(commentary);
                    }}
                    onDelete={(commentary) => {
                      handleDeleteClick(commentary);
                    }}
                  />
                ))}
              </AppBox>
            )}
          </>
        )}
      </AppBox>
      <AppModal
        opened={isDeleteMatchCommentary}
        onClose={(e) => {
          setDeleteMatchCommentary(false);
        }}
        title="Want To Delete This Commentary?"
        titleIcon={<IconBin />}
        iconColor="danger"
        confirmButtonLabel="Delete"
        onSave={(e) => {
          handleDeleteConfirm(e as Event);
        }}
      >
        <MatchCommentaryDelete />
      </AppModal>

      <AppModal
        opened={seasonMatchCommentaryDeleteStatus === StatusEnum.Loading}
        title="Deleting Commentary"
        titleIcon={<IconBin />}
        iconColor="danger"
        withoutCancelButton
        withoutButtons
        withoutCloseButton
      >
        <MatchCommentaryDeleteInprogress />
      </AppModal>
      {selectedCommentary && (
        <AppModal
          opened={isEditMatchCommentary}
          onClose={(e) => {
            setEditMatchCommentary(false);
          }}
          title="Edit Commentary"
          titleIcon={<IconEdit />}
          withoutButtons
        >
          <MatchCommentaryEdit
            match={seasonMatchDetail}
            commentaryData={selectedCommentary}
            onEdit={() => setEditMatchCommentary(false)}
          />
        </AppModal>
      )}
    </>
  );
};

export default MatchCommentaryList;
