import { MouseEventHandler, ReactNode } from "react";
import { Colors, Sizes } from "../types/core-types";
import { loadavg } from "os";

interface ButtonProps {
  as?: "button" | "a";
  type?: "button" | "submit" | "reset";
  color?: Colors;
  disabled?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  label: string;
  leftSection?: ReactNode;
  radius?: Sizes;
  rightSection?: ReactNode;
  size?: Sizes;
  variant?: "filled" | "outline" | "light" | "subtle";
  borderLight?: boolean;
  onClick?: (e?: any) => void;
}

export default function AppButton({
  as = "button",
  type = "button",
  color = "primary",
  disabled,
  label,
  leftSection,
  loading,
  radius = "sm",
  rightSection,
  size = "md",
  variant = "filled",
  borderLight = false,
  fullWidth,
  onClick,
}: ButtonProps) {
  const classNames = [
    `btn`,
    variant && `btn-${variant}`,
    color && `color-${color}`,
    size && `size-${size}`,
    radius && `radius-${radius}`,
    borderLight && `border-light`,
    fullWidth && `w-100`,
  ]
    .filter(Boolean)
    .join(" ");

  const handleClick = (event: any) => {
    if (loading) {
      event.preventDefault();
    } else {
      if (onClick) {
        onClick(event);
      }
    }
  };

  return (
    <button
      type={type}
      onClick={(e) => {
        handleClick(e);
      }}
      className={classNames}
      data-variant={variant}
      data-disabled={disabled}
      data-loading={loading}
      disabled={disabled}
      aria-label={label}
      aria-disabled={disabled}
    >
      {loading && <span className={`btn-loading-spinner-${color}`}></span>}
      {leftSection && leftSection}
      <span>{label}</span>
      {rightSection && rightSection}
    </button>
  );
}
