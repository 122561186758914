import { ChangeEventHandler, CSSProperties, useEffect, useState } from "react";
import AppBox from "./Box";
import AppText from "./Text";
import AppGridBox from "./GridBox";

interface InputProps {
  as?: "input" | "textarea";
  id: string;
  label?: string;
  type?: string;
  placeholder?: string;
  className?: string;
  style?: React.CSSProperties;
  value?: string;
  defaultValue?: string;
  withoutLabel?: boolean;
  disabled?: boolean;
  minValue?: number;
  maxValue?: number;
  flex?: "column" | "row";
  labelAlign?: CSSProperties["alignItems"];
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export default function AppInput({
  as = "input",
  className,
  style,
  id,
  label,
  placeholder,
  type = "text",
  value,
  defaultValue,
  withoutLabel,
  disabled,
  maxValue,
  minValue,
  flex = "column",
  labelAlign = "center",
  onChange,
}: InputProps) {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <AppBox flexDirection="column" gap="2xs" className="w-100">
      <AppGridBox className="w-100 flex-1" style={flex === "row" ? { gridTemplateColumns: "1fr 2fr" } : {}} gap="sm">
        {withoutLabel ?? (
          <AppBox alignItems={labelAlign}>
            <AppText as="label" size="lg">
              <label htmlFor={id}>{label}</label>
            </AppText>
          </AppBox>
        )}
        {as === "input" ? (
          <input
            id={id}
            type={type}
            className={`ee-input w-100 ${className ? className : ""}`}
            style={style}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            defaultValue={defaultValue}
            disabled={disabled}
            max={maxValue}
            min={minValue}
            onKeyDown={(e) => handleKeyDown(e)}
          />
        ) : (
          <textarea
            id={id}
            className={`ee-input w-100 ${className ? className : ""}`}
            style={style}
            placeholder={placeholder}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            disabled={disabled}
          />
        )}
      </AppGridBox>
    </AppBox>
  );
}
