import { Controller, useForm } from "react-hook-form";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppDatePicker,
  AppIconButton,
  AppModal,
  AppScorePill,
  AppSelect,
  AppText,
  AppTimePicker,
} from "../../../../commons/components";
import { findInputError, isFormInvalid } from "../../../../commons/utilities/form-utils";
import moment from "moment-timezone";
import { ISelectOptions } from "../../../../commons/components/ui-components/Select";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { formatDate, formatDateTime } from "../../../../commons/utilities/date-utils";
import { useEffect, useLayoutEffect, useState } from "react";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { fetchManageRefereeList } from "../../../../redux/slices/football/admin/referee/manage-referee-list-slice";
import { fetchManageStadiumList } from "../../../../redux/slices/stadium/manage-stadium-list-slice";
import { IFootballTeam } from "../../../../commons/models/football/interface/i-football-team";
import { IFootballMatch, ITeamOutcome } from "../../../../commons/models/football/interface/i-football-match";
import { RefereeTypeEnum } from "../../../../commons/enums/referee-type-enum";
import { seasonFixtureGroupFixtureAdd } from "../../../../redux/slices/football/admin/season/fixture-group/manage-fixture-group-fixture-add-slice";
import { IFootballSeason } from "../../../../commons/models/football/interface/i-football-season";
import { IFootballSeasonGroup } from "../../../../commons/models/football/admin/interface/i-manage-football-season-group";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import IconAdd from "../../../../commons/components/icons/add";
import { MatchTeamTypeEnum } from "../../../../commons/enums/match-team-type-enum";
import { ManageFootballTeam } from "../../../../commons/models/football/admin/manage-football-team";
import IconAddCircle from "../../../../commons/components/icons/add-circle";
import { findIndexByCondition } from "../../../../commons/utilities/array-utils";
import isNullOrUndefined from "../../../../commons/utilities/null-or-undefined";
import { seasonFixtureGroupFixtureEdit } from "../../../../redux/slices/football/admin/season/fixture-group/manage-fixture-group-fixture-edit-slice";
import { TournamentTypeEnum } from "../../../../commons/enums/tournament-type-enum";
import IconStar from "../../../../commons/components/icons/star";
import { IFootballMatchDTO } from "../../../../api-services/football/admin/manage-football-season-service";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";
import { GameDurationEnum } from "../../../../commons/enums/game-duration-enum";
import { TournamentFormEnum } from "../../../../commons/enums/tournament-form-enum";
import GroupedTeamSearch from "./GroupedTeamSearch";
import IconBin from "../../../../commons/components/icons/bin";
import { seasonKnockoutMatchEdit } from "../../../../redux/slices/football/admin/season/knockout/manage-knockout-match-edit-slice";
import { getTeamOutcomeLabel } from "../../../../commons/utilities/match-or-matchCard-utils";
import FixtureGroupOrTeamSearch from "./FixtureGroupOrTeamSearch";
import { toSentenceCase, toTitleCase } from "../../../../commons/utilities/string-utils";
import { StatusEnum } from "../../../../commons/enums/status-enum";

interface FixtureAddEditProps {
  competition: IFootballCompetition;
  season: IFootballSeason;
  fixtureGroup?: IFootballSeasonGroup;
  fixtureData: IFootballMatch | null;
  isBracketFixture?: boolean;
  isBracketFirstRound?: boolean;
  pastRoundMatches?: IFootballMatch[];
  onCreate?: () => void;
  onCancel?: () => void;
}

export default function FixtureAddEdit({
  fixtureGroup,
  season,
  competition,
  fixtureData,
  isBracketFixture,
  isBracketFirstRound,
  pastRoundMatches,
  onCreate,
  onCancel,
}: FixtureAddEditProps) {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const minDate = moment().toDate();
  const maxDate = moment(season.endAt).toDate();
  const { stadiumList, stadiumListStatus } = useAppSelector((state) => state.manageStadiumList);
  const { refereeList, refereeListStatus } = useAppSelector((state) => state.footballManageRefereeList);
  const { seasonFixtureGroupFixtureAddResponseStatus } = useAppSelector(
    (state) => state.footballManageSeasonFixtureGroupFixtureAdd
  );
  const { seasonFixtureGroupFixtureEditResponseStatus } = useAppSelector(
    (state) => state.footballManageSeasonFixtureGroupFixtureEdit
  );
  const { seasonKnockoutMatchEditResponseStatus } = useAppSelector(
    (state) => state.footballManageSeasonKnockoutMatchEdit
  );
  const methods = useForm();
  const formErrors = methods.formState.errors;
  const [matchDate, setMatchDate] = useState<Date>(moment().toDate());
  const [matchTime, setMatchTime] = useState(moment().format("HH:mm:ss"));
  const [matchTimezone, setMatchTimezone] = useState(moment.tz.guess());
  const [teamType, setTeamType] = useState<MatchTeamTypeEnum>();
  const [selectedHomeTeam, setSelectedHomeTeam] = useState<IFootballTeam | null>(null);
  const [selectedAwayTeam, setSelectedAwayTeam] = useState<IFootballTeam | null>(null);
  const [selectedHomeTeamOutcome, setSelectedHomeTeamOutcome] = useState<ITeamOutcome | null>(null);
  const [selectedAwayTeamOutcome, setSelectedAwayTeamOutcome] = useState<ITeamOutcome | null>(null);
  const [isTeamSelect, setTeamSelect] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<ManageFootballTeam[]>();
  const [venueSelectOptions, setVenueSelectOptions] = useState<ISelectOptions[]>([
    { title: "Select a venue", value: 0 },
  ]);
  const [refereeSelectOptions, setRefereeSelectOptions] = useState<ISelectOptions[]>([
    { title: "Select main referee", value: 0 },
  ]);
  const [venueIndex, setVenueIndex] = useState(0);
  const [durationIndex, setDurationIndex] = useState(0);
  const [refereeIndex, setRefereeIndex] = useState(0);

  const durationSelectOptions: ISelectOptions[] = [
    { title: "90 Minutes", value: GameDurationEnum.Minutes90 },
    { title: "70 Minutes", value: GameDurationEnum.Minutes70 },
    { title: "40 Minutes", value: GameDurationEnum.Minutes40 },
    { title: "20 Minutes", value: GameDurationEnum.Minutes20 },
  ];

  useLayoutEffect(() => {
    dispatch(fetchManageRefereeList({ sort: "createdAt", sortOrder: "DESC" }));
    dispatch(fetchManageStadiumList({ sort: "createdAt", sortOrder: "DESC" }));
  }, []);

  useEffect(() => {
    if (competition.id) {
      switch (competition.teamForm) {
        case TournamentFormEnum.Form7:
          let duration40Index = findIndexByCondition(durationSelectOptions, (option) => option.value === 40);
          if (duration40Index >= 0) {
            setDurationIndex(duration40Index);
            methods.setValue("gameplayDuration", durationSelectOptions[duration40Index].value);
          }
          break;
        case TournamentFormEnum.Form11:
        default:
          let duration60Index = findIndexByCondition(durationSelectOptions, (option) => option.value === 90);
          if (duration60Index >= 0) {
            setDurationIndex(duration60Index);
            methods.setValue("gameplayDuration", durationSelectOptions[duration60Index].value);
          }
          break;
      }
    }
  }, [competition]);

  useLayoutEffect(() => {
    const refereeOptions: ISelectOptions[] = [{ title: "Select main referee", value: 0 }];
    if (refereeList.length > 0) {
      refereeList.forEach((referee) => {
        refereeOptions.push({
          title: toTitleCase(referee.fullName),
          value: referee.id,
        });
      });
      setRefereeSelectOptions(refereeOptions);
    }
  }, [refereeList]);

  useLayoutEffect(() => {
    const venueOptions: ISelectOptions[] = [{ title: "Select a venue", value: 0 }];
    if (stadiumList.length > 0) {
      stadiumList.forEach((venue) => {
        venueOptions.push({
          title: venue.name,
          value: venue.id,
        });
      });
      setVenueSelectOptions(venueOptions);
    }
  }, [stadiumList]);

  useEffect(() => {
    if (fixtureData) {
      if (fixtureData.scheduledAt) {
        setMatchDate(moment(fixtureData.scheduledAt).toDate());
      }
      if (fixtureData.homeTeam) {
        setSelectedHomeTeam(fixtureData.homeTeam);
      } else {
        if (fixtureData.homeTeamOutcome) {
          setSelectedHomeTeamOutcome(fixtureData.homeTeamOutcome);
        }
      }
      if (fixtureData.awayTeam) {
        setSelectedAwayTeam(fixtureData.awayTeam);
      } else {
        if (fixtureData.awayTeamOutcome) {
          setSelectedAwayTeamOutcome(fixtureData.awayTeamOutcome);
        }
      }
      let durationIndex = findIndexByCondition(
        durationSelectOptions,
        (option) => option.value === Math.floor(fixtureData.gameplayDuration / 60)
      );
      if (durationIndex >= 0) {
        setDurationIndex(durationIndex);
        methods.setValue("gameplayDuration", durationSelectOptions[durationIndex].value);
      }
      if (venueSelectOptions.length > 0 && refereeSelectOptions.length > 0) {
        let venueIndex = findIndexByCondition(venueSelectOptions, (option) => option.value === fixtureData.stadiumId);

        if (venueIndex >= 0) {
          setVenueIndex(venueIndex);
          methods.setValue("stadiumId", venueSelectOptions[venueIndex].value);
        }
        let refereeIndex = findIndexByCondition(
          refereeSelectOptions,
          (option) => option.value === fixtureData.matchReferees[0]?.referee.id
        );
        if (refereeIndex >= 0) {
          setRefereeIndex(refereeIndex);
          methods.setValue("referees", refereeSelectOptions[refereeIndex].value);
        }
      }
    }
  }, [refereeSelectOptions, venueSelectOptions, fixtureData]);

  const handleDateTime = (date: Date, time: string, timezone: string): Promise<string> => {
    return new Promise((resolve) => {
      const utcDateTime = moment
        .tz(formatDate(date) + " " + time, timezone)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      resolve(utcDateTime);
    });
  };

  const handleSelectedTeams = (teams: IFootballTeam[]) => {
    if (teamType === MatchTeamTypeEnum.Home) {
      setSelectedHomeTeam(teams[0]);
      setSelectedHomeTeamOutcome(null);
    } else {
      setSelectedAwayTeam(teams[0]);
      setSelectedAwayTeamOutcome(null);
    }
    setTeamSelect(false);
  };

  const handleSelectedTeamOutcome = (teamOutcome: ITeamOutcome) => {
    if (teamType === MatchTeamTypeEnum.Home) {
      setSelectedHomeTeamOutcome(teamOutcome);
      setSelectedHomeTeam(null);
    } else {
      setSelectedAwayTeamOutcome(teamOutcome);
      setSelectedAwayTeam(null);
    }
    setTeamSelect(false);
  };

  const handleFormSubmit = (values: IFootballMatchDTO) => {
    const formattedValues: IFootballMatchDTO = {
      homeTeamId: selectedHomeTeam ? selectedHomeTeam.id : null,
      awayTeamId: selectedAwayTeam ? selectedAwayTeam.id : null,
      stadiumId: values.stadiumId ? Number(values.stadiumId) : null,
      scheduledAt: values.scheduledAt,
      referees: values?.referees ? [{ id: Number(values.referees), role: RefereeTypeEnum.Head }] : [],
      gameplayDuration: Number(values.gameplayDuration) * 60,
      awayTeamOutcome: selectedAwayTeamOutcome
        ? {
            modelId: selectedAwayTeamOutcome.modelId,
            modelType: selectedAwayTeamOutcome.modelType,
            position: selectedAwayTeamOutcome.position,
          }
        : null,
      homeTeamOutcome: selectedHomeTeamOutcome
        ? {
            modelId: selectedHomeTeamOutcome.modelId,
            modelType: selectedHomeTeamOutcome.modelType,
            position: selectedHomeTeamOutcome.position,
          }
        : null,
    };

    if (fixtureGroup) {
      if (fixtureData && fixtureData.id) {
        dispatch(
          seasonFixtureGroupFixtureEdit({
            fixtureGroupId: fixtureGroup.id,
            seasonId: season.id,
            fixtureId: fixtureData.id,
            fixtureData: formattedValues,
          })
        )
          .unwrap()
          .then(() => {
            handleSuccess();
          })
          .catch(handleError);
      } else {
        dispatch(
          seasonFixtureGroupFixtureAdd({
            fixtureGroupId: fixtureGroup.id,
            seasonId: season.id,
            seasonFixtureGroupFixtures: formattedValues,
          })
        )
          .unwrap()
          .then(() => {
            handleSuccess();
          })
          .catch(handleError);
      }
    } else {
      if (fixtureData && fixtureData.id) {
        dispatch(
          seasonKnockoutMatchEdit({
            seasonId: season.id,
            matchId: fixtureData.id,
            seasonKnockoutMatchInfo: formattedValues,
          })
        )
          .unwrap()
          .then(() => {
            handleSuccess();
          })
          .catch(handleError);
      }
    }
  };

  const handleSuccess = () => {
    addSnackbar({
      key: "add/edit",
      text: fixtureData
        ? fixtureData?.homeTeam || fixtureData?.awayTeam || fixtureData?.homeTeamOutcome || fixtureData?.awayTeamOutcome
          ? "Match Edited Successfully"
          : "Match Scheduled Successfully"
        : "Match Created Successfully",
      variant: "success",
    });
    if (onCreate) {
      onCreate();
    }
  };

  const handleError = (error: AxiosError<IServerErrorResponse>) => {
    const responseData = error.response?.data;
    if (error.response?.status === 417) {
      if (responseData) {
        addSnackbar({
          key: "error",
          text: "Form not valid",
          variant: "danger",
        });
        Object.entries(responseData).forEach(([field, messages]) => {
          messages.forEach((message: string) => {
            methods.setError(field, { message });
          });
        });
      }
    } else {
      addSnackbar({
        key: "error",
        text: responseData?.message,
        variant: "danger",
      });
    }
  };

  return (
    <>
      <form
        noValidate
        onSubmit={methods.handleSubmit((e) => {
          methods.formState.isValid && handleFormSubmit(e as IFootballMatchDTO);
        })}
      >
        <AppBox flexDirection="column" gap="md">
          <AppBox flexDirection="column" gap="sm" pr="xs" style={{ overflowY: "scroll", maxHeight: "30rem" }}>
            <AppBox alignItems="center" justifyContent="space-between" gap="xs">
              <AppBox className="team-select-container flex-1" justifyContent="center" alignItems="center">
                <AppBox flexDirection="column" gap="2xs" justifyContent="center" alignItems="center">
                  {selectedHomeTeam || selectedHomeTeamOutcome ? (
                    <>
                      {selectedHomeTeam && (
                        <AppBox
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                          gap="2xs"
                          className="flex-1"
                        >
                          <AppAvatar
                            username={selectedHomeTeam.name || ""}
                            size="lg"
                            src={selectedHomeTeam.logo || ""}
                          />
                          <AppBox flexDirection="column" alignItems="center" justifyContent="center">
                            <AppText>{selectedHomeTeam.shortName}</AppText>
                          </AppBox>
                        </AppBox>
                      )}
                      {selectedHomeTeamOutcome && (
                        <AppBox
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                          gap="2xs"
                          className="flex-1"
                        >
                          <AppBox className="team-add-svg-big" alignItems="center" justifyContent="center">
                            <IconStar />
                          </AppBox>
                          <AppBox flexDirection="column" alignItems="center" justifyContent="center">
                            <AppText>{getTeamOutcomeLabel(selectedHomeTeamOutcome)}</AppText>
                          </AppBox>
                        </AppBox>
                      )}
                    </>
                  ) : (
                    <AppBox className="team-add-svg-big" alignItems="center" justifyContent="center">
                      <IconAdd />
                    </AppBox>
                  )}
                  <AppBox gap="xs">
                    <AppButton
                      label={selectedHomeTeam || selectedHomeTeamOutcome ? "Change" : "Select Team"}
                      variant="light"
                      onClick={(e) => {
                        e.preventDefault();
                        setTeamType(MatchTeamTypeEnum.Home);
                        selectedAwayTeam
                          ? setSelectedTeam([selectedAwayTeam as ManageFootballTeam])
                          : setSelectedTeam([]);
                        setTeamSelect(true);
                      }}
                    />
                    {(selectedHomeTeam || selectedHomeTeamOutcome) && (
                      <AppIconButton
                        icon={<IconBin />}
                        variant="light"
                        color="danger"
                        onClick={() => {
                          setSelectedHomeTeam(null);
                          setSelectedHomeTeamOutcome(null);
                        }}
                      />
                    )}
                  </AppBox>
                </AppBox>
              </AppBox>
              <AppScorePill matchStatus={"upcoming"} />
              <AppBox className="team-select-container flex-1" justifyContent="center" alignItems="center">
                <AppBox flexDirection="column" gap="2xs" justifyContent="center" alignItems="center">
                  {selectedAwayTeam || selectedAwayTeamOutcome ? (
                    <>
                      {selectedAwayTeam && (
                        <AppBox
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                          gap="2xs"
                          className="flex-1"
                        >
                          <AppAvatar
                            username={selectedAwayTeam.name || ""}
                            size="lg"
                            src={selectedAwayTeam.logo || ""}
                          />
                          <AppBox flexDirection="column" alignItems="center" justifyContent="center">
                            <AppText>{selectedAwayTeam.shortName}</AppText>
                          </AppBox>
                        </AppBox>
                      )}
                      {selectedAwayTeamOutcome && (
                        <AppBox
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                          gap="2xs"
                          className="flex-1"
                        >
                          <AppBox className="team-add-svg-big" alignItems="center" justifyContent="center">
                            <IconStar />
                          </AppBox>
                          <AppBox flexDirection="column" alignItems="center" justifyContent="center">
                            <AppText>{getTeamOutcomeLabel(selectedAwayTeamOutcome)}</AppText>
                          </AppBox>
                        </AppBox>
                      )}
                    </>
                  ) : (
                    <AppBox className="team-add-svg-big" alignItems="center" justifyContent="center">
                      <IconAdd />
                    </AppBox>
                  )}
                  <AppBox gap="xs">
                    <AppButton
                      label={selectedAwayTeam || selectedAwayTeamOutcome ? "Change" : "Select Team"}
                      variant="light"
                      onClick={(e) => {
                        e.preventDefault();
                        setTeamType(MatchTeamTypeEnum.Away);
                        selectedHomeTeam
                          ? setSelectedTeam([selectedHomeTeam as ManageFootballTeam])
                          : setSelectedTeam([]);
                        setTeamSelect(true);
                      }}
                    />
                    {(selectedAwayTeam || selectedAwayTeamOutcome) && (
                      <AppIconButton
                        icon={<IconBin />}
                        variant="light"
                        color="danger"
                        onClick={() => {
                          setSelectedAwayTeam(null);
                          setSelectedAwayTeamOutcome(null);
                        }}
                      />
                    )}
                  </AppBox>
                </AppBox>
              </AppBox>
            </AppBox>
            <Controller
              name="gameplayDuration"
              control={methods.control}
              defaultValue={durationSelectOptions[durationIndex].value}
              render={({ field: { onChange } }) => (
                <AppBox flexDirection="column" gap="2xs">
                  <AppText as="label" size="lg">
                    Match Duration
                  </AppText>
                  <AppSelect
                    options={durationSelectOptions}
                    onChange={(option) => onChange(option.value)}
                    currentOption={durationSelectOptions[durationIndex]}
                  />
                  {isFormInvalid(findInputError(formErrors, "gameplayDuration")) && (
                    <AppText as="span" color="danger">
                      <>
                        {toSentenceCase(
                          `${formErrors.gameplayDuration?.message && formErrors.gameplayDuration?.message}`
                        )}
                      </>
                    </AppText>
                  )}
                </AppBox>
              )}
            />
            <Controller
              name="scheduledAt"
              defaultValue={
                fixtureData
                  ? formatDateTime(fixtureData.scheduledAt)
                  : handleDateTime(matchDate, matchTime, matchTimezone)
              }
              control={methods.control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <AppBox flexDirection="column" gap="sm">
                  <AppBox flexDirection="column" gap="2xs">
                    <AppText as="label" size="lg">
                      Match Date
                    </AppText>
                    <AppDatePicker
                      onChange={(date) => {
                        setMatchDate(date as Date);
                        handleDateTime(date as Date, matchTime, matchTimezone).then((utcDateTime) => {
                          onChange(utcDateTime);
                        });
                      }}
                      selected={matchDate}
                      calenderPlacement="bottom-end"
                      minDate={minDate}
                      maxDate={maxDate}
                    />
                  </AppBox>
                  <AppBox flexDirection="column" gap="2xs">
                    <AppText as="label" size="lg">
                      Match Time (ground time)
                    </AppText>
                    <AppTimePicker
                      givenTime={fixtureData?.scheduledAt}
                      onChange={(time) => {
                        setMatchTime(time);
                        handleDateTime(matchDate, time, matchTimezone).then((utcDateTime) => {
                          onChange(utcDateTime);
                        });
                      }}
                    />
                  </AppBox>
                </AppBox>
              )}
            />
            {venueSelectOptions.length > 0 && (
              <Controller
                name="stadiumId"
                control={methods.control}
                defaultValue={venueSelectOptions[venueIndex].value}
                render={({ field: { onChange } }) => (
                  <AppBox flexDirection="column" gap="2xs">
                    <AppText as="label" size="lg">
                      Venue
                    </AppText>
                    {venueSelectOptions.length > 0 && (
                      <AppSelect
                        options={venueSelectOptions}
                        onChange={(option) => onChange(option.value)}
                        currentOption={venueSelectOptions[venueIndex]}
                      />
                    )}
                    {isFormInvalid(findInputError(formErrors, "stadiumId")) && (
                      <AppText as="span" color="danger">
                        <>{toSentenceCase(`${formErrors.stadiumId?.message && formErrors.stadiumId?.message}`)}</>
                      </AppText>
                    )}
                  </AppBox>
                )}
              />
            )}
            {refereeSelectOptions.length > 0 && (
              <Controller
                name="referees"
                control={methods.control}
                defaultValue={
                  isNullOrUndefined(fixtureData)
                    ? refereeSelectOptions[0].value
                    : refereeSelectOptions[refereeIndex].value
                }
                render={({ field: { onChange } }) => (
                  <AppBox flexDirection="column" gap="2xs">
                    <AppText as="label" size="lg">
                      Main Referee
                    </AppText>
                    {refereeSelectOptions.length > 0 && (
                      <AppSelect
                        options={refereeSelectOptions}
                        onChange={(option) => onChange(option.value)}
                        currentOption={refereeSelectOptions[refereeIndex]}
                      />
                    )}
                    {isFormInvalid(findInputError(formErrors, "referees")) && (
                      <AppText as="span" color="danger">
                        <>{toSentenceCase(`${formErrors.referees?.message && formErrors.referees?.message}`)}</>
                      </AppText>
                    )}
                  </AppBox>
                )}
              />
            )}
          </AppBox>
          <AppBox justifyContent="end" gap="xs">
            <AppButton
              variant="outline"
              borderLight
              label="Cancel"
              color="gray"
              onClick={(e) => {
                e.preventDefault();
                if (onCancel) {
                  onCancel();
                }
              }}
              disabled={
                seasonFixtureGroupFixtureAddResponseStatus === StatusEnum.Loading ||
                seasonFixtureGroupFixtureEditResponseStatus === StatusEnum.Loading ||
                seasonKnockoutMatchEditResponseStatus === StatusEnum.Loading
              }
            />
            <AppButton
              type="submit"
              disabled={!methods.formState.isValid}
              label={
                fixtureData
                  ? fixtureData?.homeTeam ||
                    fixtureData?.awayTeam ||
                    fixtureData?.homeTeamOutcome ||
                    fixtureData?.awayTeamOutcome
                    ? "Save Changes"
                    : "Schedule Match"
                  : "Create Match"
              }
              loading={
                seasonFixtureGroupFixtureAddResponseStatus === StatusEnum.Loading ||
                seasonFixtureGroupFixtureEditResponseStatus === StatusEnum.Loading ||
                seasonKnockoutMatchEditResponseStatus === StatusEnum.Loading
              }
            />
          </AppBox>
        </AppBox>

        <AppModal
          opened={isTeamSelect}
          onClose={() => setTeamSelect(false)}
          titleIcon={<IconAddCircle />}
          title="Select A Team"
          withoutButtons
        >
          <>
            {competition.type === TournamentTypeEnum.Hybrid && !isBracketFixture ? (
              <GroupedTeamSearch
                seasonId={season.id}
                userSelectedTeams={selectedTeam ?? []}
                onSelectedTeam={handleSelectedTeams}
              />
            ) : (
              <FixtureGroupOrTeamSearch
                competition={competition}
                season={season}
                withTeamOrWinnerLoserSelection={!isBracketFirstRound}
                withGroupPositionSelection={competition.type === TournamentTypeEnum.Hybrid && isBracketFirstRound}
                onSelectedTeam={handleSelectedTeams}
                onSelectedTeamOutcome={handleSelectedTeamOutcome}
                pastRoundMatches={pastRoundMatches}
                userSelectedTeams={selectedTeam}
              />
            )}
          </>
        </AppModal>
      </form>
    </>
  );
}
