import { useEffect, useState } from "react";
import { PlayerPositionEnum } from "../../../../commons/enums/match-player-position-enum";
import { PlayerRoleType } from "../../../../commons/enums/match-player-role-type";
import { AppBox, AppGridBox, AppText, AppSelect } from "../../../../commons/components";
import IconCheck from "../../../../commons/components/icons/check";

interface PlayerRoleSelectorProps {
  playerPosition: string;
  onSelectedRoles: (roles: string) => void;
  currentRoles?: string;
  asDropDown?: boolean;
}

interface IPlayingRole {
  title: string;
  value: PlayerRoleType;
}

export const PlayerRoleSelector = ({
  playerPosition,
  currentRoles,
  asDropDown,
  onSelectedRoles,
}: PlayerRoleSelectorProps) => {
  const [playingRoles, setPlayingRoles] = useState<IPlayingRole[]>([]);
  const [checkedValues, setCheckedValues] = useState<PlayerRoleType[]>([]);

  useEffect(() => {
    let initialSelectedRoles: PlayerRoleType | null = null;
    switch (playerPosition) {
      case PlayerPositionEnum.Goalkeeper:
        initialSelectedRoles = "GK";
        setPlayingRoles([{ title: "Goalkeeper", value: "GK" }]);
        break;
      case PlayerPositionEnum.Defender:
        initialSelectedRoles = "CB";
        setPlayingRoles([
          { title: "Central Back", value: "CB" },
          { title: "Left Central Back", value: "LCB" },
          { title: "Right Central Back", value: "RCB" },
          { title: "Left Back", value: "LB" },
          { title: "Right Back", value: "RB" },
          { title: "Left Wing Back", value: "LWB" },
          { title: "Right Wing Back", value: "RWB" },
        ]);
        break;
      case PlayerPositionEnum.Midfielder:
        initialSelectedRoles = "LDM";
        setPlayingRoles([
          { title: "Left Defensive Mid", value: "LDM" },
          { title: "Central Defensive Mid", value: "CDM" },
          { title: "Right Defensive Mid", value: "RDM" },
          { title: "Left Central Mid", value: "LCM" },
          { title: "Central Mid", value: "CM" },
          { title: "Right Central Mid", value: "RCM" },
          { title: "Left Mid", value: "LM" },
          { title: "Right Mid", value: "RM" },
          { title: "Left Attacking Mid", value: "LAM" },
          { title: "Central Attacking Mid", value: "CAM" },
          { title: "Right Attacking Mid", value: "RAM" },
          { title: "Left Wing Forward", value: "LW" },
          { title: "Right Wing Forward", value: "RW" },
        ]);
        break;
      case PlayerPositionEnum.Forward:
        initialSelectedRoles = "LW";
        setPlayingRoles([
          { title: "Left Wing Forward", value: "LW" },
          { title: "Right Wing Forward", value: "RW" },
          { title: "Left Central Forward", value: "LS" },
          { title: "Central Forward", value: "CS" },
          { title: "Right Central Forward", value: "RS" },
        ]);
        break;
    }
    if (initialSelectedRoles) {
      setCheckedValues([initialSelectedRoles]);
    }
  }, [playerPosition]);

  const handleCheckboxChange = (value: PlayerRoleType) => {
    if (checkedValues.includes(value)) {
      setCheckedValues(checkedValues.filter((val) => val !== value));
    } else {
      setCheckedValues([...checkedValues, value]);
    }
  };

  useEffect(() => {
    if (currentRoles) {
      const roles: PlayerRoleType[] = currentRoles.split("|").map((role) => role as PlayerRoleType);
      setCheckedValues(roles);
    }
  }, [currentRoles]);

  useEffect(() => {
    onSelectedRoles(checkedValues.join("|"));
  }, [checkedValues]);

  return (
    <>
      {asDropDown ? (
        <>
          {playingRoles.length > 0 && (
            <AppSelect
              options={playingRoles.map((role) => ({
                title: role.title,
                value: role.value,
              }))}
              withMultiSelect
              currentOption={playingRoles.filter((role) => checkedValues.includes(role.value))}
              onMultiSelect={(selectedOptions) => {
                const selectedValues = selectedOptions.map((option) => option.value as PlayerRoleType);
                setCheckedValues(selectedValues);
              }}
            />
          )}
        </>
      ) : (
        <AppBox
          gap="2xs"
          alignItems="center"
          justifyContent="left"
          flexWrap="wrap"
          className="label-select label-checkbox"
        >
          <AppGridBox columns={2} gap="2xs">
            {playingRoles &&
              playingRoles.map((item, index) => (
                <AppBox alignItems="center" key={index}>
                  <input
                    type="checkbox"
                    name={playerPosition}
                    id={item.value}
                    checked={checkedValues.includes(item.value)}
                    onChange={() => handleCheckboxChange(item.value)}
                  />
                  <label htmlFor={item.value}>
                    <div className="label-check">
                      <IconCheck width={20} height={20} />
                    </div>
                    <div className="label-uncheck" />
                    {item.title}
                  </label>
                </AppBox>
              ))}
          </AppGridBox>
        </AppBox>
      )}
    </>
  );
};
