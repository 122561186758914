import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballTeamService } from "../../../../../commons";

interface ITeamPlayerRemoveState {
  manageTeamPlayerRemoveStatus: StatusEnum;
}

interface IPlayerRemoveInfo {
  endAt: string;
}

const initialState: ITeamPlayerRemoveState = {
  manageTeamPlayerRemoveStatus: StatusEnum.Idle,
};

export interface IManageGroupsRemoveOption {
  teamId: number;
  playerId: number;
  playerInfo: IPlayerRemoveInfo;
}

export const teamPlayerRemove = createAsyncThunk(
  "manage/teamPlayerRemove",
  async (options: IManageGroupsRemoveOption, thunkAPI) => {
    try {
      const teamPlayerRemoveResponse = await manageFootballTeamService.removeTeamPlayer(
        options.teamId,
        options.playerId,
        options.playerInfo.endAt
      );
      return teamPlayerRemoveResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const teamPlayerRemoveSlice = createSlice({
  name: "teamPlayerRemove",
  initialState: initialState,
  reducers: {
    cleanUpTeamPlayerRemove(state) {
      state.manageTeamPlayerRemoveStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(teamPlayerRemove.pending, (state) => {
        state.manageTeamPlayerRemoveStatus = StatusEnum.Loading;
      })
      .addCase(teamPlayerRemove.fulfilled, (state, action) => {
        state.manageTeamPlayerRemoveStatus = StatusEnum.Succeeded;
      })
      .addCase(teamPlayerRemove.rejected, (state) => {
        state.manageTeamPlayerRemoveStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpTeamPlayerRemove } = teamPlayerRemoveSlice.actions;
export default teamPlayerRemoveSlice.reducer;
