import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FootballTeamPlayer } from "../../../../../commons/models/football/football-team-player";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballTeamService } from "../../../../../commons";
import {
  ITeamMultiplePlayerDTO,
  ITeamPlayerDTO,
} from "../../../../../api-services/football/admin/manage-football-team-service";

export interface IManageTeamPlayerAddEditState {
  manageTeamMultiplePlayersAddStatus: StatusEnum;
  manageTeamPlayerAddStatus: StatusEnum;
  manageTeamPlayerEditStatus: StatusEnum;
}

export interface IManageTeamPlayerAddEditOption {
  teamId: number;
  playerId: number;
  playerInfo: ITeamPlayerDTO;
}
export interface IManageTeamMultiplePlayersAddOption {
  teamId: number;
  playersInfo: ITeamMultiplePlayerDTO[];
}

const initialState: IManageTeamPlayerAddEditState = {
  manageTeamMultiplePlayersAddStatus: StatusEnum.Idle,
  manageTeamPlayerAddStatus: StatusEnum.Idle,
  manageTeamPlayerEditStatus: StatusEnum.Idle,
};

export const teamMultiplePlayersAdd = createAsyncThunk(
  "manage/teamMultiplePlayersAdd",
  async (options: IManageTeamMultiplePlayersAddOption, thunkAPI) => {
    try {
      const manageTeamMultiplePlayersAdd = await manageFootballTeamService.addTeamMultiplePlayers(
        options.teamId,
        options.playersInfo
      );
      return manageTeamMultiplePlayersAdd;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const teamPlayerAdd = createAsyncThunk(
  "manage/teamPlayerAdd",
  async (options: IManageTeamPlayerAddEditOption, thunkAPI) => {
    try {
      const manageTeamPlayerAdd = await manageFootballTeamService.setTeamPlayer(
        options.teamId,
        options.playerId,
        options.playerInfo
      );
      return manageTeamPlayerAdd;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const teamPlayerEdit = createAsyncThunk(
  "manage/teamPlayerEdit",
  async (options: IManageTeamPlayerAddEditOption, thunkAPI) => {
    try {
      const manageTeamPlayerEdit = await manageFootballTeamService.editTeamPlayer(
        options.teamId,
        options.playerId,
        options.playerInfo
      );
      return manageTeamPlayerEdit;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageTeamPlayerAddEditSlice = createSlice({
  name: "manageTeamPlayerAddEditSlice",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(teamMultiplePlayersAdd.pending, (state) => {
        state.manageTeamMultiplePlayersAddStatus = StatusEnum.Loading;
      })
      .addCase(teamMultiplePlayersAdd.fulfilled, (state) => {
        state.manageTeamMultiplePlayersAddStatus = StatusEnum.Succeeded;
      })
      .addCase(teamMultiplePlayersAdd.rejected, (state) => {
        state.manageTeamMultiplePlayersAddStatus = StatusEnum.Failed;
      })
      .addCase(teamPlayerAdd.pending, (state) => {
        state.manageTeamPlayerAddStatus = StatusEnum.Loading;
      })
      .addCase(teamPlayerAdd.fulfilled, (state) => {
        state.manageTeamPlayerAddStatus = StatusEnum.Succeeded;
      })
      .addCase(teamPlayerAdd.rejected, (state) => {
        state.manageTeamPlayerAddStatus = StatusEnum.Failed;
      })
      .addCase(teamPlayerEdit.pending, (state) => {
        state.manageTeamPlayerEditStatus = StatusEnum.Loading;
      })
      .addCase(teamPlayerEdit.fulfilled, (state) => {
        state.manageTeamPlayerEditStatus = StatusEnum.Succeeded;
      })
      .addCase(teamPlayerEdit.rejected, (state) => {
        state.manageTeamPlayerEditStatus = StatusEnum.Failed;
      });
  },
});

export default manageTeamPlayerAddEditSlice.reducer;
