import {
  AppGridBox,
  AppSkeletonBasicInfo,
  AppText,
  AppTitle,
  ColorInfoCard,
  InfoCard,
} from "../../../commons/components";
import AppBox from "../../../commons/components/Box";
import IconApproval from "../../../commons/components/icons/approval";
import IconProhibited from "../../../commons/components/icons/prohibited";
import { TeamStatusEnum } from "../../../commons/enums/team-status-enum";
import { formatDate } from "../../../commons/utilities/date-utils";
import { useAppSelector } from "../../../hooks/app";
import { StatusEnum } from "../../../commons/enums/status-enum";

export default function TeamManageProfile() {
  const { teamInfo, teamInfoStatus } = useAppSelector((state) => state.footballManageTeam);

  return (
    <>
      {teamInfoStatus === StatusEnum.Loading ? (
        <AppSkeletonBasicInfo />
      ) : (
        <AppBox mb="sm" gap="sm" flexDirection="column">
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">Basic Information</AppTitle>
            <AppBox flexDirection="column" gap="sm">
              <AppGridBox columns={4} gap="sm">
                <InfoCard label="Team's Short Name" title={teamInfo.shortName} alignItems="start" shadow="xs" />
                <InfoCard
                  label="Team's Abbreviated Name"
                  title={teamInfo.abbreviation}
                  alignItems="start"
                  shadow="xs"
                />
                <InfoCard
                  label="Foundation Date"
                  title={formatDate(teamInfo.founded, "D MMM, YYYY") || "-"}
                  alignItems="start"
                  shadow="xs"
                />
                <InfoCard
                  label="Team's Status"
                  title={teamInfo.status === Number(TeamStatusEnum.Active) ? "Active" : "Terminated"}
                  icon={
                    teamInfo.status === Number(TeamStatusEnum.Active) ? (
                      <IconApproval height={32} width={32} color="var(--fg-outline-success)" />
                    ) : (
                      <IconProhibited height={32} width={32} color="var(--fg-outline-danger)" />
                    )
                  }
                  alignItems="start"
                  shadow="xs"
                />
                {teamInfo.homeColor && (
                  <ColorInfoCard
                    color={teamInfo.homeColor}
                    title="Home"
                    label="Team's Color"
                    alignItems="start"
                    shadow="xs"
                  />
                )}
                {teamInfo.awayColor && (
                  <ColorInfoCard
                    color={teamInfo.awayColor}
                    title="Away"
                    label="Team's Color"
                    alignItems="start"
                    shadow="xs"
                  />
                )}
                {teamInfo.thirdColor && (
                  <ColorInfoCard
                    color={teamInfo.thirdColor}
                    title="Optional"
                    label="Team's Color"
                    alignItems="start"
                    shadow="xs"
                  />
                )}
                {teamInfo.goalkeeperHomeColor && (
                  <ColorInfoCard
                    color={teamInfo.goalkeeperHomeColor}
                    title="Home"
                    label="Goalkeeper's Color"
                    alignItems="start"
                    shadow="xs"
                  />
                )}
                {teamInfo.goalkeeperAwayColor && (
                  <ColorInfoCard
                    color={teamInfo.goalkeeperAwayColor}
                    title="Away"
                    label="Goalkeeper's Color"
                    alignItems="start"
                    shadow="xs"
                  />
                )}
              </AppGridBox>
            </AppBox>
          </AppBox>
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">Description</AppTitle>
            <AppText as="p" fontWeight="medium" size="lg">
              <span
                dangerouslySetInnerHTML={{
                  __html: teamInfo.description || "There is not any description.",
                }}
              ></span>
            </AppText>
          </AppBox>
        </AppBox>
      )}
    </>
  );
}
