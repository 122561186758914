export default function IconCLock() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2C17.523 2 22 6.478 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12C2 6.478 6.477 2 12 2ZM12 3.667C7.405 3.667 3.667 7.405 3.667 12C3.667 16.595 7.405 20.333 12 20.333C16.595 20.333 20.333 16.595 20.333 12C20.333 7.405 16.595 3.667 12 3.667ZM11.25 6C11.4312 6.00001 11.6063 6.06564 11.7429 6.18477C11.8795 6.30389 11.9684 6.46845 11.993 6.648L12 6.75V12H15.25C15.44 12.0001 15.6229 12.0722 15.7618 12.202C15.9006 12.3317 15.9851 12.5093 15.998 12.6989C16.011 12.8885 15.9515 13.0759 15.8316 13.2233C15.7117 13.3707 15.5402 13.4671 15.352 13.493L15.25 13.5H11.25C11.0688 13.5 10.8937 13.4344 10.7571 13.3152C10.6205 13.1961 10.5316 13.0316 10.507 12.852L10.5 12.75V6.75C10.5 6.55109 10.579 6.36032 10.7197 6.21967C10.8603 6.07902 11.0511 6 11.25 6Z"
        fill="currentColor"
      />
    </svg>
  );
}
