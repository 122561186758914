import { useEffect, useState } from "react";
import {
  AppBanner,
  AppButton,
  AppGridBox,
  AppModal,
  AppPaper,
  AppSelect,
  AppSkeletonBasicInfo,
  AppSkeletonPlayerAttribute,
  AppText,
  AppTitle,
  InfoCard,
} from "../../../commons/components";
import AppBorderBox from "../../../commons/components/BorderBox";
import AppBox from "../../../commons/components/Box";
import IconApproval from "../../../commons/components/icons/approval";
import IconPersonProhibited from "../../../commons/components/icons/person-prohibited";
import IconPhotoFilm from "../../../commons/components/icons/photo-film";
import { PlayerStatusEnum } from "../../../commons/enums/player-status-enum";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { formatDate } from "../../../commons/utilities/date-utils";
import { toSentenceCase, toTitleCase } from "../../../commons/utilities/string-utils";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { ISelectOptions } from "../../../commons/components/ui-components/Select";
import moment from "moment";
import PlayerAttribute, {
  dummyIndicators,
  IAttributeData,
  IIndicators,
} from "../../public/player-detail/components/PlayerAttribute";
import { arrayRange } from "../../../commons/utilities/array-utils";
import { fetchManagePlayerAttribute } from "../../../redux/slices/football/admin/player/manage-player-attribute-slice";
import { IFootballPlayerAttribute } from "../../../commons/models/football/interface/i-football-player-attribute";
import IconEdit from "../../../commons/components/icons/edit";
import IconCrown from "../../../commons/components/icons/crown";
import PlayerAttributeAddEdit from "./components/PlayerAttributeAddEdit";
import IconDumbBell from "../../../commons/components/icons/dumb-bell";
import IconPatch from "../../../commons/components/icons/patch";

export default function PlayerManageProfile() {
  const dispatch = useAppDispatch();
  const { playerInfo, playerInfoStatus } = useAppSelector((state) => state.footballManagePlayer);
  const { playerAttribute, playerAttributeStatus } = useAppSelector((state) => state.footballManagePlayerAttribute);
  const [selectOptions, setSelectOptions] = useState<ISelectOptions[]>([]);
  const [selectedAttributeYear, setSelectedAttributeYear] = useState(moment().year().toString());
  const [indicators, setIndicators] = useState<IIndicators[]>(dummyIndicators);
  const [attributeData, setAttributeData] = useState<IAttributeData[]>([]);
  const [isAddEditPlayerAttribute, setIsAddEditPlayerAttribute] = useState<boolean>(false);
  const [selectedPlayerAttribute, setSelectedPlayerAttribute] = useState<IFootballPlayerAttribute[] | null>(null);

  useEffect(() => {
    if (playerInfo.id) {
      const yearsOption: ISelectOptions[] = [];
      const minYear = moment(playerInfo.createdAt).year();
      let playingYears: number[] = [];
      if (minYear < Number(formatDate(playerInfo.dateOfBirth, "YYYY"))) {
        playingYears = arrayRange(moment().year(), Number(formatDate(playerInfo.dateOfBirth, "YYYY")), -1);
      } else {
        playingYears = arrayRange(moment().year(), minYear, -1);
      }
      playingYears.forEach((year) => {
        yearsOption.push({ title: year.toString(), value: year });
      });
      setSelectOptions(yearsOption);
      setSelectedAttributeYear(moment().year().toString());
      dispatch(fetchManagePlayerAttribute({ playerId: playerInfo.id }));
    }
  }, [playerInfo]);

  useEffect(() => {
    if (selectedAttributeYear) {
      const indicatorList: IIndicators[] = [];
      const attributeDataList: number[] = [];
      const filteredPlayerAttribute = playerAttribute[selectedAttributeYear];
      if (filteredPlayerAttribute) {
        setSelectedPlayerAttribute(filteredPlayerAttribute);
        filteredPlayerAttribute.forEach((playerAttribute, index) => {
          indicatorList.push({
            index: index,
            label: playerAttribute.attribute.abbreviation,
            max: 100,
            name: Math.floor(playerAttribute.value),
            labelFull: playerAttribute.attribute.name,
          });
          attributeDataList.push(Math.floor(playerAttribute.value));
        });
      } else {
        setSelectedPlayerAttribute(null);
      }
      if (indicatorList.length <= 0) {
        setIndicators(dummyIndicators);
      } else {
        setIndicators(indicatorList);
      }
      setAttributeData([
        {
          player: playerInfo,
          attributeData: attributeDataList,
        },
      ]);
    }
  }, [playerAttribute, selectedAttributeYear]);

  return (
    <>
      {playerInfoStatus === StatusEnum.Loading ? (
        <AppSkeletonBasicInfo />
      ) : (
        <AppBox mb="sm" gap="sm" flexDirection="column">
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">Basic Information</AppTitle>
            <AppBox className="w-100" gap="sm">
              <AppBox>
                <AppPaper shadow="xs" radius="sm" padding="xs">
                  <AppBox gap="2xs" flexDirection="column" className="flex-1">
                    {playerInfo.banner ? (
                      <AppBanner username={playerInfo.fullName} src={playerInfo.banner || ""} />
                    ) : (
                      <AppBorderBox>
                        <AppBox
                          alignItems="center"
                          justifyContent="center"
                          flexDirection="column"
                          style={{ width: "16.5rem", height: "22rem" }}
                        >
                          <IconPhotoFilm height={40} width={40} color="rgba(var(--border-200))" />
                          <AppText size="sm" color="muted">
                            No banner added
                          </AppText>
                        </AppBox>
                      </AppBorderBox>
                    )}
                  </AppBox>
                </AppPaper>
              </AppBox>
              <AppBox flexDirection="column" className="flex-1" gap="xs">
                <AppGridBox columns={3} gap="sm">
                  <InfoCard
                    label="Display Name"
                    title={toTitleCase(playerInfo.displayName) || "-"}
                    alignItems="start"
                    shadow="xs"
                  />
                  <InfoCard
                    label="Date of Birth"
                    title={formatDate(playerInfo.dateOfBirth, "D MMM, YYYY") || "-"}
                    alignItems="start"
                    shadow="xs"
                  />
                  <InfoCard
                    label="Birth Place"
                    title={playerInfo.birthPlace ? playerInfo.birthPlace : "-"}
                    alignItems="start"
                    shadow="xs"
                  />
                  <InfoCard
                    label="Height"
                    title={playerInfo.height ? playerInfo.height + " " + "cm" : "-"}
                    alignItems="start"
                    shadow="xs"
                  />
                  <InfoCard
                    label="Weight"
                    title={playerInfo.weight ? playerInfo.weight + " " + "lb" : "-"}
                    alignItems="start"
                    shadow="xs"
                  />
                  <InfoCard
                    label="Preferred Foot"
                    title={playerInfo.preferredFoot ? toSentenceCase(playerInfo.preferredFoot) : "-"}
                    alignItems="start"
                    shadow="xs"
                  />
                  <InfoCard
                    label="Position"
                    title={toSentenceCase(playerInfo.position) || "-"}
                    alignItems="start"
                    shadow="xs"
                  />
                  <InfoCard
                    label="Player's Status"
                    title={playerInfo.status === Number(PlayerStatusEnum.Active) ? "Active" : "Retired"}
                    icon={
                      playerInfo.status === Number(PlayerStatusEnum.Active) ? (
                        <IconApproval height={32} width={32} color="var(--fg-outline-success)" />
                      ) : (
                        <IconPersonProhibited height={32} width={32} color="var(--fg-outline-danger)" />
                      )
                    }
                    alignItems="start"
                    shadow="xs"
                  />
                </AppGridBox>
                <AppBorderBox border={["Top"]} pt="xs">
                  <AppGridBox columns={2} gap="sm">
                    <InfoCard
                      title="Strengths"
                      label={toSentenceCase(`${playerInfo.strength || "There are not any Strengths."}`)}
                      alignItems="start"
                      shadow="xs"
                      icon={<IconDumbBell height={32} width={32} color="var(--fg-outline-success)" />}
                    />
                    <InfoCard
                      title="Weaknesses"
                      label={toSentenceCase(`${playerInfo.weakness || "There are not any Weaknesses."}`)}
                      alignItems="start"
                      shadow="xs"
                      icon={<IconPatch height={32} width={32} color="var(--fg-outline-danger)" />}
                    />
                  </AppGridBox>
                </AppBorderBox>
              </AppBox>
            </AppBox>
          </AppBox>
          <AppBox flexDirection="column" gap="2xs">
            <AppTitle as="h5">Description</AppTitle>
            <AppText as="p" fontWeight="medium" size="lg">
              <span
                dangerouslySetInnerHTML={{
                  __html: playerInfo.description || "There is not any description.",
                }}
              ></span>
            </AppText>
          </AppBox>
          <AppBox flexDirection="column" gap="sm">
            <AppBox flexDirection="column" gap="xs">
              <AppTitle as="h5">Attribute Overview</AppTitle>
              <AppBox justifyContent="space-between">
                <AppBox className="w-1/2">
                  {selectOptions.length > 0 && (
                    <AppSelect
                      className="w-1/2"
                      options={selectOptions}
                      onChange={(option) => setSelectedAttributeYear(option.value.toString())}
                    />
                  )}
                </AppBox>
                <AppButton
                  variant="light"
                  label={selectedPlayerAttribute ? "Edit Attribute" : "Add Attribute"}
                  onClick={() => setIsAddEditPlayerAttribute(true)}
                />
              </AppBox>
            </AppBox>
            {playerAttributeStatus === StatusEnum.Loading ? (
              <AppSkeletonPlayerAttribute />
            ) : (
              <PlayerAttribute indicators={indicators} attributeData={attributeData} />
            )}
          </AppBox>
        </AppBox>
      )}
      <AppModal
        opened={isAddEditPlayerAttribute}
        onClose={() => {
          setIsAddEditPlayerAttribute(false);
        }}
        title={selectedPlayerAttribute ? "Edit Attribute" : "Add Attribute"}
        titleIcon={selectedPlayerAttribute ? <IconEdit /> : <IconCrown />}
        withoutButtons
      >
        <PlayerAttributeAddEdit
          player={playerInfo}
          selectedYear={selectedAttributeYear}
          playerAttributeData={selectedPlayerAttribute}
          onSubmitOrCancel={() => {
            setIsAddEditPlayerAttribute(false);
          }}
        />
      </AppModal>
    </>
  );
}
