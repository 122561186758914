import { useEffect } from "react";
import { AppBox, AppGridBox, AppSkeletonNewsList, AppText, AppTitle } from "../../../commons/components";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import TournamentStandingTable from "./components/TournamentStandingTable";

export default function TournamentStandings() {
  const dispatch = useAppDispatch();
  const { newsList } = useAppSelector((state) => state.news);
  const { competition } = useAppSelector((state) => state.footballCompetition);
  const { seasonList } = useAppSelector((state) => state.footballSeason);
  const { selectedGlobalSeasonOption } = useAppSelector((state) => state.globalSeasonSelect);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  return (
    <AppBox className="page-content" gap="md">
      {seasonList.length > 0 ? (
        <AppBox flexDirection="column" gap="sm">
          {competition.status === StatusEnum.Succeeded && (
            <AppBox flexDirection="column" gap="xs">
              <AppBox justifyContent="space-between">
                <AppTitle as="h5">Standings</AppTitle>
              </AppBox>
            </AppBox>
          )}
          {selectedGlobalSeasonOption.value && (
            <AppBox className="w-100" flexDirection="column">
              <TournamentStandingTable
                competition={competition.data}
                currentSeasonId={Number(selectedGlobalSeasonOption.value)}
              />
            </AppBox>
          )}
        </AppBox>
      ) : (
        <AppText>The season has either ended or not yet started.</AppText>
      )}
      {newsList.status === (StatusEnum.Loading || StatusEnum.Idle) ? (
        <AppSkeletonNewsList />
      ) : (
        <NewsTimelineCard newsList={newsList.data} timelineType="small" />
      )}
    </AppBox>
  );
}
