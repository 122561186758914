import { AppBox, AppButton, AppGridBox, AppInput, AppText } from "../../../../../commons/components";
import { Controller, useForm } from "react-hook-form";
import { findInputError, isFormInvalid } from "../../../../../commons/utilities/form-utils";
import { FieldErrors, FieldValues } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import { setStepperFormData } from "../../../../../redux/slices/stepper-form-slice";
import { IFootballCompetition } from "../../../../../commons/models/football/interface/i-football-competition";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { ICompetitionDTO } from "../../../../../api-services/football/admin/manage-football-competition-service";
import { toSentenceCase } from "../../../../../commons/utilities/string-utils";

interface TournamentBasicInfoAddEditProps {
  competitionData?: IFootballCompetition | null;
  formStep: number;
  formErrors: FieldErrors<FieldValues>;
  submitStatus: StatusEnum;
  onBack: (currentStep: number) => void;
  onNext: (currentStep: number) => void;
  onClearError: (errorKey: string) => void;
  onSaveAndExit: () => void;
}

const TournamentBasicInfoAddEdit = ({
  competitionData,
  formStep,
  formErrors,
  submitStatus,
  onSaveAndExit,
  onBack,
  onNext,
  onClearError,
}: TournamentBasicInfoAddEditProps) => {
  const methods = useForm();
  const dispatch = useAppDispatch();
  const formData = useAppSelector<ICompetitionDTO>((state) => state.stepperForm.stepperFormData);

  const handleInputChange = (value: string, key: string) => {
    const competitionData: ICompetitionDTO = {
      ...formData,
      [key]: value,
    };
    dispatch(setStepperFormData<ICompetitionDTO>(competitionData));
    onClearError(key);
  };

  return (
    <AppBox flexDirection="column" justifyContent="space-between" gap="sm" className="flex-1">
      <AppBox flexDirection="column" gap="sm" className="w-100">
        <Controller
          name="title"
          defaultValue={competitionData ? competitionData.title : ""}
          control={methods.control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                id="title"
                label="Tournament Name *"
                placeholder="Enter tournament name"
                type="text"
                defaultValue={value}
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "title");
                }}
                flex="row"
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "title")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.title?.message ?? "Tournament name is required."}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="abbreviation"
          defaultValue={competitionData ? competitionData.abbreviation : ""}
          control={methods.control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                id="abbreviation"
                label="Abbreviated Name *"
                placeholder="Enter tournament abbreviation"
                type="text"
                defaultValue={value}
                flex="row"
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "abbreviation");
                }}
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "abbreviation")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.abbreviation?.message ?? "Abbreviation is required."}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="description"
          defaultValue={competitionData ? competitionData.description : ""}
          control={methods.control}
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                as="textarea"
                id="description"
                label="Description"
                placeholder="Enter description about your team"
                className="ee-input-textarea"
                defaultValue={value}
                flex="row"
                labelAlign="start"
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "description");
                }}
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "description")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.description?.message}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
      </AppBox>
      <AppBox gap="xs" justifyContent="end">
        <AppButton
          label="Back"
          variant="outline"
          color="gray"
          borderLight
          onClick={() => {
            onBack(formStep);
          }}
          disabled={submitStatus === StatusEnum.Loading}
        />
        {competitionData && (
          <AppButton
            label="Save & Exit"
            variant="light"
            onClick={() => {
              onSaveAndExit();
            }}
            loading={submitStatus === StatusEnum.Loading}
          />
        )}
        <AppButton
          label="Next"
          onClick={() => {
            onNext(formStep);
          }}
          disabled={submitStatus === StatusEnum.Loading}
        />
      </AppBox>
    </AppBox>
  );
};

export default TournamentBasicInfoAddEdit;
