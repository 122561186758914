import { RouteObject } from "react-router-dom";
import MatchDetailManage from "./MatchDetailManage";
import { NotFound } from "../../../commons/components";
import MatchDetailManageLineup from "./MatchDetailManageLineups";
import MatchDetailManageCommentary from "./MatchDetailManageCommentary";
import MatchDetailStandings from "./MatchDetailStandings";
import MatchDetailManageStatistics from "./MatchDetailManageStatistics";
import { MatchDetailBrackets } from "./MatchDetailBrackets";
import MatchDetailSummary from "./MatchDetailSummary";

const MATCH_DETAIL_SUMMARY = "summary";
const MATCH_DETAIL_LINEUP = "lineups";
const MATCH_DETAIL_COMMENTARY = "commentary";
const MATCH_DETAIL_STATISTICS = "statistics";
const MATCH_DETAIL_STANDING = "standings";
const MATCH_DETAIL_BRACKETS = "brackets";

export const routerAdminMatch: RouteObject[] = [
  {
    path: "",
    element: <NotFound />,
  },
  {
    path: ":matchId",
    element: <MatchDetailManage />,
    children: [
      { index: true, element: <MatchDetailSummary /> },
      { path: MATCH_DETAIL_SUMMARY, element: <MatchDetailSummary /> },
      { path: MATCH_DETAIL_LINEUP, element: <MatchDetailManageLineup /> },
      { path: MATCH_DETAIL_COMMENTARY, element: <MatchDetailManageCommentary /> },
      { path: MATCH_DETAIL_STATISTICS, element: <MatchDetailManageStatistics /> },
      { path: MATCH_DETAIL_STANDING, element: <MatchDetailStandings /> },
      { path: MATCH_DETAIL_BRACKETS, element: <MatchDetailBrackets /> },
      { path: "*", element: <NotFound /> },
    ],
  },
];
