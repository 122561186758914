import { AppBox, AppGridBox, AppLink, AppText } from "../../../../../commons/components";
import { FormProvider, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import { useSnackbar } from "../../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { IServerErrorResponse } from "../../../../../commons/components/interface";
import AppBorderBox from "../../../../../commons/components/BorderBox";
import TournamentLogoAddEdit from "./TournamentLogoAddEdit";
import TournamentBasicInfoAddEdit from "./TournamentBasicInfoAddEdit";
import TournamentSpecificsAddEdit from "./TournamentSpecificsAddEdit";
import { cleanUpStepperFormData, setStepperFormData } from "../../../../../redux/slices/stepper-form-slice";
import { getErrorKeyPosition } from "../../../../../commons/utilities/form-utils";
import { IFootballCompetition } from "../../../../../commons/models/football/interface/i-football-competition";
import { ICompetitionDTO } from "../../../../../api-services/football/admin/manage-football-competition-service";
import { competitionEdit } from "../../../../../redux/slices/football/admin/competition/manage-competition-edit-slice";
import { competitionAdd } from "../../../../../redux/slices/football/admin/competition/manage-competition-add-slice";
import { StatusEnum } from "../../../../../commons/enums/status-enum";

interface PlayerAddEditProps {
  competitionData?: IFootballCompetition | null;
  onCancel?: () => void;
  onSave: () => void;
}

const stepperFormOptions = [
  {
    step: 1,
    title: "Tournament's Logo",
  },
  {
    step: 2,
    title: "Basic Information",
  },
  {
    step: 3,
    title: "Specifics",
  },
];

const stepperFromKeyMap = [
  ["logo"],
  ["title", "abbreviation", "description"],
  ["type", "teamGender", "ageGroup", "teamForm", "skillLevel"],
];

export default function PlayerAddEdit({ competitionData, onCancel, onSave }: PlayerAddEditProps) {
  const methods = useForm();
  const formErrors = methods.formState.errors;
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const [selectedStepperFormOption, setSelectedStepperFormOption] = useState(stepperFormOptions[0]);
  const formData = useAppSelector<ICompetitionDTO>((state) => state.stepperForm.stepperFormData);
  const { competitionAddResponseStatus } = useAppSelector((state) => state.footballManageCompetitionAdd);
  const { competitionEditResponseStatus } = useAppSelector((state) => state.footballManageCompetitionEdit);
  const { uploadedImageStatus } = useAppSelector((state) => state.imageUpload);

  //initiate the stepperData
  useLayoutEffect(() => {
    if (!competitionData) {
      const competitionData: ICompetitionDTO = {
        title: "",
        abbreviation: "",
        description: null,
        logo: null,
        type: "",
        teamGender: null,
        ageGroup: null,
        teamForm: null,
        skillLevel: null,
      };
      dispatch(setStepperFormData<ICompetitionDTO>(competitionData));
    }
  }, []);

  useLayoutEffect(() => {
    if (competitionData) {
      const competitionDTO: ICompetitionDTO = {
        title: competitionData.title,
        abbreviation: competitionData.abbreviation,
        description: competitionData.description,
        logo: competitionData.logo,
        type: competitionData.type,
        teamGender: competitionData.teamGender,
        ageGroup: competitionData.ageGroup,
        teamForm: competitionData.teamForm,
        skillLevel: competitionData.skillLevel,
      };
      dispatch(setStepperFormData<ICompetitionDTO>(competitionDTO));
    }
  }, [competitionData]);

  const handleNext = (currentStep: number) => {
    if (
      competitionAddResponseStatus !== StatusEnum.Loading &&
      competitionEditResponseStatus !== StatusEnum.Loading &&
      uploadedImageStatus !== StatusEnum.Loading
    ) {
      setSelectedStepperFormOption(stepperFormOptions[currentStep]);
    }
  };
  const handleBack = (currentStep: number) => {
    if (
      competitionAddResponseStatus !== StatusEnum.Loading &&
      competitionEditResponseStatus !== StatusEnum.Loading &&
      uploadedImageStatus !== StatusEnum.Loading
    ) {
      setSelectedStepperFormOption(stepperFormOptions[currentStep - 2]);
    }
  };

  const handleStepperOptionClick = (option: any) => {
    if (
      competitionAddResponseStatus !== StatusEnum.Loading &&
      competitionEditResponseStatus !== StatusEnum.Loading &&
      uploadedImageStatus !== StatusEnum.Loading
    ) {
      setSelectedStepperFormOption(option);
    }
  };

  const handleFormSubmit = () => {
    const formattedValues: ICompetitionDTO = {
      ...formData,
      teamGender: Number(formData.teamGender),
    };
    dispatch(
      competitionData
        ? competitionEdit({ competitionId: competitionData.id, competitionData: formattedValues })
        : competitionAdd({ competitionData: formattedValues })
    )
      .unwrap()
      .then(() => {
        if (competitionData) {
          addSnackbar({
            key: "tournament-edit-success",
            text: "Tournament Edited Successfully",
            variant: "success",
          });
        } else {
          addSnackbar({
            key: "tournament-add-success",
            text: "Tournament Added Successfully",
            variant: "success",
          });
        }
        dispatch(cleanUpStepperFormData());
        onSave();
      })
      .catch((error: AxiosError<IServerErrorResponse>) => {
        const responseData = error.response?.data;
        if (error.response?.status === 417) {
          addSnackbar({
            key: "error",
            text: "Form not valid",
            variant: "danger",
          });
          if (responseData) {
            const stepperPositionSet = new Set<number>();
            Object.entries(responseData).forEach(([field, messages]) => {
              messages.forEach((message: string) => {
                methods.setError(field, { message });
                const stepperPosition = getErrorKeyPosition(field, stepperFromKeyMap);
                if (stepperPosition) {
                  stepperPositionSet.add(stepperPosition.step);
                }
                const lowestStep = Math.min(...Array.from(stepperPositionSet));
                setSelectedStepperFormOption(stepperFormOptions[lowestStep]);
              });
            });
          }
        } else {
          addSnackbar({
            key: "error",
            text: responseData?.message,
            variant: "danger",
          });
        }
      });
  };

  const clearErrorField = (key: string) => {
    methods.clearErrors(key);
  };

  return (
    <FormProvider {...methods}>
      <AppBox flexDirection="column" gap="sm" style={{ height: "var(--modal-md)" }}>
        <AppGridBox className=" flex-1" style={{ gridTemplateColumns: "1fr 3fr" }} gap="sm">
          <AppBorderBox border={["Right"]}>
            <AppBox flexDirection="column" gap="2xs" className="w-100">
              {stepperFormOptions.map((option, index) => (
                <AppLink
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStepperOptionClick(option);
                  }}
                >
                  <AppBorderBox
                    border={option.step === selectedStepperFormOption.step ? ["Right"] : []}
                    borderColor="primary"
                  >
                    <AppBox pl="sm" py="2xs">
                      <AppText
                        fontWeight="semibold"
                        size="lg"
                        color={option.step === selectedStepperFormOption.step ? "primary" : "currentColor"}
                      >
                        {option.title}
                      </AppText>
                    </AppBox>
                  </AppBorderBox>
                </AppLink>
              ))}
            </AppBox>
          </AppBorderBox>
          <AppBox flexDirection="column" className="w-100 flex-1">
            <AppBox
              className="flex-1"
              style={selectedStepperFormOption.step === 1 ? { display: "flex" } : { display: "none" }}
            >
              <TournamentLogoAddEdit
                competitionData={competitionData}
                formStep={selectedStepperFormOption.step}
                formErrors={formErrors}
                onSkipOrNext={handleNext}
                onClearError={clearErrorField}
                submitStatus={competitionEditResponseStatus}
                onSaveAndExit={handleFormSubmit}
              />
            </AppBox>
            <AppBox
              className="flex-1"
              style={selectedStepperFormOption.step === 2 ? { display: "flex" } : { display: "none" }}
            >
              <TournamentBasicInfoAddEdit
                competitionData={competitionData}
                formStep={selectedStepperFormOption.step}
                formErrors={formErrors}
                onBack={handleBack}
                onNext={handleNext}
                onClearError={clearErrorField}
                submitStatus={competitionEditResponseStatus}
                onSaveAndExit={handleFormSubmit}
              />
            </AppBox>
            <AppBox
              className="flex-1"
              style={selectedStepperFormOption.step === 3 ? { display: "flex" } : { display: "none" }}
            >
              <TournamentSpecificsAddEdit
                competitionData={competitionData}
                formStep={selectedStepperFormOption.step}
                formErrors={formErrors}
                submitStatus={competitionAddResponseStatus || competitionEditResponseStatus}
                onBack={handleBack}
                onSubmit={handleFormSubmit}
                onClearError={clearErrorField}
              />
            </AppBox>
          </AppBox>
        </AppGridBox>
      </AppBox>
    </FormProvider>
  );
}
