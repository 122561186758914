import { Outlet, createBrowserRouter, RouteObject } from "react-router-dom";
import { AppBox, Toolbar } from "./commons/components";
import { routerAuth } from "./pages/auth/routesAuth";
import { Components } from "./pages";
import { routerAdmin } from "./pages/admin/routesAdmin";
import { routerPublic } from "./pages/public/routesPublic";
import Sidenav from "./commons/components/ui-components/Sidenav";
import ManageSidenav from "./commons/components/ui-components/ManageSidenav";

export const HOME_PATH = "/";
export const MANAGE = "manage";
export const AUTH = "auth";
export const COMPONENTS = "components";
export const ERROR404 = "404";

const PublicLayout = () => (
  <>
    <Sidenav />
    <AppBox className="app-content-wrapper" flexDirection="column">
      <Toolbar />
      <Outlet />
    </AppBox>
  </>
);

const AdminLayout = () => (
  <>
    <ManageSidenav />
    <AppBox className="app-content-wrapper" flexDirection="column">
      <Toolbar />
      <Outlet />
    </AppBox>
  </>
);

const router = createBrowserRouter([
  {
    id: "home",
    path: "/",
    children: [
      {
        id: "public",
        path: "",
        element: <PublicLayout />,
        children: routerPublic as RouteObject[],
      },
      {
        id: "admin",
        path: MANAGE,
        element: <AdminLayout />,
        children: routerAdmin as RouteObject[],
      },
      {
        id: "components",
        path: COMPONENTS,
        element: <Components />,
      },
    ],
  },
  {
    id: "auth",
    path: AUTH,
    element: <Outlet />,
    children: routerAuth as RouteObject[],
  },
]);

export default router;
