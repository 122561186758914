import { Outlet, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { useEffect, useState } from "react";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { AppBox, AppContainer, AppModal, NotFound } from "../../../commons/components";
import TournamentAddEdit from "./components/tournamentAddEdit/TournamentAddEdit";
import TournamentManageDetailHeader from "./components/TournamentManageDetailHeader";
import {
  cleanUpCompetitionInfo,
  fetchManageCompetition,
} from "../../../redux/slices/football/admin/competition/manage-competition-slice";
import { setPageTitle } from "../../../redux/slices/page-title-slice";
import { cleanUpSeasonFixtureGroupFixtures } from "../../../redux/slices/football/admin/season/fixture-group/manage-fixture-group-fixtures-slice";
import { cleanUpSeasonFixtureGroups } from "../../../redux/slices/football/admin/season/fixture-group/manage-fixture-group-list-slice";
import { cleanUpCompetitionSeasonTeams } from "../../../redux/slices/football/admin/competition/manage-competition-season-teams-slice";
import setDocumentTitle from "../../../commons/utilities/document-title";
import { cleanUpRefereeList } from "../../../redux/slices/football/admin/referee/manage-referee-list-slice";
import { cleanUpStadiumList } from "../../../redux/slices/stadium/manage-stadium-list-slice";
import { cleanUpManageStanding } from "../../../redux/slices/football/admin/competition/manage-competition-season-standing-slice";
import { cleanUpManageSeasonList } from "../../../redux/slices/football/admin/competition/manage-competition-season-slice";
import IconEdit from "../../../commons/components/icons/edit";
import { cleanUpManageSeasonKnockouts } from "../../../redux/slices/football/admin/season/knockout/manage-knockout-list-slice";
import { cleanUpSeasonTeamGroups } from "../../../redux/slices/football/admin/season/team-group/manage-team-group-list-slice";
import { cleanUpGlobalSeasonSelectedOption } from "../../../redux/slices/global-season-select-slice";
import { cleanUpStepperFormData } from "../../../redux/slices/stepper-form-slice";

export default function TournamentDetailManage() {
  const { competitionId } = useParams();
  const dispatch = useAppDispatch();
  const [isEditTournament, setIsEditTournament] = useState(false);
  const { competitionInfo, competitionInfoStatus } = useAppSelector((state) => state.footballManageCompetition);
  useEffect(() => {
    dispatch(fetchManageCompetition(Number(competitionId)));
  }, [competitionId]);

  useEffect(() => {
    if (competitionInfoStatus === StatusEnum.Failed) {
      return () => {
        dispatch(cleanUpCompetitionInfo());
      };
    }
  }, [competitionInfoStatus]);

  useEffect(() => {
    setDocumentTitle([`${competitionInfo.abbreviation}`, "Manage"]);
  }, [competitionInfo]);

  const handleAfterEdit = () => {
    setIsEditTournament(false);
    dispatch(fetchManageCompetition(Number(competitionId)));
  };

  const handleAfterEditClose = () => {
    setIsEditTournament(false);
    dispatch(cleanUpStepperFormData());
  };

  useEffect(() => {
    dispatch(setPageTitle(""));
    return () => {
      dispatch(cleanUpCompetitionInfo());
      dispatch(cleanUpGlobalSeasonSelectedOption());
      dispatch(cleanUpSeasonFixtureGroupFixtures());
      dispatch(cleanUpSeasonFixtureGroups());
      dispatch(cleanUpCompetitionSeasonTeams());
      dispatch(cleanUpManageSeasonList());
      dispatch(cleanUpRefereeList());
      dispatch(cleanUpStadiumList());
      dispatch(cleanUpManageStanding());
      dispatch(cleanUpManageSeasonKnockouts());
      dispatch(cleanUpSeasonTeamGroups());
    };
  }, []);

  return (
    <AppContainer>
      {competitionInfoStatus === StatusEnum.Failed ? (
        <NotFound />
      ) : (
        <AppBox mt="md" flexDirection="column" gap="md">
          <TournamentManageDetailHeader
            competition={competitionInfo}
            onEditTournament={() => setIsEditTournament(true)}
          />
          <Outlet />
        </AppBox>
      )}
      <AppModal
        opened={isEditTournament}
        modalSize="lg"
        onClose={handleAfterEditClose}
        title="Edit Tournament"
        titleIcon={<IconEdit />}
        withoutButtons
      >
        <TournamentAddEdit competitionData={competitionInfo} onCancel={handleAfterEditClose} onSave={handleAfterEdit} />
      </AppModal>
    </AppContainer>
  );
}
