import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IPlayerAttributeDTO } from "../../../../../api-services/football/admin/manage-football-player-service";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballPlayerService } from "../../../../../commons";

export interface IPlayerAttributeAddEditOption {
  playerId: number;
  playerAttributeData: IPlayerAttributeDTO;
}

interface IPlayerAttributeAddEdit {
  playerAttributeAddEditResponseStatus: StatusEnum;
}

const initialState: IPlayerAttributeAddEdit = {
  playerAttributeAddEditResponseStatus: StatusEnum.Idle,
};

export const playerAttributeAddEdit = createAsyncThunk(
  "manage/playerAttributeAddEdit",
  async (options: IPlayerAttributeAddEditOption, thunkAPI) => {
    try {
      const playerAttributeAddEditResponse = await manageFootballPlayerService.setPlayerAttributes(
        options.playerId,
        options.playerAttributeData
      );
      return playerAttributeAddEditResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const managePlayerAttributeAddEditSlice = createSlice({
  name: "playerAttributeAddEdit",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(playerAttributeAddEdit.pending, (state) => {
        state.playerAttributeAddEditResponseStatus = StatusEnum.Loading;
      })
      .addCase(playerAttributeAddEdit.fulfilled, (state) => {
        state.playerAttributeAddEditResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(playerAttributeAddEdit.rejected, (state) => {
        state.playerAttributeAddEditResponseStatus = StatusEnum.Failed;
      });
  },
});

export default managePlayerAttributeAddEditSlice.reducer;
