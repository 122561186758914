import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../../commons";
import { IMatchPlayerPassStatisticDTO } from "../../../../../../../api-services/football/admin/manage-football-season-service";

interface ISeasonMatchPlayerPassStatisticAdd {
  seasonMatchPlayerPassStatisticAddResponseStatus: StatusEnum;
}

const initialState: ISeasonMatchPlayerPassStatisticAdd = {
  seasonMatchPlayerPassStatisticAddResponseStatus: StatusEnum.Idle,
};

export interface IFootballSeasonMatchPlayerPassStatisticAddOptions {
  seasonId: number;
  matchId: number;
  playerId: number;
  matchPlayerPassStatisticData: IMatchPlayerPassStatisticDTO;
}

export const seasonMatchPlayerPassStatisticAdd = createAsyncThunk(
  "manage/seasonMatchPlayerPassStatisticAdd",
  async (options: IFootballSeasonMatchPlayerPassStatisticAddOptions, thunkAPI) => {
    try {
      const seasonMatchPlayerPassStatisticAddResponse =
        await manageFootballSeasonService.addSeasonMatchPlayerPassStatistic(
          options.seasonId,
          options.matchId,
          options.playerId,
          options.matchPlayerPassStatisticData
        );
      return seasonMatchPlayerPassStatisticAddResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonMatchPlayerPassStatisticAddSlice = createSlice({
  name: "seasonMatchPlayerPassStatisticAdd",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonMatchPlayerPassStatisticAdd.pending, (state) => {
        state.seasonMatchPlayerPassStatisticAddResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonMatchPlayerPassStatisticAdd.fulfilled, (state, action) => {
        state.seasonMatchPlayerPassStatisticAddResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonMatchPlayerPassStatisticAdd.rejected, (state) => {
        state.seasonMatchPlayerPassStatisticAddResponseStatus = StatusEnum.Failed;
      });
  },
});

export default seasonMatchPlayerPassStatisticAddSlice.reducer;
