import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../commons/enums/status-enum";
import { FileUploadResponse } from "../../commons/models/file-upload-response";
import { uploadFileService } from "../../commons";

interface IFileUploadState {
  uploadedFile: FileUploadResponse;
  uploadedFileStatus: StatusEnum;
}

const initialState: IFileUploadState = {
  uploadedFile: new FileUploadResponse(),
  uploadedFileStatus: StatusEnum.Idle,
};

export const uploadFile = createAsyncThunk("upload-files/images", async (formData: FormData, thunkAPI) => {
  try {
    const imageUploadResponse = await uploadFileService.uploadFile(formData);

    return imageUploadResponse;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const ImageUploadSlice = createSlice({
  name: "ImageUploadSlice",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(uploadFile.pending, (state) => {
        state.uploadedFileStatus = StatusEnum.Loading;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.uploadedFileStatus = StatusEnum.Succeeded;
        state.uploadedFile = action.payload;
      })
      .addCase(uploadFile.rejected, (state) => {
        state.uploadedFileStatus = StatusEnum.Failed;
      });
  },
});

export default ImageUploadSlice.reducer;
