import { AppBox, AppGridBox, AppLink, AppText } from "../../../../../commons/components";
import { FormProvider, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import { formatDate } from "../../../../../commons/utilities/date-utils";
import { useSnackbar } from "../../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { IServerErrorResponse } from "../../../../../commons/components/interface";
import AppBorderBox from "../../../../../commons/components/BorderBox";
import TeamImageAddEdit from "./TeamLogoAddEdit";
import TeamBasicInfoAddEdit from "./TeamBasicInfoAddEdit";
import TeamSpecificsAddEdit from "./TeamSpecificsAddEdit";
import { cleanUpStepperFormData, setStepperFormData } from "../../../../../redux/slices/stepper-form-slice";
import { getErrorKeyPosition } from "../../../../../commons/utilities/form-utils";
import { IFootballTeam } from "../../../../../commons/models/football/interface/i-football-team";
import { ITeamDTO } from "../../../../../api-services/football/admin/manage-football-team-service";
import { teamEdit } from "../../../../../redux/slices/football/admin/team/manage-team-edit-slice";
import { teamAdd } from "../../../../../redux/slices/football/admin/team/manage-team-add-slice";
import moment from "moment";
import { StatusEnum } from "../../../../../commons/enums/status-enum";

interface TeamAddEditProps {
  teamData?: IFootballTeam | null;
  onCancel?: () => void;
  onSave?: () => void;
}

const stepperFormOptions = [
  {
    step: 1,
    title: "Team's Logo",
  },
  {
    step: 2,
    title: "Basic Information",
  },
  {
    step: 3,
    title: "Specifics",
  },
];

const stepperFromKeyMap = [
  ["logo"],
  ["name", "shortName", "abbreviation", "founded", "description"],
  ["homeColor", "awayColor", "thirdColor", "goalkeeperHomeColor", "goalkeeperAwayColor", "status"],
];

export default function TeamAddEdit({ teamData, onCancel, onSave }: TeamAddEditProps) {
  const methods = useForm();
  const formErrors = methods.formState.errors;
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const [selectedStepperFormOption, setSelectedStepperFormOption] = useState(stepperFormOptions[0]);
  const formData = useAppSelector<ITeamDTO>((state) => state.stepperForm.stepperFormData);
  const { teamAddResponseStatus } = useAppSelector((state) => state.footballManageTeamAdd);
  const { teamEditResponseStatus } = useAppSelector((state) => state.footballManageTeamEdit);
  const { uploadedImageStatus } = useAppSelector((state) => state.imageUpload);

  //initiate the stepperData
  useLayoutEffect(() => {
    if (!teamData) {
      const teamData: ITeamDTO = {
        name: "",
        shortName: "",
        abbreviation: "",
        description: null,
        founded: null,
        logo: null,
        homeColor: null,
        awayColor: null,
        thirdColor: null,
        goalkeeperHomeColor: null,
        goalkeeperAwayColor: null,
        playersCount: null,
        status: 1,
      };
      dispatch(setStepperFormData<ITeamDTO>(teamData));
    }
  }, []);

  useLayoutEffect(() => {
    if (teamData) {
      const teamDTO: ITeamDTO = {
        name: teamData.name,
        shortName: teamData.shortName,
        abbreviation: teamData.abbreviation,
        description: teamData.description,
        founded: teamData.founded,
        logo: teamData.logo,
        homeColor: teamData.homeColor,
        awayColor: teamData.awayColor,
        thirdColor: teamData.thirdColor,
        goalkeeperHomeColor: teamData.goalkeeperHomeColor,
        goalkeeperAwayColor: teamData.goalkeeperAwayColor,
        playersCount: teamData.playersCount,
        status: teamData.status,
      };
      dispatch(setStepperFormData<ITeamDTO>(teamDTO));
    }
  }, [teamData]);

  const handleNext = (currentStep: number) => {
    if (
      teamAddResponseStatus !== StatusEnum.Loading &&
      teamEditResponseStatus !== StatusEnum.Loading &&
      uploadedImageStatus !== StatusEnum.Loading
    ) {
      setSelectedStepperFormOption(stepperFormOptions[currentStep]);
    }
  };
  const handleBack = (currentStep: number) => {
    if (
      teamAddResponseStatus !== StatusEnum.Loading &&
      teamEditResponseStatus !== StatusEnum.Loading &&
      uploadedImageStatus !== StatusEnum.Loading
    ) {
      setSelectedStepperFormOption(stepperFormOptions[currentStep - 2]);
    }
  };

  const handleStepperOptionClick = (option: any) => {
    if (
      teamAddResponseStatus !== StatusEnum.Loading &&
      teamEditResponseStatus !== StatusEnum.Loading &&
      uploadedImageStatus !== StatusEnum.Loading
    ) {
      setSelectedStepperFormOption(option);
    }
  };

  const handleFormSubmit = () => {
    const formattedValues: ITeamDTO = {
      ...formData,
      logo: formData.logo ? formData.logo : null,
      status: Number(formData.status),
      founded: formData.founded ? formatDate(moment(formData.founded), "YYYY-MM-DD") : null,
    };
    dispatch(
      teamData ? teamEdit({ teamId: teamData.id, teamData: formattedValues }) : teamAdd({ teamData: formattedValues })
    )
      .unwrap()
      .then(() => {
        if (teamData) {
          addSnackbar({
            key: "team-edit-success",
            text: "Team Edited Successfully",
            variant: "success",
          });
        } else {
          addSnackbar({
            key: "team-add-success",
            text: "Team Added Successfully",
            variant: "success",
          });
        }
        dispatch(cleanUpStepperFormData());
        if (onSave) {
          onSave();
        }
      })
      .catch((error: AxiosError<IServerErrorResponse>) => {
        const responseData = error.response?.data;
        if (error.response?.status === 417) {
          addSnackbar({
            key: "error",
            text: "Form not valid",
            variant: "danger",
          });
          if (responseData) {
            const stepperPositionSet = new Set<number>();
            Object.entries(responseData).forEach(([field, messages]) => {
              messages.forEach((message: string) => {
                methods.setError(field, { message });
                const stepperPosition = getErrorKeyPosition(field, stepperFromKeyMap);
                if (stepperPosition) {
                  stepperPositionSet.add(stepperPosition.step);
                }
                const lowestStep = Math.min(...Array.from(stepperPositionSet));
                setSelectedStepperFormOption(stepperFormOptions[lowestStep]);
              });
            });
          }
        } else {
          addSnackbar({
            key: "error",
            text: responseData?.message,
            variant: "danger",
          });
        }
      });
  };

  const clearErrorField = (key: string) => {
    methods.clearErrors(key);
  };

  return (
    <FormProvider {...methods}>
      <AppBox flexDirection="column" gap="sm" style={{ height: "var(--modal-md)" }}>
        <AppGridBox className=" flex-1" style={{ gridTemplateColumns: "1fr 3fr" }} gap="sm">
          <AppBorderBox border={["Right"]}>
            <AppBox flexDirection="column" gap="2xs" className="w-100">
              {stepperFormOptions.map((option, index) => (
                <AppLink
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStepperOptionClick(option);
                  }}
                >
                  <AppBorderBox
                    border={option.step === selectedStepperFormOption.step ? ["Right"] : []}
                    borderColor="primary"
                  >
                    <AppBox pl="sm" py="2xs">
                      <AppText
                        fontWeight="semibold"
                        size="lg"
                        color={option.step === selectedStepperFormOption.step ? "primary" : "currentColor"}
                      >
                        {option.title}
                      </AppText>
                    </AppBox>
                  </AppBorderBox>
                </AppLink>
              ))}
            </AppBox>
          </AppBorderBox>
          <AppBox flexDirection="column" className="w-100 flex-1">
            <AppBox
              className="flex-1"
              style={selectedStepperFormOption.step === 1 ? { display: "flex" } : { display: "none" }}
            >
              <TeamImageAddEdit
                teamData={teamData}
                formStep={selectedStepperFormOption.step}
                formErrors={formErrors}
                onSkipOrNext={handleNext}
                onClearError={clearErrorField}
                submitStatus={teamEditResponseStatus}
                onSaveAndExit={handleFormSubmit}
              />
            </AppBox>
            <AppBox
              className="flex-1"
              style={selectedStepperFormOption.step === 2 ? { display: "flex" } : { display: "none" }}
            >
              <TeamBasicInfoAddEdit
                teamData={teamData}
                formStep={selectedStepperFormOption.step}
                formErrors={formErrors}
                onBack={handleBack}
                onNext={handleNext}
                onClearError={clearErrorField}
                submitStatus={teamEditResponseStatus}
                onSaveAndExit={handleFormSubmit}
              />
            </AppBox>
            <AppBox
              className="flex-1"
              style={selectedStepperFormOption.step === 3 ? { display: "flex" } : { display: "none" }}
            >
              <TeamSpecificsAddEdit
                teamData={teamData}
                formStep={selectedStepperFormOption.step}
                submitStatus={teamAddResponseStatus || teamEditResponseStatus}
                formErrors={formErrors}
                onBack={handleBack}
                onSubmit={handleFormSubmit}
                onClearError={clearErrorField}
              />
            </AppBox>
          </AppBox>
        </AppGridBox>
      </AppBox>
    </FormProvider>
  );
}
