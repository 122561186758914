import { useEffect, useState } from "react";
import { AppBox, AppPaper, AppSelect, AppSkeletonListComponent, AppText, AppTitle } from "../../../commons/components";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { IFootballStandingOption } from "../../../redux/interfaces/i-football-standing-state";
import { fetchManageCompetitionSeasonStanding } from "../../../redux/slices/football/admin/competition/manage-competition-season-standing-slice";
import { groupStandings, IGroupedStanding } from "../../../commons/utilities/standing-table-utils";
import StandingTableGroup from "../../../commons/components/ui-components/StandingTableGroup";
import AppBorderBox from "../../../commons/components/BorderBox";
import IconEmpty from "../../../commons/components/icons/empty";

export default function TournamentManageStandings() {
  const dispatch = useAppDispatch();
  const { competitionInfo, competitionInfoStatus } = useAppSelector((state) => state.footballManageCompetition);
  const { seasonList } = useAppSelector((state) => state.footballManageCompetitionSeason);
  const { selectedGlobalSeasonOption } = useAppSelector((state) => state.globalSeasonSelect);
  const { standingListByPoint, standingStatus } = useAppSelector(
    (state) => state.footballManageCompetitionSeasonStanding
  );
  const [groupedStandings, setGroupedStandings] = useState<IGroupedStanding[]>([]);

  useEffect(() => {
    if (selectedGlobalSeasonOption.value && competitionInfo.id) {
      const standingsOption: IFootballStandingOption = {
        competitionId: Number(competitionInfo.id),
        seasonId: Number(selectedGlobalSeasonOption.value),
      };
      dispatch(fetchManageCompetitionSeasonStanding(standingsOption));
    }
  }, [selectedGlobalSeasonOption]);

  useEffect(() => {
    setGroupedStandings(groupStandings(standingListByPoint));
  }, [standingListByPoint]);

  return (
    <AppBox mb="sm" flexDirection="column" gap="xs">
      {competitionInfoStatus === StatusEnum.Succeeded && (
        <AppBox flexDirection="column" gap="xs">
          <AppTitle as="h5">Standings</AppTitle>
        </AppBox>
      )}
      {seasonList.data.length > 0 ? (
        <>
          {standingStatus === StatusEnum.Loading ? (
            <AppSkeletonListComponent numberOfItems={20} noTitle />
          ) : (
            <>
              {groupedStandings.length > 0 ? (
                <StandingTableGroup groupedStandings={groupedStandings} isManage />
              ) : (
                <AppText>No standing data for the season.</AppText>
              )}
            </>
          )}
        </>
      ) : (
        <AppPaper padding="sm">
          <AppBorderBox borderVariant="dashed">
            <AppBox flexDirection="column" alignItems="center" gap="sm" justifyContent="center" p="sm">
              <AppBox alignItems="center" flexDirection="column" justifyContent="center" gap="xs">
                <IconEmpty width={56} height={56} color="rgba(var(--border-200))" />
                <AppText fontWeight="semibold">
                  To get the standings, you need to start the season first. Click the "Start Season" button above to
                  begin.
                </AppText>
              </AppBox>
            </AppBox>
          </AppBorderBox>
        </AppPaper>
      )}
    </AppBox>
  );
}
