import { useEffect, useLayoutEffect, useState } from "react";
import {
  AppBox,
  AppGridBox,
  AppSelect,
  AppSkeletonListComponent,
  AppSkeletonStatistics,
  AppSkeletonTabs,
  AppTitle,
} from "../../../commons/components";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { ISelectOptions } from "../../../commons/components/ui-components/Select";
import { fetchManageTeamCompetitionList } from "../../../redux/slices/football/admin/team/manage-team-competitions-slice";
import { IFootballTeamStatisticsOption } from "../../../redux/slices/football/public/team/football-team-basic-statistics-slice";
import {
  cleanUpManageTeamStats,
  fetchManageTeamBasicStatistics,
} from "../../../redux/slices/football/admin/team/manage-team-basic-statistics-slice";
import TeamStatisticsCard from "../../public/team-detail/components/TeamStatisticsCard";
import TeamStatisticsChartCard from "../../public/team-detail/components/TeamStatisticsChartCard";
import { IComparativeStatistics } from "../../../commons/models/football/interface/i-football-statistics";
import { FootballBasicStatistics } from "../../../commons/models/football/football-basic-statistics";
import {
  cleanUpManageCompetitionSeasonStats,
  fetchManageCompetitionSeasonStatistics,
  IManageStatisticsOption,
} from "../../../redux/slices/football/admin/competition/manage-competition-season-statistics-slice";
import { findIndexByCondition } from "../../../commons/utilities/array-utils";

export default function TeamManageStatistics() {
  const { teamInfo, teamInfoStatus } = useAppSelector((state) => state.footballManageTeam);
  const { teamCompetitionList } = useAppSelector((state) => state.footballManageTeamCompetitions);
  const { teamStatistics, teamStatisticsStatus } = useAppSelector((state) => state.footballManageTeamStatistics);
  const { competitionSeasonStatistics, competitionSeasonStatisticsStatus } = useAppSelector(
    (state) => state.footballManageCompetitionSeasonStatistics
  );
  const [competitionSelectOptions, setCompetitionSelectOptions] = useState<ISelectOptions[]>([]);
  const [seasonSelectOptions, setSeasonSelectOptions] = useState<ISelectOptions[]>([]);
  const [currentCompetitionId, setCurrentCompetitionId] = useState<number>();
  const [currentSeasonId, setCurrentSeasonId] = useState<number>();
  const [currentCompetitionOption, setCurrentCompetitionOption] = useState<ISelectOptions>();
  const [currentSeasonOption, setCurrentSeasonOption] = useState<ISelectOptions>();
  const [comparativeStatistics, setComparativeStatistics] = useState<IComparativeStatistics>({
    statistics: new FootballBasicStatistics(),
    baseStatistics: new FootballBasicStatistics(),
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (teamInfo.id) {
      dispatch(fetchManageTeamCompetitionList(teamInfo.id));
    }
  }, [teamInfo]);

  useEffect(() => {
    const competitionOptions: ISelectOptions[] = [];
    if (teamCompetitionList.length > 0) {
      teamCompetitionList.forEach((competition) => {
        competitionOptions.push({
          title: competition.title,
          value: competition.id,
        });
      });
      const currentTeamCompetitionId = teamInfo.latestSeason.competition?.id;
      let currentTeamCompetitionIndex = findIndexByCondition(
        competitionOptions,
        (option) => option.value === currentTeamCompetitionId
      );
      if (currentTeamCompetitionIndex >= 0) {
        setCurrentCompetitionOption(competitionOptions[currentTeamCompetitionIndex]);
        setCurrentCompetitionId(Number(competitionOptions[currentTeamCompetitionIndex].value));
      } else {
        setCurrentCompetitionId(Number(competitionOptions[0].value));
      }
      setCompetitionSelectOptions(competitionOptions);
    }
  }, [teamCompetitionList]);

  useEffect(() => {
    if (currentCompetitionId) {
      const currentCompetition = teamCompetitionList.first((competition) => competition.id === currentCompetitionId);
      const seasonOptions: ISelectOptions[] = [];
      currentCompetition?.seasons.forEach((season) => {
        seasonOptions.push({ title: season.title, value: season.id });
      });
      const currentTeamSeasonId = teamInfo.latestSeason.season?.id;
      let currentTeamSeasonIndex = findIndexByCondition(
        seasonOptions,
        (option) => option.value === currentTeamSeasonId
      );
      if (currentTeamSeasonIndex >= 0) {
        setCurrentSeasonOption(seasonOptions[currentTeamSeasonIndex]);
        setCurrentSeasonId(Number(seasonOptions[currentTeamSeasonIndex].value));
      } else {
        setCurrentSeasonOption(seasonOptions[0]);
        setCurrentSeasonId(Number(seasonOptions[0].value));
      }
      setSeasonSelectOptions(seasonOptions);
    }
  }, [currentCompetitionId]);

  useEffect(() => {
    if (!currentSeasonId) {
      dispatch(cleanUpManageTeamStats());
      dispatch(cleanUpManageCompetitionSeasonStats());
    }

    if (currentCompetitionId && !currentSeasonId) {
      return;
    }

    if (currentCompetitionId && currentSeasonId) {
      const teamStatOption: IFootballTeamStatisticsOption = {
        teamId: teamInfo.id,
        urlParams: {
          seasonId: currentSeasonId,
        },
      };
      const competitionSeasonStatsOption: IManageStatisticsOption = {
        competitionId: currentCompetitionId,
        seasonId: currentSeasonId,
      };
      dispatch(fetchManageTeamBasicStatistics(teamStatOption));
      dispatch(fetchManageCompetitionSeasonStatistics(competitionSeasonStatsOption));
    }
  }, [currentSeasonId]);

  useLayoutEffect(() => {
    setComparativeStatistics({ statistics: teamStatistics, baseStatistics: competitionSeasonStatistics });
  }, [teamStatistics, competitionSeasonStatistics]);

  return (
    <AppBox mb="sm" flexDirection="column" gap="sm">
      <AppBox flexDirection="column" gap="sm">
        {teamInfoStatus === StatusEnum.Succeeded && (
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">Team Statistics</AppTitle>
            {competitionSelectOptions.length > 0 && (
              <AppGridBox columns={2} gap="sm">
                {competitionSelectOptions.length > 0 && (
                  <AppSelect
                    currentOption={currentCompetitionOption}
                    options={competitionSelectOptions}
                    onChange={(option) => {
                      setCurrentCompetitionId(Number(option.value));
                    }}
                  />
                )}
                {seasonSelectOptions.length > 0 && (
                  <AppSelect
                    currentOption={currentSeasonOption}
                    options={seasonSelectOptions}
                    onChange={(option) => {
                      setCurrentSeasonId(Number(option.value));
                    }}
                  />
                )}
              </AppGridBox>
            )}
          </AppBox>
        )}
        {teamStatisticsStatus === StatusEnum.Loading ? (
          <AppBox flexDirection="column" gap="sm">
            <AppSkeletonStatistics noTitle />
            <AppSkeletonTabs numberOfItems={4} />
            <AppSkeletonListComponent noTitle noAvatar />
          </AppBox>
        ) : (
          <AppBox flexDirection="column" gap="md">
            <TeamStatisticsCard teamStats={teamStatistics} />
            <TeamStatisticsChartCard statistics={comparativeStatistics} />
          </AppBox>
        )}
      </AppBox>
    </AppBox>
  );
}
