import React, { useLayoutEffect, useState } from "react";

export interface TripleToggleSwitchSubLabel {
  title: string;
  value: string | number | boolean | null;
}

export interface TripleToggleSwitchLabels {
  left: TripleToggleSwitchSubLabel;
  center: TripleToggleSwitchSubLabel;
  right: TripleToggleSwitchSubLabel;
}

export type SwitchPositionType = "left" | "center" | "right";
interface Props {
  labels?: TripleToggleSwitchLabels;
  onChange: (value: SwitchPositionType) => void;
  withoutCenter?: boolean;
  switchId: string;
  currentOption?: SwitchPositionType;
  reset?: boolean;
  disable?: boolean;
}

const defaultLabels: TripleToggleSwitchLabels = {
  left: {
    title: "left",
    value: "left",
  },
  center: {
    title: "center",
    value: "center",
  },
  right: {
    title: "right",
    value: "right",
  },
};

const TripleToggleSwitch: React.FC<Props> = ({
  labels = defaultLabels,
  withoutCenter = false,
  switchId,
  currentOption,
  reset,
  disable,
  onChange,
}) => {
  const [switchPosition, setSwitchPosition] = useState<SwitchPositionType>("center");
  const [animation, setAnimation] = useState<string | null>(null);

  useLayoutEffect(() => {
    if (currentOption) {
      setSwitchPosition(currentOption);
      // onChange(labels[currentOption].value);
    }
  }, [currentOption]);

  useLayoutEffect(() => {
    if (currentOption) {
      setSwitchPosition(currentOption);
      // onChange(labels[currentOption].value);
    } else {
      setSwitchPosition("center");
    }
  }, [reset]);

  const getSwitchAnimation = (inputValue: keyof TripleToggleSwitchLabels) => {
    let animation: string | null = null;
    if (inputValue === "center" && switchPosition === "left") {
      animation = "left-to-center";
    } else if (inputValue === "right" && switchPosition === "center") {
      animation = "center-to-right";
    } else if (inputValue === "center" && switchPosition === "right") {
      animation = "right-to-center";
    } else if (inputValue === "left" && switchPosition === "center") {
      animation = "center-to-left";
    } else if (inputValue === "right" && switchPosition === "left") {
      animation = "left-to-right";
    } else if (inputValue === "left" && switchPosition === "right") {
      animation = "right-to-left";
    }

    onChange(inputValue);
    setSwitchPosition(inputValue);
    setAnimation(animation);
  };

  return (
    <div className={`ee-triple-toggle-switch-container ${withoutCenter && "without-center"} `}>
      <div
        className={`switch ${animation} ${switchPosition}-position ${withoutCenter && "without-center"} ${
          withoutCenter && switchPosition === "center" && "switch-without-center"
        }`}
      ></div>
      <input
        checked={switchPosition === "left"}
        onChange={(e) => getSwitchAnimation(e.target.value as keyof TripleToggleSwitchLabels)}
        name={`map-switch-${switchId}`}
        id={`left-${switchId}`}
        type="radio"
        value="left"
        className="left"
        disabled={disable}
      />
      <label
        className={`left-label ${switchPosition === "left" && "selected-font"} ${disable && "disable-button"}`}
        htmlFor={`left-${switchId}`}
      >
        {labels.left.title}
      </label>

      <input
        checked={switchPosition === "center"}
        onChange={(e) => getSwitchAnimation(e.target.value as keyof TripleToggleSwitchLabels)}
        name={`map-switch-${switchId}`}
        id={`center-${switchId}`}
        type="radio"
        value="center"
        className={`center ${withoutCenter && "without-center"}`}
        disabled={disable}
      />
      <label
        className={`center-label ${switchPosition === "center" && "selected-font"} ${
          withoutCenter && "without-center"
        } ${disable && "disable-button"}`}
        htmlFor={`center-${switchId}`}
      >
        {withoutCenter ? (
          <div className={`rounded-divider size-sm`} style={{ marginTop: "2px" }}></div>
        ) : (
          labels.center.title
        )}
      </label>

      <input
        checked={switchPosition === "right"}
        onChange={(e) => getSwitchAnimation(e.target.value as keyof TripleToggleSwitchLabels)}
        name={`map-switch-${switchId}`}
        id={`right-${switchId}`}
        type="radio"
        value="right"
        className="right"
        disabled={disable}
      />
      <label
        className={`right-label ${switchPosition === "right" && "selected-font"} ${disable && "disable-button"}`}
        htmlFor={`right-${switchId}`}
      >
        {labels.right.title}
      </label>
    </div>
  );
};

export default TripleToggleSwitch;
