import { AppPaper, AppSkeleton } from "..";
import { SurfaceColor } from "../../types/core-types";
import AppBox from "../Box";
import AppGridBox from "../GridBox";

const SkeletonMatchCard = ({ withTitle }: { withTitle?: boolean }) => {
  return (
    <AppBox flexDirection="column" gap="xs">
      {withTitle && (
        <AppBox gap="xs">
          <AppSkeleton type="title" />
          <AppSkeleton type="title" />
        </AppBox>
      )}
      <AppBox className="w-100" flexDirection="column" gap="2xs">
        {withTitle && <AppSkeleton type="label" />}
        <AppPaper className="match-card w-100" bgColor={SurfaceColor.surface1} shadow="none" padding="xs">
          <AppGridBox className="match-card-grid" gap="sm">
            <AppGridBox className="align-center live-left-section" gap="xs">
              <AppBox justifyContent="end">
                <AppBox className="match-home-team" gap="xs" alignItems="center">
                  <AppSkeleton type="title" />
                  <AppSkeleton type="avatar-md" />
                </AppBox>
              </AppBox>
              <AppSkeleton type="pill" />
              <AppBox justifyContent="start">
                <AppBox className="match-away-team" gap="xs" alignItems="center">
                  <AppSkeleton type="avatar-md" />
                  <AppSkeleton type="title" />
                </AppBox>
              </AppBox>
            </AppGridBox>
            <AppBox alignItems="center">
              <AppSkeleton type="button" className="w-100" />
            </AppBox>
          </AppGridBox>
        </AppPaper>
      </AppBox>
    </AppBox>
  );
};

export default SkeletonMatchCard;
