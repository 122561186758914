import { AppBox, AppGridBox, AppPaper, AppText, AppTitle, InfoCard } from "../../../../commons/components";
import { IFootballTeamDetail } from "../../../../commons/models/football/interface/i-football-team";
import { formatDate } from "../../../../commons/utilities/date-utils";
interface BasicInfoProps {
  team: IFootballTeamDetail;
}
export default function TeamBasicInfoCard({ team }: BasicInfoProps) {
  const teamList = [
    {
      label: "Foundation",
      title: formatDate(team.founded || "", "D MMM, YYYY") || "-",
    },
    {
      label: "Total Players",
      title: team.playersCount || "-",
    },
  ];
  return (
    <AppBox flexDirection="column" gap="sm">
      <AppGridBox className="team-info" columns={3} gap="sm">
        {teamList.map((item, index) => (
          <AppPaper shadow="xs" padding="none" key={index}>
            <InfoCard label={item.label} title={item.title} alignItems="start"></InfoCard>
          </AppPaper>
        ))}
      </AppGridBox>
      <AppBox flexDirection="column" gap="2xs">
        <AppTitle as="h5">Description</AppTitle>
        <AppText as="p" fontWeight="medium" size="lg">
          <span
            dangerouslySetInnerHTML={{
              __html: team.description || "There is not any description.",
            }}
          ></span>
        </AppText>
      </AppBox>
    </AppBox>
  );
}
