import { useEffect, useState } from "react";
import { IFootballPlayerAttribute } from "../../../../commons/models/football/interface/i-football-player-attribute";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { fetchManagePlayerAttributeList } from "../../../../redux/slices/football/admin/player/manage-player-attribute-list-slice";
import { IFootballPlayerDetail } from "../../../../commons/models/football/interface/i-football-player";
import { AppBox, AppButton, AppInput } from "../../../../commons/components";
import { Controller, useForm } from "react-hook-form";
import { PlayerPositionEnum } from "../../../../commons/enums/match-player-position-enum";
import { IPlayerAttributeDTO } from "../../../../api-services/football/admin/manage-football-player-service";
import { playerAttributeAddEdit } from "../../../../redux/slices/football/admin/player/manage-player-attribute-add-edit-slice";
import { fetchManagePlayerAttribute } from "../../../../redux/slices/football/admin/player/manage-player-attribute-slice";
import { fetchManagePlayerYearlyPosition } from "../../../../redux/slices/football/admin/player/manage-player-yearly-position-slice";
import { FootballAttribute } from "../../../../commons/models/football/football-attribute";
import { toSentenceCase } from "../../../../commons/utilities/string-utils";

interface PlayerAttributeAddEditProps {
  player: IFootballPlayerDetail;
  selectedYear: string;
  playerAttributeData: IFootballPlayerAttribute[] | null;
  onSubmitOrCancel: () => void;
}

const PlayerAttributeAddEdit = ({
  player,
  selectedYear,
  playerAttributeData,
  onSubmitOrCancel,
}: PlayerAttributeAddEditProps) => {
  const dispatch = useAppDispatch();
  const methods = useForm();
  const formErrors = methods.formState.errors;
  const [attributePosition, setAttributePosition] = useState<"GOALKEEPER" | "OUTFIELD">();
  const [filteredAttributes, setFilteredAttributes] = useState<FootballAttribute[]>([]);
  const { playerAttributeList } = useAppSelector((state) => state.footballManagePlayerAttributeList);

  useEffect(() => {
    dispatch(fetchManagePlayerAttributeList());
  }, []);

  useEffect(() => {
    if (player.id) {
      dispatch(fetchManagePlayerYearlyPosition({ playerId: player.id }))
        .unwrap()
        .then((response) => {
          const filteredResponse = response
            .all()
            .find((yearlyPosition) => yearlyPosition.year === Number(selectedYear));
          if (filteredResponse) {
            if (filteredResponse.position === PlayerPositionEnum.Goalkeeper) {
              setAttributePosition("GOALKEEPER");
            } else {
              setAttributePosition("OUTFIELD");
            }
          } else {
            if (player.position === PlayerPositionEnum.Goalkeeper) {
              setAttributePosition("GOALKEEPER");
            } else {
              setAttributePosition("OUTFIELD");
            }
          }
        });
    }
  }, [player]);

  useEffect(() => {
    if (playerAttributeData) {
      methods.reset();
      let filteredAttributeKeys: string[] = [];
      filteredAttributes.forEach((attribute) => {
        filteredAttributeKeys.push(attribute.id.toString());
      });
      for (const playerAttribute of playerAttributeData) {
        if (filteredAttributeKeys.includes(playerAttribute.attribute.id.toString())) {
          methods.setValue(playerAttribute.attribute.id.toString(), playerAttribute.value);
        }
      }
    }
  }, [playerAttributeData, filteredAttributes]);

  useEffect(() => {
    if (playerAttributeList.length > 0) {
      const filteredAttributeList = playerAttributeList
        .all()
        .filter((attribute) => attribute.position === attributePosition);
      setFilteredAttributes(filteredAttributeList);
    }
  }, [playerAttributeList, attributePosition]);

  const handleFormSubmit = (formObject: { [key: string]: string }) => {
    const transformedObject: IPlayerAttributeDTO = {
      year: selectedYear,
      attributeValues: Object.entries(formObject).map(([attributeId, value]) => ({
        attributeId: Number(attributeId),
        value: Number(value),
      })),
    };
    dispatch(
      playerAttributeAddEdit({
        playerId: player.id,
        playerAttributeData: transformedObject,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(fetchManagePlayerAttribute({ playerId: player.id }));
        onSubmitOrCancel();
      });
  };

  return (
    <form
      noValidate
      onSubmit={methods.handleSubmit((e) => {
        methods.formState.isValid && handleFormSubmit(e);
      })}
    >
      <AppBox flexDirection="column" gap="md">
        <AppBox flexDirection="column" gap="sm" pr="2xs" pb="2xs" style={{ overflowY: "scroll", maxHeight: "30rem" }}>
          <AppBox flexDirection="column" gap="xs">
            {filteredAttributes.length > 0 &&
              filteredAttributes.map((attribute) => (
                <Controller
                  key={attribute.id}
                  name={attribute.id.toString()}
                  defaultValue={""}
                  control={methods.control}
                  rules={{ required: true, pattern: /^\d{1,2}$/ }}
                  render={({ field: { onChange, value } }) => (
                    <AppBox flexDirection="column">
                      <AppInput
                        value={value}
                        id={attribute.abbreviation}
                        label={toSentenceCase(attribute.name) + ` (${attribute.abbreviation}) *`}
                        placeholder="Enter attribute value"
                        type="number"
                        minValue={0}
                        maxValue={99}
                        onChange={onChange}
                        flex="row"
                      />
                    </AppBox>
                  )}
                />
              ))}
          </AppBox>
        </AppBox>
        <AppBox justifyContent="end" gap="xs">
          <AppButton
            type="submit"
            disabled={!methods.formState.isValid}
            label={playerAttributeData ? "Save Changes" : "Add Attributes"}
          />
        </AppBox>
      </AppBox>
    </form>
  );
};

export default PlayerAttributeAddEdit;
