import { AppBox, AppGridBox, AppSkeletonBasicInfo, AppTitle, InfoCard } from "../../../../commons/components";
import { IStadium } from "../../../../commons/models/i-stadium";
import { formatDate } from "../../../../commons/utilities/date-utils";
import { useAppSelector } from "../../../../hooks/app";
import { StatusEnum } from "../../../../commons/enums/status-enum";

interface StadiumInfoProps {
  stadiumInfo: IStadium;
}

export default function StadiumInfo({ stadiumInfo }: StadiumInfoProps) {
  const { stadiumInfoStatus } = useAppSelector((state) => state.manageStadium);
  const basicInfo = [
    {
      title: stadiumInfo.inaugurationAt ? formatDate(stadiumInfo.inaugurationAt, "DD MMM, YYYY") : "-",
      label: "Inauguration Date",
    },
    {
      title: stadiumInfo.capacity ?? "-",
      label: "Capacity",
    },
    {
      title: `${stadiumInfo.pitchLength ? stadiumInfo.pitchLength + " m" : "-"} X ${
        stadiumInfo.pitchWidth ? stadiumInfo.pitchWidth + " m" : "-"
      }`,
      label: "Pitch Size (length X width)",
    },
    {
      title: stadiumInfo.playingSurfaceType ?? "-",
      label: "Playing Surface Type",
    },
  ];

  return (
    <>
      {stadiumInfoStatus === StatusEnum.Loading ? (
        <AppSkeletonBasicInfo />
      ) : (
        <AppBox flexDirection="column" gap="xs">
          <AppTitle as="h5">Basic Information</AppTitle>
          <AppBox flexDirection="column" gap="sm">
            <AppGridBox columns={4} gap="sm">
              {basicInfo.map((item) => (
                <InfoCard key={item.label} alignItems="start" shadow="xs" title={item.title} label={item.label} />
              ))}
            </AppGridBox>
          </AppBox>
        </AppBox>
      )}
    </>
  );
}
