import {
  AppAvatar,
  AppBox,
  AppDivider,
  AppLink,
  AppPaper,
  AppSkeletonHeader,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppTabs, { TabItem } from "../../../../commons/components/Tabs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IFootballTeamDetail } from "../../../../commons/models/football/interface/i-football-team";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { cleanUpNews } from "../../../../redux/slices/news-slice";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import AppBorderBox from "../../../../commons/components/BorderBox";

interface TeamDetailHeaderProps {
  team: IFootballTeamDetail;
}

export default function TeamDetailHeader({ team }: TeamDetailHeaderProps) {
  const [currentTab, setCurrentTab] = useState("");
  const [activeItem, setActiveItem] = useState<TabItem>();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { teamStatus } = useAppSelector((state) => state.footballTeamDetail);

  useEffect(() => {
    const url = location.pathname.split("/");
    setCurrentTab(url[url.length - 1]);
  }, [activeItem, location.pathname]);

  useEffect(() => {
    const lastPathSegment = location.pathname.split("/").pop();
    if (!isNaN(Number(lastPathSegment))) {
      setCurrentTab("summary");
    }
  }, [location.pathname]);

  const tabItems = [
    {
      label: "Summary",
      value: "summary",
      href: "summary",
    },
    {
      label: "Profile",
      value: "profile",
      href: "profile",
    },
    {
      label: "Squad",
      value: "squad",
      href: "squad",
    },
    {
      label: "Events",
      value: "events",
      href: "events",
    },
    {
      label: "Statistics",
      value: "statistics",
      href: "statistics",
    },
    {
      label: "Standings",
      value: "standings",
      href: "standings",
    },
  ];

  const handleTournamentClick = (e: Event) => {
    if (team.latestSeason) {
      dispatch(cleanUpNews());
      window.scrollTo(0, 0);
    } else {
      e.preventDefault();
    }
  };

  return (
    <AppPaper shadow="xs" padding="none">
      {teamStatus === StatusEnum.Loading ? (
        <AppSkeletonHeader />
      ) : (
        <AppBox
          className="team-header"
          alignItems="center"
          gap="xs"
          p="sm"
          style={{
            borderBottom: "1px solid rgb(var(--border-default))",
          }}
        >
          <AppAvatar username={team.name} size="2xl" src={team.logo || ""} />
          <AppBox flexDirection="column">
            <AppTitle as="h4">{team.name}</AppTitle>
            <AppBox gap="3xs" className="team-sub-title">
              <AppLink
                to={`/tournaments/${team.latestSeason?.competition?.id}`}
                className="link"
                onClick={(e) => {
                  handleTournamentClick(e);
                }}
              >
                <AppText size="md">{team.latestSeason?.competition?.title}</AppText>
              </AppLink>
              <AppBorderBox className="team-sub-title-season" border={["Left"]} pl="3xs">
                <AppText size="md">{team.latestSeason?.season?.title}</AppText>
              </AppBorderBox>
            </AppBox>
          </AppBox>
        </AppBox>
      )}
      <AppBox p="sm">
        <AppTabs
          as="a"
          onClick={(e) => {
            setActiveItem(e);
            window.scrollTo(0, 0);
          }}
          activeValue={currentTab}
          tabItems={tabItems}
        />
      </AppBox>
    </AppPaper>
  );
}
