import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../../commons";
import { IMatchCommentaryData } from "../../../../../../../api-services/football/admin/manage-football-season-service";

interface ISeasonMatchCOmmentaryDelete {
  seasonMatchCommentaryDeleteStatus: StatusEnum;
}

const initialState: ISeasonMatchCOmmentaryDelete = {
  seasonMatchCommentaryDeleteStatus: StatusEnum.Idle,
};

export interface IFootballSeasonMatchCommentaryDeleteOptions {
  seasonId: number;
  matchId: number;
  commentaryId: number;
}

export const seasonMatchCommentaryDelete = createAsyncThunk(
  "manage/seasonMatchCommentaryDelete",
  async (options: IFootballSeasonMatchCommentaryDeleteOptions, thunkAPI) => {
    try {
      const seasonMatchCommentaryDelete = await manageFootballSeasonService.deleteSeasonMatchCommentary(
        options.seasonId,
        options.matchId,
        options.commentaryId
      );
      return seasonMatchCommentaryDelete;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonMatchCommentaryDeleteSlice = createSlice({
  name: "seasonMatchCommentaryDelete",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonMatchCommentaryDelete.pending, (state) => {
        state.seasonMatchCommentaryDeleteStatus = StatusEnum.Loading;
      })
      .addCase(seasonMatchCommentaryDelete.fulfilled, (state, action) => {
        state.seasonMatchCommentaryDeleteStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonMatchCommentaryDelete.rejected, (state) => {
        state.seasonMatchCommentaryDeleteStatus = StatusEnum.Failed;
      });
  },
});

export default seasonMatchCommentaryDeleteSlice.reducer;
