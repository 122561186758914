export const findInputError = (errors: any, name: string) => {
  const filtered = Object.keys(errors)
    .filter((key) => key === name)
    .reduce((cur, key) => {
      return Object.assign(cur, { error: errors[key] });
    }, {});
  return filtered;
};

export const isFormInvalid = (error: any) => {
  if (Object.keys(error).length > 0) return true;
  return false;
};

export const getErrorKeyPosition = (key: string, keyMap: string[][]): { step: number; index: number } | null => {
  for (let step = 0; step < keyMap.length; step++) {
    const index = keyMap[step].indexOf(key);
    if (index !== -1) {
      return { step, index };
    }
  }
  return null;
};
