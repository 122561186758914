import { AppAvatar, AppBox, AppLink, AppPaper, AppText, AppTitle } from "../../../../commons/components";
import AppLineUpBadge from "../../../../commons/components/ui-components/LineupBadge";
import { FootballTeamPlayer } from "../../../../commons/models/football/football-team-player";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface MatchLineupSquadCardProps {
  teamPlayer: FootballTeamPlayer;
}

export default function MatchLineupSquadCard({ teamPlayer }: MatchLineupSquadCardProps) {
  const handlePlayerClick = () => {
    window.scrollTo(0, 0);
  };
  const formattedNumber = teamPlayer?.jerseyNumber?.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });

  const playerPath = `/players/${teamPlayer.player.id}`;
  return (
    <>
      {teamPlayer && (
        <AppPaper radius="md" padding="xs" shadow="xs">
          <AppLink to={playerPath} onClick={handlePlayerClick}>
            <AppBox alignItems="center" gap="xs">
              <AppText as="label" size="sm" color="muted" className="min-w-2">
                {formattedNumber}
              </AppText>
              <AppAvatar username={teamPlayer.player.fullName} src={teamPlayer.player.avatar || ""} />
              <AppBox alignItems="center" className="flex-1" gap="xs">
                <AppTitle as="h6">{toTitleCase(teamPlayer.player.displayName)}</AppTitle>
                {teamPlayer.isCaptain && <AppLineUpBadge status="CAPTAIN" size={20} />}
              </AppBox>
            </AppBox>
          </AppLink>
        </AppPaper>
      )}
    </>
  );
}
