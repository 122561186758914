import {
  AppBox,
  AppButton,
  AppGridBox,
  AppSkeletonGlobalCardList,
  AppSkeletonListComponent,
  AppSkeletonMatchCardList,
  AppSkeletonNewsList,
  AppSkeletonStatistics,
  AppText,
  AppTitle,
} from "../../../commons/components";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import MatchInfoCard from "./components/MatchInfoCard";
import MatchStatisticsGroup from "./components/MatchStatisticsGroup";
import { IMatchEvent } from "./components/MatchTimeline";
import Arsenal from "../../../assets/images/clubs/Arsenal.png";
import Chelsea from "../../../assets/images/clubs/Chelsea.png";
import MatchCommentaryCard from "./components/MatchCommentaryCard";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { IFootballCommentaryOption } from "../../../redux/interfaces/i-football-commentary-state";
import { fetchMatchCommentary } from "../../../redux/slices/football/public/match/football-match-commentary-slice";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { fetchMatchStatistic } from "../../../redux/slices/football/public/match/football-match-stats-slice";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import { MatchStatusEnum } from "../../../commons/enums/match-status-enum";
import MatchUpcomingSummary from "./components/MatchUpcomingSummary";
import { MatchTeamTypeEnum } from "../../../commons/enums/match-team-type-enum";
import AppTabs, { TabItem } from "../../../commons/components/Tabs";
import MatchResultCard from "../team-detail/components/MatchResultCard";
import { IFootballMatchOption } from "../../../redux/interfaces/i-football-match-state";
import { fetchLastFiveMatches } from "../../../redux/slices/football/public/match/football-match-list-slice";
import MatchPenaltyList from "../../admin/match/components/penalty/MatchPenaltyLIst";

export default function MatchSummary() {
  const matchD: IMatchEvent = {
    officialMatchTime: 90,
    additionalTime1: 5,
    additionalTime2: 2,
    teamA: {
      avatar: Arsenal,
      name: "Arsenal",
      event: [
        { matchHalf: "firstHalf", time: 32, type: ["G"] },
        { matchHalf: "firstHalf", time: 49, type: ["G", "RC"] },
        { matchHalf: "secondHalf", time: 88, type: ["G"] },
        { matchHalf: "secondHalf", time: 91, type: ["S"] },
      ],
    },
    teamB: {
      avatar: Chelsea,
      name: "Chelsea",
      event: [
        { matchHalf: "secondHalf", time: 18, type: ["YC"] },
        { matchHalf: "firstHalf", time: 32, type: ["G"] },
        { matchHalf: "secondHalf", time: 70, type: ["DYC"] },
      ],
    },
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { commentaryList, commentaryListStatus } = useAppSelector((state) => state.footballMatchCommentary);
  const { match, matchStatus } = useAppSelector((state) => state.footballMatchDetail);
  const { matchStatistics, matchStatisticsStatus } = useAppSelector((state) => state.footballMatchStatistics);
  const { lastFiveMatchList } = useAppSelector((state) => state.footballMatchList);
  const [teamType, setTeamType] = useState(MatchTeamTypeEnum.Home);
  const [currentTeam, setCurrentTeam] = useState(match.homeTeam);
  const { newsList } = useAppSelector((state) => state.news);

  const tabItems = [
    {
      label: match.homeTeam?.shortName || "",
      value: MatchTeamTypeEnum.Home,
      icon: match.homeTeam?.logo,
    },
    {
      label: match.awayTeam?.shortName || "",
      value: MatchTeamTypeEnum.Away,
      icon: match.awayTeam?.logo,
    },
  ];

  const handleTabChange = (args: TabItem) => {
    setTeamType(args.value as MatchTeamTypeEnum);
    setCurrentTeam(args.value === MatchTeamTypeEnum.Home ? match.homeTeam : match.awayTeam);
  };

  const handleCommentaryClick = () => {
    navigate(`/matches/${match.id}/commentary`);
    window.scrollTo(0, 0);
  };
  const handleStatisticClick = () => {
    navigate(`/matches/${match.id}/statistics`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (match.status !== MatchStatusEnum.Upcoming && match.id) {
      let options: IFootballCommentaryOption = {
        matchId: Number(match.id),
        urlParams: {
          perPage: 10,
          sort: "gameTime",
          sortOrder: SortOrderEnum.Descending,
        },
      };
      dispatch(fetchMatchCommentary(options));
      dispatch(fetchMatchStatistic(Number(match.id)));
    }
  }, [match]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (match.status === MatchStatusEnum.Live) {
      intervalId = setInterval(() => {
        let options: IFootballCommentaryOption = {
          matchId: Number(match.id),
          urlParams: {
            perPage: 10,
            sort: "gameTime",
            sortOrder: SortOrderEnum.Descending,
          },
        };
        dispatch(fetchMatchCommentary(options));
        dispatch(fetchMatchStatistic(Number(match.id)));
      }, 30000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [match]);

  useEffect(() => {
    if (match.id && match.status === MatchStatusEnum.Upcoming) {
      let matchesOptions: IFootballMatchOption = {
        teamId: teamType === MatchTeamTypeEnum.Home ? match.homeTeam?.id : match.awayTeam?.id,
      };
      dispatch(fetchLastFiveMatches(matchesOptions));
    }
  }, [teamType, match]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  return (
    <AppBox className="match-page-content" gap="md">
      <AppBox flexDirection="column" gap="md">
        {match.status === MatchStatusEnum.Upcoming ? (
          <>
            <MatchUpcomingSummary />
            {match.homeTeam && match.awayTeam && (
              <AppBox flexDirection="column" gap="xs">
                <AppTitle as="h5">Form (Last 5 Matches)</AppTitle>
                <AppBox flexDirection="column" gap="sm">
                  <AppBox gap="sm">
                    <AppTabs
                      as="button"
                      onClick={handleTabChange}
                      activeValue={teamType}
                      tabItems={tabItems}
                      variant="filled"
                      shadow="xs"
                      isFilterTabs
                      tabItemClassName="tab-big"
                    />
                  </AppBox>
                  {lastFiveMatchList.status === StatusEnum.Loading ? (
                    <AppSkeletonMatchCardList noTitle />
                  ) : (
                    <>
                      {lastFiveMatchList.status === StatusEnum.Succeeded && (
                        <AppBox flexDirection="column" gap="sm" pl="xs">
                          {lastFiveMatchList.data.length > 0 ? (
                            <>
                              {lastFiveMatchList.data.mapArray((result, index) => (
                                <MatchResultCard key={index} match={result} includeBadge currentTeam={currentTeam} />
                              ))}
                            </>
                          ) : (
                            <AppText>No fixtures found for this team.</AppText>
                          )}
                        </AppBox>
                      )}
                    </>
                  )}
                </AppBox>
              </AppBox>
            )}
          </>
        ) : (
          <>
            {/* The timeline component is still in discussion */}
            {/* <MatchTimeline matchInfo={matchD} /> */}
            {match.penalties.length > 0 && <MatchPenaltyList match={match} />}
            {commentaryListStatus === StatusEnum.Loading ? (
              <AppSkeletonGlobalCardList numberOfItems={10} withText />
            ) : (
              <>
                {commentaryList.length > 0 && (
                  <AppBox flexDirection="column" gap="xs">
                    <AppTitle as="h5">Match Commentary</AppTitle>
                    <AppBox flexDirection="column" gap="sm" pl="xs">
                      {commentaryList.mapArray((commentaryItem, index) => (
                        <MatchCommentaryCard key={index} commentary={commentaryItem} match={match} />
                      ))}
                      <AppBox className="w-1/2 mx-auto" flexDirection="column">
                        <AppButton
                          fullWidth
                          size="lg"
                          variant="light"
                          label="Full Commentary"
                          onClick={() => handleCommentaryClick()}
                        />
                      </AppBox>
                    </AppBox>
                  </AppBox>
                )}
              </>
            )}
            <AppBox flexDirection="column" gap="sm">
              {matchStatisticsStatus === StatusEnum.Loading ? (
                <AppSkeletonStatistics isMatch />
              ) : (
                <>
                  <MatchStatisticsGroup match={match} matchStats={matchStatistics} />
                  <AppBox className="w-1/2 mx-auto" flexDirection="column">
                    <AppButton
                      as="a"
                      fullWidth
                      size="lg"
                      variant="light"
                      label="View Full Statistics"
                      onClick={() => handleStatisticClick()}
                    />
                  </AppBox>
                </>
              )}
            </AppBox>
          </>
        )}
      </AppBox>
      <AppBox flexDirection="column" gap="sm">
        {matchStatus === StatusEnum.Loading ? (
          <AppSkeletonListComponent numberOfItems={4} />
        ) : (
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">Match Information</AppTitle>
            <MatchInfoCard match={match} />
          </AppBox>
        )}
        <AppBox className="summary-news">
          {newsList.status === (StatusEnum.Loading || StatusEnum.Idle) ? (
            <AppSkeletonNewsList />
          ) : (
            <NewsTimelineCard newsList={newsList.data} timelineType="small" />
          )}
        </AppBox>
      </AppBox>
    </AppBox>
  );
}
