import { CSSProperties } from "react";
import { Orientation, SpacingOptions } from "../types/core-types";

type BorderColorOptions = "default" | "dark" | "primary" | "accent" | "info" | "success" | "warning" | "danger";

interface RoundedDividerProps extends SpacingOptions {
  color?: BorderColorOptions;
  size?: "3xs" | "2xs" | "xs" | "sm" | "md";
  style?: CSSProperties | undefined;
}

export default function AppRoundedDivider({
  size = "2xs",
  p,
  pt,
  pr,
  pb,
  pl,
  px,
  py,
  m,
  mt,
  mr,
  mb,
  ml,
  mx,
  my,
  style,
}: RoundedDividerProps) {
  const classNames = [
    `rounded-divider`,
    p && `p-${p}`,
    pt && `pt-${pt}`,
    pr && `pr-${pr}`,
    pb && `pb-${pb}`,
    pl && `pl-${pl}`,
    px && `px-${px}`,
    py && `py-${py}`,
    m && `m-${m}`,
    mt && `mt-${mt}`,
    mr && `mr-${mr}`,
    mb && `mb-${mb}`,
    ml && `ml-${ml}`,
    mx && `mx-${mx}`,
    my && `my-${my}`,
    size && `size-${size}`,
  ]
    .filter(Boolean)
    .join(" ");
  return <div className={classNames} style={style}></div>;
}
