import { useEffect, useState } from "react";
import { LabelValueStatisticsData, convertStatisticsData } from "../../../../commons/utilities/statistics-utils";
import StatisticsBar, { StatisticsProps } from "./StatisticsBar";
import { AppBox, AppPaper, AppText } from "../../../../commons/components";
import AppTabs from "../../../../commons/components/Tabs";
import { IComparativeStatistics } from "../../../../commons/models/football/interface/i-football-statistics";

interface TeamStatisticsChartCardProps {
  statistics: IComparativeStatistics;
}

const TeamStatisticsChartCard = ({ statistics }: TeamStatisticsChartCardProps) => {
  const [activeTab, setActiveTab] = useState<keyof LabelValueStatisticsData>("attack");
  const [formattedStatistics, setFormattedStatistics] = useState<LabelValueStatisticsData>();
  const statsTabs = [
    { label: "Attack", value: "attack" },
    { label: "Team Play", value: "teamPlay" },
    { label: "Defense", value: "defense" },
    { label: "Discipline", value: "discipline" },
    { label: "Keeping", value: "keeper" },
  ];

  useEffect(() => {
    setFormattedStatistics(convertStatisticsData(statistics));
  }, [statistics]);

  const renderStatistics = () => {
    if (!formattedStatistics) {
      return;
    }
    const data: StatisticsProps[] = formattedStatistics[activeTab];

    if (!data) {
      return <AppText>Nothing to display</AppText>;
    }

    return data?.map((stat) => (
      <StatisticsBar key={stat.label} label={stat.label} total={stat.total} value={stat.value} />
    ));
  };

  return (
    <AppBox flexDirection="column" gap="xs">
      <AppBox>
        <AppPaper padding="none" shadow="xs">
          <AppTabs
            as="button"
            onClick={(e) => setActiveTab(e.value as keyof LabelValueStatisticsData)}
            activeValue={activeTab}
            variant="filled"
            tabItems={statsTabs}
            isFilterTabs
          />
        </AppPaper>
      </AppBox>
      <AppPaper shadow="xs" padding="xs">
        <AppBox flexDirection="column" gap="sm">
          {renderStatistics()}
        </AppBox>
      </AppPaper>
    </AppBox>
  );
};

export default TeamStatisticsChartCard;
