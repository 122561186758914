import { IFootballSeasonGroup } from "../../../commons/models/football/admin/interface/i-manage-football-season-group";
import { ManageFootballFixture } from "../../../commons/models/football/admin/manage-football-fixture";
import { ManageFootballKnockout } from "../../../commons/models/football/admin/manage-football-knockout";
import { FootballKnockoutTree } from "../../../commons/models/football/football-knockout-tree";
import { ManageFootballMatchPlayersStatistics } from "../../../commons/models/football/admin/manage-football-match-players-statistics";
import { ManageFootballMatchPossession } from "../../../commons/models/football/admin/manage-football-match-possession";
import { ManageFootballSeasonFixtureGroup } from "../../../commons/models/football/admin/manage-football-season-fixture-group";
import { ManageFootballSeasonTeamGroup } from "../../../commons/models/football/admin/manage-football-season-team-group";
import { FootballCommentary } from "../../../commons/models/football/football-commentary";
import { FootballMatchDetail } from "../../../commons/models/football/football-match-detail";
import { FootballMatchPlayer } from "../../../commons/models/football/football-match-player";
import { FootballSeason } from "../../../commons/models/football/football-season";
import { IFootballPlayerLineup } from "../../../commons/models/football/interface/i-football-player-lineup";
import { AbstractBaseService } from "../../../ensue-react-system/utilities/base-service";
import { EnsueCollection } from "../../../ensue-react-system/utilities/ensue-collection";
import { PaginatedEnsueCollection } from "../../../ensue-react-system/utilities/paginated-ensue-collection";
import { ISeasonTeamsInfo } from "./manage-football-competition-service";
import { ManageFootballMatchPlayerPassStatistics } from "../../../commons/models/football/admin/manage-football-match-players-pass-statistics";
import { FootballMatchStat } from "../../../commons/models/football/football-match-stats";

export interface IMatchPlayersStatisticDTO {
  key: string;
  value: number;
  gameTime: number;
  gameExtraTime: number | null;
}
export interface IMatchPenaltyDTO {
  playerId: number;
  goalScored: boolean;
  penaltyNumber?: number;
}
export interface IMatchPlayerPassStatisticDTO {
  passes: number;
  passSucceeded: number;
  gameTime: number;
  gameExtraTime: number | null;
}
export interface IMatchPossessionDTO {
  homePossession: number;
  gameTime: number;
  gameExtraTime: number | null;
}
export interface IMatchCommentaryData {
  playerId: number;
  supportingPlayerId: number;
  eventType: string;
  gameTime: number;
  gameExtraTime: number | null;
  metadata: string;
}
export interface IGroupInfo {
  name: string;
}
export interface IKnockoutDTO {
  noOfMatches: number;
  thirdPlace: boolean;
}
export interface IMatchStartInfo {
  startAt: string;
}

export interface IMatchStateInfo {
  startAt?: string;
  state: number;
}
export interface IInjuryTimeData {
  addedTime: number;
}

export interface IFootballMatchEditDTO {
  stadiumId: number | null;
  scheduledAt: string;
  referees: IReferee[];
  gameplayDuration: number;
}
export interface IFootballMatchDTO {
  homeTeamId: number | null;
  awayTeamId: number | null;
  stadiumId: number | null;
  scheduledAt: string;
  referees: IReferee[];
  gameplayDuration: number;
  homeTeamOutcome?: {
    modelType: string;
    modelId: number;
    position: number;
  } | null;
  awayTeamOutcome?: {
    modelType: string;
    modelId: number;
    position: number;
  } | null;
}
export interface IMatchTeamLineupInfo {
  lineUps: string;
  players: IFootballPlayerLineup[];
}

interface IReferee {
  id: number;
  role: string;
}

export class ManageFootballSeasonService extends AbstractBaseService<FootballSeason> {
  protected __model: FootballSeason = new FootballSeason();

  public getResourceName(): string {
    return "manage/football/seasons";
  }

  public getSeasonTeamGroups(seasonId: number): Promise<EnsueCollection<ManageFootballSeasonTeamGroup>> {
    const seasonGroupModel = new ManageFootballSeasonTeamGroup();
    return this.__client
      .get(this.__url + "/" + seasonId + "/team-groups")
      .then((obj) => seasonGroupModel.createFromCollection(obj) as EnsueCollection<ManageFootballSeasonTeamGroup>);
  }

  public addSeasonTeamGroup(seasonId: number, groupInfo: IGroupInfo) {
    return this.__client.post(this.__url + "/" + seasonId + "/team-groups", groupInfo);
  }

  public editSeasonTeamGroup(seasonId: number, groupId: number, groupInfo: IGroupInfo) {
    return this.__client.patch(this.__url + "/" + seasonId + "/team-groups/" + groupId, groupInfo);
  }

  public deleteSeasonTeamGroup(seasonId: number, groupId: number) {
    return this.__client.delete(this.__url + "/" + seasonId + "/team-groups/" + groupId);
  }

  public addSeasonTeamGroupTeams(seasonId: number, groupId: number, teams: ISeasonTeamsInfo) {
    return this.__client.post(this.__url + "/" + seasonId + "/team-groups/" + groupId + "/teams", teams);
  }

  public removeSeasonTeamGroupTeam(seasonId: number, groupId: number, teamId: number) {
    return this.__client.delete(this.__url + "/" + seasonId + "/team-groups/" + groupId + "/teams/" + teamId);
  }

  public getSeasonFixtureGroups(seasonId: number): Promise<EnsueCollection<ManageFootballSeasonFixtureGroup>> {
    const seasonGroupModel = new ManageFootballSeasonFixtureGroup();
    return this.__client
      .get(this.__url + "/" + seasonId + "/fixture-groups")
      .then((obj) => seasonGroupModel.createFromCollection(obj) as EnsueCollection<ManageFootballSeasonFixtureGroup>);
  }

  public addSeasonFixtureGroup(seasonId: number, groupInfo: IGroupInfo) {
    return this.__client.post(this.__url + "/" + seasonId + "/fixture-groups", groupInfo);
  }

  public editSeasonFixtureGroup(seasonId: number, groupId: number, groupInfo: IGroupInfo) {
    return this.__client.patch(this.__url + "/" + seasonId + "/fixture-groups/" + groupId, groupInfo);
  }

  public deleteSeasonFixtureGroup(seasonId: number, groupId: number) {
    return this.__client.delete(this.__url + "/" + seasonId + "/fixture-groups/" + groupId);
  }

  public getSeasonFixtureGroupFixtureList(
    seasonId: number,
    groupId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<EnsueCollection<ManageFootballFixture>> {
    const seasonGroupFixtureModel = new ManageFootballFixture();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "/" + seasonId + "/fixture-groups/" + groupId + "/fixtures", options)
      .then((obj) => seasonGroupFixtureModel.createFromCollection(obj) as EnsueCollection<ManageFootballFixture>);
  }

  public addSeasonFixtureGroupFixture(seasonId: number, groupId: number, matchInfo: IFootballMatchDTO) {
    return this.__client.post(this.__url + "/" + seasonId + "/fixture-groups/" + groupId + "/fixtures", matchInfo);
  }

  public editSeasonFixtureGroupFixture(
    seasonId: number,
    groupId: number,
    fixtureId: number,
    matchInfo: IFootballMatchDTO
  ) {
    return this.__client.patch(
      this.__url + "/" + seasonId + "/fixture-groups/" + groupId + "/fixtures/" + fixtureId,
      matchInfo
    );
  }

  public deleteSeasonFixtureGroupFixture(seasonId: number, groupId: number, fixtureId: number) {
    return this.__client.delete(this.__url + "/" + seasonId + "/fixture-groups/" + groupId + "/fixtures/" + fixtureId);
  }

  public getSeasonMatchList(
    seasonId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<PaginatedEnsueCollection<FootballMatchDetail>> {
    const seasonMatchListModel = new FootballMatchDetail();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "/" + seasonId + "/matches", options)
      .then((obj) => seasonMatchListModel.createFromCollection(obj) as PaginatedEnsueCollection<FootballMatchDetail>);
  }

  public getSeasonMatch(seasonId: number, matchId: number): Promise<FootballMatchDetail> {
    const seasonMatchModel = new FootballMatchDetail();
    return this.__client
      .get(this.__url + "/" + seasonId + "/matches/" + matchId)
      .then((obj) => seasonMatchModel.create(obj) as FootballMatchDetail);
  }

  public getSeasonMatchTeams(seasonId: number, matchId: number): Promise<FootballMatchPlayer> {
    const seasonMatchTeamsModel = new FootballMatchPlayer();
    return this.__client
      .get(this.__url + "/" + seasonId + "/matches/" + matchId + "/teams")
      .then((obj) => seasonMatchTeamsModel.create(obj) as FootballMatchPlayer);
  }

  public editSeasonMatch(seasonId: number, matchId: number, matchEditInfo: IFootballMatchEditDTO) {
    return this.__client.patch(this.__url + "/" + seasonId + "/matches/" + matchId, matchEditInfo);
  }

  public addEditTeamFormation(seasonId: number, matchId: number, teamId: number, teamLineupInfo: IMatchTeamLineupInfo) {
    return this.__client.post(
      this.__url + "/" + seasonId + "/matches/" + matchId + "/teams/" + teamId + "/players",
      teamLineupInfo
    );
  }

  public startSeasonMatch(seasonId: number, matchId: number, matchStartInfo: IMatchStartInfo) {
    return this.__client.patch(this.__url + "/" + seasonId + "/matches/" + matchId + "/start", matchStartInfo);
  }

  public endSeasonMatch(seasonId: number, matchId: number) {
    return this.__client.patch(this.__url + "/" + seasonId + "/matches/" + matchId + "/end", undefined);
  }

  public changeSeasonMatchState(seasonId: number, matchId: number, stateId: number, stateData: IMatchStateInfo) {
    return this.__client.patch(this.__url + `/${seasonId}/matches/${matchId}/states/${stateId}`, stateData);
  }

  public addSeasonMatchInjuryTime(seasonId: number, matchId: number, stateId: number, injuryTimeData: IInjuryTimeData) {
    return this.__client.patch(
      this.__url + `/${seasonId}/matches/${matchId}/states/${stateId}/add-time`,
      injuryTimeData
    );
  }

  public getSeasonMatchCommentaryList(
    seasonId: number,
    matchId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<PaginatedEnsueCollection<FootballCommentary>> {
    const seasonMatchCommentaryListModel = new FootballCommentary();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "/" + seasonId + "/matches/" + matchId + "/commentaries", options)
      .then(
        (obj) =>
          seasonMatchCommentaryListModel.createFromCollection(obj) as PaginatedEnsueCollection<FootballCommentary>
      );
  }

  public addSeasonMatchCommentary(seasonId: number, matchId: number, commentaryData: IMatchCommentaryData) {
    return this.__client.post(this.__url + "/" + seasonId + "/matches/" + matchId + "/commentaries", commentaryData);
  }

  public editSeasonMatchCommentary(
    seasonId: number,
    matchId: number,
    commentaryId: number,
    commentaryData: IMatchCommentaryData
  ) {
    return this.__client.patch(
      this.__url + "/" + seasonId + "/matches/" + matchId + "/commentaries/" + commentaryId,
      commentaryData
    );
  }

  public deleteSeasonMatchCommentary(seasonId: number, matchId: number, commentaryId: number) {
    return this.__client.delete(this.__url + "/" + seasonId + "/matches/" + matchId + "/commentaries/" + commentaryId);
  }

  public getSeasonMatchPossessionList(
    seasonId: number,
    matchId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<PaginatedEnsueCollection<ManageFootballMatchPossession>> {
    const seasonMatchPossessionListModel = new ManageFootballMatchPossession();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "/" + seasonId + "/matches/" + matchId + "/possessions", options)
      .then(
        (obj) =>
          seasonMatchPossessionListModel.createFromCollection(
            obj
          ) as PaginatedEnsueCollection<ManageFootballMatchPossession>
      );
  }

  public addSeasonMatchPossession(seasonId: number, matchId: number, possessionData: IMatchPossessionDTO) {
    return this.__client.post(this.__url + "/" + seasonId + "/matches/" + matchId + "/possessions", possessionData);
  }

  public editSeasonMatchPossession(
    seasonId: number,
    matchId: number,
    possessionId: number,
    possessionData: IMatchPossessionDTO
  ) {
    return this.__client.patch(
      this.__url + "/" + seasonId + "/matches/" + matchId + "/possessions/" + possessionId,
      possessionData
    );
  }

  public deleteSeasonMatchPossession(seasonId: number, matchId: number, possessionId: number) {
    return this.__client.delete(this.__url + "/" + seasonId + "/matches/" + matchId + "/possessions/" + possessionId);
  }

  public getMatchStatistics(seasonId: number, matchId: number): Promise<FootballMatchStat> {
    const statisticModel = new FootballMatchStat();
    return this.__client
      .get(this.__url + "/" + seasonId + "/matches/" + matchId + "/statistics")
      .then((obj) => statisticModel.create(obj) as FootballMatchStat);
  }

  public getSeasonMatchPlayerStatistics(
    seasonId: number,
    matchId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<PaginatedEnsueCollection<ManageFootballMatchPlayersStatistics>> {
    const seasonMatchPlayerStatisticsModel = new ManageFootballMatchPlayersStatistics();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "/" + seasonId + "/matches/" + matchId + "/players-statistics", options)
      .then(
        (obj) =>
          seasonMatchPlayerStatisticsModel.createFromCollection(
            obj
          ) as PaginatedEnsueCollection<ManageFootballMatchPlayersStatistics>
      );
  }

  public addSeasonMatchPlayerStatistic(
    seasonId: number,
    matchId: number,
    playerId: number,
    statisticData: IMatchPlayersStatisticDTO
  ) {
    return this.__client.post(
      this.__url + "/" + seasonId + "/matches/" + matchId + "/players/" + playerId + "/statistics",
      statisticData
    );
  }

  public editSeasonMatchPlayerStatistic(
    seasonId: number,
    matchId: number,
    playerId: number,
    statisticId: number,
    statisticData: IMatchPlayersStatisticDTO
  ) {
    return this.__client.patch(
      this.__url + "/" + seasonId + "/matches/" + matchId + "/players/" + playerId + "/statistics/" + statisticId,
      statisticData
    );
  }

  public deleteSeasonMatchPlayerStatistic(seasonId: number, matchId: number, playerId: number, statisticId: number) {
    return this.__client.delete(
      this.__url + "/" + seasonId + "/matches/" + matchId + "/players/" + playerId + "/statistics/" + statisticId
    );
  }

  public getSeasonMatchPlayerPassStatistics(
    seasonId: number,
    matchId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<PaginatedEnsueCollection<ManageFootballMatchPlayerPassStatistics>> {
    const seasonMatchPlayerPassStatisticsModel = new ManageFootballMatchPlayerPassStatistics();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "/" + seasonId + "/matches/" + matchId + "/players-pass-statistics", options)
      .then(
        (obj) =>
          seasonMatchPlayerPassStatisticsModel.createFromCollection(
            obj
          ) as PaginatedEnsueCollection<ManageFootballMatchPlayerPassStatistics>
      );
  }

  public addSeasonMatchPlayerPassStatistic(
    seasonId: number,
    matchId: number,
    playerId: number,
    statisticData: IMatchPlayerPassStatisticDTO
  ) {
    return this.__client.post(
      this.__url + "/" + seasonId + "/matches/" + matchId + "/players/" + playerId + "/pass-statistics",
      statisticData
    );
  }

  public editSeasonMatchPlayerPassStatistic(
    seasonId: number,
    matchId: number,
    playerId: number,
    statisticData: IMatchPlayerPassStatisticDTO
  ) {
    return this.__client.patch(
      this.__url + "/" + seasonId + "/matches/" + matchId + "/players/" + playerId + "/pass-statistics/",
      statisticData
    );
  }

  public deleteSeasonMatchPlayerPassStatistic(seasonId: number, matchId: number, playerId: number) {
    return this.__client.delete(
      this.__url + "/" + seasonId + "/matches/" + matchId + "/players/" + playerId + "/pass-statistics/"
    );
  }

  public getSeasonMatchPenaltyList(seasonId: number, matchId: number) {
    return this.__client.get(this.__url + "/" + seasonId + "/matches/" + matchId + "/penalties");
  }

  public addSeasonMatchPenalty(seasonId: number, matchId: number, penaltyData: IMatchPenaltyDTO) {
    return this.__client.post(this.__url + "/" + seasonId + "/matches/" + matchId + "/penalties", penaltyData);
  }

  public editSeasonMatchPenalty(seasonId: number, matchId: number, penaltyId: number, penaltyData: IMatchPenaltyDTO) {
    return this.__client.patch(
      this.__url + "/" + seasonId + "/matches/" + matchId + "/penalties/" + penaltyId,
      penaltyData
    );
  }

  public deleteSeasonMatchPenalty(seasonId: number, matchId: number, penaltyId: number) {
    return this.__client.delete(this.__url + "/" + seasonId + "/matches/" + matchId + "/penalties/" + penaltyId);
  }

  public getSeasonKnockoutList(seasonId: number): Promise<FootballKnockoutTree> {
    const seasonKnockoutListModel = new FootballKnockoutTree();
    return this.__client.get(this.__url + "/" + seasonId + "/knockouts").then((obj) => {
      return seasonKnockoutListModel.create(obj) as FootballKnockoutTree;
    });
  }

  public createSeasonKnockout(seasonId: number, knockoutInfo: IKnockoutDTO) {
    return this.__client.post(this.__url + "/" + seasonId + "/knockouts", knockoutInfo);
  }

  public editSeasonKnockout(seasonId: number, knockoutInfo: IKnockoutDTO) {
    return this.__client.patch(this.__url + "/" + seasonId + "/knockouts", knockoutInfo);
  }

  public editSeasonKnockoutMatch(seasonId: number, matchId: number, knockoutMatchInfo: IFootballMatchDTO) {
    return this.__client.patch(this.__url + "/" + seasonId + "/knockouts/" + matchId, knockoutMatchInfo);
  }
}
