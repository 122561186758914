import { useEffect } from "react";
import { AppBox, AppTitle } from "../../../commons/components";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import MatchCommentaryAdd from "./components/commentary/MatchCommentaryAdd";
import { fetchSeasonMatchCommentaryList } from "../../../redux/slices/football/admin/season/match/commentary/manage-season-match-commentary-list-slice";
import { StatusEnum } from "../../../commons/enums/status-enum";
import MatchCommentaryList from "./components/commentary/MatchCommentaryList";
import MatchPenaltyList from "./components/penalty/MatchPenaltyLIst";

export default function MatchDetailManageCommentary() {
  const dispatch = useAppDispatch();
  const { seasonMatchDetail, seasonMatchDetailStatus } = useAppSelector(
    (state) => state.footballManageSeasonMatchDetail
  );

  useEffect(() => {
    if (seasonMatchDetailStatus === StatusEnum.Succeeded) {
      dispatch(
        fetchSeasonMatchCommentaryList({ matchId: seasonMatchDetail.id, seasonId: seasonMatchDetail.season.id })
      );
    }
  }, [seasonMatchDetail]);

  return (
    <AppBox mb="sm" flexDirection="column" gap="sm">
      <AppBox flexDirection="column" gap="xs">
        <AppTitle as="h5">Add Commentary</AppTitle>
        <MatchCommentaryAdd match={seasonMatchDetail} />
      </AppBox>
      {seasonMatchDetail.penalties.length > 0 && <MatchPenaltyList match={seasonMatchDetail} />}
      <MatchCommentaryList />
    </AppBox>
  );
}
