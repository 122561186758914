import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../hooks/app";
import { login } from "../../../redux/slices/auth-slice";
import {
  AppActionIcon,
  AppBox,
  AppButton,
  AppGridBox,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../commons/components";
import { SurfaceColor } from "../../../commons/types/core-types";
import { ReactComponent as Logo } from "../../../assets/images/logo/logo-full.svg";
import IconEyeShow from "../../../commons/components/icons/eye-show";
import IconEyeHide from "../../../commons/components/icons/eye-hide";
import { FormProvider, useForm } from "react-hook-form";
import { findInputError, isFormInvalid } from "../../../commons/utilities/form-utils";
import { useSnackbar } from "../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../commons/components/interface";
import setDocumentTitle from "../../../commons/utilities/document-title";
import { appStorage } from "../../../commons";
import { toSentenceCase } from "../../../commons/utilities/string-utils";

const Login = () => {
  const dispatch = useAppDispatch();
  const methods = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const formErrors = methods.formState.errors;
  const addSnackbar = useSnackbar();
  const token = appStorage.getItem("authToken");

  const onLogin = () => {
    const result = dispatch(login({ email, password }))
      .unwrap()
      .then((e) => {
        addSnackbar({
          key: "login-success",
          text: "Login success",
          variant: "success",
        });
        if (!Object.hasOwn(result, "error")) {
          window.location.pathname = "manage";
        }
      })
      .catch((error: AxiosError<IServerErrorResponse>) => {
        const responseData = error.response?.data;
        if (error.response?.status === 417) {
          addSnackbar({
            key: "error",
            text: "Form not valid",
            variant: "danger",
          });
          if (responseData) {
            Object.entries(responseData).forEach(([field, messages]) => {
              messages.forEach((message: string) => {
                methods.setError(field, { message });
              });
            });
          }
        } else {
          addSnackbar({
            key: "error",
            text: responseData?.message,
            variant: "danger",
          });
        }
      });
    return false;
  };

  useEffect(() => {
    setDocumentTitle(["Login"]);
    if (token) {
      window.location.pathname = "manage";
    }
  }, []);

  return (
    <AppBox className="login">
      <AppGridBox gap="sm" columns={2} className="login-container">
        {/*logo*/}
        <AppBox px="3xl" className="login-logo-container">
          <AppBox alignItems="center" flexDirection="column" className="login-logo">
            {<Logo />}
            <AppText as="label" size="lg" fontWeight="bold">
              Your Ultimate Sports Management App
            </AppText>
          </AppBox>
        </AppBox>
        {/*login*/}
        <AppPaper bgColor={SurfaceColor.surface1} padding="xl" radius="md" shadow="xs">
          <AppBox flexDirection="column" gap="xl">
            <AppBox flexDirection="column">
              <AppTitle as="h3" textAlign="center">
                Welcome!
              </AppTitle>
              <AppText as="label" textAlign="center">
                Login to your account before we can get your day started.
              </AppText>
            </AppBox>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onLogin)} noValidate>
                <AppBox flexDirection="column" gap="sm">
                  <AppBox flexDirection="column" gap="2xs">
                    <AppText as="label">
                      <label htmlFor={"email"}>Email</label>
                    </AppText>
                    <input
                      id={"email"}
                      type="text"
                      className="ee-input"
                      placeholder="Enter your email"
                      {...methods.register("email", {
                        required: true,
                        pattern: {
                          value: /^\S+@\S+$/i,
                          message: "Invalid email format",
                        },
                        onChange(event) {
                          setEmail(event.target.value);
                        },
                      })}
                    />
                    {isFormInvalid(findInputError(formErrors, "email")) && (
                      <AppText as="span" color="danger">
                        <>{toSentenceCase(`${formErrors.email?.message ?? "Invalid email."}`)}</>
                      </AppText>
                    )}
                  </AppBox>
                  <AppBox flexDirection="column" gap="2xs">
                    <AppText as="label">
                      <label htmlFor={"password"}>Password</label>
                    </AppText>
                    <AppBox style={{ position: "relative" }}>
                      <input
                        id={"password"}
                        type={showPassword ? "text" : "password"}
                        className="ee-input login-input-password"
                        placeholder="Enter your password"
                        {...methods.register("password", {
                          required: true,
                          onChange(e) {
                            setPassword(e.target.value);
                          },
                        })}
                      />
                      <AppActionIcon
                        icon={showPassword ? <IconEyeHide /> : <IconEyeShow />}
                        color="gray"
                        variant="default"
                        className="login-svg"
                        onClick={() => setShowPassword(showPassword ? false : true)}
                      />
                    </AppBox>
                    {isFormInvalid(findInputError(formErrors, "password")) && (
                      <AppText as="span" color="danger">
                        <>{toSentenceCase(`${formErrors.password?.message ?? "Password is required."}`)}</>
                      </AppText>
                    )}
                  </AppBox>
                  <AppButton type="submit" label="Login" />
                </AppBox>
              </form>
            </FormProvider>
          </AppBox>
        </AppPaper>
      </AppGridBox>
    </AppBox>
  );
};

export default Login;
