import { AppBox, AppButton, AppDatePicker, AppGridBox, AppInput, AppText } from "../../../../../commons/components";
import { Controller, useForm } from "react-hook-form";
import { IFootballPlayerDetail } from "../../../../../commons/models/football/interface/i-football-player";
import { findInputError, isFormInvalid } from "../../../../../commons/utilities/form-utils";
import moment from "moment";
import { FieldErrors, FieldValues } from "react-hook-form";
import { IPlayerDTO } from "../../../../../api-services/football/admin/manage-football-player-service";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import { setStepperFormData } from "../../../../../redux/slices/stepper-form-slice";
import { formatDate } from "../../../../../commons/utilities/date-utils";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { toSentenceCase } from "../../../../../commons/utilities/string-utils";

interface PlayerBasicInfoAddEditProps {
  playerData?: IFootballPlayerDetail | null;
  formStep: number;
  formErrors: FieldErrors<FieldValues>;
  submitStatus: StatusEnum;
  onBack: (currentStep: number) => void;
  onNext: (currentStep: number) => void;
  onClearError: (errorKey: string) => void;
  onSaveAndExit: () => void;
}

const PlayerBasicInfoAddEdit = ({
  playerData,
  formStep,
  formErrors,
  submitStatus,
  onSaveAndExit,
  onBack,
  onNext,
  onClearError,
}: PlayerBasicInfoAddEditProps) => {
  const methods = useForm();
  const dispatch = useAppDispatch();
  const formData = useAppSelector<IPlayerDTO>((state) => state.stepperForm.stepperFormData);

  const handleInputChange = (value: string, key: string) => {
    const playerData: IPlayerDTO = {
      ...formData,
      [key]: value,
    };
    dispatch(setStepperFormData<IPlayerDTO>(playerData));
    onClearError(key);
  };
  const handleDateOfBirthChange = (value?: string) => {
    if (value) {
      const playerData: IPlayerDTO = {
        ...formData,
        dateOfBirth: formatDate(moment(value)),
      };
      dispatch(setStepperFormData<IPlayerDTO>(playerData));
      onClearError("dateOfBirth");
    }
  };
  return (
    <AppBox flexDirection="column" justifyContent="space-between" gap="sm" className="flex-1">
      <AppBox flexDirection="column" gap="sm" className="w-100">
        <Controller
          name="firstName"
          defaultValue={playerData ? playerData.firstName : ""}
          control={methods.control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                id="firstName"
                label="First Name *"
                placeholder="Enter player's first name"
                type="text"
                defaultValue={value}
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "firstName");
                }}
                flex="row"
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "firstName")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.firstName?.message ?? "First name is required."}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="middleName"
          defaultValue={playerData ? playerData.middleName : ""}
          control={methods.control}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                id="middleName"
                label="Middle Name"
                placeholder="Enter player's middle name"
                type="text"
                defaultValue={value}
                flex="row"
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "middleName");
                }}
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "middleName")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.middleName?.message && formErrors.middleName.message}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="lastName"
          defaultValue={playerData ? playerData.lastName : ""}
          control={methods.control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                id="lastName"
                label="Last Name *"
                placeholder="Enter player's last name"
                type="text"
                defaultValue={value}
                flex="row"
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "lastName");
                }}
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "lastName")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.lastName?.message ?? "Last name is required."}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="displayName"
          defaultValue={playerData ? playerData.displayName : ""}
          control={methods.control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                id="displayName"
                label="Display Name *"
                placeholder="Enter player's display name"
                type="text"
                defaultValue={value}
                flex="row"
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "displayName");
                }}
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "displayName")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.displayName?.message ?? "Display name is required."}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          control={methods.control}
          name="dateOfBirth"
          defaultValue={playerData?.dateOfBirth ? moment(playerData.dateOfBirth).toDate() : null}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column" gap="2xs">
              <AppGridBox className="w-100 flex-1" style={{ gridTemplateColumns: "1fr 2fr" }} gap="sm">
                <AppBox alignItems="center">
                  <AppText as="label" size="lg">
                    <label>Date of Birth</label>
                  </AppText>
                </AppBox>
                <AppDatePicker
                  onChange={(event) => {
                    onChange(event);
                    handleDateOfBirthChange(event?.toString());
                  }}
                  selected={value}
                  calenderPlacement="bottom-end"
                  maxDate={new Date()}
                />
              </AppGridBox>
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "dateOfBirth")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.dateOfBirth?.message ?? "Date of Birth is required."}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="birthPlace"
          defaultValue={playerData ? playerData.birthPlace : ""}
          control={methods.control}
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                id="birthPlace"
                label="Birthplace"
                placeholder="Enter player's birthplace"
                type="text"
                defaultValue={value}
                flex="row"
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "birthPlace");
                }}
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }}>
                {isFormInvalid(findInputError(formErrors, "birthPlace")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.birthPlace?.message ?? "Birth place is required."}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
      </AppBox>
      <AppBox gap="xs" justifyContent="end">
        <AppButton
          label="Back"
          variant="outline"
          color="gray"
          borderLight
          onClick={() => {
            onBack(formStep);
          }}
          disabled={submitStatus === StatusEnum.Loading}
        />
        {playerData && (
          <AppButton
            label="Save & Exit"
            variant="light"
            onClick={() => {
              onSaveAndExit();
            }}
            loading={submitStatus === StatusEnum.Loading}
          />
        )}
        <AppButton
          label="Next"
          onClick={() => {
            onNext(formStep);
          }}
          disabled={submitStatus === StatusEnum.Loading}
        />
      </AppBox>
    </AppBox>
  );
};

export default PlayerBasicInfoAddEdit;
