import {
  AppBox,
  AppButton,
  AppDatePicker,
  AppGridBox,
  AppInput,
  AppSelect,
  AppText,
} from "../../../../commons/components";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { ISelectOptions } from "../../../../commons/components/ui-components/Select";
import { PlayerPositionEnum } from "../../../../commons/enums/match-player-position-enum";
import { findInputError, isFormInvalid } from "../../../../commons/utilities/form-utils";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { formatDate } from "../../../../commons/utilities/date-utils";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { playerAdd } from "../../../../redux/slices/football/admin/player/manage-player-add-slice";
import { IPlayerDTO } from "../../../../api-services/football/admin/manage-football-player-service";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import moment from "moment";
import { ManageFootballPlayer } from "../../../../commons/models/football/admin/manage-football-player";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import { toSentenceCase } from "../../../../commons/utilities/string-utils";

interface PlayerAddFromSquadProps {
  onCancel: () => void;
  onSave: (player: ManageFootballPlayer) => void;
}

export default function PlayerAddFromSquad({ onCancel, onSave }: PlayerAddFromSquadProps) {
  const methods = useForm();
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const formErrors = methods.formState.errors;
  const { playerAddResponseStatus } = useAppSelector((state) => state.footballManagePlayerAdd);

  const playerPositionOptions: ISelectOptions[] = [
    {
      title: "Goalkeeper",
      value: PlayerPositionEnum.Goalkeeper,
    },
    {
      title: "Defender",
      value: PlayerPositionEnum.Defender,
    },
    {
      title: "Midfielder",
      value: PlayerPositionEnum.Midfielder,
    },
    {
      title: "Forward",
      value: PlayerPositionEnum.Forward,
    },
  ];

  const handleFormSubmit = (values: IPlayerDTO) => {
    const formattedValues: IPlayerDTO = {
      ...values,
      dateOfBirth: formatDate(values.dateOfBirth as string),
    };
    dispatch(playerAdd({ playerData: formattedValues }))
      .unwrap()
      .then((response) => {
        addSnackbar({
          key: "player-create-success",
          text: "Player Created Successfully",
          variant: "success",
        });
        onSave(new ManageFootballPlayer(response));
      })
      .catch((error: AxiosError<IServerErrorResponse>) => {
        const responseData = error.response?.data;
        if (error.response?.status === 417) {
          addSnackbar({
            key: "error",
            text: "Form not valid",
            variant: "danger",
          });
          if (responseData) {
            Object.entries(responseData).forEach(([field, messages]) => {
              messages.forEach((message: string) => {
                methods.setError(field, { message });
              });
            });
          }
        } else {
          addSnackbar({
            key: "error",
            text: responseData?.message,
            variant: "danger",
          });
        }
      });
  };

  return (
    <>
      <AppBox flexDirection="column" gap="xs">
        <FormProvider {...methods}>
          <form
            noValidate
            onSubmit={methods.handleSubmit((e) => {
              methods.formState.isValid && handleFormSubmit(e as IPlayerDTO);
            })}
          >
            <AppBox flexDirection="column" gap="sm">
              <AppBox flexDirection="column" gap="sm" pr="xs">
                <AppGridBox gap="sm" columns={2}>
                  <Controller
                    name="firstName"
                    defaultValue={null}
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column">
                        <AppInput
                          id="firstName"
                          label="First Name"
                          placeholder="Enter player's first name"
                          type="text"
                          defaultValue={value}
                          onChange={onChange}
                        />
                        {isFormInvalid(findInputError(formErrors, "firstName")) && (
                          <AppText as="span" color="danger">
                            <>{toSentenceCase(`${formErrors.firstName?.message ?? "First name is required."}`)}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                  <Controller
                    name="middleName"
                    defaultValue={null}
                    control={methods.control}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column">
                        <AppInput
                          id="middleName"
                          label="Middle Name"
                          placeholder="Enter player's middle name"
                          type="text"
                          defaultValue={value}
                          onChange={onChange}
                        />
                        {isFormInvalid(findInputError(formErrors, "middleName")) && (
                          <AppText as="span" color="danger">
                            <>{toSentenceCase(`${formErrors.middleName?.message && formErrors.middleName.message}`)}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                  <Controller
                    name="lastName"
                    defaultValue={null}
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column">
                        <AppInput
                          id="lastName"
                          label="Last Name"
                          placeholder="Enter player's last name"
                          type="text"
                          defaultValue={value}
                          onChange={onChange}
                        />
                        {isFormInvalid(findInputError(formErrors, "lastName")) && (
                          <AppText as="span" color="danger">
                            <>{toSentenceCase(`${formErrors.lastName?.message ?? "Last name is required."}`)}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                  <Controller
                    name="displayName"
                    defaultValue={null}
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column">
                        <AppInput
                          id="displayName"
                          label="Display Name"
                          placeholder="Enter player's display name"
                          type="text"
                          defaultValue={value}
                          onChange={onChange}
                        />
                        {isFormInvalid(findInputError(formErrors, "displayName")) && (
                          <AppText as="span" color="danger">
                            <>{toSentenceCase(`${formErrors.displayName?.message ?? "Display name is required."}`)}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                  <Controller
                    control={methods.control}
                    name="dateOfBirth"
                    defaultValue={moment().toDate()}
                    render={({ field: { onChange, value } }) => (
                      <AppBox flexDirection="column" gap="2xs">
                        <AppText as="label" size="lg">
                          <label>Date of Birth</label>
                        </AppText>
                        <AppDatePicker
                          onChange={onChange}
                          selected={value}
                          calenderPlacement="bottom-end"
                          maxDate={new Date()}
                        />
                        {isFormInvalid(findInputError(formErrors, "dateOfBirth")) && (
                          <AppText as="span" color="danger">
                            <>{toSentenceCase(`${formErrors.dateOfBirth?.message ?? "Date of Birth is required."}`)}</>
                          </AppText>
                        )}
                      </AppBox>
                    )}
                  />
                  <Controller
                    name="position"
                    defaultValue={playerPositionOptions[0].value}
                    control={methods.control}
                    render={({ field: { onChange } }) => (
                      <AppBox flexDirection="column" gap="2xs">
                        <AppText as="label" size="lg">
                          <label>Position</label>
                        </AppText>
                        <AppSelect
                          options={playerPositionOptions}
                          onChange={(option) => {
                            onChange(option.value);
                          }}
                        />
                      </AppBox>
                    )}
                  />
                </AppGridBox>
              </AppBox>
              <AppBox justifyContent="end" gap="xs">
                <AppButton
                  label="Cancel"
                  variant="outline"
                  color="gray"
                  borderLight
                  onClick={onCancel}
                  disabled={playerAddResponseStatus === StatusEnum.Loading}
                />
                <AppButton
                  type="submit"
                  disabled={!methods.formState.isValid}
                  label={"Add Player"}
                  loading={playerAddResponseStatus === StatusEnum.Loading}
                />
              </AppBox>
            </AppBox>
          </form>
        </FormProvider>
      </AppBox>
    </>
  );
}
