import { AppAvatar, AppBox, AppPaper, AppText, AppTitle } from "../../../../commons/components";

import AppBorderBox from "../../../../commons/components/BorderBox";
import IllustrationBadge from "../../../../commons/components/illustrations/illustrationBadge";
import { IFootballAward } from "../../../../commons/models/football/interface/i-football-award";
import { SurfaceColor } from "../../../../commons/types/core-types";
import { formatDate } from "../../../../commons/utilities/date-utils";
import { toSentenceCase } from "../../../../commons/utilities/string-utils";

interface TournamentAwardCardProps {
  award: IFootballAward;
}

export default function TournamentAwardCard({ award }: TournamentAwardCardProps) {
  return (
    <AppPaper className="best-player-card" padding="xs" shadow="xs">
      <AppBorderBox border={["Bottom"]} pb="2xs">
        <AppBox justifyContent="space-between">
          <AppBox gap="2xs" alignItems="center">
            {award.player && (
              <AppAvatar size="md" src={award.player?.avatar || ""} username={award.player.displayName} />
            )}
            <AppBox flexDirection="column">
              <AppTitle as="h6">{award.player?.displayName}</AppTitle>
              {award.position && (
                <AppText as="label" size="sm">
                  {toSentenceCase(award.position)}
                </AppText>
              )}
            </AppBox>
          </AppBox>
          {award.team && <AppAvatar size="xs" src={award.team?.logo || ""} username={award.team?.shortName} />}
        </AppBox>
      </AppBorderBox>
      <AppBox justifyContent="center" gap="2xs" py="2xs">
        <AppText>Date: {formatDate(award.receivedDate, "DD MMM, YYYY")}</AppText>
      </AppBox>
      <AppPaper radius="sm" bgColor={SurfaceColor.surface3} padding="none">
        <AppBox p="2xs" gap="3xs" justifyContent="center" alignItems="center" flexDirection="row">
          <AppBox>
            <IllustrationBadge width="24" height="24" />
          </AppBox>
          <AppTitle as="h6" color="accent" lineClamp={1}>
            {award.title}
          </AppTitle>
        </AppBox>
      </AppPaper>
    </AppPaper>
  );
}
