import { AppBox, AppText, AppTitle } from "../../../commons/components";
import { useAppSelector } from "../../../hooks/app";
import { TournamentStandingBracket } from "./components/TournamentStandingBracket";

export default function TournamentBrackets() {
  const { selectedGlobalSeasonOption } = useAppSelector((state) => state.globalSeasonSelect);
  const { seasonList } = useAppSelector((state) => state.footballSeason);

  return (
    <AppBox flexDirection="column" gap="sm">
      {seasonList.length > 0 ? (
        <>
          {selectedGlobalSeasonOption.value && (
            <AppBox className="w-100" flexDirection="column">
              <TournamentStandingBracket currentSeasonId={Number(selectedGlobalSeasonOption.value)} />
            </AppBox>
          )}
        </>
      ) : (
        <AppText>The season has either ended or not yet started.</AppText>
      )}
    </AppBox>
  );
}
