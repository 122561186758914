import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { AppBox } from "../../../commons/components";
import { fetchSeasonKnockoutList } from "../../../redux/slices/football/public/season/football-season-knockout-list-slice";
import { StandingBracket } from "../../../commons/components/ui-components/StandingBracket";

export const MatchBrackets = () => {
  const dispatch = useAppDispatch();
  const { match } = useAppSelector((state) => state.footballMatchDetail);
  const { seasonKnockoutList } = useAppSelector((state) => state.footballSeasonKnockoutList);

  useEffect(() => {
    if (match.season) {
      dispatch(fetchSeasonKnockoutList({ seasonId: match.season.id }));
    }
  }, [match]);

  return (
    <AppBox gap="sm" flexDirection="column">
      <StandingBracket knockoutTree={seasonKnockoutList} competition={match.competition} />
    </AppBox>
  );
};
