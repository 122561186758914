import {
  AppAvatar,
  AppBox,
  AppDivider,
  AppIconButton,
  AppLink,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import IconEdit from "../../../../commons/components/icons/edit";
import IconPersonDelete from "../../../../commons/components/icons/person-delete";
import AppLineUpBadge from "../../../../commons/components/ui-components/LineupBadge";
import { IFootballTeamPlayer } from "../../../../commons/models/football/interface/i-football-team-player";
import { toSentenceCase, toTitleCase } from "../../../../commons/utilities/string-utils";

interface PlayerCardProps {
  playerInfo: IFootballTeamPlayer;
  onPlayerRemove: (player: IFootballTeamPlayer) => void;
  onPlayerEdit: (player: IFootballTeamPlayer) => void;
}

export default function TeamPlayerCard({ playerInfo, onPlayerRemove, onPlayerEdit }: PlayerCardProps) {
  const playerPath = `/manage/players/${playerInfo.player.id}`;
  return (
    <AppPaper padding="xs" shadow="xs">
      <AppBox justifyContent="space-between" alignItems="center">
        <AppLink to={playerPath}>
          <AppBox gap="xs" alignItems="center">
            <AppAvatar username={playerInfo.player?.fullName || ""} src={playerInfo.player?.avatar || ""} size="lg" />
            <AppBox flexDirection="column">
              <AppBox alignItems="center" gap="2xs">
                <AppTitle as="h6">{toTitleCase(playerInfo.player?.displayName)}</AppTitle>
                {playerInfo.isCaptain && <AppLineUpBadge status="CAPTAIN" size={20} />}
              </AppBox>
              <AppBox>
                <AppText as="span" fontWeight="semibold" size="sm" color="muted">
                  {playerInfo.jerseyNumber}
                </AppText>
                <AppDivider orientation="vertical" mx="3xs" color="dark" />
                <AppText as="span" fontWeight="semibold" size="sm" color="muted">
                  {toSentenceCase(playerInfo.position)}
                </AppText>
              </AppBox>
            </AppBox>
          </AppBox>
        </AppLink>
        <AppBox gap="xs">
          <AppIconButton
            variant="light"
            onClick={() => {
              onPlayerEdit(playerInfo);
            }}
            icon={<IconEdit />}
          />
          <AppIconButton
            variant="light"
            color="danger"
            onClick={() => {
              onPlayerRemove?.(playerInfo);
            }}
            icon={<IconPersonDelete />}
          />
        </AppBox>
      </AppBox>
    </AppPaper>
  );
}
