import { useEffect, useRef, useState } from "react";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppDivider,
  AppGeneralSearch,
  AppIconButton,
  AppLink,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import { ManageFootballPlayer } from "../../../../commons/models/football/admin/manage-football-player";
import { toSentenceCase, toTitleCase } from "../../../../commons/utilities/string-utils";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { PlayerStatusEnum } from "../../../../commons/enums/player-status-enum";
import { IFootballTeam } from "../../../../commons/models/football/interface/i-football-team";
import { FootballTeamPlayer } from "../../../../commons/models/football/football-team-player";
import {
  cleanUpManageTeamPlayers,
  fetchManageTeamPlayerList,
} from "../../../../redux/slices/football/admin/team/manage-team-players-slice";
import IconMultiselect from "../../../../commons/components/icons/multiselect";
import isNullOrUndefined from "../../../../commons/utilities/null-or-undefined";
import {
  cleanUpPlayersNotInTeam,
  fetchManagePlayersNotInTeam,
} from "../../../../redux/slices/football/admin/player/manage-players-not-in-team-slice";
import { cleanUpGeneralSearchTitle, setGeneralCleanSearchField } from "../../../../redux/slices/general-search-slice";

interface PlayerSearchProps {
  onSelectedPlayer?: (players: ManageFootballPlayer[]) => void;
  onSelectedTeamPlayer?: (teamPlayers: FootballTeamPlayer[]) => void;
  withMultiSelect?: boolean;
  team?: IFootballTeam;
  userSelectedPlayers?: ManageFootballPlayer[];
  userSelectedTeamPlayers?: FootballTeamPlayer[];
}
export default function PlayerSearch({
  onSelectedPlayer,
  onSelectedTeamPlayer,
  team,
  withMultiSelect,
  userSelectedPlayers,
  userSelectedTeamPlayers,
}: PlayerSearchProps) {
  const dispatch = useAppDispatch();
  const multiSelectRef = useRef<HTMLInputElement>(null);
  const [isMultiSelect, setMultiSelect] = useState(false);
  const [isUncheckAll, setUncheckAll] = useState(false);
  const [selectedTeamPlayers, setSelectedTeamPlayers] = useState<FootballTeamPlayer[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<ManageFootballPlayer[]>([]);
  const [filteredTeamPlayers, setFilteredTeamPlayers] = useState<FootballTeamPlayer[]>([]);
  const [filteredPlayers, setFilteredPlayers] = useState<ManageFootballPlayer[]>([]);
  const { playersNotInTeam } = useAppSelector((state) => state.footballManagePlayersNotInTeam);
  const { manageTeamPlayersList } = useAppSelector((state) => state.footballManageTeamPlayers);
  const { generalSearchTitle } = useAppSelector((state) => state.generalSearch);

  useEffect(() => {
    dispatch(setGeneralCleanSearchField(true));
    dispatch(cleanUpGeneralSearchTitle());
  }, []);

  useEffect(() => {
    const filterParams = {
      sort: "firstName",
      name: generalSearchTitle,
      status: PlayerStatusEnum.Active,
      page: 0,
    };
    if (team) {
      dispatch(cleanUpManageTeamPlayers());
      dispatch(
        fetchManageTeamPlayerList({
          teamId: team.id,
          urlParams: { sort: "position", name: generalSearchTitle },
        })
      );
    } else {
      dispatch(cleanUpPlayersNotInTeam());
      dispatch(fetchManagePlayersNotInTeam(filterParams));
    }
  }, [generalSearchTitle]);

  useEffect(() => {
    const selectedIds = new Set();
    if (userSelectedPlayers && userSelectedPlayers.length > 0) {
      userSelectedPlayers.forEach((player) => selectedIds.add(player.id));
    }
    if (userSelectedTeamPlayers && userSelectedTeamPlayers.length > 0) {
      userSelectedTeamPlayers.forEach((teamPlayer) => selectedIds.add(teamPlayer.player.id));
    }
    if (playersNotInTeam.length > 0) {
      let filteredPlayers = [];
      if (selectedIds) {
        filteredPlayers = playersNotInTeam.all().filter((player) => !selectedIds.has(player.id));
      } else {
        filteredPlayers = playersNotInTeam.all();
      }
      setFilteredPlayers(filteredPlayers);
    } else {
      setFilteredPlayers([]);
    }
    if (manageTeamPlayersList.length > 0) {
      let filteredPlayers = [];
      if (selectedIds) {
        filteredPlayers = manageTeamPlayersList.all().filter((teamPlayer) => !selectedIds.has(teamPlayer.player.id));
      } else {
        filteredPlayers = manageTeamPlayersList.all();
      }
      const customOrder = ["GOALKEEPER", "DEFENDER", "MIDFIELDER", "FORWARD"];
      let sortedData = filteredPlayers.sort((a, b) => {
        return customOrder.indexOf(a.position as string) - customOrder.indexOf(b.position as string);
      });
      setFilteredTeamPlayers(sortedData);
    } else {
      setFilteredTeamPlayers([]);
    }
  }, [playersNotInTeam, manageTeamPlayersList, userSelectedPlayers, userSelectedTeamPlayers]);

  useEffect(() => {
    if (isUncheckAll) {
      setSelectedTeamPlayers([]);
      setSelectedPlayers([]);
      setUncheckAll(false);
    }
  }, [isUncheckAll]);

  const handlePlayerClick = (event: Event, player: ManageFootballPlayer | FootballTeamPlayer) => {
    event.preventDefault();
    if (player instanceof ManageFootballPlayer) {
      if (onSelectedPlayer) {
        onSelectedPlayer([player]);
      }
    } else {
      if (onSelectedTeamPlayer) {
        onSelectedTeamPlayer([player]);
      }
    }
  };

  const handleMultiSelectClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    multiSelectRef.current?.click();
  };

  const handleTeamSelection = (e: any, player: ManageFootballPlayer | FootballTeamPlayer) => {
    if (e.target.checked) {
      if (player instanceof ManageFootballPlayer) {
        setSelectedPlayers([...selectedPlayers, player]);
      } else {
        setSelectedTeamPlayers([...selectedTeamPlayers, player]);
      }
    } else {
      if (player instanceof ManageFootballPlayer) {
        setSelectedPlayers(selectedPlayers.filter((t) => t.id !== player.id));
      } else {
        setSelectedTeamPlayers(selectedTeamPlayers.filter((t) => t.id !== player.id));
      }
    }
  };

  const handleSavePlayers = () => {
    if (onSelectedPlayer) {
      onSelectedPlayer(selectedPlayers);
    }
    if (onSelectedTeamPlayer) {
      onSelectedTeamPlayer(selectedTeamPlayers);
    }
  };

  return (
    <AppBox flexDirection="column" gap="md">
      <AppBox flexDirection="column" gap="sm">
        <AppBox gap="xs">
          <AppBox className="w-100 flex-1">
            <AppGeneralSearch />
          </AppBox>
          {withMultiSelect && (
            <>
              <AppIconButton
                icon={<IconMultiselect />}
                variant="light"
                onClick={(e) => handleMultiSelectClick(e)}
                color={isMultiSelect ? "primary" : "gray"}
                borderLight
              />
              <input
                type="checkbox"
                hidden
                ref={multiSelectRef}
                checked={isMultiSelect}
                onChange={(e) => {
                  setMultiSelect(e.target.checked);
                  setSelectedTeamPlayers([]);
                  setSelectedPlayers([]);
                }}
              />
            </>
          )}
        </AppBox>
        <AppBox flexDirection="column" style={{ height: "30rem", overflowY: "scroll" }}>
          {isNullOrUndefined(team) ? (
            <>
              {filteredPlayers &&
                filteredPlayers.map((player, index) => (
                  <AppBorderBox border={["Bottom"]} py="xs" key={index}>
                    {isMultiSelect ? (
                      <AppBox justifyContent="space-between" alignItems="center" pr="xs" className="flex-1">
                        <AppBox justifyContent="space-between" alignItems="center" className="flex-1">
                          <AppBox gap="xs" alignItems="center">
                            <AppAvatar username={player.fullName || ""} src={player?.avatar} size="md" />
                            <AppBox flexDirection="column">
                              <AppTitle as="h6">{toTitleCase(player?.displayName)}</AppTitle>
                              {player.position && (
                                <AppText as="span" fontWeight="semibold" size="sm">
                                  {toSentenceCase(player?.position)}
                                </AppText>
                              )}
                            </AppBox>
                          </AppBox>
                        </AppBox>
                        <label className="ee-checkbox">
                          <input
                            type="checkbox"
                            checked={selectedPlayers.includes(player)}
                            onChange={(e) => {
                              handleTeamSelection(e, player);
                            }}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </AppBox>
                    ) : (
                      <AppLink onClick={(e) => handlePlayerClick(e, player)}>
                        <AppBox justifyContent="space-between" alignItems="center" className="flex-1">
                          <AppBox gap="xs" alignItems="center">
                            <AppAvatar username={player.fullName || ""} src={player?.avatar} size="md" />
                            <AppBox flexDirection="column">
                              <AppTitle as="h6">{toTitleCase(player?.displayName)}</AppTitle>
                              {player.position && (
                                <AppText as="span" fontWeight="semibold" size="sm">
                                  {toSentenceCase(player?.position)}
                                </AppText>
                              )}
                            </AppBox>
                          </AppBox>
                        </AppBox>
                      </AppLink>
                    )}
                  </AppBorderBox>
                ))}
              {filteredPlayers.length === 0 && <AppText>There are not any players</AppText>}
            </>
          ) : (
            <>
              {filteredTeamPlayers &&
                filteredTeamPlayers.map((teamPlayer, index) => (
                  <AppBorderBox border={["Bottom"]} py="xs" key={index}>
                    {isMultiSelect ? (
                      <AppBox justifyContent="space-between" alignItems="center" pr="xs" className="flex-1">
                        <AppBox justifyContent="space-between" alignItems="center" className="flex-1">
                          <AppBox gap="xs" alignItems="center">
                            <AppAvatar
                              username={teamPlayer.player?.fullName ?? ""}
                              src={teamPlayer.player?.avatar || ""}
                              size="md"
                            />
                            <AppBox flexDirection="column">
                              <AppTitle as="h6">{teamPlayer.player?.displayName}</AppTitle>
                              {teamPlayer?.position && (
                                <AppText as="span" fontWeight="semibold" size="sm">
                                  {toSentenceCase(teamPlayer?.position)}
                                </AppText>
                              )}
                            </AppBox>
                          </AppBox>
                        </AppBox>
                        <label className="ee-checkbox">
                          <input
                            type="checkbox"
                            checked={selectedTeamPlayers.includes(teamPlayer)}
                            onChange={(e) => {
                              handleTeamSelection(e, teamPlayer);
                            }}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </AppBox>
                    ) : (
                      <AppLink onClick={(e) => handlePlayerClick(e, teamPlayer)}>
                        <AppBox justifyContent="space-between" alignItems="center" className="flex-1">
                          <AppBox gap="xs" alignItems="center">
                            <AppAvatar
                              username={teamPlayer.player?.fullName || ""}
                              src={teamPlayer.player?.avatar || ""}
                              size="md"
                            />
                            <AppBox flexDirection="column">
                              <AppTitle as="h6">{teamPlayer.player?.displayName}</AppTitle>
                              <AppBox gap="3xs">
                                <AppText as="span" fontWeight="semibold" size="sm">
                                  {teamPlayer?.jerseyNumber}
                                </AppText>
                                <AppDivider color="dark" orientation="vertical" />

                                <AppText as="span" fontWeight="semibold" size="sm">
                                  {toSentenceCase(teamPlayer?.position)}
                                </AppText>
                              </AppBox>
                            </AppBox>
                          </AppBox>
                        </AppBox>
                      </AppLink>
                    )}
                  </AppBorderBox>
                ))}
              {filteredTeamPlayers.length === 0 && <AppText>There are not any players</AppText>}
            </>
          )}
        </AppBox>
      </AppBox>
      {isMultiSelect && (
        <AppBox justifyContent="end" gap="xs">
          <AppButton variant="outline" borderLight label="Clear" color="gray" onClick={() => setUncheckAll(true)} />
          <AppButton label={"Add Players"} onClick={handleSavePlayers} />
        </AppBox>
      )}
    </AppBox>
  );
}
