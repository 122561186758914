import { useEffect, useLayoutEffect, useState } from "react";
import { Controller, FieldErrors, FieldValues, useForm, UseFormReturn } from "react-hook-form";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppDatePicker,
  AppGridBox,
  AppIconButton,
  AppInput,
  AppModal,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import IconPersonAvailable from "../../../../commons/components/icons/person-available";
import PlayerSearch from "./PlayerSearch";
import IconPersonAdd from "../../../../commons/components/icons/person-add";
import PlayerAddFromSquad from "./PlayerAddFromSquad";
import { ManageFootballPlayer } from "../../../../commons/models/football/admin/manage-football-player";
import { IFootballTeamDetail } from "../../../../commons/models/football/interface/i-football-team";
import AppBorderBox from "../../../../commons/components/BorderBox";
import { ITeamMultiplePlayerDTO } from "../../../../api-services/football/admin/manage-football-team-service";
import IconBin from "../../../../commons/components/icons/bin";
import { findInputError, isFormInvalid } from "../../../../commons/utilities/form-utils";
import moment from "moment";
import { toSentenceCase, toTitleCase } from "../../../../commons/utilities/string-utils";
import { PlayerRoleSelector } from "./PlayerRoleSelector";
import { formatDate } from "../../../../commons/utilities/date-utils";
import setDocumentTitle from "../../../../commons/utilities/document-title";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { teamMultiplePlayersAdd } from "../../../../redux/slices/football/admin/team/manage-team-player-add-edit-slice";
import {
  fetchManageTeamPlayerList,
  IManageTeamPlayersOption,
} from "../../../../redux/slices/football/admin/team/manage-team-players-slice";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { StatusEnum } from "../../../../commons/enums/status-enum";

interface MultipleTeamPlayerAdd {
  team: IFootballTeamDetail;
  onCancel: () => void;
  onAdd: () => void;
}

const MultipleTeamPlayerAdd = ({ team, onAdd, onCancel }: MultipleTeamPlayerAdd) => {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const methods = useForm();
  const formErrors = methods.formState.errors;
  const [selectedPlayers, setSelectedPlayers] = useState<ManageFootballPlayer[]>([]);
  const [isPlayerSearch, setPlayerSearch] = useState(false);
  const [isPlayerCreate, setPlayerCreate] = useState(false);
  const [teamMultiplePlayerAddData, setTeamMultiplePlayerAddData] = useState<ITeamMultiplePlayerDTO[]>([]);
  const { manageTeamMultiplePlayersAddStatus } = useAppSelector((state) => state.footballManageTeamPlayerAddEdit);

  useEffect(() => {
    setDocumentTitle([`${toTitleCase(team.shortName)}`, "Add Players", "Manage"]);
  }, [team]);

  const handlePlayerCreate = (player: ManageFootballPlayer) => {
    let players = selectedPlayers;
    players.push(player);
    setSelectedPlayers(players);
    setPlayerCreate(false);
  };

  const handlePlayerSelect = (players: ManageFootballPlayer[]) => {
    let previousPlayers = selectedPlayers;
    for (const player of players) {
      previousPlayers.push(player);
    }
    setSelectedPlayers(previousPlayers);
    setPlayerSearch(false);
  };

  const handelRemovePlayer = (removePlayer: ManageFootballPlayer) => {
    let filteredPlayers = selectedPlayers.filter((player) => player.id !== removePlayer.id);
    let filteredMultiplePlayerAddData = teamMultiplePlayerAddData.filter((player) => player.id !== removePlayer.id);
    setTeamMultiplePlayerAddData(filteredMultiplePlayerAddData);
    setSelectedPlayers(filteredPlayers);
  };

  const handleMultiplePlayerDataChange = (teamPlayer: ITeamMultiplePlayerDTO) => {
    let teamMultiplePlayerData = teamMultiplePlayerAddData;
    const existingPlayerIndex = teamMultiplePlayerData.findIndex((player) => player.id === teamPlayer.id);
    if (existingPlayerIndex !== -1) {
      teamMultiplePlayerData[existingPlayerIndex] = teamPlayer;
    } else {
      teamMultiplePlayerData.push(teamPlayer);
    }
    setTeamMultiplePlayerAddData(teamMultiplePlayerData);
  };

  const clearErrorField = (key: string) => {
    methods.clearErrors(key);
  };

  const handleDistinctJerseyNumber = (errorKey: string, value: number) => {
    let jerseyNumbers = [];
    for (const player of [...teamMultiplePlayerAddData]) {
      jerseyNumbers.push(player.jerseyNumber);
    }
    if (value !== 0 && jerseyNumbers.includes(value)) {
      methods.setError(errorKey, { message: "jerseyNumber should be distinct." });
    }
  };

  const handleFormSubmit = () => {
    if (team && teamMultiplePlayerAddData.length > 0) {
      dispatch(
        teamMultiplePlayersAdd({
          teamId: team.id,
          playersInfo: teamMultiplePlayerAddData,
        })
      )
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "team-player-add-success",
            text: "Team Player(s) Added Successfully",
            variant: "success",
          });
          const playerListOption: IManageTeamPlayersOption = {
            teamId: team.id,
          };
          dispatch(fetchManageTeamPlayerList(playerListOption));
          onAdd();
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          const responseData = error.response?.data;
          if (error.response?.status === 417) {
            addSnackbar({
              key: "error",
              text: "Form not valid",
              variant: "danger",
            });
            if (responseData) {
              Object.entries(responseData).forEach(([parentField, nestedErrors]) => {
                if (typeof nestedErrors === "object" && nestedErrors !== null) {
                  Object.entries(nestedErrors).forEach(([field, messages]) => {
                    if (Array.isArray(messages)) {
                      messages.forEach((message: string) => {
                        const errorKey = `${parentField}-${teamMultiplePlayerAddData[Number(field)]}`;
                        methods.setError(errorKey, { message });
                      });
                    } else if (typeof messages === "object" && messages !== null) {
                      Object.entries(messages).forEach(([subField, subMessages]) => {
                        if (Array.isArray(subMessages)) {
                          subMessages.forEach((message: string) => {
                            const errorKey = `${parentField}-${
                              teamMultiplePlayerAddData[Number(field)].id
                            }-${subField}`;
                            methods.setError(errorKey, { message });
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          } else {
            addSnackbar({
              key: "error",
              text: responseData?.message,
              variant: "danger",
            });
          }
        });
    }
  };

  return (
    <>
      <AppBox flexDirection="column" className="w-100 flex-1" gap="md">
        <AppBox flexDirection="column" className="w-100 flex-1" gap="sm" pr="2xs" pb="2xs" style={{ height: "30rem" }}>
          {selectedPlayers.length > 0 ? (
            <AppBox flexDirection="column" className="flex-1 w-100" gap="xs">
              <AppBox justifyContent="end" gap="xs">
                <AppButton
                  label="Create Player"
                  variant="light"
                  onClick={(e) => {
                    e.preventDefault();
                    setPlayerCreate(true);
                  }}
                />
                <AppButton
                  label="Choose Player"
                  onClick={(e) => {
                    e.preventDefault();
                    setPlayerSearch(true);
                  }}
                />
              </AppBox>
              <AppBorderBox border={["Bottom"]} pb="2xs" className="w-100">
                <AppGridBox
                  style={{ gridTemplateColumns: "2.25fr 1fr 1.5fr 1fr 0.5fr" }}
                  className="w-100"
                  gap="xs"
                  pr="2xs"
                >
                  <AppTitle as="h6">Player</AppTitle>
                  <AppTitle as="h6" textAlign="center">
                    Jersey Number
                  </AppTitle>
                  <AppTitle as="h6" textAlign="center">
                    Playing Roles
                  </AppTitle>
                  <AppTitle as="h6" textAlign="center">
                    Start Date
                  </AppTitle>
                </AppGridBox>
              </AppBorderBox>
              <AppBox style={{ overflowY: "scroll", height: "24rem" }}>
                <AppBox flexDirection="column" className="w-100" gap="xs">
                  {selectedPlayers.map((player) => (
                    <AppBorderBox border={["Bottom"]} pb="xs" className="w-100" key={player.id}>
                      <TeamPlayerAddComponent
                        onTeamPlayerDataChange={handleMultiplePlayerDataChange}
                        player={player}
                        team={team}
                        formErrors={formErrors}
                        onRemove={handelRemovePlayer}
                        onClearError={clearErrorField}
                        onCheckDistinctValue={handleDistinctJerseyNumber}
                      />
                    </AppBorderBox>
                  ))}
                </AppBox>
              </AppBox>
            </AppBox>
          ) : (
            <AppBox
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              gap="sm"
              className="w-100 flex-1"
            >
              <AppBox flexDirection="column" alignItems="center" gap="xs">
                <IconPersonAvailable height={40} width={40} color="rgba(var(--border-200))" />
                <AppText>Choose or create a player you wish to add to your team.</AppText>
              </AppBox>
              <AppBox gap="sm">
                <AppButton
                  label="Create Player"
                  variant="light"
                  onClick={(e) => {
                    e.preventDefault();
                    setPlayerCreate(true);
                  }}
                />
                <AppButton
                  label="Choose Player"
                  onClick={(e) => {
                    e.preventDefault();
                    setPlayerSearch(true);
                  }}
                />
              </AppBox>
            </AppBox>
          )}
        </AppBox>
        <AppBox justifyContent="end" gap="xs">
          <AppButton label="Cancel" variant="outline" borderLight color="gray" onClick={onCancel} />
          {selectedPlayers.length > 0 && (
            <AppBox justifyContent="end" gap="xs">
              <AppButton
                label={"Add"}
                loading={manageTeamMultiplePlayersAddStatus === StatusEnum.Loading}
                onClick={handleFormSubmit}
              />
            </AppBox>
          )}
        </AppBox>
      </AppBox>
      <AppModal
        titleIcon={<IconPersonAvailable />}
        title="Choose A Player"
        opened={isPlayerSearch}
        onClose={(e) => {
          setPlayerSearch(false);
        }}
        withoutButtons
      >
        <PlayerSearch onSelectedPlayer={handlePlayerSelect} withMultiSelect userSelectedPlayers={selectedPlayers} />
      </AppModal>
      <AppModal
        titleIcon={<IconPersonAdd />}
        title="Create A Player"
        opened={isPlayerCreate}
        onClose={(e) => {
          setPlayerCreate(false);
        }}
        withoutButtons
      >
        <PlayerAddFromSquad
          onCancel={() => {
            setPlayerCreate(false);
          }}
          onSave={handlePlayerCreate}
        />
      </AppModal>
    </>
  );
};

export default MultipleTeamPlayerAdd;

interface TeamPlayerAddComponentProps {
  team: IFootballTeamDetail;
  player: ManageFootballPlayer;
  formErrors: FieldErrors<FieldValues>;
  onClearError: (errorKey: string) => void;
  onCheckDistinctValue: (errorKey: string, value: number) => void;
  onTeamPlayerDataChange: (teamPlayer: ITeamMultiplePlayerDTO) => void;
  onRemove: (player: ManageFootballPlayer) => void;
}

const TeamPlayerAddComponent = ({
  player,
  team,
  formErrors,
  onClearError,
  onTeamPlayerDataChange,
  onCheckDistinctValue,
  onRemove,
}: TeamPlayerAddComponentProps) => {
  const [minDate, setMinDate] = useState<Date>();
  const [teamPlayerInfo, setTeamPlayerInfo] = useState<ITeamMultiplePlayerDTO>();
  const methods = useForm();

  useLayoutEffect(() => {
    if (player) {
      const playerDateOfBirth = moment(player.dateOfBirth).toDate();
      const teamFounded = moment(team.founded).toDate();
      const latestDate = playerDateOfBirth > teamFounded ? playerDateOfBirth : teamFounded;
      setMinDate(latestDate);
      let initialTeamPlayerInfo = {
        id: player.id,
        jerseyNumber: 0,
        startAt: formatDate(moment(), "YYYY-MM-DD HH:mm:00"),
        isCaptain: false,
        playingRoles: "",
        teamId: team.id,
      };
      setTeamPlayerInfo(initialTeamPlayerInfo);
    }
  }, [player]);

  const handleInputChange = (value: string, key: string) => {
    if (teamPlayerInfo) {
      const teamPlayerData: ITeamMultiplePlayerDTO = {
        ...teamPlayerInfo,
        [key]: key === "jerseyNumber" ? Number(value) : value,
      };

      setTeamPlayerInfo(teamPlayerData);
      onClearError(`players-${player.id}-${key}`);
      if (key === "jerseyNumber") {
        onCheckDistinctValue(`players-${player.id}-${key}`, Number(value));
      }
    }
  };
  const handleDateChange = (value?: string) => {
    if (value && teamPlayerInfo) {
      const teamPlayerData: ITeamMultiplePlayerDTO = {
        ...teamPlayerInfo,
        startAt: formatDate(moment(value), "YYYY-MM-DD HH:mm:00"),
      };
      setTeamPlayerInfo(teamPlayerData);
      onClearError(`players-${player.id}-startAt`);
    }
  };

  useEffect(() => {
    if (teamPlayerInfo) {
      onTeamPlayerDataChange(teamPlayerInfo);
    }
  }, [teamPlayerInfo]);

  const handlePlayerRemove = () => {
    onClearError(`players-${player.id}-jerseyNumber`);
    onClearError(`players-${player.id}-playingRoles`);
    onClearError(`players-${player.id}-startAt`);
    onRemove(player);
  };

  return (
    <AppBox className="w-100">
      <AppGridBox style={{ gridTemplateColumns: "2.25fr 1fr 1.5fr 1fr 0.5fr" }} className="w-100 flex-1" gap="xs">
        <Controller
          control={methods.control}
          name="id"
          render={() => (
            <AppBox gap="xs" alignItems="start">
              <AppBox gap="xs" alignItems="center">
                <AppAvatar username={player.fullName as string} src={player.avatar} size="sm" />
                <AppBox flexDirection="column">
                  <AppText fontWeight="semibold">{player.displayName}</AppText>
                  <AppText as="span" fontWeight="light" size="sm">
                    {toSentenceCase(player.position)}
                  </AppText>
                </AppBox>
              </AppBox>
            </AppBox>
          )}
        />
        <Controller
          name="jerseyNumber"
          control={methods.control}
          rules={{ required: true, pattern: /^\d{1,2}$/ }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column" alignItems="center" justifyContent="center">
              <AppInput
                defaultValue={value}
                id="number"
                placeholder="Enter number"
                type="number"
                minValue={0}
                maxValue={99}
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "jerseyNumber");
                }}
                withoutLabel
              />
              {isFormInvalid(findInputError(formErrors, `players-${player.id}-jerseyNumber`)) && (
                <AppText as="span" color="danger">
                  {toSentenceCase(
                    `${formErrors?.[`players-${player.id}-jerseyNumber`]?.message || "Jersey number is required."}`
                  )}
                </AppText>
              )}
            </AppBox>
          )}
        />
        <Controller
          name="playingRoles"
          defaultValue={""}
          control={methods.control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppBox flexDirection="column" gap="xs">
                <PlayerRoleSelector
                  playerPosition={player.position}
                  onSelectedRoles={(roles) => {
                    onChange(roles);
                    handleInputChange(roles, "playingRoles");
                  }}
                  asDropDown
                />
              </AppBox>
              {isFormInvalid(findInputError(formErrors, `players-${player.id}-playingRoles`)) && (
                <AppText as="span" color="danger">
                  {toSentenceCase(
                    `${
                      (formErrors.players as Record<number, any>)?.[player.id]?.playingRoles?.message ||
                      "Playing roles is required."
                    }`
                  )}
                </AppText>
              )}
            </AppBox>
          )}
        />
        <Controller
          control={methods.control}
          name="startAt"
          rules={{ required: true }}
          defaultValue={moment().toDate()}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column" gap="2xs">
              <AppDatePicker
                onChange={(date) => {
                  onChange(date);
                  handleDateChange(value.toString());
                }}
                selected={value}
                calenderPlacement="bottom-end"
                maxDate={new Date()}
                minDate={minDate}
                dateFormat="d/M/yyyy"
              />
              {isFormInvalid(findInputError(formErrors, `players-${player.id}-startAt`)) && (
                <AppText as="span" color="danger">
                  {toSentenceCase(
                    `${
                      (formErrors.players as Record<number, any>)?.[player.id]?.startAt?.message ||
                      "Start date is required."
                    }`
                  )}
                </AppText>
              )}
            </AppBox>
          )}
        />
        <AppBox justifyContent="center">
          <AppIconButton icon={<IconBin />} variant="light" color="danger" onClick={handlePlayerRemove} />
        </AppBox>
      </AppGridBox>
    </AppBox>
  );
};
