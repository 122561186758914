import React, { useEffect, useState } from "react";
import {
  AppBox,
  AppButton,
  AppModal,
  AppPaper,
  AppSkeletonGlobalCardList,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import IconAddCircle from "../../../../commons/components/icons/add-circle";
import setDocumentTitle from "../../../../commons/utilities/document-title";
import { SeasonStatusEnum } from "../../../../commons/enums/season-status-enum";
import TournamentTeamCard from "./TournamentTeamCard";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import TournamentSeasonTeamCard from "./TournamentSeasonTeamCard";
import TeamSearch from "./TeamSearch";
import IconBin from "../../../../commons/components/icons/bin";
import TournamentSeasonTeamRemove from "./TournamentSeasonTeamRemove";
import TournamentSeasonTeamInprogress from "./TournamentSeasonTeamRemoveInprogress";
import { IFootballTeam } from "../../../../commons/models/football/interface/i-football-team";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import {
  cleanUpCompetitionSeasonTeams,
  fetchCompetitionSeasonTeamList,
} from "../../../../redux/slices/football/admin/competition/manage-competition-season-teams-slice";
import { competitionSeasonTeamDelete } from "../../../../redux/slices/football/admin/competition/manage-competition-season-team-remove-slice";
import {
  competitionSeasonTeamAdd,
  IManageCompetitionTeamsAddOption,
} from "../../../../redux/slices/football/admin/competition/manage-competition-season-teams-add-slice";
import { ManageFootballTeam } from "../../../../commons/models/football/admin/manage-football-team";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import IconEmpty from "../../../../commons/components/icons/empty";

const TournamentTeamBaseComponent = () => {
  const [isTeamAdd, setTeamAdd] = useState(false);
  const [selectedRemovalTeam, setRemovalTeam] = useState<IFootballTeam | null>(null);
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const { competitionInfo } = useAppSelector((state) => state.footballManageCompetition);
  const { selectedGlobalSeasonOption } = useAppSelector((state) => state.globalSeasonSelect);
  const { competitionSeasonTeamList, competitionSeasonTeamListStatus } = useAppSelector(
    (state) => state.footballManageCompetitionSeasonTeamList
  );
  const { competitionSeasonTeamDeleteStatus } = useAppSelector(
    (state) => state.footballManageCompetitionSeasonTeamRemove
  );
  const [isRemoveTournamentTeam, setRemoveTournamentTeam] = useState(false);

  useEffect(() => {
    if (selectedGlobalSeasonOption.value) {
      dispatch(
        fetchCompetitionSeasonTeamList({
          competitionId: competitionInfo.id,
          seasonId: Number(selectedGlobalSeasonOption.value),
        })
      );
    } else {
      dispatch(cleanUpCompetitionSeasonTeams());
    }
  }, [selectedGlobalSeasonOption]);

  const handleSelectedTeams = (teams: ManageFootballTeam[]) => {
    if (teams.length > 0) {
      const selectedTeamIds: number[] = [];
      teams.forEach((team) => {
        selectedTeamIds.push(team.id);
      });

      const seasonTeamsOptions: IManageCompetitionTeamsAddOption = {
        competitionId: competitionInfo.id,
        seasonId: Number(selectedGlobalSeasonOption.value),
        seasonTeams: { teamIds: selectedTeamIds },
      };
      dispatch(competitionSeasonTeamAdd(seasonTeamsOptions))
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "team-add",
            text: "Team(s) Added Successfully",
            variant: "success",
          });
          setTeamAdd(false);
          setDocumentTitle([`${competitionInfo.abbreviation}`, "Manage"]);
          dispatch(
            fetchCompetitionSeasonTeamList({
              competitionId: competitionInfo.id,
              seasonId: Number(selectedGlobalSeasonOption.value),
            })
          );
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          addSnackbar({
            key: "error",
            text: error.response?.data.message,
            variant: "danger",
          });
        });
    }
  };

  const handleTeamRemoveConfirm = (e: Event) => {
    e.preventDefault();
    if (selectedRemovalTeam) {
      setRemoveTournamentTeam(false);
      dispatch(
        competitionSeasonTeamDelete({
          competitionId: competitionInfo.id,
          seasonId: Number(selectedGlobalSeasonOption.value),
          teamId: selectedRemovalTeam.id,
        })
      )
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "team-remove",
            text: "Team Removed Successfully",
            variant: "success",
          });
          setRemovalTeam(null);
          dispatch(
            fetchCompetitionSeasonTeamList({
              competitionId: competitionInfo.id,
              seasonId: Number(selectedGlobalSeasonOption.value),
            })
          );
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          addSnackbar({
            key: "error",
            text: error.response?.data.message,
            variant: "danger",
          });
        });
    }
  };
  return (
    <>
      <AppBox mb="sm" gap="sm" flexDirection="column">
        <AppBox flexDirection="column" gap="xs">
          <AppBox alignItems="center" justifyContent="space-between">
            <AppTitle as="h5">Teams</AppTitle>
            {competitionSeasonTeamList.length > 0 &&
              selectedGlobalSeasonOption.secondaryValue.status === SeasonStatusEnum.Active && (
                <AppButton label="Add Teams" variant="light" onClick={() => setTeamAdd(true)} />
              )}
          </AppBox>
          {competitionSeasonTeamListStatus === StatusEnum.Loading ? (
            <AppSkeletonGlobalCardList numberOfItems={5} noTitle withAvatar />
          ) : (
            <>
              {competitionSeasonTeamList.length > 0 ? (
                <AppBox flexDirection="column" gap="sm">
                  {competitionSeasonTeamList.mapArray((item) => (
                    <TournamentSeasonTeamCard
                      key={item.id}
                      teamInfo={item.team}
                      withOutRemove={selectedGlobalSeasonOption.secondaryValue.status !== SeasonStatusEnum.Active}
                      onTeamRemove={(team) => {
                        setRemovalTeam(team);
                        setRemoveTournamentTeam(true);
                      }}
                    />
                  ))}
                </AppBox>
              ) : (
                <AppPaper padding="sm">
                  <AppBorderBox borderVariant="dashed">
                    {selectedGlobalSeasonOption.secondaryValue &&
                    selectedGlobalSeasonOption.secondaryValue.status !== SeasonStatusEnum.Active ? (
                      <AppBox flexDirection="column" alignItems="center" gap="sm" justifyContent="center" p="sm">
                        <AppBox alignItems="center" flexDirection="column" justifyContent="center" gap="xs">
                          <IconEmpty width={56} height={56} color="rgba(var(--border-200))" />
                          <AppText fontWeight="semibold">There are no team.</AppText>
                        </AppBox>
                      </AppBox>
                    ) : (
                      <AppBox flexDirection="column" alignItems="center" gap="sm" justifyContent="center" p="sm">
                        <AppBox alignItems="center" flexDirection="column" justifyContent="center" gap="xs">
                          <IconAddCircle width={40} height={40} color="rgba(var(--border-200))" />
                          {competitionInfo.latestSeason &&
                          competitionInfo.latestSeason.status === SeasonStatusEnum.Active ? (
                            <AppText fontWeight="semibold">
                              To add teams, click 'Add Teams' and select from the list.
                            </AppText>
                          ) : (
                            <AppText fontWeight="semibold">
                              To add teams, you need to start the season first. Click the "Start Season" button above to
                              begin.
                            </AppText>
                          )}
                        </AppBox>
                        <AppButton
                          label="Add Teams"
                          onClick={() => {
                            setTeamAdd(true);
                          }}
                          disabled={
                            !(
                              competitionInfo.latestSeason &&
                              competitionInfo.latestSeason.status === SeasonStatusEnum.Active
                            )
                          }
                        />
                      </AppBox>
                    )}
                  </AppBorderBox>
                </AppPaper>
              )}
            </>
          )}
        </AppBox>
      </AppBox>
      <AppModal
        opened={isTeamAdd}
        onClose={() => {
          setTeamAdd(false);
          setDocumentTitle([`${competitionInfo.abbreviation}`, "Manage"]);
        }}
        titleIcon={<IconAddCircle />}
        title="Add Teams To The Season"
        withoutButtons
      >
        <TeamSearch
          onSelectedTeam={handleSelectedTeams}
          userSelectedCompetitionTeams={competitionSeasonTeamList.all()}
        />
      </AppModal>
      <AppModal
        opened={isRemoveTournamentTeam}
        onClose={(e) => {
          setRemoveTournamentTeam(false);
        }}
        title="Want To Remove This Team?"
        titleIcon={<IconBin />}
        iconColor="danger"
        confirmButtonLabel="Remove"
        onSave={(e) => {
          handleTeamRemoveConfirm(e as Event);
        }}
      >
        <TournamentSeasonTeamRemove tournamentSeasonTeam={selectedRemovalTeam as IFootballTeam} />
      </AppModal>
      <AppModal
        opened={competitionSeasonTeamDeleteStatus === StatusEnum.Loading}
        title="Removing Team"
        titleIcon={<IconBin />}
        iconColor="danger"
        withoutCancelButton
        withoutButtons
        withoutCloseButton
      >
        <TournamentSeasonTeamInprogress tournamentSeasonTeam={selectedRemovalTeam as IFootballTeam} />
      </AppModal>
    </>
  );
};

export default TournamentTeamBaseComponent;
