import { AppBox, AppPaper } from "../../../../commons/components";
import EChartsReact from "echarts-for-react";
import { IFootballPlayerDetail } from "../../../../commons/models/football/interface/i-football-player";
import { useEffect, useState } from "react";
import { toSentenceCase, toTitleCase } from "../../../../commons/utilities/string-utils";
import { useAppSelector } from "../../../../hooks/app";

export interface IIndicators {
  index: number;
  max: number;
  name: number;
  label: string;
  labelFull: string;
}

export interface IAttributeData {
  player: IFootballPlayerDetail;
  attributeData: number[];
}
interface IRadarData {
  name: string;
  type: "radar";
  data: [
    {
      value: number[];
    }
  ];
  lineStyle: {
    width: 1;
  };
  symbolSize: 0;
}

interface PlayerAttributeProps {
  indicators: IIndicators[];
  attributeData: IAttributeData[];
}
export const dummyIndicators = [
  {
    index: 0,
    name: 0,
    label: "",
    labelFull: "",
    max: 0,
  },
  {
    index: 1,
    name: 0,
    label: "",
    labelFull: "",
    max: 0,
  },
  {
    index: 2,
    name: 0,
    label: "",
    labelFull: "",
    max: 0,
  },
  {
    index: 3,
    name: 0,
    label: "",
    labelFull: "",
    max: 0,
  },
  {
    index: 4,
    name: 0,
    label: "",
    labelFull: "",
    max: 0,
  },
  {
    index: 5,
    name: 0,
    label: "",
    labelFull: "",
    max: 0,
  },
];
export default function PlayerAttribute({ indicators, attributeData }: PlayerAttributeProps) {
  const [series, setSeries] = useState<IRadarData[]>([]);
  const { theme } = useAppSelector((state) => state.session);

  const getRadarRadius = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 480) {
      return "50%";
    } else {
      return "70%";
    }
  };

  const getStyleForA = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 480) {
      return {
        fontSize: 12,
        fontWeight: 700,
        color: theme === "dark" ? "rgb(211, 208, 206, 1)" : "rgb(54, 52, 51, 1)",
      };
    } else {
      return {
        fontSize: 14,
        fontWeight: 700,
        color: theme === "dark" ? "rgb(211, 208, 206, 1)" : "rgb(54, 52, 51, 1)",
      };
    }
  };
  const getStyleForB = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 480) {
      return {
        backgroundColor: "rgba(66, 49, 191, 1)",
        padding: 5,
        fontSize: 14,
        color: "rgb(255, 255, 255, 1)",
        fontWeight: 700,
        borderRadius: 4,
      };
    } else {
      return {
        backgroundColor: "rgba(66, 49, 191, 1)",
        padding: 6,
        fontSize: 16,
        color: "rgb(255, 255, 255, 1)",
        fontWeight: 700,
        borderRadius: 4,
      };
    }
  };

  useEffect(() => {
    const seriesList: IRadarData[] = [];
    attributeData.forEach((data) => {
      seriesList.push({
        name: toTitleCase(data?.player.displayName) || "",
        type: "radar",
        data: [
          {
            value: data.attributeData,
          },
        ],
        lineStyle: {
          width: 1,
        },
        symbolSize: 0,
      });
    });
    setSeries(seriesList);
  }, [attributeData]);

  const chartOptions = {
    tooltip: {
      formatter: function (params: any) {
        const values = params.value;
        const tooltipText = [];
        for (let i = 0; i < indicators?.length; i++) {
          const label = indicators[i];
          tooltipText.push(toSentenceCase(label.labelFull || "Label") + ": " + (values[i] || 0));
        }
        return "<b>Player Attributes</b> <br/>" + tooltipText.join("<br>");
      },
    },
    radar: {
      radius: getRadarRadius(),
      indicator: indicators,
      name: {
        formatter: function (value: number, indicator: IIndicators) {
          if (indicator.index > 2) {
            return `{b|${value}} {a|${indicator.label}}`;
          }
          return `{a|${indicator.label}} {b|${value}}`;
        },
        rich: {
          a: getStyleForA(),
          b: getStyleForB(),
        },
      },
      splitNumber: 4,
      splitLine: {
        show: false,
      },

      axisLine: {
        show: false,
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: [
            "rgba(251, 76, 11, 0.6)",
            "rgba(251, 76, 11, 0.48)",
            "rgba(251, 76, 11, 0.36)",
            "rgba(251, 76, 11, 0.24)",
            "rgba(251, 76, 11, 0.12)",
          ],
        },
      },
    },
    series: series,
    // Legend to be added when player attribute comparison is implemented
    legend: {
      data: [attributeData[0]?.player?.displayName || "player 1", attributeData[1]?.player?.displayName || "player 2"],
      show: false,
      right: 10,
      top: 10,
    },
    color: ["rgba(251, 76, 11, 1)", "rgba(66, 49, 191, 1)"],
  };

  return (
    <AppBox flexDirection="column" gap="sm" className="player-attribute">
      <AppPaper padding="sm" shadow="xs">
        <EChartsReact option={chartOptions} className="attribute-chart" />
        {/* TODO: to be added in implementation of player comparison */}
        {/* <AppGridBox columns={2} gap="sm" pt="sm">
          <AppBox displayBlock className="attribute-compare">
            <input
              type="text"
              placeholder="Search players to compare"
              className="ee-input"
            />
            <AppText className="swap-icon">
              <IconPeopleSwap />
            </AppText>
          </AppBox>

          <AppBox gap="2xs">
            <IconInfo />
            <AppText size="sm" fontWeight="medium">
              Tap on attribute overview to display average values for this
              position.
            </AppText>
          </AppBox>
        </AppGridBox> */}
      </AppPaper>
    </AppBox>
  );
}
