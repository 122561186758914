import { AppBox, AppGridBox, AppLink, AppText } from "../../../../../commons/components";
import { FormProvider, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import { formatDate } from "../../../../../commons/utilities/date-utils";
import { useSnackbar } from "../../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { IFootballPlayerDetail } from "../../../../../commons/models/football/interface/i-football-player";
import { playerAdd } from "../../../../../redux/slices/football/admin/player/manage-player-add-slice";
import { playerEdit } from "../../../../../redux/slices/football/admin/player/manage-player-edit-slice";
import { IPlayerDTO } from "../../../../../api-services/football/admin/manage-football-player-service";
import { IServerErrorResponse } from "../../../../../commons/components/interface";
import AppBorderBox from "../../../../../commons/components/BorderBox";
import PlayerImageAddEdit from "./PlayerImageAddEdit";
import PlayerBannerAddEdit from "./PlayerBannerAddEdit";
import PlayerBasicInfoAddEdit from "./PlayerBasicInfoAddEdit";
import PlayerSpecificsAddEdit, { playerFootOptions } from "./PlayerSpecificsAddEdit";
import PlayerCareerDetailsAddEdit from "./PlayerCareerDetailsAddEdit";
import { cleanUpStepperFormData, setStepperFormData } from "../../../../../redux/slices/stepper-form-slice";
import moment from "moment";
import { getErrorKeyPosition } from "../../../../../commons/utilities/form-utils";
import { StatusEnum } from "../../../../../commons/enums/status-enum";

interface PlayerAddEditProps {
  playerData?: IFootballPlayerDetail | null;
  onCancel?: () => void;
  onSave: () => void;
}

const stepperFormOptions = [
  {
    step: 1,
    title: "Player's Avatar",
  },
  {
    step: 2,
    title: "Player's Banner",
  },
  {
    step: 3,
    title: "Basic Information",
  },
  {
    step: 4,
    title: "Specifics",
  },
  {
    step: 5,
    title: "Career Details",
  },
];

const stepperFromKeyMap = [
  ["avatar"],
  ["banner"],
  ["firstName", "middleName", "lastName", "displayName", "dateOfBirth", "birthPlace"],
  ["height", "weight", "preferredFoot", "position", "status"],
  ["strength", "weakness", "description"],
];

export default function PlayerAddEdit({ playerData, onCancel, onSave }: PlayerAddEditProps) {
  const methods = useForm();
  const formErrors = methods.formState.errors;
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const [selectedStepperFormOption, setSelectedStepperFormOption] = useState(stepperFormOptions[0]);
  const formData = useAppSelector<IPlayerDTO>((state) => state.stepperForm.stepperFormData);
  const { playerAddResponseStatus } = useAppSelector((state) => state.footballManagePlayerAdd);
  const { playerEditResponseStatus } = useAppSelector((state) => state.footballManagePlayerEdit);
  const { uploadedFileStatus } = useAppSelector((state) => state.fileUpload);
  const { uploadedImageStatus } = useAppSelector((state) => state.imageUpload);

  //initiate the stepperData
  useLayoutEffect(() => {
    if (!playerData) {
      const playerData: IPlayerDTO = {
        dateOfBirth: null,
        firstName: "",
        lastName: "",
        middleName: null,
        description: null,
        avatar: null,
        banner: null,
        birthPlace: null,
        height: null,
        weight: null,
        preferredFoot: null,
        strength: null,
        weakness: null,
        status: 1,
        displayName: null,
        position: "",
      };
      dispatch(setStepperFormData<IPlayerDTO>(playerData));
    }
  }, []);

  useLayoutEffect(() => {
    if (playerData) {
      const playerDTO: IPlayerDTO = {
        firstName: playerData.firstName,
        middleName: playerData.middleName,
        lastName: playerData.lastName,
        description: playerData.description,
        avatar: playerData.avatar,
        banner: playerData.banner,
        dateOfBirth: playerData.dateOfBirth ? playerData.dateOfBirth.toString() : null,
        birthPlace: playerData.birthPlace,
        height: playerData.height,
        weight: playerData.weight,
        preferredFoot: playerData.preferredFoot || playerFootOptions[0].value.toString(),
        strength: playerData.strength,
        weakness: playerData.weakness,
        status: playerData.status,
        displayName: playerData.displayName,
        position: playerData.position,
      };
      dispatch(setStepperFormData<IPlayerDTO>(playerDTO));
    }
  }, [playerData]);

  const handleNext = (currentStep: number) => {
    if (
      playerAddResponseStatus !== StatusEnum.Loading &&
      playerEditResponseStatus !== StatusEnum.Loading &&
      uploadedFileStatus !== StatusEnum.Loading &&
      uploadedImageStatus !== StatusEnum.Loading
    ) {
      setSelectedStepperFormOption(stepperFormOptions[currentStep]);
    }
  };
  const handleBack = (currentStep: number) => {
    if (
      playerAddResponseStatus !== StatusEnum.Loading &&
      playerEditResponseStatus !== StatusEnum.Loading &&
      uploadedFileStatus !== StatusEnum.Loading &&
      uploadedImageStatus !== StatusEnum.Loading
    ) {
      setSelectedStepperFormOption(stepperFormOptions[currentStep - 2]);
    }
  };

  const handleStepperOptionClick = (option: any) => {
    if (
      playerAddResponseStatus !== StatusEnum.Loading &&
      playerEditResponseStatus !== StatusEnum.Loading &&
      uploadedFileStatus !== StatusEnum.Loading &&
      uploadedImageStatus !== StatusEnum.Loading
    ) {
      setSelectedStepperFormOption(option);
    }
  };

  const handleFormSubmit = () => {
    const formattedValues: IPlayerDTO = {
      ...formData,
      avatar: formData.avatar ? formData.avatar : null,
      height: formData.height ? Number(formData.height) : null,
      weight: formData.weight ? Number(formData.weight) : null,
      status: Number(formData.status),
      dateOfBirth: formData.dateOfBirth ? formatDate(formData.dateOfBirth as string) : null,
    };
    dispatch(
      playerData
        ? playerEdit({ playerId: playerData.id, playerData: formattedValues })
        : playerAdd({ playerData: formattedValues })
    )
      .unwrap()
      .then(() => {
        if (playerData) {
          addSnackbar({
            key: "player-edit-success",
            text: "Player Edited Successfully",
            variant: "success",
          });
        } else {
          addSnackbar({
            key: "player-add-success",
            text: "Player Added Successfully",
            variant: "success",
          });
        }
        dispatch(cleanUpStepperFormData());
        onSave();
      })
      .catch((error: AxiosError<IServerErrorResponse>) => {
        const responseData = error.response?.data;
        if (error.response?.status === 417) {
          addSnackbar({
            key: "error",
            text: "Form not valid",
            variant: "danger",
          });
          if (responseData) {
            const stepperPositionSet = new Set<number>();
            Object.entries(responseData).forEach(([field, messages]) => {
              messages.forEach((message: string) => {
                methods.setError(field, { message });
                const stepperPosition = getErrorKeyPosition(field, stepperFromKeyMap);
                if (stepperPosition) {
                  stepperPositionSet.add(stepperPosition.step);
                }
                const lowestStep = Math.min(...Array.from(stepperPositionSet));
                setSelectedStepperFormOption(stepperFormOptions[lowestStep]);
              });
            });
          }
        } else {
          addSnackbar({
            key: "error",
            text: responseData?.message,
            variant: "danger",
          });
        }
      });
  };

  const clearErrorField = (key: string) => {
    methods.clearErrors(key);
  };

  return (
    <FormProvider {...methods}>
      <AppBox flexDirection="column" gap="sm" style={{ height: "var(--modal-md)" }}>
        <AppGridBox className=" flex-1" style={{ gridTemplateColumns: "1fr 3fr" }} gap="sm">
          <AppBorderBox border={["Right"]}>
            <AppBox flexDirection="column" gap="2xs" className="w-100">
              {stepperFormOptions.map((option, index) => (
                <AppLink
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStepperOptionClick(option);
                  }}
                >
                  <AppBorderBox
                    border={option.step === selectedStepperFormOption.step ? ["Right"] : []}
                    borderColor="primary"
                  >
                    <AppBox pl="sm" py="2xs">
                      <AppText
                        fontWeight="semibold"
                        size="lg"
                        color={option.step === selectedStepperFormOption.step ? "primary" : "currentColor"}
                      >
                        {option.title}
                      </AppText>
                    </AppBox>
                  </AppBorderBox>
                </AppLink>
              ))}
            </AppBox>
          </AppBorderBox>
          <AppBox flexDirection="column" className="w-100 flex-1">
            <AppBox
              className="flex-1"
              style={selectedStepperFormOption.step === 1 ? { display: "flex" } : { display: "none" }}
            >
              <PlayerImageAddEdit
                playerData={playerData}
                formStep={selectedStepperFormOption.step}
                formErrors={formErrors}
                onSkipOrNext={handleNext}
                onClearError={clearErrorField}
                submitStatus={playerEditResponseStatus}
                onSaveAndExit={handleFormSubmit}
              />
            </AppBox>
            <AppBox
              className="flex-1"
              style={selectedStepperFormOption.step === 2 ? { display: "flex" } : { display: "none" }}
            >
              <PlayerBannerAddEdit
                playerData={playerData}
                formStep={selectedStepperFormOption.step}
                formErrors={formErrors}
                onBack={handleBack}
                onSkipOrNext={handleNext}
                onClearError={clearErrorField}
                submitStatus={playerEditResponseStatus}
                onSaveAndExit={handleFormSubmit}
              />
            </AppBox>
            <AppBox
              className="flex-1"
              style={selectedStepperFormOption.step === 3 ? { display: "flex" } : { display: "none" }}
            >
              <PlayerBasicInfoAddEdit
                playerData={playerData}
                formStep={selectedStepperFormOption.step}
                formErrors={formErrors}
                onBack={handleBack}
                onNext={handleNext}
                onClearError={clearErrorField}
                submitStatus={playerEditResponseStatus}
                onSaveAndExit={handleFormSubmit}
              />
            </AppBox>
            <AppBox
              className="flex-1"
              style={selectedStepperFormOption.step === 4 ? { display: "flex" } : { display: "none" }}
            >
              <PlayerSpecificsAddEdit
                playerData={playerData}
                formStep={selectedStepperFormOption.step}
                formErrors={formErrors}
                onBack={handleBack}
                onNext={handleNext}
                onClearError={clearErrorField}
                submitStatus={playerEditResponseStatus}
                onSaveAndExit={handleFormSubmit}
              />
            </AppBox>
            <AppBox
              className="flex-1"
              style={selectedStepperFormOption.step === 5 ? { display: "flex" } : { display: "none" }}
            >
              <PlayerCareerDetailsAddEdit
                playerData={playerData}
                formStep={selectedStepperFormOption.step}
                formErrors={formErrors}
                submitStatus={playerAddResponseStatus || playerEditResponseStatus}
                onBack={handleBack}
                onSubmit={handleFormSubmit}
                onClearError={clearErrorField}
              />
            </AppBox>
          </AppBox>
        </AppGridBox>
      </AppBox>
    </FormProvider>
  );
}
