import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/app";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppPaper,
  AppSkeletonHeader,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import { IStadium } from "../../../../commons/models/i-stadium";
import { getCountryName } from "../../../../commons/utilities/country-utils";
import setDocumentTitle from "../../../../commons/utilities/document-title";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface StadiumManageDetailHeaderProps {
  stadium: IStadium;
  onEditStadium?: (event?: Event) => void;
}

export default function StadiumManageDetailHeader({ stadium, onEditStadium }: StadiumManageDetailHeaderProps) {
  const { stadiumInfoStatus } = useAppSelector((state) => state.manageStadium);
  useEffect(() => {
    setDocumentTitle([`${toTitleCase(stadium.name)}`, "Manage"]);
  }, [stadium]);
  return (
    <AppPaper shadow="xs" padding="none">
      {stadiumInfoStatus === StatusEnum.Loading ? (
        <AppSkeletonHeader withoutBackground />
      ) : (
        <AppBox alignItems="center" justifyContent="space-between" p="sm" gap="xs">
          <AppBox alignItems="center" gap="xs">
            <AppAvatar username={stadium.name} size="2xl" src={stadium.imageUrl || ""} />
            <AppBox flexDirection="column" gap="3xs">
              <AppTitle as="h4">{toTitleCase(stadium.name)}</AppTitle>
              <AppText>
                {stadium.address && `${stadium.address}, `}
                {stadium.city && `${stadium.city}, `}
                {getCountryName(stadium?.country || "")}
              </AppText>
            </AppBox>
          </AppBox>
          <AppButton variant="light" label="Edit Venue Info" onClick={onEditStadium} />
        </AppBox>
      )}
    </AppPaper>
  );
}
