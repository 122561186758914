export default function IconCrown() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 17C13.1046 17 14 16.1046 14 15C14 13.8954 13.1046 13 12 13C10.8954 13 10 13.8954 10 15C10 16.1046 10.8954 17 12 17ZM14 5C14 5.53031 13.7936 6.01241 13.4568 6.37036L16.0806 9.65021C16.1543 9.74226 16.2822 9.77036 16.3877 9.71764L19.0385 8.39225C19.0132 8.26541 19 8.13425 19 8C19 6.89543 19.8954 6 21 6C22.1046 6 23 6.89543 23 8C23 8.95189 22.335 9.74847 21.4442 9.95049L19.6401 19.5725C19.4849 20.4002 18.7622 21 17.9201 21H6.07993C5.2378 21 4.51509 20.4002 4.3599 19.5725L2.55577 9.95049C1.665 9.74847 1 8.95189 1 8C1 6.89543 1.89543 6 3 6C4.10457 6 5 6.89543 5 8C5 8.13425 4.98677 8.26541 4.96155 8.39225L7.61234 9.71765C7.71778 9.77036 7.84572 9.74226 7.91936 9.65021L10.5432 6.37036C10.2064 6.01241 10 5.53031 10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5ZM12 7C11.9869 7 11.9738 6.99987 11.9608 6.99962L9.09066 10.5873C8.57517 11.2316 7.67959 11.4283 6.94152 11.0593L4.12737 9.65221C4.09778 9.67244 4.06762 9.69189 4.03692 9.71054L5.83421 19.2961C5.85638 19.4143 5.95962 19.5 6.07993 19.5H17.9201C18.0404 19.5 18.1436 19.4143 18.1658 19.2961L19.9631 9.71055C19.9324 9.69189 19.9022 9.67244 19.8726 9.65221L17.0585 11.0593C16.3204 11.4283 15.4248 11.2316 14.9093 10.5873L12.0392 6.99962C12.0262 6.99987 12.0131 7 12 7Z"
        fill="currentColor"
      />
    </svg>
  );
}
