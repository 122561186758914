import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { IFootballPlayerYearlyPosition } from "./interface/i-football-player-team";

export class FootballPlayerYearlyPosition extends BaseModel implements IFootballPlayerYearlyPosition {
  public year: number = 0;
  public position: string = "";
  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
