import { AppBox, AppGridBox, AppLink, AppText } from "../../../../../commons/components";
import { FormProvider, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import { formatDateTime } from "../../../../../commons/utilities/date-utils";
import { useSnackbar } from "../../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { IServerErrorResponse } from "../../../../../commons/components/interface";
import AppBorderBox from "../../../../../commons/components/BorderBox";
import StadiumImageAddEdit from "./StadiumImageAddEdit";
import StadiumBasicInfoAddEdit from "./StadiumBasicInfoAddEdit";
import StadiumSpecificsAddEdit from "./StadiumSpecificsAddEdit";
import { cleanUpStepperFormData, setStepperFormData } from "../../../../../redux/slices/stepper-form-slice";
import { getErrorKeyPosition } from "../../../../../commons/utilities/form-utils";
import moment from "moment";
import { IStadium } from "../../../../../commons/models/i-stadium";
import { IStadiumDTO } from "../../../../../api-services/football/admin/manage-stadium-service";
import { stadiumEdit } from "../../../../../redux/slices/stadium/manage-stadium-edit-slice";
import { stadiumAdd } from "../../../../../redux/slices/stadium/manage-stadium-add-slice";
import { StatusEnum } from "../../../../../commons/enums/status-enum";

interface StadiumAddEditProps {
  stadiumData?: IStadium | null;
  onCancel?: () => void;
  onSave: () => void;
}

const stepperFormOptions = [
  {
    step: 1,
    title: "Venue's Image",
  },
  {
    step: 2,
    title: "Basic Information",
  },
  {
    step: 3,
    title: "Specifics",
  },
];

const stepperFromKeyMap = [
  ["imageUrl"],
  ["name", "address", "city", "inaugurationAt", "country"],
  ["capacity", "pitchLength", "pitchWidth", "playingSurfaceType"],
];

export default function StadiumAddEdit({ stadiumData, onCancel, onSave }: StadiumAddEditProps) {
  const methods = useForm();
  const formErrors = methods.formState.errors;
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const [selectedStepperFormOption, setSelectedStepperFormOption] = useState(stepperFormOptions[0]);
  const formData = useAppSelector<IStadiumDTO>((state) => state.stepperForm.stepperFormData);
  const { stadiumAddResponseStatus } = useAppSelector((state) => state.manageStadiumAdd);
  const { stadiumEditResponseStatus } = useAppSelector((state) => state.manageStadiumEdit);
  const { uploadedImageStatus } = useAppSelector((state) => state.imageUpload);

  //initiate the stepperData
  useLayoutEffect(() => {
    if (!stadiumData) {
      const stadiumData: IStadiumDTO = {
        name: "",
        capacity: null,
        address: null,
        city: null,
        country: null,
        imageUrl: null,
        inaugurationAt: null,
        pitchLength: null,
        pitchWidth: null,
        playingSurfaceType: null,
      };
      dispatch(setStepperFormData<IStadiumDTO>(stadiumData));
    }
  }, []);

  useLayoutEffect(() => {
    if (stadiumData) {
      const stadiumDTO: IStadiumDTO = {
        name: stadiumData.name,
        capacity: stadiumData.capacity,
        address: stadiumData.address,
        city: stadiumData.city,
        country: stadiumData.country,
        imageUrl: stadiumData.imageUrl,
        inaugurationAt: stadiumData.inaugurationAt,
        pitchLength: stadiumData.pitchLength,
        pitchWidth: stadiumData.pitchWidth,
        playingSurfaceType: stadiumData.playingSurfaceType,
      };
      dispatch(setStepperFormData<IStadiumDTO>(stadiumDTO));
    }
  }, [stadiumData]);

  const handleNext = (currentStep: number) => {
    if (
      stadiumAddResponseStatus !== StatusEnum.Loading &&
      stadiumEditResponseStatus !== StatusEnum.Loading &&
      uploadedImageStatus !== StatusEnum.Loading
    ) {
      setSelectedStepperFormOption(stepperFormOptions[currentStep]);
    }
  };
  const handleBack = (currentStep: number) => {
    if (
      stadiumAddResponseStatus !== StatusEnum.Loading &&
      stadiumEditResponseStatus !== StatusEnum.Loading &&
      uploadedImageStatus !== StatusEnum.Loading
    ) {
      setSelectedStepperFormOption(stepperFormOptions[currentStep - 2]);
    }
  };

  const handleStepperOptionClick = (option: any) => {
    if (
      stadiumAddResponseStatus !== StatusEnum.Loading &&
      stadiumEditResponseStatus !== StatusEnum.Loading &&
      uploadedImageStatus !== StatusEnum.Loading
    ) {
      setSelectedStepperFormOption(option);
    }
  };

  const handleFormSubmit = () => {
    const formattedValues: IStadiumDTO = {
      ...formData,
      imageUrl: formData.imageUrl ? formData.imageUrl : null,
      capacity: formData.capacity ? Number(formData.capacity) : null,
      pitchLength: formData.pitchLength ? Number(formData.pitchLength) : null,
      pitchWidth: formData.pitchWidth ? Number(formData.pitchWidth) : null,
      inaugurationAt: formData.inaugurationAt
        ? formatDateTime(formData.inaugurationAt as string, "YYYY-MM-DD HH:mm:00")
        : null,
    };
    dispatch(
      stadiumData
        ? stadiumEdit({ stadiumId: stadiumData.id, stadiumData: formattedValues })
        : stadiumAdd({ stadiumData: formattedValues })
    )
      .unwrap()
      .then(() => {
        if (stadiumData) {
          addSnackbar({
            key: "stadium-edit-success",
            text: "Venue Edited Successfully",
            variant: "success",
          });
        } else {
          addSnackbar({
            key: "stadium-add-success",
            text: "Venue Added Successfully",
            variant: "success",
          });
        }
        dispatch(cleanUpStepperFormData());
        onSave();
      })
      .catch((error: AxiosError<IServerErrorResponse>) => {
        const responseData = error.response?.data;
        if (error.response?.status === 417) {
          addSnackbar({
            key: "error",
            text: "Form not valid",
            variant: "danger",
          });
          if (responseData) {
            const stepperPositionSet = new Set<number>();
            Object.entries(responseData).forEach(([field, messages]) => {
              messages.forEach((message: string) => {
                methods.setError(field, { message });
                const stepperPosition = getErrorKeyPosition(field, stepperFromKeyMap);
                if (stepperPosition) {
                  stepperPositionSet.add(stepperPosition.step);
                }
                const lowestStep = Math.min(...Array.from(stepperPositionSet));
                setSelectedStepperFormOption(stepperFormOptions[lowestStep]);
              });
            });
          }
        } else {
          addSnackbar({
            key: "error",
            text: responseData?.message,
            variant: "danger",
          });
        }
      });
  };

  const clearErrorField = (key: string) => {
    methods.clearErrors(key);
  };

  return (
    <FormProvider {...methods}>
      <AppBox flexDirection="column" gap="sm" style={{ height: "var(--modal-md)" }}>
        <AppGridBox className=" flex-1" style={{ gridTemplateColumns: "1fr 3fr" }} gap="sm">
          <AppBorderBox border={["Right"]}>
            <AppBox flexDirection="column" gap="2xs" className="w-100">
              {stepperFormOptions.map((option, index) => (
                <AppLink
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    handleStepperOptionClick(option);
                  }}
                >
                  <AppBorderBox
                    border={option.step === selectedStepperFormOption.step ? ["Right"] : []}
                    borderColor="primary"
                  >
                    <AppBox pl="sm" py="2xs">
                      <AppText
                        fontWeight="semibold"
                        size="lg"
                        color={option.step === selectedStepperFormOption.step ? "primary" : "currentColor"}
                      >
                        {option.title}
                      </AppText>
                    </AppBox>
                  </AppBorderBox>
                </AppLink>
              ))}
            </AppBox>
          </AppBorderBox>
          <AppBox flexDirection="column" className="w-100 flex-1">
            <AppBox
              className="flex-1"
              style={selectedStepperFormOption.step === 1 ? { display: "flex" } : { display: "none" }}
            >
              <StadiumImageAddEdit
                stadiumData={stadiumData}
                formStep={selectedStepperFormOption.step}
                formErrors={formErrors}
                onSkipOrNext={handleNext}
                onClearError={clearErrorField}
                submitStatus={stadiumEditResponseStatus}
                onSaveAndExit={handleFormSubmit}
              />
            </AppBox>
            <AppBox
              className="flex-1"
              style={selectedStepperFormOption.step === 2 ? { display: "flex" } : { display: "none" }}
            >
              <StadiumBasicInfoAddEdit
                stadiumData={stadiumData}
                formStep={selectedStepperFormOption.step}
                formErrors={formErrors}
                onBack={handleBack}
                onNext={handleNext}
                onClearError={clearErrorField}
                submitStatus={stadiumEditResponseStatus}
                onSaveAndExit={handleFormSubmit}
              />
            </AppBox>
            <AppBox
              className="flex-1"
              style={selectedStepperFormOption.step === 3 ? { display: "flex" } : { display: "none" }}
            >
              <StadiumSpecificsAddEdit
                stadiumData={stadiumData}
                formStep={selectedStepperFormOption.step}
                submitStatus={stadiumAddResponseStatus || stadiumEditResponseStatus}
                formErrors={formErrors}
                onBack={handleBack}
                onSubmit={handleFormSubmit}
                onClearError={clearErrorField}
              />
            </AppBox>
          </AppBox>
        </AppGridBox>
      </AppBox>
    </FormProvider>
  );
}
