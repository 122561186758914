import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballPlayerService } from "../../../../../commons";
import { EnsueCollection } from "../../../../../ensue-react-system/utilities/ensue-collection";
import { FootballPlayerYearlyPosition } from "../../../../../commons/models/football/football-player-team";

export interface IFootballPlayerYearlyPositionState {
  playerYearlyPosition: EnsueCollection<FootballPlayerYearlyPosition>;
  playerYearlyPositionStatus: StatusEnum;
}

export interface IFootballPlayerYearlyPositionOption {
  playerId: number;
}

const initialState: IFootballPlayerYearlyPositionState = {
  playerYearlyPosition: new EnsueCollection<FootballPlayerYearlyPosition>(),
  playerYearlyPositionStatus: StatusEnum.Idle,
};

export const fetchManagePlayerYearlyPosition = createAsyncThunk(
  "players/fetchManagePlayerYearlyPosition",
  async (options: IFootballPlayerYearlyPositionOption, thunkAPI) => {
    try {
      const playerYearlyPositionList = await manageFootballPlayerService.getPlayerYearlyPosition(options.playerId);
      return playerYearlyPositionList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballPlayerYearlyPositionSlice = createSlice({
  name: "footballManagePlayerYearlyPosition",
  initialState: initialState,
  reducers: {
    cleanUpManagePlayerYearlyPosition(state) {
      state.playerYearlyPosition = new EnsueCollection<FootballPlayerYearlyPosition>();
      state.playerYearlyPositionStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManagePlayerYearlyPosition.pending, (state) => {
        state.playerYearlyPositionStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchManagePlayerYearlyPosition.fulfilled,
        (state, action: PayloadAction<EnsueCollection<FootballPlayerYearlyPosition>>) => {
          state.playerYearlyPositionStatus = StatusEnum.Succeeded;
          state.playerYearlyPosition = action.payload;
        }
      )
      .addCase(fetchManagePlayerYearlyPosition.rejected, (state) => {
        state.playerYearlyPositionStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpManagePlayerYearlyPosition } = footballPlayerYearlyPositionSlice.actions;
export default footballPlayerYearlyPositionSlice.reducer;
