import { AppBox, AppText } from "../../../../commons/components";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";

interface SeasonEndProps {
  competition: IFootballCompetition;
}
export default function SeasonEndInprogress({ competition }: SeasonEndProps) {
  return (
    <AppBox flexDirection="column" gap="sm">
      <AppText>
        Ending <strong>“Season {competition.latestSeason?.title}”</strong> In Progress
      </AppText>
      <AppBox className="loader-line"></AppBox>
    </AppBox>
  );
}
