import { useNavigate } from "react-router";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppDateAndTime,
  AppGridBox,
  AppIconButton,
  AppLink,
  AppPaper,
  AppScorePill,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import Pill from "../../../../commons/components/Pill";
import IconAlert from "../../../../commons/components/icons/alert";
import IconLive from "../../../../commons/components/icons/live";
import { IFootballMatchDetail, IMatchState } from "../../../../commons/models/football/interface/i-football-match";
import { useEffect, useState } from "react";
import { MatchStateEnum } from "../../../../commons/enums/match-state-enum";
import moment from "moment";
import { formatLiveMatchTIme, getGameTime, getMatchPauseString } from "../../../../commons/utilities/game-time-utils";
import AppBorderBox from "../../../../commons/components/BorderBox";
import AppRoundedDivider from "../../../../commons/components/RoundedDivider";

interface footballMatchProps {
  match: IFootballMatchDetail;
}

export default function LiveMatchCard({ match }: footballMatchProps) {
  const navigate = useNavigate();
  const [penaltyGoals, setPenaltyGoals] = useState<{ home?: number; away?: number }>();
  const [selectedMatchState, setSelectedMatchState] = useState<IMatchState>();
  const [gameTime, setGameTime] = useState("");
  const handleMatchDetailsClick = () => {
    navigate(`/matches/${match.id}`);
    window.scrollTo(0, 0);
  };
  const handleTeamClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const matchState = match.states.find((matchState) => matchState.state === MatchStateEnum.InProgress);
    if (matchState) {
      setSelectedMatchState(matchState);
    } else {
      const finishedMatchStates = match.states.filter((matchState) => matchState.state === MatchStateEnum.Completed);
      finishedMatchStates.sort((a, b) => moment(b.startAt).valueOf() - moment(a.startAt).valueOf());
      setSelectedMatchState(finishedMatchStates[0]);
    }

    if (match.penalties.length > 0) {
      const homePenaltyGoal = match.penalties.filter(
        (penalty) => penalty.teamId === match.homeTeam?.id && penalty.goalScored
      ).length;
      const awayPenaltyGoal = match.penalties.filter(
        (penalty) => penalty.teamId === match.awayTeam?.id && penalty.goalScored
      ).length;
      setPenaltyGoals({ home: homePenaltyGoal, away: awayPenaltyGoal });
    }
  }, [match]);

  useEffect(() => {
    if (selectedMatchState) {
      const intervalId = setInterval(() => {
        setGameTime(getGameTime(selectedMatchState, match.gameplayDuration));
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [match.status, selectedMatchState]);

  return (
    <AppPaper className="match-card" shadow="xs" padding="xs">
      <AppBox flexDirection="column" gap="2xs">
        <AppBorderBox border={["Bottom"]} pb="xs">
          <AppGridBox className="match-card-grid" gap="sm">
            <AppGridBox className="align-center live-left-section" gap="sm">
              <AppBox justifyContent="end">
                <AppLink to={`/teams/${match.homeTeam?.id}`} onClick={handleTeamClick}>
                  <AppBox className="match-home-team" gap="xs" alignItems="center">
                    <AppTitle truncate={true} as="h6" textAlign="right">
                      {match.homeTeam?.shortName}
                    </AppTitle>
                    <AppAvatar username={match.homeTeam?.name || ""} src={match.homeTeam?.logo || ""} />
                  </AppBox>
                </AppLink>
              </AppBox>
              <AppBox flexDirection="column" alignItems="center" gap="2xs">
                <AppScorePill
                  matchStatus="live"
                  valueFirst={match.homeGoals || 0}
                  valueSecond={match.awayGoals || 0}
                  penaltyValueFirst={penaltyGoals?.home}
                  penaltyValueSecond={penaltyGoals?.away}
                />
                {selectedMatchState && (
                  <Pill
                    icon={<IconLive />}
                    label={`live | ${
                      selectedMatchState.state === MatchStateEnum.Completed
                        ? getMatchPauseString(selectedMatchState, match.competition)
                        : formatLiveMatchTIme(getGameTime(selectedMatchState, match.gameplayDuration))
                    }`}
                  ></Pill>
                )}
              </AppBox>
              <AppBox justifyContent="start">
                <AppLink to={`/teams/${match.awayTeam?.id}`} onClick={handleTeamClick}>
                  <AppBox className="match-away-team" gap="xs" alignItems="center">
                    <AppAvatar username={match.awayTeam?.name || ""} src={match.awayTeam?.logo || ""} />
                    <AppTitle truncate={true} as="h6">
                      {match.awayTeam?.shortName}
                    </AppTitle>
                  </AppBox>
                </AppLink>
              </AppBox>
            </AppGridBox>
            <AppBox className="match-detail-desktop" gap="xs" justifyContent="end" alignItems="center">
              <AppIconButton icon={<IconAlert />} size="lg" variant="light" disabled></AppIconButton>
              <AppButton
                size="lg"
                variant="light"
                label="Match Details"
                onClick={() => handleMatchDetailsClick()}
              ></AppButton>
            </AppBox>
          </AppGridBox>
        </AppBorderBox>
        <AppBox justifyContent="center" alignItems="center">
          <AppDateAndTime date={moment(match.scheduledAt)} format="DD MMM, yyyy" />
          <AppRoundedDivider mx="2xs" />
          <AppDateAndTime date={moment(match.scheduledAt)} format="HH:mm" />
          {match.stadium && (
            <>
              <AppRoundedDivider mx="2xs" />
              <AppText lineClamp={1}>
                {match.stadium.name}, {match.stadium.city}
              </AppText>
            </>
          )}
        </AppBox>
        {/* For mobile view only */}
        <AppBox className="match-detail-mobile" gap="xs" justifyContent="center" alignItems="center">
          <AppIconButton icon={<IconAlert />} size="lg" variant="light" disabled></AppIconButton>
          <AppButton
            fullWidth
            size="lg"
            variant="light"
            label="Match Details"
            onClick={() => handleMatchDetailsClick()}
          ></AppButton>
        </AppBox>
      </AppBox>
    </AppPaper>
  );
}
