import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { manageFootballPlayerService } from "../../../../../commons";
import { IFootballGroupedPlayerAttribute } from "../../../../../commons/models/football/interface/i-football-grouped-player-attribute";

export interface IFootballPlayerAttributeState {
  playerAttribute: IFootballGroupedPlayerAttribute;
  playerAttributeStatus: StatusEnum;
}

export interface IFootballPlayerAttributeOption {
  playerId: number;
  urlParams?: {
    year?: number;
  };
}

const initialState: IFootballPlayerAttributeState = {
  playerAttribute: {},
  playerAttributeStatus: StatusEnum.Idle,
};

export const fetchManagePlayerAttribute = createAsyncThunk(
  "players/fetchManagePlayerAttribute",
  async (options: IFootballPlayerAttributeOption, thunkAPI) => {
    try {
      const playerAttributeList = await manageFootballPlayerService.getPlayerAttributes(options.playerId);
      return playerAttributeList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballPlayerAttributeListSlice = createSlice({
  name: "footballManagePlayerAttribute",
  initialState: initialState,
  reducers: {
    cleanUpManagePlayerAttribute(state) {
      state.playerAttribute = {};
      state.playerAttributeStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManagePlayerAttribute.pending, (state) => {
        state.playerAttributeStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchManagePlayerAttribute.fulfilled,
        (state, action: PayloadAction<IFootballGroupedPlayerAttribute>) => {
          state.playerAttributeStatus = StatusEnum.Succeeded;
          state.playerAttribute = action.payload;
        }
      )
      .addCase(fetchManagePlayerAttribute.rejected, (state) => {
        state.playerAttributeStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpManagePlayerAttribute } = footballPlayerAttributeListSlice.actions;
export default footballPlayerAttributeListSlice.reducer;
