import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../commons";
import { FootballMatchPenalty } from "../../../../../../commons/models/football/football-match-penalty";
import { EnsueCollection } from "../../../../../../ensue-react-system/utilities/ensue-collection";

export interface IFootballMatchPenaltyState {
  seasonMatchPenaltyList: EnsueCollection<FootballMatchPenalty>;
  seasonMatchPenaltyListStatus: StatusEnum;
}

export interface IManageSeasonMatchPenaltyListOption {
  seasonId: number;
  matchId: number;
}

const initialState: IFootballMatchPenaltyState = {
  seasonMatchPenaltyList: new EnsueCollection<FootballMatchPenalty>(),
  seasonMatchPenaltyListStatus: StatusEnum.Idle,
};

export const fetchSeasonMatchPenaltyList = createAsyncThunk(
  "manage/seasonMatchPenaltyList",
  async (options: IManageSeasonMatchPenaltyListOption, thunkAPI) => {
    try {
      const seasonMatchDetail = await manageFootballSeasonService.getSeasonMatchPenaltyList(
        options.seasonId,
        options.matchId
      );
      return seasonMatchDetail;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballSeasonMatchPenaltyListSlice = createSlice({
  name: "footballMatchPenalties",
  initialState: initialState,
  reducers: {
    cleanUpSeasonMatchPenalties(state) {
      state.seasonMatchPenaltyList = new EnsueCollection<FootballMatchPenalty>();
      state.seasonMatchPenaltyListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSeasonMatchPenaltyList.pending, (state) => {
        state.seasonMatchPenaltyListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchSeasonMatchPenaltyList.fulfilled,
        (state, action: PayloadAction<EnsueCollection<FootballMatchPenalty>>) => {
          state.seasonMatchPenaltyListStatus = StatusEnum.Succeeded;
          state.seasonMatchPenaltyList = action.payload;
        }
      )
      .addCase(fetchSeasonMatchPenaltyList.rejected, (state) => {
        state.seasonMatchPenaltyListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpSeasonMatchPenalties } = footballSeasonMatchPenaltyListSlice.actions;
export default footballSeasonMatchPenaltyListSlice.reducer;
