import {
  AppBox,
  AppGridBox,
  AppSkeletonAwardList,
  AppSkeletonBasicInfo,
  AppSkeletonNewsList,
  AppText,
  AppTitle,
} from "../../../commons/components";
import TournamentBasicInfoCard from "./components/TournamentBasicInfoCard";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { useEffect } from "react";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import {
  IFootballCompetitionAwardOption,
  fetchCompetitionAward,
} from "../../../redux/slices/football/public/competition/football-competition-award-slice";
import TournamentAwardCard from "./components/TournamentAwardCard";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";

export default function TournamentProfile() {
  const dispatch = useAppDispatch();
  const { competition } = useAppSelector((state) => state.footballCompetition);
  const { competitionAwardList, competitionAwardStatus } = useAppSelector((state) => state.footballCompetitionAwards);
  const { selectedGlobalSeasonOption } = useAppSelector((state) => state.globalSeasonSelect);

  const { newsList } = useAppSelector((state) => state.news);

  useEffect(() => {
    if (Number(selectedGlobalSeasonOption.value) && competition.data.id) {
      const awardsOption: IFootballCompetitionAwardOption = {
        competitionId: Number(competition.data.id),
        seasonId: Number(selectedGlobalSeasonOption.value),
        urlParams: {
          perPage: 25,
          sort: "receivedDate",
          sortOrder: SortOrderEnum.Descending,
        },
      };
      dispatch(fetchCompetitionAward(awardsOption));
    }
  }, [Number(selectedGlobalSeasonOption.value)]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status, competition.data.id]);

  return (
    <AppBox className="page-content" gap="md">
      <AppBox displayBlock={true}>
        <AppBox flexDirection="column" gap="md">
          {competition.status === StatusEnum.Loading ? (
            <AppSkeletonBasicInfo />
          ) : (
            <AppBox flexDirection="column" gap="xs">
              <AppTitle as="h5">Basic Information</AppTitle>
              <TournamentBasicInfoCard
                tournament={competition.data}
                season={selectedGlobalSeasonOption.secondaryValue}
              />
            </AppBox>
          )}
        </AppBox>
      </AppBox>
      <AppBox flexDirection="column" gap="sm">
        {competition.status === StatusEnum.Succeeded && (
          <AppBox flexDirection="column" gap="xs">
            <AppTitle as="h5">Awards & Trophies</AppTitle>
          </AppBox>
        )}
        {competitionAwardStatus === StatusEnum.Loading ? (
          <AppSkeletonAwardList />
        ) : (
          <AppBox flexDirection="column" gap="sm">
            {competitionAwardList.length > 0 ? (
              <>
                {competitionAwardList.mapArray((award) => (
                  <TournamentAwardCard key={award.id} award={award} />
                ))}
              </>
            ) : (
              <AppText>There are not any awards.</AppText>
            )}
          </AppBox>
        )}
      </AppBox>
      {newsList.status === StatusEnum.Loading ? (
        <AppSkeletonNewsList isBig />
      ) : (
        <NewsTimelineCard timelineType="big" newsList={newsList.data} />
      )}
    </AppBox>
  );
}
