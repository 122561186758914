import { useEffect, useState } from "react";
import { AppBox, AppPaper, AppCommentaryBadge, AppText } from "../../../../commons/components";
import { MatchEventTypeEnum } from "../../../../commons/enums/match-event-type-enum";
import {
  IFootballCommentary,
  IMatchCommentaryMetadata,
} from "../../../../commons/models/football/interface/i-football-commentary";
import { getCommentaryString } from "../../../../commons/utilities/commentary-utils";
import { formatMatchTime } from "../../../../commons/utilities/game-time-utils";
import { MatchCommentaryMetaDataTypeEnum } from "../../../../commons/enums/match-commentary-metadata-type-enum";
import { IFootballMatchDetail } from "../../../../commons/models/football/interface/i-football-match";

export interface IMatchCommentary {
  matchId: number;
  date?: string;
  league?: string;
  status: MatchEventTypeEnum;
  label: string;
  matchTime: string;
}

interface MatchCommentaryProps {
  match?: IFootballMatchDetail;
  commentary: IFootballCommentary;
}

export default function MatchCommentaryCard({ commentary, match }: MatchCommentaryProps) {
  const [mutatedCommentary, setMutatedCommentary] = useState<IFootballCommentary>();

  const matchStateEvents = [
    MatchEventTypeEnum.MatchStart,
    MatchEventTypeEnum.MatchEnd,
    MatchEventTypeEnum.MatchFirstHalfEnd,
    MatchEventTypeEnum.MatchSecondHalfStart,
    MatchEventTypeEnum.MatchSecondHalfEnd,
    MatchEventTypeEnum.MatchExtraTimeFirstHalfStart,
    MatchEventTypeEnum.MatchExtraTimeFirstHalfEnd,
    MatchEventTypeEnum.MatchExtraTimeSecondHalfStart,
    MatchEventTypeEnum.MatchExtraTimeSecondHalfEnd,
  ];
  const regularGameTime = commentary.gameTime;
  const injuryGameTime = commentary.gameExtraTime ? commentary.gameExtraTime : null;

  useEffect(() => {
    if (commentary.eventType === MatchEventTypeEnum.Penalty) {
      if ((commentary.metadata as IMatchCommentaryMetadata)["type"] === MatchCommentaryMetaDataTypeEnum.Scored) {
        setMutatedCommentary({
          ...commentary,
          eventType: MatchEventTypeEnum.PenaltyScored,
        });
      } else {
        setMutatedCommentary({
          ...commentary,
          eventType: MatchEventTypeEnum.Penalty,
        });
      }
    } else {
      setMutatedCommentary(commentary);
    }
  }, [commentary]);
  return (
    <AppPaper shadow="xs" padding="sm" style={{ position: "relative" }}>
      <AppBox gap="sm">
        {mutatedCommentary && mutatedCommentary.eventType && (
          <AppBox
            style={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              left: "-1rem",
            }}
          >
            <AppCommentaryBadge status={mutatedCommentary.eventType} />
          </AppBox>
        )}
        <AppBox alignItems="center" gap="xs">
          <AppBox className="match-time-width">
            {!matchStateEvents.includes(commentary.eventType) && (
              <AppText as="p" fontWeight="extrabold">
                {formatMatchTime(regularGameTime, injuryGameTime)}
              </AppText>
            )}
          </AppBox>
          <AppBox>
            <AppText as="p" color="muted">
              {getCommentaryString(commentary, match)}
            </AppText>
          </AppBox>
        </AppBox>
      </AppBox>
    </AppPaper>
  );
}
