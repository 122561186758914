import { useEffect, useLayoutEffect, useState } from "react";
import {
  AppAvatar,
  AppBox,
  AppDateAndTime,
  AppGeneralSearch,
  AppLink,
  AppScorePill,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";
import { IFootballSeason } from "../../../../commons/models/football/interface/i-football-season";
import { IFootballTeam } from "../../../../commons/models/football/interface/i-football-team";
import RadioInputGroup, { IRadioInputOptions } from "../../../../commons/components/RadioInputGroup";
import { MatchTeamOutcomePositionEnum } from "../../../../commons/enums/match-team-outcome-position-enum copy";
import { IFootballMatch, ITeamOutcome } from "../../../../commons/models/football/interface/i-football-match";
import { TournamentTypeEnum } from "../../../../commons/enums/tournament-type-enum";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { fetchCompetitionSeasonTeamList } from "../../../../redux/slices/football/admin/competition/manage-competition-season-teams-slice";
import { FootballCompetitionTeam } from "../../../../commons/models/football/football-competition-team";
import AppBorderBox from "../../../../commons/components/BorderBox";
import { isLastIndex } from "../../../../commons/utilities/array-utils";
import { ManageFootballTeam } from "../../../../commons/models/football/admin/manage-football-team";
import { fetchSeasonTeamGroupList } from "../../../../redux/slices/football/admin/season/team-group/manage-team-group-list-slice";
import { IFootballSeasonGroup } from "../../../../commons/models/football/admin/interface/i-manage-football-season-group";
import IconStar from "../../../../commons/components/icons/star";
import { MatchStatusEnum } from "../../../../commons/enums/match-status-enum";
import { getTeamOutcomeLabel } from "../../../../commons/utilities/match-or-matchCard-utils";
import { MatchTeamOutcomeModelTypeEnum } from "../../../../commons/enums/match-team-outcome-model-type-enum";
import { cleanUpGeneralSearchTitle, setGeneralCleanSearchField } from "../../../../redux/slices/general-search-slice";
import { toTitleCase } from "../../../../commons/utilities/string-utils";
import { transformKnockoutTree } from "./EventsBracketComponent";

interface FixtureGroupOrTeamSearchProps {
  onSelectedTeam?: (team: IFootballTeam[]) => void;
  onSelectedTeamOutcome?: (team: ITeamOutcome) => void;
  withTeamOrWinnerLoserSelection?: boolean;
  withGroupPositionSelection?: boolean;
  competition: IFootballCompetition;
  season: IFootballSeason;
  userSelectedTeams?: IFootballTeam[];
  pastRoundMatches?: IFootballMatch[];
}

const FixtureGroupOrTeamSearch = ({
  competition,
  season,
  withGroupPositionSelection,
  withTeamOrWinnerLoserSelection,
  userSelectedTeams,
  pastRoundMatches,
  onSelectedTeam,
  onSelectedTeamOutcome,
}: FixtureGroupOrTeamSearchProps) => {
  const dispatch = useAppDispatch();
  const [filteredCompetitionTeams, setFilteredCompetitionTeams] = useState<FootballCompetitionTeam[]>([]);
  const [filteredCompetitionTeamGroups, setFilteredCompetitionTeamGroups] = useState<IFootballSeasonGroup[]>([]);
  const [filteredCompetitionMatches, setFilteredCompetitionMatches] = useState<IFootballMatch[]>([]);
  const [selectedOption, setSelectedOption] = useState<IRadioInputOptions>({
    title: "All teams",
    value: "teams",
  });
  const [finalTeamOrWinnerOptions, setFinalTeamOrWinnerOptions] = useState<IRadioInputOptions[]>([]);
  let teamOrWinnerOptions: IRadioInputOptions[] = [
    { title: "All teams", value: "teams" },
    { title: "Winner of past match", value: MatchTeamOutcomePositionEnum.Winner },
    { title: "Loser of past match", value: MatchTeamOutcomePositionEnum.Loser },
  ];
  const groupOptions: IRadioInputOptions[] = [
    { title: "1\u02E2\u1D57 position of group", value: MatchTeamOutcomePositionEnum.FirstPosition },
    { title: "2\u207F\u1D48 position of group", value: MatchTeamOutcomePositionEnum.SecondPosition },
  ];
  const { seasonKnockoutList } = useAppSelector((state) => state.footballManageSeasonKnockoutList);
  const { competitionSeasonTeamList } = useAppSelector((state) => state.footballManageCompetitionSeasonTeamList);
  const { seasonTeamGroupList } = useAppSelector((state) => state.footballManageSeasonTeamGroupList);
  const { generalSearchTitle } = useAppSelector((state) => state.generalSearch);

  useLayoutEffect(() => {
    dispatch(setGeneralCleanSearchField(true));
    dispatch(cleanUpGeneralSearchTitle());
    if (withGroupPositionSelection) {
      setSelectedOption(groupOptions[0]);
    }
    if (withTeamOrWinnerLoserSelection) {
      if (competition.type === TournamentTypeEnum.Hybrid) {
        teamOrWinnerOptions = [
          { title: "Winner of past match", value: MatchTeamOutcomePositionEnum.Winner },
          { title: "Loser of past match", value: MatchTeamOutcomePositionEnum.Loser },
        ];
        setFinalTeamOrWinnerOptions(teamOrWinnerOptions);
        setSelectedOption(teamOrWinnerOptions[0]);
      } else if (competition.type === TournamentTypeEnum.Knockout) {
        const knockoutArray = transformKnockoutTree(seasonKnockoutList);
        if (pastRoundMatches?.length === 0 || pastRoundMatches?.length === knockoutArray[0].matches.length) {
          teamOrWinnerOptions = [
            { title: "All teams", value: "teams" },
            { title: "Winner of past match", value: MatchTeamOutcomePositionEnum.Winner },
            { title: "Loser of past match", value: MatchTeamOutcomePositionEnum.Loser },
          ];
        } else {
          teamOrWinnerOptions = [
            { title: "Winner of past match", value: MatchTeamOutcomePositionEnum.Winner },
            { title: "Loser of past match", value: MatchTeamOutcomePositionEnum.Loser },
          ];
        }
        setFinalTeamOrWinnerOptions(teamOrWinnerOptions);
        setSelectedOption(teamOrWinnerOptions[0]);
      }
    }
  }, []);

  useEffect(() => {
    dispatch(
      fetchCompetitionSeasonTeamList({
        competitionId: competition.id,
        seasonId: season.id,
      })
    );
    if (withGroupPositionSelection) {
      dispatch(fetchSeasonTeamGroupList({ seasonId: season.id }));
    }
  }, [competition, season]);

  useEffect(() => {
    handleDefaultTeamFilter();
  }, [competitionSeasonTeamList, userSelectedTeams]);

  useEffect(() => {
    handleDefaultGroupFilter();
  }, [seasonTeamGroupList]);

  useEffect(() => {
    handleDefaultMatchFilter();
  }, [pastRoundMatches]);

  const handleDefaultTeamFilter = () => {
    const selectedIds = new Set();
    if (userSelectedTeams && userSelectedTeams.length > 0) {
      userSelectedTeams.forEach((team) => selectedIds.add(team.id));
    }
    if (competitionSeasonTeamList.length > 0) {
      let filteredTeams = [];
      if (selectedIds) {
        filteredTeams = competitionSeasonTeamList
          .all()
          .filter((competitionTeam) => !selectedIds.has(competitionTeam.team.id));
      } else {
        filteredTeams = competitionSeasonTeamList.all();
      }
      setFilteredCompetitionTeams(filteredTeams);
    } else {
      setFilteredCompetitionTeams([]);
    }
  };

  const handleDefaultGroupFilter = () => {
    if (seasonTeamGroupList.length > 0) {
      const seasonTeamGroupWithTeam = seasonTeamGroupList.all().filter((teamGroup) => teamGroup.seasonTeams.length > 0);
      let groupList: IFootballSeasonGroup[] = [];
      for (const group of seasonTeamGroupWithTeam) {
        groupList.push({ id: group.id, name: group.name });
      }
      setFilteredCompetitionTeamGroups(groupList);
    } else {
      setFilteredCompetitionTeamGroups([]);
    }
  };

  const handleDefaultMatchFilter = () => {
    if (pastRoundMatches && pastRoundMatches.length > 0) {
      const sortedMatches = pastRoundMatches.sort((a, b) => a.id - b.id);
      setFilteredCompetitionMatches(sortedMatches);
    } else {
      setFilteredCompetitionMatches([]);
    }
  };

  const handleTeamClick = (team: ManageFootballTeam) => {
    if (onSelectedTeam) {
      onSelectedTeam([team]);
    }
  };

  const handleTeamOutcomeClick = (id: number, titleOrName: string) => {
    const teamOutcome = {
      modelType: withGroupPositionSelection
        ? MatchTeamOutcomeModelTypeEnum.SeasonGroup
        : MatchTeamOutcomeModelTypeEnum.Match,
      modelId: id,
      position: selectedOption?.value,
      model: withGroupPositionSelection ? { id: id, name: titleOrName } : { id: id, title: titleOrName },
    };
    if (onSelectedTeamOutcome) {
      onSelectedTeamOutcome(teamOutcome as ITeamOutcome);
    }
  };

  useEffect(() => {
    if (generalSearchTitle) {
      const filteredTeamArray = filteredCompetitionTeams.filter(
        (seasonTeam) =>
          seasonTeam.team.name.toLowerCase().includes(generalSearchTitle.toLowerCase()) ||
          seasonTeam.team.shortName.toLowerCase().includes(generalSearchTitle.toLowerCase())
      );
      setFilteredCompetitionTeams(filteredTeamArray);
      if (withGroupPositionSelection) {
        const filteredGroupArray = filteredCompetitionTeamGroups.filter((group) =>
          group.name.toLowerCase().includes(generalSearchTitle.toLowerCase())
        );
        setFilteredCompetitionTeamGroups(filteredGroupArray);
      }
      if (withTeamOrWinnerLoserSelection) {
        const filteredMatchArray = filteredCompetitionMatches.filter((match) => {
          if (match.homeTeam || match.awayTeam) {
            (match.homeTeam && match.homeTeam.name.toLowerCase().includes(generalSearchTitle.toLowerCase())) ||
              (match.awayTeam && match.awayTeam.name.toLowerCase().includes(generalSearchTitle.toLowerCase()));
          }
        });
        setFilteredCompetitionMatches(filteredMatchArray);
      }
    } else {
      handleDefaultTeamFilter();
      withGroupPositionSelection && handleDefaultGroupFilter();
      withTeamOrWinnerLoserSelection && handleDefaultMatchFilter();
    }
  }, [generalSearchTitle]);

  const getMatchStatus = (status: MatchStatusEnum) => {
    switch (status) {
      case MatchStatusEnum.Upcoming:
        return "upcoming";
      case MatchStatusEnum.Live:
        return "live";
      case MatchStatusEnum.Finished:
        return "live";
      default:
        return "upcoming";
    }
  };

  return (
    <AppBox flexDirection="column" gap="md">
      <AppBox flexDirection="column" gap="sm">
        {competition.type === TournamentTypeEnum.League || (
          <>
            {withGroupPositionSelection && competition.type === TournamentTypeEnum.Hybrid && (
              <RadioInputGroup
                name="teamOrGroup"
                options={groupOptions}
                currentOption={selectedOption}
                onChange={(option) => {
                  setSelectedOption(option as IRadioInputOptions);
                }}
              />
            )}
            {withTeamOrWinnerLoserSelection && (
              <RadioInputGroup
                name="teamOrWinner"
                options={finalTeamOrWinnerOptions}
                currentOption={selectedOption}
                onChange={(option) => {
                  setSelectedOption(option as IRadioInputOptions);
                }}
              />
            )}
          </>
        )}
        <AppBox className="w-100 flex-1">
          <AppGeneralSearch />
        </AppBox>
        <AppBox flexDirection="column" pr="xs" style={{ height: "25rem", overflowY: "scroll" }}>
          {selectedOption?.value === "teams" &&
            filteredCompetitionTeams &&
            filteredCompetitionTeams.map((seasonTeam, index) => (
              <AppBorderBox border={isLastIndex(index, filteredCompetitionTeams) ? [] : ["Bottom"]} py="xs" key={index}>
                <AppLink
                  onClick={(e) => {
                    e.preventDefault();
                    handleTeamClick(seasonTeam.team as ManageFootballTeam);
                  }}
                >
                  <AppBox justifyContent="space-between" alignItems="center" className="flex-1">
                    <AppBox gap="xs" alignItems="center">
                      <AppAvatar username={seasonTeam.team.name} src={seasonTeam.team.logo || ""} size="sm" />
                      <AppBox flexDirection="column">
                        <AppTitle as="h6">{toTitleCase(seasonTeam.team.shortName)}</AppTitle>
                        {seasonTeam.team.founded && (
                          <AppBox gap="3xs" alignItems="center">
                            <AppText as="span" fontWeight="semibold" size="sm" color="muted">
                              Foundation Date:
                            </AppText>
                            <AppDateAndTime
                              date={seasonTeam.team.founded}
                              format="DD MMM, YYYY"
                              size="sm"
                              color="muted"
                            />
                          </AppBox>
                        )}
                      </AppBox>
                    </AppBox>
                  </AppBox>
                </AppLink>
              </AppBorderBox>
            ))}
          {selectedOption?.value === "teams" && filteredCompetitionTeams.length === 0 && (
            <AppText>There are not any team</AppText>
          )}
          {withGroupPositionSelection &&
            (selectedOption?.value === MatchTeamOutcomePositionEnum.FirstPosition ||
              selectedOption?.value === MatchTeamOutcomePositionEnum.SecondPosition) &&
            filteredCompetitionTeamGroups &&
            filteredCompetitionTeamGroups.map((group, index) => (
              <AppBorderBox
                border={isLastIndex(index, filteredCompetitionTeams) ? [] : ["Bottom"]}
                py="2xs"
                key={index}
              >
                <AppLink
                  onClick={(e) => {
                    e.preventDefault();
                    handleTeamOutcomeClick(group.id, group.name);
                  }}
                >
                  <AppBox alignItems="center" py="2xs">
                    <AppText as="span" fontWeight="semibold" size="lg" color="muted">
                      {group.name}
                    </AppText>
                  </AppBox>
                </AppLink>
              </AppBorderBox>
            ))}
          {withGroupPositionSelection &&
            (selectedOption?.value === MatchTeamOutcomePositionEnum.FirstPosition ||
              selectedOption?.value === MatchTeamOutcomePositionEnum.SecondPosition) &&
            filteredCompetitionTeamGroups.length === 0 && <AppText>There are not any group</AppText>}

          {withTeamOrWinnerLoserSelection &&
            (selectedOption?.value === MatchTeamOutcomePositionEnum.Winner ||
              selectedOption?.value === MatchTeamOutcomePositionEnum.Loser) &&
            filteredCompetitionMatches &&
            filteredCompetitionMatches.map((match, index) => (
              <AppBorderBox
                border={isLastIndex(index, filteredCompetitionMatches) ? [] : ["Bottom"]}
                py="xs"
                key={index}
              >
                <AppLink
                  onClick={(e) => {
                    e.preventDefault();
                    handleTeamOutcomeClick(match.id, match.title);
                  }}
                >
                  <AppBox flexDirection="column" gap="2xs" alignItems="center">
                    <AppTitle as="h6" textAlign="center">
                      {match.title}
                    </AppTitle>
                    <AppBox alignItems="center" gap="xs" className="w-100" justifyContent="center">
                      <AppBox justifyContent="end" className="flex-1" style={{ maxWidth: "200px" }}>
                        {match.homeTeam ? (
                          <AppBox gap="xs" alignItems="center">
                            <AppText truncate={true} textAlign="right">
                              {match.homeTeam.shortName}
                            </AppText>
                            <AppAvatar username={match.homeTeam?.name || ""} src={match.homeTeam?.logo || ""} />
                          </AppBox>
                        ) : (
                          <AppBox gap="xs" alignItems="center">
                            {match.homeTeamOutcome ? (
                              <AppText truncate={true} textAlign="right">
                                {getTeamOutcomeLabel(match.homeTeamOutcome)}
                              </AppText>
                            ) : (
                              <AppText truncate={true} textAlign="right">
                                TBD
                              </AppText>
                            )}
                            <AppBox className="team-add-svg" alignItems="center" justifyContent="center">
                              <IconStar />
                            </AppBox>
                          </AppBox>
                        )}
                      </AppBox>
                      <AppScorePill
                        matchStatus={getMatchStatus(match.status as MatchStatusEnum)}
                        valueFirst={match.homeGoals || 0}
                        valueSecond={match.awayGoals || 0}
                      />
                      <AppBox justifyContent="start" className="flex-1" style={{ maxWidth: "200px" }}>
                        {match.awayTeam ? (
                          <AppBox gap="xs" alignItems="center">
                            <AppAvatar username={match.awayTeam?.name || ""} src={match.awayTeam?.logo || ""} />
                            <AppText truncate={true} textAlign="left">
                              {match.awayTeam.shortName}
                            </AppText>
                          </AppBox>
                        ) : (
                          <AppBox gap="xs" alignItems="center">
                            <AppBox className="team-add-svg" alignItems="center" justifyContent="center">
                              <IconStar />
                            </AppBox>
                            {match.awayTeamOutcome ? (
                              <AppText truncate={true} textAlign="left">
                                {getTeamOutcomeLabel(match.awayTeamOutcome)}
                              </AppText>
                            ) : (
                              <AppText truncate={true} textAlign="left">
                                TBD
                              </AppText>
                            )}
                          </AppBox>
                        )}
                      </AppBox>
                    </AppBox>
                  </AppBox>
                </AppLink>
              </AppBorderBox>
            ))}
        </AppBox>
      </AppBox>
    </AppBox>
  );
};

export default FixtureGroupOrTeamSearch;
