import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../../commons";
import { IMatchPlayerPassStatisticDTO } from "../../../../../../../api-services/football/admin/manage-football-season-service";

interface ISeasonMatchPlayerPassStatisticEdit {
  seasonMatchPlayerPassStatisticEditResponseStatus: StatusEnum;
}

const initialState: ISeasonMatchPlayerPassStatisticEdit = {
  seasonMatchPlayerPassStatisticEditResponseStatus: StatusEnum.Idle,
};

export interface IFootballSeasonMatchPlayerPassStatisticEditOptions {
  seasonId: number;
  matchId: number;
  playerId: number;
  matchPlayerPassStatisticData: IMatchPlayerPassStatisticDTO;
}

export const seasonMatchPlayerPassStatisticEdit = createAsyncThunk(
  "manage/seasonMatchPlayerPassStatisticEdit",
  async (options: IFootballSeasonMatchPlayerPassStatisticEditOptions, thunkAPI) => {
    try {
      const seasonMatchPlayerPassStatisticEditResponse =
        await manageFootballSeasonService.editSeasonMatchPlayerPassStatistic(
          options.seasonId,
          options.matchId,
          options.playerId,
          options.matchPlayerPassStatisticData
        );
      return seasonMatchPlayerPassStatisticEditResponse;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const seasonMatchPlayerPassStatisticEditSlice = createSlice({
  name: "seasonMatchPlayerPassStatisticEdit",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(seasonMatchPlayerPassStatisticEdit.pending, (state) => {
        state.seasonMatchPlayerPassStatisticEditResponseStatus = StatusEnum.Loading;
      })
      .addCase(seasonMatchPlayerPassStatisticEdit.fulfilled, (state, action) => {
        state.seasonMatchPlayerPassStatisticEditResponseStatus = StatusEnum.Succeeded;
      })
      .addCase(seasonMatchPlayerPassStatisticEdit.rejected, (state) => {
        state.seasonMatchPlayerPassStatisticEditResponseStatus = StatusEnum.Failed;
      });
  },
});

export default seasonMatchPlayerPassStatisticEditSlice.reducer;
