import { AbstractBaseService } from "../../../ensue-react-system/utilities/base-service";
import { ManageFootballPlayer } from "../../../commons/models/football/admin/manage-football-player";
import { PaginatedEnsueCollection } from "../../../ensue-react-system/utilities/paginated-ensue-collection";
import { EnsueCollection } from "../../../ensue-react-system/utilities/ensue-collection";
import { FootballAssociatedCompetition } from "../../../commons/models/football/football-associated-competition";
import { FootballBasicStatistics } from "../../../commons/models/football/football-basic-statistics";
import { FootballMatchDetail } from "../../../commons/models/football/football-match-detail";
import { IFootballAttribute } from "../../../commons/models/football/interface/i-football-player-attribute";
import { FootballAttribute } from "../../../commons/models/football/football-attribute";
import { FootballPlayerAttribute } from "../../../commons/models/football/football-player-attribute";
import { IFootballGroupedPlayerAttribute } from "../../../commons/models/football/interface/i-football-grouped-player-attribute";
import { Environment } from "../../../environments/environment";
import { FootballPlayerYearlyPosition } from "../../../commons/models/football/football-player-team";

export interface IPlayerChangeStatus {
  status: number;
}

export interface IPlayerDTO {
  firstName: string;
  lastName: string;
  middleName: string | null;
  description: string | null;
  avatar: string | null;
  banner: string | null;
  dateOfBirth: string | null;
  birthPlace: string | null;
  height: number | null;
  weight: number | null;
  preferredFoot: string | null;
  strength: string | null;
  weakness: string | null;
  status: number | null;
  displayName: string | null;
  position: string;
}
export interface IPlayerAttributeDTO {
  year: string;
  attributeValues: IAttributeValue[];
}

export interface IAttributeValue {
  attributeId: number;
  value: number;
}

export class ManageFootballPlayerService extends AbstractBaseService<ManageFootballPlayer> {
  protected __model: ManageFootballPlayer = new ManageFootballPlayer();

  public getResourceName(): string {
    return "manage/football/players";
  }

  public addPlayer(playerData: IPlayerDTO) {
    return this.__client.post(this.__url, playerData);
  }

  public editPlayer(playerId: number, playerData: IPlayerDTO) {
    return this.__client.patch(this.__url + "/" + playerId, playerData);
  }

  public setPlayerInactive(playerId: number) {
    return this.__client.patch(this.__url + "/" + playerId + "/inactive", null);
  }

  public changeStatus(playerId: number, body: IPlayerChangeStatus) {
    return this.__client.patch(this.__url + "/" + playerId + "/status", body);
  }

  public getPlayersNotInTeam(urlParams?: {
    [key: string]: string | number | boolean;
  }): Promise<PaginatedEnsueCollection<ManageFootballPlayer>> {
    const playerNotInTeamListModel = new ManageFootballPlayer();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "-not-in-team", options)
      .then(
        (obj) => playerNotInTeamListModel.createFromCollection(obj) as PaginatedEnsueCollection<ManageFootballPlayer>
      );
  }

  public getPlayerCompetitions(playerId: number): Promise<EnsueCollection<FootballAssociatedCompetition>> {
    const competitionModel = new FootballAssociatedCompetition();
    return this.__client
      .get(this.__url + "/" + playerId + "/competitions")
      .then((obj) => competitionModel.createFromCollection(obj) as EnsueCollection<FootballAssociatedCompetition>);
  }

  public getStatistics(
    playerId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<FootballBasicStatistics> {
    const options = this.createHttpParamObject(urlParams);
    const statistics = new FootballBasicStatistics();
    return this.__client.get(this.__url + "/" + playerId + "/statistics", options).then((o) => {
      return statistics.create(o) as FootballBasicStatistics;
    });
  }

  public getPlayerMatches(
    playerId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<PaginatedEnsueCollection<FootballMatchDetail>> {
    const options = this.createHttpParamObject(urlParams);
    const matchModel = new FootballMatchDetail();
    return this.__client
      .get(this.__url + "/" + playerId + "/matches", options)
      .then((obj) => matchModel.createFromCollection(obj) as PaginatedEnsueCollection<FootballMatchDetail>);
  }

  public getPlayerAttributeList(): Promise<EnsueCollection<FootballAttribute>> {
    const attributeModal = new FootballAttribute();
    return this.__client
      .get(this.__endpointProvider.provide() + `/manage/football/player-attributes`)
      .then((obj) => attributeModal.createFromCollection(obj) as EnsueCollection<FootballAttribute>);
  }

  public getPlayerAttributes(
    playerId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<IFootballGroupedPlayerAttribute> {
    const options = this.createHttpParamObject(urlParams);
    return this.__client.get(this.__url + "/" + playerId + "/attributes", options);
  }

  public setPlayerAttributes(playerId: number, playerAttributeData: IPlayerAttributeDTO) {
    return this.__client.patch(this.__url + "/" + playerId + "/attributes", playerAttributeData);
  }

  public getPlayerYearlyPosition(playerId: number): Promise<EnsueCollection<FootballPlayerYearlyPosition>> {
    const attributeModal = new FootballPlayerYearlyPosition();
    return this.__client
      .get(this.__url + "/" + playerId + "/yearly-positions")
      .then((obj) => attributeModal.createFromCollection(obj) as EnsueCollection<FootballPlayerYearlyPosition>);
  }
}
