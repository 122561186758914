import { useEffect } from "react";
import { AppBox, AppPaper, AppText } from "../../../../../commons/components";
import AppBorderBox from "../../../../../commons/components/BorderBox";
import IconAddCircle from "../../../../../commons/components/icons/add-circle";
import { SurfaceColor } from "../../../../../commons/types/core-types";
import IconArrowCircleDown from "../../../../../commons/components/icons/arrow-circle-down";

type FormationBoxComponentProps = {
  onClick?: () => void;
  isDropZone?: boolean;
};

export const FormationBoxComponent = ({ onClick, isDropZone }: FormationBoxComponentProps) => {
  return (
    <AppBorderBox className="w-100 player-grid " borderVariant="dashed" borderColor="success" radius="md">
      <AppBox className="player-cell-container">
        <AppBox className="w-100" onClick={onClick}>
          <AppPaper padding="none" bgColor={SurfaceColor.surface3} className="w-100 player-add-display">
            <AppBox py="xs" flexDirection="column" alignItems="center" justifyContent="center" gap="2xs">
              <IconAddCircle />
              <AppText>Add Player</AppText>
            </AppBox>
          </AppPaper>
        </AppBox>
      </AppBox>
    </AppBorderBox>
  );
};
