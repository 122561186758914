import { BaseModel } from "../../../../ensue-react-system/utilities/base-model";
import {
  IManageFootballMatchPlayerPassStatistics,
} from "./interface/i-manage-football-match-players-statistics";

export class ManageFootballMatchPlayerPassStatistics
  extends BaseModel
  implements IManageFootballMatchPlayerPassStatistics
{
  public id: number = 0;
  public gameTime: number = 0;
  public gameExtraTime: number = 0;
  public passes: number = 0;
  public passSucceeded: number = 0;
  public player = {
    id: 0,
    firstName: "",
    lastName: "",
    middleName: "",
    avatar: "",
    displayName: "",
    position: "",
    fullName: "",
    jerseyNumber: 0,
  };
  public team = {
    id: 0,
    name: "",
    abbreviation: "",
    logo: "",
  };

  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
