import { MatchStatisticsEventEnum } from "../enums/match-statistics-event-enum";
import { MatchTeamOutcomeModelTypeEnum } from "../enums/match-team-outcome-model-type-enum";
import { MatchTeamOutcomePositionEnum } from "../enums/match-team-outcome-position-enum copy";
import { ISeasonGroupModel, ISeasonMatchModel, ITeamOutcome } from "../models/football/interface/i-football-match";

export interface IStatisticsEventObject {
  title: string;
  titleSecondary?: string;
  placeHolderStringPrimary: string;
  placeHolderStringSecondary?: string;
  playerSelectString: string;
}

export const getStatisticsEventObject = (statEvent: MatchStatisticsEventEnum): IStatisticsEventObject => {
  const statsEventObject = {
    POSSESSION: {
      title: "",
      placeHolderStringPrimary: "",
      playerSelectString: "",
    },
    PASSES: {
      title: "Total Passes",
      titleSecondary: "Total Passes Succeeded",
      placeHolderStringPrimary: "Enter the number of total passes executed by the player at the chosen match time.",
      placeHolderStringSecondary: "Enter the number of total passes executed by the player at the chosen match time.",
      playerSelectString: "Choose a player who executed the passes",
    },
    PASS_INTERCEPTED: {
      title: "Total Passes Intercepted",
      placeHolderStringPrimary: "Enter the number of total interceptions executed by player at the chosen match time.",
      playerSelectString: "Choose a player who intercepted the passes",
    },
  };
  return statsEventObject[statEvent] || "";
};

export const getStatisticsKeyString = (key: string): string => {
  switch (key) {
    case MatchStatisticsEventEnum.Passes:
      return "Passes";
    case MatchStatisticsEventEnum.Interception:
      return "Interception";
    default:
      return "";
  }
};

export const getTeamOutcomeLabel = (teamOutcome?: ITeamOutcome): JSX.Element => {
  if (!teamOutcome) {
    return <></>;
  }
  switch (teamOutcome?.modelType) {
    case MatchTeamOutcomeModelTypeEnum.Match:
      const matchModel = teamOutcome.model as ISeasonMatchModel;
      if (matchModel && matchModel.title) {
        if (teamOutcome.position === MatchTeamOutcomePositionEnum.Winner) {
          return <>Winner of {matchModel.title}</>;
        } else {
          return <>Loser of {matchModel.title}</>;
        }
      } else {
        return <></>;
      }

    case MatchTeamOutcomeModelTypeEnum.SeasonGroup:
      const groupModel = teamOutcome.model as ISeasonGroupModel;
      if (groupModel && groupModel.name) {
        if (teamOutcome.position === MatchTeamOutcomePositionEnum.FirstPosition) {
          return (
            <>
              1<sup>st</sup> pos. ({groupModel.name})
            </>
          );
        } else if (teamOutcome.position === MatchTeamOutcomePositionEnum.SecondPosition) {
          return (
            <>
              2<sup>nd</sup> pos. ({groupModel.name})
            </>
          );
        } else {
          return <></>;
        }
      } else {
        return <></>;
      }

    default:
      return <></>;
  }
};
