import { AppBox, AppTitle } from "../../../commons/components";
import { useAppSelector } from "../../../hooks/app";
import { ISelectOptions } from "../../../commons/components/ui-components/Select";
import { MatchStatisticsEventEnum } from "../../../commons/enums/match-statistics-event-enum";
import { MatchStatisticsAdd } from "./components/statistics/MatchStatisticsAdd";
import MatchStatisticsList from "./components/statistics/MatchStatisticsList";
import { MatchStatusEnum } from "../../../commons/enums/match-status-enum";
import { useState } from "react";

export const statisticsEventsOption: ISelectOptions[] = [
  { title: "Possession", value: MatchStatisticsEventEnum.Possession },
  { title: "Passes", value: MatchStatisticsEventEnum.Passes },
  { title: "Interception", value: MatchStatisticsEventEnum.Interception },
];

const MatchDetailManageStatistics = () => {
  const { seasonMatchDetail } = useAppSelector((state) => state.footballManageSeasonMatchDetail);
  const [selectedStatisticEvent, setSelectedStatisticEvent] = useState<string>(MatchStatisticsEventEnum.Possession);

  return (
    <AppBox mb="sm" flexDirection="column" gap="sm">
      <AppBox flexDirection="column" gap="xs">
        <AppTitle as="h5">Add Statistics</AppTitle>
        <MatchStatisticsAdd
          match={seasonMatchDetail}
          onSelectedEvent={(event) => {
            setSelectedStatisticEvent(event);
          }}
          statisticMatchEvent={selectedStatisticEvent}
        />
      </AppBox>
      {seasonMatchDetail.status === MatchStatusEnum.Upcoming || (
        <MatchStatisticsList
          match={seasonMatchDetail}
          statisticMatchEvent={selectedStatisticEvent}
          onSelectedEvent={(event) => {
            setSelectedStatisticEvent(event);
          }}
        />
      )}
    </AppBox>
  );
};

export default MatchDetailManageStatistics;
