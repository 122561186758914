import { useEffect } from "react";
import { Colors, Sizes } from "../types/core-types";
import AppBorderBox from "./BorderBox";
import AppBox from "./Box";
import AppButton from "./Button";
import AppIconButton from "./IconButton";
import AppPaper from "./Paper";
import AppTitle from "./Title";
import IconDismiss from "./icons/dismiss";

export interface ModalProps {
  title?: string;
  titleIcon?: React.ReactNode;
  iconColor?: Colors;
  children?: React.ReactNode;
  opened?: boolean;
  padding?: Sizes | "none";
  modalSize?: Sizes | "2xl";
  withoutButtons?: boolean;
  withoutCancelButton?: boolean;
  withoutCloseButton?: boolean;
  withoutConfirmButton?: boolean;
  withLoadingButton?: boolean;
  confirmButtonLabel?: string;
  style?: object;
  onClose?: (event: React.MouseEvent<HTMLElement, MouseEvent> | Event) => void;
  onSave?: (event: React.MouseEvent<HTMLElement, MouseEvent> | Event) => void;
}

export default function AppModal({
  padding = "sm",
  title,
  titleIcon,
  iconColor = "info",
  opened,
  withoutButtons = false,
  withoutCancelButton = false,
  withoutConfirmButton = false,
  withoutCloseButton = false,
  withLoadingButton = false,
  confirmButtonLabel = "Save",
  children,
  modalSize = "md",
  style,
  onClose,
  onSave,
}: ModalProps) {
  useEffect(() => {
    const openModals = document.querySelectorAll(".modal-overlay").length;
    if (openModals > 0) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
    return () => {
      document.body.style.overflow = "scroll";
    };
  });
  return (
    <>
      {opened && (
        <>
          <AppBox className="modal-overlay" style={style}>
            <AppPaper padding={padding} className={`modal size-${modalSize}`} shadow="sm" radius="sm">
              <AppBox flexDirection="column" gap="xs">
                <AppBorderBox border={["Bottom"]} pb="xs">
                  <AppBox justifyContent="space-between">
                    <AppBox gap="xs" alignItems="center">
                      {titleIcon && (
                        <AppBox className={`title-icon color-${iconColor}`} p="2xs">
                          {titleIcon}
                        </AppBox>
                      )}
                      <AppTitle as="h5">{title}</AppTitle>
                    </AppBox>
                    {withoutCloseButton || (
                      <AppIconButton
                        icon={<IconDismiss />}
                        radius="rounded"
                        variant="outline"
                        color="gray"
                        borderLight
                        onClick={onClose}
                      />
                    )}
                  </AppBox>
                </AppBorderBox>
                {children}
                {withoutButtons || (
                  <AppBox gap="xs" justifyContent="end">
                    {withoutCancelButton || (
                      <AppButton
                        label="Cancel"
                        variant="outline"
                        color="gray"
                        borderLight
                        onClick={onClose}
                        disabled={withLoadingButton}
                      />
                    )}
                    {withoutConfirmButton || (
                      <AppButton
                        label={confirmButtonLabel}
                        variant="light"
                        onClick={onSave}
                        loading={withLoadingButton}
                      />
                    )}
                  </AppBox>
                )}
              </AppBox>
            </AppPaper>
          </AppBox>
        </>
      )}
    </>
  );
}
