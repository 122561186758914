import { useEffect, useState } from "react";
import { AppBox, AppButton, AppModal, AppTitle } from "../../../../commons/components";
import { EmptyGroupComponent } from "./EmptyGroupComponent";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { SeasonStatusEnum } from "../../../../commons/enums/season-status-enum";
import {
  cleanUpSeasonTeamGroups,
  fetchSeasonTeamGroupList,
} from "../../../../redux/slices/football/admin/season/team-group/manage-team-group-list-slice";
import IconBin from "../../../../commons/components/icons/bin";
import { TeamGroupComponent } from "./TeamGroupComponent";
import { IManageFootballSeasonTeamGroup } from "../../../../commons/models/football/admin/interface/i-manage-football-season-group";
import IconGroup from "../../../../commons/components/icons/group";
import SeasonTeamGroupAddEdit from "./SeasonTeamGroupAddEdit";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { seasonTeamGroupDelete } from "../../../../redux/slices/football/admin/season/team-group/manage-team-group-delete-slice";
import SeasonGroupDelete from "./SeasonGroupDelete";
import SeasonGroupDeleteInprogress from "./SeasonGroupDeleteInprogress";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import { FootballCompetitionTeam } from "../../../../commons/models/football/football-competition-team";

const TournamentTeamGroupedComponent = () => {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const { competitionInfo } = useAppSelector((state) => state.footballManageCompetition);
  const { seasonTeamGroupList } = useAppSelector((state) => state.footballManageSeasonTeamGroupList);
  const { seasonTeamGroupDeleteStatus } = useAppSelector((state) => state.footballManageSeasonTeamGroupDelete);
  const { selectedGlobalSeasonOption } = useAppSelector((state) => state.globalSeasonSelect);
  const [isSeasonTeamGroupAddEdit, setSeasonTeamGroupAddEdit] = useState(false);
  const [isDeleteSeasonTeamGroup, setDeleteSeasonTeamGroup] = useState(false);
  const [selectedTeamGroup, setSelectedTeamGroup] = useState<IManageFootballSeasonTeamGroup | null>(null);

  useEffect(() => {
    if (selectedGlobalSeasonOption.value) {
      dispatch(fetchSeasonTeamGroupList({ seasonId: Number(selectedGlobalSeasonOption.value) }));
    } else {
      dispatch(cleanUpSeasonTeamGroups());
    }
  }, [selectedGlobalSeasonOption]);

  const handleDeleteClick = (teamGroup: IManageFootballSeasonTeamGroup) => {
    setSelectedTeamGroup(teamGroup);
    setDeleteSeasonTeamGroup(true);
  };

  const handleEditClick = (teamGroup: IManageFootballSeasonTeamGroup) => {
    setSelectedTeamGroup(teamGroup);
    setSeasonTeamGroupAddEdit(true);
  };

  const handleDeleteConfirm = (e: Event) => {
    e.preventDefault();
    if (selectedTeamGroup) {
      setDeleteSeasonTeamGroup(false);
      dispatch(
        seasonTeamGroupDelete({
          seasonId: Number(selectedGlobalSeasonOption.value),
          teamGroupId: selectedTeamGroup.id,
        })
      )
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "group-delete",
            text: "Group Deleted Successfully",
            variant: "success",
          });
          setSelectedTeamGroup(null);
          dispatch(fetchSeasonTeamGroupList({ seasonId: Number(selectedGlobalSeasonOption.value) }));
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          setSelectedTeamGroup(null);
          addSnackbar({
            key: "error",
            text: error.response?.data.message as string,
            variant: "danger",
          });
        });
    }
  };

  const getCompetitionTeams = (): FootballCompetitionTeam[] => {
    let groupTeams: any[] = [];
    seasonTeamGroupList.forEach((teamGroup) => {
      teamGroup.seasonTeams.forEach((seasonTeam) => {
        groupTeams.push(seasonTeam);
      });
    });
    return groupTeams;
  };

  return (
    <>
      <AppBox mb="sm" gap="xs" flexDirection="column">
        <AppBox alignItems="center" justifyContent="space-between">
          <AppTitle as="h5">Teams</AppTitle>
          {seasonTeamGroupList.length > 0 &&
            selectedGlobalSeasonOption.secondaryValue &&
            selectedGlobalSeasonOption.secondaryValue.status === SeasonStatusEnum.Active && (
              <AppButton
                label="Add Group"
                variant="light"
                onClick={() => {
                  setSeasonTeamGroupAddEdit(true);
                }}
              />
            )}
        </AppBox>
        {seasonTeamGroupList.length === 0 ? (
          <EmptyGroupComponent
            isPastSeason={
              selectedGlobalSeasonOption.value
                ? selectedGlobalSeasonOption.secondaryValue?.status !== SeasonStatusEnum.Active
                : undefined
            }
            competition={competitionInfo}
            onAddClick={() => {
              setSeasonTeamGroupAddEdit(true);
            }}
          />
        ) : (
          <AppBox justifyContent="center" flexDirection="column" gap="sm">
            {seasonTeamGroupList.mapArray((teamGroup, index) => (
              <TeamGroupComponent
                key={index}
                competitionTeams={getCompetitionTeams()}
                competition={competitionInfo}
                teamGroup={teamGroup}
                onEdit={handleEditClick}
                onDelete={handleDeleteClick}
              />
            ))}
          </AppBox>
        )}
      </AppBox>
      {competitionInfo.latestSeason && (
        <AppModal
          title={selectedTeamGroup ? "Edit Team Group" : "Add A Team Group"}
          titleIcon={<IconGroup />}
          opened={isSeasonTeamGroupAddEdit}
          onClose={() => {
            setSeasonTeamGroupAddEdit(false);
            setSelectedTeamGroup(null);
          }}
          withoutButtons
        >
          <SeasonTeamGroupAddEdit
            season={competitionInfo.latestSeason}
            teamGroupData={selectedTeamGroup}
            onSave={() => {
              setSeasonTeamGroupAddEdit(false);
              setSelectedTeamGroup(null);
            }}
            onClose={() => {
              setSeasonTeamGroupAddEdit(false);
              setSelectedTeamGroup(null);
            }}
          />
        </AppModal>
      )}
      <AppModal
        opened={isDeleteSeasonTeamGroup}
        onClose={(e) => {
          setDeleteSeasonTeamGroup(false);
          setSelectedTeamGroup(null);
        }}
        title="Want To Delete This Group?"
        titleIcon={<IconBin />}
        iconColor="danger"
        confirmButtonLabel="Delete"
        onSave={(e) => {
          handleDeleteConfirm(e as Event);
        }}
      >
        <SeasonGroupDelete group={selectedTeamGroup as IManageFootballSeasonTeamGroup} />
      </AppModal>
      <AppModal
        opened={seasonTeamGroupDeleteStatus === StatusEnum.Loading}
        title="Deleting Group"
        titleIcon={<IconBin />}
        iconColor="danger"
        withoutCancelButton
        withoutButtons
        withoutCloseButton
      >
        <SeasonGroupDeleteInprogress group={selectedTeamGroup as IManageFootballSeasonTeamGroup} />
      </AppModal>
    </>
  );
};

export default TournamentTeamGroupedComponent;
