import { useEffect, useState } from "react";
import { AppAvatar, AppBox, AppLink, AppPaper, AppText, AppTitle } from "../../../../commons/components";
import AppLineUpBadge from "../../../../commons/components/ui-components/LineupBadge";
import { IFootballPlayerCommentaries } from "../../../../commons/models/football/interface/i-football-player-lineup";
import { formatMatchTime } from "../../../../commons/utilities/game-time-utils";
import { toSentenceCase, toTitleCase } from "../../../../commons/utilities/string-utils";
import { IMatchEvent } from "../../../../commons/components/interface/i-match";
import { MatchEventTypeEnum } from "../../../../commons/enums/match-event-type-enum";

interface MatchLineupProps {
  player: IFootballPlayerCommentaries;
  isSubstitute?: boolean;
}

export default function MatchLineupCard({ player, isSubstitute }: MatchLineupProps) {
  const [events, setEvents] = useState<IMatchEvent[]>([]);
  const handlePlayerClick = () => {
    window.scrollTo(0, 0);
  };
  const formattedNumber = player?.jerseyNumber?.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });
  const formatLineUpTime = (matchTimeArray: number[], matchExtraTimeArray: number[]) => {
    return matchTimeArray
      .map((matchTime, i) => {
        const extraTime = matchExtraTimeArray[i];
        return extraTime !== 0 ? formatMatchTime(matchTime, extraTime) : formatMatchTime(matchTime);
      })
      .join(", ");
  };
  useEffect(() => {
    const commentaries = player?.commentaries || [];
    const eventMap = commentaries.reduce((eventRecord, event) => {
      const eventType = event.eventType;
      const eventTime = event.gameTime;
      const eventExtraTime = event.gameExtraTime || 0;
      if (!eventRecord[eventType]) {
        eventRecord[eventType] = {
          eventType: eventType as MatchEventTypeEnum,
          eventTime: [eventTime],
          eventExtraTime: [eventExtraTime],
        };
      } else {
        eventRecord[eventType].eventTime.push(eventTime);
        eventRecord[eventType].eventExtraTime.push(eventExtraTime);
      }
      return eventRecord;
    }, {} as Record<MatchEventTypeEnum, IMatchEvent>);
    const eventsArray: IMatchEvent[] = Object.values(eventMap);
    setEvents(eventsArray);
  }, [player]);

  const playerPath = `/players/${player.id}`;
  return (
    <>
      {player && (
        <AppPaper radius="md" padding="xs" shadow="xs">
          <AppLink to={playerPath} onClick={handlePlayerClick}>
            <AppBox alignItems="center" gap="xs" justifyContent="space-between" flexWrap="wrap">
              <AppBox alignItems="center" gap="xs">
                <AppText as="label" size="sm" color="muted" className="min-w-2">
                  {formattedNumber}
                </AppText>
                <AppAvatar username={player.fullName as string} src={player.avatar} />
                <AppBox alignItems="center" className="flex-1" gap="xs">
                  <AppTitle as="h6">{toTitleCase(player.displayName)}</AppTitle>
                  {isSubstitute && <AppText>{toSentenceCase(player.position)}</AppText>}
                  {player.isCaptain && <AppLineUpBadge status="CAPTAIN" size={20} />}
                </AppBox>
              </AppBox>
              {events && events.length > 0 && (
                <AppBox className="flex-1" alignItems="center" gap="xs" justifyContent="end">
                  {events.map((event, index) => (
                    <AppBox gap="2xs" key={index}>
                      <AppBox alignItems="center">
                        <AppText fontWeight="semibold" size="lg">
                          {formatLineUpTime(event.eventTime, event.eventExtraTime)}
                        </AppText>
                      </AppBox>
                      <AppLineUpBadge status={event.eventType} />
                    </AppBox>
                  ))}
                </AppBox>
              )}
            </AppBox>
          </AppLink>
        </AppPaper>
      )}
    </>
  );
}
