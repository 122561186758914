import {
  AppAvatar,
  AppBox,
  AppButton,
  AppIconButton,
  AppLink,
  AppModal,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import IconBin from "../../../../commons/components/icons/bin";
import IconEmpty from "../../../../commons/components/icons/empty";
import SkeletonGlobalCardList from "../../../../commons/components/skeleton/SkeletonGlobalCardList";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { useEffect, useState } from "react";
import { ManageFootballCompetition } from "../../../../commons/models/football/admin/manage-football-competition";
import TournamentDelete from "./TournamentDelete";
import TournamentDeleteInprogress from "./TournamentDeleteInprogress";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import setDocumentTitle from "../../../../commons/utilities/document-title";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { competitionDelete } from "../../../../redux/slices/football/admin/competition/manage-competition-delete-slice";
import { fetchManageCompetitionList } from "../../../../redux/slices/football/admin/competition/manage-competition-list-slice";
import TournamentAddEdit from "./tournamentAddEdit/TournamentAddEdit";
import IconAdd from "../../../../commons/components/icons/add";
import IconEdit from "../../../../commons/components/icons/edit";
import { cleanUpStepperFormData } from "../../../../redux/slices/stepper-form-slice";
import IconTrophy from "../../../../commons/components/icons/trophy";

export default function TournamentList() {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const { competitionList, competitionListStatus } = useAppSelector((state) => state.footballManageCompetitionList);
  const { competitionDeleteStatus } = useAppSelector((state) => state.footballManageCompetitionDelete);
  const [isDeleteTournament, setDeleteTournament] = useState(false);
  const [isAddEditTournament, setAddEditTournament] = useState(false);
  const [selectedTournament, setSelectedTournament] = useState<ManageFootballCompetition | null>(null);

  const handleDeleteClick = (tournament: ManageFootballCompetition) => (event: any) => {
    setSelectedTournament(tournament);
    setDeleteTournament(true);
  };

  const handleEditClick = (tournament: ManageFootballCompetition) => (event: any) => {
    setSelectedTournament(tournament);
    setAddEditTournament(true);
  };

  const handleAfterAddEdit = () => {
    setSelectedTournament(null);
    setAddEditTournament(false);
    dispatch(fetchManageCompetitionList({ sort: "createdAt", sortOrder: "DESC" }));
  };
  const handleAfterAddEditClose = () => {
    setSelectedTournament(null);
    setAddEditTournament(false);
    dispatch(cleanUpStepperFormData());
  };

  const handleDeleteConfirm = (e: Event) => {
    e.preventDefault();
    if (selectedTournament) {
      setDeleteTournament(false);
      dispatch(competitionDelete(selectedTournament.id))
        .unwrap()
        .then(() => {
          addSnackbar({
            key: "tournament-delete-success",
            text: "Tournament Deleted Successfully",
            variant: "success",
          });
          setSelectedTournament(null);
          dispatch(fetchManageCompetitionList({ sort: "createdAt", sortOrder: "DESC" }));
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          addSnackbar({
            key: "error",
            text: error.response?.data.message,
            variant: "danger",
          });
        });
    }
  };

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setDocumentTitle(["Tournaments", "Manage"]);
  }, []);

  return (
    <>
      <AppBox flexDirection="column" gap="xs">
        <AppBox alignItems="center" justifyContent="space-between">
          <AppTitle as="h4">All Tournaments</AppTitle>
          {competitionList.length > 0 && (
            <AppButton label="Add Tournament" onClick={() => setAddEditTournament(true)} />
          )}
        </AppBox>
        {competitionListStatus === StatusEnum.Loading ? (
          <SkeletonGlobalCardList noTitle numberOfItems={10} withAvatar withBadge />
        ) : (
          <>
            {competitionList.length === 0 ? (
              <AppPaper style={{ height: "100%" }}>
                <AppBorderBox borderVariant="dashed">
                  <AppBox
                    flexDirection="column"
                    alignItems="center"
                    gap="sm"
                    justifyContent="center"
                    style={{ height: "35rem" }}
                  >
                    <AppBox alignItems="center" flexDirection="column" justifyContent="center" gap="2xs">
                      <IconEmpty width={56} height={56} color="rgba(var(--border-200))" />
                      <AppTitle as="h6">No Tournaments Found</AppTitle>
                      <AppText>Ready to kick things off? Let's get started by adding tournaments.</AppText>
                    </AppBox>
                    <AppButton label="Add New Tournament" onClick={() => setAddEditTournament(true)} />
                  </AppBox>
                </AppBorderBox>
              </AppPaper>
            ) : (
              <>
                {competitionList.mapArray((competition, index) => (
                  <AppPaper radius="sm" padding="none" shadow="xs" key={index}>
                    <AppBox gap="xs" my="2xs" mx="xs" justifyContent="space-between">
                      <AppLink to={`/manage/tournaments/${competition.id}`} onClick={handleClick}>
                        <AppBox gap="xs" alignItems="center">
                          <AppAvatar username={competition.title} src={competition.logo} size="lg" />
                          <AppTitle as={"h5"}>{competition.title}</AppTitle>
                        </AppBox>
                      </AppLink>
                      <AppBox gap="xs" justifyContent="end" alignItems="center">
                        <AppIconButton icon={<IconEdit />} variant="light" onClick={handleEditClick(competition)} />
                        <AppIconButton
                          icon={<IconBin />}
                          variant="light"
                          color="danger"
                          onClick={handleDeleteClick(competition)}
                        />
                      </AppBox>
                    </AppBox>
                  </AppPaper>
                ))}
              </>
            )}
          </>
        )}
      </AppBox>
      {selectedTournament && (
        <>
          <AppModal
            opened={isDeleteTournament}
            onClose={() => {
              setSelectedTournament(null);
              setDeleteTournament(false);
            }}
            title="Want To Delete This Tournament?"
            titleIcon={<IconBin />}
            iconColor="danger"
            confirmButtonLabel="Delete"
            onSave={(e) => {
              handleDeleteConfirm(e as Event);
            }}
          >
            <TournamentDelete tournament={selectedTournament} />
          </AppModal>
          <AppModal
            opened={competitionDeleteStatus === StatusEnum.Loading}
            title="Deleting Tournament"
            titleIcon={<IconBin />}
            iconColor="danger"
            withoutCancelButton
            withoutButtons
            withoutCloseButton
          >
            <TournamentDeleteInprogress tournament={selectedTournament} />
          </AppModal>
        </>
      )}
      <AppModal
        opened={isAddEditTournament}
        modalSize="lg"
        onClose={handleAfterAddEditClose}
        title={selectedTournament ? "Edit Tournament" : "Add New Tournament"}
        titleIcon={selectedTournament ? <IconEdit /> : <IconTrophy />}
        withoutButtons
      >
        <TournamentAddEdit
          onSave={handleAfterAddEdit}
          onCancel={handleAfterAddEditClose}
          competitionData={selectedTournament}
        />
      </AppModal>
    </>
  );
}
