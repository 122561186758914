import { useLayoutEffect, useState } from "react";
import { AppAvatar, AppBox, AppButton, AppDivider, AppGridBox, AppText } from "../../../../../commons/components";
import { IFootballMatchDetail } from "../../../../../commons/models/football/interface/i-football-match";
import { toTitleCase } from "../../../../../commons/utilities/string-utils";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import { IFootballMatchPenalty } from "../../../../../commons/models/football/interface/i-football-match-penalty";
import MatchPenaltyAddEditDeleteCard from "./MatchPenaltyAddEditDeleteCard";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { fetchSeasonMatchPenaltyList } from "../../../../../redux/slices/football/admin/season/match/manage-season-match-penalty-list-slice";
import { fetchManageMatchTeams } from "../../../../../redux/slices/football/admin/season/match/manage-season-match-teams-slice";

interface MatchPenaltiesEntryProps {
  onEndGame: () => void;
  match: IFootballMatchDetail;
}

export interface IPlayerWithPenalty {
  id: number;
  fullName?: string;
  displayName: string;
  jerseyNumber: number;
  isCaptain: boolean;
  avatar?: string | null;
  playerTeamId: number;
  penalty: IFootballMatchPenalty | null;
}

const MatchPenaltiesEntry = ({ match, onEndGame }: MatchPenaltiesEntryProps) => {
  const dispatch = useAppDispatch();
  const [homeTeamPlayerWithPenaltyList, setHomeTeamPlayerWithPenaltyList] = useState<{
    [x: string]: IPlayerWithPenalty[];
  }>();
  const [awayTeamPlayerWithPenaltyList, setAwayTeamPlayerWithPenaltyList] = useState<{
    [x: string]: IPlayerWithPenalty[];
  }>();
  const [penaltyHomeScoreCount, setPenaltyHomeScoreCount] = useState<number>(0);
  const [penaltyAwayScoreCount, setPenaltyAwayScoreCount] = useState<number>(0);
  const { manageMatchTeams, manageMatchTeamsStatus } = useAppSelector((state) => state.footballManageSeasonMatchTeams);
  const { seasonMatchPenaltyList } = useAppSelector((state) => state.footballManageSeasonMatchPenaltyList);
  const { manageMatchPenaltyAddStatus, manageMatchPenaltyDeleteStatus, manageMatchPenaltyEditStatus } = useAppSelector(
    (state) => state.footballManageSeasonMatchPenaltyAddEditDelete
  );
  const [penaltyRoundCount, setPenaltyRoundCount] = useState<number>();
  const [isPenaltyConcluded, setIsPenaltyConcluded] = useState<boolean>(false);
  useLayoutEffect(() => {
    dispatch(fetchManageMatchTeams({ matchId: match.id, seasonId: match.season.id }));
    dispatch(fetchSeasonMatchPenaltyList({ matchId: match.id, seasonId: match.season.id }));
  }, [match]);

  useLayoutEffect(() => {
    if (
      manageMatchTeams.home.length > 0 &&
      manageMatchTeams.away.length > 0 &&
      match.homeTeam?.id &&
      match.awayTeam?.id
    ) {
      let homeTeamPenaltyGoalCount = 0;
      let awayTeamPenaltyGoalCount = 0;
      const homeFieldPlayers = manageMatchTeams.home.filter((player) => !player.onBench);
      const awayFieldPlayers = manageMatchTeams.away.filter((player) => !player.onBench);
      for (const penalty of seasonMatchPenaltyList.all()) {
        if (penalty.goalScored) {
          if (penalty.teamId === match.homeTeam?.id) {
            homeTeamPenaltyGoalCount++;
          } else {
            awayTeamPenaltyGoalCount++;
          }
        }
      }
      setPenaltyHomeScoreCount(homeTeamPenaltyGoalCount);
      setPenaltyAwayScoreCount(awayTeamPenaltyGoalCount);
      if (seasonMatchPenaltyList.length > 0) {
        setPenaltyRoundCount(
          Math.floor(seasonMatchPenaltyList.length / (homeFieldPlayers.length + awayFieldPlayers.length) + 1)
        );
      } else {
        setPenaltyRoundCount(1);
      }
    }
  }, [seasonMatchPenaltyList, manageMatchTeams]);

  useLayoutEffect(() => {
    if (penaltyRoundCount) {
      for (let round = 1; round <= penaltyRoundCount; round++) {
        handlePenaltyRound(round);
      }
    }
  }, [penaltyRoundCount, seasonMatchPenaltyList]);

  useLayoutEffect(() => {
    const goalDifference = Math.abs(penaltyAwayScoreCount - penaltyHomeScoreCount);
    const latestPenaltyNumber = seasonMatchPenaltyList
      .all()
      .reduce((max, item) => Math.max(max, item.penaltyNumber), 0);
    const totalPenalties = seasonMatchPenaltyList.length;
    const isFullPenaltyRound = totalPenalties === 2 * latestPenaltyNumber;
    const shouldConcludePenalty =
      (latestPenaltyNumber === 3 && isFullPenaltyRound && goalDifference === 3) ||
      (latestPenaltyNumber === 4 && (goalDifference === 3 || (goalDifference === 2 && isFullPenaltyRound))) ||
      (latestPenaltyNumber === 5 && goalDifference === 2) ||
      (latestPenaltyNumber >= 5 && isFullPenaltyRound && goalDifference >= 1);
    setIsPenaltyConcluded(shouldConcludePenalty);
  }, [penaltyHomeScoreCount, penaltyAwayScoreCount, seasonMatchPenaltyList]);

  const handlePenaltyRound = (round: number) => {
    if (
      manageMatchTeams.home.length > 0 &&
      manageMatchTeams.away.length > 0 &&
      match.homeTeam?.id &&
      match.awayTeam?.id
    ) {
      const homeFieldPlayers = manageMatchTeams.home.filter((player) => !player.onBench);
      const awayFieldPlayers = manageMatchTeams.away.filter((player) => !player.onBench);

      let homeTeamPlayerWithPenalty: IPlayerWithPenalty[] = [];
      let awayTeamPlayerWithPenalty: IPlayerWithPenalty[] = [];
      for (const player of homeFieldPlayers) {
        const penaltyData = seasonMatchPenaltyList.all().find((penalty) => {
          if (round === 1) {
            return penalty.playerId === player.id;
          } else {
            return penalty.playerId === player.id && penalty.penaltyNumber > homeFieldPlayers.length * (round - 1);
          }
        });
        homeTeamPlayerWithPenalty.push({
          id: player.id,
          avatar: player.avatar,
          displayName: player.displayName,
          fullName: player.fullName,
          jerseyNumber: player.jerseyNumber,
          isCaptain: player.isCaptain,
          playerTeamId: match.homeTeam.id,
          penalty: penaltyData ? penaltyData : null,
        });
      }

      for (const player of awayFieldPlayers) {
        const penaltyData = seasonMatchPenaltyList.all().find((penalty) => {
          if (round === 1) {
            return penalty.playerId === player.id;
          } else {
            return penalty.playerId === player.id && penalty.penaltyNumber > homeFieldPlayers.length * (round - 1);
          }
        });
        awayTeamPlayerWithPenalty.push({
          id: player.id,
          avatar: player.avatar,
          displayName: player.displayName,
          fullName: player.fullName,
          jerseyNumber: player.jerseyNumber,
          isCaptain: player.isCaptain,
          playerTeamId: match.awayTeam.id,
          penalty: penaltyData ? penaltyData : null,
        });
      }

      const sortedHomeTeamPlayerWithPenalty = homeTeamPlayerWithPenalty.sort((a, b) => {
        if (a.penalty?.penaltyNumber === undefined) return 1;
        if (b.penalty?.penaltyNumber === undefined) return -1;
        return a.penalty.penaltyNumber - b.penalty.penaltyNumber;
      });

      const sortedAwayTeamPlayerWithPenalty = awayTeamPlayerWithPenalty.sort((a, b) => {
        if (a.penalty?.penaltyNumber === undefined) return 1;
        if (b.penalty?.penaltyNumber === undefined) return -1;
        return a.penalty.penaltyNumber - b.penalty.penaltyNumber;
      });

      setHomeTeamPlayerWithPenaltyList((prevState) => ({
        ...prevState,
        [`round-${round}`]: sortedHomeTeamPlayerWithPenalty,
      }));

      setAwayTeamPlayerWithPenaltyList((prevState) => ({
        ...prevState,
        [`round-${round}`]: sortedAwayTeamPlayerWithPenalty,
      }));
    }
  };

  return (
    <AppBox flexDirection="column" gap="sm" style={{ height: "var(--modal-md)" }}>
      <AppBox className="flex-1">
        <AppBox flexDirection="column" gap="xs" alignItems="center" className="w-100">
          <AppBox gap="2xs" alignItems="center">
            <AppAvatar username={match.homeTeam?.shortName || ""} src={match.homeTeam?.logo || ""} />
            <AppText size="lg" fontWeight="bold">
              {toTitleCase(match.homeTeam?.shortName || "")}
            </AppText>
            <AppText size="lg" fontWeight="bold">
              {`(${penaltyHomeScoreCount})`}
            </AppText>
          </AppBox>
          <AppBox
            flexDirection="column"
            gap="xs"
            className="w-100"
            pr="2xs"
            style={{ height: "26rem", overflowY: "scroll" }}
          >
            {penaltyRoundCount && (
              <>
                {(() => {
                  const rounds = [];
                  for (let round = 1; round <= penaltyRoundCount; round++) {
                    const playersForCurrentRound = homeTeamPlayerWithPenaltyList?.[`round-${round}`];
                    rounds.push(
                      <AppBox key={`round-${round}`} flexDirection="column" gap="xs">
                        <AppText textAlign="center" color="muted" size="sm">
                          Penalty Round {round}
                        </AppText>
                        {playersForCurrentRound && playersForCurrentRound.length > 0 ? (
                          playersForCurrentRound.map((player, index) => (
                            <MatchPenaltyAddEditDeleteCard
                              match={match}
                              player={player}
                              key={`round-${round}-${index}`}
                              switchId={`round-${round}-${index}-${player.id}`}
                              isPenaltyDisable={isPenaltyConcluded && player.penalty === null}
                            />
                          ))
                        ) : (
                          <AppText>No players for this round</AppText>
                        )}
                      </AppBox>
                    );
                  }
                  return rounds;
                })()}
              </>
            )}
          </AppBox>
        </AppBox>
        <AppDivider orientation="vertical" mx="2xs" />
        <AppBox flexDirection="column" gap="xs" alignItems="center" className="w-100">
          <AppBox gap="2xs" alignItems="center">
            <AppAvatar username={match.awayTeam?.shortName || ""} src={match.awayTeam?.logo || ""} />
            <AppText size="lg" fontWeight="bold">
              {toTitleCase(match.awayTeam?.shortName || "")}
            </AppText>
            <AppText size="lg" fontWeight="bold">
              {`(${penaltyAwayScoreCount})`}
            </AppText>
          </AppBox>
          <AppBox
            flexDirection="column"
            gap="xs"
            className="w-100"
            pr="2xs"
            style={{ height: "26rem", overflowY: "scroll" }}
          >
            {penaltyRoundCount && (
              <>
                {(() => {
                  const rounds = [];
                  for (let round = 1; round <= penaltyRoundCount; round++) {
                    const playersForCurrentRound = awayTeamPlayerWithPenaltyList?.[`round-${round}`];
                    rounds.push(
                      <AppBox key={`round-${round}`} flexDirection="column" gap="xs">
                        <AppText textAlign="center" color="muted" size="sm">
                          Penalty Round {round}
                        </AppText>
                        {playersForCurrentRound && playersForCurrentRound.length > 0 ? (
                          playersForCurrentRound.map((player, index) => (
                            <MatchPenaltyAddEditDeleteCard
                              match={match}
                              player={player}
                              key={`round-${round}-${index}`}
                              switchId={`round-${round}-${index}-${player.id}`}
                              isPenaltyDisable={isPenaltyConcluded && player.penalty === null}
                            />
                          ))
                        ) : (
                          <AppText>No players for this round</AppText>
                        )}
                      </AppBox>
                    );
                  }
                  return rounds;
                })()}
              </>
            )}
          </AppBox>
        </AppBox>
      </AppBox>
      <AppBox gap="xs" justifyContent="end">
        <AppButton
          label="End Game"
          onClick={() => {
            onEndGame();
          }}
          disabled={
            manageMatchPenaltyAddStatus === StatusEnum.Loading ||
            manageMatchPenaltyEditStatus === StatusEnum.Loading ||
            manageMatchPenaltyDeleteStatus === StatusEnum.Loading
          }
        />
      </AppBox>
    </AppBox>
  );
};

export default MatchPenaltiesEntry;
