import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../../commons";
import { ManageFootballMatchPossession } from "../../../../../../../commons/models/football/admin/manage-football-match-possession";
import { PaginatedEnsueCollection } from "../../../../../../../ensue-react-system/utilities/paginated-ensue-collection";

export interface IManageSeasonMatchPossessionListState {
  seasonMatchPossessionList: PaginatedEnsueCollection<ManageFootballMatchPossession>;
  seasonMatchPossessionListStatus: StatusEnum;
}

export interface IManageSeasonMatchPossessionListOption {
  seasonId: number;
  matchId: number;
  urlParams?: {
    page?: number;
    perPage?: number;
    sort?: string;
    sortOrder?: string;
  };
}

const initialState: IManageSeasonMatchPossessionListState = {
  seasonMatchPossessionList: new PaginatedEnsueCollection<ManageFootballMatchPossession>(),
  seasonMatchPossessionListStatus: StatusEnum.Idle,
};

export const fetchSeasonMatchPossessionList = createAsyncThunk(
  "manage/seasonMatchPossessionList",
  async (options: IManageSeasonMatchPossessionListOption, thunkAPI) => {
    try {
      const seasonMatchPossessionList = await manageFootballSeasonService.getSeasonMatchPossessionList(
        options.seasonId,
        options.matchId,
        options.urlParams
      );
      return seasonMatchPossessionList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageSeasonMatchPossessionListSlice = createSlice({
  name: "manageSeasonMatchPossessionList",
  initialState: initialState,
  reducers: {
    cleanUpSeasonMatchPossessionList(state) {
      state.seasonMatchPossessionList = new PaginatedEnsueCollection<ManageFootballMatchPossession>();
      state.seasonMatchPossessionListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSeasonMatchPossessionList.pending, (state) => {
        state.seasonMatchPossessionListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchSeasonMatchPossessionList.fulfilled,
        (state, action: PayloadAction<PaginatedEnsueCollection<ManageFootballMatchPossession>>) => {
          state.seasonMatchPossessionListStatus = StatusEnum.Succeeded;
          state.seasonMatchPossessionList = action.payload;
        }
      )
      .addCase(fetchSeasonMatchPossessionList.rejected, (state) => {
        state.seasonMatchPossessionListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpSeasonMatchPossessionList } = manageSeasonMatchPossessionListSlice.actions;
export default manageSeasonMatchPossessionListSlice.reducer;
