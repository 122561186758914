import { useEffect, useState } from "react";
import {
  AppBox,
  AppGridBox,
  AppSkeletonGlobalCardList,
  AppSkeletonNewsList,
  AppText,
  AppTitle,
} from "../../../commons/components";
import AppTabs, { TabItem } from "../../../commons/components/Tabs";
import { useParams, useSearchParams } from "react-router-dom";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { RootState } from "../../../redux/store";
import { IFootballCommentaryOption } from "../../../redux/interfaces/i-football-commentary-state";
import { fetchMatchCommentary } from "../../../redux/slices/football/public/match/football-match-commentary-slice";
import MatchCommentaryCard from "./components/MatchCommentaryCard";
import { getQueryParams } from "../../../commons/utilities/filterParams";
import { FootballCommentary } from "../../../commons/models/football/football-commentary";
import { MatchKeyEventEnum } from "../../../commons/enums/match-key-event-enum";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import { MatchStatusEnum } from "../../../commons/enums/match-status-enum";
import MatchPenaltyList from "../../admin/match/components/penalty/MatchPenaltyLIst";

export default function MatchCommentary() {
  const { match } = useAppSelector((state) => state.footballMatchDetail);
  const dispatch = useAppDispatch();
  const { commentaryList, commentaryListStatus } = useAppSelector((state: RootState) => state.footballMatchCommentary);
  const { newsList } = useAppSelector((state: RootState) => state.news);

  const tabItems = [
    {
      label: "All Commentary",
      value: "all-commentary",
    },
    {
      label: "Key Events",
      value: "key-events",
    },
  ];
  const [_, setSearchParams] = useSearchParams();
  const [commentaryType, setCommentaryType] = useState("all-commentary");
  const queryParams = getQueryParams();
  const [commentary, setCommentary] = useState<FootballCommentary[]>([]);
  useEffect(() => {
    if (match.id) {
      let commentaryOptions: IFootballCommentaryOption = {
        matchId: Number(match.id),
        urlParams: {
          sort: "gameTime",
          sortOrder: SortOrderEnum.Descending,
        },
      };
      dispatch(fetchMatchCommentary(commentaryOptions));
      setCommentaryType(queryParams.isKeyEvents === "true" ? "key-events" : "all-commentary");
    }
  }, [match]);

  useEffect(() => {
    const urlParams = {
      isKeyEvents: commentaryType === "key-events" ? "true" : "false",
    };
    setSearchParams(urlParams, {
      replace: true,
    });
  }, [commentaryType]);

  useEffect(() => {
    if (queryParams.isKeyEvents === "true") {
      const matchKeyEvents: string[] = Object.values(MatchKeyEventEnum);
      const filteredCommentary = commentaryList
        .all()
        .filter((commentary) => matchKeyEvents.includes(commentary.eventType));
      setCommentary(filteredCommentary);
    } else {
      setCommentary(commentaryList.all());
    }
  }, [queryParams.isKeyEvents, commentaryList]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  const handleTabChange = (args: TabItem) => {
    setCommentaryType(args.value);
  };

  return (
    <AppBox className="page-content" gap="md">
      <AppBox flexDirection="column" gap="sm">
        {commentaryListStatus === StatusEnum.Loading ? (
          <AppSkeletonGlobalCardList numberOfItems={20} withText />
        ) : (
          <>
            {match.penalties.length > 0 && <MatchPenaltyList match={match} />}
            <AppBox flexDirection="column" gap="xs">
              <AppTitle as="h5">Match Commentary</AppTitle>
              <AppBox>
                <AppTabs
                  as="button"
                  onClick={handleTabChange}
                  tabItems={tabItems}
                  variant="filled"
                  shadow="xs"
                  activeValue={commentaryType}
                  isFilterTabs
                />
              </AppBox>
              {commentary.length > 0 ? (
                <AppBox flexDirection="column" gap="sm" pl="xs">
                  {commentary.map((commentaryItem, index) => (
                    <MatchCommentaryCard key={index} commentary={commentaryItem} match={match} />
                  ))}
                </AppBox>
              ) : (
                <AppText>There are no available commentaries.</AppText>
              )}
            </AppBox>
          </>
        )}
      </AppBox>
      {newsList.status === (StatusEnum.Loading || StatusEnum.Idle) ? (
        <AppSkeletonNewsList />
      ) : (
        <NewsTimelineCard newsList={newsList.data} timelineType="small" />
      )}
    </AppBox>
  );
}
