import { appStorage } from "../../../../commons";

interface BracketSVGComponentProps {
  id: string;
  parentMatchPosition: { x: number; y: number } | null;
  currentMatchPosition: { x: number; y: number } | null;
}
const getTopLeftPosition = (id: string): { x: number; y: number } | null => {
  const element = document.getElementById(id);
  if (element) {
    const rect = element.getBoundingClientRect();
    const TopLeftPosition = {
      x: rect.left,
      y: rect.top,
    };
    return TopLeftPosition;
  } else {
    return null;
  }
};

export const BracketSVGComponent = ({ currentMatchPosition, parentMatchPosition, id }: BracketSVGComponentProps) => {
  if (currentMatchPosition === null || parentMatchPosition === null) {
    return <></>;
  } else {
    const svgContainerPosition = getTopLeftPosition(id);
    if (svgContainerPosition === null) {
      return <></>;
    } else {
      if (currentMatchPosition.y < parentMatchPosition.y) {
        const svgStartingX = Math.abs(currentMatchPosition.x - svgContainerPosition.x);
        const svgStartingY = Math.abs(currentMatchPosition.y - svgContainerPosition.y);
        const svgEndingX = Math.abs(parentMatchPosition.x - svgContainerPosition.x);
        const svgEndingY = Math.abs(parentMatchPosition.y - svgContainerPosition.y);
        return (
          <svg
            width="100"
            height={`${svgEndingY + 20}px`}
            overflow="auto"
            style={{ position: "absolute", right: "-4.5rem", top: `-${svgStartingY + 10}px`, pointerEvents: "none" }}
          >
            <path
              d={`M ${svgStartingX} ${svgStartingY + 10} L  50 ${svgStartingY + 10} L 50 ${svgEndingY + 10} ${
                svgEndingX + 20
              } ${svgEndingY + 10}`}
              stroke="rgba(var(--border-200))"
              strokeDasharray="0 0"
              strokeWidth="1.5"
              fill="transparent"
              pointerEvents="visibleStroke"
            ></path>
          </svg>
        );
      } else {
        const svgStartingX = Math.abs(parentMatchPosition.x - svgContainerPosition.x);
        const svgStartingY = Math.abs(parentMatchPosition.y - svgContainerPosition.y);
        const svgEndingX = Math.abs(currentMatchPosition.x - svgContainerPosition.x);
        const svgEndingY = Math.abs(currentMatchPosition.y - svgContainerPosition.y);
        return (
          <svg
            width="100"
            height={`${svgStartingY + 20}px`}
            overflow="auto"
            style={{ position: "absolute", right: "-4.5rem", top: `-${svgStartingY + 10}px`, pointerEvents: "none" }}
          >
            <path
              d={`M ${svgStartingX + 90} ${svgEndingY + 10} L  50 ${svgEndingY + 10} L 50 ${
                svgStartingY + 15
              } ${svgEndingX} ${svgStartingY + 15}`}
              stroke="rgba(var(--border-200))"
              strokeDasharray="0 0"
              strokeWidth="1.5"
              fill="transparent"
              pointerEvents="visibleStroke"
            ></path>
          </svg>
        );
      }
    }
  }
};
