import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as SmallLogo } from "../../../assets/images/logo/logo-small.svg";
import { ReactComponent as LargeLogo } from "../../../assets/images/logo/logo-large.svg";
import AppBox from "../Box";
import AppText from "../Text";
import IconDismiss from "../icons/dismiss";
import AppDivider from "../Divider";
import IconPeopleTeam from "../icons/people-team";
import IconStarStack from "../icons/star-stack";
import IconWhistle from "../icons/whistle";
import IconTrophy from "../icons/trophy";
import IconStadium from "../icons/stadium";
import { Tooltip } from "react-tooltip";
import { useAppDispatch } from "../../../hooks/app";
import { setTheme, setUserType } from "../../../redux/slices/session-slice";
import { appStorage } from "../..";
import IconMenu from "../icons/menu";

export type NavItemProps = {
  link: string;
  label: string;
  icon: React.ReactNode;
};

const ManageSidenav = () => {
  const dispatch = useAppDispatch();
  const sidenavRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(() => {
    const themeType = appStorage.getItem("appTheme");
    return themeType === "dark";
  });

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", isDarkTheme ? "dark" : "light");
    dispatch(setTheme(isDarkTheme ? "dark" : "light"));
    appStorage.setItem("appTheme", isDarkTheme ? "dark" : "light");
  }, [isDarkTheme, dispatch]);

  useEffect(() => {
    dispatch(setUserType("admin"));
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sidenavRef.current && !sidenavRef.current.contains(event.target as Node)) {
        setIsPinned(false);
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const toggleSideNav = () => {
    if (isPinned) {
      setIsPinned(false);
      setIsOpen(false);
    } else {
      setIsPinned(true);
      setIsOpen(true);
    }
  };

  const navItem: Array<NavItemProps> = [
    {
      link: "/manage/players",
      label: "Manage Players",
      icon: <IconPeopleTeam />,
    },
    {
      link: "/manage/teams",
      label: "Manage Teams",
      icon: <IconStarStack />,
    },
    {
      link: "/manage/tournaments",
      label: "Manage Tournaments",
      icon: <IconTrophy />,
    },
    {
      link: "/manage/referees",
      label: "Manage Referees",
      icon: <IconWhistle />,
    },
    {
      link: "/manage/stadiums",
      label: "Manage Venues",
      icon: <IconStadium />,
    },
  ];

  const handleClick = () => {
    if (isPinned) {
      setIsPinned(false);
      setIsOpen(false);
    }
    window.scrollTo(0, 0);
  };

  return (
    <nav className={`ee-side-nav ${isOpen ? "opened" : "closed"} ${isPinned && "pinned"}`} ref={sidenavRef}>
      <AppBox flexDirection="column" className="flex-1" gap="3xl" style={{ height: "100%" }}>
        <AppBox flexDirection="column">
          <AppBox justifyContent="space-between" onClick={toggleSideNav}>
            {isOpen ? (
              <>
                <AppBox className="ee-side-nav-logo ee-logo-large">{<LargeLogo />}</AppBox>
                {isPinned && (
                  <button className="btn" onClick={toggleSideNav}>
                    <IconDismiss />
                  </button>
                )}
              </>
            ) : (
              <>
                <AppBox className="ee-side-nav-logo ee-small-logo">{<SmallLogo />}</AppBox>
                <AppBox className="ee-side-nav-mobile">
                  <button className="btn" onClick={toggleSideNav}>
                    <IconMenu />
                  </button>
                  <AppBox className="ee-side-nav-logo ee-logo-large">{<LargeLogo />}</AppBox>
                </AppBox>
              </>
            )}
          </AppBox>
          <AppDivider mt="xs" />
          <AppBox flexDirection="column" gap="xs" pt="lg">
            {navItem.map((item: NavItemProps, index) => (
              <Fragment key={index}>
                <li className={`ee-side-nav-item `} key={item.label} data-tooltip-id={`nav-${index}`}>
                  <NavLink className={`ee-side-nav-link`} to={item.link} onClick={handleClick}>
                    <span className="ee-side-nav-icon">{item.icon}</span>
                    <span className="ee-side-nav-label">{isOpen && <AppText as="label">{item.label}</AppText>}</span>
                  </NavLink>
                </li>
                {isOpen || (
                  <Tooltip
                    id={`nav-${index}`}
                    content={item.label}
                    place="right"
                    variant={isDarkTheme ? "light" : "dark"}
                  />
                )}
              </Fragment>
            ))}
          </AppBox>
        </AppBox>
        <AppBox gap="xs" alignItems="end" className="flex-1">
          <AppBox className="checkbox-switch">
            <input
              type="checkbox"
              id="theme-switch"
              checked={isDarkTheme}
              onChange={(e) => {
                setIsDarkTheme(e.target.checked);
              }}
            />
            <label htmlFor="theme-switch" data-tooltip-id="theme-switch"></label>
          </AppBox>
          <span className="ee-side-nav-label">
            {isOpen && (
              <AppText htmlFor="theme-switch" as="label">
                {isDarkTheme ? "Switch to light" : "Switch to dark"}
              </AppText>
            )}
          </span>
          {isOpen || (
            <Tooltip
              id="theme-switch"
              content={isDarkTheme ? "Switch to light" : "Switch to dark"}
              place="right"
              variant={isDarkTheme ? "light" : "dark"}
            />
          )}
        </AppBox>
      </AppBox>
    </nav>
  );
};

export default ManageSidenav;
