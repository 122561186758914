import { IPlayerWithPenalty } from "./MatchPenaltiesEntry";
import { IFootballMatchDetail } from "../../../../../commons/models/football/interface/i-football-match";
import AppBorderBox from "../../../../../commons/components/BorderBox";
import { AppAvatar, AppBox, AppIconButton, AppText } from "../../../../../commons/components";
import AppLineUpBadge from "../../../../../commons/components/ui-components/LineupBadge";
import TripleToggleSwitch, { SwitchPositionType } from "../../../../../commons/components/TripleToggleSwitch";
import IconDismiss from "../../../../../commons/components/icons/dismiss";
import IconCheck from "../../../../../commons/components/icons/check";
import { useEffect, useState } from "react";
import { IFootballMatchPenalty } from "../../../../../commons/models/football/interface/i-football-match-penalty";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import {
  AddMatchPenalty,
  DeleteMatchPenalty,
  EditMatchPenalty,
} from "../../../../../redux/slices/football/admin/season/match/manage-season-match-penalty-add-edit-delete-slice";
import { IMatchPenaltyDTO } from "../../../../../api-services/football/admin/manage-football-season-service";
import { useSnackbar } from "../../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../../commons/components/interface";
import AppLoader from "../../../../../commons/components/Loader";
import { fetchSeasonMatchPenaltyList } from "../../../../../redux/slices/football/admin/season/match/manage-season-match-penalty-list-slice";

interface MatchPenaltiesAddEditDeleteProps {
  match: IFootballMatchDetail;
  player: IPlayerWithPenalty;
  switchId: string;
  isPenaltyDisable?: boolean;
}

const MatchPenaltyAddEditDeleteCard = ({
  match,
  player,
  switchId,
  isPenaltyDisable,
}: MatchPenaltiesAddEditDeleteProps) => {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const [formattedPenaltyData, setFormattedPenaltyData] = useState<IFootballMatchPenalty | null>(null);
  const [currentTogglePosition, setCurrentTogglePosition] = useState<SwitchPositionType>("center");
  const [togglePosition, setTogglePosition] = useState<SwitchPositionType>();
  const [toggleValueChanged, setToggleValueChanged] = useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);
  const [homePenaltyCount, setHomePenaltyCount] = useState<number>(0);
  const [awayPenaltyCount, setAwayPenaltyCount] = useState<number>(0);
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const { seasonMatchPenaltyList } = useAppSelector((state) => state.footballManageSeasonMatchPenaltyList);
  const toggleOption = {
    left: {
      title: "Goal",
      value: true,
    },
    center: {
      title: "",
      value: null,
    },
    right: {
      title: "Miss",
      value: false,
    },
  };
  const formattedNumber = player.jerseyNumber?.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });

  useEffect(() => {
    if (match.id) {
      let homeTeamPenaltyCount = 0;
      let awayTeamPenaltyCount = 0;
      for (const penalty of seasonMatchPenaltyList.all()) {
        if (penalty.teamId === match.homeTeam?.id) {
          homeTeamPenaltyCount++;
        } else {
          awayTeamPenaltyCount++;
        }
      }
      setHomePenaltyCount(homeTeamPenaltyCount);
      setAwayPenaltyCount(awayTeamPenaltyCount);
    }
  }, [match, seasonMatchPenaltyList]);

  useEffect(() => {
    setFormattedPenaltyData(player.penalty);
  }, [player]);

  useEffect(() => {
    if (formattedPenaltyData) {
      if (formattedPenaltyData.goalScored) {
        setCurrentTogglePosition("left");
      } else {
        setCurrentTogglePosition("right");
      }
    } else {
      setCurrentTogglePosition("center");
    }
  }, [formattedPenaltyData]);

  const handleToggleChange = (value: SwitchPositionType) => {
    if (value !== currentTogglePosition) {
      setToggleValueChanged(true);
      setTogglePosition(value);
    } else {
      setToggleValueChanged(false);
    }
  };

  const handleConfirm = () => {
    setIsLoader(true);
    if (togglePosition === "center" && formattedPenaltyData) {
      dispatch(
        DeleteMatchPenalty({
          seasonId: match.season.id,
          matchId: match.id,
          penaltyId: formattedPenaltyData.id,
        })
      )
        .unwrap()
        .then(() => {
          addSnackbar({
            key: `penalty-delete`,
            text: "Penalty Removed Successfully",
            variant: "success",
          });
          dispatch(fetchSeasonMatchPenaltyList({ matchId: match.id, seasonId: match.season.id }));
          setReset(!reset);
          setToggleValueChanged(false);
          setIsLoader(false);
        })
        .catch(handleError);
    } else {
      const penaltyAddData: IMatchPenaltyDTO = {
        playerId: player.id,
        goalScored: togglePosition === "left" ? true : false,
        penaltyNumber: player.playerTeamId === match.homeTeam?.id ? homePenaltyCount + 1 : awayPenaltyCount + 1,
      };
      const penaltyEditData: IMatchPenaltyDTO = {
        playerId: player.id,
        goalScored: togglePosition === "left" ? true : false,
      };
      dispatch(
        formattedPenaltyData
          ? EditMatchPenalty({
              seasonId: match.season.id,
              matchId: match.id,
              penaltyId: formattedPenaltyData.id,
              penaltyInfo: penaltyEditData,
            })
          : AddMatchPenalty({
              seasonId: match.season.id,
              matchId: match.id,
              penaltyInfo: penaltyAddData,
            })
      )
        .unwrap()
        .then(() => {
          formattedPenaltyData
            ? addSnackbar({
                key: `penalty-edit`,
                text: "Penalty Edited Successfully",
                variant: "success",
              })
            : addSnackbar({
                key: `penalty-add`,
                text: "Penalty Added Successfully",
                variant: "success",
              });
          dispatch(fetchSeasonMatchPenaltyList({ matchId: match.id, seasonId: match.season.id }));
          setReset(!reset);
          setToggleValueChanged(false);
          setIsLoader(false);
        })
        .catch(handleError);
    }
  };

  const handleDismiss = () => {
    setReset(!reset);
    setToggleValueChanged(false);
  };

  const handleError = (error: AxiosError<IServerErrorResponse>) => {
    handleDismiss();
    const responseData = error.response?.data;
    addSnackbar({
      key: `error ${responseData?.message}`,
      text: responseData?.message,
      variant: "danger",
    });
    setIsLoader(false);
  };

  return (
    <AppBorderBox radius="sm" className="w-100 flex-1">
      <AppBox justifyContent="space-between" className="w-100 flex-1" px="xs" py="2xs" style={{ position: "relative" }}>
        <AppBox className="flex-1" gap="2xs" alignItems="center">
          <AppText size="sm" color="muted">
            {formattedNumber}
          </AppText>
          <AppAvatar size="xs" username={player.displayName || ""} src={player.avatar || ""} />
          <AppBox flexDirection="column">
            <AppBox alignItems="center" gap="2xs">
              <AppText size="lg">{player.displayName}</AppText>
              {player.isCaptain && <AppLineUpBadge status="CAPTAIN" size={20} />}
            </AppBox>
          </AppBox>
        </AppBox>
        <AppBox alignItems="center" gap="sm">
          <TripleToggleSwitch
            switchId={switchId}
            onChange={handleToggleChange}
            withoutCenter
            labels={toggleOption}
            currentOption={currentTogglePosition}
            reset={reset}
            disable={isPenaltyDisable}
          />
          <AppBox gap="2xs">
            <AppIconButton
              variant="light"
              icon={<IconCheck />}
              color="success"
              size="xs"
              disabled={!toggleValueChanged}
              onClick={handleConfirm}
            />
            <AppIconButton
              variant="light"
              color="danger"
              icon={<IconDismiss />}
              size="xs"
              disabled={!toggleValueChanged}
              onClick={handleDismiss}
            />
          </AppBox>
        </AppBox>
        {isLoader && <AppLoader withinContent variant="dots" size="xs" />}
      </AppBox>
    </AppBorderBox>
  );
};

export default MatchPenaltyAddEditDeleteCard;
