import { AppBox, AppGridBox, AppSelect, AppSkeletonNewsList, AppText, AppTitle } from "../../../commons/components";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import AppTabs, { TabItem } from "../../../commons/components/Tabs";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import { TournamentStatsEnum } from "../../../commons/enums/tournament-stats-enum";
import { getQueryParams } from "../../../commons/utilities/filterParams";
import TournamentPlayerStatistics from "./components/TournamentPlayerStatistics";
import TournamentTeamStatistics from "./components/TournamentTeamStatistics";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";

export default function TournamentStatistics() {
  const statisticsOptions = [
    { title: "Goals", value: TournamentStatsEnum.Goals },
    { title: "Assists", value: TournamentStatsEnum.Assists },
    { title: "Red Cards", value: TournamentStatsEnum.RedCards },
    { title: "Yellow Cards", value: TournamentStatsEnum.YellowCards },
  ];

  const tabItems = [
    {
      label: "Players",
      value: "players",
    },
    {
      label: "Teams",
      value: "teams",
    },
  ];
  const dispatch = useAppDispatch();
  const [statsOf, setStatsOf] = useState("players");
  const [selectedStat, setSelectedStat] = useState(TournamentStatsEnum.Goals);
  const [, setSearchParams] = useSearchParams();
  const queryParams = getQueryParams();
  const { newsList } = useAppSelector((state) => state.news);
  const { competition } = useAppSelector((state) => state.footballCompetition);
  const { seasonList } = useAppSelector((state) => state.footballSeason);
  const { selectedGlobalSeasonOption } = useAppSelector((state) => state.globalSeasonSelect);

  useEffect(() => {
    setStatsOf(queryParams.statsOf === "teams" ? "teams" : "players");
  }, []);

  useEffect(() => {
    const urlParams = {
      statsOf: statsOf === "players" ? "players" : "teams",
    };
    setSearchParams(urlParams, {
      replace: true,
    });
  }, [statsOf]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  const handleTabChange = (args: TabItem) => {
    setStatsOf(args.value);
  };

  return (
    <AppBox className="page-content" gap="md">
      <AppBox flexDirection="column" gap="sm">
        {seasonList.length > 0 ? (
          <>
            <AppBox flexDirection="column" gap="xs">
              <AppTitle as="h5">{"League Statistics"}</AppTitle>
              <AppGridBox columns={2} gap="sm">
                <AppSelect
                  options={statisticsOptions}
                  onChange={(option) => setSelectedStat(option.value as TournamentStatsEnum)}
                />
              </AppGridBox>
            </AppBox>
            <AppTabs
              tabItemClassName="flex-1"
              className="w-1/2"
              onClick={handleTabChange}
              activeValue={statsOf}
              tabItems={tabItems}
              as="button"
              variant="filled"
              shadow="xs"
              isFilterTabs
            />
            {statsOf === "players" ? (
              <>
                {competition.status === StatusEnum.Succeeded && (
                  <TournamentPlayerStatistics
                    statType={selectedStat as TournamentStatsEnum}
                    competition={competition.data}
                    seasonId={Number(selectedGlobalSeasonOption.value)}
                  />
                )}
              </>
            ) : (
              <>
                {competition.status === StatusEnum.Succeeded && (
                  <TournamentTeamStatistics
                    statType={selectedStat as TournamentStatsEnum}
                    competition={competition.data}
                    seasonId={Number(selectedGlobalSeasonOption.value)}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <AppText>The season has either ended or not yet started.</AppText>
        )}
      </AppBox>
      {newsList.status === (StatusEnum.Loading || StatusEnum.Idle) ? (
        <AppSkeletonNewsList />
      ) : (
        <NewsTimelineCard newsList={newsList.data} timelineType="small" />
      )}
    </AppBox>
  );
}
