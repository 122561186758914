import { AppBox } from "../../../../commons/components";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";

interface SeasonEndProps {
  competition: IFootballCompetition;
}
export default function SeasonEnd({ competition }: SeasonEndProps) {
  return (
    <AppBox displayBlock>
      Do you want to end <strong>“Season {competition.latestSeason?.title}”</strong>? Once you end the season, the
      action cannot be undone.
    </AppBox>
  );
}
