import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { IFootballPlayerDetail } from "../../../../../commons/models/football/interface/i-football-player";
import { AppBox, AppButton, AppText, AppBanner, AppLink } from "../../../../../commons/components";
import AppBorderBox from "../../../../../commons/components/BorderBox";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../../commons/components/interface";
import { useSnackbar } from "../../../../../redux/snackbarProvider";
import IconVideoAdd from "../../../../../commons/components/icons/video-add";
import { uploadFile } from "../../../../../redux/slices/file-upload-slice";
import { FieldErrors, FieldValues } from "react-hook-form";
import { IPlayerDTO } from "../../../../../api-services/football/admin/manage-football-player-service";
import { findInputError, isFormInvalid } from "../../../../../commons/utilities/form-utils";
import { setStepperFormData } from "../../../../../redux/slices/stepper-form-slice";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { toSentenceCase } from "../../../../../commons/utilities/string-utils";
interface PlayerBannerAddEditProps {
  playerData?: IFootballPlayerDetail | null;
  formStep: number;
  formErrors: FieldErrors<FieldValues>;
  submitStatus: StatusEnum;
  onBack: (currentStep: number) => void;
  onSkipOrNext: (currentStep: number) => void;
  onClearError: (errorKey: string) => void;
  onSaveAndExit: () => void;
}

const PlayerBannerAddEdit = ({
  playerData,
  formStep,
  formErrors,
  submitStatus,
  onBack,
  onSkipOrNext,
  onClearError,
  onSaveAndExit,
}: PlayerBannerAddEditProps) => {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const inputRef = useRef<HTMLInputElement>(null);
  const [rawBanner, setRawBanner] = useState<File>();
  const [bannerAdded, setBannerAdded] = useState(false);
  const [bannerAddInProgress, setBannerAddInProgress] = useState(false);
  const [isInvalidBannerSize, setInvalidBannerSize] = useState(false);
  const [isInvalidBannerType, setInvalidBannerType] = useState(false);
  const formData = useAppSelector<IPlayerDTO>((state) => state.stepperForm.stepperFormData);

  useLayoutEffect(() => {
    if (playerData?.banner) {
      setBannerAdded(true);
    }
  }, [playerData]);

  const handleBannerUpload = (banner: string | null) => {
    const playerData: IPlayerDTO = {
      ...formData,
      banner: banner,
    };
    dispatch(setStepperFormData<IPlayerDTO>(playerData));
    onClearError("banner");
  };

  useEffect(() => {
    if (rawBanner) {
      const formData = new FormData();
      formData.append("file", rawBanner);
      dispatch(uploadFile(formData))
        .unwrap()
        .then((response) => {
          setBannerAddInProgress(false);
          setBannerAdded(true);
          handleBannerUpload(response.url);
        })
        .catch((error: AxiosError<IServerErrorResponse>) => {
          const responseData = error.response?.data;
          addSnackbar({
            key: "error",
            text: responseData?.message,
            variant: "danger",
          });
          setBannerAddInProgress(false);
        });
    }
  }, [rawBanner]);

  const handleUploadClick = (e: Event) => {
    e.preventDefault();
    inputRef.current?.click();
  };

  const handleBannerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBannerAddInProgress(true);
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }
    const validFileTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif", "video/mp4"];
    const isValidFileType = validFileTypes.includes(file.type);
    const isValidFileSize = file.size <= 512000000; // 512MB
    if (isValidFileType && isValidFileSize) {
      setRawBanner(file);
      setInvalidBannerSize(false);
      setInvalidBannerType(false);
    } else {
      if (!isValidFileSize) {
        setInvalidBannerSize(true);
      } else {
        setInvalidBannerType(true);
      }
      setBannerAddInProgress(false);
    }
    if (inputRef.current) {
      (inputRef.current as HTMLInputElement).value = "";
    }
  };

  return (
    <>
      <AppBox flexDirection="column" justifyContent="space-between" gap="sm" className="flex-1">
        <AppBox flexDirection="column" alignItems="center" gap="xs" className="w-100">
          <AppLink
            onClick={(e) => {
              e.preventDefault();
              handleUploadClick(e);
            }}
          >
            <AppBorderBox>
              <AppBox alignItems="center" justifyContent="center" style={{ width: "16.5rem", height: "22rem" }}>
                {bannerAdded ? (
                  <AppBanner username={"Banner"} src={formData.banner || ""} />
                ) : (
                  <IconVideoAdd height={40} width={40} color="rgba(var(--border-200))" />
                )}
              </AppBox>
            </AppBorderBox>
          </AppLink>
          <AppBox flexDirection="column" gap="xs">
            {bannerAdded ? (
              <AppBox flexDirection="column" gap="2xs">
                <AppText as="label" size="lg" textAlign="center">
                  Player's Banner
                </AppText>
                <AppText textAlign="center">
                  A vibrant and eye-catching banner helps highlight the player's identity and achievements, making the
                  profile stand out.
                </AppText>
              </AppBox>
            ) : (
              <AppBox flexDirection="column" gap="2xs">
                <AppText as="label" size="lg" textAlign="center">
                  Add Banner/Video for Player's Banner
                </AppText>
                <AppText>
                  We support videos, GIFs, JPEGs, and PNGs up to 512 MB. (Preferred aspect ratio of 3:4)
                </AppText>
              </AppBox>
            )}
            {isInvalidBannerSize && (
              <AppText as="span" color="danger">
                The Banner size is more than 512 MB
              </AppText>
            )}
            {isInvalidBannerType && (
              <AppText as="span" color="danger">
                The Banner type is not supported.
              </AppText>
            )}
            {isFormInvalid(findInputError(formErrors, "banner")) && (
              <AppText as="span" color="danger">
                <>{toSentenceCase(`${formErrors.banner?.message && formErrors.banner.message}`)}</>
              </AppText>
            )}
          </AppBox>
          <AppBox alignItems="center" justifyContent="center" gap="sm">
            <input
              type="file"
              ref={inputRef}
              onChange={(e) => {
                handleBannerChange(e);
              }}
              accept=".jpeg,.jpg,.png,.gif,.mp4"
              hidden
            />
            <AppButton
              size="sm"
              label={bannerAdded ? "Change Banner" : "Upload Banner"}
              color={bannerAdded ? "gray" : "primary"}
              variant={bannerAdded ? "outline" : "filled"}
              borderLight={bannerAdded}
              onClick={(e) => {
                handleUploadClick(e);
              }}
              loading={bannerAddInProgress}
            />
            {bannerAdded && (
              <AppButton
                size="sm"
                label={"Remove Image"}
                color={"danger"}
                variant={"light"}
                onClick={() => {
                  setBannerAdded(false);
                  handleBannerUpload(null);
                }}
                disabled={bannerAddInProgress}
              />
            )}
          </AppBox>
        </AppBox>

        <AppBox gap="xs" justifyContent="end">
          <AppButton
            label="Back"
            variant="outline"
            color="gray"
            borderLight
            onClick={() => {
              onBack(formStep);
            }}
            disabled={submitStatus === StatusEnum.Loading || bannerAddInProgress}
          />
          {bannerAdded || (
            <AppButton
              label="Skip"
              variant="outline"
              color="gray"
              borderLight
              onClick={() => {
                onSkipOrNext(formStep);
              }}
              disabled={bannerAddInProgress}
            />
          )}
          {playerData && (
            <AppButton
              label="Save & Exit"
              variant="light"
              onClick={() => {
                onSaveAndExit();
              }}
              loading={submitStatus === StatusEnum.Loading}
              disabled={bannerAddInProgress}
            />
          )}
          {bannerAdded && (
            <AppButton
              label="Next"
              onClick={() => {
                onSkipOrNext(formStep);
              }}
              disabled={submitStatus === StatusEnum.Loading || bannerAddInProgress}
            />
          )}
        </AppBox>
      </AppBox>
    </>
  );
};

export default PlayerBannerAddEdit;
