import {
  AppBox,
  AppDateAndTime,
  AppDatePicker,
  AppGridBox,
  AppSelect,
  AppSkeletonMatchCardList,
  AppSkeletonNewsList,
  AppText,
  AppTitle,
} from "../../../commons/components";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { useEffect, useState } from "react";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import { ISelectOptions } from "../../../commons/components/ui-components/Select";
import { IFootballMatchOption } from "../../../redux/interfaces/i-football-match-state";
import {
  cleanUpMatchList,
  fetchLastFiveMatches,
  fetchMatchList,
} from "../../../redux/slices/football/public/match/football-match-list-slice";
import UpcomingMatchCard from "./components/UpcomingMatchCard";
import MatchResultCard from "./components/MatchResultCard";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import { formatDateTime } from "../../../commons/utilities/date-utils";
import { fetchTeamCompetitionList } from "../../../redux/slices/football/public/team/football-team-competitions-slice";
import LiveMatchCard from "./components/LiveMatchCard";
import PreviousMatchCard from "./components/PreviousMatchCard";
import { MatchStatusEnum } from "../../../commons/enums/match-status-enum";
import { FootballMatchDetail } from "../../../commons/models/football/football-match-detail";
import { findIndexByCondition } from "../../../commons/utilities/array-utils";
export default function TeamEvents() {
  const dispatch = useAppDispatch();
  const { team, teamStatus } = useAppSelector((state) => state.footballTeamDetail);
  const { lastFiveMatchList, matchList } = useAppSelector((state) => state.footballMatchList);
  const { newsList } = useAppSelector((state) => state.news);
  const [matchStartDate, setMatchStartDate] = useState<Date | null>(null);
  const [matchEndDate, setMatchEndDate] = useState<Date | null>(null);
  const { teamCompetitionList } = useAppSelector((state) => state.footballTeamCompetitions);
  const [competitionSelectOptions, setCompetitionSelectOptions] = useState<ISelectOptions[]>([]);
  const [seasonSelectOptions, setSeasonSelectOptions] = useState<ISelectOptions[]>([]);
  const [currentCompetitionId, setCurrentCompetitionId] = useState<number>();
  const [currentSeasonId, setCurrentSeasonId] = useState<number>();
  const [currentCompetitionOption, setCurrentCompetitionOption] = useState<ISelectOptions>();
  const [currentSeasonOption, setCurrentSeasonOption] = useState<ISelectOptions>();
  const [liveMatchList, setLiveMatchList] = useState<FootballMatchDetail[]>([]);
  const [upcomingMatchList, setUpcomingMatchList] = useState<FootballMatchDetail[]>([]);
  const [finishedMatchList, setFinishedMatchList] = useState<FootballMatchDetail[]>([]);

  const onChangeMatchDate = (
    dates: Date | [Date | null, Date | null] | null,
    event: React.SyntheticEvent<any, Event>
  ) => {
    event.preventDefault();
    if (dates instanceof Array) {
      const [start, end] = dates;
      setMatchStartDate(start || null);
      setMatchEndDate(end || null);
    }
  };

  useEffect(() => {
    if (team.id) {
      dispatch(fetchTeamCompetitionList(team.id));
    }
  }, [team]);

  useEffect(() => {
    const competitionOptions: ISelectOptions[] = [];
    if (teamCompetitionList.length > 0) {
      teamCompetitionList.forEach((competition) => {
        competitionOptions.push({
          title: competition.title,
          value: competition.id,
        });
      });
      const currentTeamCompetitionId = team.latestSeason.competition?.id;
      let currentTeamCompetitionIndex = findIndexByCondition(
        competitionOptions,
        (option) => option.value === currentTeamCompetitionId
      );
      if (currentTeamCompetitionIndex >= 0) {
        setCurrentCompetitionOption(competitionOptions[currentTeamCompetitionIndex]);
        setCurrentCompetitionId(Number(competitionOptions[currentTeamCompetitionIndex].value));
      } else {
        setCurrentCompetitionId(Number(competitionOptions[0].value));
      }
      setCompetitionSelectOptions(competitionOptions);
    }
  }, [teamCompetitionList]);

  useEffect(() => {
    if (currentCompetitionId) {
      const currentCompetition = teamCompetitionList.first((competition) => competition.id === currentCompetitionId);
      const seasonOptions: ISelectOptions[] = [];
      currentCompetition?.seasons.forEach((season) => {
        seasonOptions.push({ title: season.title, value: season.id });
      });
      const currentTeamSeasonId = team.latestSeason.season?.id;
      let currentTeamSeasonIndex = findIndexByCondition(
        seasonOptions,
        (option) => option.value === currentTeamSeasonId
      );
      if (currentTeamSeasonIndex >= 0) {
        setCurrentSeasonOption(seasonOptions[currentTeamSeasonIndex]);
        setCurrentSeasonId(Number(seasonOptions[currentTeamSeasonIndex].value));
      } else {
        setCurrentSeasonOption(seasonOptions[0]);
        setCurrentSeasonId(Number(seasonOptions[0].value));
      }
      setSeasonSelectOptions(seasonOptions);
    }
  }, [currentCompetitionId]);

  useEffect(() => {
    if (currentCompetitionId && currentSeasonId && team.id) {
      let matchesOption: IFootballMatchOption = {
        competitionId: currentCompetitionId,
        seasonId: currentSeasonId,
        teamId: team.id,
        sort: "scheduledAt",
        sortOrder: SortOrderEnum.Descending,
        page: 0,
      };
      if (matchStartDate && matchEndDate) {
        matchesOption = {
          ...matchesOption,
          beforeDate: formatDateTime(matchStartDate, "YYYY-MM-DD HH:mm:00"),
          afterDate: formatDateTime(matchEndDate, "YYYY-MM-DD HH:mm:00"),
        };
      } else {
        matchesOption = {
          ...matchesOption,
        };
      }
      dispatch(fetchMatchList(matchesOption));
    } else {
      dispatch(cleanUpMatchList());
    }
  }, [currentCompetitionId, currentSeasonId, team, matchEndDate]);

  useEffect(() => {
    const upcomingMatches = matchList.data.all().filter((match) => match.status === MatchStatusEnum.Upcoming);
    const liveMatches = matchList.data.all().filter((match) => match.status === MatchStatusEnum.Live);
    const finishedMatches = matchList.data.all().filter((match) => match.status === MatchStatusEnum.Finished);
    setLiveMatchList(liveMatches);
    setUpcomingMatchList(upcomingMatches);
    setFinishedMatchList(finishedMatches);
  }, [matchList]);

  useEffect(() => {
    if (currentSeasonId) {
      let intervalId: NodeJS.Timeout;
      intervalId = setInterval(() => {
        let matchesOption: IFootballMatchOption = {
          competitionId: currentCompetitionId,
          seasonId: currentSeasonId,
          teamId: team.id,
          sort: "scheduledAt",
          sortOrder: SortOrderEnum.Descending,
          page: 0,
        };
        if (matchStartDate && matchEndDate) {
          matchesOption = {
            ...matchesOption,
            beforeDate: formatDateTime(matchStartDate, "YYYY-MM-DD HH:mm:00"),
            afterDate: formatDateTime(matchEndDate, "YYYY-MM-DD HH:mm:00"),
          };
        } else {
          matchesOption = {
            ...matchesOption,
          };
        }
        dispatch(fetchMatchList(matchesOption));
      }, 30000);
      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    }
  }, [matchList]);

  /**
   * TODO: implement Training after implementation in Admin
  const { teamTrainingList, teamTrainingListStatus } = useAppSelector((state) => state.footballTeamTraining);
  const [trainingStartDate, setTrainingStartDate] = useState<Date | null>(moment().toDate());
  const [trainingEndDate, setTrainingEndDate] = useState<Date | null>(moment().toDate());
  useEffect(() => {
    if (team.id) {
      let upcomingTrainingNextDate: Date | undefined | null;
      if (trainingStartDate && trainingEndDate && trainingStartDate.toDateString() === trainingEndDate.toDateString()) {
        upcomingTrainingNextDate = null;
      } else {
        upcomingTrainingNextDate = trainingEndDate;
      }
      const trainingOption: IFootballTrainingOption = {
        teamId: team.id,
        urlParams: {
          perPage: 6,
          sort: "startAt",
          sortOrder: SortOrderEnum.Ascending,
          startAt: trainingStartDate?.toISOString(),
          endAt: upcomingTrainingNextDate?.toISOString(),
        },
      };
      if (trainingStartDate && trainingEndDate) {
        dispatch(fetchTeamTrainingList(trainingOption));
      }
    } else {
      dispatchClean();
    }
  }, [team, trainingEndDate]);

  const onChangeTrainingDate = (
    dates: Date | [Date | null, Date | null] | null,
    event: React.SyntheticEvent<any, Event>
  ) => {
    event.preventDefault();
    dispatch(cleanUpTeamTraining());
    if (dates instanceof Array) {
      const [start, end] = dates;
      setTrainingStartDate(start || null);
      setTrainingEndDate(end || null);
    }
  };
  */

  useEffect(() => {
    if (team.id) {
      const lastFiveMatchOption: IFootballMatchOption = {
        teamId: team.id,
      };
      dispatch(fetchLastFiveMatches(lastFiveMatchOption));
    }
  }, [team]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  return (
    <AppBox className="page-content" gap="md">
      <AppBox displayBlock={true}>
        <AppBox flexDirection="column" gap="md">
          <AppBox flexDirection="column" gap="sm">
            {teamStatus === StatusEnum.Succeeded && (
              <AppBox flexDirection="column" gap="xs">
                <AppBox justifyContent="space-between">
                  <AppTitle as="h5">Matches & Result</AppTitle>
                  <AppBox>
                    <AppDatePicker
                      onChange={onChangeMatchDate}
                      selectedStart={matchStartDate}
                      selectedEnd={matchEndDate}
                      isMultiSelector
                      isIconButton
                      isClearable
                      calenderPlacement="left-start"
                    />
                  </AppBox>
                </AppBox>
                {competitionSelectOptions.length > 0 ? (
                  <AppGridBox columns={2} gap="sm">
                    {competitionSelectOptions.length > 0 && (
                      <AppSelect
                        options={competitionSelectOptions}
                        currentOption={currentCompetitionOption}
                        onChange={(option) => {
                          setCurrentCompetitionId(Number(option.value));
                        }}
                      />
                    )}
                    {seasonSelectOptions.length > 0 && (
                      <AppSelect
                        currentOption={currentSeasonOption}
                        options={seasonSelectOptions}
                        onChange={(option) => {
                          setCurrentSeasonId(Number(option.value));
                        }}
                      />
                    )}
                  </AppGridBox>
                ) : (
                  <AppText>There are not any matches for this team.</AppText>
                )}
              </AppBox>
            )}
            {matchList.status === StatusEnum.Loading ? (
              <AppSkeletonMatchCardList />
            ) : (
              <>
                {matchList.status === StatusEnum.Succeeded && matchList.data.length === 0 ? (
                  <AppText>There are not any matches for this season.</AppText>
                ) : (
                  <>
                    {liveMatchList.length > 0 && (
                      <AppBox flexDirection="column" gap="xs">
                        <AppTitle as="h6">Live Matches</AppTitle>
                        <AppBox flexDirection="column" gap="sm">
                          {liveMatchList.map((match, index) => (
                            <AppBox flexDirection="column" gap="2xs" key={index}>
                              <AppDateAndTime date={match.scheduledAt} />
                              <AppBox flexDirection="column" gap="sm">
                                <LiveMatchCard key={match.id} match={match} />
                              </AppBox>
                            </AppBox>
                          ))}
                        </AppBox>
                      </AppBox>
                    )}
                    {upcomingMatchList.length > 0 && (
                      <AppBox flexDirection="column" gap="xs">
                        <AppTitle as="h6">Upcoming Matches</AppTitle>
                        <AppBox flexDirection="column" gap="sm">
                          {upcomingMatchList.map((match, index) => (
                            <AppBox flexDirection="column" gap="2xs" key={index}>
                              <AppDateAndTime date={match.scheduledAt} />
                              <AppBox flexDirection="column" gap="sm">
                                <UpcomingMatchCard match={match} />
                              </AppBox>
                            </AppBox>
                          ))}
                        </AppBox>
                      </AppBox>
                    )}
                    {finishedMatchList.length > 0 && (
                      <AppBox flexDirection="column" gap="xs">
                        <AppTitle as="h6">Previous Matches</AppTitle>
                        <AppBox flexDirection="column" gap="sm">
                          {finishedMatchList.map((match, index) => (
                            <AppBox flexDirection="column" gap="2xs" key={index}>
                              <AppDateAndTime date={match.scheduledAt} />
                              <AppBox flexDirection="column" gap="sm">
                                <PreviousMatchCard match={match} />
                              </AppBox>
                            </AppBox>
                          ))}
                        </AppBox>
                      </AppBox>
                    )}
                  </>
                )}
              </>
            )}
          </AppBox>
          {/* TODO: implement Training after implementation in Admin */}
          {/* <AppBox flexDirection="column" gap="xs">
            {teamStatus === StatusEnum.Succeeded && (
              <AppBox justifyContent="space-between" alignItems="center">
                <AppTitle as="h5">Upcoming Trainings</AppTitle>
                <AppBox>
                  <AppDatePicker
                    onChange={onChangeTrainingDate}
                    selectedStart={trainingStartDate}
                    selectedEnd={trainingEndDate}
                    isMultiSelector
                    isIconButton
                    calenderPlacement="left-start"
                  />
                </AppBox>
              </AppBox>
            )}
            {teamTrainingListStatus === StatusEnum.Loading ? (
              <AppSkeletonGlobalCardList numberOfItems={3} noTitle withAvatar withBadge withLabel />
            ) : (
              <>
                {teamTrainingList.length > 0 ? (
                  <AppBox flexDirection="column" gap="sm">
                    {teamTrainingList.mapArray((training, index) => (
                      <AppBox flexDirection="column" gap="2xs" key={index}>
                        <AppDateAndTime date={training.startAt} />
                        <UpcomingTrainingCard trainingData={training} />
                      </AppBox>
                    ))}
                    <AppBox className="w-1/2 mx-auto" flexDirection="column">
                      <AppButton fullWidth size="lg" variant="light" label="View All Training" disabled />
                    </AppBox>
                  </AppBox>
                ) : (
                  <AppText>There is no fixture for any upcoming training.</AppText>
                )}
              </>
            )}
          </AppBox> */}
          {lastFiveMatchList.status === StatusEnum.Loading ? (
            <AppSkeletonMatchCardList />
          ) : (
            <>
              {lastFiveMatchList.data.length > 0 && (
                <AppBox flexDirection="column" gap="xs">
                  <AppTitle as="h5">Form (Last 5 Matches)</AppTitle>
                  <AppBox flexDirection="column" gap="sm" pl="xs">
                    {lastFiveMatchList.data.mapArray((result) => (
                      <MatchResultCard key={result.id} match={result} includeBadge currentTeam={team} />
                    ))}
                  </AppBox>
                </AppBox>
              )}
            </>
          )}
        </AppBox>
      </AppBox>
      <AppBox displayBlock>
        {newsList.status === (StatusEnum.Loading || StatusEnum.Idle) ? (
          <AppSkeletonNewsList />
        ) : (
          <NewsTimelineCard newsList={newsList.data} timelineType="small" />
        )}
      </AppBox>
    </AppBox>
  );
}
