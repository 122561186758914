import {
  AppAvatar,
  AppBox,
  AppDateAndTime,
  AppLink,
  AppPaper,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import { IFootballTeam } from "../../../../commons/models/football/interface/i-football-team";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface TournamentTeamCardProps {
  team: IFootballTeam;
}
export default function TournamentTeamCard({ team }: TournamentTeamCardProps) {
  const teamPath = `/teams/${team.id}`;
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <AppLink to={teamPath} onClick={handleClick}>
      <AppPaper radius="sm" padding="xs" shadow="xs">
        <AppBox gap="xs">
          <AppAvatar username={team.name} src={team.logo || ""} />
          <AppBox flexDirection="column">
            <AppTitle as={"h5"}>{toTitleCase(team.shortName)}</AppTitle>
            <AppBox gap="3xs">
              <AppText as="span" fontWeight="semibold" size="sm" color="muted">
                {"Foundation Date:"}
              </AppText>
              <AppDateAndTime
                fontWeight="semibold"
                color="muted"
                size="sm"
                date={team.founded || ""}
                format="D MMM, YYYY"
              />
            </AppBox>
          </AppBox>
        </AppBox>
      </AppPaper>
    </AppLink>
  );
}
