import { useEffect, useState } from "react";
import { AppBox, AppButton, AppModal, AppText, AppTitle } from "../../../../commons/components";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";
import { EmptyBracketComponent } from "./EmptyBracketComponent";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { SeasonStatusEnum } from "../../../../commons/enums/season-status-enum";
import {
  cleanUpManageSeasonKnockouts,
  fetchManageSeasonKnockoutList,
} from "../../../../redux/slices/football/admin/season/knockout/manage-knockout-list-slice";
import { FixtureCard } from "./FixtureCard";
import { IManageFootballKnockout } from "../../../../commons/models/football/admin/interface/i-manage-football-knockout";
import BracketCreateEdit from "./BracketCreateEdit";
import IconKnockout from "../../../../commons/components/icons/knockout";
import AppBorderBox from "../../../../commons/components/BorderBox";
import { EmptyFixtureCard } from "./EmptyFixtureCard";
import { IFootballMatch } from "../../../../commons/models/football/interface/i-football-match";
import FixtureAddEdit from "./FixtureAddEdit";
import IconEdit from "../../../../commons/components/icons/edit";
import IconRibbonStar from "../../../../commons/components/icons/ribbon-star";
import {
  IBracketTree,
  IFootballKnockoutTree,
} from "../../../../commons/models/football/interface/i-football-knockout-tree";
import { BracketSVGComponent } from "./BracketSVGComponent";
import { FootballKnockoutTree } from "../../../../commons/models/football/football-knockout-tree";

interface EventsBracketComponentProps {
  competition: IFootballCompetition;
}

const getCentralRightPosition = (id: string): { x: number; y: number } | null => {
  const element = document.getElementById(id);
  if (element) {
    const rect = element.getBoundingClientRect();
    const centralRightPosition = {
      x: rect.right,
      y: rect.top + rect.height / 2,
    };
    return centralRightPosition;
  } else {
    return null;
  }
};

const getCentralLeftPosition = (id: string): { x: number; y: number } | null => {
  const element = document.getElementById(id);
  if (element) {
    const rect = element.getBoundingClientRect();
    const centralLeftPosition = {
      x: rect.left,
      y: rect.top + rect.height / 2,
    };
    return centralLeftPosition;
  } else {
    return null;
  }
};

//DFS function to traverse the tree
export function traverseTree(tree: IBracketTree | null, knockoutArray: IManageFootballKnockout[]) {
  if (tree === null) {
    return;
  }
  let knockout = knockoutArray.find((k) => k.id === tree.group.id);
  if (!knockout) {
    knockout = {
      id: tree.group.id,
      name: tree.group.name,
      type: tree.group.type,
      roundNumber: tree.group.roundNumber,
      matches: [],
    };
    knockoutArray.push(knockout);
  }
  knockout.matches.push(tree.match);
  if (tree.left !== null) traverseTree(tree.left, knockoutArray);
  if (tree.right !== null) traverseTree(tree.right, knockoutArray);
}

export function transformKnockoutTree(treeData: IFootballKnockoutTree | {}): IManageFootballKnockout[] {
  const knockoutArray: IManageFootballKnockout[] = [];
  if ("all" in treeData && treeData.all) {
    traverseTree(treeData.all, knockoutArray);
  }
  if ("third" in treeData && treeData.third) {
    traverseTree(treeData.third, knockoutArray);
  }
  return knockoutArray.reverse();
}

const EventsBracketComponent = ({ competition }: EventsBracketComponentProps) => {
  const dispatch = useAppDispatch();
  const [isSeasonBracketCreateEdit, setSeasonBracketCreateEdit] = useState(false);
  const [bracketFirstRoundData, setBracketFirstRoundData] = useState<IManageFootballKnockout | null>(null);
  const [bracketThirdPlace, setBracketThirdPlace] = useState<boolean>(false);
  const [knockoutData, setKnockoutData] = useState<IManageFootballKnockout[]>([]);
  const [knockoutTitles, setKnockoutTitles] = useState<string[]>([]);
  const { seasonKnockoutList } = useAppSelector((state) => state.footballManageSeasonKnockoutList);
  const { selectedGlobalSeasonOption } = useAppSelector((state) => state.globalSeasonSelect);

  useEffect(() => {
    if (selectedGlobalSeasonOption.value) {
      dispatch(fetchManageSeasonKnockoutList({ seasonId: Number(selectedGlobalSeasonOption.value) }));
    }
  }, [selectedGlobalSeasonOption]);

  useEffect(() => {
    if (Object.keys(seasonKnockoutList).length > 0) {
      let titleArray = [];
      const knockoutArray = transformKnockoutTree(seasonKnockoutList);
      for (const knockout of knockoutArray) {
        titleArray.push(knockout.name);
      }
      setKnockoutTitles(titleArray);
      setKnockoutData(knockoutArray);
      setBracketFirstRoundData(knockoutArray[0]);
      if ((seasonKnockoutList as FootballKnockoutTree).third) {
        setBracketThirdPlace(true);
      } else {
        setBracketThirdPlace(false);
      }
    }
  }, [seasonKnockoutList]);

  return (
    <>
      <AppBox gap="xs" flexDirection="column">
        <AppBox alignItems="center" justifyContent="space-between">
          <AppTitle as="h5">Bracket</AppTitle>
          {Object.keys(seasonKnockoutList).length > 0 &&
            selectedGlobalSeasonOption.secondaryValue?.status === SeasonStatusEnum.Active && (
              <AppButton
                label={"Edit Bracket"}
                variant="light"
                onClick={() => {
                  setSeasonBracketCreateEdit(true);
                }}
              />
            )}
        </AppBox>
        {Object.keys(seasonKnockoutList).length === 0 ? (
          <EmptyBracketComponent
            isPastSeason={
              selectedGlobalSeasonOption.value
                ? selectedGlobalSeasonOption.secondaryValue?.status !== SeasonStatusEnum.Active
                : undefined
            }
            competition={competition}
            onCreateClick={() => {
              setSeasonBracketCreateEdit(true);
            }}
          />
        ) : (
          <AppBox flexDirection="column" gap="sm" pb="xl" style={{ overflowX: "scroll", overflowY: "hidden" }}>
            {knockoutTitles.length > 0 && (
              <AppBox gap="3xl">
                {knockoutTitles.map((title, index) => (
                  <AppBox key={index} style={{ minWidth: "24rem", maxWidth: "24rem" }}>
                    <AppBorderBox border={["Bottom"]} pb="sm" className="w-100">
                      <AppText textAlign="center" fontWeight="semibold" size="lg">
                        {title}
                      </AppText>
                    </AppBorderBox>
                  </AppBox>
                ))}
              </AppBox>
            )}
            <AppBox>
              <AppBox style={{ position: "relative" }}>
                <KnockoutTree
                  tree={(seasonKnockoutList as FootballKnockoutTree).all}
                  competition={competition}
                  knockoutData={knockoutData}
                  thirdMatchData={(seasonKnockoutList as FootballKnockoutTree).third}
                />
              </AppBox>
            </AppBox>
          </AppBox>
        )}
      </AppBox>
      {competition.latestSeason && (
        <AppModal
          title={bracketFirstRoundData ? "Edit Bracket" : "Create Bracket"}
          titleIcon={<IconKnockout />}
          opened={isSeasonBracketCreateEdit}
          onClose={() => {
            setSeasonBracketCreateEdit(false);
          }}
          withoutButtons
        >
          <BracketCreateEdit
            season={competition.latestSeason}
            bracketFirstRoundData={bracketFirstRoundData}
            bracketThirdPlace={bracketThirdPlace}
            onCreate={() => {
              setSeasonBracketCreateEdit(false);
            }}
            onCancel={() => {
              setSeasonBracketCreateEdit(false);
            }}
          />
        </AppModal>
      )}
    </>
  );
};

export default EventsBracketComponent;

interface KnockoutTreeProps {
  tree: IBracketTree;
  parentId?: string;
  competition: IFootballCompetition;
  knockoutData: IManageFootballKnockout[];
  thirdMatchData?: IBracketTree;
}

const KnockoutTree = ({ tree, parentId, competition, knockoutData, thirdMatchData }: KnockoutTreeProps) => {
  const dispatch = useAppDispatch();
  const { selectedGlobalSeasonOption } = useAppSelector((state) => state.globalSeasonSelect);
  const [isBracketData, setIsBracketData] = useState(false);
  const [isBracketLines, setIsBracketLines] = useState(false);
  const [isBracketMatchEdit, setIsBracketMatchEdit] = useState<boolean>(false);
  const [isBracketFirstRound, setIsBracketFirstRound] = useState<boolean>(false);
  const [selectedBracketMatch, setSelectedBracketMatch] = useState<IFootballMatch | null>(null);
  const [selectedBracketPastRoundMatches, setSelectedBracketPastRoundMatches] = useState<
    IFootballMatch[] | undefined
  >();
  useEffect(() => {
    setIsBracketLines(false);
    if (tree) {
      setIsBracketData(true);
    }
    setTimeout(() => {
      setIsBracketLines(true);
    }, 50);
  }, [tree]);

  const handleBracketMatchEdit = (match: IFootballMatch, knockoutRound: number) => {
    if (selectedGlobalSeasonOption.secondaryValue.status === SeasonStatusEnum.Active) {
      const totalRound = knockoutData.length;
      if (knockoutRound === totalRound) {
        setIsBracketFirstRound(true);
      } else {
        setIsBracketFirstRound(false);
        const pastRoundBracket = knockoutData.find((knockout) => knockout.roundNumber === knockoutRound + 1);
        setSelectedBracketPastRoundMatches(pastRoundBracket?.matches);
      }
      setSelectedBracketMatch(match);
      setIsBracketMatchEdit(true);
    }
  };

  const handleBracketMatchAfterEdit = () => {
    dispatch(cleanUpManageSeasonKnockouts());
    dispatch(fetchManageSeasonKnockoutList({ seasonId: Number(selectedGlobalSeasonOption.value) }));
    setIsBracketMatchEdit(false);
    setSelectedBracketMatch(null);
  };

  if (!tree) return null;
  return (
    <>
      <AppBox alignItems="center">
        <AppBox flexDirection="column" alignItems="center" justifyContent="space-between">
          {tree.left && (
            <KnockoutTree
              tree={tree.left}
              parentId={`match-${tree.order}-${tree.match.id}`}
              competition={competition}
              knockoutData={knockoutData}
            />
          )}
          {tree.right && (
            <KnockoutTree
              tree={tree.right}
              parentId={`match-${tree.order}-${tree.match.id}`}
              competition={competition}
              knockoutData={knockoutData}
            />
          )}
        </AppBox>
        <AppBox flexDirection="column">
          <AppBox
            id={`match-${tree.order}-${tree.match.id}`}
            alignItems="center"
            mr="3xl"
            mb="sm"
            style={{ position: "relative", minWidth: "24rem", maxWidth: "24rem" }}
          >
            {tree.match.homeTeam || tree.match.awayTeam || tree.match.homeTeamOutcome || tree.match.awayTeamOutcome ? (
              <FixtureCard
                competition={competition}
                fixture={tree.match}
                withoutDelete
                onEdit={(match) => {
                  handleBracketMatchEdit(match, tree.group.roundNumber);
                }}
                withoutUpdate={selectedGlobalSeasonOption.secondaryValue.status !== SeasonStatusEnum.Active}
              />
            ) : (
              <EmptyFixtureCard
                fixture={tree.match}
                onEdit={(match) => {
                  handleBracketMatchEdit(match, tree.group.roundNumber);
                }}
              />
            )}
            {parentId && isBracketData && (
              <div id={`svg-${tree.order}-${tree.match.id}`} style={{ position: "absolute", zIndex: "-1", right: 0 }}>
                {isBracketLines && (
                  <BracketSVGComponent
                    id={`svg-${tree.order}-${tree.match.id}`}
                    currentMatchPosition={getCentralRightPosition(`match-${tree.order}-${tree.match.id}`)}
                    parentMatchPosition={getCentralLeftPosition(parentId)}
                  />
                )}
              </div>
            )}
          </AppBox>
          {thirdMatchData && (
            <AppBox alignItems="center" mr="3xl" mb="sm" style={{ minWidth: "24rem", maxWidth: "24rem" }}>
              {thirdMatchData.match.homeTeam ||
              thirdMatchData.match.awayTeam ||
              thirdMatchData.match.homeTeamOutcome ||
              thirdMatchData.match.awayTeamOutcome ? (
                <FixtureCard
                  competition={competition}
                  fixture={thirdMatchData.match}
                  withoutDelete
                  onEdit={(match) => {
                    handleBracketMatchEdit(match, tree.group.roundNumber);
                  }}
                />
              ) : (
                <EmptyFixtureCard
                  fixture={thirdMatchData.match}
                  onEdit={(match) => {
                    handleBracketMatchEdit(match, thirdMatchData.group.roundNumber);
                  }}
                />
              )}
            </AppBox>
          )}
        </AppBox>
      </AppBox>
      {competition.latestSeason && (
        <AppModal
          opened={isBracketMatchEdit}
          onClose={() => {
            setIsBracketMatchEdit(false);
            setSelectedBracketMatch(null);
          }}
          titleIcon={
            selectedBracketMatch?.homeTeam ||
            selectedBracketMatch?.awayTeam ||
            selectedBracketMatch?.homeTeamOutcome ||
            selectedBracketMatch?.awayTeamOutcome ? (
              <IconEdit />
            ) : (
              <IconRibbonStar />
            )
          }
          title={
            selectedBracketMatch?.homeTeam ||
            selectedBracketMatch?.awayTeam ||
            selectedBracketMatch?.homeTeamOutcome ||
            selectedBracketMatch?.awayTeamOutcome
              ? "Edit Match Details"
              : "Schedule Match"
          }
          withoutButtons
        >
          <FixtureAddEdit
            competition={competition}
            season={competition.latestSeason}
            fixtureData={selectedBracketMatch}
            isBracketFixture
            isBracketFirstRound={isBracketFirstRound}
            pastRoundMatches={selectedBracketPastRoundMatches}
            onCreate={() => {
              handleBracketMatchAfterEdit();
            }}
            onCancel={() => {
              setIsBracketMatchEdit(false);
              setSelectedBracketMatch(null);
            }}
          />
        </AppModal>
      )}
    </>
  );
};
