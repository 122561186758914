import { useEffect, useState } from "react";
import { AppBox, AppSkeletonListComponent, AppTitle } from "../../../commons/components";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { RootState } from "../../../redux/store";
import { IFootballStandingOption } from "../../../redux/interfaces/i-football-standing-state";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { fetchManageCompetitionSeasonStanding } from "../../../redux/slices/football/admin/competition/manage-competition-season-standing-slice";
import { groupStandings, IGroupedStanding } from "../../../commons/utilities/standing-table-utils";
import StandingTableGroup from "../../../commons/components/ui-components/StandingTableGroup";

export default function MatchDetailStandings() {
  const dispatch = useAppDispatch();
  const [groupedStandings, setGroupedStandings] = useState<IGroupedStanding[]>([]);
  const { standingListByPoint, standingStatus } = useAppSelector(
    (state: RootState) => state.footballManageCompetitionSeasonStanding
  );
  const { seasonMatchDetail } = useAppSelector((state) => state.footballManageSeasonMatchDetail);

  useEffect(() => {
    const standingOptions: IFootballStandingOption = {
      competitionId: seasonMatchDetail.competition.id,
      seasonId: seasonMatchDetail.season.id,
    };
    if (standingOptions.competitionId && standingOptions.seasonId) {
      dispatch(fetchManageCompetitionSeasonStanding(standingOptions));
    }
  }, [seasonMatchDetail]);

  useEffect(() => {
    setGroupedStandings(groupStandings(standingListByPoint));
  }, [standingListByPoint]);

  return (
    <AppBox mb="sm" flexDirection="column" gap="xs">
      {standingStatus === StatusEnum.Loading ? (
        <AppSkeletonListComponent numberOfItems={20} />
      ) : (
        <AppBox flexDirection="column" gap="sm">
          <AppBox justifyContent="space-between">
            <AppTitle as="h5">Standings</AppTitle>
          </AppBox>
          {seasonMatchDetail.homeTeam?.id && seasonMatchDetail.awayTeam?.id && groupedStandings.length > 0 && (
            <StandingTableGroup
              groupedStandings={groupedStandings}
              highlightedTeams={[
                { id: seasonMatchDetail.homeTeam?.id, color: seasonMatchDetail.homeTeam?.homeColor || "" },
                { id: seasonMatchDetail.awayTeam?.id, color: seasonMatchDetail.awayTeam?.homeColor || "" },
              ]}
              isManage
            />
          )}
        </AppBox>
      )}
    </AppBox>
  );
}
