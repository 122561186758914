import { FootballTeamPlayer } from "../../../commons/models/football/football-team-player";
import { AbstractBaseService } from "../../../ensue-react-system/utilities/base-service";
import { PaginatedEnsueCollection } from "../../../ensue-react-system/utilities/paginated-ensue-collection";
import { ManageFootballTeam } from "../../../commons/models/football/admin/manage-football-team";
import { EnsueCollection } from "../../../ensue-react-system/utilities/ensue-collection";
import { FootballAssociatedCompetition } from "../../../commons/models/football/football-associated-competition";
import { FootballBasicStatistics } from "../../../commons/models/football/football-basic-statistics";

export interface ITeamPlayerDTO {
  jerseyNumber: number;
  startAt: string;
  isCaptain: boolean;
  playingRoles: string;
}

export interface ITeamMultiplePlayerDTO extends ITeamPlayerDTO {
  id: number;
  teamId: number;
}

export interface ITeamDTO {
  name: string;
  shortName: string;
  abbreviation: string;
  description: string | null;
  founded: string | null;
  logo: string | null;
  homeColor: string | null;
  awayColor: string | null;
  thirdColor: string | null;
  goalkeeperHomeColor: string | null;
  goalkeeperAwayColor: string | null;
  playersCount: number | null;
  status: number | null;
}

export interface ITeamChangeStatus {
  status: number;
}

export class ManageFootballTeamService extends AbstractBaseService<ManageFootballTeam> {
  protected __model: ManageFootballTeam = new ManageFootballTeam();

  public getResourceName(): string {
    return "manage/football/teams";
  }

  public addTeam(teamData: ITeamDTO) {
    return this.__client.post(this.__url, teamData);
  }

  public editTeam(teamId: number, teamData: ITeamDTO) {
    return this.__client.patch(this.__url + "/" + teamId, teamData);
  }

  public setTeamInactive(teamId: number) {
    return this.__client.patch(this.__url + "/" + teamId + "/inactive", null);
  }

  public changeStatus(teamId: number, body: ITeamChangeStatus) {
    return this.__client.patch(this.__url + "/" + teamId + "/status", body);
  }

  public setTeamTerminate(teamId: number, date: string) {
    const terminateBody = {
      endAt: date,
    };
    return this.__client.patch(this.__url + "/" + teamId + "/terminate", terminateBody);
  }

  public getTeamPlayers(
    teamId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<PaginatedEnsueCollection<FootballTeamPlayer>> {
    const teamPlayerModel = new FootballTeamPlayer();
    const options = this.createHttpParamObject(urlParams);
    return this.__client
      .get(this.__url + "/" + teamId + "/players", options)
      .then((obj) => teamPlayerModel.createFromCollection(obj) as PaginatedEnsueCollection<FootballTeamPlayer>);
  }

  public addTeamMultiplePlayers(teamId: number, teamMultiplePlayersInfo: ITeamMultiplePlayerDTO[]) {
    return this.__client.post(this.__url + "/" + teamId + "/players", { players: teamMultiplePlayersInfo });
  }

  public setTeamPlayer(teamId: number, playerId: number, playerInfo: ITeamPlayerDTO) {
    return this.__client.post(this.__url + "/" + teamId + "/players/" + playerId, playerInfo);
  }

  public editTeamPlayer(teamId: number, playerId: number, playerInfo: ITeamPlayerDTO) {
    return this.__client.patch(this.__url + "/" + teamId + "/players/" + playerId, playerInfo);
  }

  public removeTeamPlayer(teamId: number, playerId: number, date: string) {
    const playerRemoveBody = {
      endAt: date,
    };
    return this.__client.patch(this.__url + "/" + teamId + "/players/" + playerId + "/unassign", playerRemoveBody);
  }

  public getTeamCompetitions(teamId: number): Promise<EnsueCollection<FootballAssociatedCompetition>> {
    const competitionModel = new FootballAssociatedCompetition();
    return this.__client
      .get(this.__url + "/" + teamId + "/competitions")
      .then((obj) => competitionModel.createFromCollection(obj) as EnsueCollection<FootballAssociatedCompetition>);
  }

  public getStatistics(
    teamId: number,
    urlParams?: { [key: string]: string | number | boolean }
  ): Promise<FootballBasicStatistics> {
    const options = this.createHttpParamObject(urlParams);
    const statistics = new FootballBasicStatistics();
    return this.__client.get(this.__url + "/" + teamId + "/statistics", options).then((o) => {
      return statistics.create(o) as FootballBasicStatistics;
    });
  }
}
