import { BaseModel } from "../../../ensue-react-system/utilities/base-model";
import { IFootballAttribute, IFootballPlayerAttribute } from "./interface/i-football-player-attribute";

export class FootballAttribute extends BaseModel implements IFootballAttribute {
  public id: number = 0;
  public name: string = "";
  public abbreviation: string = "";
  public position: string = "";
  public constructor(props: { [key: string]: any } | undefined = undefined) {
    super(props);
    this.__init(props);
  }
}
