import { ReactNode, useEffect, useState } from "react";
import IconArrowLeft from "./icons/arrow-left";
import IconArrowRight from "./icons/arrow-right";
import { AppBox, AppText, AppTitle } from ".";

export type MatchStatus = "live" | "upcoming" | "previous";
type PillSizeType = "sm" | "md" | "lg";

interface ScorePillProps {
  size?: PillSizeType;
  valueFirst?: number;
  valueSecond?: number;
  penaltyValueFirst?: number;
  penaltyValueSecond?: number;
  matchStatus: MatchStatus;
}

export default function ScorePill({
  size = "md",
  valueFirst,
  valueSecond,
  matchStatus,
  penaltyValueFirst,
  penaltyValueSecond,
}: ScorePillProps) {
  const [icon, setIcon] = useState<ReactNode>(null);
  const [arrowDirection, setDirection] = useState("left");

  useEffect(() => {
    if (matchStatus !== "upcoming") {
      // @ts-ignore
      if (valueFirst === valueSecond) {
        if (penaltyValueFirst && penaltyValueSecond) {
          if (penaltyValueFirst > penaltyValueSecond) {
            setDirection("left");
            setIcon(<IconArrowLeft />);
          } else if (penaltyValueFirst < penaltyValueSecond) {
            setDirection("right");
            setIcon(<IconArrowRight />);
          }
        } else {
          setIcon(null);
        }
      }
      // @ts-ignore
      else if (valueFirst > valueSecond) {
        setDirection("left");
        setIcon(<IconArrowLeft />);
      } else {
        // @ts-ignore
        if (valueSecond > valueFirst) {
          setDirection("right");
          setIcon(<IconArrowRight />);
        }
      }
    }
  }, [valueFirst, valueSecond, penaltyValueFirst, penaltyValueSecond]);

  const pillLabel = (label: string) => {
    switch (size) {
      case "lg":
        return (
          <AppTitle color="currentColor" as="h4">
            {label}
          </AppTitle>
        );
      case "sm":
        return (
          <AppText fontWeight="semibold" size="md">
            {label}
          </AppText>
        );
      case "md":
      default:
        return (
          <AppText fontWeight="bold" size="lg">
            {label}
          </AppText>
        );
    }
  };
  const pillPenaltyLabel = (label: string) => {
    switch (size) {
      case "lg":
        return (
          <AppTitle color="currentColor" as="h6">
            {label}
          </AppTitle>
        );
      case "sm":
        return (
          <AppText fontWeight="medium" size="sm">
            {label}
          </AppText>
        );
      case "md":
      default:
        return (
          <AppText fontWeight="semibold" size="md">
            {label}
          </AppText>
        );
    }
  };

  const scorePill = (status: MatchStatus) => {
    if (status === "upcoming") {
      return pillLabel("VS");
    } else if (status === "live") {
      return (
        <>
          <AppBox alignItems="center" justifyContent="center" className={`result-indicator arrow-${arrowDirection}`}>
            {icon}
          </AppBox>
          <AppBox alignItems="center" gap="3xs">
            {pillLabel(`${valueFirst ?? "0"}`)}
            {penaltyValueFirst !== undefined && penaltyValueFirst !== null && (
              <>{pillPenaltyLabel(`(${penaltyValueFirst})`)}</>
            )}
            {pillLabel(`:`)}
            {pillLabel(`${valueSecond ?? "0"}`)}
            {penaltyValueSecond !== undefined && penaltyValueSecond !== null && (
              <>{pillPenaltyLabel(`(${penaltyValueSecond})`)}</>
            )}
          </AppBox>
        </>
      );
    } else {
      return (
        <>
          <AppBox alignItems="center" justifyContent="center" className={`result-indicator arrow-${arrowDirection}`}>
            {icon}
          </AppBox>
          <AppBox alignItems="center" gap="3xs">
            {pillLabel(`${valueFirst ?? "0"}`)}
            {penaltyValueFirst !== undefined && penaltyValueFirst !== null && (
              <>{pillPenaltyLabel(`(${penaltyValueFirst})`)}</>
            )}
            {pillLabel(`:`)}
            {pillLabel(`${valueSecond ?? "0"}`)}
            {penaltyValueSecond !== undefined && penaltyValueSecond !== null && (
              <>{pillPenaltyLabel(`(${penaltyValueSecond})`)}</>
            )}
          </AppBox>
        </>
      );
    }
  };

  return (
    <AppBox
      alignItems="center"
      justifyContent="center"
      className={`pill ${size === "lg" ? "big-pill" : `${size === "sm" ? "small-pill" : ""}`}`}
    >
      {scorePill(matchStatus)}
    </AppBox>
  );
}
