import PlayerCard from "./components/PlayerCard";
import {
  AppBox,
  AppGridBox,
  AppSkeletonGlobalCardList,
  AppSkeletonNewsList,
  AppText,
  AppTitle,
} from "../../../commons/components";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { useEffect, useState } from "react";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import { FootballTeamPlayer } from "../../../commons/models/football/football-team-player";
import { groupArrayByProperty } from "../../../commons/utilities/array-utils";
import {
  IFootballTeamPlayersOption,
  fetchTeamPlayerList,
} from "../../../redux/slices/football/public/team/football-team-players-slice";
import { pluralizeString, toSentenceCase } from "../../../commons/utilities/string-utils";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";

export default function TeamSquad() {
  const [sortedGroupedPlayers, setSortedGroupedPlayers] = useState<
    {
      [x: string]: string | FootballTeamPlayer[];
      items: FootballTeamPlayer[];
    }[]
  >([]);
  const dispatch = useAppDispatch();
  const { teamPlayersList, teamPlayersListStatus } = useAppSelector((state) => state.footballTeamPlayers);
  const { team } = useAppSelector((state) => state.footballTeamDetail);
  const { newsList } = useAppSelector((state) => state.news);

  useEffect(() => {
    if (team.id && team.latestSeason?.season?.id) {
      const playerListOption: IFootballTeamPlayersOption = {
        teamId: team.id,
        urlParams: { seasonId: team.latestSeason?.season?.id },
      };
      dispatch(fetchTeamPlayerList(playerListOption));
    }
  }, [team]);

  useEffect(() => {
    if (teamPlayersList.length > 0) {
      const formattedPlayers: FootballTeamPlayer[] = teamPlayersList.mapArray((player) => ({
        ...player,
        position: player.position,
      }));
      const groupedPlayers = groupArrayByProperty(formattedPlayers, "position");
      const customOrder = ["GOALKEEPER", "DEFENDER", "MIDFIELDER", "FORWARD"];
      const sortedData = groupedPlayers.sort((a, b) => {
        return customOrder.indexOf(a.position as string) - customOrder.indexOf(b.position as string);
      });
      setSortedGroupedPlayers(sortedData);
    }
  }, [teamPlayersList]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  return (
    <AppBox className="page-content" gap="md">
      <AppBox flexDirection="column" gap="xs" mb="sm">
        {teamPlayersListStatus === StatusEnum.Loading ? (
          <>
            <AppSkeletonGlobalCardList numberOfItems={3} withAvatar withGrid />
            <AppSkeletonGlobalCardList numberOfItems={6} withAvatar withGrid />
            <AppSkeletonGlobalCardList numberOfItems={5} withAvatar withGrid />
            <AppSkeletonGlobalCardList numberOfItems={5} withAvatar withGrid />
          </>
        ) : (
          <>
            <AppTitle as="h5">Squad</AppTitle>
            {sortedGroupedPlayers.length === 0 ? (
              <AppText>There is no squad in the team</AppText>
            ) : (
              <>
                {sortedGroupedPlayers.map((groupedPlayer, index) => (
                  <AppBox key={index} flexDirection="column" gap="xs">
                    <AppTitle as="h6">{toSentenceCase(pluralizeString(groupedPlayer.position as string))}</AppTitle>
                    <AppGridBox className="associated-player" columns={2} gap="sm">
                      {(groupedPlayer.items as FootballTeamPlayer[]).map((player) => (
                        <PlayerCard key={player.id} playerInfo={player} />
                      ))}
                    </AppGridBox>
                  </AppBox>
                ))}
              </>
            )}
          </>
        )}
      </AppBox>
      <AppBox displayBlock>
        {newsList.status === (StatusEnum.Loading || StatusEnum.Idle) ? (
          <AppSkeletonNewsList />
        ) : (
          <NewsTimelineCard newsList={newsList.data} timelineType="small" />
        )}
      </AppBox>
    </AppBox>
  );
}
