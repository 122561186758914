import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface StepperFormState<T> {
  stepperFormData: T;
}

const initialState: StepperFormState<any> = {
  stepperFormData: {},
};

export const StepperFormSlice = createSlice({
  name: "StepperFormSlice",
  initialState,
  reducers: {
    setStepperFormData: <T>(state: StepperFormState<T>, action: PayloadAction<T>) => {
      state.stepperFormData = action.payload;
    },

    cleanUpStepperFormData: <T>(state: StepperFormState<T>) => {
      state.stepperFormData = {} as T;
    },
  },
});

export const { setStepperFormData, cleanUpStepperFormData } = StepperFormSlice.actions;
export default StepperFormSlice.reducer;
