import { MatchEventTypeEnum } from "../../enums/match-event-type-enum";
import AppBox from "../Box";
import AppText from "../Text";
import AppLineUpBadge from "./LineupBadge";

interface JerseyProps {
  name: string;
  number: number;
  colorBack: string;
  colorSleeve: string;
  colorText: string;
  jerseySize?: number;
  jerseyNumberSize?: number;
  isCaptain?: boolean;
  isSubstitution?: boolean;
}

export default function Jersey({
  name,
  number,
  colorBack,
  colorSleeve,
  colorText,
  jerseySize = 50,
  jerseyNumberSize = 24,
  isCaptain = false,
  isSubstitution = false,
}: JerseyProps) {
  return (
    <div style={{ position: "absolute" }}>
      <AppBox
        className="jersey"
        flexDirection="column"
        alignItems="center"
        style={{
          position: "relative",
          height: jerseySize,
          width: jerseySize,
        }}
      >
        <svg fill="none" viewBox="0 0 56 56">
          <path
            fill={colorBack}
            d="M47.405 3.148V56H8.595V3.148L19.622 0C22.159 1.23 25 1.917 28 1.917S33.841 1.23 36.378 0l11.027 3.148Z"
          />
          <path
            fill={colorSleeve}
            d="M47.404 3.148 56 20.912s-2.692 5.472-8.596 4.363V3.148Zm-38.809 0L0 20.912s2.691 5.472 8.595 4.363V3.148Z"
          />
        </svg>
        {isSubstitution && (
          <AppBox
            style={{
              position: "absolute",
              margin: "0",
              top: "0%",
              left: "0%",
              transform: "translate(0%, 0%)",
            }}
          >
            <AppLineUpBadge status={MatchEventTypeEnum.Substitution} size={20} />
          </AppBox>
        )}
        {isCaptain && (
          <AppBox
            style={{
              position: "absolute",
              margin: "0",
              bottom: "0%",
              right: "0%",
              transform: "translate(0%, 0%)",
            }}
          >
            <AppLineUpBadge status="CAPTAIN" size={18} />
          </AppBox>
        )}
        <AppBox
          alignItems="center"
          style={{
            position: "absolute",
            margin: "0",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <AppText
            className="jersey-number"
            style={{
              fontSize: jerseyNumberSize,
              fontWeight: "bold",
              color: colorText,
            }}
          >
            {number}
          </AppText>
        </AppBox>
        <AppBox
          alignItems="center"
          style={{
            position: "absolute",
            margin: "0",
            top: "100%",
          }}
        >
          <AppText
            className="jersey-name"
            style={{
              color: "white",
            }}
            textAlign="center"
            size="sm"
          >
            {name}
          </AppText>
        </AppBox>
      </AppBox>
    </div>
  );
}
