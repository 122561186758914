import { useEffect } from "react";
import {
  AppBox,
  AppModal,
  AppSelect,
  AppSkeletonGlobalCardList,
  AppText,
  AppTitle,
} from "../../../../../commons/components";
import { useState } from "react";
import { statisticsEventsOption } from "../../MatchDetailManageStatistics";
import { IFootballMatchDetail } from "../../../../../commons/models/football/interface/i-football-match";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import { MatchStatisticsEventEnum } from "../../../../../commons/enums/match-statistics-event-enum";
import {
  cleanUpSeasonMatchPossessionList,
  fetchSeasonMatchPossessionList,
} from "../../../../../redux/slices/football/admin/season/match/statistics/manage-season-match-possession-list-slice";
import {
  cleanUpSeasonMatchPlayersStatisticList,
  fetchSeasonMatchPlayersStatisticList,
} from "../../../../../redux/slices/football/admin/season/match/statistics/manage-season-match-players-statistic-list-slice";
import { MatchPossessionListCard } from "./MatchPossessionListCard";
import { MatchPlayersStatisticListCard } from "./MatchPlayersStatisticListCard";
import {
  IManageFootballMatchPlayerPassStatistics,
  IManageFootballMatchPlayersStatistics,
} from "../../../../../commons/models/football/admin/interface/i-manage-football-match-players-statistics";
import { IManageFootballMatchPossession } from "../../../../../commons/models/football/admin/interface/i-manage-football-match-possession";
import IconEdit from "../../../../../commons/components/icons/edit";
import { MatchStatisticsEdit } from "./MatchStatisticsEdit";
import { getStatisticsKeyString } from "../../../../../commons/utilities/match-or-matchCard-utils";
import IconBin from "../../../../../commons/components/icons/bin";
import MatchStatisticDelete from "./MatchStatisticDelete";
import MatchStatisticDeleteInprogress from "./MatchStatisticDeleteInprogress";
import { useSnackbar } from "../../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../../commons/components/interface";
import { seasonMatchPossessionDelete } from "../../../../../redux/slices/football/admin/season/match/statistics/manage-season-match-possession-delete-slice";
import { seasonMatchPlayersStatisticDelete } from "../../../../../redux/slices/football/admin/season/match/statistics/manage-season-match-players-statistic-delete-slice";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import AppTabs, { TabItem } from "../../../../../commons/components/Tabs";
import { MatchTeamTypeEnum } from "../../../../../commons/enums/match-team-type-enum";
import SkeletonMatchCardList from "../../../../../commons/components/skeleton/SkeletonMatchCardList";
import { ISelectOptions } from "../../../../../commons/components/ui-components/Select";
import { MatchPlayerPassStatisticListCard } from "./MatchPlayerPassStatisticListCard";
import {
  cleanUpSeasonMatchPlayerPassStatisticList,
  fetchSeasonMatchPlayerPassStatisticList,
} from "../../../../../redux/slices/football/admin/season/match/statistics/manage-season-match-players-pass-statistic-list-slice";
import { seasonMatchPlayerPassStatisticDelete } from "../../../../../redux/slices/football/admin/season/match/statistics/manage-season-match-players-pass-statistic-delete-slice";
interface MatchStatisticsListProps {
  match: IFootballMatchDetail;
  statisticMatchEvent: string;
  onSelectedEvent: (event: string) => void;
}

const MatchStatisticsList = ({ match, statisticMatchEvent, onSelectedEvent }: MatchStatisticsListProps) => {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const [tabItems, setTabItems] = useState<TabItem[]>([]);
  const [teamType, setTeamType] = useState(MatchTeamTypeEnum.Home);
  const { seasonMatchPossessionList, seasonMatchPossessionListStatus } = useAppSelector(
    (state) => state.footballManageSeasonMatchPossessionList
  );
  const { seasonMatchPlayersStatisticList, seasonMatchPlayersStatisticListStatus } = useAppSelector(
    (state) => state.footballManageSeasonMatchPlayersStatisticList
  );
  const { seasonMatchPlayerPassStatisticList, seasonMatchPlayerPassStatisticListStatus } = useAppSelector(
    (state) => state.footballManageSeasonMatchPlayerPassStatisticList
  );
  const { seasonMatchPlayersStatisticDeleteStatus } = useAppSelector(
    (state) => state.footballManageSeasonMatchPlayersStatisticDelete
  );
  const { seasonMatchPossessionDeleteStatus } = useAppSelector(
    (state) => state.footballManageSeasonMatchPossessionDelete
  );
  const [selectedStatisticOptionValue, setSelectedStatisticOptionValue] = useState<string>(statisticMatchEvent);
  const [currentStatisticOption, setCurrentStatisticOption] = useState<ISelectOptions>(statisticsEventsOption[0]);
  const [selectedPlayersStatistic, setSelectedPlayersStatistic] =
    useState<IManageFootballMatchPlayersStatistics | null>(null);
  const [selectedPlayerPassStatistic, setSelectedPlayerPassStatistic] =
    useState<IManageFootballMatchPlayerPassStatistics | null>(null);
  const [selectedPossession, setSelectedPossession] = useState<IManageFootballMatchPossession | null>(null);
  const [isEditMatchStatistic, setEditMatchStatistic] = useState(false);
  const [isDeleteMatchStatistic, setDeleteMatchStatistic] = useState(false);
  const [filteredPlayerStatistics, setFilteredPlayerStatistics] = useState<IManageFootballMatchPlayersStatistics[]>([]);
  const [filteredPlayerPassStatistics, setFilteredPlayerPassStatistics] = useState<
    IManageFootballMatchPlayerPassStatistics[]
  >([]);

  useEffect(() => {
    handleSelectChange(statisticMatchEvent);
    const matchEvent = statisticsEventsOption.find((eventObject) => eventObject.value === statisticMatchEvent);
    if (matchEvent) {
      setCurrentStatisticOption(matchEvent);
    }
  }, [statisticMatchEvent]);

  useEffect(() => {
    if (match) {
      setTabItems([
        {
          label: match.homeTeam?.shortName || "",
          value: MatchTeamTypeEnum.Home,
          icon: match.homeTeam?.logo,
        },
        {
          label: match.awayTeam?.shortName || "",
          value: MatchTeamTypeEnum.Away,
          icon: match.awayTeam?.logo,
        },
      ]);
    }
  }, [match]);

  useEffect(() => {
    dispatch(cleanUpSeasonMatchPlayersStatisticList());
    dispatch(cleanUpSeasonMatchPlayerPassStatisticList());
    dispatch(cleanUpSeasonMatchPossessionList());
    if (match && match.id && match.season.id) {
      onSelectedEvent(selectedStatisticOptionValue);
      if (selectedStatisticOptionValue === MatchStatisticsEventEnum.Possession) {
        dispatch(fetchSeasonMatchPossessionList({ matchId: match.id, seasonId: match.season.id }));
      } else if (selectedStatisticOptionValue === MatchStatisticsEventEnum.Passes) {
        dispatch(fetchSeasonMatchPlayerPassStatisticList({ matchId: match.id, seasonId: match.season.id }));
      } else {
        const plyersStatsParams = {
          key: selectedStatisticOptionValue,
        };
        dispatch(
          fetchSeasonMatchPlayersStatisticList({
            matchId: match.id,
            seasonId: match.season.id,
            urlParams: plyersStatsParams,
          })
        );
      }
    }
  }, [selectedStatisticOptionValue, match]);

  useEffect(() => {
    if (seasonMatchPlayersStatisticList.length > 0) {
      const filteredStats = seasonMatchPlayersStatisticList
        .all()
        .filter((stats) =>
          teamType === MatchTeamTypeEnum.Home
            ? stats.team.id === match.homeTeam?.id
            : stats.team.id === match.awayTeam?.id
        );
      setFilteredPlayerStatistics(filteredStats);
    } else {
      setFilteredPlayerStatistics([]);
    }
  }, [seasonMatchPlayersStatisticList, teamType]);

  useEffect(() => {
    if (seasonMatchPlayerPassStatisticList.length > 0) {
      const filteredStats = seasonMatchPlayerPassStatisticList
        .all()
        .filter((stats) =>
          teamType === MatchTeamTypeEnum.Home
            ? stats.team.id === match.homeTeam?.id
            : stats.team.id === match.awayTeam?.id
        );
      setFilteredPlayerPassStatistics(filteredStats);
    } else {
      setFilteredPlayerPassStatistics([]);
    }
  }, [seasonMatchPlayerPassStatisticList, teamType]);

  const handleTabChange = (args: TabItem) => {
    setTeamType(args.value as MatchTeamTypeEnum);
  };

  const handleSelectChange = (value: string) => {
    if (value !== selectedStatisticOptionValue) {
      setSelectedStatisticOptionValue(value);
    }
  };

  const handleMatchPlayersStatisticsDelete = (playersStats: IManageFootballMatchPlayersStatistics) => {
    setSelectedPlayersStatistic(playersStats);
    setDeleteMatchStatistic(true);
  };
  const handleMatchPlayersStatisticsEdit = (playersStats: IManageFootballMatchPlayersStatistics) => {
    setSelectedPlayersStatistic(playersStats);
    setEditMatchStatistic(true);
  };

  const handleMatchPlayerPassStatisticsDelete = (playersStats: IManageFootballMatchPlayerPassStatistics) => {
    setSelectedPlayerPassStatistic(playersStats);
    setDeleteMatchStatistic(true);
  };
  const handleMatchPlayerPassStatisticsEdit = (playersStats: IManageFootballMatchPlayerPassStatistics) => {
    setSelectedPlayerPassStatistic(playersStats);
    setEditMatchStatistic(true);
  };

  const handleMatchPossessionDelete = (possession: IManageFootballMatchPossession) => {
    setSelectedPossession(possession);
    setDeleteMatchStatistic(true);
  };
  const handleMatchPossessionEdit = (possession: IManageFootballMatchPossession) => {
    setSelectedPossession(possession);
    setEditMatchStatistic(true);
  };

  const handleDeleteConfirm = (e: Event) => {
    e.preventDefault();
    if (match) {
      setDeleteMatchStatistic(false);
      if (selectedPossession) {
        dispatch(
          seasonMatchPossessionDelete({
            seasonId: match.season.id,
            matchId: match.id,
            possessionId: selectedPossession.id,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(
              fetchSeasonMatchPossessionList({
                matchId: match.id,
                seasonId: match.season.id,
              })
            );
            addSnackbar({
              key: "possession-delete",
              text: "Statistics Deleted Successfully",
              variant: "success",
            });
            handleReset();
          })
          .catch(handleError);
      }
      if (selectedPlayersStatistic) {
        dispatch(
          seasonMatchPlayersStatisticDelete({
            seasonId: match.season.id,
            matchId: match.id,
            playerId: selectedPlayersStatistic.player.id,
            statisticId: selectedPlayersStatistic.id,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(
              fetchSeasonMatchPlayersStatisticList({
                matchId: match.id,
                seasonId: match.season.id,
                urlParams: {
                  key: selectedPlayersStatistic.key,
                },
              })
            );
            addSnackbar({
              key: "stats-delete",
              text: "Statistics Deleted Successfully",
              variant: "success",
            });
            handleReset();
          })
          .catch(handleError);
      }
      if (selectedPlayerPassStatistic) {
        dispatch(
          seasonMatchPlayerPassStatisticDelete({
            seasonId: match.season.id,
            matchId: match.id,
            playerId: selectedPlayerPassStatistic.player.id,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(
              fetchSeasonMatchPlayerPassStatisticList({
                matchId: match.id,
                seasonId: match.season.id,
              })
            );
            addSnackbar({
              key: "pass-stats-delete",
              text: "Statistics Deleted Successfully",
              variant: "success",
            });
            handleReset();
          })
          .catch(handleError);
      }
    }
  };

  const handleError = (error: AxiosError<IServerErrorResponse>) => {
    addSnackbar({
      key: "error",
      text: error?.response?.data.message,
      variant: "danger",
    });
  };

  const handleReset = () => {
    setEditMatchStatistic(false);
    setSelectedPossession(null);
    setSelectedPlayersStatistic(null);
    setSelectedPlayerPassStatistic(null);
  };

  return (
    <>
      <AppBox flexDirection="column" gap="xs">
        <AppBox justifyContent="space-between">
          <AppBox>
            <AppTitle as="h5">Match Statistics</AppTitle>
          </AppBox>
          <AppBox className="w-1/2" justifyContent="end">
            <AppSelect
              className="w-1/2"
              options={statisticsEventsOption}
              onChange={(option) => handleSelectChange(option.value.toString())}
              currentOption={currentStatisticOption}
            />
          </AppBox>
        </AppBox>
        {statisticMatchEvent === MatchStatisticsEventEnum.Possession && (
          <AppBox flexDirection="column" gap="2xs">
            <AppTitle as="h6">Possession</AppTitle>
            {seasonMatchPossessionListStatus === StatusEnum.Loading ? (
              <SkeletonMatchCardList noTitle numberOfItems={5} />
            ) : (
              <>
                {seasonMatchPossessionList.length > 0 ? (
                  <AppBox flexDirection="column" gap="sm">
                    {seasonMatchPossessionList.mapArray((possession, index) => (
                      <MatchPossessionListCard
                        match={match}
                        possessionData={possession}
                        key={index}
                        isEditable={index === 0}
                        onDelete={handleMatchPossessionDelete}
                        onEdit={handleMatchPossessionEdit}
                      />
                    ))}
                  </AppBox>
                ) : (
                  <AppText>There are not any statistics to display.</AppText>
                )}
              </>
            )}
          </AppBox>
        )}
        {statisticMatchEvent !== MatchStatisticsEventEnum.Possession && (
          <AppBox flexDirection="column" gap="xs">
            <AppBox>
              <AppTabs
                as="button"
                tabItemClassName="tab-big"
                onClick={handleTabChange}
                activeValue={teamType}
                tabItems={tabItems}
                variant="filled"
                shadow="xs"
                isFilterTabs
              />
            </AppBox>
            <AppBox flexDirection="column" gap="2xs">
              {statisticMatchEvent === MatchStatisticsEventEnum.Interception && (
                <>
                  <AppTitle as="h6">{getStatisticsKeyString(selectedStatisticOptionValue)}</AppTitle>
                  {seasonMatchPlayersStatisticListStatus === StatusEnum.Loading ? (
                    <AppSkeletonGlobalCardList numberOfItems={5} withText noTitle withAvatar />
                  ) : (
                    <>
                      {filteredPlayerStatistics.length > 0 ? (
                        <AppBox flexDirection="column" gap="sm">
                          {filteredPlayerStatistics.map((playerStats, index) => (
                            <MatchPlayersStatisticListCard
                              playerStatsData={playerStats}
                              key={index}
                              onDelete={handleMatchPlayersStatisticsDelete}
                              onEdit={handleMatchPlayersStatisticsEdit}
                            />
                          ))}
                        </AppBox>
                      ) : (
                        <AppText>There are not any statistics to display.</AppText>
                      )}
                    </>
                  )}
                </>
              )}
              {statisticMatchEvent === MatchStatisticsEventEnum.Passes && (
                <>
                  <AppTitle as="h6">{getStatisticsKeyString(selectedStatisticOptionValue)}</AppTitle>
                  {seasonMatchPlayerPassStatisticListStatus === StatusEnum.Loading ? (
                    <AppSkeletonGlobalCardList numberOfItems={5} withText noTitle withAvatar />
                  ) : (
                    <>
                      {filteredPlayerPassStatistics.length > 0 ? (
                        <AppBox flexDirection="column" gap="sm">
                          {filteredPlayerPassStatistics.map((playerStats, index) => (
                            <MatchPlayerPassStatisticListCard
                              playerStatsData={playerStats}
                              key={index}
                              onDelete={handleMatchPlayerPassStatisticsDelete}
                              onEdit={handleMatchPlayerPassStatisticsEdit}
                            />
                          ))}
                        </AppBox>
                      ) : (
                        <AppText>There are not any statistics to display.</AppText>
                      )}
                    </>
                  )}
                </>
              )}
            </AppBox>
          </AppBox>
        )}
      </AppBox>
      <AppModal
        opened={isEditMatchStatistic}
        onClose={handleReset}
        title="Edit Statistic"
        titleIcon={<IconEdit />}
        withoutButtons
      >
        <MatchStatisticsEdit
          match={match}
          statisticData={
            (selectedStatisticOptionValue === MatchStatisticsEventEnum.Possession && selectedPossession) ||
            (selectedStatisticOptionValue === MatchStatisticsEventEnum.Passes && selectedPlayerPassStatistic) ||
            (selectedPlayersStatistic as IManageFootballMatchPlayersStatistics)
          }
          statisticMatchEvent={selectedStatisticOptionValue}
          onEdit={handleReset}
          onCancel={handleReset}
        />
      </AppModal>
      <AppModal
        opened={isDeleteMatchStatistic}
        onClose={(e) => {
          setDeleteMatchStatistic(false);
          setSelectedPossession(null);
        }}
        title="Want To Delete This Statistic?"
        titleIcon={<IconBin />}
        iconColor="danger"
        confirmButtonLabel="Delete"
        onSave={(e) => {
          handleDeleteConfirm(e as Event);
        }}
      >
        <MatchStatisticDelete />
      </AppModal>
      <AppModal
        opened={
          seasonMatchPlayersStatisticDeleteStatus === StatusEnum.Loading ||
          seasonMatchPossessionDeleteStatus === StatusEnum.Loading
        }
        title="Deleting Statistic"
        titleIcon={<IconBin />}
        iconColor="danger"
        withoutCancelButton
        withoutButtons
        withoutCloseButton
      >
        <MatchStatisticDeleteInprogress />
      </AppModal>
    </>
  );
};

export default MatchStatisticsList;
