import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../../../commons/enums/status-enum";
import { manageFootballSeasonService } from "../../../../../../../commons";
import { PaginatedEnsueCollection } from "../../../../../../../ensue-react-system/utilities/paginated-ensue-collection";
import { ManageFootballMatchPlayerPassStatistics } from "../../../../../../../commons/models/football/admin/manage-football-match-players-pass-statistics";

export interface IManageSeasonMatchPlayersStatListState {
  seasonMatchPlayerPassStatisticList: PaginatedEnsueCollection<ManageFootballMatchPlayerPassStatistics>;
  seasonMatchPlayerPassStatisticListStatus: StatusEnum;
}

export interface IManageSeasonMatchPlayerPassStatisticListOption {
  seasonId: number;
  matchId: number;
  urlParams?: {
    page?: number;
    perPage?: number;
    sort?: string;
    sortOrder?: string;
  };
}

const initialState: IManageSeasonMatchPlayersStatListState = {
  seasonMatchPlayerPassStatisticList: new PaginatedEnsueCollection<ManageFootballMatchPlayerPassStatistics>(),
  seasonMatchPlayerPassStatisticListStatus: StatusEnum.Idle,
};

export const fetchSeasonMatchPlayerPassStatisticList = createAsyncThunk(
  "manage/seasonMatchPlayerPassStatisticList",
  async (options: IManageSeasonMatchPlayerPassStatisticListOption, thunkAPI) => {
    try {
      const seasonMatchPlayerPassStatisticList = await manageFootballSeasonService.getSeasonMatchPlayerPassStatistics(
        options.seasonId,
        options.matchId,
        options.urlParams
      );
      return seasonMatchPlayerPassStatisticList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const manageSeasonMatchPlayerPassStatisticListSlice = createSlice({
  name: "manageSeasonMatchPlayerPassStatisticList",
  initialState: initialState,
  reducers: {
    cleanUpSeasonMatchPlayerPassStatisticList(state) {
      state.seasonMatchPlayerPassStatisticList =
        new PaginatedEnsueCollection<ManageFootballMatchPlayerPassStatistics>();
      state.seasonMatchPlayerPassStatisticListStatus = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSeasonMatchPlayerPassStatisticList.pending, (state) => {
        state.seasonMatchPlayerPassStatisticListStatus = StatusEnum.Loading;
      })
      .addCase(
        fetchSeasonMatchPlayerPassStatisticList.fulfilled,
        (state, action: PayloadAction<PaginatedEnsueCollection<ManageFootballMatchPlayerPassStatistics>>) => {
          state.seasonMatchPlayerPassStatisticListStatus = StatusEnum.Succeeded;
          state.seasonMatchPlayerPassStatisticList = action.payload;
        }
      )
      .addCase(fetchSeasonMatchPlayerPassStatisticList.rejected, (state) => {
        state.seasonMatchPlayerPassStatisticListStatus = StatusEnum.Failed;
      });
  },
});

export const { cleanUpSeasonMatchPlayerPassStatisticList } = manageSeasonMatchPlayerPassStatisticListSlice.actions;
export default manageSeasonMatchPlayerPassStatisticListSlice.reducer;
