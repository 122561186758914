import { AppBox, AppPaper, AppText, AppTitle } from "../../../../commons/components";
import IconChat from "../../../../commons/components/icons/chat";

interface MatchUpcomingSummaryProps {
  isAdmin?: boolean;
}

const MatchUpcomingSummary = ({ isAdmin }: MatchUpcomingSummaryProps) => {
  return (
    <AppBox flexDirection="column" gap="xs">
      <AppTitle as="h5">Match Updates</AppTitle>
      <AppPaper shadow="xs">
        <AppBox flexDirection="column" alignItems="center" gap="sm" justifyContent="center" p="lg">
          <AppBox alignItems="center" flexDirection="column" justifyContent="center" gap="2xs">
            <IconChat width={56} height={56} color="rgba(var(--border-200))" />
            {isAdmin ? (
              <AppTitle as="h6">Ready to start the match?</AppTitle>
            ) : (
              <AppTitle as="h6">No Live Info Available</AppTitle>
            )}
            {isAdmin ? (
              <AppText textAlign="center">Click the button "Start Match" above to begin.</AppText>
            ) : (
              <AppText textAlign="center">
                Live updates for this match are not available yet. Please check back closer to kickoff for real-time
                updates, scores, and commentary.
              </AppText>
            )}
          </AppBox>
        </AppBox>
      </AppPaper>
    </AppBox>
  );
};

export default MatchUpcomingSummary;
