import { Controller, useForm } from "react-hook-form";
import {
  AppBox,
  AppButton,
  AppDatePicker,
  AppGridBox,
  AppInput,
  AppSelect,
  AppText,
} from "../../../../commons/components";
import { findInputError, isFormInvalid } from "../../../../commons/utilities/form-utils";
import moment from "moment";
import { ISelectOptions } from "../../../../commons/components/ui-components/Select";
import IconApproval from "../../../../commons/components/icons/approval";
import { SeasonStatusEnum } from "../../../../commons/enums/season-status-enum";
import { IFootballSeason } from "../../../../commons/models/football/interface/i-football-season";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { seasonAdd } from "../../../../redux/slices/football/admin/season/manage-season-add-slice";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";
import { formatDateTime } from "../../../../commons/utilities/date-utils";
import { fetchManageCompetition } from "../../../../redux/slices/football/admin/competition/manage-competition-slice";
import { seasonEdit } from "../../../../redux/slices/football/admin/season/manage-season-edit-slice";
import { useEffect, useState } from "react";
import { findIndexByCondition } from "../../../../commons/utilities/array-utils";
import IconProhibited from "../../../../commons/components/icons/prohibited";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import { toSentenceCase } from "../../../../commons/utilities/string-utils";

interface SeasonAddEditProps {
  competition: IFootballCompetition;
  seasonData?: IFootballSeason | null;
  onSave?: () => void;
  onClose?: () => void;
}

export default function SeasonAddEdit({ competition, seasonData, onSave, onClose }: SeasonAddEditProps) {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const methods = useForm();
  const formErrors = methods.formState.errors;
  const [startDate, setStartDate] = useState(moment().toDate());
  const { seasonAddResponseStatus } = useAppSelector((state) => state.footballManageSeasonAdd);
  const { seasonEditResponseStatus } = useAppSelector((state) => state.footballManageSeasonEdit);

  const handleFormSubmit = (values: IFootballSeason) => {
    const formattedValues = {
      ...values,
      startAt: moment(formatDateTime(values.startAt, "YYYY-MM-DD 00:00:00")).utc().format("YYYY-MM-DD HH:mm:ss"),
      endAt: moment(formatDateTime(values.endAt, "YYYY-MM-DD 23:59:59")).utc().format("YYYY-MM-DD HH:mm:ss"),
    };
    dispatch(
      seasonData
        ? seasonEdit({
            competitionId: competition.id,
            seasonId: seasonData.id,
            seasonInfo: formattedValues,
          })
        : seasonAdd({
            competitionId: competition.id,
            seasonInfo: formattedValues,
          })
    )
      .unwrap()
      .then(() => {
        dispatch(fetchManageCompetition(Number(competition.id)));
        if (seasonData) {
          addSnackbar({
            key: "season-edit-success",
            text: "Season Edited Successfully",
            variant: "success",
          });
        } else {
          addSnackbar({
            key: "season-add-success",
            text: "Season Added Successfully",
            variant: "success",
          });
        }
        if (onSave) {
          onSave();
        }
      })
      .catch((error: AxiosError<IServerErrorResponse>) => {
        const responseData = error.response?.data;
        if (error.response?.status === 417) {
          addSnackbar({
            key: "error",
            text: "Form not valid",
            variant: "danger",
          });
          if (responseData) {
            Object.entries(responseData).forEach(([field, messages]) => {
              messages.forEach((message: string) => {
                methods.setError(field, { message });
              });
            });
          }
        } else {
          addSnackbar({
            key: "error",
            text: responseData?.message,
            variant: "danger",
          });
        }
      });
  };

  useEffect(() => {
    if (seasonData) {
      setStartDate(moment(seasonData.startAt).toDate());
    }
  }, [seasonData]);

  return (
    <form
      noValidate
      onSubmit={methods.handleSubmit((e) => {
        methods.formState.isValid && handleFormSubmit(e as IFootballSeason);
      })}
    >
      <AppBox flexDirection="column" gap="md">
        <AppBox flexDirection="column" gap="sm">
          <Controller
            name="title"
            defaultValue={seasonData ? seasonData.title : ""}
            control={methods.control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <AppBox flexDirection="column">
                <AppInput
                  id="title"
                  label="Season Title"
                  placeholder="Enter season title"
                  type="text"
                  defaultValue={value}
                  onChange={onChange}
                />
                {isFormInvalid(findInputError(formErrors, "title")) && (
                  <AppText as="span" color="danger">
                    <>{toSentenceCase(`${formErrors.title?.message ?? "Season title is required."}`)}</>
                  </AppText>
                )}
              </AppBox>
            )}
          />
          <AppGridBox columns={2} gap="sm">
            <Controller
              control={methods.control}
              name="startAt"
              defaultValue={seasonData ? moment(seasonData.startAt).toDate() : moment(Date.now()).toDate()}
              render={({ field: { onChange, value } }) => (
                <AppBox flexDirection="column" gap="2xs">
                  <AppText as="label" size="lg">
                    Start Date
                  </AppText>
                  <AppDatePicker
                    onChange={(date) => {
                      onChange(date);
                      setStartDate(date as Date);
                    }}
                    selected={value}
                    calenderPlacement="bottom-end"
                  />
                  {isFormInvalid(findInputError(formErrors, "startAt")) && (
                    <AppText as="span" color="danger">
                      <>{toSentenceCase(`${formErrors.startAt?.message ?? "Start date is required."}`)}</>
                    </AppText>
                  )}
                </AppBox>
              )}
            />
            <Controller
              control={methods.control}
              name="endAt"
              defaultValue={seasonData ? moment(seasonData.endAt).toDate() : moment().add(1, "years").toDate()}
              render={({ field: { onChange, value } }) => {
                return (
                  <AppBox flexDirection="column" gap="2xs">
                    <AppText as="label" size="lg">
                      End Date
                    </AppText>
                    <AppDatePicker
                      onChange={onChange}
                      selected={value}
                      calenderPlacement="bottom-end"
                      minDate={startDate}
                    />
                    {isFormInvalid(findInputError(formErrors, "endAt")) && (
                      <AppText as="span" color="danger">
                        <>{toSentenceCase(`${formErrors.endAt?.message ?? "End date is required."}`)}</>
                      </AppText>
                    )}
                  </AppBox>
                );
              }}
            />
          </AppGridBox>
        </AppBox>
        <AppBox justifyContent="end" gap="xs">
          {seasonData && (
            <AppButton
              variant="outline"
              borderLight
              label="Cancel"
              color="gray"
              onClick={onClose}
              disabled={seasonEditResponseStatus === StatusEnum.Loading}
            />
          )}
          <AppButton
            type="submit"
            disabled={!methods.formState.isValid}
            label={seasonData ? "Save Changes" : "Start Season"}
            loading={seasonEditResponseStatus === StatusEnum.Loading || seasonAddResponseStatus === StatusEnum.Loading}
          />
        </AppBox>
      </AppBox>
    </form>
  );
}
