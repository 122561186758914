import { useNavigate } from "react-router";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppDateAndTime,
  AppGridBox,
  AppLink,
  AppPaper,
  AppScorePill,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import AppRoundedDivider from "../../../../commons/components/RoundedDivider";
import moment from "moment";
import { FootballMatchDetail } from "../../../../commons/models/football/football-match-detail";
import { useEffect, useState } from "react";

interface footballMatchProps {
  match: FootballMatchDetail;
}

export default function PreviousMatchCard({ match }: footballMatchProps) {
  const navigate = useNavigate();
  const [penaltyGoals, setPenaltyGoals] = useState<{ home?: number; away?: number }>();
  const handleMatchDetailsClick = () => {
    navigate(`/matches/${match.id}`);
    window.scrollTo(0, 0);
  };
  const handleTeamClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (match.id) {
      if (match.penalties.length > 0) {
        const homePenaltyGoal = match.penalties.filter(
          (penalty) => penalty.teamId === match.homeTeam?.id && penalty.goalScored
        ).length;
        const awayPenaltyGoal = match.penalties.filter(
          (penalty) => penalty.teamId === match.awayTeam?.id && penalty.goalScored
        ).length;
        setPenaltyGoals({ home: homePenaltyGoal, away: awayPenaltyGoal });
      }
    }
  }, [match]);
  return (
    <AppPaper className="match-card" shadow="xs" padding="xs">
      <AppBox flexDirection="column" gap="2xs">
        <AppBorderBox border={["Bottom"]} pb="xs">
          <AppGridBox className="match-card-grid" gap="sm">
            <AppGridBox className="left-section align-center" gap="sm">
              <AppBox justifyContent="end">
                <AppLink to={`/teams/${match.homeTeam.id}`} onClick={handleTeamClick}>
                  <AppBox className="match-home-team" gap="xs" alignItems="center">
                    <AppTitle truncate={true} as="h6" textAlign="right">
                      {match.homeTeam.shortName}
                    </AppTitle>
                    <AppAvatar username={match.homeTeam.name} src={match.homeTeam.logo || ""} />
                  </AppBox>
                </AppLink>
              </AppBox>
              <AppBox justifyContent="center">
                <AppScorePill
                  matchStatus="previous"
                  valueFirst={match.homeGoals}
                  valueSecond={match.awayGoals}
                  penaltyValueFirst={penaltyGoals?.home}
                  penaltyValueSecond={penaltyGoals?.away}
                />
              </AppBox>
              <AppBox justifyContent="start">
                <AppLink to={`/teams/${match.awayTeam.id}`} onClick={handleTeamClick}>
                  <AppBox className="match-away-team" gap="xs" alignItems="center">
                    <AppAvatar username={match.awayTeam.name} src={match.awayTeam.logo || ""} />
                    <AppTitle truncate={true} as="h6">
                      {match.awayTeam.shortName}
                    </AppTitle>
                  </AppBox>
                </AppLink>
              </AppBox>
            </AppGridBox>
            <AppBox className="match-detail-desktop" justifyContent="end" alignItems="center">
              <AppButton size="lg" variant="light" label="Match Details" onClick={() => handleMatchDetailsClick()} />
            </AppBox>
          </AppGridBox>
        </AppBorderBox>
        <AppBox justifyContent="center" alignItems="center">
          <AppDateAndTime date={moment(match.scheduledAt)} format="DD MMM, yyyy" />
          <AppRoundedDivider mx="2xs" />
          <AppDateAndTime date={match.scheduledAt} format="HH:mm" />
          {match.stadium && (
            <>
              <AppRoundedDivider mx="2xs" />
              <AppText lineClamp={1}>
                {match.stadium.name}, {match.stadium.city}
              </AppText>
            </>
          )}
        </AppBox>
        {/* For mobile view only */}
        <AppBox className="match-detail-mobile" gap="xs" justifyContent="center" alignItems="center">
          <AppButton
            fullWidth
            size="lg"
            variant="light"
            label="Match Details"
            onClick={() => handleMatchDetailsClick()}
          ></AppButton>
        </AppBox>
      </AppBox>
    </AppPaper>
  );
}
