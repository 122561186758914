import {
  AppBox,
  AppButton,
  AppDateAndTime,
  AppSkeletonListComponent,
  AppSkeletonMatchCardList,
  AppSkeletonNewsList,
  AppText,
  AppTitle,
} from "../../../commons/components";
import UpcomingMatchCard from "../team-detail/components/UpcomingMatchCard";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import TopStatsGroup from "./components/TopStatsGroup";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { useEffect, useState } from "react";
import {
  cleanUpMatchList,
  fetchMatchList,
} from "../../../redux/slices/football/public/match/football-match-list-slice";
import { IFootballMatchOption } from "../../../redux/interfaces/i-football-match-state";
import { groupArrayByProperty } from "../../../commons/utilities/array-utils";
import { formatDate } from "../../../commons/utilities/date-utils";
import {
  cleanUpStanding,
  fetchSeasonStanding,
} from "../../../redux/slices/football/public/season/football-season-standing-slice";
import { useNavigate } from "react-router";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { IFootballCompetitionStatOption } from "../../../redux/interfaces/i-football-competition-stat-state";
import { TournamentStatsEnum } from "../../../commons/enums/tournament-stats-enum";
import {
  cleanUpCompetitionPlayerStatList,
  cleanUpCompetitionTeamStatList,
  fetchCompetitionPlayerStatList,
} from "../../../redux/slices/football/public/competition/football-competition-stat-list-slice";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import { IFootballStandingOption } from "../../../redux/interfaces/i-football-standing-state";
import { SeasonStatusEnum } from "../../../commons/enums/season-status-enum";
import { groupStandings, IGroupedStanding } from "../../../commons/utilities/standing-table-utils";
import StandingTableGroup from "../../../commons/components/ui-components/StandingTableGroup";
import { TournamentTypeEnum } from "../../../commons/enums/tournament-type-enum";
import { FootballMatchDetail } from "../../../commons/models/football/football-match-detail";
import { MatchStatusEnum } from "../../../commons/enums/match-status-enum";
import LiveMatchCard from "../team-detail/components/LiveMatchCard";

export default function TournamentSummary() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { competition } = useAppSelector((state) => state.footballCompetition);
  const { seasonList } = useAppSelector((state) => state.footballSeason);
  const { standingListByPoint, standingStatus } = useAppSelector((state) => state.footballSeasonStanding);
  const { matchList } = useAppSelector((state) => state.footballMatchList);
  const { competitionPlayerStatList } = useAppSelector((state) => state.footballCompetitionStats);
  const { selectedGlobalSeasonOption } = useAppSelector((state) => state.globalSeasonSelect);
  const { newsList } = useAppSelector((state) => state.news);
  const [groupedStandings, setGroupedStandings] = useState<IGroupedStanding[]>([]);
  const [groupedUpcomingMatches, setGroupedUpcomingMatches] = useState<
    { [x: string]: string | FootballMatchDetail[]; items: FootballMatchDetail[] }[]
  >([]);
  const [groupedLiveMatches, setGroupedLiveMatches] = useState<
    { [x: string]: string | FootballMatchDetail[]; items: FootballMatchDetail[] }[]
  >([]);

  useEffect(() => {
    dispatch(cleanUpMatchList());
    dispatch(cleanUpStanding());
    dispatch(cleanUpCompetitionPlayerStatList());
    dispatch(cleanUpCompetitionTeamStatList());
    setGroupedUpcomingMatches([]);
  }, []);

  useEffect(() => {
    if (selectedGlobalSeasonOption.value) {
      if (selectedGlobalSeasonOption.secondaryValue.status === SeasonStatusEnum.Active) {
        let matchesOption: IFootballMatchOption = {
          competitionId: competition.data.id,
          seasonId: Number(selectedGlobalSeasonOption.value),
          sort: "scheduledAt",
          sortOrder: SortOrderEnum.Ascending,
          page: 0,
        };
        dispatch(fetchMatchList(matchesOption));
      } else {
        dispatch(cleanUpMatchList());
        cleanGroupingArray();
      }
      if (competition.data.type !== TournamentTypeEnum.Knockout) {
        const standingsOption: IFootballStandingOption = {
          competitionId: competition.data.id,
          seasonId: Number(selectedGlobalSeasonOption.value),
        };
        dispatch(fetchSeasonStanding(standingsOption));
      }
      const competitionStatOption: IFootballCompetitionStatOption = {
        competitionId: competition.data.id,
        seasonId: Number(selectedGlobalSeasonOption.value),
        urlParams: {
          statistics: TournamentStatsEnum.Goals,
          perPage: 5,
        },
      };
      dispatch(fetchCompetitionPlayerStatList(competitionStatOption));
    }
  }, [selectedGlobalSeasonOption]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    intervalId = setInterval(() => {
      if (selectedGlobalSeasonOption.secondaryValue.status === SeasonStatusEnum.Active) {
        let matchesOption: IFootballMatchOption = {
          competitionId: competition.data.id,
          seasonId: Number(selectedGlobalSeasonOption.value),
          sort: "scheduledAt",
          sortOrder: SortOrderEnum.Ascending,
          page: 0,
        };
        dispatch(fetchMatchList(matchesOption));
      } else {
        dispatch(cleanUpMatchList());
        cleanGroupingArray();
      }
    }, 30000);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [matchList]);

  const cleanGroupingArray = () => {
    setGroupedLiveMatches([]);
    setGroupedUpcomingMatches([]);
  };

  useEffect(() => {
    if (matchList.data.length > 0) {
      const upcomingMatches = matchList.data
        .all()
        .filter((match) => match.status === MatchStatusEnum.Upcoming)
        .slice(0, 5);
      const liveMatches = matchList.data.all().filter((match) => match.status === MatchStatusEnum.Live);
      const formattedLiveMatches: FootballMatchDetail[] = liveMatches.map((match) => {
        const formattedMatch = new FootballMatchDetail({
          ...match,
          formattedScheduledAt: formatDate(match.scheduledAt),
        });
        return formattedMatch;
      });
      const formattedUpcomingMatches: FootballMatchDetail[] = upcomingMatches.map((match) => {
        const formattedMatch = new FootballMatchDetail({
          ...match,
          formattedScheduledAt: formatDate(match.scheduledAt),
        });
        return formattedMatch;
      });
      setGroupedLiveMatches(groupArrayByProperty(formattedLiveMatches, "formattedScheduledAt"));
      setGroupedUpcomingMatches(groupArrayByProperty(formattedUpcomingMatches, "formattedScheduledAt"));
    } else {
      cleanGroupingArray();
    }
  }, [matchList]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  useEffect(() => {
    setGroupedStandings(groupStandings(standingListByPoint));
  }, [standingListByPoint]);

  const handleStandingsClick = () => {
    navigate(`/tournaments/${competition.data.id}/standings`);
    window.scrollTo(0, 0);
  };

  return (
    <AppBox className="page-content" gap="md">
      {seasonList.length > 0 ? (
        <AppBox flexDirection="column" gap="md">
          {matchList.status === StatusEnum.Loading ? (
            <AppSkeletonMatchCardList />
          ) : (
            <>
              {groupedLiveMatches.length > 0 && (
                <AppBox flexDirection="column" gap="xs">
                  <AppTitle as="h5">Live Matches</AppTitle>
                  {groupedLiveMatches.map((match, index) => (
                    <AppBox flexDirection="column" gap="2xs" key={index}>
                      <AppDateAndTime date={match.formattedScheduledAt as string} />
                      <AppBox flexDirection="column" gap="sm">
                        {match?.items.map((match, index) => (
                          <LiveMatchCard key={index} match={match} />
                        ))}
                      </AppBox>
                    </AppBox>
                  ))}
                </AppBox>
              )}
              {matchList.status === StatusEnum.Succeeded && (
                <AppBox flexDirection="column" gap="xs">
                  <AppTitle as="h5">Upcoming Matches</AppTitle>
                  {groupedUpcomingMatches.length > 0 ? (
                    <AppBox flexDirection="column" gap="2xs">
                      {groupedUpcomingMatches.map((match, index) => (
                        <AppBox flexDirection="column" gap="2xs" key={index}>
                          <AppDateAndTime date={match.formattedScheduledAt as string} />
                          <AppBox flexDirection="column" gap="sm">
                            {match?.items.map((match, index) => (
                              <UpcomingMatchCard key={index} match={match} />
                            ))}
                          </AppBox>
                        </AppBox>
                      ))}
                    </AppBox>
                  ) : (
                    <AppText>There is no fixture of upcoming matches.</AppText>
                  )}
                </AppBox>
              )}
            </>
          )}
          <TopStatsGroup
            statsType="Goals"
            stats={competitionPlayerStatList.data.all()}
            competition={competition.data}
          />
          {competition.data.type !== TournamentTypeEnum.Knockout && (
            <>
              {standingStatus === StatusEnum.Loading ? (
                <AppSkeletonListComponent />
              ) : (
                <AppBox flexDirection="column" gap="xs">
                  <AppBox justifyContent="space-between">
                    <AppTitle as="h5">Standings</AppTitle>
                  </AppBox>
                  {groupedStandings.length > 0 ? (
                    <AppBox gap="sm" flexDirection="column">
                      <StandingTableGroup
                        groupedStandings={groupedStandings}
                        tableItems={10}
                        isLeague={competition.data.type === TournamentTypeEnum.League}
                        numberOfTables={2}
                      />
                      <AppBox className="w-1/2 mx-auto" flexDirection="column">
                        <AppButton
                          onClick={handleStandingsClick}
                          fullWidth
                          size="lg"
                          label="View Full Table"
                          variant="light"
                        />
                      </AppBox>
                    </AppBox>
                  ) : (
                    <AppText>No standing data for the season.</AppText>
                  )}
                </AppBox>
              )}
            </>
          )}
        </AppBox>
      ) : (
        <AppText>The season has either ended or not yet started.</AppText>
      )}
      {newsList.status === (StatusEnum.Loading || StatusEnum.Idle) ? (
        <AppSkeletonNewsList />
      ) : (
        <NewsTimelineCard newsList={newsList.data} timelineType="small" />
      )}
    </AppBox>
  );
}
