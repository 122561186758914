import { useEffect, useState } from "react";
import AppTabs, { TabItem } from "../../../../commons/components/Tabs";
import { useLocation } from "react-router";
import { useAppSelector } from "../../../../hooks/app";
import {
  AppAvatar,
  AppBox,
  AppButton,
  AppLink,
  AppPaper,
  AppSkeletonHeader,
  AppText,
  AppTitle,
} from "../../../../commons/components";
import { StatusEnum } from "../../../../commons/enums/status-enum";
import { IFootballPlayerDetail } from "../../../../commons/models/football/interface/i-football-player";
import { toTitleCase } from "../../../../commons/utilities/string-utils";

interface PlayerManageDetailHeaderProps {
  player: IFootballPlayerDetail;
  onEditPlayer?: (event?: Event) => void;
}

export default function PlayerManageDetailHeader({ player, onEditPlayer }: PlayerManageDetailHeaderProps) {
  const [currentTab, setCurrentTab] = useState("");
  const [activeItem, setActiveItem] = useState<TabItem>();
  const location = useLocation();
  const { playerInfoStatus } = useAppSelector((state) => state.footballManagePlayer);

  useEffect(() => {
    const url = location.pathname.split("/");
    setCurrentTab(url[url.length - 1]);
  }, [activeItem, location.pathname]);

  useEffect(() => {
    const lastPathSegment = location.pathname.split("/").pop();
    if (!isNaN(Number(lastPathSegment))) {
      setCurrentTab("profile");
    }
  }, [location.pathname]);

  const tabItems = [
    {
      label: "Profile",
      value: "profile",
      href: "profile",
    },
    {
      label: "Events",
      value: "events",
      href: "events",
    },
    {
      label: "Statistics",
      value: "statistics",
      href: "statistics",
    },
  ];

  return (
    <AppPaper shadow="xs" padding="none">
      {playerInfoStatus === StatusEnum.Loading ? (
        <AppSkeletonHeader withoutBackground />
      ) : (
        <AppBox
          alignItems="center"
          justifyContent="space-between"
          p="sm"
          style={{
            borderBottom: "1px solid rgb(var(--border-default))",
          }}
        >
          <AppBox alignItems="center" gap="xs">
            <AppAvatar username={player.fullName} size="2xl" src={player.avatar || ""} />
            <AppBox flexDirection="column" gap="2xs">
              <AppTitle as="h4">{toTitleCase(player.fullName)}</AppTitle>
              {player.additionalInfo && player.additionalInfo.team.id && (
                <AppText color="muted">
                  Current team:{" "}
                  <AppLink to={`/manage/teams/${player.additionalInfo.team.id}`}>
                    {toTitleCase(player.additionalInfo.team.shortName)}
                  </AppLink>
                </AppText>
              )}
            </AppBox>
          </AppBox>
          <AppButton variant="light" label="Edit Player Info" onClick={onEditPlayer} />
        </AppBox>
      )}
      <AppBox p="sm">
        <AppTabs
          as="a"
          onClick={(e) => {
            setActiveItem(e);
            window.scrollTo(0, 0);
          }}
          activeValue={currentTab}
          tabItems={tabItems}
        />
      </AppBox>
    </AppPaper>
  );
}
