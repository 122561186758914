import { useEffect, useState } from "react";
import {
  AppBox,
  AppGridBox,
  AppSkeletonListComponent,
  AppSkeletonNewsList,
  AppTitle,
} from "../../../commons/components";
import NewsTimelineCard from "../../../commons/components/ui-components/NewsTimelineCard";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { RootState } from "../../../redux/store";
import { IFootballStandingOption } from "../../../redux/interfaces/i-football-standing-state";
import { fetchSeasonStanding } from "../../../redux/slices/football/public/season/football-season-standing-slice";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { INewsOptions } from "../../../redux/interfaces/i-news-state";
import { fetchNews } from "../../../redux/slices/news-slice";
import { SortOrderEnum } from "../../../commons/enums/sort-order-enum";
import StandingTableGroup from "../../../commons/components/ui-components/StandingTableGroup";
import { groupStandings, IGroupedStanding } from "../../../commons/utilities/standing-table-utils";

export default function MatchStandings() {
  const dispatch = useAppDispatch();

  const { standingListByPoint, standingStatus } = useAppSelector((state: RootState) => state.footballSeasonStanding);
  const { match } = useAppSelector((state: RootState) => state.footballMatchDetail);
  const { newsList } = useAppSelector((state: RootState) => state.news);
  const [groupedStandings, setGroupedStandings] = useState<IGroupedStanding[]>([]);

  useEffect(() => {
    const standingOptions: IFootballStandingOption = {
      competitionId: match.competition.id,
      seasonId: match.season.id,
    };
    if (standingOptions.competitionId && standingOptions.seasonId) {
      dispatch(fetchSeasonStanding(standingOptions));
    }
  }, [match]);

  useEffect(() => {
    if (newsList.status === StatusEnum.Idle) {
      const newsOptions: INewsOptions = {
        perPage: 10,
        sort: "publishAt",
        sortOrder: SortOrderEnum.Descending,
      };
      dispatch(fetchNews(newsOptions));
    }
  }, [newsList.status]);

  useEffect(() => {
    setGroupedStandings(groupStandings(standingListByPoint));
  }, [standingListByPoint]);

  return (
    <AppBox className="page-content" gap="md">
      {standingStatus === StatusEnum.Loading ? (
        <AppSkeletonListComponent numberOfItems={20} />
      ) : (
        <AppBox flexDirection="column" gap="sm">
          <AppBox justifyContent="space-between">
            <AppTitle as="h5">Standings</AppTitle>
          </AppBox>
          {match.homeTeam?.id && match.awayTeam?.id && groupedStandings.length > 0 && (
            <StandingTableGroup
              groupedStandings={groupedStandings}
              highlightedTeams={[
                { id: match.homeTeam.id, color: match.homeTeam?.homeColor || "" },
                { id: match.awayTeam.id, color: match.awayTeam?.homeColor || "" },
              ]}
            />
          )}
        </AppBox>
      )}
      {newsList.status === (StatusEnum.Loading || StatusEnum.Idle) ? (
        <AppSkeletonNewsList />
      ) : (
        <NewsTimelineCard newsList={newsList.data} timelineType="small" />
      )}
    </AppBox>
  );
}
