import moment from "moment";
import { AppBox, AppGridBox, AppText, AppTitle, InfoCard } from "../../../../commons/components";
import { IFootballPlayerDetail } from "../../../../commons/models/football/interface/i-football-player";
import { toSentenceCase, toTitleCase } from "../../../../commons/utilities/string-utils";
import { formatDate } from "../../../../commons/utilities/date-utils";

interface PlayerInfoProps {
  playerInfo: IFootballPlayerDetail;
}

export default function PlayerInfoHeader({ playerInfo }: PlayerInfoProps) {
  const basicInfo = [
    {
      title: toSentenceCase(playerInfo.additionalInfo?.position || "-"),
      label: "Position",
    },
    {
      title: playerInfo.additionalInfo?.jerseyNumber || "-",
      label: "Squad Number",
    },
    {
      title: playerInfo.preferredFoot ? toTitleCase(playerInfo.preferredFoot) : "-",
      label: "Preferred Foot",
    },
    {
      title: playerInfo.dateOfBirth ? formatDate(playerInfo.dateOfBirth, "d MMM, yyyy") : "-",
      label: "Date Of Birth",
    },
    {
      title: playerInfo.birthPlace ? toTitleCase(playerInfo.birthPlace) : "-",
      label: "Birth Place",
    },
    {
      title: `${playerInfo.height ? playerInfo.height + " " + "cm" : "-"} / ${
        playerInfo.weight ? playerInfo.weight + " " + "cm" : "-"
      }`,
      label: "Height / Weight",
    },
  ];

  return (
    <AppBox className="player-header-info-container w-100 flex-1" flexDirection="column" gap="md">
      <AppGridBox className="player-header-info" columns={3} gap="sm" pt="sm">
        {basicInfo.map((item) => (
          <InfoCard
            key={item.label}
            alignItems="start"
            shadow="none"
            title={item.title}
            label={item.label}
            withBorder
          />
        ))}
      </AppGridBox>
    </AppBox>
  );
}
