import { useEffect, useState } from "react";
import { Colors, Radius, Sizes } from "../types/core-types";
import AppTitle from "./Title";

export type BannerVariant = "filled" | "outline" | "light";

interface BannerProps {
  username: string;
  color?: Colors;
  radius?: Radius;
  src?: string;
  variant?: BannerVariant;
  withBorder?: boolean;
}

function generateInitials(name: string): string {
  const nameArr = name?.split(" ");
  const generatedInitials = (
    nameArr[0].charAt(0) + (nameArr.length > 1 ? nameArr[nameArr.length - 1].charAt(0) : "")
  ).toUpperCase();
  return generatedInitials;
}

function isVideo(src: string): boolean {
  return /\.(mp4)$/i.test(src);
}

export default function AppBanner({
  username,
  color = "accent",
  radius = "xs",
  src,
  variant = "filled",
  withBorder = true,
}: BannerProps) {
  const [initials, setInitials] = useState(generateInitials(username));
  useEffect(() => {
    setInitials(generateInitials(username));
  }, [username]);

  const classNames = [
    `banner`,
    !src && variant && `bg-${variant}`,
    color && `color-${color}`,
    radius && `radius-${radius}`,
    withBorder && `border-default`,
  ]
    .filter(Boolean)
    .join(" ");

  const renderBannerContent = () => {
    if (src) {
      if (isVideo(src)) {
        return <video crossOrigin="anonymous" src={src} className="Banner-video" loop autoPlay muted />;
      } else {
        return <img crossOrigin="anonymous" src={src} alt={`${username} Banner`} className="Banner-img" />;
      }
    } else {
      return <AppTitle color="currentColor">{initials}</AppTitle>;
    }
  };

  return <div className={classNames}>{renderBannerContent()}</div>;
}
