import { Outlet, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { useEffect, useState } from "react";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { AppBox, AppContainer, AppModal, NotFound } from "../../../commons/components";
import PlayerManageDetailHeader from "./components/PlayerManageDetailHeader";
import { cleanUpPlayer } from "../../../redux/slices/football/public/player/football-player-detail-slice";
import { fetchManagePlayer } from "../../../redux/slices/football/admin/player/manage-player-slice";
import { setPageTitle } from "../../../redux/slices/page-title-slice";
import setDocumentTitle from "../../../commons/utilities/document-title";
import { cleanUpManagePlayerCompetitions } from "../../../redux/slices/football/admin/player/manage-player-competitions-slice";
import IconEdit from "../../../commons/components/icons/edit";
import { toTitleCase } from "../../../commons/utilities/string-utils";
import PlayerAddEdit from "./components/playerAddEdit/PlayerAddEdit";
import { cleanUpStepperFormData } from "../../../redux/slices/stepper-form-slice";

export default function PlayerDetailManage() {
  const { playerId } = useParams();
  const dispatch = useAppDispatch();
  const [isEditPlayer, setIsEditPlayer] = useState(false);
  const { playerInfo, playerInfoStatus } = useAppSelector((state) => state.footballManagePlayer);
  useEffect(() => {
    dispatch(fetchManagePlayer(Number(playerId)));
  }, [playerId]);

  useEffect(() => {
    if (playerInfoStatus === StatusEnum.Failed) {
      return () => {
        dispatch(cleanUpPlayer());
      };
    }
  }, [playerInfoStatus]);

  useEffect(() => {
    dispatch(setPageTitle(""));
    return () => {
      dispatch(cleanUpPlayer());
      dispatch(cleanUpManagePlayerCompetitions());
    };
  }, []);

  useEffect(() => {
    setDocumentTitle([`${toTitleCase(playerInfo.displayName)}`, "Manage"]);
  }, [playerInfo]);

  const handleAfterEdit = () => {
    setIsEditPlayer(false);
    dispatch(fetchManagePlayer(Number(playerId)));
  };

  const handleAfterEditClose = () => {
    setIsEditPlayer(false);
    dispatch(cleanUpStepperFormData());
  };

  return (
    <AppContainer>
      {playerInfoStatus === StatusEnum.Failed ? (
        <NotFound />
      ) : (
        <AppBox mt="md" flexDirection="column" gap="md">
          <PlayerManageDetailHeader player={playerInfo} onEditPlayer={() => setIsEditPlayer(true)} />
          <Outlet />
        </AppBox>
      )}
      <AppModal
        opened={isEditPlayer}
        modalSize="lg"
        onClose={handleAfterEditClose}
        title="Edit Player"
        titleIcon={<IconEdit />}
        withoutButtons
      >
        <PlayerAddEdit playerData={playerInfo} onCancel={handleAfterEditClose} onSave={handleAfterEdit} />
      </AppModal>
    </AppContainer>
  );
}
