import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { footballPlayerService } from "../../../../../commons";
import { EnsueCollection } from "../../../../../ensue-react-system/utilities/ensue-collection";
import { FootballPlayerAttribute } from "../../../../../commons/models/football/football-player-attribute";
import { IFootballGroupedPlayerAttribute } from "../../../../../commons/models/football/interface/i-football-grouped-player-attribute";

export interface IFootballPlayerAttributeState {
  playerAttributeList: {
    data: IFootballGroupedPlayerAttribute;
    status: StatusEnum;
  };
  comparedPlayerAttributeList: {
    data: IFootballGroupedPlayerAttribute;
    status: StatusEnum;
  };
}

export interface IFootballPlayerAttributeOption {
  playerId: number;
  urlParams?: {
    year?: number;
  };
}

const initialState: IFootballPlayerAttributeState = {
  playerAttributeList: {
    data: {},
    status: StatusEnum.Idle,
  },
  comparedPlayerAttributeList: {
    data: {},
    status: StatusEnum.Idle,
  },
};

export const fetchPlayerAttributeList = createAsyncThunk(
  "players/fetchPlayerAttributeList",
  async (options: IFootballPlayerAttributeOption, thunkAPI) => {
    try {
      const playerAttributeList = await footballPlayerService.getAttributes(options.playerId, options.urlParams);
      return playerAttributeList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchComparedPlayerAttributeList = createAsyncThunk(
  "players/fetchComparedPlayerAttributeList",
  async (options: IFootballPlayerAttributeOption, thunkAPI) => {
    try {
      const comparedPlayerAttributeList = await footballPlayerService.getAttributes(
        options.playerId,
        options.urlParams
      );
      return comparedPlayerAttributeList;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const footballPlayerAttributeListSlice = createSlice({
  name: "footballPlayerAttributeList",
  initialState: initialState,
  reducers: {
    cleanUpPlayerAttribute(state) {
      state.playerAttributeList.data = {};
      state.playerAttributeList.status = StatusEnum.Idle;
    },
    cleanUpComparedPlayerAttribute(state) {
      state.comparedPlayerAttributeList.data = {};
      state.comparedPlayerAttributeList.status = StatusEnum.Idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPlayerAttributeList.pending, (state) => {
        state.playerAttributeList.status = StatusEnum.Loading;
      })
      .addCase(fetchPlayerAttributeList.fulfilled, (state, action: PayloadAction<IFootballGroupedPlayerAttribute>) => {
        state.playerAttributeList.status = StatusEnum.Succeeded;
        state.playerAttributeList.data = action.payload;
      })
      .addCase(fetchPlayerAttributeList.rejected, (state) => {
        state.playerAttributeList.status = StatusEnum.Failed;
      })
      .addCase(fetchComparedPlayerAttributeList.pending, (state) => {
        state.playerAttributeList.status = StatusEnum.Loading;
      })
      .addCase(
        fetchComparedPlayerAttributeList.fulfilled,
        (state, action: PayloadAction<IFootballGroupedPlayerAttribute>) => {
          state.playerAttributeList.status = StatusEnum.Succeeded;
          state.playerAttributeList.data = action.payload;
        }
      )
      .addCase(fetchComparedPlayerAttributeList.rejected, (state) => {
        state.playerAttributeList.status = StatusEnum.Failed;
      });
  },
});

export const { cleanUpPlayerAttribute, cleanUpComparedPlayerAttribute } = footballPlayerAttributeListSlice.actions;
export default footballPlayerAttributeListSlice.reducer;
