import { AppBox, AppButton, AppGridBox, AppInput, AppText } from "../../../../../commons/components";
import { Controller, useForm } from "react-hook-form";
import { IFootballPlayerDetail } from "../../../../../commons/models/football/interface/i-football-player";
import { findInputError, isFormInvalid } from "../../../../../commons/utilities/form-utils";
import { FieldErrors, FieldValues } from "react-hook-form";
import { IPlayerDTO } from "../../../../../api-services/football/admin/manage-football-player-service";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/app";
import { setStepperFormData } from "../../../../../redux/slices/stepper-form-slice";
import { StatusEnum } from "../../../../../commons/enums/status-enum";
import { toSentenceCase } from "../../../../../commons/utilities/string-utils";

interface PlayerCareerDetailsAddEditProps {
  playerData?: IFootballPlayerDetail | null;
  formStep: number;
  formErrors: FieldErrors<FieldValues>;
  submitStatus: StatusEnum;
  onBack: (currentStep: number) => void;
  onSubmit: (currentStep: number) => void;
  onClearError: (errorKey: string) => void;
}

const PlayerCareerDetailsAddEdit = ({
  playerData,
  formStep,
  formErrors,
  submitStatus,
  onBack,
  onSubmit,
  onClearError,
}: PlayerCareerDetailsAddEditProps) => {
  const methods = useForm();
  const dispatch = useAppDispatch();
  const formData = useAppSelector<IPlayerDTO>((state) => state.stepperForm.stepperFormData);

  const handleInputChange = (value: string, key: string) => {
    const playerData: IPlayerDTO = {
      ...formData,
      [key]: value,
    };
    dispatch(setStepperFormData<IPlayerDTO>(playerData));
    onClearError(key);
  };
  return (
    <AppBox flexDirection="column" justifyContent="space-between" gap="sm" className="flex-1">
      <AppBox flexDirection="column" gap="sm" className="w-100">
        <Controller
          name="strength"
          defaultValue={playerData ? playerData.strength : ""}
          control={methods.control}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                as="textarea"
                id="strengths"
                label="Strengths"
                placeholder="Enter player's strengths, e.g. Passing, Play making"
                className="ee-input-textarea"
                defaultValue={value}
                flex="row"
                labelAlign="start"
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "strength");
                }}
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "strength")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.strength?.message && formErrors.strength.message}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="weakness"
          defaultValue={playerData ? playerData.weakness : ""}
          control={methods.control}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                as="textarea"
                id="weaknesses"
                label="Weaknesses"
                placeholder="Enter player's weaknesses, e.g. Team play, High pressing"
                className="ee-input-textarea"
                defaultValue={value}
                flex="row"
                labelAlign="start"
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "weakness");
                }}
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "weakness")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.weakness?.message && formErrors.weakness.message}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
        <Controller
          name="description"
          defaultValue={playerData ? playerData.description : ""}
          control={methods.control}
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column">
              <AppInput
                as="textarea"
                id="description"
                label="Description"
                placeholder="Enter description about player"
                className="ee-input-textarea"
                defaultValue={value}
                flex="row"
                labelAlign="start"
                onChange={(event) => {
                  onChange(event);
                  handleInputChange(event.target.value, "description");
                }}
              />
              <AppGridBox style={{ gridTemplateColumns: "2fr 1fr", direction: "rtl" }} gap="sm">
                {isFormInvalid(findInputError(formErrors, "description")) && (
                  <AppText as="span" color="danger" textAlign="end">
                    <>{toSentenceCase(`${formErrors.description?.message ?? "Description is required."}`)}</>
                  </AppText>
                )}
              </AppGridBox>
            </AppBox>
          )}
        />
      </AppBox>
      <AppBox gap="xs" justifyContent="end">
        <AppButton
          label="Back"
          variant="outline"
          color="gray"
          borderLight
          onClick={() => {
            onBack(formStep);
          }}
          disabled={submitStatus === StatusEnum.Loading}
        />
        <AppButton
          type="submit"
          label={playerData ? "Save Changes" : "Submit"}
          onClick={() => {
            onSubmit(formStep);
          }}
          loading={submitStatus === StatusEnum.Loading}
        />
      </AppBox>
    </AppBox>
  );
};

export default PlayerCareerDetailsAddEdit;
