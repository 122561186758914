import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../hooks/app";
import { useEffect, useState } from "react";
import { StatusEnum } from "../../../commons/enums/status-enum";
import { AppBox, AppContainer, AppModal, NotFound } from "../../../commons/components";
import { cleanUpStadiumInfo, fetchStadium } from "../../../redux/slices/stadium/manage-stadium-slice";
import StadiumManageDetailHeader from "./components/StadiumManageDetailHeader";
import StadiumAddEdit from "./components/stadiumAddEdit/StadiumAddEdit";
import StadiumInfo from "./components/StadiumInfo";
import { setPageTitle } from "../../../redux/slices/page-title-slice";
import IconEdit from "../../../commons/components/icons/edit";
import { cleanUpStepperFormData } from "../../../redux/slices/stepper-form-slice";

export default function StadiumDetailManage() {
  const { stadiumId } = useParams();
  const dispatch = useAppDispatch();
  const [isEditStadium, setIsEditStadium] = useState(false);
  const { stadiumInfo, stadiumInfoStatus } = useAppSelector((state) => state.manageStadium);
  useEffect(() => {
    dispatch(fetchStadium(Number(stadiumId)));
  }, [stadiumId]);

  useEffect(() => {
    if (stadiumInfoStatus === StatusEnum.Failed) {
      return () => {
        dispatch(cleanUpStadiumInfo());
      };
    }
  }, [stadiumInfoStatus]);

  useEffect(() => {
    dispatch(setPageTitle(""));
    return () => {
      dispatch(cleanUpStadiumInfo());
    };
  }, []);

  const handleAfterEdit = () => {
    setIsEditStadium(false);
    dispatch(fetchStadium(Number(stadiumId)));
  };

  const handleAfterEditClose = () => {
    setIsEditStadium(false);
    dispatch(cleanUpStepperFormData());
  };

  return (
    <AppContainer>
      {stadiumInfoStatus === StatusEnum.Failed ? (
        <NotFound />
      ) : (
        <AppBox mt="md" flexDirection="column" gap="md">
          <StadiumManageDetailHeader stadium={stadiumInfo} onEditStadium={() => setIsEditStadium(true)} />
          <StadiumInfo stadiumInfo={stadiumInfo} />
        </AppBox>
      )}
      <AppModal
        opened={isEditStadium}
        modalSize="lg"
        onClose={handleAfterEditClose}
        title="Edit Venue"
        titleIcon={<IconEdit />}
        withoutButtons
      >
        <StadiumAddEdit stadiumData={stadiumInfo} onCancel={handleAfterEditClose} onSave={handleAfterEdit} />
      </AppModal>
    </AppContainer>
  );
}
