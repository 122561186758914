import { AppBox, AppButton, AppPaper, AppText, AppTitle } from "../../../../commons/components";
import AppBorderBox from "../../../../commons/components/BorderBox";
import IconEmpty from "../../../../commons/components/icons/empty";
import { SeasonStatusEnum } from "../../../../commons/enums/season-status-enum";
import { IFootballCompetition } from "../../../../commons/models/football/interface/i-football-competition";

interface EmptyGroupComponentProps {
  competition: IFootballCompetition;
  isPastSeason?: boolean;
  onAddClick?: () => void;
}

export const EmptyGroupComponent = ({ onAddClick, competition, isPastSeason }: EmptyGroupComponentProps) => {
  return (
    <AppPaper shadow="xs">
      <AppBorderBox borderVariant="dashed" p="sm">
        <AppBox flexDirection="column" alignItems="center" gap="sm" justifyContent="center">
          <AppBox alignItems="center" flexDirection="column" justifyContent="center" gap="2xs">
            <IconEmpty width={56} height={56} color="rgba(var(--border-200))" />
            <AppTitle as="h6">No groups created</AppTitle>
            {isPastSeason || (
              <>
                {competition.latestSeason && competition.latestSeason.status === SeasonStatusEnum.Active ? (
                  <AppText fontWeight="semibold">You need to add a group to continue ahead.</AppText>
                ) : (
                  <AppText fontWeight="semibold">
                    To add group, you need to start the season first. Click the "Start Season" button above to begin.
                  </AppText>
                )}
              </>
            )}
          </AppBox>
          {isPastSeason || (
            <AppButton
              label="Add Group"
              onClick={onAddClick}
              disabled={!(competition.latestSeason && competition.latestSeason.status === SeasonStatusEnum.Active)}
            />
          )}
        </AppBox>
      </AppBorderBox>
    </AppPaper>
  );
};
