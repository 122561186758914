import React, { ReactNode, useEffect, useState } from "react";
import { AppBox, AppCommentaryBadge, AppLink, AppSearch, AppText } from "../../../../../commons/components";
import { MatchEventTypeEnum } from "../../../../../commons/enums/match-event-type-enum";
import AppBorderBox from "../../../../../commons/components/BorderBox";
import isNullOrUndefined from "../../../../../commons/utilities/null-or-undefined";

export interface IEvent {
  title: string;
  icon: ReactNode;
  value: MatchEventTypeEnum;
  keyEvent: boolean;
  actorMessage: string;
  supportActorMessage: string | null;
  actorTitle: string;
  supportActorTitle: string | null;
}

interface EventSelectComponentProps {
  selectedEvent?: IEvent;
  onSelectEvent: (event: IEvent) => void;
}

export const eventList: IEvent[] = [
  {
    title: "Substitution",
    icon: <AppCommentaryBadge status={MatchEventTypeEnum.Substitution} size={18} />,
    keyEvent: true,
    value: MatchEventTypeEnum.Substitution,
    actorMessage: "Choose a player who got substituted out of the match.",
    supportActorMessage: "Choose a player who got substituted into the match.",
    actorTitle: "Substitute Out",
    supportActorTitle: "Substitute In",
  },
  {
    title: "Goal",
    icon: <AppCommentaryBadge status={MatchEventTypeEnum.Goal} size={18} />,
    keyEvent: true,
    value: MatchEventTypeEnum.Goal,
    actorMessage: "Choose a player who scored the goal.",
    supportActorMessage: "Choose a player who assisted.",
    actorTitle: "Goal Scorer",
    supportActorTitle: "Assist",
  },
  {
    title: "Yellow Card",
    icon: <AppCommentaryBadge status={MatchEventTypeEnum.YellowCard} size={18} />,
    keyEvent: true,
    value: MatchEventTypeEnum.YellowCard,
    actorMessage: "Choose a player who received yellow card.",
    supportActorMessage: null,
    actorTitle: "Yellow Card Receiver",
    supportActorTitle: null,
  },
  {
    title: "Double Yellow Card / Red Card",
    icon: <AppCommentaryBadge status={MatchEventTypeEnum.SecondYellowCard} size={18} />,
    keyEvent: true,
    value: MatchEventTypeEnum.SecondYellowCard,
    actorMessage: "Choose a player who received double yellow card / red card.",
    supportActorMessage: null,
    actorTitle: "Double Yellow Card / Red Card Receiver",
    supportActorTitle: null,
  },
  {
    title: "Red Card",
    icon: <AppCommentaryBadge status={MatchEventTypeEnum.RedCard} size={18} />,
    keyEvent: true,
    value: MatchEventTypeEnum.RedCard,
    actorMessage: "Choose a player who received red card.",
    supportActorMessage: null,
    actorTitle: "Red Card Receiver",
    supportActorTitle: null,
  },
  {
    title: "Own Goal",
    icon: <AppCommentaryBadge status={MatchEventTypeEnum.OwnGoal} size={18} />,
    keyEvent: true,
    value: MatchEventTypeEnum.OwnGoal,
    actorMessage: "Choose a player who scored the own goal.",
    supportActorMessage: null,
    actorTitle: "Own Goal Scorer",
    supportActorTitle: null,
  },
  {
    title: "Injury",
    icon: <AppCommentaryBadge status={MatchEventTypeEnum.Injury} size={18} />,
    keyEvent: true,
    value: MatchEventTypeEnum.Injury,
    actorMessage: "Choose a player who suffered the injury.",
    supportActorMessage: null,
    actorTitle: "Injured Player",
    supportActorTitle: null,
  },
  {
    title: "Save",
    icon: <AppCommentaryBadge status={MatchEventTypeEnum.Save} size={18} />,
    keyEvent: false,
    value: MatchEventTypeEnum.Save,
    actorMessage: "Choose a player who prevented the ball from a goal.",
    supportActorMessage: null,
    actorTitle: "Goal Saver",
    supportActorTitle: null,
  },
  {
    title: "Shot",
    icon: <AppCommentaryBadge status={MatchEventTypeEnum.Shot} size={18} />,
    keyEvent: false,
    value: MatchEventTypeEnum.Shot,
    actorMessage: "Choose a player who executed a shot.",
    supportActorMessage: null,
    actorTitle: "Shot Executor",
    supportActorTitle: null,
  },
  {
    title: "Cross",
    icon: <AppCommentaryBadge status={MatchEventTypeEnum.Cross} size={18} />,
    keyEvent: false,
    value: MatchEventTypeEnum.Cross,
    actorMessage: "Choose a player who executed a cross.",
    supportActorMessage: null,
    actorTitle: "Cross Creator",
    supportActorTitle: null,
  },
  {
    title: "Clearance",
    icon: <AppCommentaryBadge status={MatchEventTypeEnum.Clearance} size={18} />,
    keyEvent: false,
    value: MatchEventTypeEnum.Clearance,
    actorMessage: "Choose a player who cleared the ball away.",
    supportActorMessage: null,
    actorTitle: "Clearance Creator",
    supportActorTitle: null,
  },
  {
    title: "Tackle",
    icon: <AppCommentaryBadge status={MatchEventTypeEnum.Tackle} size={18} />,
    keyEvent: false,
    value: MatchEventTypeEnum.Tackle,
    actorMessage: "Choose a player who executed a tackle.",
    supportActorMessage: null,
    actorTitle: "Tackle Executer",
    supportActorTitle: null,
  },
  {
    title: "Foul",
    icon: <AppCommentaryBadge status={MatchEventTypeEnum.Foul} size={18} />,
    keyEvent: false,
    value: MatchEventTypeEnum.Foul,
    actorMessage: "Choose a player who committed a foul.",
    supportActorMessage: "Choose a player who received the foul.",
    actorTitle: "Foul Conceder",
    supportActorTitle: "Foul Receiver",
  },
  {
    title: "Offside",
    icon: <AppCommentaryBadge status={MatchEventTypeEnum.Offside} size={18} />,
    keyEvent: false,
    value: MatchEventTypeEnum.Offside,
    actorMessage: "Choose a player who is called for an offside.",
    supportActorMessage: null,
    actorTitle: "Offside Receiver",
    supportActorTitle: null,
  },
  {
    title: "Corner",
    icon: <AppCommentaryBadge status={MatchEventTypeEnum.Corner} size={18} />,
    keyEvent: false,
    value: MatchEventTypeEnum.Corner,
    actorMessage: "Choose a player who took the corner.",
    supportActorMessage: null,
    actorTitle: "Corner Taker",
    supportActorTitle: null,
  },
  {
    title: "Free Kick",
    icon: <AppCommentaryBadge status={MatchEventTypeEnum.FreeKick} size={18} />,
    keyEvent: false,
    value: MatchEventTypeEnum.FreeKick,
    actorMessage: "Choose a player who took the free kick.",
    supportActorMessage: null,
    actorTitle: "Free Kick Taker",
    supportActorTitle: null,
  },
  {
    title: "Penalty",
    icon: <AppCommentaryBadge status={MatchEventTypeEnum.Penalty} size={18} />,
    keyEvent: false,
    value: MatchEventTypeEnum.Penalty,
    actorMessage: "Choose a player who took the penalty.",
    supportActorMessage: null,
    actorTitle: "Penalty Taker",
    supportActorTitle: null,
  },
];

const EventSelectComponent = ({ onSelectEvent, selectedEvent }: EventSelectComponentProps) => {
  const [filteredEvents, setFilteredEvents] = useState<IEvent[]>(eventList);
  const [keyEvents, setKeyEvents] = useState<IEvent[]>();
  const [normalEvents, setNormalEvents] = useState<IEvent[]>();

  const handleFilter = (searchValue: string): void => {
    if (searchValue) {
      const filteredArray = eventList.filter((event) => event.title.toLowerCase().includes(searchValue.toLowerCase()));
      setFilteredEvents(filteredArray);
    } else {
      setFilteredEvents(eventList);
    }
  };

  const isLastIndex = (index: number, options: IEvent[]): boolean => {
    return index === options.length - 1;
  };

  const handleEventClick = (event: IEvent): (() => void) => {
    return () => {
      onSelectEvent(event);
    };
  };

  useEffect(() => {
    const keyEventsArray = filteredEvents.filter((event) => event.keyEvent);
    const normalEventsArray = filteredEvents.filter((event) => !event.keyEvent);
    setKeyEvents(keyEventsArray);
    setNormalEvents(normalEventsArray);
  }, [filteredEvents]);

  return (
    <AppBox flexDirection="column" gap="sm">
      <AppSearch onChange={(searchValue) => handleFilter(searchValue)} />
      <AppBox flexDirection="column" style={{ height: "30rem", overflowY: "scroll" }}>
        {filteredEvents.length > 0 ? (
          <AppBox flexDirection="column" gap="xs">
            {keyEvents && keyEvents?.length > 0 && (
              <AppBox flexDirection="column" gap="2xs">
                <AppText size="sm">Key Events</AppText>
                <AppBox flexDirection="column">
                  {keyEvents.map((event, index) => (
                    <AppLink onClick={handleEventClick(event)} key={index}>
                      <AppBorderBox border={isLastIndex(index, keyEvents) ? [] : ["Bottom"]} className="w-100">
                        <AppBox
                          gap="xs"
                          alignItems="center"
                          className="flex-1"
                          py="2xs"
                          style={
                            event.value === selectedEvent?.value
                              ? { backgroundColor: "var(--button-bg-light-primary-hover)" }
                              : {}
                          }
                        >
                          {event.icon}
                          <AppText>{event.title}</AppText>
                        </AppBox>
                      </AppBorderBox>
                    </AppLink>
                  ))}
                </AppBox>
              </AppBox>
            )}
            {normalEvents && normalEvents?.length > 0 && (
              <AppBox flexDirection="column" gap="2xs">
                <AppText size="sm">Others</AppText>
                <AppBox flexDirection="column">
                  {normalEvents?.map((event, index) => (
                    <AppLink onClick={handleEventClick(event)} key={index}>
                      <AppBorderBox border={isLastIndex(index, normalEvents) ? [] : ["Bottom"]} className="w-100">
                        <AppBox
                          gap="xs"
                          alignItems="center"
                          className="flex-1"
                          py="2xs"
                          style={
                            event.value === selectedEvent?.value
                              ? { backgroundColor: "var(--button-bg-light-primary-hover)" }
                              : {}
                          }
                        >
                          {event.icon}
                          <AppText>{event.title}</AppText>
                        </AppBox>
                      </AppBorderBox>
                    </AppLink>
                  ))}
                </AppBox>
              </AppBox>
            )}
          </AppBox>
        ) : (
          <AppText>There are not any event you searched for.</AppText>
        )}
      </AppBox>
    </AppBox>
  );
};

export default EventSelectComponent;
