import { useEffect, useState } from "react";
import { AppBox, AppPaper, AppCommentaryBadge, AppText, AppIconButton } from "../../../../../commons/components";
import { MatchEventTypeEnum } from "../../../../../commons/enums/match-event-type-enum";
import {
  IFootballCommentary,
  IMatchCommentaryMetadata,
} from "../../../../../commons/models/football/interface/i-football-commentary";
import { getCommentaryString } from "../../../../../commons/utilities/commentary-utils";
import { formatMatchTime } from "../../../../../commons/utilities/game-time-utils";
import { MatchCommentaryMetaDataTypeEnum } from "../../../../../commons/enums/match-commentary-metadata-type-enum";
import IconEdit from "../../../../../commons/components/icons/edit";
import IconBin from "../../../../../commons/components/icons/bin";
import { IFootballMatchDetail } from "../../../../../commons/models/football/interface/i-football-match";

export interface IMatchCommentary {
  matchId: number;
  date?: string;
  league?: string;
  status: MatchEventTypeEnum;
  label: string;
  matchTime: string;
}

interface MatchCommentaryProps {
  match: IFootballMatchDetail;
  commentary: IFootballCommentary;
  onEdit: (commentary: IFootballCommentary) => void;
  onDelete: (commentary: IFootballCommentary) => void;
}

export default function MatchManageCommentaryCard({ commentary, match, onDelete, onEdit }: MatchCommentaryProps) {
  const [mutatedCommentary, setMutatedCommentary] = useState<IFootballCommentary>();

  const matchStateEvents = [
    MatchEventTypeEnum.MatchStart,
    MatchEventTypeEnum.MatchEnd,
    MatchEventTypeEnum.MatchFirstHalfEnd,
    MatchEventTypeEnum.MatchSecondHalfStart,
    MatchEventTypeEnum.MatchSecondHalfEnd,
    MatchEventTypeEnum.MatchExtraTimeFirstHalfStart,
    MatchEventTypeEnum.MatchExtraTimeFirstHalfEnd,
    MatchEventTypeEnum.MatchExtraTimeSecondHalfStart,
    MatchEventTypeEnum.MatchExtraTimeSecondHalfEnd,
  ];
  useEffect(() => {
    if (commentary.eventType === MatchEventTypeEnum.Penalty) {
      if ((commentary.metadata as IMatchCommentaryMetadata)["type"] === MatchCommentaryMetaDataTypeEnum.Scored) {
        setMutatedCommentary({
          ...commentary,
          eventType: MatchEventTypeEnum.PenaltyScored,
        });
      } else {
        setMutatedCommentary({
          ...commentary,
          eventType: MatchEventTypeEnum.Penalty,
        });
      }
    } else {
      setMutatedCommentary(commentary);
    }
  }, [commentary]);
  return (
    <>
      {mutatedCommentary && (
        <AppPaper shadow="xs" padding="sm" style={{ position: "relative" }}>
          <AppBox gap="sm">
            <AppBox
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                left: "-1rem",
              }}
            >
              <AppCommentaryBadge status={mutatedCommentary.eventType} />
            </AppBox>
            <AppBox alignItems="center" gap="xs" className="w-100 flex-1">
              <AppBox className="match-time-width">
                {!matchStateEvents.includes(mutatedCommentary.eventType) && (
                  <AppText as="p" fontWeight="extrabold">
                    {formatMatchTime(mutatedCommentary.gameTime, mutatedCommentary.gameExtraTime)}
                  </AppText>
                )}
              </AppBox>
              <AppText as="p" color="muted">
                {getCommentaryString(mutatedCommentary, match)}
              </AppText>
            </AppBox>
            {!matchStateEvents.includes(mutatedCommentary.eventType) && (
              <AppBox gap="xs" justifyContent="end" alignItems="center">
                <AppIconButton
                  icon={<IconEdit />}
                  variant="light"
                  onClick={() => {
                    onEdit(commentary);
                  }}
                />
                <AppIconButton
                  icon={<IconBin />}
                  variant="light"
                  color="danger"
                  onClick={() => {
                    onDelete(commentary);
                  }}
                />
              </AppBox>
            )}
          </AppBox>
        </AppPaper>
      )}
    </>
  );
}
