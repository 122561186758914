import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AppBox, AppButton, AppDatePicker, AppText, AppTimePicker } from "../../../../commons/components";
import moment from "moment";
import { formatDate, formatDateTime } from "../../../../commons/utilities/date-utils";
import { IFootballMatchDetail } from "../../../../commons/models/football/interface/i-football-match";
import { useAppDispatch, useAppSelector } from "../../../../hooks/app";
import { seasonMatchStart } from "../../../../redux/slices/football/admin/season/match/manage-season-match-start-slice";
import { fetchSeasonMatchDetail } from "../../../../redux/slices/football/admin/season/match/manage-season-match-detail-slice";
import { useSnackbar } from "../../../../redux/snackbarProvider";
import { AxiosError } from "axios";
import { IServerErrorResponse } from "../../../../commons/components/interface";
import { IMatchStartInfo } from "../../../../api-services/football/admin/manage-football-season-service";
import { StatusEnum } from "../../../../commons/enums/status-enum";

interface MatchStartDetailProps {
  match: IFootballMatchDetail;
  onMatchStart: () => void;
  onCancel: () => void;
}

const MatchStartDetail = ({ match, onCancel, onMatchStart }: MatchStartDetailProps) => {
  const dispatch = useAppDispatch();
  const addSnackbar = useSnackbar();
  const methods = useForm();
  const minDate = moment(match.scheduledAt).toDate();
  const [matchDate, setMatchDate] = useState<Date>(moment().toDate());
  const [matchTime, setMatchTime] = useState(moment().format("HH:mm:ss"));
  const [matchTimezone, setMatchTimezone] = useState(moment.tz.guess());
  const { seasonMatchStartResponseStatus } = useAppSelector((state) => state.footballManageSeasonMatchStart);

  useEffect(() => {
    setMatchDate(moment(match.scheduledAt).toDate());
  }, [match]);

  const handleDateTime = (date: Date, time: string, timezone: string): Promise<string> => {
    return new Promise((resolve) => {
      const utcDateTime = formatDateTime(moment.tz(formatDate(date) + " " + time, timezone).utc());
      resolve(utcDateTime);
    });
  };

  const handleFormSubmit = (values: IMatchStartInfo) => {
    dispatch(seasonMatchStart({ seasonId: match.season.id, matchId: match.id, matchStartInfo: values }))
      .unwrap()
      .then(() => {
        dispatch(
          fetchSeasonMatchDetail({
            seasonId: match.season.id,
            matchId: match.id,
          })
        );
        addSnackbar({
          key: "match-start-success",
          text: "Match Started Successfully",
          variant: "success",
        });
        onMatchStart();
      })
      .catch((error: AxiosError<IServerErrorResponse>) => {
        const responseData = error.response?.data;
        if (error.response?.status === 417) {
          if (responseData) {
            addSnackbar({
              key: "error",
              text: "Form not valid",
              variant: "danger",
            });
            Object.entries(responseData).forEach(([field, messages]) => {
              messages.forEach((message: string) => {
                methods.setError(field, { message });
              });
            });
          }
        } else {
          addSnackbar({
            key: "error",
            text: responseData?.message,
            variant: "danger",
          });
        }
      });
  };

  return (
    <form
      noValidate
      onSubmit={methods.handleSubmit((e) => {
        methods.formState.isValid && handleFormSubmit(e as IMatchStartInfo);
      })}
    >
      <AppBox flexDirection="column" gap="sm">
        <Controller
          name="startAt"
          defaultValue={handleDateTime(matchDate, matchTime, matchTimezone)}
          control={methods.control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <AppBox flexDirection="column" gap="sm">
              <AppBox flexDirection="column" gap="2xs">
                <AppText as="label" size="lg">
                  Match Date
                </AppText>
                <AppDatePicker
                  onChange={(date) => {
                    setMatchDate(date as Date);
                    handleDateTime(date as Date, matchTime, matchTimezone).then((utcDateTime) => {
                      onChange(utcDateTime);
                    });
                  }}
                  selected={matchDate}
                  calenderPlacement="bottom-end"
                  minDate={minDate}
                  disabled
                />
              </AppBox>
              <AppBox flexDirection="column" gap="2xs">
                <AppText as="label" size="lg">
                  Match Time (ground time)
                </AppText>
                <AppTimePicker
                  onChange={(time) => {
                    setMatchTime(time);
                    handleDateTime(matchDate, time, matchTimezone).then((utcDateTime) => {
                      onChange(utcDateTime);
                    });
                  }}
                />
              </AppBox>
            </AppBox>
          )}
        />
        <AppBox justifyContent="end" gap="xs">
          <AppButton
            label="Cancel"
            variant="outline"
            color="gray"
            borderLight
            onClick={onCancel}
            disabled={seasonMatchStartResponseStatus === StatusEnum.Loading}
          />
          <AppButton
            type="submit"
            disabled={!methods.formState.isValid}
            label="Start Match"
            loading={seasonMatchStartResponseStatus === StatusEnum.Loading}
          />
        </AppBox>
      </AppBox>
    </form>
  );
};

export default MatchStartDetail;
